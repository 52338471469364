import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ChangePasswordModel } from '../Model/ChangePasswordModel';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../Service/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UserRoles } from '../UserRoles';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { OutofOfficeActivityModel } from '../Model/OutOfOfficeActivityModel';
import { EmployeeGuestModel } from '../Model/EmployeeGuestModel';
import { AppConfigService } from '../Service/app-config.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

 outOfOfficeObj : OutofOfficeActivityModel = new OutofOfficeActivityModel();
  profileObj: EmployeeGuestModel = new EmployeeGuestModel();
  changePasswordObj: ChangePasswordModel = new ChangePasswordModel();
  //passwordPattern: any = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,30}$/;
  passwordPattern: any = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,30}';
  emailPattern: string = "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  OldPasshide: boolean = true;
  NewPasshide: boolean = true;
  ConfirmPasshide: boolean = true;
  isOutOfOffice : boolean = false;
  @Input()
  showLoader: boolean = false;
  charCode: number;
  Role: any;
  UserRoles: any;
  Userid: string;
  EnquiryngbDateFromFiter: NgbDate | null;
  EnquiryngbDateToFiter: NgbDate | null;
  hoveredDate: NgbDate | null = null;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  datePipe = new DatePipe('en-US');
  mailIdFormat : any;
  

  constructor(private toastr: ToastrService, private commonService: CommonService, public dialog: MatDialog,
    private router: Router, private route: ActivatedRoute,public formatter: NgbDateParserFormatter,private cd: ChangeDetectorRef, private calendar: NgbCalendar,
    private appConfigService: AppConfigService) {
    this.UserRoles = UserRoles;
    const current = new Date();
    this.minDate = { year: current.getFullYear(), month: current.getMonth(), day: current.getDate() };
    this.maxDate = { year: current.getFullYear()+1, month: current.getMonth() + 5, day: current.getDate() }
  }

  ngOnInit() {
    const appConfig = this.appConfigService.getConfig();
    this.mailIdFormat = appConfig.mailIdFormat;
    this.Role = localStorage.getItem("UserRole");
    this.Userid = localStorage.getItem("UserID");
    this.commonService.RefreshHeaderFun();
    this.getUserProfileInfo();
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which: evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  getUserProfileInfo() {
    this.showLoader = true;
    this.commonService.GetUserProfileData('Account', 'GetUserProfileInfo').subscribe((data: any) => {
      this.profileObj = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  updateProfile() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update your profile?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // if user pressed yes dialogResult will be true, 
      // if he pressed no - it will be false
      if (dialogResult) {
        this.showLoader = true;

        this.commonService.PostData('Account', 'UpdateProfile', this.profileObj, null).subscribe((data: any) => {
          if (data.ResponseCode == 1) {
            this.toastr.success("Profile updated successfully!");
            this.router.navigate(['/Dashboard']);
            this.showLoader = false;
          }
          else if (data.ResponseCode == 3) {
            this.toastr.warning('Contact number already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error("Failed to update profile!");
            this.showLoader = false;
          }
        }, error => {
          this.toastr.error("Failed to update profile!");
          this.showLoader = false;
        });

      }
    });

  }

  changePassword() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to change your password?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // if user pressed yes dialogResult will be true, 
      // if he pressed no - it will be false
      if (dialogResult) {
        this.showLoader = true;
        this.commonService.PostData('Account', 'ChangePassword', this.changePasswordObj, null).subscribe((data: any) => {
          if (data.ResponseCode == 1) {
            this.toastr.success("Password updated successfully!");
            this.router.navigate(['/Dashboard']);
            this.showLoader = false;
          }
          else {
            this.toastr.error("Failed to update password! Please enter correct old password!");
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error("Failed to update password!");
          this.showLoader = false;
        });

      }
    });

  }

  OutofOffice(value: any) {
   // alert(value);
    if(value == true)
    {
      this.isOutOfOffice = true;
    }
    else{
      this.isOutOfOffice = false;
    }
  }
  OnToDateFilterChange() {

    //  this.GetAllEnquiry();
  }

  onDateSelection(date: NgbDate) {
    if (!this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter) {
      this.EnquiryngbDateFromFiter = date;
    } else if (this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter && date && date.after(this.EnquiryngbDateFromFiter)) {
      this.EnquiryngbDateToFiter = date;
    } else {
      this.EnquiryngbDateToFiter = null;
      this.EnquiryngbDateFromFiter = date;
    }
  }
  OnFromDateFilterChange() {
    let EnquiryDateFromFiter = this.ConvertNgbDateToDate(this.EnquiryngbDateFromFiter)
    let EnquiryDateToFiter = this.ConvertNgbDateToDate(this.EnquiryngbDateToFiter)
    if (EnquiryDateToFiter < EnquiryDateFromFiter) {
      this.EnquiryngbDateToFiter = this.EnquiryngbDateFromFiter;
      this.cd.detectChanges();

    }
    //this.GetAllEnquiry();

  }
  ConvertNgbDateToDate(ngbdate: any) {
    if (ngbdate != null) {
      return new Date(ngbdate.year, ngbdate.month - 1, ngbdate.day)
    }

  }

  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  
  isHovered(date: NgbDate) {
    return this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter && this.hoveredDate && date.after(this.EnquiryngbDateFromFiter) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.EnquiryngbDateToFiter && date.after(this.EnquiryngbDateFromFiter) && date.before(this.EnquiryngbDateToFiter);
  }

  isRange(date: NgbDate) {
    return date.equals(this.EnquiryngbDateFromFiter) || (this.EnquiryngbDateToFiter && date.equals(this.EnquiryngbDateToFiter)) || this.isInside(date) || this.isHovered(date);
  }

  updateOutOfOfficeSetting(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update your out of office setting?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // if user pressed yes dialogResult will be true, 
      // if he pressed no - it will be false
      if (dialogResult) {
        this.showLoader = true;

       // this.outOfOfficeObj.Role = this.Role;
        this.outOfOfficeObj.Fk_UserId = this.Userid;
        if (this.EnquiryngbDateFromFiter != undefined) {
          this.outOfOfficeObj.StartDate = new Date(this.convertDate(new NgbDate(this.EnquiryngbDateFromFiter.year, this.EnquiryngbDateFromFiter.month, this.EnquiryngbDateFromFiter.day))).toDateString();
        }
        if (this.EnquiryngbDateToFiter != undefined) {
          this.outOfOfficeObj.EndDate = new Date(this.convertDate(new NgbDate(this.EnquiryngbDateToFiter.year, this.EnquiryngbDateToFiter.month, this.EnquiryngbDateToFiter.day))).toDateString();
        }

        this.commonService.PostData('Account', 'SetOutOfOffice', this.outOfOfficeObj, null).subscribe((data: any) => {
          if (data.ResponseCode == 1) {
            this.toastr.success("Out of office setting updated successfully!");
            this.router.navigate(['/Dashboard']);
            this.showLoader = false;
          }
          else {
            this.toastr.error("Failed to update out of office setting!");
            this.showLoader = false;
          }
        }, error => {
          this.toastr.error("Failed to update out of office setting!");
          this.showLoader = false;
        });

      }
    });

  }
  
}
