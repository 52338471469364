import { Component, OnInit, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { reportmodel } from '../dashboard/enquiry-details-chart/enquiry-details-chart.component';
import { AmChartsService } from '@amcharts/amcharts3-angular';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-charts-modal',
  templateUrl: './charts-modal.component.html',
  styleUrls: ['./charts-modal.component.scss']
})
export class ChartsModalComponent implements OnInit {
  @Input() region;
  constructor(private activeModal: NgbActiveModal, private amcharts: AmChartsService
    , private commonService: CommonService) { }
  dataList: any[] = [];
  reportdata: reportmodel[] = Array<reportmodel>();
  ngOnInit(): void {
    this.commonService.RefreshHeaderFun();
    this.enquiryDetailsModal();
  }
  enquiryDetailsModal1() {

    let chart = am4core.create("enquiryDetailsChartModal", am4charts.SlicedChart);
    //chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
    chart.responsive.enabled = true;
    // Export
    chart.exporting.menu = new am4core.ExportMenu();

    chart.data = [{
      "category": "Submitted Enquiry",
      "value": this.region.SubmittedEnquiry
    },
    {
      "category": "RFQ",
      "value": this.region.RFQ
    },
    {
      "category": "Proposal Delivered",
      "value": this.region.ProposalDelivered
    },
    {
      "category": "Order Confirmation",
      "value": this.region.OrderConfirmation
    },
    {
      "category": "Order Delivered",
      "value": this.region.OrderDelivered
    }];

    let series = chart.series.push(new am4charts.FunnelSeries());
    series.colors.step = 2;
    series.dataFields.value = "value";
    series.dataFields.category = "category";
    series.alignLabels = true;

    series.labelsContainer.paddingLeft = 15;
    series.labelsContainer.width = 200;

    series.labels.template.text = "{category}: {value.value}";
    series.slices.template.tooltipText = "{category}: {value.value}\nPercent: {value.percent.formatNumber('#.0')}%";

  }

  enquiryDetailsModal() {
    var chart = this.amcharts.makeChart("enquiryDetailsChartModal", {
      "type": "funnel",
      "theme": "none",
      "dataProvider": [{
        "category": "Lead/Enquiry",
        "actualvalue": this.region.SubmittedEnquiry,
        "showvalue": 50
      },
      {
        "category": "RFQ",
        "actualvalue": this.region.RFQ,
        "showvalue": 50
      },
      {
        "category": "Quotations",
        "actualvalue": this.region.ProposalDelivered,
        "showvalue": 50
      },
      {
        "category": "Order Booked",
        "actualvalue": this.region.OrderConfirmation,
        "showvalue": 50
      },
      {
        "category": "Sales",
        "actualvalue": this.region.OrderDelivered,
        "showvalue": 50
      }],
      "creditsPosition": "bottom-left",
      "titleField": "category",
      "marginRight": 160,
      "hideLabelsPercent": 0,
      "precision": 2,
      "marginLeft": 15,
      "labelPosition": "center",
      "funnelAlpha": 0.9,
      "valueField": "showvalue",
      "startX": 0,
      "neckWidth": "40%",
      "startAlpha": 0,
      "outlineThickness": 1,
      "neckHeight": "30%",
      "balloonText": "[[category]]: <b>" + this.region.Label + " [[actualvalue]]</b>",
      "labelText": "[[category]]: " + this.region.Label + " [[actualvalue]]",
      "responsive": {
        "enabled": true
      },
      "export": {
        "enabled": true,
        "pageOrigin": false,
        "fileName": "SalesFunnel",
        "exportTitles": true,
        "exportFields": [
          "category",
          "actualvalue"
        ],
        "columnNames": {
          "category": "Category",
          "actualvalue": "Value(" + this.region.Label + ")"
        },
        "menu": [
          {
            "label": "<i class='fa fa-ellipsis-h' aria-hidden='true'></i>",
            "menu": [
              {
                "format": "png",
                "label": "PNG"
              },
              {
                "format": "jpg",
                "label": "JPG"
              },
              // {
              //   "format": "svg",
              //   "label": "SVG"
              // },
              {
                "format": "pdf",
                "label": "PDF"
              },
              { "format": "xlsx", "label": "XLSX" },
            ]
          }
        ]
      },
    });

  }

  closePopup() {
    this.activeModal.close('Close Click');
  }

}

export class DataModel {
  category: any;
  value: any;
}
