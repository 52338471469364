export class AddCustomerOpportunityModel {

    Id: number;
    CustomerName: string = "";
    CustomerAddress:string = "";
    KeyPersonName: string = "";
    IndustrySegment: string = "";
    CustomerType: string = "";
    Region: string = "";
    OpportunityDate: string = "";
    Description:string="";
}