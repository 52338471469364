<loader [showLoader]="showLoader"></loader>
<div class="custsize">
    <!-- <div class="modal fade" id="ActivityModal" tabindex="-1" role="dialog"> -->

    <div class="modal-content custContent">

        <div class="modal-body">
            <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="activityForm"
                (ngSubmit)="activityForm.form.valid" #activityForm="ngForm" novalidate>
                <div class="multisteps-form__panel shadow p-4 rounded bg-white js-active" data-animation="fadeIn">
                    <!-- <h3 class="multisteps-form__title">Enquiry </h3> -->
                    <div class="multisteps-form__content">
                        <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                            <div class="m-portlet__head">
                                <div class="m-portlet__head-caption">
                                    <div class="m-portlet__head-title">
                                        <h3 class="m-portlet__head-text">
                                            Enquiry Forward
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="m-portlet__body">
                                <form class="m-form m-form--fit m-form--label-align-right">
                                    <div class="form-group m-form__group row">

                                        <label class="col-lg-5  col-form-label">Region:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                        <div class="col-lg-7">
                                            <select name="region" autofocus [(ngModel)]="selectedRegion" (change)="onRegionChange()" class="form-control" id="sel1">
                                                <option value="0" disabled selected>Select Region</option>
                                                <option *ngFor="let item of salesEnggList" value={{item.Region.Id}}>
                                                    {{item.Region.Name}}
                                                </option>
                                            </select>
                                            <div *ngIf="salesRegionValidation" class="validationmsg">
                                                Region is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-5 col-form-label">Sales Engineer
                                            Name:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                        <div class="col-lg-7">
                                            <ng-select id="sename"
                                                                name="sename"
                                                                [items]="seList"
                                                                bindLabel="Name" 
                                                                bindValue="Id"
                                                                (change)="onEnggChange()"
                                                                [(ngModel)]="selectedSalesEngg"
                                                                placeholder="Select Sales Engineer"
                                                                required>
                                                            </ng-select>
                                                            <div *ngIf="salesEnggValidation" class="validationmsg">
                                                                Sales Engineer is required
                                                            </div>
                                           
                                        </div>

                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="button-row text-right mt-4" style="margin:15px;">
                    <button type="button"  class="btn btn-primary btnspc" (click)="PassonToPerson()"
                    data-dismiss="modal">Pass On</button>&nbsp;&nbsp;
                <button type="button" class="btn btn-metal" (click)="closePopup()"
                    data-dismiss="modal">Close</button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
<!-- </div> -->