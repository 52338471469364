export class ProductMatrixModel {
    productid: number = 0;
    productname: string = "";
    stationlist: productstation[] = [];

}

export class productstation {
    stationid: number = 0;
    stationname: string = "";
    price: number;
    speedsteplist: productspeedstep[] = [];
    IsStationPOR: boolean = false;
    isStationValid: boolean = true;  // Price On Request flag

}
export class productspeedstep {
    stepid: number = 0;
    stepname: string = "";
    price: number;
    IsSpeedStepPOR: boolean = false;    // Price On Request flag
    isSpeedstepValid: boolean = true;

}