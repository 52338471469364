<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        {{FormStatus}} {{ProductSeriesVariantLabel}}
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm" appFocusInvalidInput
                            (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                            <div class="m-portlet__body">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Product Series:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="productseries"
                                            [(ngModel)]="VariantLevel1obj.SeriesName" #productseries="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productseries.invalid || productseries?.touched && productseries.invalid}"
                                            placeholder="Enter Product Series" (keypress)="AvoidSpace($event)" required
                                            (change)="VariantLevel1obj.SeriesName=VariantLevel1obj.SeriesName.trim()"
                                            disabled>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && productseries.invalid || productseries?.touched && productseries.invalid">
                                            <div *ngIf="myform.hasError('required', 'productseries')">
                                                Product series is required
                                            </div>
                                        </div>

                                    </div>

                                    <div class="offset-1"></div>
                                    <label class="col-lg-2  col-form-label">Product Model:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="productmodel"
                                            [(ngModel)]="VariantLevel1obj.ModelName" #productmodel="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productmodel.invalid || productmodel?.touched && productmodel.invalid}"
                                            placeholder="Enter Product Model" (keypress)="AvoidSpace($event)" required
                                            (change)="VariantLevel1obj.ModelName=VariantLevel1obj.ModelName.trim()"
                                            disabled>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && productmodel.invalid || productmodel?.touched && productmodel.invalid">
                                            <div *ngIf="myform.hasError('required', 'productmodel')">
                                                Product model is required
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label" *ngIf="VariantLevel1obj.SeriesName != ''">
                                        {{ProductSeriesVariantLabel}}:
                                        <span style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3"
                                        *ngIf="(VariantLevel1obj.SeriesName != 'TC' &&  VariantLevel1obj.SeriesName != 'TR' && VariantLevel1obj.SeriesName != '')">
                                        <input class="form-control m-input" type="text" name="level1"
                                            [(ngModel)]="VariantLevel1obj.Level1[0].Level1" #level1="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && level1.invalid || level1?.touched && level1.invalid}"
                                            placeholder="Enter {{ProductSeriesVariantLabel}}" maxlength="130"
                                            (keypress)="AvoidSpace($event)" [disabled]="isEditable" required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && level1.invalid || level1?.touched && level1.invalid">
                                            <div *ngIf="myform.hasError('required', 'level1')">
                                                {{ProductSeriesVariantLabel}} is required</div>
                                        </div>

                                    </div>

                                    <div class="col-lg-3"
                                        *ngIf="(VariantLevel1obj.SeriesName == 'TC' || VariantLevel1obj.SeriesName == 'TR' ) ">
                                        <mat-select class="form-control m-input" placeholder="Select Station"
                                            [(ngModel)]="SelectedStation" [ngStyle]="{'background-color': isEditable ? '#f4f5f8' : '#fff' }"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && station.invalid || station?.touched && station.invalid}"
                                            (selectionChange)="OnStationselected($event)" #station="ngModel" multiple
                                            name="station" [disabled]="isEditable" required>
                                            <mat-option *ngFor="let item of StationList;let i=index"
                                                value="{{item.Level1}}"> {{item.Level1}}</mat-option>
                                            <mat-option disabled *ngIf="StationList.length <= 0" value="No Data Found">
                                                No Data Found </mat-option>
                                        </mat-select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && station.invalid || station?.touched && station.invalid">
                                            <div *ngIf="myform.hasError('required', 'station')">
                                                Station is required
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div class="form-group m-form__group row">

                                    <label class="col-lg-2 col-form-label">Price (€):</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="price" min="0"
                                            [(ngModel)]="VariantLevel1obj.Price" #price="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && price.invalid || price?.touched && price.invalid}"
                                            style="text-align:right;" placeholder="Enter Price" maxlength="7"
                                            pattern="[0-9]+" (keypress)="AvoidSpace($event);isNumberKey($event);"
                                            [disabled]="VariantLevel1obj.IsPriceOnRequest || isEditable">
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && price.invalid || price?.touched && price.invalid">

                                            <div *ngIf="myform.hasError('pattern', 'price')">
                                                Price must be an integer</div>
                                        </div>
                                    </div>
                                    <div class="offset-1"></div>
                                    <div class="col-lg-3">
                                        <mat-checkbox name="dep2" #dep2="ngModel"
                                            [(ngModel)]="VariantLevel1obj.IsPriceOnRequest"
                                            (change)="onIsPriceOnRequest($event)" [disabled]="isEditable">
                                            <label>Is Price On Request</label>
                                        </mat-checkbox>
                                    </div>
                                    <div class="col-lg-3"
                                        *ngIf="(VariantLevel1obj.SeriesName != 'TC' && VariantLevel1obj.SeriesName != 'TR' )">
                                        <mat-checkbox name="dep1" #dep1="ngModel" [(ngModel)]="VariantLevel1obj.IsUnits"
                                            [disabled]="isEditable">
                                            <label>Is Units</label>
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit" *ngIf="isEditable"
                                                [hidden]="!isProductVariant1Editable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                                (click)="EditDetails()">Edit</button>&nbsp;&nbsp;
                                            <button id="m_login_signin_submit" *ngIf="!isEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <!-- <button type="button"
                                                *ngIf="!isEditable && (VariantLevel1obj.SeriesName == 'TC' || VariantLevel1obj.SeriesName == 'TR')"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                                [disabled]="SelectedStation.length == 0"
                                                (click)="DeleteStation()">Delete</button>&nbsp;&nbsp; -->
                                            <a [routerLink]="['/VariantLevel1',VariantLevel1obj?.ProductSeries,ProductSeriesVariantLabel]"
                                                routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>