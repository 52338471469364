<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <!-- BEGIN: Subheader -->
        <!-- <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title ">Enquiry</h3>
                </div>

            </div>
        </div> -->

        <!-- END: Subheader -->
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        {{FormStatus}} Guest
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm" appFocusInvalidInput
                            (ngSubmit)="myform.form.valid && onSubmit(myform)"
                            [mustMatch]="['password', 'confirmpassword']" novalidate>
                            <div class="m-portlet__body">

                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Username:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="username"
                                            [(ngModel)]="GuestDetails.Username" #username="ngModel"
                                            [ngClass]="{  'is-invalid':myform.submitted  && username.invalid || username?.touched && username.invalid}"
                                            placeholder="Enter Username" (keypress)="AvoidSpace($event)" minlength="3"
                                            pattern="^[^\n ]*$" maxlength="55" [disabled]="isEditable"
                                            (change)="GuestDetails.Username=GuestDetails.Username.trim()" required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && username.invalid || username?.touched && username.invalid">
                                            <div *ngIf="myform.hasError('required', 'username')">
                                                Username is required</div>
                                            <div  *ngIf="myform.hasError('minlength', 'username')">
                                                Username should be 3-55 characters</div>
                                            <div *ngIf="myform.hasError('AlreadyPresent', 'username')">
                                                Username already exists!
                                            </div>
                                            <div *ngIf="myform.hasError('pattern', 'username')">
                                                Username should not contain spaces</div>
                                        </div>
                                    </div>

                                    <div class="offset-1"></div>
                                    <label class="col-lg-2 col-form-label">Name:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="guestname"
                                            [(ngModel)]="GuestDetails.Name" #guestname="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && guestname.invalid || guestname?.touched && guestname.invalid}"
                                            placeholder="Enter Name" (keypress)="AvoidSpace($event)" minlength="3"
                                            maxlength="55" [disabled]="isEditable"
                                            (change)="GuestDetails.Name=GuestDetails.Name.trim()" required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && guestname.invalid || guestname?.touched && guestname.invalid">
                                            <div *ngIf="myform.hasError('required', 'guestname')">
                                                Guest name is required</div>
                                            <div  *ngIf="myform.hasError('minlength', 'guestname')">
                                                Guest name should be 3-55 characters</div>

                                        </div>
                                    </div>


                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2  col-form-label">Guest Id:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="guest_id"
                                            [(ngModel)]="GuestDetails.GuestId" #guest_id="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && guest_id.invalid || guest_id?.touched && guest_id.invalid}"
                                            placeholder="Enter Guest Id" (keypress)="AvoidSpace($event)" maxlength="50"
                                            [disabled]="isEditable"
                                            (change)="GuestDetails.GuestId=GuestDetails.GuestId.trim()" required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && guest_id.invalid || guest_id?.touched && guest_id.invalid">
                                            <div *ngIf="myform.hasError('required', 'guest_id')">
                                                Guest id is required</div>
                                            <div *ngIf="myform.hasError('AlreadyPresent', 'guest_id')">
                                                Guest id already exists!
                                            </div>
                                        </div>
                                    </div>
                                    <div class="offset-1"></div>
                                    <label class="col-lg-2 col-form-label">Email:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="email" maxlength="255"
                                            [(ngModel)]="GuestDetails.Email" #email="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && email.invalid || email?.touched && email.invalid}"
                                            placeholder="Enter Email" [pattern]="emailPattern"
                                            (keypress)="AvoidSpace($event)" [disabled]="isEditable"
                                            (change)="GuestDetails.Email=GuestDetails.Email.trim()" required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && email.invalid || email?.touched && email.invalid">
                                            <div *ngIf="myform.hasError('required', 'email')">
                                                Email is required</div>
                                            <div *ngIf="myform.hasError('pattern', 'email')">
                                                <!-- Should be in Email Id format (e.g. user@ecotech.com) -->
                                                Should be in Email Id format ({{mailIdFormat}})
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2  col-form-label">Contact Number:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="contactnumber"
                                            [(ngModel)]="GuestDetails.ContactNumber" #contactnumber="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && contactnumber.invalid || contactnumber?.touched && contactnumber.invalid}"
                                            placeholder="Enter Contact Number" minlength="10" maxlength="15"
                                            (keypress)="AvoidSpace($event);isNumberKey($event);" [disabled]="isEditable"
                                            pattern="[0-9]+"
                                            (change)="GuestDetails.ContactNumber=GuestDetails.ContactNumber.trim()"
                                            required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && contactnumber.invalid || contactnumber?.touched && contactnumber.invalid">
                                            <div *ngIf="myform.hasError('required', 'contactnumber')">
                                                Contact number is required</div>
                                            <div *ngIf="myform.hasError('pattern', 'contactnumber')">
                                                Please enter valid contact number</div>
                                            <div  *ngIf="myform.hasError('minlength', 'contactnumber')">
                                                Contact number should be 10-15 digits</div>
                                            <div *ngIf="myform.hasError('AlreadyPresent', 'contactnumber')">
                                                Contact number already exists!</div>
                                        </div>
                                    </div>
                                    <div class="offset-1"></div>
                                    <label class="col-lg-2 col-form-label">Address:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">

                                        <textarea class="form-control m-input" type="text" name="address"
                                            [(ngModel)]="GuestDetails.Address" #address="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && address.invalid || address?.touched && address.invalid}"
                                            placeholder="Enter Address" (keypress)="AvoidSpace($event)" required
                                            [disabled]="isEditable" maxlength="200"
                                            (change)="GuestDetails.Address=GuestDetails.Address.trim()"></textarea>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && address.invalid || address?.touched && address.invalid">
                                            <div *ngIf="myform.hasError('required', 'address')">
                                                Address is required</div>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Country:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">

                                        <select class="form-control " id="sel1" name="country"
                                            [(ngModel)]="GuestDetails.Country" #country="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && country.invalid || country?.touched && country.invalid}"
                                            [disabled]="isEditable" required>
                                            <option value="null" disabled>Select Country</option>
                                            <option *ngFor="let item of Country" value="{{item.Id}}">{{item.Name}}
                                            </option>

                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && country.invalid || country?.touched && country.invalid">
                                            <div *ngIf="myform.hasError('required', 'country')">
                                                Country is required</div>
                                        </div>
                                    </div>
                                    <div class="offset-1"></div>
                                    <label class="col-lg-2  col-form-label">Region:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel1" name="regionid"
                                            [(ngModel)]="GuestDetails.RegionForGuest" #regionid="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && regionid.invalid || regionid?.touched && regionid.invalid}"
                                            [disabled]="isEditable" required>
                                            <!-- <option value="GuestDetails.Region.lenght === 0" disabled>Select Region</option> -->
                                            <option value="null" disabled>Select Region</option>
                                            <option *ngFor="let item of Region" value="{{item.Id}}">{{item.Name}}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && regionid.invalid || regionid?.touched && regionid.invalid">
                                            <div *ngIf="myform.hasError('required', 'regionid')">
                                                Region is required</div>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Role:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel1" name="roleid"
                                            [(ngModel)]="GuestDetails.Role" #roleid="ngModel"
                                            [ngClass]="{  'is-invalid':myform.submitted  && roleid.invalid || roleid?.touched && roleid.invalid}"
                                            [disabled]="isEditable" required>
                                            <option value="null" disabled>Select Role</option>
                                            <option *ngFor="let item of Roles" value="{{item.Id}}">{{item.Name}}
                                            </option>

                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && roleid.invalid || roleid?.touched && roleid.invalid">
                                            <div *ngIf="myform.hasError('required', 'roleid')">
                                                Role is required</div>
                                        </div>
                                    </div>
                                    <div class="offset-1"></div>
                                    <label class="col-lg-2 col-form-label" *ngIf="!isEditable">New Password:</label>
                                    <div class="col-lg-3" *ngIf="!isEditable">
                                        <input class="form-control m-input" [type]="Passhide ? 'password': 'text'"
                                            name="password" [(ngModel)]="GuestDetails.Password" #password="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && password.invalid || password?.touched && password.invalid }"
                                            placeholder="Enter new password" [pattern]="passPattern" minlength="6"
                                            maxlength="30" (keypress)="AvoidSpace($event)"
                                            (change)="GuestDetails.Password=GuestDetails.Password.trim()"
                                            [disabled]="isEditable">
                                        <span class="p-viewer">
                                            <mat-icon matSuffix (click)="Passhide = !Passhide"
                                                style=" font-size: 20px;">{{Passhide ? 'visibility_off': 'visibility'}}
                                            </mat-icon>
                                        </span>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && password.invalid || password?.touched && password.invalid">
                                            <div *ngIf="myform.hasError('required', 'password')">
                                                Password is required</div>
                                            <div *ngIf="myform.hasError('pattern', 'password')">
                                                Password must contains minimum 6 alphanumeric with at least one
                                                uppercase, one lowercase and one special characters. (Ex. Pass@123)
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-group m-form__group row" *ngIf="!isEditable">
                                    <label class="col-lg-2  col-form-label">Confirm Password:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input"
                                            [type]="ConfirmPasshide ? 'password': 'text'" name="confirmpassword"
                                            [(ngModel)]="GuestDetails.ConfirmPassword" #confirmpassword="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && confirmpassword.invalid || confirmpassword?.touched && confirmpassword.invalid }"
                                            placeholder="Enter Confirm Password" (keypress)="AvoidSpace($event)"
                                            (change)="GuestDetails.ConfirmPassword=GuestDetails.ConfirmPassword.trim()"
                                            [disabled]="isEditable" [required]="GuestDetails.Password != ''">
                                        <span class="p-viewer">
                                            <mat-icon matSuffix (click)="ConfirmPasshide = !ConfirmPasshide"
                                                style=" font-size: 20px;">{{ConfirmPasshide ? 'visibility_off':
                                                'visibility'}}</mat-icon>
                                        </span>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && confirmpassword.invalid || confirmpassword?.touched && confirmpassword.invalid">
                                            <div *ngIf="myform.hasError('required', 'confirmpassword')">
                                                Confirm Password is required</div>
                                            <div *ngIf="myform.hasError('mustMatch', 'confirmpassword')">
                                                Confirm password must be same as password</div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit" *ngIf="isEditable"
                                                [hidden]="!isGuestEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                                (click)="EditDetails()">Edit</button>&nbsp;&nbsp;
                                            <button id="m_login_signin_submit" *ngIf="!isEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a [routerLink]="['/Guest']" routerLinkActive="active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>