import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/Service/common.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { StockInventoryModel } from 'src/app/Model/StockInventoryModel';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-add-stock-inventory',
  templateUrl: './add-stock-inventory.component.html',
  styleUrls: ['./add-stock-inventory.component.scss']
})
export class AddStockInventoryComponent implements OnInit {
  StockInventoryObj: StockInventoryModel = new StockInventoryModel();
  StockDatevar: Date = new Date();
  minDate: NgbDate;
  maxDate:NgbDate;
  currYear: number = new Date().getFullYear();
  StockFile: any[] = [];
  @Input()
  showLoader: boolean = false;
  tempfile: any;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  allowedFileType: string[] = [
    'image/bmp', 'image/jpeg', 'image/png', 'application/pdf', 'text/plain', 'text/csv', 'application/xml', 'text/xml',
    'application/msword', 'image/jpg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    , 'application/zip', 'application/x-zip-compressed', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/zip', 'application/x-zip-compressed', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
    
  constructor(private toastr: ToastrService, public dialog: MatDialog, private commonService: CommonService,
    private router: Router, private route: ActivatedRoute, private titleService: Title, private appConfigService : AppConfigService) {
      this.minDate = new NgbDate(2018, 1, 1);
      this.maxDate = new NgbDate(this.currYear,12,31);
  }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    // this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
  }

  getMultipleFileDetails(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];
      let isValidFile = true;
      if (((file.size) / 1000000) <= 5) {

        isValidFile = this.allowedFileType.includes(file.type);
        if (!isValidFile) {
          this.toastr.warning("Please attach the file having extension :\n\n .bmp, .jpeg, .jpg, .png, .pdf, .xml, .txt, .doc, .docx, .xls, .xlsx, .zip, .ppt, .pptx");
          //event.target.value = null;
          isValidFile = false;
        } else if (file.name.length > 50) {
          this.toastr.warning("The file name with more than 50 characters are not allowed.");
          isValidFile = false;
        }
      }
      else {
        this.toastr.warning("File size must be less than or equal to 5MB!");
        //event.target.value = null;
        isValidFile = false;
      }
      if (isValidFile) {
        this.StockFile.push(file);
      }
    }
    console.log(this.myInputVariable.nativeElement.files);
  }

  removeAttachments(index) {
    this.myInputVariable.nativeElement.value = "";
    this.StockFile.splice(index, 1);
  }

  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }

  onSubmit(form: any) {
    if (this.StockFile.length == 0 || this.StockFile == null) {
      form.controls['stockfiles'].setErrors(true);
    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to add this stock inventory details?"
        }
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.showLoader = true;
          const frmData = new FormData();
          if (this.StockInventoryObj.StockDate != undefined) {
            this.StockInventoryObj.StockDate = new Date(this.convertDate(new NgbDate(this.StockInventoryObj.StockDate.year, this.StockInventoryObj.StockDate.month, this.StockInventoryObj.StockDate.day))).toDateString();
            this.StockInventoryObj.StockDateISO = new Date(this.convertDate(new NgbDate(this.StockInventoryObj.StockDate.year, this.StockInventoryObj.StockDate.month, this.StockInventoryObj.StockDate.day))).toDateString();
          }
          let StockInventoryObj = JSON.stringify(this.StockInventoryObj);
          frmData.append("StockInventory", StockInventoryObj);

          if (this.StockFile != null) {
            for (let i = 0; i < this.StockFile.length; i++) {
              frmData.append("StockAttachments[" + i + "]", this.StockFile[i]);
            }
          }
          this.commonService.PostData('StockInventory', 'Add', frmData, null).subscribe((data: any) => {

            if (data.ResponseCode == 1) {
              this.toastr.success("Stock inventory details added successfully!");
              this.router.navigate(['/StockInventory']);
              this.showLoader = false;
            }
            else {
              this.toastr.error("Failed to stock inventory details!");
              this.router.navigate(['/StockInventory']);
              this.showLoader = false;
            }


          }, error => {
            this.toastr.error("Failed to add stock inventory details!");
            this.showLoader = false;
            this.router.navigate(['/StockInventory']);
          });

        }
      });
    }
  }

}


