import { Component, OnInit, Input } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductFamily } from 'src/app/Model/ProductModel';
import { Title } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/Service/app-config.service';


@Component({
  selector: 'app-add-product-family',
  templateUrl: './add-product-family.component.html',
  styleUrls: ['./add-product-family.component.scss']
})
export class AddProductFamilyComponent implements OnInit {
  ProductFamilyobj: ProductFamily = new ProductFamily();
  charCode: any;
  @Input()
  showLoader: boolean = false;
  ProductLine: any[] = [];
  constructor(private router: Router, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title, private route: ActivatedRoute,
    private appConfigService : AppConfigService) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    //this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.getAllProductLine();
  }

  /*******************************************************************************
   * Get data for add product family
   * ******************************************************************************/
  getAllProductLine() {
    this.showLoader = true;
    this.CommonService.GetData("ProductLine", "GetAll", null).subscribe((data: any) => {
      this.ProductLine = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  /*******************************************************************************
   * End get data for add product family
   * ******************************************************************************/

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this product family?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;
        this.CommonService.PostData('ProductFamily', 'Add', this.ProductFamilyobj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('Product family added successfully!');
            this.router.navigate(['/ProductFamily']);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('Product family already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to add product family!');
            this.router.navigate(['/ProductFamily']);
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add product family!');
          this.showLoader = false;
          this.router.navigate(['/ProductFamily']);
        });
      }
    });

  }

}
