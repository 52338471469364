export class ReportFilterViewModel {
    EnqType:string="";
    ReportValueType:string="";
    Year:any;
    Region:any = 0;
    ProductLine:any = 0;
    ProductSeries:any = 0;
    Productmodal:any = 0;
    CustomerClass:any = 0;
    CustomerType:any = 0;
    Segment:any = 0;
    reporttype:any;
    curency:number;
    CustomerId:number = 0;
    CustomerName: string = "";
    currencyLable:string = "";
    currencycode : string = "";
}