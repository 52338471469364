import { Component, OnInit, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-avg-discount-month',
  templateUrl: './avg-discount-month.component.html',
  styleUrls: ['./avg-discount-month.component.scss']
})
export class AvgDiscountMonthComponent implements OnInit {

  @Input()
  showLoader: boolean = false;
  @Input() Year: number;
  drpdnOption: string = "Amount";
  constructor(private toastr: ToastrService, private commonService: CommonService) { }

  ngOnInit() {
   // am4core.options.onlyShowOnViewport = true;
    this.avgDiscountMonth();

  }

  ngOnChanges(): void {
    this.avgDiscountMonth();
  }

  OnSelectChange() {
    this.avgDiscountMonth();
  }

  avgDiscountMonth() {
    this.showLoader = true;
    let queryString = "";
    queryString += "drpdnOption=" + this.drpdnOption;
    queryString += "&Year=" + this.Year;

    this.commonService.GetData("Dashboard", "AvgDiscountMonthChart", queryString).subscribe((data: any) => {
      let result = data;

      let chart = am4core.create("avgDiscountMonth", am4charts.XYChart);
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      // chart.cursor = new am4charts.XYCursor();
      chart.scrollbarX.exportable = false;
      chart.scrollbarY.exportable = false;
      // Export
      chart.exporting.menu = new am4core.ExportMenu();

      let title = chart.titles.create();
      title.text = "Avg Discount/Month";
      title.fontSize = 15;
      title.fontWeight = "bold";
      title.marginBottom = 30;

      chart.data = result;

      /* Create axes */
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.minGridDistance = 30;

      /* Create value axis */
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      valueAxis.min = 0;
      valueAxis.extraMax = 0.5;

      valueAxis.title.fontWeight = "bold";
      valueAxis.title.fontSize = "16px";

      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.name = "Sales Engineer/Agent Sales";
      lineSeries.dataFields.valueY = "value";
      lineSeries.dataFields.categoryX = "category";
      lineSeries.tooltip.getFillFromObject = false;
      lineSeries.tooltip.background.fill = am4core.color("#4a463c");
      //lineSeries.stroke = am4core.color("#FCD202");

      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#4a463c"); // tooltips grab fill from parent by default
      bullet.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]";

      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 4;
      circle.fill = am4core.color("#fff");
      circle.strokeWidth = 3;

      let labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.label.verticalCenter = "bottom";

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to add enquiry details!");
      this.showLoader = false;

    });
  }

  avgDiscountMonth_old() {
    this.showLoader = true;
    // Create chart instance
    let chart = am4core.create("avgDiscountMonth", am4charts.XYChart);

    // Export
    chart.exporting.menu = new am4core.ExportMenu();

    let title = chart.titles.create();
    title.text = "Avg Discount/Month";
    title.fontSize = 15;
    title.fontWeight = "bold";
    title.marginBottom = 30;

    chart.data = [{
      "category": "Jan",
      "value": 4
    },
    {
      "category": "Feb",
      "value": 5
    },
    {
      "category": "Mar",
      "value": 6
    },
    {
      "category": "Apr",
      "value": 7
    },
    {
      "category": "May",
      "value": 8
    },
    {
      "category": "Jun",
      "value": 9
    },
    {
      "category": "Jul",
      "value": 10
    },
    {
      "category": "Aug",
      "value": 11
    },
    {
      "category": "Sep",
      "value": 6
    },
    {
      "category": "Oct",
      "value": 5
    },
    {
      "category": "Nov",
      "value": 7
    },
    {
      "category": "Dec",
      "value": 8
    }];

    /* Create axes */
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.minGridDistance = 30;

    /* Create value axis */
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    valueAxis.title.fontWeight = "bold";
    valueAxis.title.fontSize = "16px";

    let lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.name = "Sales Engineer/Agent Sales";
    lineSeries.dataFields.valueY = "value";
    lineSeries.dataFields.categoryX = "category";
    lineSeries.tooltip.getFillFromObject = false;
    lineSeries.tooltip.background.fill = am4core.color("#4a463c");
    //lineSeries.stroke = am4core.color("#FCD202");

    let bullet = lineSeries.bullets.push(new am4charts.Bullet());
    bullet.fill = am4core.color("#4a463c"); // tooltips grab fill from parent by default
    bullet.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]";

    let circle = bullet.createChild(am4core.Circle);
    circle.radius = 4;
    circle.fill = am4core.color("#fff");
    circle.strokeWidth = 3;

    let labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.label.verticalCenter = "bottom";

    this.showLoader = false;

  }
}
