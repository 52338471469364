import { Injectable } from '@angular/core';
import { AppConfig } from 'src/assets/constants';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor() { }

  private config = AppConfig;

  getConfig() {
    return this.config;
  }
}
