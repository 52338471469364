import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { SingleFieldModel, SingleFieldComponent } from 'src/app/Model/SingleFieldModel';
import { Sort } from '@angular/material/sort';
import { UpdateStatus } from 'src/app/Model/CommonModel';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { ViewChild } from '@angular/core';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { number } from '@amcharts/amcharts4/core';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';


@Component({
  selector: 'app-configuration-details',
  templateUrl: './configuration-details.component.html',
  styleUrls: ['./configuration-details.component.scss']
})
export class ConfigurationDetailsComponent implements OnInit {

  displayedColumns: string[] = ['Name', 'Value', 'Action'];
  dataSource: MatTableDataSource<SingleFieldModel>;
  disabled: boolean = false;
  singleFieldComponent: SingleFieldComponent = new SingleFieldComponent();
  UserRole: any;
  isConfigureEditable: boolean = false;
  RouteName : string ='';
  someCondition : boolean = false;
  isReasonCategoryVisible : boolean = false;
  viewedit:boolean=true;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];
  appConfig : any;
  /*************************************************************************
        Server side Pagination purpose
    *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';
  ColumnName: string = 'ModifiedOn';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /*************************************************************************
     End here
  *************************************************************************/

  @Input()
  showLoader: boolean = false;
  data: any[] = [];
  UpdateStatus: UpdateStatus = new UpdateStatus();
  configurationArray: string[] = ["Country", "Customer Category", "Customer Classification", "Enquiry Source",
    "Industry Segment", "Sub Segment", "MDS Option", "Region", "Sales Price Discount", "Type of Customer", "Estimated Product Value",
    "Customer Group", "Technical Discard", "Application",
    "EnquiryCloseReason","LeadCloseReason","CustomerSpecialDiscount","AddLoginImage"];
  constructor(private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog,
    private route: ActivatedRoute, private router: Router, private titleService: Title,private accessservice: AccessPermissionService,
    private appConfigService: AppConfigService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.appConfig = this.appConfigService.getConfig();
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
   // this.isConfigureEditable = AppGlobals.ConfigureMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.singleFieldComponent.RoutingParam = this.route.snapshot.params["heading"];

    this.OnSelect(this.singleFieldComponent.RoutingParam)
   
    if (this.singleFieldComponent.RoutingParam == "" || this.singleFieldComponent.RoutingParam == undefined) {
      this.singleFieldComponent.Heading = "Country";
      this.singleFieldComponent.RoutingParam = "Country";
      this.singleFieldComponent.ButtonText = "Add Country";
      this.singleFieldComponent.ConfirmationText = "country";
      this.singleFieldComponent.ControllerName = "Country";
      this.singleFieldComponent.ActionName = "GetAllPagination";
      this.singleFieldComponent.SuccessText = "Country";
      this.singleFieldComponent.firstFiledLabel = "Country";
      this.singleFieldComponent.secondFiledLabel = "Country Code";
      this.singleFieldComponent.TableHeader1 = "Country";
      this.singleFieldComponent.TableHeader2 = "Country Code";
      this.singleFieldComponent.isDoubleField = true;
    
      this.GetAll();

    }
    else {
      switch (this.singleFieldComponent.RoutingParam) {
        case "MDSOption":
          this.singleFieldComponent.Heading = "MDS Option";
          this.singleFieldComponent.RoutingParam = "MDSOption";
          this.singleFieldComponent.ButtonText = "Add MDS Option";
          this.singleFieldComponent.ConfirmationText = "MDS option";
          this.singleFieldComponent.ControllerName = "MDSOptions";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "MDS option";
          this.singleFieldComponent.firstFiledLabel = "MDS Option";
          this.singleFieldComponent.TableHeader1 = "MDS Option";
          this.singleFieldComponent.isDoubleField = false;
          this.GetAll();
          break;
        case "SalesPrice":
          this.singleFieldComponent.Heading = " Sales Price Discount";
          this.singleFieldComponent.RoutingParam = "SalesPrice";
          this.singleFieldComponent.ButtonText = "Add Sales Price Discount";
          this.singleFieldComponent.ConfirmationText = "Sales Price Discount";
          this.singleFieldComponent.ControllerName = "SalesPrice";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Sales Price Discount";
          this.singleFieldComponent.firstFiledLabel = "Sales Price ";
          this.singleFieldComponent.TableHeader1 = "Sales Price"; 
          this.singleFieldComponent.TableHeader2 = "Discount % (From - To)";
          this.singleFieldComponent.isDoubleField = true;
          this.singleFieldComponent.isExtraFields=true;
          this.GetAll();
          break;
        case "Country":
          this.singleFieldComponent.Heading = "Country";
          this.singleFieldComponent.RoutingParam = "Country";
          this.singleFieldComponent.ButtonText = "Add Country";
          this.singleFieldComponent.ConfirmationText = "country";
          this.singleFieldComponent.ControllerName = "Country";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Country";
          this.singleFieldComponent.firstFiledLabel = "Country";
          this.singleFieldComponent.secondFiledLabel = "Country Code";
          this.singleFieldComponent.TableHeader1 = "Country";
          this.singleFieldComponent.TableHeader2 = "Country Code";
          this.singleFieldComponent.isDoubleField = true;
          this.GetAll();
          break;

        case "CustomerCategory":
          this.singleFieldComponent.Heading = "Customer Category";
          this.singleFieldComponent.RoutingParam = "CustomerCategory";
          this.singleFieldComponent.ButtonText = "Add Customer Category";
          this.singleFieldComponent.ConfirmationText = "customer category";
          this.singleFieldComponent.ControllerName = "CustomerCategory";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Customer category";
          this.singleFieldComponent.firstFiledLabel = "Customer Category";
          this.singleFieldComponent.TableHeader1 = "Customer Category";
          this.singleFieldComponent.isDoubleField = false;
          this.GetAll();
          break;
        case "CustomerClassification":
          this.singleFieldComponent.Heading = "Customer Classification";
          this.singleFieldComponent.RoutingParam = "CustomerClassification";
          this.singleFieldComponent.ButtonText = "Add Customer Classification";
          this.singleFieldComponent.ConfirmationText = "customer classification";
          this.singleFieldComponent.ControllerName = "CustomerClassification";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Customer classification";
          this.singleFieldComponent.firstFiledLabel = "Customer Classification";
          this.singleFieldComponent.TableHeader1 = "Customer Classification";
          this.singleFieldComponent.isDoubleField = false;
          this.GetAll();
          break;
        case "EnquirySource":
          this.singleFieldComponent.Heading = "Enquiry Source";
          this.singleFieldComponent.RoutingParam = "EnquirySource";
          this.singleFieldComponent.ButtonText = "Add Enquiry Source";
          this.singleFieldComponent.ConfirmationText = "enquiry source";
          this.singleFieldComponent.ControllerName = "EnquirySource";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Enquiry source";
          this.singleFieldComponent.firstFiledLabel = "Enquiry Source";
          this.singleFieldComponent.TableHeader1 = "Enquiry Source";
          this.singleFieldComponent.isDoubleField = false;
          this.GetAll();
          break;
        case "IndustrySegment":
          this.singleFieldComponent.Heading = "Industry Segment";
          this.singleFieldComponent.RoutingParam = "IndustrySegment";
          this.singleFieldComponent.ButtonText = "Add Industry Segment";
          this.singleFieldComponent.ConfirmationText = "industry segment";
          this.singleFieldComponent.ControllerName = "IndustrySegment";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Industry segment";
          this.singleFieldComponent.firstFiledLabel = "Industry Segment";
          this.singleFieldComponent.TableHeader1 = "Industry Segment";
          this.singleFieldComponent.isDoubleField = false;
          this.GetAll();
          break;
        case "SubSegment":
          this.singleFieldComponent.Heading = "Sub Segment";
          this.singleFieldComponent.RoutingParam = "SubSegment";
          this.singleFieldComponent.ButtonText = "Add Sub Segment";
          this.singleFieldComponent.ConfirmationText = "sub segment";
          this.singleFieldComponent.ControllerName = "SubSegment";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Sub segment";
          this.singleFieldComponent.firstFiledLabel = "Industry Segment";
          this.singleFieldComponent.secondFiledLabel = "Sub Segment";
          this.singleFieldComponent.TableHeader1 = "Industry Segment";
          this.singleFieldComponent.TableHeader2 = "Sub Segment";
          this.singleFieldComponent.isDoubleField = true;
          this.GetAll();
          break;
        case "Region":
          this.singleFieldComponent.Heading = "Region";
          this.singleFieldComponent.RoutingParam = "Region";
          this.singleFieldComponent.ButtonText = "Add Region";
          this.singleFieldComponent.ConfirmationText = "region";
          this.singleFieldComponent.ControllerName = "Region";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Region";
          this.singleFieldComponent.firstFiledLabel = "Region";
          this.singleFieldComponent.secondFiledLabel = "Region Code";
          this.singleFieldComponent.TableHeader1 = "Region";
          this.singleFieldComponent.TableHeader2 = "Region Code";
          this.singleFieldComponent.isDoubleField = true;
          this.GetAll();
          break;
        case "TypeofCustomer":
          this.singleFieldComponent.Heading = "Type of Customer";
          this.singleFieldComponent.RoutingParam = "TypeofCustomer";
          this.singleFieldComponent.ButtonText = "Add Type of Customer";
          this.singleFieldComponent.ConfirmationText = "type of customer";
          this.singleFieldComponent.ControllerName = "CustomerType";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Type of customer";
          this.singleFieldComponent.firstFiledLabel = "Type of Customer";
          this.singleFieldComponent.TableHeader1 = "Type of Customer";
          this.singleFieldComponent.isDoubleField = false;
          this.GetAll();
          break;
        case "EstimatedProductValue":
          this.singleFieldComponent.Heading = "Estimated Product Value";
          this.singleFieldComponent.RoutingParam = "EstimatedProductValue";
          this.singleFieldComponent.ButtonText = "Add Estimated Product Value";
          this.singleFieldComponent.ConfirmationText = "estimated product value";
          this.singleFieldComponent.ControllerName = "EstimatedProductValue";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Estimated product value";
          this.singleFieldComponent.firstFiledLabel = "Estimated Product Value";
          this.singleFieldComponent.TableHeader1 = "Estimated Product Value";
          this.singleFieldComponent.isDoubleField = true;
          this.singleFieldComponent.TableHeader2 = "Role";
          this.GetAll();
          break;
        case "Currency":
          this.singleFieldComponent.Heading = "Currency";
          this.singleFieldComponent.RoutingParam = "Currency";
          this.singleFieldComponent.ButtonText = "Add Currency";
          this.singleFieldComponent.ConfirmationText = "currency";
          this.singleFieldComponent.ControllerName = "Currency";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Currency";
          this.singleFieldComponent.firstFiledLabel = "Currency";
          this.singleFieldComponent.secondFiledLabel = "Exchange Rate (€)";
          this.singleFieldComponent.TableHeader1 = "Currency";
          this.singleFieldComponent.TableHeader2 = "Exchange Rate (€)";
          this.singleFieldComponent.isDoubleField = true;
          this.GetAll();
          break;
        case "TermsConditions":
          this.singleFieldComponent.Heading = "Terms & Conditions";
          this.singleFieldComponent.RoutingParam = "TermsConditions";
          this.singleFieldComponent.ButtonText = "Add Terms & Conditions";
          this.singleFieldComponent.ConfirmationText = "terms & conditions";
          this.singleFieldComponent.ControllerName = "TermsAndConditions";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Terms & conditions";
          this.singleFieldComponent.firstFiledLabel = "Terms & Condition";
          this.singleFieldComponent.secondFiledLabel = "Standard Value";
          this.singleFieldComponent.TableHeader1 = "Terms & Conditions";
          this.singleFieldComponent.TableHeader2 = "Standard Values";
          this.singleFieldComponent.isDoubleField = true;
          this.GetAll();
          break;
        case "StandardReasonforWinningLosing":
          this.singleFieldComponent.Heading = "Standard Reason";
          this.singleFieldComponent.RoutingParam = "StandardReasonforWinningLosing";
          this.singleFieldComponent.ButtonText = "Add Standard Reason";
          this.singleFieldComponent.ConfirmationText = "standard reason for winning/losing";
          this.singleFieldComponent.ControllerName = "ReasonEnquiryWinLoss";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Standard reason for winning/losing";
          this.singleFieldComponent.firstFiledLabel = "Enquiry Status";
          this.singleFieldComponent.secondFiledLabel = "Reason for Winning/Losing";
          this.singleFieldComponent.thirdFieldLabel = "Reason Category";
          this.singleFieldComponent.TableHeader1 = "Enquiry Status";
          this.singleFieldComponent.TableHeader2 = "Reason for Winning/Losing";
          this.singleFieldComponent.TableHeader3 = "Reason Category";
          this.singleFieldComponent.isDoubleField = true;
          this.singleFieldComponent.isTripleField = true; 
          this.GetAll();
          break;
        case "EnquiryCompetitor":
          this.singleFieldComponent.Heading = "Enquiry Competitor";
          this.singleFieldComponent.RoutingParam = "EnquiryCompetitor";
          this.singleFieldComponent.ButtonText = "Add Enquiry Competitor";
          this.singleFieldComponent.ConfirmationText = "Enquiry Competitor";
          this.singleFieldComponent.ControllerName = "EnquiryCompetitor";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Enquiry Competitor";
          this.singleFieldComponent.firstFiledLabel = "Enquiry Competitor";
          this.singleFieldComponent.TableHeader1 = "Enquiry Competitor";
          this.singleFieldComponent.isDoubleField = false;
          this.GetAll();
          break;

          case "CustomerGroup":
            this.singleFieldComponent.Heading = "Customer Group";
            this.singleFieldComponent.RoutingParam = "CustomerGroup";
            this.singleFieldComponent.ButtonText = "Add Customer Group";
            this.singleFieldComponent.ConfirmationText = "customer group";
            this.singleFieldComponent.ControllerName = "CustomerGroup";
            this.singleFieldComponent.ActionName = "GetAllPagination";
            this.singleFieldComponent.SuccessText = "Customer group";
            this.singleFieldComponent.firstFiledLabel = "Customer Group";
            this.singleFieldComponent.secondFiledLabel = "Customer Group Code";
            this.singleFieldComponent.TableHeader1 = "Customer Group ";
            this.singleFieldComponent.TableHeader2 = "Customer Group Code";
            this.singleFieldComponent.isDoubleField = true;
            this.GetAll();
            break;
  
          case "TechnicalDiscardReason":
            this.singleFieldComponent.Heading = "Technical Discard Reason";
            this.singleFieldComponent.RoutingParam = "TechnicalDiscardReason";
            this.singleFieldComponent.ButtonText = "Add Technical Discard Reason";
            this.singleFieldComponent.ConfirmationText = "Technical discard reason";
            this.singleFieldComponent.ControllerName = "TechnicalDiscard";
            this.singleFieldComponent.ActionName = "GetAllPagination";
            this.singleFieldComponent.SuccessText = "Technical discard reason";
            this.singleFieldComponent.firstFiledLabel = "Technical Discard Reason";
            this.singleFieldComponent.TableHeader1 = "Technical Discard Reason";
            this.singleFieldComponent.isDoubleField = false;
            this.GetAll();
            break;
  
          case "TermsConditions-NonStandard":
            this.singleFieldComponent.Heading = "Terms & Conditions-Non Standard";
            this.singleFieldComponent.RoutingParam = "TermsConditions-NonStandard";
            this.singleFieldComponent.ButtonText = "Add Non Standard Terms & Conditions";
            this.singleFieldComponent.ConfirmationText = "non standard terms & conditions";
            this.singleFieldComponent.ControllerName = "TermsAndConditionsCustomized";
            this.singleFieldComponent.ActionName = "GetAllPagination";
            this.singleFieldComponent.SuccessText = "Terms & conditions-Non Standard";
            this.singleFieldComponent.firstFiledLabel = "Terms & Condition-Non Standard";
            this.singleFieldComponent.secondFiledLabel = "Non Standard Value";
            this.singleFieldComponent.TableHeader1 = "Terms & Conditions-Non Standard";
            this.singleFieldComponent.TableHeader2 = "Non Standard Values";
            this.singleFieldComponent.isDoubleField = true;
            this.GetAll();
            break;
          case "Application":
            this.singleFieldComponent.Heading = "Application";
            this.singleFieldComponent.RoutingParam = "Application";
            this.singleFieldComponent.ButtonText = "Add Application";
            this.singleFieldComponent.ConfirmationText = "Application";
            this.singleFieldComponent.ControllerName = "Application";
            this.singleFieldComponent.ActionName = "GetAllPagination";
            this.singleFieldComponent.SuccessText = "Application";
            this.singleFieldComponent.firstFiledLabel = "Industry Segment";
            this.singleFieldComponent.secondFiledLabel = "Sub Segment";
            this.singleFieldComponent.thirdFieldLabel = "Application Name";
            this.singleFieldComponent.TableHeader1 = "Industry Segment";
            this.singleFieldComponent.TableHeader2 = "Sub Segment";
            this.singleFieldComponent.TableHeader3 = "Application";
            this.singleFieldComponent.isDoubleField = true;
            this.singleFieldComponent.isTripleField = true;
            this.GetAll();
            break;
          case "EnquiryCloseReason":
          this.singleFieldComponent.Heading = "Enquiry Close Reason";
          this.singleFieldComponent.RoutingParam = "EnquiryCloseReason";
          this.singleFieldComponent.ButtonText = "Add Enquiry Close Reason";
          this.singleFieldComponent.ConfirmationText = "Enquiry Close Reason";
          this.singleFieldComponent.ControllerName = "EnquiryCloseReason";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Enquiry Close Reason";
          this.singleFieldComponent.firstFiledLabel = "Enquiry Close Reason";
          this.singleFieldComponent.TableHeader1 = "Enquiry Close Reason";
          this.singleFieldComponent.isDoubleField = false;
          this.GetAll();
          break;

            case "LeadCloseReason":
            this.singleFieldComponent.Heading = "Lead Close Reason";
            this.singleFieldComponent.RoutingParam = "LeadCloseReason";
            this.singleFieldComponent.ButtonText = "Add Lead Close Reason";
            this.singleFieldComponent.ConfirmationText = "Lead close reason";
            this.singleFieldComponent.ControllerName = "OpportunityCloseReason";
            this.singleFieldComponent.ActionName = "GetAllPagination";
            this.singleFieldComponent.SuccessText = "Lead close reason";
            this.singleFieldComponent.firstFiledLabel = "Lead Close Reason";
            this.singleFieldComponent.TableHeader1 = "Lead Close Reason";
            this.singleFieldComponent.isDoubleField = false;
            this.GetAll();
            break;
            
            // case "CustomerSpecialDiscount":
            // this.singleFieldComponent.Heading = "Customer Special Discount";
            // this.singleFieldComponent.RoutingParam = "CustomerSpecialDiscount";
            // this.singleFieldComponent.ButtonText = "Add Customer Special Discount";
            // this.singleFieldComponent.ConfirmationText = "Customer Special Discount";
            // this.singleFieldComponent.ControllerName = "CustomerSpecialDiscount";
            // this.singleFieldComponent.ActionName = "GetAllPagination";
            // this.singleFieldComponent.SuccessText = "Customer Special Discount";
            // this.singleFieldComponent.firstFiledLabel = "Customer Special Discount";
            // this.singleFieldComponent.TableHeader1 = "Customer Name";
            // this.singleFieldComponent.isCompanynamerequired = true;
            // this.singleFieldComponent.isDoubleField = false;
            // this.GetAll();
            // break;

            case "CustomerSpecialDiscount":
              this.singleFieldComponent.Heading = "Customer Frame Agreement";
              this.singleFieldComponent.RoutingParam = "CustomerSpecialDiscount";
              this.singleFieldComponent.ButtonText = "Add Customer Frame Agreement";
              this.singleFieldComponent.ConfirmationText = "Customer Frame Agreement";
              this.singleFieldComponent.ControllerName = "CustomerSpecialDiscount";
              this.singleFieldComponent.ActionName = "GetAllPagination";
              this.singleFieldComponent.SuccessText = "Customer Frame Agreement";
              this.singleFieldComponent.firstFiledLabel = "Customer Frame Agreement";
              this.singleFieldComponent.TableHeader1 = "Customer Name";
              this.singleFieldComponent.isCompanynamerequired = true;
              this.singleFieldComponent.secondFiledLabel = "WorkFlow Status";
             
              this.singleFieldComponent.TableHeader2 = "WorkFlow Status";
              this.singleFieldComponent.isDoubleField = true;
              this.GetAll();
              break;

          case "RoleManagement":
          this.singleFieldComponent.Heading = "Role Management";
          this.singleFieldComponent.RoutingParam = "RoleManagement";
          this.singleFieldComponent.ButtonText = "Add Role";
          this.singleFieldComponent.ConfirmationText = "Role Management";
          this.singleFieldComponent.ControllerName = "Account";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Role Management";
          this.singleFieldComponent.firstFiledLabel = "Role Management";
          this.singleFieldComponent.TableHeader1 = "Role Management";
          this.singleFieldComponent.isDoubleField = false;
          this.GetAll();
          break;
          case "AddLoginImage":
                this.singleFieldComponent.Heading="LoginPageImage";
                this.singleFieldComponent.RoutingParam="AddLoginImage";
                this.singleFieldComponent.ButtonText = "Add LoginPageImage";
                this.singleFieldComponent.ConfirmationText = "LoginPageImage ";
                this.singleFieldComponent.ControllerName = "LoginPageImage";
                this.singleFieldComponent.ActionName = "GetAllPagination";
                this.singleFieldComponent.SuccessText = "";
                this.singleFieldComponent.firstFiledLabel = "LoginPageImage";
                this.singleFieldComponent.TableHeader1 = "LoginPageImage";
                this.singleFieldComponent.isDoubleField = false;
                this.GetAll();
                break;


      }
      this.showLoader = false;


    }
    this.titleService.setTitle(this.singleFieldComponent.Heading + " - " + this.appConfig.titleName);
    //this.titleService.setTitle(this.singleFieldComponent.Heading + " - ECOTECH");
  }



  /*************************************************************************
   * Below function used for Server side Pagination,Sorting and Searching
   * (1)GetAll() - To get data for grid
   * (2)SearchParam() -  searching
   * (3)sortData() - Sorting
   * (4)pageEvent() - Pagination
   * 
   * ***********************************************************************/
  MasterChange() {
    this.showLoader = true;
    this.singleFieldComponent.isCompanynamerequired = false;
    this.pageNumber = 1;
    this.pageSize = 5;
    this.paginator.pageIndex = 0;
    this.length = 0;
    this.SearchText = "";
    this.direction = 'desc';
    this.ColumnName = 'ModifiedOn';

   
    this.OnSelect(this.singleFieldComponent.RoutingParam);
    this.singleFieldComponent.isExtraFields=false;
    
    switch (this.singleFieldComponent.RoutingParam) {
      case "MDSOption":
        this.singleFieldComponent.Heading = "MDS Option";
        this.singleFieldComponent.RoutingParam = "MDSOption";
        this.singleFieldComponent.ButtonText = "Add MDS Option";
        this.singleFieldComponent.ConfirmationText = "MDS option";
        this.singleFieldComponent.ControllerName = "MDSOptions";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "MDS option";
        this.singleFieldComponent.firstFiledLabel = "MDS Option";
        this.singleFieldComponent.TableHeader1 = "MDS Option";
        this.singleFieldComponent.isDoubleField = false;
        this.GetAll();
        break;
      case "SalesPrice":
        this.singleFieldComponent.Heading = "Sales Price Discount";
        this.singleFieldComponent.RoutingParam = "SalesPrice";
        this.singleFieldComponent.ButtonText = "Add Sales Price Discount";
        this.singleFieldComponent.ConfirmationText = "sales price Discount";
        this.singleFieldComponent.ControllerName = "SalesPrice";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Sales price Discount";
        this.singleFieldComponent.firstFiledLabel = "Sales Price";
        this.singleFieldComponent.TableHeader1 = "Sales Price";
        this.singleFieldComponent.secondFiledLabel = "From";
        this.singleFieldComponent.TableHeader2 = "Discount % (From - To)";
      
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isExtraFields=true;
        this.GetAll();
        break;
      case "Country":
        this.singleFieldComponent.Heading = "Country";
        this.singleFieldComponent.RoutingParam = "Country";
        this.singleFieldComponent.ButtonText = "Add Country";
        this.singleFieldComponent.ConfirmationText = "country";
        this.singleFieldComponent.ControllerName = "Country";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Country";
        this.singleFieldComponent.firstFiledLabel = "Country";
        this.singleFieldComponent.secondFiledLabel = "Country Code";
        this.singleFieldComponent.TableHeader1 = "Country";
        this.singleFieldComponent.TableHeader2 = "Country Code";
        this.singleFieldComponent.isDoubleField = true;
        this.GetAll();
        break;

      case "CustomerCategory":
        this.singleFieldComponent.Heading = "Customer Category";
        this.singleFieldComponent.RoutingParam = "CustomerCategory";
        this.singleFieldComponent.ButtonText = "Add Customer Category";
        this.singleFieldComponent.ConfirmationText = "customer category";
        this.singleFieldComponent.ControllerName = "CustomerCategory";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Customer category";
        this.singleFieldComponent.firstFiledLabel = "Customer Category";
        this.singleFieldComponent.TableHeader1 = "Customer Category";
        this.singleFieldComponent.isDoubleField = false;
        this.GetAll();
        break;
      case "CustomerClassification":
        this.singleFieldComponent.Heading = "Customer Classification";
        this.singleFieldComponent.RoutingParam = "CustomerClassification";
        this.singleFieldComponent.ButtonText = "Add Customer Classification";
        this.singleFieldComponent.ConfirmationText = "customer classification";
        this.singleFieldComponent.ControllerName = "CustomerClassification";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Customer classification";
        this.singleFieldComponent.firstFiledLabel = "Customer Classification";
        this.singleFieldComponent.TableHeader1 = "Customer Classification";
        this.singleFieldComponent.isDoubleField = false;
        this.GetAll();
        break;
      case "EnquirySource":
        this.singleFieldComponent.Heading = "Enquiry Source";
        this.singleFieldComponent.RoutingParam = "EnquirySource";
        this.singleFieldComponent.ButtonText = "Add Enquiry Source";
        this.singleFieldComponent.ConfirmationText = "enquiry source";
        this.singleFieldComponent.ControllerName = "EnquirySource";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Enquiry source";
        this.singleFieldComponent.firstFiledLabel = "Enquiry Source";
        this.singleFieldComponent.TableHeader1 = "Enquiry Source";
        this.singleFieldComponent.isDoubleField = false;
        this.GetAll();
        break;
      case "IndustrySegment":
        this.singleFieldComponent.Heading = "Industry Segment";
        this.singleFieldComponent.RoutingParam = "IndustrySegment";
        this.singleFieldComponent.ButtonText = "Add Industry Segment";
        this.singleFieldComponent.ConfirmationText = "industry segment";
        this.singleFieldComponent.ControllerName = "IndustrySegment";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Industry segment";
        this.singleFieldComponent.firstFiledLabel = "Industry Segment";
        this.singleFieldComponent.TableHeader1 = "Industry Segment";
        this.singleFieldComponent.isDoubleField = false;
        this.GetAll();
        break;
      case "SubSegment":
        this.singleFieldComponent.Heading = "Sub Segment";
        this.singleFieldComponent.RoutingParam = "SubSegment";
        this.singleFieldComponent.ButtonText = "Add Sub Segment";
        this.singleFieldComponent.ConfirmationText = "sub segment";
        this.singleFieldComponent.ControllerName = "SubSegment";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Sub segment";
        this.singleFieldComponent.firstFiledLabel = "Industry Segment";
        this.singleFieldComponent.secondFiledLabel = "Sub Segment";
        this.singleFieldComponent.TableHeader1 = "Industry Segment";
        this.singleFieldComponent.TableHeader2 = "Sub Segment";
        this.singleFieldComponent.isDoubleField = true;
        this.GetAll();
        break;
      case "Region":
        this.singleFieldComponent.Heading = "Region";
        this.singleFieldComponent.RoutingParam = "Region";
        this.singleFieldComponent.ButtonText = "Add Region";
        this.singleFieldComponent.ConfirmationText = "region";
        this.singleFieldComponent.ControllerName = "Region";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Region";
        this.singleFieldComponent.firstFiledLabel = "Region";
        this.singleFieldComponent.secondFiledLabel = "Region Code";
        this.singleFieldComponent.TableHeader1 = "Region";
        this.singleFieldComponent.TableHeader2 = "Region Code";
        this.singleFieldComponent.isDoubleField = true;
        this.GetAll();
        break;
      case "TypeofCustomer":
        this.singleFieldComponent.Heading = "Type of Customer";
        this.singleFieldComponent.RoutingParam = "TypeofCustomer";
        this.singleFieldComponent.ButtonText = "Add Type of Customer";
        this.singleFieldComponent.ConfirmationText = "type of customer";
        this.singleFieldComponent.ControllerName = "CustomerType";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Type of customer";
        this.singleFieldComponent.firstFiledLabel = "Type of Customer";
        this.singleFieldComponent.TableHeader1 = "Type of Customer";
        this.singleFieldComponent.isDoubleField = false;
        this.GetAll();
        break;
      case "EstimatedProductValue":
        this.singleFieldComponent.Heading = "Estimated Product Value";
        this.singleFieldComponent.RoutingParam = "EstimatedProductValue";
        this.singleFieldComponent.ButtonText = "Add Estimated Product Value";
        this.singleFieldComponent.ConfirmationText = "estimated product value";
        this.singleFieldComponent.ControllerName = "EstimatedProductValue";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Estimated product value";
        this.singleFieldComponent.firstFiledLabel = "Estimated Product Value";
        this.singleFieldComponent.TableHeader1 = "Estimated Product Value";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.TableHeader2 = "Role";
        this.GetAll();
        break;
      case "Currency":
        this.singleFieldComponent.Heading = "Currency";
        this.singleFieldComponent.RoutingParam = "Currency";
        this.singleFieldComponent.ButtonText = "Add Currency";
        this.singleFieldComponent.ConfirmationText = "currency";
        this.singleFieldComponent.ControllerName = "Currency";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Currency";
        this.singleFieldComponent.firstFiledLabel = "Currency";
        this.singleFieldComponent.secondFiledLabel = "Exchange Rate (€)";
        this.singleFieldComponent.TableHeader1 = "Currency";
        this.singleFieldComponent.TableHeader2 = "Exchange Rate (€)";
        this.singleFieldComponent.isDoubleField = true;
        this.GetAll();
        break;
      case "TermsConditions":
        this.singleFieldComponent.Heading = "Terms & Conditions";
        this.singleFieldComponent.RoutingParam = "TermsConditions";
        this.singleFieldComponent.ButtonText = "Add Terms & Conditions";
        this.singleFieldComponent.ConfirmationText = "terms & conditions";
        this.singleFieldComponent.ControllerName = "TermsAndConditions";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Terms & conditions";
        this.singleFieldComponent.firstFiledLabel = "Terms & Condition";
        this.singleFieldComponent.secondFiledLabel = "Standard Value";
        this.singleFieldComponent.TableHeader1 = "Terms & Conditions";
        this.singleFieldComponent.TableHeader2 = "Standard Values";
        this.singleFieldComponent.isDoubleField = true;
        this.GetAll();
        break;
      case "StandardReasonforWinningLosing":
        this.singleFieldComponent.Heading = "Standard Reason";
        this.singleFieldComponent.RoutingParam = "StandardReasonforWinningLosing";
        this.singleFieldComponent.ButtonText = "Add Standard Reason";
        this.singleFieldComponent.ConfirmationText = "standard reason for winning/losing";
        this.singleFieldComponent.ControllerName = "ReasonEnquiryWinLoss";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Standard reason for winning/losing";
        this.singleFieldComponent.firstFiledLabel = "Enquiry Status";
        this.singleFieldComponent.secondFiledLabel = "Reason for Winning/Losing";
        this.singleFieldComponent.thirdFieldLabel = "Reason Category";
        this.singleFieldComponent.TableHeader1 = "Enquiry Status";
        this.singleFieldComponent.TableHeader2 = "Reason for Winning/Losing";
        this.singleFieldComponent.TableHeader3 = "Reason Category";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isTripleField = true;
        this.GetAll();
        break;
      
        case "EnquiryCloseReason":
          this.singleFieldComponent.Heading = "Enquiry Close Reason";
          this.singleFieldComponent.RoutingParam = "EnquiryCloseReason";
          this.singleFieldComponent.ButtonText = "Add Enquiry Close Reason";
          this.singleFieldComponent.ConfirmationText = "Enquiry Close Reason";
          this.singleFieldComponent.ControllerName = "EnquiryCloseReason";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Enquiry Close Reason";
          this.singleFieldComponent.firstFiledLabel = "Enquiry Close Reason";
          this.singleFieldComponent.TableHeader1 = "Enquiry Close Reason";
          this.singleFieldComponent.isDoubleField = false;
          this.GetAll();
          break;

            case "LeadCloseReason":
            this.singleFieldComponent.Heading = "Lead Close Reason";
            this.singleFieldComponent.RoutingParam = "LeadCloseReason";
            this.singleFieldComponent.ButtonText = "Add Lead Close Reason";
            this.singleFieldComponent.ConfirmationText = "Lead close reason";
            this.singleFieldComponent.ControllerName = "OpportunityCloseReason";
            this.singleFieldComponent.ActionName = "GetAllPagination";
            this.singleFieldComponent.SuccessText = "Lead close reason";
            this.singleFieldComponent.firstFiledLabel = "Lead Close Reason";
            this.singleFieldComponent.TableHeader1 = "Lead Close Reason";
            this.singleFieldComponent.isDoubleField = false;
            this.GetAll();
            break;
          //   case "CustomerSpecialDiscount":
           
          //   this.singleFieldComponent.Heading = "Customer Special Discount";
          //   this.singleFieldComponent.RoutingParam = "CustomerSpecialDiscount";
          //   this.singleFieldComponent.ButtonText = "Add Customer Special Discount";
          //   this.singleFieldComponent.ConfirmationText = "Customer Special Discount";
          //   this.singleFieldComponent.ControllerName = "CustomerSpecialDiscount";
          //   this.singleFieldComponent.ActionName = "GetAllPagination";
          //   this.singleFieldComponent.SuccessText = "Customer Special Discount";
          //   this.singleFieldComponent.firstFiledLabel = "Customer Special Discount";
          //   this.singleFieldComponent.TableHeader1 = "Customer Name";
          //   this.singleFieldComponent.isDoubleField = false;
          //  // this.singleFieldComponent.isvariablediscountfield=true;
          //   this.singleFieldComponent.isCompanynamerequired = true;
          //   this.GetAll();
           
          //   break;

          case "CustomerSpecialDiscount":
           
            this.singleFieldComponent.Heading = "Customer Frame Agreement";
            this.singleFieldComponent.RoutingParam = "CustomerSpecialDiscount";
            this.singleFieldComponent.ButtonText = "Add Customer Frame Agreement";
            this.singleFieldComponent.ConfirmationText = "Customer Frame Agreement";
            this.singleFieldComponent.ControllerName = "CustomerSpecialDiscount";
            this.singleFieldComponent.ActionName = "GetAllPagination";
            this.singleFieldComponent.SuccessText = "Customer Frame Agreement";
            this.singleFieldComponent.firstFiledLabel = "Customer Frame Agreement";
            this.singleFieldComponent.TableHeader1 = "Customer Name";
            this.singleFieldComponent.isDoubleField = true;
            this.singleFieldComponent.secondFiledLabel = " WorkFlow Status";
            this.singleFieldComponent.TableHeader2 = " WorkFlow Status";
           
           // this.singleFieldComponent.isvariablediscountfield=true;
            this.singleFieldComponent.isCompanynamerequired = true;
            this.GetAll();
           
            break;
      case "EnquiryCompetitor":
        this.singleFieldComponent.Heading = "Enquiry Competitor";
        this.singleFieldComponent.RoutingParam = "EnquiryCompetitor";
        this.singleFieldComponent.ButtonText = "Add Enquiry Competitor";
        this.singleFieldComponent.ConfirmationText = "Enquiry Competitor";
        this.singleFieldComponent.ControllerName = "EnquiryCompetitor";
        this.singleFieldComponent.ActionName = "GetAllPagination";
        this.singleFieldComponent.SuccessText = "Enquiry Competitor";
        this.singleFieldComponent.firstFiledLabel = "Enquiry Competitor";
        this.singleFieldComponent.TableHeader1 = "Enquiry Competitor";
        this.singleFieldComponent.isDoubleField = false;
        this.GetAll();
        break;
        case "CustomerGroup":
          this.singleFieldComponent.Heading = "Customer Group";
          this.singleFieldComponent.RoutingParam = "CustomerGroup";
          this.singleFieldComponent.ButtonText = "Add Customer Group";
          this.singleFieldComponent.ConfirmationText = "customer group";
          this.singleFieldComponent.ControllerName = "CustomerGroup";
          this.singleFieldComponent.ActionName = "GetAllPagination";
          this.singleFieldComponent.SuccessText = "Customer group";
          this.singleFieldComponent.firstFiledLabel = "Customer Group";
          this.singleFieldComponent.secondFiledLabel = "Customer Group Code";
          this.singleFieldComponent.TableHeader1 = "Customer Group";
          this.singleFieldComponent.TableHeader2 = "Customer Group Code";
          this.singleFieldComponent.isDoubleField = true;
          this.GetAll();
          break;
  
         case "TechnicalDiscardReason":
            this.singleFieldComponent.Heading = "Technical Discard Reason";
            this.singleFieldComponent.RoutingParam = "TechnicalDiscardReason";
            this.singleFieldComponent.ButtonText = "Add Technical Discard Reason";
            this.singleFieldComponent.ConfirmationText = "Technical discard reason";
            this.singleFieldComponent.ControllerName = "TechnicalDiscard";
            this.singleFieldComponent.ActionName = "GetAllPagination";
            this.singleFieldComponent.SuccessText = "Technical discard reason";
            this.singleFieldComponent.firstFiledLabel = "Technical Discard Reason";
            this.singleFieldComponent.TableHeader1 = "Technical Discard Reason";
            this.singleFieldComponent.isDoubleField = false;
            this.GetAll();
            break;
  
          case "TermsConditions-NonStandard":
            this.singleFieldComponent.Heading = "Terms & Conditions-Non Standard";
            this.singleFieldComponent.RoutingParam = "TermsConditions-NonStandard";
            this.singleFieldComponent.ButtonText = "Add Non Standard Terms & Conditions";
            this.singleFieldComponent.ConfirmationText = "non standard terms & conditions";
            this.singleFieldComponent.ControllerName = "TermsAndConditionsCustomized";
            this.singleFieldComponent.ActionName = "GetAllPagination";
            this.singleFieldComponent.SuccessText = "Terms & conditions-Non Standard";
            this.singleFieldComponent.firstFiledLabel = "Terms & Condition-Non Standard";
            this.singleFieldComponent.secondFiledLabel = "Non Standard Value";
            this.singleFieldComponent.TableHeader1 = "Terms & Conditions-Non Standard";
            this.singleFieldComponent.TableHeader2 = "Non Standard Values";
            this.singleFieldComponent.isDoubleField = true;
            this.GetAll();
            break;
  
          case "Application":
            this.singleFieldComponent.Heading = "Application";
            this.singleFieldComponent.RoutingParam = "Application";
            this.singleFieldComponent.ButtonText = "Add Application";
            this.singleFieldComponent.ConfirmationText = "Application";
            this.singleFieldComponent.ControllerName = "Application";
            this.singleFieldComponent.ActionName = "GetAllPagination";
            this.singleFieldComponent.SuccessText = "Application";
            this.singleFieldComponent.firstFiledLabel = "Industry Segment";
            this.singleFieldComponent.secondFiledLabel = "Sub Segment";
            this.singleFieldComponent.thirdFieldLabel = "Application Name";
            this.singleFieldComponent.TableHeader1 = "Industry Segment";
            this.singleFieldComponent.TableHeader2 = "Sub Segment";
            this.singleFieldComponent.TableHeader3 = "Application";
            this.singleFieldComponent.isDoubleField = true;
            this.singleFieldComponent.isTripleField = true;    
            this.GetAll();
            break;
            case "RoleManagement":
              this.singleFieldComponent.Heading = "Role Management";
              this.singleFieldComponent.RoutingParam = "RoleManagement";
              this.singleFieldComponent.ButtonText = "Add Role";
              this.singleFieldComponent.ConfirmationText = "Role Management";
              this.singleFieldComponent.ControllerName = "Account";
              this.singleFieldComponent.ActionName = "GetAllPagination";
              this.singleFieldComponent.SuccessText = "Role Management";
              this.singleFieldComponent.firstFiledLabel = "Role Management";
              this.singleFieldComponent.TableHeader1 = "Role Management";
              this.singleFieldComponent.isDoubleField = false;
              this.GetAll();
              break;
              // case "AddLoginImage":
              //   this.singleFieldComponent.Heading="LoginPageImage";
              //   this.singleFieldComponent.RoutingParam="AddLoginImage";
              //   this.singleFieldComponent.ButtonText = "Add LoginPageImage";
              //   this.singleFieldComponent.ConfirmationText = "LoginPageImage ";
              //   this.singleFieldComponent.ControllerName = "LoginPageImage";
              //   this.singleFieldComponent.ActionName = "GetAllPagination";
              //   this.singleFieldComponent.SuccessText = "Login Page Image";
              //   this.singleFieldComponent.firstFiledLabel = "LoginPageImage";
              //   this.singleFieldComponent.TableHeader1 = "LoginPageImage";
              //   this.singleFieldComponent.isDoubleField = false;
              //   this.GetAll();
    }
    this.titleService.setTitle(this.singleFieldComponent.Heading + " - " + this.appConfig.titleName);
    //this.titleService.setTitle(this.singleFieldComponent.Heading + " - ECOTECH");

  }

  OnSelect(dropdownOption = ""){
    if(dropdownOption == 'Application')
    {
      this.displayedColumns.splice(2, 0, "Application");
      this.someCondition=true;
    }
    else if(dropdownOption == 'StandardReasonforWinningLosing')
    {
      this.displayedColumns.splice(2, 0, "ReasonCategory");
      this.isReasonCategoryVisible=true;
    }
    else if (this.displayedColumns.length == 4)
    {
        this.displayedColumns.splice(2,1);
    }
  }

  GetAll() {
    this.showLoader = true;
    let queryString = "";
    
    queryString = "pageNumber=" + this.pageNumber;
    queryString += "&pageSize=" + this.pageSize;
    queryString += "&searchText=" + this.SearchText;
    queryString += "&direction=" + this.direction;
    queryString += "&columnName=" + this.ColumnName;

    this.CommonService.GetData(this.singleFieldComponent.ControllerName, this.singleFieldComponent.ActionName, queryString).subscribe((data: any) => {
      this.data = data.Data;
     
      this.length = data.Total;
      this.dataSource = new MatTableDataSource(this.data);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.GetAll();
  }

  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAll();
    }
  }

  pageEvent(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAll();
  }
  /************************************************************************
   * Grid Code End 
   * **********************************************************************/

  /*******************************************************************************
 * Form validation method
 * ******************************************************************************/

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  UpdateStatusById(Id: number, CurrentStatus: boolean) {

    if (CurrentStatus == true) {
      //Create ConfirmDialogRef 
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to deactivate this " + this.singleFieldComponent.ConfirmationText + "?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Id;

          this.CommonService.PostData(this.singleFieldComponent.ControllerName, 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success(this.singleFieldComponent.SuccessText + " deactivated successfully!");
            }
            else if (data.ResponseCode == 0) {
              this.toastr.warning("Failed to deactivate this " + this.singleFieldComponent.ConfirmationText + " because it has some dependency!");
            }
            else {
              this.toastr.info("Failed to deactivate " + this.singleFieldComponent.ConfirmationText + "!");
            }
            this.GetAll();
          }, error => {
            this.toastr.error("Failed to deactivate " + this.singleFieldComponent.ConfirmationText + "!");
            this.GetAll();
            this.showLoader = false;
          });
        }
        else {
          this.GetAll();
        }
      });

    }
    else {
      //Create ConfirmDialogRef
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to activate this " + this.singleFieldComponent.ConfirmationText + "?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Id;

          this.CommonService.PostData(this.singleFieldComponent.ControllerName, 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success(this.singleFieldComponent.SuccessText + " activated successfully!");
            }
            else {
              this.toastr.info("Failed to activate " + this.singleFieldComponent.ConfirmationText + "!");
            }
            this.GetAll();
          }, error => {
            this.toastr.error("Failed to activate " + this.singleFieldComponent.ConfirmationText + "!");
            this.GetAll();
            this.showLoader = false;
          });

        }
        else {
          this.GetAll();
        }
      });
    }
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isConfigureEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isConfigureMasters')[0]?.IsEditable;
      
    });
 
  }
  
}
