<ng-container *ngIf="activity.InEditMode == true" >

  <td >
    {{activity.Customer}}
  </td>


  <td [ngStyle]="activity.ActivityId == null || '' ? {'display':'none'} : {} ">
    {{activity.ActivityType}}
  </td>

  <td [ngStyle]="activity.ActivityId == null || '' ? {} : {'display':'none'} ">
    <select class="form-control form-input" id="ActivityTypeId" name="ActivityTypeId"
      (change)="onActivityTypeChange(activity)" [(ngModel)]="activity.ActivityTypeId"  required>
      <option value="undefined" selected disabled> Select activity </option>
      <option *ngFor="let item of activityTypeMaster" [value]="item.ActivityTypeId">
        {{ item.ActivityTypeDesc }}
      </option>
    </select>
  </td>

  <td >
    {{activity.Participants}}
  </td>

  <td>
    <input class="form-control form-input" [(ngModel)]="activity.Subject" #Subject (keydown)="onKeyDown($event)"
      placeholder="Subject" type="text" [disabled]="activity.ActivityStatus =='Completed' " required />
  </td>

  <td>
    <input class="form-control form-input" [(ngModel)]="activity.PlannedDueDatetime" type="datetime-local" [disabled]="activity.ActivityStatus =='Completed' " required />
  </td>

  <td>
    <select [ngStyle]="activity.ActivityId == null || '' ? {'display':'none'} : {} " class="form-control form-input" id="ActivityStatusId" name="ActivityStatusId"
      [(ngModel)]="activity.ActivityStatusId" [disabled]="activity.ActivityStatus =='Completed' " required>
      <option *ngFor="let item of activityStatusByTypeMaster" [selected]="item.ActivityStatusId == activity.ActivityStatusId" value="{{ item.ActivityStatusId }}">
        {{ item.ActivityStatusDesc }}
      </option>
    </select>

    <span [ngStyle]="activity.ActivityId == null || '' ? {} : {'display':'none'} ">Not Started</span>

  </td>

  <td>
    {{activity.DueDays}}
  </td>

  <td>
    <a class="btn"  *ngIf="activity.canEditCustomerEngagementReportOfOthers && activity?.ActivityStatus !=='Completed'" style="width: 25px;"  (click)="save(activity,index)" > 
      <i title="Save" class="fas fa-save" style="color:#f4516c"></i>
    </a>
    <a class="btn"  *ngIf="activity.canViewCustomerEngagementReportOfOthers"  style="width: 25px;" (click)="openViewPopup(activity.ActivityId)">
      <i class="fa fa-eye fa-lg" title="View" style="color:#f4516c"></i>
    </a>
    <a class="btn" *ngIf="activity.canEditCustomerEngagementReportOfOthers && activity?.ActivityStatus !=='Completed'" style="width: 25px;" (click)="cancel(activity,index)">
      <i title="Cancel" class="fa fa-times" style="color:#f4516c" aria-hidden="true"></i>
    </a>
  </td>
</ng-container>




<ng-container *ngIf="activity.InEditMode == undefined || activity.InEditMode == false">

  <td >
    {{activity.Customer}}
  </td>

  <td >
    {{activity.ActivityType}}
  </td>

  <td>
    {{activity.Participants}}
  </td>

  <td >
    {{activity.Subject}}
  </td>

  <td>
    {{activity.PlannedDueDatetime | date:'dd-MM-yyyy'}}
  </td>

  <td >
    {{activity.ActivityStatus}}
  </td>
  <td >
    {{activity.DueDays}}
  </td>

  <td >
    <a class="btn" *ngIf="activity.canEditCustomerEngagementReportOfOthers && activity?.ActivityStatus !=='Completed'"  style="width: 25px;" (click)="edit(activity)"><i title="Quick Edit" class="fas fa-edit" style="color:#f4516c"></i></a>
    <a class="btn"  *ngIf="activity.canViewCustomerEngagementReportOfOthers"  style="width: 25px;" (click)="openViewPopup(activity.ActivityId)">
      <i class="fa fa-eye fa-lg" title="View" style="color:#f4516c"></i>
    </a>
  </td>
</ng-container>


<loader [showLoader]="showLoader"></loader>



