
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivityService } from '../../../../Service/activity.service';
import { Activity, ActivityType, ActivityStatusByType } from '../../../../Model/ActivityModel';
import { MatDialog } from '@angular/material/dialog';
import { ViewActivityComponent } from '../view-activity/view-activity.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';

@Component({
  selector: '[list-activity-row]',
  templateUrl: './list-activity-row.component.html',
  styleUrls: ['./list-activity-row.component.scss']
})
export class ListActivityRowComponent implements OnInit {


  @Input() activity: Activity;
  // @Output() refreshGridParent = new EventEmitter();
  @Output() initialActivityToCompairParent = new EventEmitter();
  @Input() index: number;
  @Output() dataEvent = new EventEmitter<string>();
  accesspermissionobj: any;
  canViewCustomerEngagementReportOfOthers: any;
  canEditCustomerEngagementReportOfOthers: any;
  Role: string;


  constructor(
    private cd:ChangeDetectorRef,
    private activityService: ActivityService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private accessservice: AccessPermissionService
  ) { }



  tempSubject: string;
  tempPlannedDueDate: Date;
  activityOriginal: Activity;
  showLoader: boolean = false;
  activityStatusByTypeMaster: ActivityStatusByType[] = [];
  activityTypeMaster: ActivityType[] = [];
  acitvityStatusIdOfNotStarted: any;


  ngOnInit(): void {
    
    this.showLoader = true;
    this.Role = localStorage.getItem("UserRole");

   
      this.activityOriginal = { ...this.activityOriginal, ...this.activity };
      this.activityService.getActivityTypeMaster().subscribe((data) => {
        this.activityTypeMaster = data;
      });

      if (this.activityOriginal.InEditMode == true) {
        this.activityService.getActivityStatusMaster(this.activityOriginal.ActivityTypeId).subscribe((data: ActivityStatusByType[]) => {
          this.activityStatusByTypeMaster = data;
        });
      }
   
    this.showLoader = false;
  }






  onActivityTypeChange(activity: any) {
    this.activityService.getActivityStatusMaster(activity.ActivityTypeId).subscribe((data: ActivityStatusByType[]) => {
      this.activityStatusByTypeMaster = data;
      if (!activity.ActivityId) {
        let initialStatusId = this.activityStatusByTypeMaster.find((arg) => {
          return arg.ActivityStatusCode == "NOT_STARTED"
        })
        this.acitvityStatusIdOfNotStarted = initialStatusId.ActivityStatusId;
      }
    });

  }


  save(activity: Activity, index): void {


    if (this.activity.Subject && this.activity.PlannedDueDatetime?.toString()) {
      activity['ActivityOwnerId'] = activity['ActivityOwner'];
      this.activityService.addOrUpdateActivity(activity).subscribe(data => {
        //this.activityOriginal = data;
       
        activity.InEditMode = false;
        this.toastr.success(data.ResponseMessage);
        this.initialActivityToCompairParent.emit({ activity: activity, index: index })
      });
    }
    else {
      let inputs = document.querySelectorAll('.form-input')
      inputs.forEach(input => {
        input.classList.add('error')
      })
    }
    this.acitvityStatusIdOfNotStarted = null;
  }

  cancel(activity: Activity, index): void {
    this.activity.Subject = this.tempSubject;
    this.activity.PlannedDueDatetime = this.tempPlannedDueDate;
    this.activity.ActivityStatusId = this.tempActivityStatusId;
    activity.InEditMode = false;
    this.dataEvent.emit(index);
  }

  tempActivityStatusId;

  edit(activity: Activity): void {
    this.activityService.getActivityStatusMaster(activity.ActivityTypeId).subscribe(
      (data: ActivityStatusByType[]) => {
        this.activityStatusByTypeMaster = data;
        this.tempSubject = this.activity.Subject;
        this.tempPlannedDueDate = this.activity.PlannedDueDatetime;
        this.tempActivityStatusId = this.activity.ActivityStatusId;
        activity.InEditMode = true;
      }
    );
  }


  delete(activity: Activity) {
    this.activityService.deleteActivity(activity).subscribe(activity => {
    });
  }


  onKeyDown(event: KeyboardEvent) {
    if (event.key === ' ' && event.target['value'].trim() === '') {
      event.preventDefault();
    }
  }

  openViewPopup(activityId: number) {
    const modalRef = this.modalService.open(ViewActivityComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });
    var Activity = {
      activityId: activityId,
      inPopUpMode: true,
      canEditCustomerEngagementReportOfOthers:this.activity.canEditCustomerEngagementReportOfOthers,
      canViewCustomerEngagementReportOfOthers:this.activity.canViewCustomerEngagementReportOfOthers
    };

    modalRef.componentInstance.Activity = Activity;
  }
  
  getAccess(): Observable<any> {
    let tracker = this.accessservice.GetAll();

    tracker.subscribe((data: any) => {
      this.accesspermissionobj = data;
      this.canViewCustomerEngagementReportOfOthers = this.accesspermissionobj.filter(x => x.Fk_RoleId == this.Role && x.ElementKey == 'canViewCustomerEngagementReportOfOthers')[0]?.IsVisible;
      this.canEditCustomerEngagementReportOfOthers = this.accesspermissionobj.filter(x => x.Fk_RoleId == this.Role && x.ElementKey == 'canViewCustomerEngagementReportOfOthers')[0]?.IsEditable;

    });
    return tracker;
  }

  checkAccess()
  {
      const participantsIdArray = this.activityOriginal.ParticipantsID.split(',');
      
      if((participantsIdArray.filter(x=>x==localStorage.getItem('UserID')).length>0 ||this.activityOriginal.ActivityOwner==localStorage.getItem('UserID')))
      {
        this.activityOriginal.canEditCustomerEngagementReportOfOthers=true;
        this.activityOriginal.canViewCustomerEngagementReportOfOthers=true;
      }
      else
      {
        this.activityOriginal.canEditCustomerEngagementReportOfOthers=this.canEditCustomerEngagementReportOfOthers;
        this.activityOriginal.canViewCustomerEngagementReportOfOthers=this.canViewCustomerEngagementReportOfOthers;
      }     
  }
}
