import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-engagement-reports',
  templateUrl: './engagement-reports.component.html',
  styleUrls: ['./engagement-reports.component.scss']
})
export class EngagementReportsComponent implements OnInit {

  showLoader: any;

  datefrom: any;
  dateto: any;

  date: any = {};
  clicked: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.showLoader = true;
  }
  ngAfterViewInit(): void {
    this.showLoader = false;
  }

  applyFilters() {
    if (this.datefrom && this.dateto) {
      if (new Date(this.datefrom) <= new Date(this.dateto)) {
        this.date.from = this.datefrom;
        this.date.to = this.dateto;
        this.clicked = true
      }
    }
  }

  removeFilters() {
    if(this.clicked && (this.datefrom && this.dateto)) {
      this.datefrom = '';
      this.dateto = '';
      this.date.from = this.datefrom;
      this.date.to = this.dateto;
    }
    else {
      this.datefrom = '';
      this.dateto = '';
    }
  }
}
