<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Add Stock Inventory
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <form class="m-form m-form--fit m-form--label-align-right"
                            (ngSubmit)="myform.form.valid && onSubmit(myform)" #myform="ngForm" novalidate>
                            <div class="m-portlet__body">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Stock as on:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <div class="input-group date">
                                            <input class="form-control" placeholder="DD-MMM-YYYY" id="stockdate"
                                                name="stockdate" ngbDatepicker #ed="ngbDatepicker"
                                                [(ngModel)]="StockInventoryObj.StockDate" #stockdate="ngModel"
                                                (click)="ed.toggle()"  [minDate]="minDate"
                                                [maxDate]="maxDate"
                                                [ngClass]="{ 'is-invalid': (myform.submitted && stockdate.invalid) || (stockdate?.touched && stockdate.invalid) }"
                                                required>
                                            <div class="input-group-append" (click)="ed.toggle()">
                                                <span class="input-group-text">
                                                    <i class="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>

                                        <div *ngIf="(myform.submitted && stockdate.invalid) || (stockdate?.touched && stockdate.invalid)"
                                            class="invalid-feedback" style="display: block;">
                                            Stock date is required
                                        </div>
                                    </div>
                                    <div class="offset-1"></div>
                                    <label class="col-lg-2  col-form-label">Upload Stock File(s):<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3 pt-1">
                                        <div class="file btn btn-sm btn-warning">
                                            Upload
                                            <input type="file" class="form-control-file" name="stockfiles"
                                                (change)="getMultipleFileDetails($event)" id="stockfiles"
                                                [(ngModel)]="tempfile" #stockfiles="ngModel" required multiple #myInput
                                                [ngClass]="{'is-invalid':myform.submitted  && stockfiles.invalid || stockfiles?.touched && stockfiles.invalid}">

                                        </div>
                                        <div *ngIf="((myform.submitted && stockfiles.invalid) || (stockfiles?.touched && stockfiles.invalid))"
                                            class="invalid-feedback" style="display: block;">
                                            Stock file is required
                                        </div>
                                        <!-- <div *ngIf="myform.hasError('fileempty', 'stockfiles')">
                                                Stock file is required
                                            </div> -->
                                        <br>

                                        <span class="fileext">
                                            [File Format: .bmp, .jpeg, .jpg, .png, .pdf, .xml, .txt, .doc, .docx, .xls,
                                            .xlsx, .zip, .ppt, .pptx] [Max 5 MB]</span>
                                        <span *ngIf="StockFile != undefined && StockFile.length > 0">
                                            <ul>
                                                <ng-container *ngFor="let item of StockFile;let i=index;">
                                                    <li>{{item.name}}&nbsp;&nbsp;<i class="flaticon-delete"
                                                            style="color:#f4516c" (click)="removeAttachments(i)"
                                                            title="Remove"></i>
                                                    </li>
                                                </ng-container>
                                            </ul>

                                        </span>
                                    </div>

                                </div>

                            </div>
                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button type="submit"
                                                class="btn btn-primary m-btn m-btn--air m-btn--custom">Save</button>&nbsp;&nbsp;
                                            <button type="button" routerLink="/StockInventory"
                                                routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>