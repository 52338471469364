import { Component, OnInit, Input, ElementRef, SimpleChange, SimpleChanges, OnChanges } from '@angular/core';
import { CommonService } from '../Service/common.service';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnChanges {

  @Input()
  showLoader: boolean;

  constructor(private elementRef: ElementRef,private commonService:CommonService) {
    
  }

  ngOnInit() {
    this.commonService.RefreshHeaderFun();
  }

  ngOnChanges(changes: SimpleChanges) {
    let change = changes.currentValue;

    if (this.showLoader == true) {
      this.elementRef.nativeElement.getElementsByClassName('overlays')[0].style.display = "block";
    }
    else {
      this.elementRef.nativeElement.getElementsByClassName('overlays')[0].style.display = "none";
    }
  }


}
