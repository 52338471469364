<h1 mat-dialog-title>
    Forgot Password?
</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <mat-label>Username</mat-label>
        <input type="text" name="email" [(ngModel)]="forgotPassword.Username" #email="ngModel"
            [ngClass]="{ 'is-invalid': email.invalid }"
            [ngClass]="{ 'is-invalid':email.invalid || email?.touched && email.invalid}"
            (change)="forgotPassword.Username=forgotPassword.Username.trim()" required matInput>

        <mat-error *ngIf="email.invalid">
            Username is required
        </mat-error>
    </mat-form-field><br>

</div>
<div mat-dialog-actions align="center">
    <button [mat-dialog-close]="forgotPassword" [ngClass]="{ 'normalcursor' : forgotPassword.Username=='' }"
        class="btn btn-primary m-btn m-btn--air m-btn--custom"
        [disabled]="forgotPassword.Username==''">Submit</button>&nbsp;&nbsp;
    <button (click)="onNoClick()" class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</button>
</div>