import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivityLinkageEntityType, ActivityOutcomeByType, ActivityParticipant, ActivityPurposeByType, ActivityStatusByType, ActivityType, EntityRecord, User } from 'src/app/Model/ActivityModel';
import { ActivityService } from 'src/app/Service/activity.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss']
})


export class AddActivityComponent implements OnInit {


  constructor(private cd: ChangeDetectorRef, private location: Location, private router: Router, private activityService: ActivityService, private toastr: ToastrService, private route: ActivatedRoute,
    private activeModal: NgbActiveModal) { }


  activityTypeMaster: ActivityType[] = [];
  activityStatusByTypeMaster: ActivityStatusByType[] = [];
  activityOutcomeByTypeMaster: ActivityOutcomeByType[] = [];
  activityPurposeByTypeMaster: ActivityPurposeByType[] = [];
  allEntityTypesMaster: ActivityLinkageEntityType[] = [];
  allCustomers:EntityRecord[] = [];
  allLeads:EntityRecord[] = [];
  allEnquiries:EntityRecord[] = [];
  selectedParticipants = [];
  allUserMaster: User[] = [];
  frmData: any = {};
  ownerIdLocalStorage: any
  entityTypeFilterArray = [];
  subtypeArray1 = []
  showLoader: boolean = false;
  entityId: number;
  custId: number;
  entityType: any;
  isEnquiryShow: boolean = false;
  selectedOptions: any[] = [];
  @Input() ActivityEntity
  inPopUpMode: boolean;
  selectedEnquries = [];
  previousSelectedEnquiries = [];
  selectedCustomers = [];
  selectedCustomersOrLeads = [];
  selectedEntityRecords = [];
  selectedLeads = [];
  ENTITY_TYPE_CUSTOMER = "1";
  ENTITY_TYPE_LEAD = "2";
  ENTITY_TYPE_ENQUIRY = "3";
  relatedEntityRecords: EntityRecord[] = [];




  ngOnInit(): void {

    this.inPopUpMode = this.ActivityEntity?.inPopUpMode ? this.ActivityEntity?.inPopUpMode : false;
    localStorage.removeItem('ViewType-A');
    this.showLoader = true;
    this.ownerIdLocalStorage = localStorage.getItem('UserID')
    // **********Initial render linckages********
    this.getActivityFromMaster();
    this.getAllCustomers();
    this.getAllLeads();
    this.getAllParticipantFromMaster();
    // ****** LoggedInUser as default Participant****
    this.frmData.ActivityOwnerId = this.ownerIdLocalStorage;
    if (this.frmData.ListActivityParticipants == undefined) {
      this.frmData.ListActivityParticipants = [];
      this.frmData.ListActivityParticipants.push(this.frmData.ActivityOwnerId);
    }
    // ************ if Route comes from customer-lead-Enquiry*********
    this.entityType = this.ActivityEntity?.entityType ? this.ActivityEntity?.entityType : this.route.snapshot.queryParams['EntityType'];

    if(this.entityType === 'Lead') {
      this.entityId = this.route.snapshot.queryParams['LeadId'];
      this.selectedLeads.push(Number(this.entityId));
    }
    if(this.entityType === 'Customer') {
      this.entityId = this.route.snapshot.queryParams['CustId'];
      this.selectedCustomers.push(Number(this.entityId));
      this.getEnquiriessByCustomers();
    }
    if(this.entityType === 'Enquiry') {
      this.entityId = this.ActivityEntity?.enqId ? this.ActivityEntity?.enqId : this.route.snapshot.queryParams['EnqId'];
      this.custId = this.ActivityEntity?.custId ? this.ActivityEntity?.custId : this.route.snapshot.queryParams['CustId'];

      if(this.custId && !isNaN(this.custId) && this.entityId && !isNaN(this.entityId)){
        this.selectedCustomers.push(Number(this.custId));
        this.selectedEnquries.push(Number(this.entityId));
        this.getEnquiriessByCustomers();
      }

    }
    if (this.entityType !== undefined && this.entityId !== undefined) {
      this.isLinkagePresent();
    }
    this.showLoader = false;
  }


  getAllParticipantFromMaster() {
    this.showLoader = true;
    this.activityService.getallUserMaster().subscribe((data: User[]) => {
      this.allUserMaster = data;
      this.showLoader = false;
    })
  }

  getActivityFromMaster() {
    this.showLoader = true;
    this.activityService.getActivityTypeMaster().subscribe((data) => {
      this.activityTypeMaster = data;
      this.showLoader = false;
    });
  }


  isLinkagePresent() {
    this.activityService.getallEntityTypesMaster().subscribe((data: ActivityLinkageEntityType[]) => {
      this.frmData.ListActivityLinkage = [];
      this.frmData.ListActivityLinkage.push({ EntityTypeId: null, EntityId: this.entityId });
      this.allEntityTypesMaster = data;
      let obj = this.allEntityTypesMaster.find((arg) => { return arg.EntityType == this.entityType })
      this.frmData.ListActivityLinkage[0].EntityTypeId = obj.Id;
    });
  }

  onOwnerChange(frmData, index) {
    if (frmData.ListActivityParticipants != undefined) {
      if (frmData.ListActivityParticipants.length > 1) {
        frmData.ListActivityParticipants[0] = this.frmData.ActivityOwnerId;
      }
      else {
        frmData.ListActivityParticipants = [];
        frmData.ListActivityParticipants.push(this.frmData.ActivityOwnerId);
      }
    }
    else {
      frmData.ListActivityParticipants = [];
      frmData.ListActivityParticipants.push(this.frmData.ActivityOwnerId);
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === ' ' && event.target['value'].trim() === '') {
      event.preventDefault();
    }
  }

  onActivityTypeChange(ActivityTypeId: number,) {

    // const obj = this.activityTypeMaster.find(obj => obj.ActivityTypeId == ActivityTypeId);

    // if (obj.ActivityTypeDesc == 'Meeting') {
    //   this.subtypeArray1 = ['Seminar', 'Webinar']
    //   this.frmData.ActivitySubType = "Webinar"
    // }
    // else {
    //   this.subtypeArray1 = []
    //   delete this.frmData['ActivitySubType'];
    // }
    delete this.frmData['ActivityPurposeId'];
    delete this.frmData['PurposeDesc'];

    this.activityService.getActivityPurposeMaster(ActivityTypeId).subscribe((data: ActivityPurposeByType[]) => {
      this.activityPurposeByTypeMaster = data;

    });

    this.activityService.getActivityStatusMaster(ActivityTypeId).subscribe((data: ActivityStatusByType[]) => {
      this.activityStatusByTypeMaster = data;
    });

    this.activityService.getActivityOutcomeMaster(ActivityTypeId).subscribe((data: ActivityOutcomeByType[]) => {
      this.activityOutcomeByTypeMaster = data;
    })
  }

  goBack() {
    this.location.back();
  }

  closePopup() {
    this.activeModal.close('Close Click');
  }

  onSubmit(frmData: any, addActivityForm: any) {

    this.showLoader = true;
    if (!addActivityForm.valid) {
      this.showLoader = false;
      return
    }
// debugger
    // frmData.ListActivityLinkage = frmData.ListActivityLinkage?.flatMap(obj => obj.EntityId?.map(id => {
    //   if (obj.EntityTypeId && id) {
    //     return ({
    //       EntityTypeId: obj.EntityTypeId,
    //       EntityId: id,
    //     })
    //   }
    // }));

    frmData.allEnquiries = frmData.allEnquiries?.flatMap(obj => obj.EntityId?.map(id => {
      if (obj.EntityTypeId && id) {
        return ({
          EntityTypeId: obj.EntityTypeId,
          EntityId: id   ,
        })
      }
    }));

    frmData.allLeads = frmData.allLeads?.flatMap(obj => obj.EntityId?.map(id => {
      if (obj.EntityTypeId && id) {
        return ({
          EntityTypeId: obj.EntityTypeId,
          EntityId: id   ,
        })
      }
    }));
    frmData.allCustomers = frmData.allCustomers?.flatMap(obj => obj.EntityId?.map(id => {
      if (obj.EntityTypeId && id) {
        return ({
          EntityTypeId: obj.EntityTypeId,
          EntityId: id   ,
        })
      }
    }));


    // ***** Participant Conversion*****
    this.frmData.ListActivityParticipants = this.frmData.ListActivityParticipants.map(id => ({ ActivityParticipant: id }));
    let selectedCustomersAsObjCol = this.convertSelectedEntityArrayToEntityObjCollection(this.selectedCustomers, this.ENTITY_TYPE_CUSTOMER);
    let selectedLeadsAsObjCol = this.convertSelectedEntityArrayToEntityObjCollection(this.selectedLeads, this.ENTITY_TYPE_LEAD);
    let selectedEnquriesAsObjCol = this.convertSelectedEntityArrayToEntityObjCollection(this.selectedEnquries, this.ENTITY_TYPE_ENQUIRY);

    //Clear existing linkage object
    this.frmData.ListActivityLinkage = [];

    if (selectedCustomersAsObjCol) {
      this.frmData.ListActivityLinkage = this.frmData.ListActivityLinkage.concat(selectedCustomersAsObjCol);
    }
    if (selectedLeadsAsObjCol) {
      this.frmData.ListActivityLinkage = this.frmData.ListActivityLinkage.concat(selectedLeadsAsObjCol);
    }
    if (selectedEnquriesAsObjCol) {
      this.frmData.ListActivityLinkage = this.frmData.ListActivityLinkage.concat(selectedEnquriesAsObjCol);
    }


    this.showLoader = true;


    this.activityService.addActivity(frmData).subscribe((res) => {
      this.showLoader = false;
    //   this.toastr.success(res.ResponseMessage);
    //   if (this.inPopUpMode) this.activeModal.close();
    //   if (!this.inPopUpMode) this.goBack();
    // });
    if (res.ResponseCode == 1) {
      this.toastr.success('Activity added successfully!');
    }
    else {
      this.toastr.error('Failed to add activity');
    }
    if (this.inPopUpMode) this.activeModal.close();
    if (!this.inPopUpMode) this.goBack();

  }, error => {
    this.toastr.error('Failed to add activity!');
    if (this.inPopUpMode) this.activeModal.close();
    if (!this.inPopUpMode) this.goBack();
  });
}



  onChangeInCustomerSelection() {
    this.allEnquiries = [];

    //this.selectedEnquries = [];
    this.getEnquiriessByCustomers();
  }


  getEnquiriessByCustomers() {

    if (Array.isArray(this.selectedCustomers) && this.selectedCustomers.length > 0) {

      let tracker = this.activityService.getrelatedEntityRecords(this.ENTITY_TYPE_ENQUIRY, this.selectedCustomers);

      tracker.subscribe((data: EntityRecord[]) => {
        let linkagesByEntityType = this.groupArrayByKey(data, "EntityTypeId");
        this.allEnquiries = linkagesByEntityType[this.ENTITY_TYPE_ENQUIRY] as EntityRecord[];
        this.relatedEntityRecords = this.allEnquiries;
        this.previousSelectedEnquiries = [...this.selectedEnquries];
        this.selectedEnquries = [];
        for(let enquiry of this.allEnquiries) {
          for(let prevSelEnquiryId of this.previousSelectedEnquiries){

            if(enquiry.EntityId.toString() == prevSelEnquiryId) {
              this.selectedEnquries.push(enquiry.EntityId)
             // console.log(this.selectedEnquries);
            }

          }
        }

        //this.previousSelectedEnquiries = [];
      });

      return tracker;

    } else {
      this.selectedEnquries = [];
    }
  }


  getAllCustomers() {
    this.showLoader = true;
    let tracker = this.activityService.getrelatedEntityRecords(this.ENTITY_TYPE_CUSTOMER, null);
    tracker.subscribe((data: EntityRecord[]) => {
      let linkagesByEntityType = this.groupArrayByKey(data, "EntityTypeId");
      this.allCustomers = linkagesByEntityType[this.ENTITY_TYPE_CUSTOMER] as EntityRecord[];
      this.relatedEntityRecords = this.allCustomers;

    });
    this.showLoader = false;
    return tracker;

  }

  getAllLeads() {
    this.showLoader = true;
    let tracker = this.activityService.getrelatedEntityRecords(this.ENTITY_TYPE_LEAD, null);

    tracker.subscribe((data: EntityRecord[]) => {
      let linkagesByEntityType = this.groupArrayByKey(data, "EntityTypeId");
      this.allLeads = linkagesByEntityType[this.ENTITY_TYPE_LEAD] as EntityRecord[];
      this.relatedEntityRecords = this.allLeads;

    });
    this.showLoader = false;
    return tracker;
  }


  groupArrayByKey(array: any[], key: string): [] | null {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  }


  convertSelectedEntityArrayToEntityObjCollection(entities: any[], entityTypeId: any) {

    let response: any[] = [];

    if (Array.isArray(entities) && entities.length > 0) {

      entities.forEach(entityId => {
        response.push({ "EntityTypeId": entityTypeId, "EntityId": entityId });
      });
      return response;

    } else {
      return null;
    }

  }


}









