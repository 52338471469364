import { Component, OnInit, Input } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Options, ProductModel } from 'src/app/Model/ProductModel';
import { Title } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-add-options',
  templateUrl: './add-options.component.html',
  styleUrls: ['./add-options.component.scss']
})
export class AddOptionsComponent implements OnInit {
  Optionsobj: Options = new Options();
  charCode: any;
  @Input()
  showLoader: boolean = false;
  ProductModelarr: ProductModel[] = [];

  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title,
    private appConfigService : AppConfigService) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    //this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.getAllProductSeries(this.route.snapshot.params["Seriesid"]);
    this.GetProductModelById(this.route.snapshot.params["Seriesid"]);
  }

  /*******************************************************************************
  * Get data for Product options
  * ******************************************************************************/
  getAllProductSeries(SeriesId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "Id=" + SeriesId;

    this.CommonService.GetData("ProductSeries", "GetById", queryString).subscribe((data: any) => {
      this.Optionsobj.SeriesName = data.SeriesName;
      this.Optionsobj.ProductSeries = data.Id;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetProductModelById(SeriesId: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "SeriesId=" + SeriesId;
    this.CommonService.GetData("ProductModel", "GetProductModelById", queryString).subscribe((data: any) => {
      this.ProductModelarr = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  onIsPriceOnRequest(event: any) {
    if (event.checked) {
      this.Optionsobj.Price = 0;
    }
  }

  /*******************************************************************************
   * End get data for add product options
   * ******************************************************************************/

  /*******************************************************************************
 * Form validation method
 * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }


  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this product option?",
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;

        this.CommonService.PostData('ProductOptions', 'Add', this.Optionsobj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('Product option added successfully!');
            this.router.navigate(['/Options', this.Optionsobj.ProductSeries]);
            this.showLoader = false;

          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('Product option already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to product option!');
            this.router.navigate(['/Options', this.Optionsobj.ProductSeries]);
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add product option!');
          this.showLoader = false;
          this.router.navigate(['/Options', this.Optionsobj.ProductSeries]);
        });
      }
    });

  }
}
