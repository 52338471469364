import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { SalesOrderBusinesPlan } from 'src/app/Model/SalesOrderBusinessPlan';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UserRoles } from 'src/app/UserRoles';
import { BusinessPlanModel, PlanModel } from 'src/app/Model/BusinessPlanModel';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-business-plan-for-sales-and-order',
  templateUrl: './business-plan-for-sales-and-order.component.html',
  styleUrls: ['./business-plan-for-sales-and-order.component.scss']
})
export class BusinessPlanForSalesAndOrderComponent implements OnInit {
  BusnessPlan: SalesOrderBusinesPlan = new SalesOrderBusinesPlan();
  showLoader: boolean = false;
  // targetYears = ["2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030",
  //   "2031", "2032", "2033", "2034", "2035", "2036", "2037", "2038", "2039", "2040",
  //   "2041", "2042", "2043", "2044", "2045", "2046", "2047", "2048", "2049",
  // ];
  targetYears:any[]=[];
  currYear: number = new Date().getFullYear();
  targets = ['BP Sales', 'BP Order Intake'];
  allEmployees: any;
  roleName: any;
  item: any;
  allExistingData: any[];
  planExist: boolean;
  FinalModel: any[];
  name: any;
  selectedUserData: any;
  zeroValues: boolean;
  charCode: any;
  BusinessPlanobje: BusinessPlanModel = new BusinessPlanModel();
  IsBPDataValid: boolean = true;

  constructor(
    public dialog: MatDialog,
    private Service: CommonService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private appConfigService: AppConfigService
  ) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    //this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    let k = 1;
    for (let i = 2018; i <= this.currYear; i++) {
        let batch =i;
        this.targetYears.push(batch);
        k++;
    }
    this.getAllUsers();
  }

  setName(event: any) {
    this.BusinessPlanobje.RegionName = event.target.value;

  }
  onUnitsChange(i) {
    var povalue: number = parseFloat(this.BusinessPlanobje.PlanList[i].Units.toString(0));
    if (povalue == undefined || povalue == null) {
      this.BusinessPlanobje.PlanList[i].UnitsValidation = true;
      this.IsBPDataValid = false;
    } else if (povalue < 0) {
      this.BusinessPlanobje.PlanList[i].UnitsValidation = true;
      this.IsBPDataValid = false;
    } else if (povalue % 1 != 0) {
      this.BusinessPlanobje.PlanList[i].UnitsValidation = true;
      this.IsBPDataValid = false;
    } else if (this.BusinessPlanobje.PlanList[i].Units.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].UnitsValidation = true;
      this.IsBPDataValid = false;
    } else if (isNaN(this.BusinessPlanobje.PlanList[i].Units)) {
      this.BusinessPlanobje.PlanList[i].UnitsValidation = true;
      this.IsBPDataValid = false;
    } else {
      this.BusinessPlanobje.PlanList[i].UnitsValidation = false;
    }
  }
  onAmountChange(i) {

    var povalue: number = parseFloat(this.BusinessPlanobje.PlanList[i].Amount.toString());
    if (povalue == undefined || povalue == null) {
      this.BusinessPlanobje.PlanList[i].AmountValidation = true;
      this.IsBPDataValid = false;
    } else if (povalue < 0) {
      this.BusinessPlanobje.PlanList[i].AmountValidation = true;
      this.IsBPDataValid = false;
    } else if (povalue % 1 != 0 || povalue.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].AmountValidation = true;
      this.IsBPDataValid = false;
    } else if (this.BusinessPlanobje.PlanList[i].Amount.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].AmountValidation = true;
      this.IsBPDataValid = false;
    }  else if (isNaN(this.BusinessPlanobje.PlanList[i].Amount)) {
      this.BusinessPlanobje.PlanList[i].AmountValidation = true;
      this.IsBPDataValid = false;
    }else {
      this.BusinessPlanobje.PlanList[i].AmountValidation = false;
    }
  }
  onFC1Change(i)
  {
    var povalue: number = parseFloat(this.BusinessPlanobje.PlanList[i].FC1Amount.toString());
    if (povalue == undefined || povalue == null) {
      this.BusinessPlanobje.PlanList[i].FC1Validation = true;
      this.IsBPDataValid = false;
    } else if (povalue < 0) {
      this.BusinessPlanobje.PlanList[i].FC1Validation = true;
      this.IsBPDataValid = false;
    } else if (povalue % 1 != 0 || povalue.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].FC1Validation = true;
      this.IsBPDataValid = false;
    } else if (this.BusinessPlanobje.PlanList[i].FC1Amount.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].FC1Validation = true;
      this.IsBPDataValid = false;
    }  else if (isNaN(this.BusinessPlanobje.PlanList[i].FC1Amount)) {
      this.BusinessPlanobje.PlanList[i].FC1Validation = true;
      this.IsBPDataValid = false;
    }else {
      this.BusinessPlanobje.PlanList[i].FC1Validation = false;
    }
  }
  onFC2Change(i)
  {
    var povalue: number = parseFloat(this.BusinessPlanobje.PlanList[i].FC2Amount.toString());
    if (povalue == undefined || povalue == null) {
      this.BusinessPlanobje.PlanList[i].FC2Validation = true;
      this.IsBPDataValid = false;
    } else if (povalue < 0) {
      this.BusinessPlanobje.PlanList[i].FC2Validation = true;
      this.IsBPDataValid = false;
    } else if (povalue % 1 != 0 || povalue.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].FC2Validation = true;
      this.IsBPDataValid = false;
    } else if (this.BusinessPlanobje.PlanList[i].FC2Amount.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].FC2Validation = true;
      this.IsBPDataValid = false;
    }  else if (isNaN(this.BusinessPlanobje.PlanList[i].FC2Amount)) {
      this.BusinessPlanobje.PlanList[i].FC2Validation = true;
      this.IsBPDataValid = false;
    }else {
      this.BusinessPlanobje.PlanList[i].FC2Validation = false;
    }
  }
  onFC3Change(i)
  {
    var povalue: number = parseFloat(this.BusinessPlanobje.PlanList[i].FC3Amount.toString());
    if (povalue == undefined || povalue == null) {
      this.BusinessPlanobje.PlanList[i].FC3Validation = true;
      this.IsBPDataValid = false;
    } else if (povalue < 0) {
      this.BusinessPlanobje.PlanList[i].FC3Validation = true;
      this.IsBPDataValid = false;
    } else if (povalue % 1 != 0 || povalue.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].FC3Validation = true;
      this.IsBPDataValid = false;
    } else if (this.BusinessPlanobje.PlanList[i].FC3Amount.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].FC3Validation = true;
      this.IsBPDataValid = false;
    }  else if (isNaN(this.BusinessPlanobje.PlanList[i].FC3Amount)) {
      this.BusinessPlanobje.PlanList[i].FC3Validation = true;
      this.IsBPDataValid = false;
    }else {
      this.BusinessPlanobje.PlanList[i].FC3Validation = false;
    }
  }
  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }
  checkValidBusinessPlan() {
    this.BusinessPlanobje.PlanList.forEach(element => {
      // var povalue: number = parseFloat(element.Units.toString());
      // if (povalue == undefined || povalue == null) {
      //   element.UnitsValidation = true;
      //   this.IsBPDataValid = false;
      // } else if (povalue < 0) {
      //   element.UnitsValidation = true;
      //   this.IsBPDataValid = false;
      // } else if (povalue % 1 != 0) {
      //   element.UnitsValidation = true;
      //   this.IsBPDataValid = false;
      // } else if (element.Units.toString().indexOf('.') > -1) {
      //   element.UnitsValidation = true;
      //   this.IsBPDataValid = false;
      // } else {
      //   element.UnitsValidation = false;
      // }

      var povalue: number = parseFloat(element.Amount.toString());
      if (povalue == undefined || povalue == null) {
        element.AmountValidation = true;
        this.IsBPDataValid = false;
      } else if (povalue < 0) {
        element.AmountValidation = true;
        this.IsBPDataValid = false;
      } else if (povalue % 1 != 0) {
        element.AmountValidation = true;
        this.IsBPDataValid = false;
      } else if (element.Amount.toString().indexOf('.') > -1) {
        element.AmountValidation = true;
        this.IsBPDataValid = false;
      } else {
        element.AmountValidation = false;
      }

      var povalue: number = parseFloat(element.FC1Amount.toString());
      if (povalue == undefined || povalue == null) {
        element.FC1Validation = true;
        this.IsBPDataValid = false;
      } else if (povalue < 0) {
        element.FC1Validation = true;
        this.IsBPDataValid = false;
      } else if (povalue % 1 != 0) {
        element.FC1Validation = true;
        this.IsBPDataValid = false;
      } else if (element.FC1Amount.toString().indexOf('.') > -1) {
        element.FC1Validation = true;
        this.IsBPDataValid = false;
      } else {
        element.FC1Validation = false;
      }

      var povalue: number = parseFloat(element.FC2Amount.toString());
      if (povalue == undefined || povalue == null) {
        element.FC2Validation = true;
        this.IsBPDataValid = false;
      } else if (povalue < 0) {
        element.FC2Validation = true;
        this.IsBPDataValid = false;
      } else if (povalue % 1 != 0) {
        element.FC2Validation = true;
        this.IsBPDataValid = false;
      } else if (element.FC2Amount.toString().indexOf('.') > -1) {
        element.FC2Validation = true;
        this.IsBPDataValid = false;
      } else {
        element.FC2Validation = false;
      }

      var povalue: number = parseFloat(element.FC3Amount.toString());
      if (povalue == undefined || povalue == null) {
        element.FC3Validation = true;
        this.IsBPDataValid = false;
      } else if (povalue < 0) {
        element.FC3Validation = true;
        this.IsBPDataValid = false;
      } else if (povalue % 1 != 0) {
        element.FC3Validation = true;
        this.IsBPDataValid = false;
      } else if (element.FC3Amount.toString().indexOf('.') > -1) {
        element.FC3Validation = true;
        this.IsBPDataValid = false;
      } else {
        element.FC3Validation = false;
      }

      
    });
  }
  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/
  onSubmit(myform: any) {
  //  var unit = this.BusinessPlanobje.PlanList.filter(x => x.Units != 0);
    var amt = this.BusinessPlanobje.PlanList.filter(x => x.Amount != 0);
    // if (unit.length == 0) {
    //   this.zeroValues = true;
    //   alert("Units cannot be 0 for all the months.")
    //   return;
    // }

    if (amt.length == 0) {
      this.zeroValues = true;
      alert("Amount cannot be 0 for all the months.")
      return;
    }

    this.IsBPDataValid = true;
    this.checkValidBusinessPlan();
    if (!this.IsBPDataValid) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this business plan for sales and orders?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showLoader = true;
        this.FinalModel = [];
        this.zeroValues = false;

        if (this.allExistingData == null) {
          this.showLoader = true;
          this.Service.PostData('BusinessPlanSalesTarget', 'Add', this.BusinessPlanobje, null).subscribe((data: any) => {
            let result = data;
            if (result.ResponseCode == 1) {
              this.toastr.success('Business plan added successfully!');
              this.router.navigate(['/BusinessPlanDetails']);
              this.showLoader = false;
            }

          }, error => {
            this.toastr.error('Failed to add business plan');
            this.showLoader = false;
            this.router.navigate(['/BusinessPlanDetails']);
          });
        }
        else {
          this.showLoader = true;
          this.Service.PostData('BusinessPlanSalesTarget', 'Update', this.BusinessPlanobje, null).subscribe((data: any) => {
            let result = data;
            if (result.ResponseCode == 1) {
              this.toastr.success('Business plan updated successfully!');
              this.router.navigate(['/BusinessPlanDetails']);
              this.showLoader = false;
            }

          }, error => {
            this.toastr.error('Failed to update business plan');
            this.showLoader = false;
            this.router.navigate(['/BusinessPlanDetails']);
          });
        }
      }
    });
  }

  getAllUsers() {
    this.showLoader = true;
    this.Service.GetData("Employee", "GetAllsalesengg", null).subscribe((data: any) => {
      this.allEmployees = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  onselectChange() {
    if (this.BusnessPlan.UserName != undefined) {
      this.selectedUserData = this.allEmployees.filter(x => x.Fk_User_Id == this.BusinessPlanobje.EmployeeId);
      if (this.selectedUserData.length > 0) {
        this.BusinessPlanobje.RoleName = this.selectedUserData[0].RoleName;

      }
    }
    if (this.BusnessPlan.TargetFor == undefined || this.BusnessPlan.Year == undefined || this.BusnessPlan.UserName == undefined) {
      return;
    }


    let queryString = "";
    queryString = "TargetYear=" + this.BusinessPlanobje.TargetYear;
    queryString += "&TargetFor=" + this.BusinessPlanobje.TargetFor;
    queryString += "&EmployeeId=" + this.BusinessPlanobje.EmployeeId;

    this.Service.GetData("BusinessPlanSalesTarget", "GetExistingPlan", queryString).subscribe((data: any) => {
      this.allExistingData = data;
      if (data != null) {
        this.allExistingData = data.PlanList;
        var i = 0;
        data.PlanList.forEach(element => {
          this.BusinessPlanobje.PlanList[i].Id = element.Id;
         // this.BusinessPlanobje.PlanList[i].Units = element.Units;
          this.BusinessPlanobje.PlanList[i].Amount = element.Amount;
          this.BusinessPlanobje.PlanList[i].FC1Amount = element.FC1Amount==null?0:element.FC1Amount;
          this.BusinessPlanobje.PlanList[i].FC2Amount = element.FC2Amount==null?0:element.FC2Amount;
          this.BusinessPlanobje.PlanList[i].FC3Amount = element.FC3Amount==null?0:element.FC3Amount;
          i = i + 1;
        });
      }
      else {
        var i = 0;
        this.BusinessPlanobje.PlanList.forEach(element => {
          this.BusinessPlanobje.PlanList[i].Id = 0;
         // this.BusinessPlanobje.PlanList[i].Units = 0;
          this.BusinessPlanobje.PlanList[i].Amount = 0;
          this.BusinessPlanobje.PlanList[i].FC1Amount = 0;
          this.BusinessPlanobje.PlanList[i].FC2Amount = 0;
          this.BusinessPlanobje.PlanList[i].FC3Amount = 0;
          i = i + 1;
        });
      }
    });
  }

}
