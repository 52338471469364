import { query } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { AppGlobals } from 'src/app/app.global';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ProductMatrixModel } from 'src/app/Model/ProductMatrixModel';
import { ProductSeries } from 'src/app/Model/ProductModel';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-product-matrix',
  templateUrl: './product-matrix.component.html',
  styleUrls: ['./product-matrix.component.scss']
})
export class ProductMatrixComponent implements OnInit {

  @Input()
  showLoader: boolean = false;
  ProductList: any = [];
  ProductModelList: any = [];
  ProductMatrix: ProductMatrixModel = new ProductMatrixModel();
  ProductSeriesId: number;
  ProductModelId: number;
  isEditable: boolean = true;
  ProductSeries: ProductSeries[] = [];
  SelectedtSeriesdata:any;
  VariantDrpdown: string = "";
  charCode: any;
  UserRole: any;
  isProductMatrixEditable: boolean = false;
  ShowGrid:boolean = false;
  AlldataValid:boolean = true;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];

  
  constructor(private toastr: ToastrService, private commonService: CommonService, private router: Router,
    public dialog: MatDialog,private route: ActivatedRoute,private titleService: Title,private accessservice: AccessPermissionService,
    private appConfigService : AppConfigService) {
  }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    // this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
   // this.isProductMatrixEditable = AppGlobals.ProductMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.getAllProductSeries();
  }

  getAllProductSeries() {
    this.showLoader = true;
    this.commonService.GetData("ProductSeries", "GetAll", null).subscribe((data: any) => {
      this.ProductSeries = data.filter(x=>x.SeriesName == 'TC' || x.SeriesName == 'TR');
      this.ProductSeries = this.ProductSeries.sort((a, b) => (a.SeriesName < b.SeriesName ? -1 : 1));
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  ProductSeriesChange(evt:any)
  {
    this.showLoader = true;

    let queryString = "";
    queryString = "Id=" + this.ProductSeriesId;
    this.ShowGrid = false;
    this.commonService.GetData("ProductSeries", "GetById", queryString).subscribe((data: any) => {
      this.SelectedtSeriesdata = data;
      this.GetProductModelById(this.ProductSeriesId);
      this.isEditable = true;
      this.ProductMatrix = new ProductMatrixModel();
    this.ProductModelId = undefined;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
    
    this.ProductModelId = undefined;
  }

  RedirectToVariant(event: any) {
    if (this.VariantDrpdown == 'ProductModel') {
      this.router.navigate(['/ProductModel', this.SelectedtSeriesdata.Id]);
    }
    else if (this.VariantDrpdown == 'Options') {
      this.router.navigate(['/Options', this.SelectedtSeriesdata.Id]);
    }
    else if (this.VariantDrpdown == 'Variant1') {
      this.router.navigate(['/VariantLevel1', this.SelectedtSeriesdata.Id, this.SelectedtSeriesdata.Label_Variant1]);
    }
    else if (this.VariantDrpdown == 'Variant2') {
      this.router.navigate(['/VariantLevel2', this.SelectedtSeriesdata.Id, this.SelectedtSeriesdata.Label_Variant1, this.SelectedtSeriesdata.Label_Variant2]);
    }
    else if (this.VariantDrpdown == 'Variant3') {
      this.router.navigate(['/VariantLevel3', this.SelectedtSeriesdata.Id, this.SelectedtSeriesdata.Label_Variant2, this.SelectedtSeriesdata.Label_Variant3]);
    }
    else if (this.VariantDrpdown == 'Variant4') {
      this.router.navigate(['/VariantLevel4', this.SelectedtSeriesdata.Id, this.SelectedtSeriesdata.Label_Variant3, this.SelectedtSeriesdata.Label_Variant4]);
    }
  }

  GetProductModelById(SeriesId: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "SeriesId=" + SeriesId;
    this.commonService.GetData("ProductModel", "GetProductModelById", queryString).subscribe((data: any) => {
      this.ProductModelList = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getProductMatrix() {
    this.showLoader = true;
    let querystring = "";
    querystring = "ProductModelId=" + this.ProductModelId;
    this.commonService.GetData("ProductModel", "GetMatrix", querystring).subscribe((data: any) => {
      this.ProductMatrix = data;
      this.ShowGrid = true;
      window.scroll({
        left: 0,
        behavior: 'smooth'
      });
      this.isEditable = true;
      this.showLoader = false;
    });
  }

  SpeedStepPriceValidation(i:number,j:number)
  {
    let strprice =this.ProductMatrix.stationlist[i].speedsteplist[j].price.toString();
    if(this.ProductMatrix.stationlist[i].speedsteplist[j].price % 1 != 0)
    {
      this.ProductMatrix.stationlist[i].speedsteplist[j].isSpeedstepValid = false;
      this.AlldataValid = false;
    }
    else if(strprice.includes(".")){
      this.ProductMatrix.stationlist[i].speedsteplist[j].isSpeedstepValid = false;
      this.AlldataValid = false;
    }
    else if(strprice == "")
    {
      this.ProductMatrix.stationlist[i].speedsteplist[j].price = 0;
    }
    else{
      this.ProductMatrix.stationlist[i].speedsteplist[j].isSpeedstepValid = true;
      this.AlldataValid = true;
    }
  }

  StationPriceValidation(i:number)
  {
    let strprice = this.ProductMatrix.stationlist[i].price.toString();
    if(this.ProductMatrix.stationlist[i].price % 1 != 0)
    {
      this.ProductMatrix.stationlist[i].isStationValid = false;
      this.AlldataValid = false;
    }
    else if(strprice.includes(".")){
      this.ProductMatrix.stationlist[i].isStationValid = false;
      this.AlldataValid = false;
    }
    else if(strprice == "")
    {
      this.ProductMatrix.stationlist[i].price = 0;
    }
    else{
      this.ProductMatrix.stationlist[i].isStationValid = true;
      this.AlldataValid = true;
    }
  }
/*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/
  Cancel()
  {
    this.ProductMatrix = new ProductMatrixModel();
    this.ProductModelId = undefined;
    this.ProductSeriesId = undefined;
    this.ProductModelList = [];
    this.ShowGrid = false;
  }

  EditMatrix() {
      this.isEditable = false;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
  }

  SaveMatrix() {
   
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update this product matrix?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.showLoader = true;
      if (dialogResult) {
        this.commonService.PostData("ProductModel", "SaveMatrix", this.ProductMatrix, null).subscribe((data: any) => {
        
          if (data == 1) {
            this.toastr.success("Product matrix updated successfully!");
            this.isEditable = true;
           
            this.showLoader = false;
          }
          else {
            this.toastr.error("Failed to update product matrix!");
            this.showLoader = false;
          }
        }, error => {
          this.toastr.error("Failed to update product matrix!");
          this.showLoader = false;
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
      else {
        this.showLoader = false;
      }
    });
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isProductMatrixEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isProductLine')[0]?.IsEditable;
      
    });
 
  }
}
