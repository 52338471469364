<loader [showLoader]="showLoader"></loader>
<div
  class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-content">
      <div class="row">
        <div class="col-sm-12">
          <div class="m-portlet">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    {{ProductSeriesVariant3Label}} Listing
                  </h3>
                </div>
              </div>
              <div class="m-portlet__head-tools">
                <ul class="m-portlet__nav">
                  <li class="m-portlet__nav-item">
                    <a class="btn btn-primary m-btn m-btn--air m-btn--custom"
                      style=" white-space: normal;word-wrap: break-word;"
                      [routerLink]="['/AddVariantLevel3',ProductSeriesId]" [hidden]="!isProductVariant3Editable"
                      routerLinkActive="m-menu__item--active">
                      Add {{ProductSeriesVariant3Label}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="m-portlet__body">
              <div class="table-responsive">
                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      
                      <mat-label style="font-weight: bold;font-size: 15px;color: black;">{{ProductSeriesArr.SeriesName}}</mat-label><br>
                      <mat-form-field>
                        <mat-select [(ngModel)]="VariantDrpdown" name="Prodvariant"
                          (selectionChange)="RedirectToVariant($event)">
                          <mat-option value="ProductModel">Product Model</mat-option>
                          <mat-option
                            *ngIf="ProductSeriesArr.Label_Variant1 != '' && ProductSeriesArr.Label_Variant1 != null"
                            value="Variant1">{{ProductSeriesArr.Label_Variant1}}
                          </mat-option>
                          <mat-option
                            *ngIf="ProductSeriesArr.Label_Variant2 != '' && ProductSeriesArr.Label_Variant2 != null"
                            value="Variant2">{{ProductSeriesArr.Label_Variant2}}</mat-option>
                          <mat-option
                            *ngIf="ProductSeriesArr.Label_Variant3 != '' && ProductSeriesArr.Label_Variant3 != null"
                            value="Variant3">{{ProductSeriesArr.Label_Variant3}}</mat-option>
                          <mat-option
                            *ngIf="ProductSeriesArr.Label_Variant4 != '' && ProductSeriesArr.Label_Variant4 != null"
                            value="Variant4">{{ProductSeriesArr.Label_Variant4}}</mat-option>
                          <mat-option value="Options">Product Options</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 right">
                      <mat-form-field style="width: 50%;" class="pull-right">

                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="SearchParam($event)" (keypress)="AvoidSpace($event)" maxlength="50"
                          name="searchtext" [(ngModel)]="SearchText" #searchtext="ngModel" placeholder="Search" #input>
                      </mat-form-field>

                    </div>

                  </div>

                  <div class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                      class="table table-striped- table-bordered table-hover dataTable no-footer" role="grid">

                      <ng-container matColumnDef="SeriesName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Series </th>
                        <td mat-cell *matCellDef="let row"> {{row.SeriesName}} </td>
                      </ng-container>
                      <ng-container matColumnDef="ModelName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Model </th>
                        <td mat-cell *matCellDef="let row"> {{row.ModelName}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Level1Name">
                        <th [hidden]="SeriesName == 'TC' || SeriesName == 'TR' || !(isVariant2DependsOnVariant1 && isVariant3DependsOnVariant2)"
                          mat-header-cell *matHeaderCellDef mat-sort-header> {{ProductSeriesVariant1Label}} </th>
                        <td [hidden]="SeriesName == 'TC' || SeriesName == 'TR' || !(isVariant2DependsOnVariant1 && isVariant3DependsOnVariant2)" mat-cell
                          *matCellDef="let row">
                          {{row.Level1Name}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Level2">
                        <th [hidden]="SeriesName == 'TC' || SeriesName == 'TR' || !isVariant3DependsOnVariant2"
                          mat-header-cell *matHeaderCellDef mat-sort-header> {{ProductSeriesVariant2Label}} </th>
                        <td [hidden]="SeriesName == 'TC' || SeriesName == 'TR' || !isVariant3DependsOnVariant2" mat-cell
                          *matCellDef="let row">
                          {{row.Level2}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Level2Price">
                        <th [hidden]="SeriesName == 'TC' || SeriesName == 'TR' || !isVariant3DependsOnVariant2"
                          mat-header-cell *matHeaderCellDef mat-sort-header> {{ProductSeriesVariant2Label + " - Price"}}
                          (€) </th>
                        <td style="text-align: right;"
                          [hidden]="SeriesName == 'TC' || SeriesName == 'TR' || !isVariant3DependsOnVariant2" mat-cell
                          *matCellDef="let row">
                          {{row.Level2IsPriceOnRequest ? 'Price on Request': row.Level2Price |indianCurrency}}</td>
                      </ng-container>
                      <ng-container matColumnDef="Level3">
                        <th [hidden]="SeriesName != 'TC' && SeriesName != 'TR'" mat-header-cell *matHeaderCellDef>
                          {{ProductSeriesVariant3Label}}</th>
                        <td [hidden]="SeriesName != 'TC' && SeriesName != 'TR'" mat-cell *matCellDef="let row">
                          <ng-container *ngFor="let item of row.Level3; let i = index">
                            {{item.Level1}}{{i === row.Level3.length -1 ? '': ',' }}
                          </ng-container>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="otherType">
                        <th [hidden]="SeriesName == 'TC' || SeriesName == 'TR'" mat-header-cell *matHeaderCellDef
                          mat-sort-header> {{ProductSeriesVariant3Label}} </th>
                        <td [hidden]="SeriesName == 'TC' || SeriesName == 'TR'" mat-cell *matCellDef="let row">
                          {{row.otherType}}</td>
                      </ng-container>

                      <ng-container matColumnDef="Level3Price">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          {{ProductSeriesVariant3Label + " - Price"}} (€)</th>
                        <td mat-cell *matCellDef="let row" style="text-align: right;"> {{row.IsPriceOnRequest ? 'Price
                          on Request' : row.Level3Price |indianCurrency}} </td>
                      </ng-container>


                      <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let row">
                          <a *ngIf="row.SeriesName == 'TC' || row.SeriesName == 'TR'" mat-icon-button color="accent"
                            [routerLink]="['/EditViewVariantLevel3',row?.ProductModel,row?.Level3Price,row?.Status,row?.IsPriceOnRequest]"
                            routerLinkActive="active" title="View">
                            <i class="fa fa-eye fa-lg" aria-hidden="true"></i>
                          </a>
                          <a *ngIf="row.SeriesName != 'TC' && row.SeriesName != 'TR'" mat-icon-button color="accent"
                            [routerLink]="['/EditViewVariantLevel3',row?.Id]" routerLinkActive="active" title="View">
                            <i class="fa fa-eye fa-lg" aria-hidden="true"></i>
                          </a>&nbsp;&nbsp;&nbsp;&nbsp;
                          <mat-slide-toggle [hidden]="!isProductVariant3Editable" class="example-margin"
                            [checked]="row.Status" [disabled]="disabled"
                            (change)="UpdateVariantLevel3StatusById(row,row.Status)">
                          </mat-slide-toggle>&nbsp; {{row.Status==true ? 'Active': 'Inactive'}}
                        </td>
                      </ng-container>


                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                    </table>
                    <div
                      *ngIf="dataSource != undefined && (dataSource.filteredData == null || dataSource.filteredData?.length === 0)"
                      class="text-center">No records found</div>

                    <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                      [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent($event)">
                    </mat-paginator>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>