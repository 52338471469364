<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Add {{VariantLevel3obj.Label_Variant3}}
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm" appFocusInvalidInput
                            (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                            <div class="m-portlet__body">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Product Series:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="productseries"
                                            [(ngModel)]="VariantLevel3obj.SeriesName" #productseries="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productseries.invalid || productseries?.touched && productseries.invalid}"
                                            placeholder="Enter Product Series" (keypress)="AvoidSpace($event)" required
                                            (change)="VariantLevel3obj.SeriesName=VariantLevel3obj.SeriesName.trim()"
                                            disabled>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && productseries.invalid || productseries?.touched && productseries.invalid">
                                            <div *ngIf="myform.hasError('required', 'productseries')">
                                                Product series is required
                                            </div>
                                        </div>

                                    </div>

                                    <div class="offset-1"></div>
                                    <label class="col-lg-2  col-form-label">Product Model:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control" id="sel1" name="productmodel"
                                            [(ngModel)]="VariantLevel3obj.ProductModel" #productmodel="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productmodel.invalid || productmodel?.touched && productmodel.invalid}"
                                            (change)='onModelSelected($event)' required>
                                            <option value="null" disabled>Select Product Model</option>
                                            <option *ngFor="let item of ProductModel" value="{{item.Id}}">
                                                {{item.ModelName}} </option>
                                            <option disabled *ngIf="ProductModel.length <= 0" value="No Data Found"> No
                                                Data Found</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && productmodel.invalid || productmodel?.touched && productmodel.invalid">
                                            <div *ngIf="myform.hasError('required', 'productmodel')">
                                                Product model is required
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-group m-form__group row"
                                    *ngIf="VariantLevel3obj.SeriesName == 'TC' || VariantLevel3obj.SeriesName == 'TR'">
                                    <label class="col-lg-2 col-form-label">{{VariantLevel3obj.Label_Variant3}}:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <mat-select class="form-control"
                                            placeholder="Select {{VariantLevel3obj.Label_Variant3}}"
                                            [(ngModel)]="Level3Currdata"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && Variant3.invalid || Variant3?.touched && Variant3.invalid}"
                                            #Variant3="ngModel" multiple name="Variant3" required>
                                            <mat-option *ngFor="let item of Level3arr" value="{{item.Level1}}">
                                                {{item.Level1}}</mat-option>
                                            <mat-option disabled *ngIf="Level3arr.length <= 0" value="No Data Found"> No
                                                Data Found </mat-option>
                                        </mat-select>
                                        <div class="invalid-feedback"
                                            *ngIf="(myform.submitted && Variant3.invalid || Variant3?.touched && Variant3.invalid)">
                                            <div *ngIf="myform.hasError('required', 'Variant3')">
                                                {{VariantLevel3obj.Label_Variant3}} is required
                                            </div>
                                        </div>
                                        

                                    </div>
                                    <div class="col-lg-3">
                                        <a title="Add" (click)="OpenPopupAddPosition()" title="Add Position"
                                            style="color:#000000;width: 25px;height: 25px;margin-top: 6px;"
                                            data-toggle="modal" data-target="#myModal"
                                            class="btn btn-primary m-btn m-btn--icon m-btn--icon-only mr-2"><i
                                                class="fa fa-plus"></i></a>
                                    </div>


                                </div>

                                <div class="form-group m-form__group row"
                                    *ngIf="VariantLevel3obj.SeriesName != 'TC' && VariantLevel3obj.SeriesName != 'TR' && VariantLevel3obj.IsLevel3DepOnLevel2">
                                    <label *ngIf="VariantLevel3obj.IsLevel3DepOnLevel2 && VariantLevel3obj.IsLevel2DepOnLevel1"
                                        class="col-lg-2 col-form-label">{{VariantLevel3obj.Label_Variant1}}:<span
                                            style="color:#f4516c;"
                                           >&nbsp;*</span></label>
                                    <div *ngIf="VariantLevel3obj.IsLevel3DepOnLevel2 && VariantLevel3obj.IsLevel2DepOnLevel1" class="col-lg-3">

                                        <select class="form-control" id="sel1" name="var1"
                                            [(ngModel)]="VariantLevel3obj.Level1Id" #var1="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && var1.invalid || var1?.touched && var1.invalid}"
                                            [required]="VariantLevel3obj.IsLevel3DepOnLevel2 && VariantLevel3obj.IsLevel2DepOnLevel1"
                                            (change)='onVariant1Selected($event)'>
                                            <option value="null" disabled selected>Select
                                                {{VariantLevel3obj.Label_Variant1}}</option>
                                            <option *ngFor="let item of Variant1Arr" value="{{item.Id}}">{{item.Level1}}
                                            </option>
                                            <option disabled *ngIf="Variant1Arr.length <= 0" value="No Data Found"> No
                                                Data Found</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="(myform.submitted && var1.invalid || var1?.touched && var1.invalid)">
                                            <div *ngIf="myform.hasError('required', 'var1')">
                                                {{VariantLevel3obj.Label_Variant1}} is required
                                            </div>
                                        </div>

                                    </div>
                                    <div class="offset-1" *ngIf="VariantLevel3obj.IsLevel3DepOnLevel2 && VariantLevel3obj.IsLevel2DepOnLevel1"></div>

                                    <label *ngIf="VariantLevel3obj.IsLevel3DepOnLevel2"
                                        class="col-lg-2 col-form-label">{{VariantLevel3obj.Label_Variant2}}:<span
                                            style="color:#f4516c;"
                                            *ngIf="VariantLevel3obj.IsLevel3DepOnLevel2">&nbsp;*</span></label>
                                    <div *ngIf="VariantLevel3obj.IsLevel3DepOnLevel2" class="col-lg-3">

                                        <select class="form-control" id="sel1" name="var2"
                                            [(ngModel)]="VariantLevel3obj.Level2Id" #var2="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && var2.invalid || var2?.touched && var2.invalid}"
                                            [required]="VariantLevel3obj.IsLevel3DepOnLevel2">
                                            <option value="null" disabled selected>Select
                                                {{VariantLevel3obj.Label_Variant2}}</option>
                                            <option *ngFor="let item of Level2arr" value="{{item.Id}}">{{item.Level1}}
                                            </option>
                                            <option disabled *ngIf="Level2arr.length <= 0" value="No Data Found"> No
                                                Data Found</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="(myform.submitted && var2.invalid || var2?.touched && var2.invalid)">
                                            <div *ngIf="myform.hasError('required', 'var2')">
                                                {{VariantLevel3obj.Label_Variant2}} is required
                                            </div>
                                        </div>

                                    </div>
                                    <div class="offset-1" *ngIf="!VariantLevel3obj.IsLevel2DepOnLevel1"></div>
                                    <label class="col-lg-2 col-form-label" *ngIf="(!VariantLevel3obj.IsLevel2DepOnLevel1)">{{VariantLevel3obj.Label_Variant3}}:<span
                                        style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3" *ngIf="(!VariantLevel3obj.IsLevel2DepOnLevel1)">
                                    <input class="form-control m-input" type="text" name="var3"
                                        [(ngModel)]="Level3Currdata[0]" #var3="ngModel"
                                        [ngClass]="{ 'is-invalid':myform.submitted  && var3.invalid || var3?.touched && var3.invalid}"
                                        placeholder="Enter {{VariantLevel3obj.Label_Variant3}}"
                                        (keypress)="AvoidSpace($event)" maxlength="130"
                                        (change)="Level3Currdata[0]=Level3Currdata[0].trim()" required>
                                    <div class="invalid-feedback"
                                        *ngIf="(myform.submitted && var3.invalid || var3?.touched && var3.invalid)">
                                        <div *ngIf="myform.hasError('required', 'var3')">
                                            {{VariantLevel3obj.Label_Variant3}} is required
                                        </div>
                                    </div>

                                </div>

                                </div>

                                <div class="form-group m-form__group row"
                                    *ngIf="VariantLevel3obj.SeriesName != 'TC' && VariantLevel3obj.SeriesName != 'TR' && ((VariantLevel3obj.IsLevel3DepOnLevel2 && VariantLevel3obj.IsLevel2DepOnLevel1)|| (!VariantLevel3obj.IsLevel3DepOnLevel2)) ">

                                    <label class="col-lg-2 col-form-label">{{VariantLevel3obj.Label_Variant3}}:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="var3"
                                            [(ngModel)]="Level3Currdata[0]" #var3="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && var3.invalid || var3?.touched && var3.invalid}"
                                            placeholder="Enter {{VariantLevel3obj.Label_Variant3}}"
                                            (keypress)="AvoidSpace($event)" maxlength="130"
                                            (change)="Level3Currdata[0]=Level3Currdata[0].trim()" required>
                                        <div class="invalid-feedback"
                                            *ngIf="(myform.submitted && var3.invalid || var3?.touched && var3.invalid)">
                                            <div *ngIf="myform.hasError('required', 'var3')">
                                                {{VariantLevel3obj.Label_Variant3}} is required
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Price (€):</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="price" min="0"
                                            [(ngModel)]="VariantLevel3obj.Level3Price" #price="ngModel" pattern="[0-9]+"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && price.invalid || price?.touched && price.invalid}"
                                            style="text-align:right;" placeholder="Enter Price" maxlength="7"
                                            (keypress)="AvoidSpace($event);isNumberKey($event);"
                                            [disabled]="VariantLevel3obj.IsPriceOnRequest">
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && price.invalid || price?.touched && price.invalid">

                                            <div *ngIf="myform.hasError('pattern', 'price')">
                                                Price must be an integer</div>
                                        </div>
                                    </div>
                                    <div class="offset-1"></div>
                                    <div class="col-lg-3">
                                        <mat-checkbox name="dep2" #dep2="ngModel"
                                            [(ngModel)]="VariantLevel3obj.IsPriceOnRequest"
                                            (change)="onIsPriceOnRequest($event)">
                                            <label>Is Price On Request</label>
                                        </mat-checkbox>
                                    </div>
                                    <div class="col-lg-3"
                                        *ngIf="(VariantLevel3obj.SeriesName != 'TC' && VariantLevel3obj.SeriesName != 'TR' )">
                                        <mat-checkbox name="dep1" #dep1="ngModel"
                                            [(ngModel)]="VariantLevel3obj.IsUnits">
                                            <label>Is Units</label>
                                        </mat-checkbox>
                                    </div>
                                </div>

                            </div>

                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a [routerLink]="['/VariantLevel3',VariantLevel3obj?.ProductSeries,VariantLevel3obj?.Label_Variant2,VariantLevel3obj?.Label_Variant3]"
                                                routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

<!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
    <div class="custsize">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content custContent">
                <div class="modal-body">
                    <div class="multisteps-form__panel shadow p-4 rounded bg-white js-active" data-animation="fadeIn">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="m-portlet">
                                    <div class="modal-header">
                                        <h5 class="modal-title" style="font-weight: 450">Add Position</h5>
                                        <!-- <button type="button" class="btn btn-primary"  data-dismiss="modal">
                            <span>&times;</span>
                        </button> -->
                                    </div>
                                    <div class="markedFields">
                                        <span style="margin-left:10px;">* Marked fields are
                                            mandatory</span>
                                    </div>
                                    <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right"
                                        name="myform1" #myform1="ngForm"
                                        (ngSubmit)="myform1.form.valid && AddPosition(myform1)" novalidate>

                                        <div class="m-portlet__body">

                                            <div class="form-group m-form__group row">
                                                <label class="col-lg-5 col-form-label">Position :<span
                                                        style="color:#f4516c;">&nbsp;*</span></label>
                                                <div class="col-lg-6">
                                                    <input class="form-control m-input" type="text" name="position"
                                                        id="position" [(ngModel)]="PositionName" #positionName="ngModel"
                                                        [ngClass]="{ 'is-invalid':myform1.submitted  && positionName.invalid || positionName?.touched && positionName.invalid}"
                                                        placeholder="Enter Position" (keypress)="AvoidSpace($event)"
                                                        maxlength="15" (change)="PositionName=PositionName.trim()"
                                                        required >

                                                    <div class="invalid-feedback"
                                                        *ngIf="myform1.submitted && positionName.invalid || positionName?.touched && positionName.invalid">
                                                        <div *ngIf="myform1.hasError('required', 'positionName')">
                                                            Position is required</div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                            <div class="m-form__actions m-form__actions--solid" style="padding: 16px;">
                                                <div class="row">
                                                    <div class="col-lg-2"></div>
                                                    <div class="col-lg-10 button-row text-right mt-3">
                                                        <button id="m_login_signin_submit"
                                                            class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                                        <button type="button" class="btn btn-metal" #closebutton
                                                            data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>