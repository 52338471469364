<div class="row">
    <div class="col-sm-6">
        <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
            <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="regionfilterform"
                (ngSubmit)="regionfilterform.form.valid" #regionfilterform="ngForm" appFocusInvalidInput novalidate>

                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Product Series
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body">
                    <div class="form-group m-form__group row">
                        <!-- <label class="col-lg-3 col-form-label">First Filter:<span
                                style="color:#f4516c;">&nbsp;*</span></label> -->
                        <div class="col-lg-4">
                            <!-- <select class="form-control "
                            id="supplychaintype"
                            name="supplychaintype"
                            [(ngModel)]="SelectedRegion"
                            (change)="onRegionChange()"
                            required>
                            <option value=undefined selected disabled>
                                Select</option>
                                <option *ngFor="let item of regionList" value={{item.Region.Id}}>
                                    {{item.Region.Name}}
                                </option>
                        </select> -->

                        </div>
                    </div>
                    <div id="ProductSeriesBarChart"
                        style="width: 90%; height: 400px; background-color: #FFFFFF;margin-left: 5%;"></div>

                </div>
            </form>
        </div>
    </div>
    <div class="col-sm-6">
        <app-productmodel-columnchart></app-productmodel-columnchart>
    </div>
</div>