import { ChangeDetectorRef, Injectable,NgZone} from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { resolve } from 'dns';
import { access } from 'fs';
import { Observable, of } from 'rxjs';
import { promise } from 'selenium-webdriver';
import { HeaderMenuModel, Menuelement } from '../Model/HeaderMenuModel';
import { AccessPermissionModel } from '../Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from '../Service/access-permission.service';
import { CommonService } from '../Service/common.service';


@Injectable()
export class RoleGuard implements CanActivate {
    loginUser: any;
    accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
    AccesspermissionForRole:any[]=[];
    menuaccessinfo:Menuelement[]=Array<Menuelement>();
    map = new Map<string, boolean>();
    mapedit=new Map<string, boolean>();
    hasAccess:boolean=false;

    constructor(private router: Router,private NgZone:NgZone,private accessservice: AccessPermissionService) {
 }
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean>| Promise<boolean> | boolean {
        const Element = route.data.Element;
        const Access = route.data.Access;
        const redirectTo=route.data.redirectTo;
        const userRole: any = localStorage.getItem("UserRole");
        return new Promise((resolve) => { 
            if(Element && Access)
            {
               
                if(this.accesspermissionobj.length==0)
                {
                    this.accessservice.GetAll().subscribe((data:any)=>
                    {
                        this.accesspermissionobj=data;
                        localStorage.setItem("accesspermissionobj", JSON.stringify(this.accesspermissionobj));
                       
                        resolve(this.hasAccessPermission(route));
                     });
                 }
                 else{
                    resolve(this.hasAccessPermission(route));
                  
                 }
                 
            }
            else
            {
                 resolve(this.hasRequiredPermission(route));
            }
         });

        
        // if(Element!=null)
        // {
        //         this.accessservice.GetAll().toPromise((data:any)=>{
                // this.accesspermissionobj=data;
                // const userRole: any = localStorage.getItem("UserRole");
                // this.AccesspermissionForRole = this.accesspermissionobj.filter(x => x.Fk_RoleId == userRole && x.ElementKey==Element);
                // console.log("Rules",this.AccesspermissionForRole)
                // resolve(this.hasAccessPermission(this.AccesspermissionForRole,Access,redirectTo));
        //      });
        // }
        // else{
        //    // return of(this.hasRequiredPermission(route));
        //     return of(true);
        //      }
       
    }
  
    protected hasAccessPermission(route: ActivatedRouteSnapshot): boolean {
        // If user’s permissions already retrieved from the API
        // const role: string = <string>localStorage.getItem('role');
        const accessToken: any = localStorage.getItem("userToken");
        const Element = route.data.Element;
        const Access = route.data.Access;
        const redirectTo=route.data.redirectTo;
        const userRole: any = localStorage.getItem("UserRole");
        let AccesspermissionForRole = this.accesspermissionobj.filter(x => x.ElementKey==Element);
      // return true;
        if (!accessToken) {
           // this.router.navigate(['/login']);
            this.router.navigateByUrl('/');
        } else if (userRole != null && accessToken != null) {
           
            if(Access=="IsVisible")
            {
                const hasPermission = AccesspermissionForRole.filter(x=>x.Fk_RoleId == userRole)[0]?.IsVisible;
                 
                if (hasPermission) {
                    return true;
                    
                } else {
                    alert('You do not have rights to access this page.');
                    //this.router.navigate([next.data.permissions.redirectTo])
                    return false;
                }
            }else if(Access=="IsEditable")
            {
                const hasPermission = AccesspermissionForRole.filter(x=>x.Fk_RoleId == userRole)[0]?.IsEditable;
                 
                if (hasPermission) {
                    return true;
                    
                } else {
                    alert('You do not have rights to access this page.');
                    //this.router.navigate([next.data.permissions.redirectTo])
                    return false;
                }
            }
            else if(Access=="CanList")
            {
                const hasPermission = AccesspermissionForRole.filter(x=>x.Fk_RoleId == userRole)[0]?.CanList;
                 
                if (hasPermission) {
                    return true;
                    
                } else {
                    alert('You do not have rights to access this page.');
                    //this.router.navigate([next.data.permissions.redirectTo])
                    return false;
                }
            }
            else if(Access=="CanCreate")
            {
                const hasPermission = AccesspermissionForRole.filter(x=>x.Fk_RoleId == userRole)[0]?.CanCreate;
                 
                if (hasPermission) {
                    return true;
                    
                } else {
                    alert('You do not have rights to access this page.');
                    //this.router.navigate([next.data.permissions.redirectTo])
                    return false;
                }
            }
            
            
           
        } else {
            this.router.navigate([redirectTo]);
            return false;
        }
    }


      protected hasRequiredPermission(next: any): Promise<boolean> | boolean {
        // If user’s permissions already retrieved from the API
        // const role: string = <string>localStorage.getItem('role');
        const accessToken: any = localStorage.getItem("userToken");
        const userRole: any = localStorage.getItem("UserRole");
        if (!accessToken) {
           // this.router.navigate(['/login']);
            this.router.navigateByUrl('/');
        } else if (userRole != null && accessToken != null) {
            
            if (next.data.permissions.only.filter(x => x == userRole).length > 0) {
                return true;
                
            } else {
                alert('You do not have rights to access this page.');
                //this.router.navigate([next.data.permissions.redirectTo])
                return false;
            }
        } else {
            this.router.navigate([next.data.permissions.redirectTo]);
            return false;
        }
    }

}