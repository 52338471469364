import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { QuotationDetailsModel, ProductDetailsModel, ProductDrpdnDetails, OptionsModel, QuotationCustomerDetails, TechnicalSpecifications, QuotationInfo } from 'src/app/Model/QuotationModel';
import { number } from '@amcharts/amcharts4/core';
import { MatDialog } from '@angular/material/dialog';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { NgbActiveModal, NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/Service/common.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Form, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from 'src/app/app.global';
import { EnquiryStatus } from "src/app/EnquiryStatus";
import { UserRoles } from "src/app/UserRoles";
import { EnquiryDetailsModel, ProductDetails } from 'src/app/Model/EnquiryModel';
import { EnquiryStatusList } from 'src/app/Model/EnquiryStatus';
import { empty, forkJoin } from 'rxjs';
import { element } from 'protractor';
import { EmployeeGuestModel } from 'src/app/Model/EmployeeGuestModel';
import { map } from '@amcharts/amcharts4/.internal/core/utils/Iterator';
import { tap } from 'rxjs/operators';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-enquiry-modal',
  templateUrl: 'enquiry-modal.component.html',
  styleUrls: ['./enquiry-modal.component.scss']
})

export class EnquiryModalComponent implements OnInit {
  dropdownData: any;
  QuotationData: QuotationDetailsModel = new QuotationDetailsModel();
  EnquiryObj: EnquiryDetailsModel = new EnquiryDetailsModel();
  QuotationDatatoExport: QuotationDetailsModel = new QuotationDetailsModel();
  productDrpdnDetails: ProductDrpdnDetails = new ProductDrpdnDetails();
  EmployeeDetails: EmployeeGuestModel = new EmployeeGuestModel();
  dropdownSettings = {};
  enquiryStatus = EnquiryStatus;
  IsQuotationDataValid: boolean = true;
  isQtyDiscountEditable:boolean=false;
  AppRoles: UserRoles;
  WeissLogoPath = "SITELOGO";
  Roles: any[] = [];
  isMenu:boolean=true;
  currentDate: Date = new Date();
  QuotationPdfGenerationHostPath:any;

  @Output() updateLoaderEvent = new EventEmitter<boolean>();

  @ViewChild('QuotationForm') QuotationForm: NgForm;
  @ViewChild('refDateElement') refDateElement: ElementRef;
  @ViewChild('yourRefElement') yourRefElement: ElementRef;
  @ViewChild('keyPerElement') keyPerElement: ElementRef;
  @ViewChild('techspec') techspec: ElementRef;
  @ViewChild('keyPerEmail') keyPerEmail: ElementRef;
  @ViewChild('keyPerCNo') keyPerCNo: ElementRef;
  @ViewChild('QuoteComm') QuoteComm: ElementRef;

  @Input() enquiObj;
  productseriesDropdownData: any;
  productmdodelDropdownData: any;
  variantDropdownData: any;
  variant1Data: any[];
  variant2Data: any[];
  variant3Data: any[];
  variant4Data: any[];
  variantLabl1: any;
  variantLabl2: any;
  variantLabl3: any;
  variantLabl4: any;
  Options: any[] = [];
  optionsList: any[];
  selectedOptions: any[] = [];
  OptionsNameList: any[] = [];
  CustomerDetailsObj: QuotationCustomerDetails = new QuotationCustomerDetails();
  enquiryStatusPassed: any;
  OptionsPrice: number;
  IsQuotationPriceOnRequest: any;
  optionsSelected: any[] = [];
  btnforL1: boolean = false;
  btnforL2: boolean = false;
  btnforL3: boolean = false;
  btn_PriceOnRequest: boolean = false;
  isPriceOnRequestVisible: boolean;
  user: string;
  isQuotationEditable: boolean;
  isfetchfromRFQ: boolean = false;
  isfinalamount: boolean = false;

  finalpricewithoutspecialdiscount: any = 0;
  discountaddmanually: boolean ;
  isPricesRequestedBeforeSubmit: boolean;
  TermsandConditions: any;
  SalesContactPersonValidation:boolean;
  YourRefValidation: boolean;
  RefDateValidation: boolean;
  KindAttValidation: boolean;
  isPriceOnRequestValid: boolean;
  columnCount: any = 0;
  showTechnicalSpecs: TechnicalSpecifications[] = new Array<TechnicalSpecifications>();
  role: string;
  btnforCS: boolean;
  charCode: any;
  KindAttContactNoValidilation: boolean;
  KingAttConactNumberValidationMsg: string = "";
  KindAttEmailValidilation: boolean;
  KingAttEmailValidationMsg: string = "";
  minDate: NgbDate;
  maxDate: NgbDate;
  displayfurtherrows: boolean = false;


  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  QuoteCommentsValidation: Boolean;
  DiscountforOneOrAll: number;
  showVarientandOptionPrice: boolean = false;
  showVarientDiscount: boolean = false;
  showSpecialPrice: boolean = false;
  specialPkgDiscount: number;

  PendingAtRoleId: any;
  CustomTermsAndConditions: any;
  enqTcSelected: any;
  isenqTcSelected: boolean;
  transitionRule: any;
  EmployeeData: any[] = [];

  workflowActionList: any[] = [];
  enquiryStatusList: EnquiryStatusList[] = [];
  workflowId: number = 6;
  @Input() showLoader: boolean = false;
  authorityLevels: any;
  priceOnRequestAuthorities:any;
 
  priceonRequestDisabled:boolean=true;
  ispriceOnrequestTrigger: boolean=false;
  frameAgreemtdiscountDisabled: boolean=false;
  userRole: string;
  UserRolesData: any;
  isAppEngRoles: boolean=true;
  showOverallDiscount: boolean;
  mailIdFormat : any;
  salesPersonOfWhichComapany : any;
  
  constructor(
    private modalService: NgbModal
    , private commonService: CommonService
    , private activeModal: NgbActiveModal
    , public dialog: MatDialog
    , private toastr: ToastrService
    , private route: ActivatedRoute
    , private cd: ChangeDetectorRef
    , private router: Router
    , private Service: CommonService,
    private appConfigService: AppConfigService) {
    this.minDate = new NgbDate(2018, 1, 1);
    this.maxDate = new NgbDate(this.currentDate.getFullYear(), 12, 31);
    // Initialize KingAttEmailValidationMsg with a placeholder message
    this.KingAttEmailValidationMsg = 'Should be in Email Id format ([emailFormat])';
  }


  ngOnInit(): void {
    this.showLoader=true;
    this.mailIdFormat = this.appConfigService.getConfig().mailIdFormat;
    this.QuotationPdfGenerationHostPath=this.appConfigService.getConfig().QuotationPdfGenerationHostPath;
    this.salesPersonOfWhichComapany = this.appConfigService.getConfig().salesPersonOfWhichComapany;
    this.role = localStorage.getItem("UserRole");
    this.QuotationData.QuotationInfo.EnquiryId = this.route.snapshot.params["id"];//this.enquiObj.EnquiryId;
  
    this.hasAccesstoFillQtyDiscount();
    this.enquiryStatusPassed = this.enquiObj.EnquiryStatus;
    this.IsQuotationPriceOnRequest = this.enquiObj.IsQuotationPriceOnRequest;
    this.enqTcSelected = this.enquiObj.EnqTermsConditionSelected;
    this.TermsandConditions = this.enquiObj.TermsAndCondition;
    this.CustomTermsAndConditions = this.enquiObj.CustomTermsAndCondition;
    this.PendingAtRoleId = this.enquiObj.PendingAtRoleId;
    this.EnquiryObj = this.enquiObj;
    let enquirystatus = this.enquiryStatusPassed;

    if (this.enqTcSelected == 'Standard') {
      this.isenqTcSelected = true;
    } else {
      this.isenqTcSelected = false;
    }
    this.getAllEmployeeData();
    this.getUserRoles();
    
     this.getEnquiryStatus().subscribe((data:any)=>{   
        this.getQuotationDetailsById();        
        this.getWorkflowActions(); 
        this.getPORAuthorities();
    });
   
    
  }

  GetXMLData() {
    this.showLoader=true;
    let queryString = "";
    queryString = "id=" + this.enquiObj.EnquiryId;
    this.commonService.GetData("Enquiry", "GetQuotationFromXMl", queryString).subscribe((data: any) => {
      if (data != null) {
        this.QuotationData.QuotationInfo = data.QuotationInfo;
        this.QuotationData.QuotationObj = data.QuotationObj;

        if (this.QuotationData.QuotationInfo.ReferenceDateISO == undefined) this.QuotationData.QuotationInfo.ReferenceDateISO = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
        else this.QuotationData.QuotationInfo.ReferenceDateISO = new NgbDate(new Date(this.QuotationData.QuotationInfo.ReferenceDate).getFullYear(), new Date(this.QuotationData.QuotationInfo.ReferenceDate).getMonth() + 1, new Date(this.QuotationData.QuotationInfo.ReferenceDate).getDate());
        if (this.QuotationData.QuotationObj.length == 0) {
          var obj = new ProductDetailsModel();
          this.QuotationData.QuotationObj.push(obj);
        }
        
        //all series data
        this.dropdownData = data.allseriesmodel;
        this.productDrpdnDetails.ProductSeries = this.dropdownData.ProductSeries;
        this.CustomerDetailsObj = data.allseriesmodel.CustomerDetails;

        this.QuotationData.QuotationInfo.KindAttention = this.CustomerDetailsObj.KindAttention;
        var inde = 0;
        this.QuotationData.QuotationObj.forEach(element => {

          
          this.discountaddmanually = false;
          
          this.calculateTotalPricesForProduct(inde, 0);
          this.specialPkgDiscount = element.Special_pkg_Discount === null ? 0 : element.Special_pkg_Discount;
          
          this.showVarientandOptionPrice = element.showVariantOptionPrice === null ? false : element.showVariantOptionPrice;
          this.showVarientDiscount = element.showVariantOptionDiscount === null ? false : element.showVariantOptionDiscount;
          this.showSpecialPrice = element.showSpecialPrice === null ? false : element.showSpecialPrice;

          inde++;

        });


      }
      if(this.IsQuotationPriceOnRequest)
      {
        this.btn_PriceOnRequest = true;
      }
      //this.getPriceDiscountApprovalAuthority();
      this.showLoader=false;
    }, error => {
      this.toastr.error("Failed to load data.");
      this.showLoader=false;
    });
  }
  getQuotationDetailsById() {
    this.showLoader=true;
    let queryString = "";
    queryString = "id=" + this.enquiObj.EnquiryId;
    this.commonService.GetData('Enquiry', 'GetQuotationByEnquiryId', queryString).subscribe((data: any) => {

      this.QuotationData.QuotationInfo = data.QuotationInfo;
      this.QuotationData.QuotationObj = data.QuotationObj;
      this.QuotationData.QuotationInfo.ReferenceDateISO = new NgbDate(new Date(this.QuotationData.QuotationInfo.ReferenceDate).getFullYear(), new Date(this.QuotationData.QuotationInfo.ReferenceDate).getMonth() + 1, new Date(this.QuotationData.QuotationInfo.ReferenceDate).getDate());
      //all series data
      this.dropdownData = data.allseriesmodel;
      this.productDrpdnDetails.ProductSeries = this.dropdownData.ProductSeries;
      this.CustomerDetailsObj = data.allseriesmodel.CustomerDetails;
      this.QuotationData.QuotationInfo.KindAttention = this.CustomerDetailsObj.KindAttention;
      // this.QuotationData.QuotationInfo.QuotationComments=this.CustomerDetailsObj. 
      if(!this.QuotationData || !this.QuotationData.QuotationObj || this.QuotationData.QuotationObj.length==0)
      {
        this.GetXMLData();
      }
       else
      {
      this.QuotationData.QuotationObj?.forEach(element => {
        if (!(element.Discount==0 || element.Discount==null || element.Discount==undefined)) {
          this.discountaddmanually = false;
        }else{
          this.discountaddmanually = true;
        }
        element.selectedOptions?.forEach(a => {
          if (element.productmodeldata.Options.length != 0) {
            element.productmodeldata.Options?.forEach(b => {
              if (a.Id == b.Id && b.IsPriceOnRequest == true) {
                a.IsPriceOnRequest = true;
              }
            });
          }
          element.Options?.forEach(ele => {
            element.Option_Discount = ele.Option_Discount;
            element.Option_FinalDiscount = ele.Option_FinalDiscount;
          
          });
          this.specialPkgDiscount = element.Special_pkg_Discount === null ? 0 : element.Special_pkg_Discount;
          this.showVarientandOptionPrice = element.showVariantOptionPrice === null ? false : element.showVariantOptionPrice;
          this.showVarientDiscount = element.showVariantOptionDiscount === null ? false : element.showVariantOptionDiscount;
          this.showSpecialPrice = element.showSpecialPrice === null ? false : element.showSpecialPrice;
        });

        let data = element.Options.filter(x => x.IsPriceOnRequest == true);
        if (data.length > 0 && data.length != null && data.length != undefined) {
          element.IsPriceOnRequest_Option = true;
        }
        else {
          element.IsPriceOnRequest_Option = false;
        }
      });

      var inde = 0;
      this.QuotationData.QuotationObj?.forEach(element => {
        element.OptionSelected = [];

        // if (enquirystatus == this.enquiryStatusList.filter(x => x.Status == 'QuotationSendtoCustomerServicebyL1')[0]?.Pk_Id ||
        //   enquirystatus == this.enquiryStatusList.filter(x => x.Status == 'QuotationSendtoCustomerServicebyL2')[0]?.Pk_Id ||
        //   enquirystatus == this.enquiryStatusList.filter(x => x.Status == 'QuotationSendtoCustomerServicebyL3')[0]?.Pk_Id ||
        //   enquirystatus == this.enquiryStatusList.filter(x => x.Status == 'QuotationSaveAsDraftbyCustomerService')[0]?.Pk_Id ||
        //   enquirystatus == this.enquiryStatusList.filter(x => x.Status == 'QuotationSaveAsDraftbyL1')[0]?.Pk_Id ||
        //   enquirystatus == this.enquiryStatusList.filter(x => x.Status == 'QuotationSaveAsDraftbyL2')[0]?.Pk_Id ||
        //   enquirystatus == this.enquiryStatusList.filter(x => x.Status == 'QuotationSaveAsDraftbyL3')[0]?.Pk_Id) {
        //   this.calculateTotalPricesForProduct(inde, 1);
        // } else {
        //   this.calculateTotalPricesForProduct(inde, 0);
        // }
        this.calculateTotalPricesForProduct(inde, 0);
        element.Options?.forEach(ele => {
          element.OptionSelected.push(ele.OptionId.toString());
        });
        inde++;
      });
      if(this.IsQuotationPriceOnRequest)
      {
        this.btn_PriceOnRequest = true;	
      }
   }
      
     // this.getPriceDiscountApprovalAuthority();
     // this.showLoader=false;
    }, error => {
      this.toastr.error("Failed to load data.");
      this.showLoader=false;
    });
  }

  getAllEmployeeData() {
    this.showLoader = true;
    this.Service.GetData("Employee", "GetAllEmployeeData", null).subscribe((data: any) => {
      this.EmployeeData = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  showTechnicalSpecifications(i) {
    if (this.QuotationData.QuotationObj[i].ProductModel == undefined) {
      this.QuotationData.QuotationObj[i].ProductModelValidation = true;
      this.QuotationData.QuotationObj[i].ProductSeriesValidation = true;
      return;
    }
    this.QuotationData.QuotationObj[i].showTechnicalSpecifications = !this.QuotationData.QuotationObj[i].showTechnicalSpecifications;
    if (this.QuotationData.QuotationObj[i].showTechnicalSpecifications
      &&
      (this.QuotationData.QuotationObj[i].TechnicalSpecifications == undefined || this.QuotationData.QuotationObj[i].TechnicalSpecifications?.length == 0)) {


      var temp = [];
      this.QuotationData.QuotationObj[i].TechnicalSpecifications = [];
      var obj = new TechnicalSpecifications();
      obj.ProductId = this.QuotationData.QuotationObj[i].ProductId;
      obj.uniqueid = this.makeRandom();
      temp.push(obj);
      this.QuotationData.QuotationObj[i].TechnicalSpecifications = temp;
    }
  }

  ShowOptionPriceOnRequestGrid(i) {
    if (this.QuotationData.QuotationObj[i].ShowOptionPriceOnRequestGrid) {
      this.QuotationData.QuotationObj[i].ShowOptionPriceOnRequestGrid = false;
    }
    else {
      this.QuotationData.QuotationObj[i].ShowOptionPriceOnRequestGrid = true;
    }
  }
  makeRandom() {
    let text = "";
    var possible: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~";
    const lengthOfCode = 40;
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  addTechnicalSpec(i, index) {
    if (this.QuotationData.QuotationObj[i].TechnicalSpecifications[index].value != undefined) {
      var temp = this.QuotationData.QuotationObj[i].TechnicalSpecifications;
      this.QuotationData.QuotationObj[i].TechnicalSpecifications = [];
      var obj = new TechnicalSpecifications();
      obj.ProductId = this.QuotationData.QuotationObj[i].ProductId;
      obj.uniqueid = this.makeRandom();
      temp.push(obj);
      this.QuotationData.QuotationObj[i].TechnicalSpecifications = temp;

    }
  }
  deleteTechnicalSpec(i, tempindex) {
    var currentproduct = this.QuotationData.QuotationObj[i].TechnicalSpecifications;
    this.QuotationData.QuotationObj[i].TechnicalSpecifications = [];
    currentproduct.splice(tempindex, 1);
    this.QuotationData.QuotationObj[i].TechnicalSpecifications = currentproduct;
  }
  ButtonsAccess() {
    this.isQuotationEditable = true;
    /**************************Admin Access******************************* */
    if (this.role == UserRoles.Admin) {
      if (this.enquiObj.PendingAtRoleId == UserRoles.L1) {
        this.btnforL1 = true;
      }
      if (this.enquiObj.PendingAtRoleId == UserRoles.L2) {
        this.btnforL2 = true;
      }
      if (this.enquiObj.PendingAtRoleId == UserRoles.L3) {
        this.btnforL3 = true;
      }
      if (this.enquiObj.PendingAtRoleId == UserRoles.CustomerService) {
        this.btnforCS = true;
      }
      this.isQuotationEditable = true;
    }
    /**************************End Admin Access******************************* */
    if (this.role == UserRoles.L1) {
      this.btnforL1 = true;
      this.isQuotationEditable = true;
    }
    if (this.role == UserRoles.L2) {
      this.btnforL2 = true;
      this.isQuotationEditable = true;
    }
    if (this.role == UserRoles.L3) {
      this.btnforL3 = true;
      this.isQuotationEditable = true;
    }
    if (this.role == UserRoles.CustomerService) {
      this.btnforCS = true;
      this.isQuotationEditable = true;
    }
    if (this.IsQuotationPriceOnRequest) {     
            this.btn_PriceOnRequest = true;
     
    }
    if (this.enquiryStatusPassed != this.enquiryStatusList.filter(x => x.Status == 'RFQSubmittedbySalesEngg')[0]?.Pk_Id) {
      this.isPriceOnRequestVisible = true;
    }
  }


  clearRow(index) {
    this.productDrpdnDetails.ProductModel = [];
    this.QuotationData.QuotationObj[index].ProductModel = undefined;
    this.QuotationData.QuotationObj[index].Variant1Label = null;
    this.QuotationData.QuotationObj[index].Variant2Label = null;
    this.QuotationData.QuotationObj[index].Variant3Label = null;
    this.QuotationData.QuotationObj[index].Variant4Label = null;
    this.QuotationData.QuotationObj[index].Variant1 = undefined;
    this.QuotationData.QuotationObj[index].Variant2 = undefined;
    this.QuotationData.QuotationObj[index].Variant3 = undefined;
    this.QuotationData.QuotationObj[index].Variant4 = undefined;
    this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant1 = false;
    this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant2 = false;
    this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant3 = false;
    this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant4 = false;
    this.QuotationData.QuotationObj[index].IsPriceOnRequest_Option = false;
    this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
    this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
    this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
    this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
    this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
    this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
    this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
    this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
    this.QuotationData.QuotationObj[index].Variant1Price = 0;
    this.QuotationData.QuotationObj[index].Variant2Price = 0;
    this.QuotationData.QuotationObj[index].Variant3Price = 0;
    this.QuotationData.QuotationObj[index].Variant4Price = 0;
    this.QuotationData.QuotationObj[index].OptionsPrice = 0;
    this.QuotationData.QuotationObj[index].Options = [];
    this.QuotationData.QuotationObj[index].OptionSelected = [];
    this.QuotationData.QuotationObj[index].selectedOptions = [];
    this.QuotationData.QuotationObj[index].selectedVariant1 = [];
    this.QuotationData.QuotationObj[index].selectedVariant2 = [];
    this.QuotationData.QuotationObj[index].selectedVariant3 = [];
    this.QuotationData.QuotationObj[index].selectedVariant4 = [];
    this.Options = [];

    this.QuotationData.QuotationObj[index].Variant1Validation = false;
    this.QuotationData.QuotationObj[index].Variant2Validation = false;
    this.QuotationData.QuotationObj[index].Variant3Validation = false;
    this.QuotationData.QuotationObj[index].Variant4Validation = false;

    this.QuotationData.QuotationObj[index].Options_POR_Validation = false;
    this.QuotationData.QuotationObj[index].ProductModelPrice = 0;
    this.QuotationData.QuotationObj[index].TotalforRfqdiscount = 0;

    this.QuotationData.QuotationObj[index].TotalAfterDiscount=0;
    this.QuotationData.QuotationObj[index].TotalAmount = 0;

    this.QuotationData.QuotationObj[index].Model_Discount = 0;
    this.QuotationData.QuotationObj[index].Model_FinalPrice = 0;

    this.QuotationData.QuotationObj[index].Units_Variant1 = 0;
    this.QuotationData.QuotationObj[index].Variant1Price = 0;
    this.QuotationData.QuotationObj[index].Varient1_Discount = 0;
    this.QuotationData.QuotationObj[index].Varient1_FinalPrice = 0;

    this.QuotationData.QuotationObj[index].Units_Variant2 = 0;
    this.QuotationData.QuotationObj[index].Variant2Price = 0;
    this.QuotationData.QuotationObj[index].Varient2_Discount = 0;
    this.QuotationData.QuotationObj[index].Varient2_FinalPrice = 0;

    this.QuotationData.QuotationObj[index].Units_Variant3 = 0;
    this.QuotationData.QuotationObj[index].Variant3Price = 0;
    this.QuotationData.QuotationObj[index].Varient3_Discount = 0;
    this.QuotationData.QuotationObj[index].Varient3_FinalPrice = 0;

    this.QuotationData.QuotationObj[index].Units_Variant4 = 0;
    this.QuotationData.QuotationObj[index].Variant4Price = 0;
    this.QuotationData.QuotationObj[index].Varient4_Discount = 0;
    this.QuotationData.QuotationObj[index].Varient4_FinalPrice = 0;

    this.QuotationData.QuotationObj[index].Option_Discount = 0;
    this.QuotationData.QuotationObj[index].Option_FinalDiscount = 0;
    this.QuotationData.QuotationInfo.FinalAmount = 0;

  }

  onProductFamilyChange(index) {
    //this.clearRow(index);
    //var selectedProductFamily = this.QuotationData.QuotationObj[index].ProductFamily;
    var selectedProductFamily = 1;
    this.productDrpdnDetails.ProductSeries = this.dropdownData.Product.filter(x => x.ProductFamily.Id == selectedProductFamily)?.ProductSeries;
    this.QuotationData.QuotationObj[index].selectedProductSeries = this.productDrpdnDetails.ProductSeries;
    this.QuotationData.QuotationObj[index].selectedProductModel = [];
    this.QuotationData.QuotationObj[index].ProductModel = undefined;

  }
  onProductSeriesChange(index) {
    this.showLoader=true;
    this.clearRow(index);
    this.QuotationData.QuotationObj[index].ProductSeriesValidation = false;
    var selectedProductSeries = this.QuotationData.QuotationObj[index].ProductSeries;
    this.QuotationData.QuotationObj[index].selectedProductModel = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.ProductModels;
    //currentProduct.selectedProductModel = this.productDrpdnDetails.ProductModel;

    this.QuotationData.QuotationObj[index].Variant1Label = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.Variant1Label;
    this.QuotationData.QuotationObj[index].Variant2Label = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.Variant2Label;
    this.QuotationData.QuotationObj[index].Variant3Label = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.Variant3Label;
    this.QuotationData.QuotationObj[index].Variant4Label = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.Variant4Label;
    this.setColspan(index);

    this.QuotationData.QuotationObj[index].IsLevel2DepOnLevel1 = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.IsLevel2DepOnLevel1;
    this.QuotationData.QuotationObj[index].IsLevel3DepOnLevel2 = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.IsLevel3DepOnLevel2;
    this.QuotationData.QuotationObj[index].IsLevel4DepOnLevel3 = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.IsLevel4DepOnLevel3;

    if (this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.Name == 'HN' ||
      this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.Name == 'HG') {
      this.QuotationData.QuotationObj[index].Units_Variant1 = 100;
      this.QuotationData.QuotationObj[index].Units_Variant2 = 100;
      this.QuotationData.QuotationObj[index].Units_Variant3 = 100;
      this.QuotationData.QuotationObj[index].Units_Variant4 = 100;
    } else {
      this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
      this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
      this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
      this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
    }
    this.displayfurtherrows = true;
    this.showLoader=false;
  }
  onProductModelChange(index) {
    this.getFrameAgreement(index);
    this.showLoader=true;
    this.QuotationData.QuotationObj[index].ProductModelValidation = false;
    let queryString = "";
    queryString = "ProductModelId=" + this.QuotationData.QuotationObj[index].ProductModel;
    this.commonService.GetData("Enquiry", "GetQuotationVariantDropdownData", queryString).subscribe((data: any) => {

      this.variantDropdownData = data;
      // this.productDrpdnDetails.Variant1 = this.variantDropdownData.Variant1;
      this.QuotationData.QuotationObj[index].productmodeldata = undefined;
      this.QuotationData.QuotationObj[index].selectedVariant1 = this.variantDropdownData.Variant1;

      if (!this.QuotationData.QuotationObj[index].IsLevel2DepOnLevel1  && this.QuotationData.QuotationObj[index].IsLevel2DepOnLevel1!=undefined) {
        this.QuotationData.QuotationObj[index].selectedVariant2 = this.variantDropdownData.Variant2;
      }
      if (!this.QuotationData.QuotationObj[index].IsLevel3DepOnLevel2 && this.QuotationData.QuotationObj[index].IsLevel3DepOnLevel2!=undefined ) {
        this.QuotationData.QuotationObj[index].selectedVariant3 = this.variantDropdownData.Variant3;
      }
      if (!this.QuotationData.QuotationObj[index].IsLevel4DepOnLevel3 && this.QuotationData.QuotationObj[index].IsLevel4DepOnLevel3!=undefined ) {
        this.QuotationData.QuotationObj[index].selectedVariant4 = this.variantDropdownData.Variant4;

      }
      this.QuotationData.QuotationObj[index].Variant1 = null;
      this.QuotationData.QuotationObj[index].Variant2 = null;
      this.QuotationData.QuotationObj[index].Variant3 = null;
      this.QuotationData.QuotationObj[index].Variant4 = null;
      this.QuotationData.QuotationObj[index].OptionSelected = [];
      this.QuotationData.QuotationObj[index].selectedOptions = [];
      this.QuotationData.QuotationObj[index].Options = [];
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant1 = false;
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant2 = false;
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant3 = false;
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant4 = false;
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Option = false;
      if (this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name == 'HN' ||
        this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name == 'HG') {
        this.QuotationData.QuotationObj[index].Units_Variant1 = 100;
        this.QuotationData.QuotationObj[index].Units_Variant2 = 100;
        this.QuotationData.QuotationObj[index].Units_Variant3 = 100;
        this.QuotationData.QuotationObj[index].Units_Variant4 = 100;
      } else {
        this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
        this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
        this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
        this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
      }
      this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
      this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
      this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
      this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
      this.QuotationData.QuotationObj[index].Variant1Price = 0;
      this.QuotationData.QuotationObj[index].Variant2Price = 0;
      this.QuotationData.QuotationObj[index].Variant3Price = 0;
      this.QuotationData.QuotationObj[index].Variant4Price = 0;
      this.QuotationData.QuotationObj[index].OptionsPrice = 0;
      this.QuotationData.QuotationObj[index].selectedOptions = this.variantDropdownData.Options;
      this.QuotationData.QuotationObj[index].OptionSelected = [];
      this.QuotationData.QuotationObj[index].ProductModelPrice = this.variantDropdownData.Price;
      this.showLoader=false;

      this.calculateTotalPricesForProduct(index, 1);
      this.showLoader=false;
    }, error => {
      this.showLoader=false;
    });
  }

  onOptionSelect(item, index) {
    if (!item.isUserInput) {
      return;
    }
    var optionname;
    // this.QuotationData.QuotationObj[index].selectedOptions = [];
    // this.QuotationData.QuotationObj[index].selectedOptions = this.QuotationData.QuotationObj[index].productmodeldata.Options;
    if (this.Options.length == 0) {
      optionname = this.QuotationData.QuotationObj[index].selectedOptions.filter(x => x.Id == item.source.value)[0];
    } else {
      optionname = this.Options.filter(x => x.Id == item.source.value)[0];
    }
    if (item.source._selected) {
      var opObj = new OptionsModel();
      opObj.OptionId = optionname.Id;
      opObj.ProductOptionId = optionname.ProductOptionId;
      opObj.Price = optionname.Price;
      opObj.Name = optionname.Name;
      opObj.IsPriceOnRequest = optionname.IsPriceOnRequest;
      opObj.optionValid = false;

      this.QuotationData.QuotationObj[index]?.Options.push(opObj);
    } else {
      this.QuotationData.QuotationObj[index].Options = this.QuotationData.QuotationObj[index]?.Options.filter(x => x.OptionId != item.source.value);
    }
    let anyOptionPOR = this.QuotationData.QuotationObj[index]?.Options.filter(x => x.IsPriceOnRequest == true);
    if (anyOptionPOR.length > 0 && anyOptionPOR.length != null && anyOptionPOR.length != undefined) {
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Option = true;
    }
    else {
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Option = false;
    }
    // if (this.QuotationData.QuotationObj[index].Options.length == 0) {
    //   this.QuotationData.QuotationObj[index].OptionsValidation = true;
    // } else {
    //   this.QuotationData.QuotationObj[index].OptionsValidation = false;
    // }

    this.calculateTotalPricesForProduct(index, 1);
  }

  onVariant1Change(index) {
    this.showLoader=true;
    this.QuotationData.QuotationObj[index].Variant1Validation = false;
    this.productDrpdnDetails.IsLevel2DepOnLevel1 = this.dropdownData.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0].IsLevel2DepOnLevel1;

    if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      var selectedVariant1 = this.QuotationData.QuotationObj[index].Variant1;
      var var1Obj = this.variantDropdownData.Variant1.filter(x => x.Id == selectedVariant1)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant1 = var1Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant1) {
        this.QuotationData.QuotationObj[index].Variant1Price = var1Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant1;
      } else {
        this.QuotationData.QuotationObj[index].Variant1Price = var1Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant1 = var1Obj?.IsPriceOnRequest;
      if (this.productDrpdnDetails.IsLevel2DepOnLevel1) {
        this.QuotationData.QuotationObj[index].Variant2 = null;
        this.QuotationData.QuotationObj[index].Variant2Price = 0;
        this.QuotationData.QuotationObj[index].selectedVariant2 = this.variantDropdownData.Variant2.filter(x => x.Fk_Id == selectedVariant1);
        this.productDrpdnDetails.Variant2 = this.variantDropdownData.Variant2.filter(x => x.Fk_Id == selectedVariant1);
      }
    } else {
      var selectedVariant1 = this.QuotationData.QuotationObj[index].Variant1;
      var var1Obj = this.QuotationData.QuotationObj[index].selectedVariant1.filter(x => x.Id == selectedVariant1)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant1 = var1Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant1) {
        this.QuotationData.QuotationObj[index].Variant1Price = var1Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant1;
      } else {
        this.QuotationData.QuotationObj[index].Variant1Price = var1Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant1 = var1Obj?.IsPriceOnRequest;
      if (this.productDrpdnDetails.IsLevel2DepOnLevel1) {
        this.QuotationData.QuotationObj[index].Variant2 = null;
        this.QuotationData.QuotationObj[index].Variant2Price = 0;
        this.QuotationData.QuotationObj[index].selectedVariant2 = this.variantDropdownData.Variant2.filter(x => x.Fk_Id == selectedVariant1);
        this.productDrpdnDetails.Variant2 = this.variantDropdownData.Variant2.filter(x => x.Fk_Id == selectedVariant1);
      }
    }
    this.calculateTotalPricesForProduct(index, 1);
    this.showLoader=false;
  }
  onVariant2Change(index) {
    this.showLoader=true;
    this.QuotationData.QuotationObj[index].Variant2Validation = false;
    this.productDrpdnDetails.IsLevel3DepOnLevel2 = this.dropdownData.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0].IsLevel3DepOnLevel2;

    if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      var selectedVariant2 = this.QuotationData.QuotationObj[index].Variant2;
      this.QuotationData.QuotationObj[index].IsUnits_Variant2 = var2Obj?.IsUnits;
      var var2Obj = this.variantDropdownData.Variant2.filter(x => x.Id == selectedVariant2)[0];
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant2) {
        this.QuotationData.QuotationObj[index].Variant2Price = var2Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant2;
      } else {
        this.QuotationData.QuotationObj[index].Variant2Price = var2Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant2 = var2Obj?.IsPriceOnRequest;
      if (this.productDrpdnDetails.IsLevel3DepOnLevel2) {
        this.QuotationData.QuotationObj[index].Variant3 = null;
        this.QuotationData.QuotationObj[index].Variant3Price = 0;
        this.QuotationData.QuotationObj[index].selectedVariant3 = this.variantDropdownData.Variant3.filter(x => x.Fk_Id == selectedVariant2);
        this.productDrpdnDetails.Variant3 = this.variantDropdownData.Variant3.filter(x => x.Fk_Id == selectedVariant2);
      }
    } else {
      var selectedVariant2 = this.QuotationData.QuotationObj[index].Variant2;
      var var2Obj = this.QuotationData.QuotationObj[index].selectedVariant2.filter(x => x.Id == selectedVariant2)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant2 = var2Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant2) {
        this.QuotationData.QuotationObj[index].Variant2Price = var2Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant2;
      } else {
        this.QuotationData.QuotationObj[index].Variant2Price = var2Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant2 = var2Obj?.IsPriceOnRequest;
      if (this.productDrpdnDetails.IsLevel3DepOnLevel2) {
        this.QuotationData.QuotationObj[index].Variant3 = null;
        this.QuotationData.QuotationObj[index].Variant3Price = 0;
        this.QuotationData.QuotationObj[index].selectedVariant3 = this.variantDropdownData.Variant3.filter(x => x.Fk_Id == selectedVariant2);
        this.productDrpdnDetails.Variant3 = this.variantDropdownData.Variant3.filter(x => x.Fk_Id == selectedVariant2);
      }
    }
    this.calculateTotalPricesForProduct(index, 1);
    this.showLoader=false;
  }
  onVariant3Change(index) {
    this.showLoader=true;
    this.QuotationData.QuotationObj[index].Variant3Validation = false;
    this.productDrpdnDetails.IsLevel4DepOnLevel3 = this.dropdownData.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0].IsLevel4DepOnLevel3;

    if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      var selectedVariant3 = this.QuotationData.QuotationObj[index].Variant3;
      var var3Obj = this.variantDropdownData.Variant3.filter(x => x.Id == selectedVariant3)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant3 = var3Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant3) {
        this.QuotationData.QuotationObj[index].Variant3Price = var3Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant3;
      } else {
        this.QuotationData.QuotationObj[index].Variant3Price = var3Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant3 = var3Obj?.IsPriceOnRequest;
      if (this.productDrpdnDetails.IsLevel4DepOnLevel3) {
        this.QuotationData.QuotationObj[index].Variant4 = null;
        this.QuotationData.QuotationObj[index].Variant4Price = 0;
        this.QuotationData.QuotationObj[index].selectedVariant4 = this.variantDropdownData.Variant4.filter(x => x.Fk_Id == selectedVariant3);
        this.productDrpdnDetails.Variant4 = this.variantDropdownData.Variant4.filter(x => x.Fk_Id == selectedVariant3);
      }
    } else {
      var selectedVariant3 = this.QuotationData.QuotationObj[index].Variant3;
      var var3Obj = this.QuotationData.QuotationObj[index].selectedVariant3.filter(x => x.Id == selectedVariant3)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant3 = var3Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant3) {
        this.QuotationData.QuotationObj[index].Variant3Price = var3Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant3;
      } else {
        this.QuotationData.QuotationObj[index].Variant3Price = var3Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant3 = var3Obj?.IsPriceOnRequest;
      if (this.productDrpdnDetails.IsLevel4DepOnLevel3) {
        this.QuotationData.QuotationObj[index].Variant4 = null;
        this.QuotationData.QuotationObj[index].Variant4Price = 0;
        this.QuotationData.QuotationObj[index].selectedVariant4 = this.variantDropdownData.Variant4.filter(x => x.Fk_Id == selectedVariant3);
        this.productDrpdnDetails.Variant4 = this.variantDropdownData.Variant4.filter(x => x.Fk_Id == selectedVariant3);
      }
    }
    this.calculateTotalPricesForProduct(index, 1);
    this.showLoader=false;
  }
  onVariant4Change(index) {
    this.showLoader=true;
    this.QuotationData.QuotationObj[index].Variant4Validation = false;
    if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      var selectedVariant4 = this.QuotationData.QuotationObj[index].Variant4;
      var var4Obj = this.QuotationData.QuotationObj[index].selectedVariant4.filter(x => x.Id == selectedVariant4)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant4 = var4Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant4) {
        this.QuotationData.QuotationObj[index].Variant4Price = var4Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant4;
      } else {
        this.QuotationData.QuotationObj[index].Variant4Price = var4Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant4 = var4Obj?.IsPriceOnRequest;
    } else {
      var selectedVariant4 = this.QuotationData.QuotationObj[index].Variant4;

      var var4Obj = this.QuotationData.QuotationObj[index].selectedVariant4.filter(x => x.Id == selectedVariant4)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant4 = var4Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant4) {
        this.QuotationData.QuotationObj[index].Variant4Price = var4Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant4;
      } else {
        this.QuotationData.QuotationObj[index].Variant4Price = var4Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant4 = var4Obj?.IsPriceOnRequest;

      /*************************************************************************************************** */

    }
    this.calculateTotalPricesForProduct(index, 1);
    this.showLoader=false;
  }
  // var1UnitsChange(index) {
  //   var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
  //   let strunits = this.QuotationData.QuotationObj[index].Units_Variant1.toString();
  //   if (this.QuotationData.QuotationObj[index].Units_Variant1 == undefined || this.QuotationData.QuotationObj[index].Units_Variant1 == null) {
  //     this.QuotationData.QuotationObj[index].var1_Unit_Validation = true;
  //     this.IsQuotationDataValid = false;
  //   } else if (this.QuotationData.QuotationObj[index].Units_Variant1 < 0) {
  //     this.QuotationData.QuotationObj[index].var1_Unit_Validation = true;
  //     this.IsQuotationDataValid = false;
  //   } else if (this.QuotationData.QuotationObj[index].Units_Variant1 % 1 != 0) {
  //     this.toastr.info('Please enter only integer value');
  //     if (seriesname == 'HG' || seriesname == 'HN') {
  //       this.QuotationData.QuotationObj[index].Units_Variant1 = 100;
  //     }
  //     else {
  //       this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
  //     }

  //     this.IsQuotationDataValid = false;
  //   } else if (strunits.includes(".")) {
  //     this.toastr.info('Please enter valid units');
  //     if (seriesname == 'HG' || seriesname == 'HN') {
  //       this.QuotationData.QuotationObj[index].Units_Variant1 = 100;
  //     }
  //     else {
  //       this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
  //     }

  //     this.IsQuotationDataValid = false;
  //   }

  //   else {

  //     if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant1 % 100 != 0)) {
  //       this.toastr.info('Please enter the units in multiple of 100.');
  //       this.QuotationData.QuotationObj[index].var1_Unit_Validation = true;
  //       this.IsQuotationDataValid = false;
  //     } else {
  //       this.QuotationData.QuotationObj[index].var1_Unit_Validation = false;
  //     }
  //   }
  //   if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
  //     this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
  //     var selectedVariant1 = this.QuotationData.QuotationObj[index].Variant1;
  //     var price: number = this.variantDropdownData.Variant1.filter(x => x.Id == selectedVariant1)[0]?.Price;
  //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant1;
  //     this.QuotationData.QuotationObj[index].Variant1Price = price * units;
  //   } else {
  //     var selectedVariant1 = this.QuotationData.QuotationObj[index].Variant1;
  //     var price: number = this.variantDropdownData.Variant1.filter(x => x.Id == selectedVariant1)[0]?.Price;
  //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant1;
  //     this.QuotationData.QuotationObj[index].Variant1Price = price * units;
  //     //this.QuotationData.QuotationObj[index].Variant1Price = this.QuotationData.QuotationObj[index].selectedVariant1.filter(x => x.Id == selectedVariant1)[0]?.Price;
  //   }
  //   this.calculateTotalPricesForProduct(index, 1);
  // }

  // var2UnitsChange(index) {
  //   var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
  //   let strunits = this.QuotationData.QuotationObj[index].Units_Variant2.toString();
  //   if (this.QuotationData.QuotationObj[index].Units_Variant2 == undefined || this.QuotationData.QuotationObj[index].Units_Variant2 == null) {
  //     this.QuotationData.QuotationObj[index].var2_Unit_Validation = true;
  //     this.IsQuotationDataValid = false;
  //   } else if (this.QuotationData.QuotationObj[index].Units_Variant2 < 0) {
  //     this.QuotationData.QuotationObj[index].var2_Unit_Validation = true;
  //     this.IsQuotationDataValid = false;
  //   } else if (this.QuotationData.QuotationObj[index].Units_Variant2 % 1 != 0) {
  //     this.toastr.info('Please enter only integer value');
  //     if (seriesname == 'HG' || seriesname == 'HN') {
  //       this.QuotationData.QuotationObj[index].Units_Variant2 = 100;
  //     }
  //     else {
  //       this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
  //     }

  //     this.IsQuotationDataValid = false;
  //   }
  //   else if (strunits.includes(".")) {
  //     this.toastr.info('Please enter valid units');
  //     if (seriesname == 'HG' || seriesname == 'HN') {
  //       this.QuotationData.QuotationObj[index].Units_Variant2 = 100;
  //     }
  //     else {
  //       this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
  //     }

  //     this.IsQuotationDataValid = false;
  //   }
  //   else {

  //     if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant2 % 100 != 0)) {
  //       this.toastr.info('Please enter the units in multiple of 100.');
  //       this.QuotationData.QuotationObj[index].var2_Unit_Validation = true;
  //       this.IsQuotationDataValid = false;
  //     } else {
  //       this.QuotationData.QuotationObj[index].var2_Unit_Validation = false;
  //     }
  //   }
  //   if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
  //     this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
  //     var selectedVariant2 = this.QuotationData.QuotationObj[index].Variant2;
  //     var price: number = this.variantDropdownData.Variant2.filter(x => x.Id == selectedVariant2)[0]?.Price;
  //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant2;
  //     this.QuotationData.QuotationObj[index].Variant2Price = price * units;
  //   } else {
  //     var selectedVariant2 = this.QuotationData.QuotationObj[index].Variant2;
  //     var price: number = this.variantDropdownData.Variant2.filter(x => x.Id == selectedVariant2)[0]?.Price;
  //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant2;
  //     this.QuotationData.QuotationObj[index].Variant2Price = price * units;
  //     // this.QuotationData.QuotationObj[index].Variant2Price = this.productDrpdnDetails.Variant2.filter(x => x.Id == selectedVariant2)[0]?.Price;
  //   }
  //   this.calculateTotalPricesForProduct(index, 1);
  // }
  // var3UnitsChange(index) {
  //   var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
  //   let strunits = this.QuotationData.QuotationObj[index].Units_Variant3.toString();
  //   if (this.QuotationData.QuotationObj[index].Units_Variant3 == undefined || this.QuotationData.QuotationObj[index].Units_Variant3 == null) {
  //     this.QuotationData.QuotationObj[index].var3_Unit_Validation = true;
  //     this.IsQuotationDataValid = false;
  //   } else if (this.QuotationData.QuotationObj[index].Units_Variant3 < 0) {
  //     this.QuotationData.QuotationObj[index].var3_Unit_Validation = true;
  //     this.IsQuotationDataValid = false;
  //   } else if (this.QuotationData.QuotationObj[index].Units_Variant3 % 1 != 0) {
  //     this.toastr.info('Please enter only integer value');
  //     if (seriesname == 'HG' || seriesname == 'HN') {
  //       this.QuotationData.QuotationObj[index].Units_Variant3 = 100;
  //     }
  //     else {
  //       this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
  //     }

  //     this.IsQuotationDataValid = false;
  //   }
  //   else if (strunits.includes(".")) {
  //     this.toastr.info('Please enter valid units');
  //     if (seriesname == 'HG' || seriesname == 'HN') {
  //       this.QuotationData.QuotationObj[index].Units_Variant3 = 100;
  //     }
  //     else {
  //       this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
  //     }

  //     this.IsQuotationDataValid = false;
  //   }
  //   else {

  //     if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant3 % 100 != 0)) {
  //       this.toastr.info('Please enter the units in multiple of 100.');
  //       this.QuotationData.QuotationObj[index].var3_Unit_Validation = true;
  //       this.IsQuotationDataValid = false;
  //     } else {
  //       this.QuotationData.QuotationObj[index].var3_Unit_Validation = false;
  //     }
  //   }
  //   if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
  //     this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
  //     var selectedVariant3 = this.QuotationData.QuotationObj[index].Variant3;
  //     var price: number = this.variantDropdownData.Variant3.filter(x => x.Id == selectedVariant3)[0]?.Price;
  //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant3;
  //     this.QuotationData.QuotationObj[index].Variant3Price = price * units;
  //   } else {
  //     var selectedVariant3 = this.QuotationData.QuotationObj[index].Variant3;
  //     var price: number = this.variantDropdownData.Variant3.filter(x => x.Id == selectedVariant3)[0]?.Price;
  //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant3;
  //     this.QuotationData.QuotationObj[index].Variant3Price = price * units;
  //     //this.QuotationData.QuotationObj[index].Variant3Price = this.productDrpdnDetails.Variant3.filter(x => x.Id == selectedVariant3)[0]?.Price;
  //   }
  //   this.calculateTotalPricesForProduct(index, 1);
  // }
  // var4UnitsChange(index) {
  //   var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
  //   let strunits = this.QuotationData.QuotationObj[index].Units_Variant4.toString();
  //   if (this.QuotationData.QuotationObj[index].Units_Variant4 == undefined || this.QuotationData.QuotationObj[index].Units_Variant4 == null) {
  //     this.QuotationData.QuotationObj[index].var4_Unit_Validation = true;
  //     this.IsQuotationDataValid = false;
  //   } else if (this.QuotationData.QuotationObj[index].Units_Variant4 < 0) {
  //     this.QuotationData.QuotationObj[index].var4_Unit_Validation = true;
  //     this.IsQuotationDataValid = false;
  //   } else if (this.QuotationData.QuotationObj[index].Units_Variant4 % 1 != 0) {
  //     this.toastr.info('Please enter only integer value');
  //     if (seriesname == 'HG' || seriesname == 'HN') {
  //       this.QuotationData.QuotationObj[index].Units_Variant4 = 100;
  //     }
  //     else {
  //       this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
  //     }

  //     this.IsQuotationDataValid = false;
  //   }
  //   else if (strunits.includes(".")) {
  //     this.toastr.info('Please enter valid units');
  //     if (seriesname == 'HG' || seriesname == 'HN') {
  //       this.QuotationData.QuotationObj[index].Units_Variant4 = 100;
  //     }
  //     else {
  //       this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
  //     }

  //     this.IsQuotationDataValid = false;
  //   }
  //   else {

  //     if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant4 % 100 != 0)) {
  //       this.toastr.info('Please enter the units in multiple of 100');
  //       this.QuotationData.QuotationObj[index].var4_Unit_Validation = true;
  //       this.IsQuotationDataValid = false;
  //     } else {
  //       this.QuotationData.QuotationObj[index].var4_Unit_Validation = false;
  //     }
  //   }
  //   if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
  //     this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
  //     var selectedVariant4 = this.QuotationData.QuotationObj[index].Variant4;
  //     var price: number = this.variantDropdownData.Variant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
  //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant4;
  //     this.QuotationData.QuotationObj[index].Variant4Price = price * units;
  //   } else {
  //     var selectedVariant4 = this.QuotationData.QuotationObj[index].Variant4;
  //     var price: number = this.variantDropdownData.Variant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
  //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant4;
  //     this.QuotationData.QuotationObj[index].Variant4Price = price * units;
  //     // this.QuotationData.QuotationObj[index].Variant4Price = this.productDrpdnDetails.Variant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
  //   }
  //   this.calculateTotalPricesForProduct(index, 1);
  // }

  var1PORChange(index) {
    var element = this.QuotationData.QuotationObj[index];
    let strprice = element.Variant1Price.toString();
    if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant1 && (element.Variant1Price == undefined || element.Variant1Price == null || (element.Variant1Price == 0 && element.Units_Variant1 != 0) || (element.Variant1Price != 0 && element.Units_Variant1 == 0))) {
      element.var1_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant1 && (element.Variant1Price < 0)) {
      element.var1_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (element.Variant1Price % 1 != 0) {
      this.toastr.info('Please enter only integer value');
      element.Variant1Price = 0;
      this.IsQuotationDataValid = false;
    } else if (strprice.includes(".")) {
      this.toastr.info('Please enter valid price');
      element.Variant1Price = 0;
      this.IsQuotationDataValid = false;
    }
    else {
      element.var1_POR_Validation = false;
    }
    this.calculateTotalPricesForProduct(index, 1);
  }
  var2PORChange(index) {
    var element = this.QuotationData.QuotationObj[index];
    let strprice = element.Variant2Price.toString();
    if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant2 && (element.Variant2Price == undefined || element.Variant2Price == null || (element.Variant2Price == 0 && element.Units_Variant2 != 0) || (element.Variant2Price != 0 && element.Units_Variant2 == 0))) {
      element.var2_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant2 && (element.Variant2Price < 0)) {
      element.var2_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (element.Variant2Price % 1 != 0) {
      this.toastr.info('Please enter only integer value');
      element.Variant2Price = 0;
      this.IsQuotationDataValid = false;
    } else if (strprice.includes(".")) {
      this.toastr.info('Please enter valid price');
      element.Variant2Price = 0;
      this.IsQuotationDataValid = false;
    }
    else {
      element.var2_POR_Validation = false;
    }
    this.calculateTotalPricesForProduct(index, 1);
  }
  var3PORChange(index) {
    var element = this.QuotationData.QuotationObj[index];
    let strprice = element.Variant3Price.toString();
    if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant3 && (element.Variant3Price == undefined || element.Variant3Price == null || (element.Variant3Price == 0 && element.Units_Variant3 != 0) || (element.Variant3Price != 0 && element.Units_Variant3 == 0))) {
      element.var3_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant3 && (element.Variant3Price < 0)) {
      element.var3_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (element.Variant3Price % 1 != 0) {
      this.toastr.info('Please enter only integer value');
      element.Variant3Price = 0;
      this.IsQuotationDataValid = false;
    } else if (strprice.includes(".")) {
      this.toastr.info('Please enter valid price');
      element.Variant3Price = 0;
      this.IsQuotationDataValid = false;
    }
    else {
      element.var3_POR_Validation = false;
      this.IsQuotationDataValid = true;
    }
    this.calculateTotalPricesForProduct(index, 1);
  }
  var4PORChange(index) {
    var element = this.QuotationData.QuotationObj[index];
    let strprice = element.Variant4Price.toString();
    if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant4 && (element.Variant4Price == undefined || element.Variant4Price == null || (element.Variant4Price == 0 && element.Units_Variant4 != 0) || (element.Variant4Price != 0 && element.Units_Variant4 == 0))) {
      element.var4_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant4 && (element.Variant4Price < 0)) {
      element.var4_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (element.Variant4Price % 1 != 0) {
      this.toastr.info('Please enter only integer value');
      element.Variant4Price = 0;
      this.IsQuotationDataValid = false;
    }
    else if (strprice.includes(".")) {
      this.toastr.info('Please enter valid price');
      element.Variant4Price = 0;
      this.IsQuotationDataValid = false;
    }
    else {
      element.var4_POR_Validation = false;
    }
    this.calculateTotalPricesForProduct(index, 1);
  }
  varOptionPORChange(i, j) {
    var element = this.QuotationData.QuotationObj[i];
    let strprice = element.Options[j].Price.toString();
    if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Option && (element.Options[j].Price == undefined || element.Options[j].Price == null || element.Options[j].Price == 0)) {
      element.Options[j].optionValid = true;
      this.IsQuotationDataValid = false;
    } else if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Option && element.Options[j].Price < 0) {
      element.Options[j].optionValid = true;
      this.IsQuotationDataValid = false;
    } else if (element.Options[j].Price % 1 != 0) {
      this.toastr.info('Please enter only integer value');
      element.Options[j].Price = 0;
      this.IsQuotationDataValid = false;
    }
    else if (strprice.includes(".")) {
      this.toastr.info('Please enter valid price');
      element.Options[j].Price = 0;
      this.IsQuotationDataValid = false;
    }
    else {
      element.Options[j].optionValid = false;
      this.IsQuotationDataValid = true;
    }
    this.calculateTotalPricesForProduct(i, 1);
  }

  onQuatityChange(index) {

    let strquantity = this.QuotationData.QuotationObj[index].Quantity.toString();
    if ((this.QuotationData.QuotationObj[index].Quantity == 0 || this.QuotationData.QuotationObj[index].Quantity == null || this.QuotationData.QuotationObj[index].Quantity == undefined)) {
      this.QuotationData.QuotationObj[index].Quantity = 1;
    } else if (this.QuotationData.QuotationObj[index].Quantity % 1 != 0) {
      this.QuotationData.QuotationObj[index].QuantityValidation = true;
      return;
    } else {
      this.QuotationData.QuotationObj[index].QuantityValidation = false;
    }
    if (this.QuotationData.QuotationObj[index].Quantity > 1000) {
      this.QuotationData.QuotationObj[index].QuantityRangeValidation = true;
      return;
    } else if (this.QuotationData.QuotationObj[index].Quantity < 1) {
      this.QuotationData.QuotationObj[index].QuantityValidation = true;
      return;
    } else if (strquantity.includes(".")) {
      this.toastr.info('Please enter valid Quantity');
      this.QuotationData.QuotationObj[index].Quantity = null;
      return;
    }
    else {
      this.QuotationData.QuotationObj[index].QuantityRangeValidation = false;
    }
    this.calculateTotalPricesForProduct(index, 1);
  }
  maxDiscount: number = 0;
  getMaxDiscountAndAuthorities() {
    //FOR EVERY CHANGE OF DISCOUNT WE ARE FETCHING THE SALES PRICE AUTHORITIES FOR WORKFLOW PROCESS
    this.maxDiscount = 0;
    this.QuotationData.QuotationObj.forEach(element => {
      if (this.maxDiscount < element.Discount) {
        this.maxDiscount = element.Discount;
      }
    });
    //this.getPriceDiscountApprovalAuthority();
  }

  onDiscountChange(index) {
    
    this.getPriceDiscountApprovalAuthorityByPayload();
   // this.getPriceDiscountApprovalAuthority();

    if ((this.QuotationData.QuotationObj[index].Discount < 0) || (this.QuotationData.QuotationObj[index].Varient1_Discount < 0)
      || (this.QuotationData.QuotationObj[index].Varient2_Discount < 0) || (this.QuotationData.QuotationObj[index].Varient4_Discount < 0)
      || (this.QuotationData.QuotationObj[index].Varient4_Discount < 0) || (this.QuotationData.QuotationObj[index].Option_Discount < 0)
      || (this.specialPkgDiscount < 0) ||(this.QuotationData.QuotationObj[index].Model_Discount<0)) {
      this.QuotationData.QuotationObj[index].Discount = 0;
      this.QuotationData.QuotationObj[index].Model_Discount=0;
      this.QuotationData.QuotationObj[index].Varient1_Discount = 0;
      this.QuotationData.QuotationObj[index].Varient2_Discount = 0;
      this.QuotationData.QuotationObj[index].Varient3_Discount = 0;
      this.QuotationData.QuotationObj[index].Varient4_Discount = 0;
      this.QuotationData.QuotationObj[index].Option_Discount = 0;
    }
    else if ((this.QuotationData.QuotationObj[index].Discount > 100)) {
      this.QuotationData.QuotationObj[index].Discount = 0;
      return;
    }
    else if ((this.QuotationData.QuotationObj[index].Model_Discount > 100)) {
      this.QuotationData.QuotationObj[index].Model_Discount = 0;
      return;
    }
    else if ((this.QuotationData.QuotationObj[index].Varient1_Discount > 100)) {
      this.QuotationData.QuotationObj[index].Varient1_Discount = 0;
      return;
    }
    else if ((this.QuotationData.QuotationObj[index].Varient2_Discount > 100)) {
      this.QuotationData.QuotationObj[index].Varient2_Discount = 0;
      return;
    }
    else if ((this.QuotationData.QuotationObj[index].Varient3_Discount > 100)) {
      this.QuotationData.QuotationObj[index].Varient3_Discount = 0;
      return;
    }
    else if ((this.QuotationData.QuotationObj[index].Varient4_Discount > 100)) {
      this.QuotationData.QuotationObj[index].Varient4_Discount = 0;
      return;
    }
    else if ((this.QuotationData.QuotationObj[index].Option_Discount > 100)) {
      this.QuotationData.QuotationObj[index].Option_Discount = 0;
      return;
    }
    else if (this.QuotationData.QuotationObj[index].Discount % 1 != 0) {
      this.QuotationData.QuotationObj[index].Discount;
    }
    else if (this.QuotationData.QuotationObj[index].Model_Discount % 1 != 0) {
      this.QuotationData.QuotationObj[index].Model_Discount ;
    }
    else if (this.QuotationData.QuotationObj[index].Varient1_Discount % 1 != 0) {
      this.QuotationData.QuotationObj[index].Varient1_Discount ;
    }
    else if (this.QuotationData.QuotationObj[index].Varient2_Discount % 1 != 0) {
      this.QuotationData.QuotationObj[index].Varient2_Discount ;
    }
    else if (this.QuotationData.QuotationObj[index].Varient3_Discount % 1 != 0) {
      this.QuotationData.QuotationObj[index].Varient3_Discount ;
    }
    else if (this.QuotationData.QuotationObj[index].Varient4_Discount % 1 != 0) {
      this.QuotationData.QuotationObj[index].Varient4_Discount ;
    }
    else if (this.QuotationData.QuotationObj[index].Option_Discount % 1 != 0) {
      this.QuotationData.QuotationObj[index].Option_Discount ;
    }
    else {
      this.QuotationData.QuotationObj[index].DiscountValidation = false;
    }
    this.calculateTotalPricesForProduct(index, 1);
  }


  SetStatus(rule) {
    this.QuotationData.QuotationInfo.EnquiryStatus = rule.workflowNextStatusId;
    this.AddQuotation(rule);
  }

  checkQuotationGridValidation(flag) {
    var inde = 0;
    this.ispriceOnrequestTrigger=false;
    this.QuotationData.QuotationObj.forEach(element => {
      if (element.ProductSeries == undefined || element.ProductSeries == null || element.ProductSeries == 0) {
        element.ProductSeriesValidation = true;
        this.IsQuotationDataValid = false;
      }
      if (element.ProductModel == undefined || element.ProductModel == null || element.ProductModel == 0) {
        element.ProductModelValidation = true;
        this.IsQuotationDataValid = false;
      }
      if (!element.ProductModelValidation && element.Variant1Label && (element.Variant1 == undefined || element.Variant1 == null || element.Variant1 == 0)) {
        element.Variant1Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (!element.ProductModelValidation && element.Variant2Label && (element.Variant2 == undefined || element.Variant2 == null || element.Variant2 == 0)) {
        element.Variant2Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (!element.ProductModelValidation && element.Variant3Label && (element.Variant3 == undefined || element.Variant3 == null || element.Variant3 == 0)) {
        element.Variant3Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (!element.ProductModelValidation && element.Variant4Label && (element.Variant4 == undefined || element.Variant4 == null || element.Variant4 == 0)) {
        element.Variant4Validation = true;
        this.IsQuotationDataValid = false;
      }
      // if (element.Options == undefined || element.Options == null || element.Options.length == 0) {
      //   element.OptionsValidation = true;
      //   this.IsQuotationDataValid = false;
      // }
      if (element.Quantity == undefined || element.Quantity == null || element.Quantity == 0) {
        element.QuantityValidation = true;
        this.IsQuotationDataValid = false;
      }
      if (element.Discount < 0) {
        element.DiscountValidation = true;
        this.IsQuotationDataValid = false;
      }

      if (element.Discount > 100) {
        element.DiscountValidation = true;
        this.IsQuotationDataValid = false;
      }

      //<PRICE ON REQUEST VALIDATION
      if (this.IsQuotationPriceOnRequest && flag == 1 && element.IsPriceOnRequest_Variant1 && (element.Variant1Price == undefined || element.Variant1Price == null || (element.Variant1Price == 0 && element.Units_Variant1 != 0) || (element.Variant1Price != 0 && element.Units_Variant1 == 0))) {
        element.var1_POR_Validation = true;
        this.IsQuotationDataValid = false;
        this.ispriceOnrequestTrigger=true;
      }
      if (this.IsQuotationPriceOnRequest && flag == 1 && element.IsPriceOnRequest_Variant2 && (element.Variant2Price == undefined || element.Variant2Price == null || (element.Variant2Price == 0 && element.Units_Variant2 != 0) || (element.Variant2Price != 0 && element.Units_Variant2 == 0))) {
        element.var2_POR_Validation = true;
        this.IsQuotationDataValid = false;
        this.ispriceOnrequestTrigger=true;
      }
      if (this.IsQuotationPriceOnRequest && flag == 1 && element.IsPriceOnRequest_Variant3 && (element.Variant3Price == undefined || element.Variant3Price == null || (element.Variant3Price == 0 && element.Units_Variant3 != 0) || (element.Variant3Price != 0 && element.Units_Variant3 == 0))) {
        element.var3_POR_Validation = true;
        this.IsQuotationDataValid = false;
        this.ispriceOnrequestTrigger=true;
      }
      if (this.IsQuotationPriceOnRequest && flag == 1 && element.IsPriceOnRequest_Variant4 && (element.Variant4Price == undefined || element.Variant4Price == null || (element.Variant4Price == 0 && element.Units_Variant4 != 0) || (element.Variant4Price != 0 && element.Units_Variant4 == 0))) {
        element.var4_POR_Validation = true;
        this.IsQuotationDataValid = false;
        this.ispriceOnrequestTrigger=true;
      }
      if (this.IsQuotationPriceOnRequest && flag == 1 && element.IsPriceOnRequest_Option) {

        element.Options.forEach(x => {

          if (this.IsQuotationPriceOnRequest && flag == 1 && element.IsPriceOnRequest_Option && x.IsPriceOnRequest && (x.Price == undefined || x.Price == null || x.Price == 0)) {
            element.ShowOptionPriceOnRequestGrid = true;
            x.optionValid = true;
            this.IsQuotationDataValid = false;
            this.ispriceOnrequestTrigger=true;
          }

        });

      }
      //>
      //<UNITS VALIDATION
      if (element.IsUnits_Variant1 && (element.Units_Variant1 == undefined || element.Units_Variant1 == null)) {
        element.var1_Unit_Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (element.IsUnits_Variant2 && (element.Units_Variant2 == undefined || element.Units_Variant2 == null)) {
        element.var2_Unit_Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (element.IsUnits_Variant3 && (element.Units_Variant3 == undefined || element.Units_Variant3 == null)) {
        element.var3_Unit_Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (element.IsUnits_Variant4 && (element.Units_Variant4 == undefined || element.Units_Variant4 == null)) {
        element.var4_Unit_Validation = true;
        this.IsQuotationDataValid = false;
      }
      //>
      var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == element.ProductSeries)[0]?.Name;
      if ((seriesname == 'HG' || seriesname == 'HN')) {
        if (element.IsUnits_Variant1 && element.Units_Variant1 % 100 != 0) {
          element.var1_Unit_Validation = true;
          this.IsQuotationDataValid = false;
        }
        if (element.IsUnits_Variant2 && element.Units_Variant2 % 100 != 0) {
          element.var2_Unit_Validation = true;
          this.IsQuotationDataValid = false;
        }
        if (element.IsUnits_Variant3 && element.Units_Variant3 % 100 != 0) {
          element.var3_Unit_Validation = true;
          this.IsQuotationDataValid = false;
        }
        if (element.IsUnits_Variant4 && element.Units_Variant4 % 100 != 0) {
          element.var4_Unit_Validation = true;
          this.IsQuotationDataValid = false;
        }
      }
      inde++;
    });
  }
  onKindAttChange() {
    if (this.QuotationData.QuotationInfo.KindAttention == "" || this.QuotationData.QuotationInfo.KindAttention == undefined || this.QuotationData.QuotationInfo.KindAttention == null) {
      this.IsQuotationDataValid = false;
      this.KindAttValidation = true;
    } else {
      this.KindAttValidation = false;
    }
  }
  onKindAttContactNumberChange() {
    if (this.QuotationData.QuotationInfo.KindAttContactNo == "" || this.QuotationData.QuotationInfo.KindAttContactNo == undefined || this.QuotationData.QuotationInfo.KindAttContactNo == null) {
      this.IsQuotationDataValid = false;
      this.KindAttContactNoValidilation = true;
      this.KingAttConactNumberValidationMsg = "Contact Number is required"
    }
    else if (this.QuotationData.QuotationInfo.KindAttContactNo.match(/^[0-9]+$/) == null) {
      this.IsQuotationDataValid = false;
      this.KindAttContactNoValidilation = true;
      this.KingAttConactNumberValidationMsg = "Please enter valid contact number"
    }
    else if (this.QuotationData.QuotationInfo.KindAttContactNo.length < 10) {
      this.IsQuotationDataValid = false;
      this.KindAttContactNoValidilation = true;
      this.KingAttConactNumberValidationMsg = "Contact number should be 10-15 digits"
    }

    else {
      this.KindAttContactNoValidilation = false;
      this.KingAttConactNumberValidationMsg = ""
    }
  }
  onKindAttEmailChange() {
    if (this.QuotationData.QuotationInfo.KindAttEmailId == "" || this.QuotationData.QuotationInfo.KindAttEmailId == undefined || this.QuotationData.QuotationInfo.KindAttEmailId == null) {
      this.IsQuotationDataValid = false;
      this.KindAttEmailValidilation = true;
      this.KingAttEmailValidationMsg = "Email is required"
    }
    else if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.QuotationData.QuotationInfo.KindAttEmailId))) {
      this.IsQuotationDataValid = false;
      this.KindAttEmailValidilation = true;
      // Replace the placeholder [emailFormat] with the dynamic email format
      this.KingAttEmailValidationMsg = this.KingAttEmailValidationMsg.replace('[emailFormat]', this.mailIdFormat);
      // this.KingAttEmailValidationMsg = "Should be in Email Id format (e.g. user@ecotech.com)"
    }

    else {
      this.KindAttEmailValidilation = false;
      this.KingAttEmailValidationMsg = ""
    }
  }
  onYourRefChange() {
    if (this.QuotationData.QuotationInfo.YourReference == "" || this.QuotationData.QuotationInfo.YourReference == undefined || this.QuotationData.QuotationInfo.YourReference == null) {
      this.IsQuotationDataValid = false;
      this.YourRefValidation = true;
    } else {
      this.YourRefValidation = false;
    }
  }
  onRefDateChange() {
    if (this.QuotationData.QuotationInfo.ReferenceDateISO == "" || this.QuotationData.QuotationInfo.ReferenceDateISO == undefined || this.QuotationData.QuotationInfo.ReferenceDateISO == null) {
      this.IsQuotationDataValid = false;
      this.RefDateValidation = true;
    } else {
      this.RefDateValidation = false;
    }
  }

  checkPriceOnRequestCostsAndUnits() {
    var inde = 0;
    this.isPricesRequestedBeforeSubmit = true;
    this.QuotationData.QuotationObj.forEach(element => {
      //<CHECK IF THE PRICES ARE PRESENT BEFORE SUBMITTING THE QUOTATION
      if (!this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant1 && element.Units_Variant1 != 0) {
        this.isPricesRequestedBeforeSubmit = false;
        this.IsQuotationDataValid = false;
      }
      if (!this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant2 && element.Units_Variant2 != 0) {
        this.isPricesRequestedBeforeSubmit = false;
        this.IsQuotationDataValid = false;
      }
      if (!this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant3 && element.Units_Variant3 != 0) {
        this.isPricesRequestedBeforeSubmit = false;
        this.IsQuotationDataValid = false;
      }
      if (!this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant4 && element.Units_Variant4 != 0) {
        this.isPricesRequestedBeforeSubmit = false;
        this.IsQuotationDataValid = false;
      }
      if (!this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Option) {
        this.isPricesRequestedBeforeSubmit = false;
        this.IsQuotationDataValid = false;
      }

      element.Options.forEach(ele => {
        ele.Option_Discount = element.Option_Discount;
        ele.Option_FinalDiscount = element.Option_FinalDiscount;
      });
      element.showVariantOptionDiscount = this.showVarientDiscount;
      element.showVariantOptionPrice = this.showVarientandOptionPrice;
      element.showSpecialPrice = this.showSpecialPrice;
      element.Special_pkg_Discount = this.specialPkgDiscount;

      inde++;
    });

  }


  checkQuotationHeaderValidation() {
    this.IsQuotationDataValid = true;
    if (this.QuotationData.QuotationInfo.YourReference == "" || this.QuotationData.QuotationInfo.YourReference == undefined || this.QuotationData.QuotationInfo.YourReference == null) {
      this.YourRefValidation = true;
      this.IsQuotationDataValid = false;
      setTimeout(() => {
        this.yourRefElement.nativeElement.focus();
      }, 0);
    } else {
      this.YourRefValidation = false;
    }
    if (this.QuotationData.QuotationInfo.ReferenceDateISO == "" || this.QuotationData.QuotationInfo.ReferenceDateISO == undefined || this.QuotationData.QuotationInfo.ReferenceDateISO == null) {
      this.IsQuotationDataValid = false;
      this.RefDateValidation = true;
      setTimeout(() => {
        this.refDateElement.nativeElement.focus();
      }, 0);
    } else {
      this.RefDateValidation = false;
    }
    if (this.QuotationData.QuotationInfo.KindAttention == "" || this.QuotationData.QuotationInfo.KindAttention == undefined || this.QuotationData.QuotationInfo.KindAttention == null) {
      this.IsQuotationDataValid = false;
      this.KindAttValidation = true;
      setTimeout(() => {
        this.keyPerElement.nativeElement.focus();
      }, 0);
    } else {
      this.KindAttValidation = false;
    }
    if (this.QuotationData.QuotationInfo.KindAttEmailId == "" || this.QuotationData.QuotationInfo.KindAttEmailId == undefined || this.QuotationData.QuotationInfo.KindAttEmailId == null) {
      this.IsQuotationDataValid = false;
      this.KindAttEmailValidilation = true;
      this.KingAttEmailValidationMsg = "Email is required"
      setTimeout(() => {
        this.keyPerEmail.nativeElement.focus();
      }, 0);
    }
    else {
      this.KindAttEmailValidilation = false;
    }
    if (this.QuotationData.QuotationInfo.KindAttContactNo == "" || this.QuotationData.QuotationInfo.KindAttContactNo == undefined || this.QuotationData.QuotationInfo.KindAttContactNo == null) {
      this.IsQuotationDataValid = false;
      this.KindAttContactNoValidilation = true;
      this.KingAttConactNumberValidationMsg = "Contact Number is required"
      setTimeout(() => {
        this.keyPerCNo.nativeElement.focus();
      }, 0);
    }
    else if (this.QuotationData.QuotationInfo.KindAttContactNo.match(/^[0-9]+$/) == null) {
      this.IsQuotationDataValid = false;
      this.KindAttContactNoValidilation = true;
      this.KingAttConactNumberValidationMsg = "Please enter valid contact number";
      setTimeout(() => {
        this.keyPerCNo.nativeElement.focus();
      }, 0);
    }
    else if (this.QuotationData.QuotationInfo.KindAttContactNo.length < 10) {
      this.IsQuotationDataValid = false;
      this.KindAttContactNoValidilation = true;
      this.KingAttConactNumberValidationMsg = "Contact number should be 10-15 digits";
      setTimeout(() => {
        this.keyPerCNo.nativeElement.focus();
      }, 0);
    }
    else {
      this.KindAttContactNoValidilation = false;
    }
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.QuotationData.QuotationInfo.KindAttEmailId))) {
      this.IsQuotationDataValid = false;
      this.KindAttEmailValidilation = true;
      this.KingAttEmailValidationMsg = "Should be in Email Id format (e.g. user@weiss.com)"
      setTimeout(() => {
        this.keyPerEmail.nativeElement.focus();
      }, 0);
    }
    else {
      this.KindAttEmailValidilation = false;
    }

    if (this.QuotationData.QuotationInfo.QuotationComments == "" || this.QuotationData.QuotationInfo.QuotationComments == undefined || this.QuotationData.QuotationInfo.QuotationComments == null) {
      this.IsQuotationDataValid = false;
      this.QuoteCommentsValidation = true;
      setTimeout(() => {
        this.QuoteComm.nativeElement.focus();
      }, 0);
    }
    else {
      this.QuoteCommentsValidation = false;
    }
   
 if (this.QuotationData.QuotationInfo.SalesContactPerson == "" || this.QuotationData.QuotationInfo.SalesContactPerson == undefined || this.QuotationData.QuotationInfo.SalesContactPerson == null) {
      this.IsQuotationDataValid = false;
      this.SalesContactPersonValidation = true;
      setTimeout(() => {
        this.QuoteComm.nativeElement.focus();
      }, 0);
    }
    else {
      this.SalesContactPersonValidation = false;
    }

  }

  AddQuotation(rule) {
 
    this.checkQuotationHeaderValidation();
    if (!this.IsQuotationDataValid) {
      this.showLoader=false;
      return;
    }
    //CHECK IF ALL THE VALID FIELDS ARE PUT
    this.checkQuotationGridValidation(1);
    if(this.ispriceOnrequestTrigger)
    {
      this.toastr.info('It seems that the prices are provided which needs to be request first. Please press Price on Request button.');
      this.showLoader=false;
      return;
    }
    if (!this.IsQuotationDataValid) {
      this.showLoader=false;
      return;
    }
    //if (rule[0].workflowNextStatusId == this.enquiryStatusList.filter(x => x.Status == 'QuotationSaveAsDraftbyCustomerService')[0]?.Pk_Id) {
      this.assignValuetoObjects();
    //}

    this.role = localStorage.getItem("UserRole");
    var isapplicationEng=this.UserRolesData.filter(x=>x.Name=='Internal Sales/Customer Service');
    if(isapplicationEng.length>0 && isapplicationEng[0].Id==this.role)
    {
      this.checkPriceOnRequestCostsAndUnits();
      if (!this.isPricesRequestedBeforeSubmit) {
        this.toastr.info('It seems that the prices are provided which needs to be request first. Please press Price on Request button.');
        this.showLoader=false;
        return;
       }    
    }
      

    this.QuotationData.QuotationInfo.EnquiryStatus = rule[0].workflowNextStatusId;
    var confimrmsg = AppGlobals.successMessages.filter(x => x.id == rule[0].workflowNextStatusId)[0];
    if (this.QuotationData.QuotationInfo.FinalAmount < 0) {
      this.toastr.info("Quotation total amount can't be negative");
      this.showLoader=false;
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "You want to proceed?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
 
      if (dialogResult) {
        this.showLoader=true;

        var kd = this.QuotationData.QuotationInfo.ReferenceDateISO.year + "-" + this.QuotationData.QuotationInfo.ReferenceDateISO.month + "-" + this.QuotationData.QuotationInfo.ReferenceDateISO.day;
        this.QuotationData.QuotationInfo.ReferenceDate = kd;
        //this.QuotationData.QuotationInfo.Fk_Customer_Id = this.CustomerDetailsObj.CustomerId;
        this.QuotationData.allseriesmodel = [];
        this.commonService.PostData("Enquiry", "AddQuotation", this.QuotationData, null).subscribe((data: any) => {
          if (this.QuotationData.QuotationInfo.EnquiryStatus == this.enquiryStatusList.filter(x => x.Status == 'QuotationGeneratedbyCustomerService')[0]?.Pk_Id) {
            this.generatePDF();
            // this.getDatatoExport();
          } else {
            // this.getDatatoExport();
            this.showLoader=false;
            this.toastr.success("Quotation processed successfully");
            this.activeModal.close('Close Click');
            window.location.reload();
          }
        }, error => {
          this.toastr.error(confimrmsg.errormsg);
          this.showLoader=false;
          //this.router.navigate(['/Enquiry']);
        });
      }
      else
      {
        this.showLoader=false;
      }
    });
  }

  generatePDF() {
    this.showLoader=true;
    let queryString = "";
    let url = this.QuotationPdfGenerationHostPath + "PrintPdf/GeneratePdf?EnquiryId=" + this.enquiObj.EnquiryId;
    queryString = "Url=" + url;

    this.commonService.GetData('Enquiry', 'GeneratePdf', queryString).subscribe((data: any) => {
      let res = data;
      this.toastr.success('Quotation Generated Successfully!');
      this.activeModal.close('Close Click');
      window.location.reload();
      this.showLoader=false;
    }, error => {
      this.toastr.error('Quotation not generated successfully!');
      this.showLoader=false;

    });
  }

  checkifPORPresent() {
    this.isPriceOnRequestValid = false;
    this.QuotationData.QuotationObj.forEach(element => {
      if (element.IsPriceOnRequest_Variant1) {
        this.isPriceOnRequestValid = true;
      }
      if (element.IsPriceOnRequest_Variant2) {
        this.isPriceOnRequestValid = true;
      }
      if (element.IsPriceOnRequest_Variant3) {
        this.isPriceOnRequestValid = true;
      }
      if (element.IsPriceOnRequest_Variant4) {
        this.isPriceOnRequestValid = true;
      }
      if (element.IsPriceOnRequest_Option) {
        this.isPriceOnRequestValid = true;
      }

    });
  }
  quotationPriceOnRequest(status) {

    this.checkQuotationHeaderValidation();
    if (!this.IsQuotationDataValid) {
      return;
    }
    //CHECK IF ALL THE VALID FIELDS ARE PUT
    this.checkQuotationGridValidation(0);
    if (!this.IsQuotationDataValid) {
      return;
    }
    this.checkifPORPresent();
    if (!this.isPriceOnRequestValid) {
      this.toastr.info('The price on request is not applicable as no product is present for price on request.');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: {
        title: "Are you sure?",
        message: "Do you want to proceed for Price on Request?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.showLoader=true;

        var kd = this.QuotationData.QuotationInfo.ReferenceDateISO.year + "-" + this.QuotationData.QuotationInfo.ReferenceDateISO.month + "-" + this.QuotationData.QuotationInfo.ReferenceDateISO.day;
        this.QuotationData.QuotationInfo.ReferenceDate = kd;
        this.QuotationData.QuotationInfo.EnquiryStatus = status;
        //this.QuotationData.QuotationInfo.Fk_Customer_Id = this.CustomerDetailsObj.CustomerId;

        this.commonService.PostData("Enquiry", "AddQuotation", this.QuotationData, null).subscribe((data: any) => {

          this.toastr.success("Prices requested successfully!");
          this.showLoader=false;
          this.activeModal.close('Close Click');
          window.location.reload();
        }, error => {
          this.toastr.error("Failed to add enquiry details!");
          this.showLoader=false;
        });
      }
    });
  }


  closePopup() {
    this.activeModal.close('Close Click');
  }

  AddNewProduct() {
    var obj = new ProductDetailsModel();
    this.QuotationData.QuotationObj.push(obj);
  }
  DeleteProduct(index: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to delete this row?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (index != null) {
          this.QuotationData.QuotationObj.splice(index, 1);
        } else {
          this.QuotationData.QuotationObj.pop();
        }
        // this.calculateTotalQuotationValues();
        this.calculateTotalPricesForProduct(index - 1, 0)
      }
    });
  }
  setColspan(index) {
    this.columnCount = 0;
    if (this.QuotationData.QuotationObj[index]?.Variant1Label) {
      this.columnCount = this.columnCount + 1;
    }
    if (this.QuotationData.QuotationObj[index]?.Variant2Label) {
      this.columnCount = this.columnCount + 1;
    }
    if (this.QuotationData.QuotationObj[index]?.Variant3Label) {
      this.columnCount = this.columnCount + 1;
    }
    if (this.QuotationData.QuotationObj[index]?.Variant4Label) {
      this.columnCount = this.columnCount + 1;
    }
    if (this.columnCount == 4) {
      this.QuotationData.QuotationObj[index].colspan1 = 1;
      this.QuotationData.QuotationObj[index].colspan2 = 1;
      this.QuotationData.QuotationObj[index].colspan3 = 1;
      this.QuotationData.QuotationObj[index].colspan4 = 1;
    } else if (this.columnCount == 3) {
      this.QuotationData.QuotationObj[index].colspan1 = 1;
      this.QuotationData.QuotationObj[index].colspan2 = 1;
      this.QuotationData.QuotationObj[index].colspan3 = 1;
      this.QuotationData.QuotationObj[index].colspan4 = 0;
    } else if (this.columnCount == 2) {
      this.QuotationData.QuotationObj[index].colspan1 = 1;
      this.QuotationData.QuotationObj[index].colspan2 = 1;
      this.QuotationData.QuotationObj[index].colspan3 = 0;
      this.QuotationData.QuotationObj[index].colspan4 = 0;
    } else if (this.columnCount == 1) {
      this.QuotationData.QuotationObj[index].colspan1 = 1;
      this.QuotationData.QuotationObj[index].colspan2 = 0;
      this.QuotationData.QuotationObj[index].colspan3 = 0;
      this.QuotationData.QuotationObj[index].colspan4 = 0;
    }
  }

  calculateTotalPricesForProduct(index, flag) {
    var modelprice = 0;
    var var1price = 0;
    var var2price = 0;
    var var3price = 0;
    var var4price = 0;
    var optionprice = 0;
    var QuotationObj = this.QuotationData.QuotationObj[index];

    modelprice = QuotationObj?.ProductModelPrice == undefined ? 0 : QuotationObj.ProductModelPrice;
    var1price = QuotationObj?.Variant1Price == undefined ? 0 : QuotationObj.Variant1Price;
    var2price = QuotationObj?.Variant2Price == undefined ? 0 : QuotationObj.Variant2Price;
    var3price = QuotationObj?.Variant3Price == undefined ? 0 : QuotationObj.Variant3Price;
    var4price = QuotationObj?.Variant4Price == undefined ? 0 : QuotationObj.Variant4Price;

    this.setColspan(index);

    if (flag == 0) {
      if (QuotationObj?.Units_Variant1 >= 0 && QuotationObj?.IsUnits_Variant1) {
        QuotationObj.Variant1Price = QuotationObj.Variant1Price * QuotationObj.Units_Variant1;
        var1price = QuotationObj.Variant1Price;
      }
      if (QuotationObj?.Units_Variant2 >= 0 && QuotationObj?.IsUnits_Variant2) {
        QuotationObj.Variant2Price = QuotationObj.Variant2Price * QuotationObj.Units_Variant2;
        var2price = QuotationObj.Variant2Price;
      }
      if (QuotationObj?.Units_Variant3 >= 0 && QuotationObj?.IsUnits_Variant3) {
        QuotationObj.Variant3Price = QuotationObj.Variant3Price * QuotationObj.Units_Variant3;
        var3price = QuotationObj.Variant3Price;
      }
      if (QuotationObj?.Units_Variant4 >= 0 && QuotationObj?.IsUnits_Variant4) {
        QuotationObj.Variant4Price = QuotationObj.Variant4Price * QuotationObj.Units_Variant4;
        var4price = QuotationObj.Variant4Price;
      }
    }

    QuotationObj.PerUnitAmount = QuotationObj?.PerUnitAmount == undefined ? 0 : QuotationObj.PerUnitAmount;
    QuotationObj.TotalAmount = QuotationObj?.TotalAmount == undefined ? 0 : QuotationObj.TotalAmount;
    QuotationObj.FinalAmount = QuotationObj?.FinalAmount == undefined ? 0 : QuotationObj.FinalAmount;
    QuotationObj.OptionsPrice = 0;
    QuotationObj.Options?.forEach(element => {
      QuotationObj.OptionsPrice = QuotationObj?.OptionsPrice + element.Price;
      optionprice = optionprice + element.Price;
    });

    QuotationObj.PerUnitAmount = modelprice + var1price + var2price + var3price + var4price + optionprice;
    QuotationObj.Variant1 = (QuotationObj.Variant1 == null || QuotationObj.Variant1 == undefined) ? null : QuotationObj.Variant1;
    QuotationObj.Variant2 = (QuotationObj.Variant2 == null || QuotationObj.Variant2 == undefined) ? null : QuotationObj.Variant2;
    QuotationObj.Variant3 = (QuotationObj.Variant3 == null || QuotationObj.Variant3 == undefined) ? null : QuotationObj.Variant3;
    QuotationObj.Variant4 = (QuotationObj.Variant4 == null || QuotationObj.Variant4 == undefined) ? null : QuotationObj.Variant4;
    QuotationObj.Discount = (QuotationObj.Discount == null || QuotationObj.Discount == undefined) ? null : QuotationObj.Discount;


    //****************for All Discount selection******************** */
    // if(this.discountaddmanually)
    // {
    if (this.QuotationData.QuotationObj[index]?.colspan1 == 1) {
      var percent = 100 - QuotationObj.Varient1_Discount;
      QuotationObj.Varient1_FinalPrice = Math.round(var1price * QuotationObj.Quantity * (percent / 100));
    }

    if (this.QuotationData.QuotationObj[index]?.colspan2 == 1) {
      var percent = 100 - QuotationObj.Varient2_Discount;
      QuotationObj.Varient2_FinalPrice = Math.round(var2price * QuotationObj.Quantity * (percent / 100));
    }

    if (this.QuotationData.QuotationObj[index]?.colspan3 == 1) {

      var percent = 100 - QuotationObj.Varient3_Discount;
      QuotationObj.Varient3_FinalPrice = Math.round(var3price * QuotationObj.Quantity * (percent / 100));
    }

    if (this.QuotationData.QuotationObj[index]?.colspan4 == 1) {
      var percent = 100 - QuotationObj.Varient4_Discount;
      QuotationObj.Varient4_FinalPrice = Math.round(var4price * QuotationObj.Quantity * (percent / 100));
    }

    var percen=100-QuotationObj.Model_Discount;
    QuotationObj.Model_FinalPrice=Math.round(modelprice * QuotationObj.Quantity*(percen/100));

    this.QuotationData.QuotationObj[index].Option_Discount = this.QuotationData.QuotationObj[index]?.Option_Discount == undefined ? 0 : this.QuotationData.QuotationObj[index]?.Option_Discount;
    var percent = 100 - QuotationObj.Option_Discount;
    QuotationObj.Option_FinalDiscount = Math.round(optionprice * QuotationObj.Quantity * (percent / 100));

    var percent = 100 - QuotationObj.Discount;
    QuotationObj.TotalAfterDiscount = Math.round(QuotationObj.PerUnitAmount * QuotationObj.Quantity * (percent / 100));

    if (this.discountaddmanually) {
      var modelfinalprice = this.QuotationData.QuotationObj[index].ProductModelPrice * QuotationObj.Quantity;

      QuotationObj.TotalAfterDiscount = Math.round(QuotationObj.Varient1_FinalPrice + QuotationObj.Varient2_FinalPrice + QuotationObj.Varient3_FinalPrice + QuotationObj.Varient4_FinalPrice + QuotationObj.Option_FinalDiscount + QuotationObj.Model_FinalPrice);
      var final = QuotationObj.TotalAfterDiscount;
    }
    else {
      final = QuotationObj.TotalAfterDiscount;
    }

    QuotationObj.FinalAmount = Math.round(final);

    this.calculateTotalQuotationValues();
    this.setspecialDiscountPrice();
    //}

    //****************for individual Discount selection******************** */
    //  else 
    //  {
    //    var percent = 100 - QuotationObj.Discount;
    //    QuotationObj.TotalforRfqdiscount = QuotationObj.PerUnitAmount * QuotationObj.Quantity * (percent / 100);
    //    final= QuotationObj.TotalforRfqdiscount;

    //    QuotationObj.FinalAmount = Math.round(final);
    //    this.calculateTotalQuotationValues();
    //    this.setspecialDiscountPrice();
    //  }
  }

  calculateTotalQuotationValues() {
    this.QuotationData.QuotationInfo.PerUnitAmount = 0;
    this.QuotationData.QuotationInfo.TotalAmount = 0;
    this.QuotationData.QuotationInfo.FinalAmount = 0;
    var final = 0;
    this.QuotationData.QuotationObj.forEach(element => {
      this.QuotationData.QuotationInfo.PerUnitAmount = this.QuotationData.QuotationInfo.PerUnitAmount + element.PerUnitAmount;
      this.QuotationData.QuotationInfo.TotalAmount = this.QuotationData.QuotationInfo.TotalAmount + element.TotalAmount;

      final = parseFloat(final.toString()) + parseFloat(element.FinalAmount);

      var amount = this.QuotationData.QuotationInfo.FinalAmount + element.FinalAmount;
      this.QuotationData.QuotationInfo.FinalAmount = Math.round(amount);

    });

    this.QuotationData.QuotationInfo.FinalAmount = Math.round(final);
    this.finalpricewithoutspecialdiscount = this.QuotationData.QuotationInfo.FinalAmount;
  }
  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if
      (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;

    }
    else {
      return true;
    }
  }




  isintNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 46 || this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }


  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }

    if (this.QuotationData.QuotationInfo.QuotationComments == "" || this.QuotationData.QuotationInfo.QuotationComments == undefined || this.QuotationData.QuotationInfo.QuotationComments == null) {
      this.IsQuotationDataValid = false;
      this.QuoteCommentsValidation = true;
    } else {
      this.QuoteCommentsValidation = false;
    }
  }

  //********************************* For Discount Fields ************* Variant1Label*/
  setDiscountType() {
    if (this.DiscountforOneOrAll == 1) {
      this.QuotationData.QuotationObj.forEach(element => {
        this.discountaddmanually = false;
        this.isfetchfromRFQ = true;
        this.showOverallDiscount=false;
        element.Model_Discount=0;
        element.Varient1_Discount = 0;
        element.Varient2_Discount = 0;
        element.Varient3_Discount = 0;
        element.Varient4_Discount = 0;
        element.Option_Discount = 0;

        // element.Varient1_Discount = element.Variant1Label.trim().length === 0 ? 0 : element.Discount;
        // element.Varient2_Discount = element.Variant2Label.trim().length === 0 ? 0 : element.Discount;
        // element.Varient3_Discount = element.Variant3Label.trim().length === 0 ? 0 : element.Discount;
        // element.Varient4_Discount = element.Variant4Label.trim().length === 0 ? 0 : element.Discount;
     
        // element.Option_Discount = element.Options.length === 0 ? 0 : element.Discount;
        var inde = 0;
        this.calculateTotalPricesForProduct(inde, 1);
        inde++;
      });
    }
    else {
      this.QuotationData.QuotationObj.forEach(element => {
        this.discountaddmanually = true;
        this.isfetchfromRFQ = false;
        this.showOverallDiscount=true;
          element.Discount=0;
        if (element.Varient1_Discount == null || element.Varient2_Discount == null || element.Varient3_Discount == null || element.Varient4_Discount == null || element.Option_Discount == null||element.Model_Discount==null) {
         element.Model_Discount=0;
          element.Varient1_Discount = 0;
          element.Varient2_Discount = 0;
          element.Varient3_Discount = 0;
          element.Varient4_Discount = 0;
          element.Option_Discount = 0;
        }
        var inde = 0;
        this.calculateTotalPricesForProduct(inde, 1);
        inde++;
      });
    }
  }
  checkVarientPrice(event) {
    if (event.target.checked) {
      this.showVarientandOptionPrice = true;
      this.showSpecialPrice = false;
    }
    else {
      this.showVarientandOptionPrice = false;
    }
  }
  checkVarientDiscount(event) {
    if (event.target.checked) {
      this.showVarientDiscount = true;
      this.showSpecialPrice = false;
    }
    else {
      this.showVarientDiscount = false;
    }
  }
  checkSpecialPrice(event) {
    if (event.target.checked) {
      this.showSpecialPrice = true;
      this.showVarientandOptionPrice = false;
      this.showVarientDiscount = false;
    }
    else {
      this.showSpecialPrice = false;
    }
  }

  setspecialDiscountPrice() {
    if ((this.specialPkgDiscount < 0)) {
      this.specialPkgDiscount = 0;
      return;
    }
    else if ((this.specialPkgDiscount > 100)) {
      this.specialPkgDiscount = 0;
      return;
    }
    else if (this.specialPkgDiscount != 0) {
      var percent = 100 - this.specialPkgDiscount;
      var calculate = this.finalpricewithoutspecialdiscount * (percent / 100);
      this.QuotationData.QuotationInfo.FinalAmount = Math.round(calculate);
      this.specialPkgDiscount = this.specialPkgDiscount;
    }
    else if (this.specialPkgDiscount == 0) {
      this.QuotationData.QuotationInfo.FinalAmount = Math.round(this.finalpricewithoutspecialdiscount);
    }
  }

  assignValuetoObjects() {
    var ind = 0;
    this.QuotationData.QuotationObj.forEach(element => {
      element.Options.forEach(ele => {
        ele.Option_Discount = element.Option_Discount;
        ele.Option_FinalDiscount = element.Option_FinalDiscount;
      });
      element.showVariantOptionDiscount = this.showVarientDiscount;
      element.showVariantOptionPrice = this.showVarientandOptionPrice;
      element.showSpecialPrice = this.showSpecialPrice;
      element.Special_pkg_Discount = this.specialPkgDiscount;

      ind++;
    });
  }


  getWorkflowActions() {
    let queryString = "";
    queryString = "enquiryId=" + this.enquiObj.EnquiryId + "&workflowId=" + this.workflowId;

    this.showLoader=true;
    this.commonService.GetData("EnquiryWorkflowTransitionRules", "GetTransitionRulesByStatus", queryString).subscribe((data: any) => {
     
      if (data != null) {       
        this.workflowActionList=data;
      }
      this.showLoader=false;
    }, error => {
      this.showLoader=false;
    });
  }

  getEnquiryStatus() {
    
    this.showLoader=true;
    
    let tracker = this.commonService.GetData("Enquiry", "GetEnquiryStatus", null);
    
    tracker.subscribe((data: any) => {
      this.enquiryStatusList = data;
      this.showLoader=false;
    }, error => {
      this.showLoader=false;
    });

    return tracker;

  }

  OnWorkflowAction(QuotationForm: NgForm, workflowActionId: number) {
    this.showLoader=true;
    var rule = this.workflowActionList.filter(x => x.workflowActionId == workflowActionId);
    var priceOnRequestRule = this.workflowActionList.filter(x => x.workflowActionCode == "PriceOnRequest");
    if (priceOnRequestRule.length > 0 && rule[0].workflowActionId == priceOnRequestRule[0].workflowActionId) {
      this.quotationPriceOnRequest(priceOnRequestRule[0].workflowNextStatusId);
      this.showLoader=false;
      return;
    }

    QuotationForm.control.markAllAsTouched();
    if (QuotationForm.invalid) {
      this.toastr.error("Please fill required information");
      this.showLoader=false;
      return;
    }
    this.AddQuotation(rule);
  //  this.showLoader=false;
  }
// getPriceDiscountApprovalAuthority() {
//     let queryString = "";
//     queryString = "enquiryId=" + this.QuotationData.QuotationInfo.EnquiryId;
//     this.showLoader=true;
//     this.commonService.GetData("EnquiryWorkflowTransitionRules", "getPriceDiscountApprovalAuthority", queryString).subscribe((data: any) => {
//       this.authorityLevels = data;
    
//       this.showLoader=false;
    
//       this.cd.detectChanges();
//     }, error => {
//       this.showLoader=false;
//     });
//   }
 

  changeSalesContactPerson(value: any) {
    var employee = this.EmployeeData?.filter(x => x.Fk_User_Id == value);
    this.QuotationData.QuotationInfo.ContactNumber = employee[0].ContactNumber;
    if (this.QuotationData.QuotationInfo.SalesContactPerson == "" || this.QuotationData.QuotationInfo.SalesContactPerson == undefined || this.QuotationData.QuotationInfo.SalesContactPerson == null) {
      this.IsQuotationDataValid = false;
      this.SalesContactPersonValidation = true;
    } else {
      this.SalesContactPersonValidation = false;
    }
  }

  hasAccesstoFillQtyDiscount()
  {
    this.showLoader = true;
    this.commonService.GetData("Account", "GetAllRoles", null).subscribe((data: any) => {
      this.Roles = data;
      this.Roles=this.Roles.filter(x=>x.Id==this.role);
      this.showOverallDiscount=true;
      if(this.Roles[0].Id=="1"||this.Roles[0].Id=="14"){
          this.isQtyDiscountEditable=false;
          this.isfetchfromRFQ=true;
          this.discountaddmanually=true;
        
        }else{
          this.isQtyDiscountEditable=true;
          this.isfetchfromRFQ=true;
          this.discountaddmanually=true;
        }
     
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
 getPORAuthorities()
  {
    this.commonService.GetData("EnquiryWorkflowTransitionRules", "getPriceOnRequestAuthority", null).subscribe((data: any) => {
      this.priceOnRequestAuthorities = data; 
      if(this.priceOnRequestAuthorities.length>0)
      {
        if(this.priceOnRequestAuthorities.filter(x=>x.Fk_RoleId==localStorage.getItem("UserRole")).length>0)
        {
          this.priceonRequestDisabled=false;
        }
      }
      this.cd.detectChanges();
     // console.log(this.QuotationData.QuotationObj);
    }, error => {
      this.showLoader=false;
    });
 }
  OnSalesPriceChange(index) {
  //this.QuotationData.QuotationObj[index] = false;
   
  }

  getFrameAgreement(index) {
    //this.QuotationData.QuotationObj[index].ProductModelValidation = false;
    let queryString = "";
    queryString = "id=" + this.CustomerDetailsObj.CustomerId;
    this.commonService.GetData("CustomerSpecialDiscount", "GetById", queryString).subscribe((data: any) => {
      this.EnquiryObj.CustomerSpecialDiscountdtls = data;     
      var productModelList=data.filter(x=>x.Fk_ProductModel==this.QuotationData.QuotationObj[index].ProductModel);
      if(productModelList.length >0)
      {
        this.QuotationData.QuotationObj[index].Discount=productModelList[0].ProductDiscount;
       // this.getPriceDiscountApprovalAuthority();
        //this.frameAgreemtdiscountDisabled=true;
      }      
    });
  }
  getUserRoles(){
    this.showLoader=true;
    this.commonService.GetData("Account", "GetAll", null).subscribe((data: any) => {
      this.UserRolesData = data;
      this.isAppEngRoles=false;
      let appEngRoles=data.filter(x=>x.Name=='Application Engineering L1' || x.Name=='Application Engineering L2' || x.Name=='Application Engineering L3');
      if(appEngRoles.length>0)
      {
        if(appEngRoles.filter(x=>x.Id==this.role).length>0)
        {
          this.isAppEngRoles=true;
        }
      }
      this.showLoader=false;
    }, error => {
      this.showLoader=false;
    });
  }

  getPriceDiscountApprovalAuthorityByPayload() {
    
    //this.showLoader=true;
   
    this.commonService.PostData("EnquiryWorkflowTransitionRules", "getPriceDiscountApprovalAuthorityByPayload", this.QuotationData,null).subscribe((data: any) => {
     // th(is.authorityLevels = data;
      if(!(data==null || data==0))
      {
        this.workflowActionList.filter(x=>x.workflowActionName=="Submit")[0].workflowNextStatusId=data;
      }
      //this.showLoader=false;
    
      this.cd.detectChanges();
    }, error => {
      //this.showLoader=false;
    });
  }

  GetVarientDropdowndataforModel(index) {
    
    let queryString = "";
    queryString = "ProductModelId=" + this.QuotationData.QuotationObj[index].ProductModel;
    // this.commonService.GetData("Enquiry", "GetQuotationVariantDropdownData", queryString).subscribe((data: any) => {

    //   this.variantDropdownData = data;
    //   // this.productDrpdnDetails.Variant1 = this.variantDropdownData.Variant1;
    //   this.QuotationData.QuotationObj[index].productmodeldata = undefined;
    //   this.QuotationData.QuotationObj[index].selectedVariant1 = this.variantDropdownData.Variant1;

    //   if (!this.QuotationData.QuotationObj[index].IsLevel2DepOnLevel1) {
    //     this.QuotationData.QuotationObj[index].selectedVariant2 = this.variantDropdownData.Variant2;
    //   }
    //   if (!this.QuotationData.QuotationObj[index].IsLevel3DepOnLevel2) {
    //     this.QuotationData.QuotationObj[index].selectedVariant3 = this.variantDropdownData.Variant3;
    //   }
    //   if (!this.QuotationData.QuotationObj[index].IsLevel4DepOnLevel3) {
    //     this.QuotationData.QuotationObj[index].selectedVariant4 = this.variantDropdownData.Variant4;

    //   }
     
    // });

    let tracker =  this.commonService.GetData("Enquiry", "GetQuotationVariantDropdownData", queryString);

    tracker.subscribe((data: any) => {
      this.variantDropdownData = data;
      this.QuotationData.QuotationObj[index].productmodeldata = undefined;
      this.QuotationData.QuotationObj[index].selectedVariant1 = this.variantDropdownData.Variant1;

      if (!this.QuotationData.QuotationObj[index].IsLevel2DepOnLevel1) {
        this.QuotationData.QuotationObj[index].selectedVariant2 = this.variantDropdownData.Variant2;
      }
      if (!this.QuotationData.QuotationObj[index].IsLevel3DepOnLevel2) {
        this.QuotationData.QuotationObj[index].selectedVariant3 = this.variantDropdownData.Variant3;
      }
      if (!this.QuotationData.QuotationObj[index].IsLevel4DepOnLevel3) {
        this.QuotationData.QuotationObj[index].selectedVariant4 = this.variantDropdownData.Variant4;

      }
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });

    return tracker;
  }

  var1UnitsChange(index) {
    forkJoin([
           this.GetVarientDropdowndataforModel(index)
         ]).pipe(tap(res => {  
       var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
       let strunits = this.QuotationData.QuotationObj[index].Units_Variant1.toString();
       if (this.QuotationData.QuotationObj[index].Units_Variant1 == undefined || this.QuotationData.QuotationObj[index].Units_Variant1 == null) {
         this.QuotationData.QuotationObj[index].var1_Unit_Validation = true;
         this.IsQuotationDataValid = false;
       } else if (this.QuotationData.QuotationObj[index].Units_Variant1 < 0) {
         this.QuotationData.QuotationObj[index].var1_Unit_Validation = true;
         this.IsQuotationDataValid = false;
       } else if (this.QuotationData.QuotationObj[index].Units_Variant1 % 1 != 0) {
         this.toastr.info('Please enter only integer value');
         if (seriesname == 'HG' || seriesname == 'HN') {
           this.QuotationData.QuotationObj[index].Units_Variant1 = 100;
         }
         else {
           this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
         }
   
         this.IsQuotationDataValid = false;
       } else if (strunits.includes(".")) {
         this.toastr.info('Please enter valid units');
         if (seriesname == 'HG' || seriesname == 'HN') {
           this.QuotationData.QuotationObj[index].Units_Variant1 = 100;
         }
         else {
           this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
         }
   
         this.IsQuotationDataValid = false;
       }
   
       else {
   
         if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant1 % 100 != 0)) {
           this.toastr.info('Please enter the units in multiple of 100.');
           this.QuotationData.QuotationObj[index].var1_Unit_Validation = true;
           this.IsQuotationDataValid = false;
         } else {
           this.QuotationData.QuotationObj[index].var1_Unit_Validation = false;
         }
       }
       if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
         this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
         var selectedVariant1 = this.QuotationData.QuotationObj[index].Variant1;
         var price: number = this.variantDropdownData.Variant1.filter(x => x.Id == selectedVariant1)[0]?.Price;
         var units: number = this.QuotationData.QuotationObj[index].Units_Variant1;
         this.QuotationData.QuotationObj[index].Variant1Price = price * units;
       } else {
         var selectedVariant1 = this.QuotationData.QuotationObj[index].Variant1;
         var price: number = this.variantDropdownData.Variant1.filter(x => x.Id == selectedVariant1)[0]?.Price;
         var units: number = this.QuotationData.QuotationObj[index].Units_Variant1;
         this.QuotationData.QuotationObj[index].Variant1Price = price * units;
         //this.QuotationData.QuotationObj[index].Variant1Price = this.QuotationData.QuotationObj[index].selectedVariant1.filter(x => x.Id == selectedVariant1)[0]?.Price;
       }
       this.calculateTotalPricesForProduct(index, 1);
   })).subscribe(x=>{
          // this.updateLoaderEvent.emit(false);
       });
     }
   
     var2UnitsChange(index) {
    forkJoin([
         this.GetVarientDropdowndataforModel(index)
       ]).pipe(tap(res => {   
       var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
       let strunits = this.QuotationData.QuotationObj[index].Units_Variant2.toString();
       if (this.QuotationData.QuotationObj[index].Units_Variant2 == undefined || this.QuotationData.QuotationObj[index].Units_Variant2 == null) {
         this.QuotationData.QuotationObj[index].var2_Unit_Validation = true;
         this.IsQuotationDataValid = false;
       } else if (this.QuotationData.QuotationObj[index].Units_Variant2 < 0) {
         this.QuotationData.QuotationObj[index].var2_Unit_Validation = true;
         this.IsQuotationDataValid = false;
       } else if (this.QuotationData.QuotationObj[index].Units_Variant2 % 1 != 0) {
         this.toastr.info('Please enter only integer value');
         if (seriesname == 'HG' || seriesname == 'HN') {
           this.QuotationData.QuotationObj[index].Units_Variant2 = 100;
         }
         else {
           this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
         }
   
         this.IsQuotationDataValid = false;
       }
       else if (strunits.includes(".")) {
         this.toastr.info('Please enter valid units');
         if (seriesname == 'HG' || seriesname == 'HN') {
           this.QuotationData.QuotationObj[index].Units_Variant2 = 100;
         }
         else {
           this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
         }
   
         this.IsQuotationDataValid = false;
       }
       else {
   
         if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant2 % 100 != 0)) {
           this.toastr.info('Please enter the units in multiple of 100.');
           this.QuotationData.QuotationObj[index].var2_Unit_Validation = true;
           this.IsQuotationDataValid = false;
         } else {
           this.QuotationData.QuotationObj[index].var2_Unit_Validation = false;
         }
       }
       if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
         this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
         var selectedVariant2 = this.QuotationData.QuotationObj[index].Variant2;
         var price: number = this.variantDropdownData.Variant2.filter(x => x.Id == selectedVariant2)[0]?.Price;
         var units: number = this.QuotationData.QuotationObj[index].Units_Variant2;
         this.QuotationData.QuotationObj[index].Variant2Price = price * units;
       } else {
         var selectedVariant2 = this.QuotationData.QuotationObj[index].Variant2;
         var price: number = this.variantDropdownData.Variant2.filter(x => x.Id == selectedVariant2)[0]?.Price;
         var units: number = this.QuotationData.QuotationObj[index].Units_Variant2;
         this.QuotationData.QuotationObj[index].Variant2Price = price * units;
         // this.QuotationData.QuotationObj[index].Variant2Price = this.productDrpdnDetails.Variant2.filter(x => x.Id == selectedVariant2)[0]?.Price;
       }
       this.calculateTotalPricesForProduct(index, 1);
   })).subscribe(x=>{
           // this.updateLoaderEvent.emit(false);
       });
     }
     var3UnitsChange(index) {
    forkJoin([
         this.GetVarientDropdowndataforModel(index)
       ]).pipe(tap(res => {
       var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
       let strunits = this.QuotationData.QuotationObj[index].Units_Variant3.toString();
       if (this.QuotationData.QuotationObj[index].Units_Variant3 == undefined || this.QuotationData.QuotationObj[index].Units_Variant3 == null) {
         this.QuotationData.QuotationObj[index].var3_Unit_Validation = true;
         this.IsQuotationDataValid = false;
       } else if (this.QuotationData.QuotationObj[index].Units_Variant3 < 0) {
         this.QuotationData.QuotationObj[index].var3_Unit_Validation = true;
         this.IsQuotationDataValid = false;
       } else if (this.QuotationData.QuotationObj[index].Units_Variant3 % 1 != 0) {
         this.toastr.info('Please enter only integer value');
         if (seriesname == 'HG' || seriesname == 'HN') {
           this.QuotationData.QuotationObj[index].Units_Variant3 = 100;
         }
         else {
           this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
         }
   
         this.IsQuotationDataValid = false;
       }
       else if (strunits.includes(".")) {
         this.toastr.info('Please enter valid units');
         if (seriesname == 'HG' || seriesname == 'HN') {
           this.QuotationData.QuotationObj[index].Units_Variant3 = 100;
         }
         else {
           this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
         }
   
         this.IsQuotationDataValid = false;
       }
       else {
   
         if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant3 % 100 != 0)) {
           this.toastr.info('Please enter the units in multiple of 100.');
           this.QuotationData.QuotationObj[index].var3_Unit_Validation = true;
           this.IsQuotationDataValid = false;
         } else {
           this.QuotationData.QuotationObj[index].var3_Unit_Validation = false;
         }
       }
       if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
         this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
         var selectedVariant3 = this.QuotationData.QuotationObj[index].Variant3;
         var price: number = this.variantDropdownData.Variant3.filter(x => x.Id == selectedVariant3)[0]?.Price;
         var units: number = this.QuotationData.QuotationObj[index].Units_Variant3;
         this.QuotationData.QuotationObj[index].Variant3Price = price * units;
       } else {
         var selectedVariant3 = this.QuotationData.QuotationObj[index].Variant3;
         var price: number = this.variantDropdownData.Variant3.filter(x => x.Id == selectedVariant3)[0]?.Price;
         var units: number = this.QuotationData.QuotationObj[index].Units_Variant3;
         this.QuotationData.QuotationObj[index].Variant3Price = price * units;
         //this.QuotationData.QuotationObj[index].Variant3Price = this.productDrpdnDetails.Variant3.filter(x => x.Id == selectedVariant3)[0]?.Price;
       }
       this.calculateTotalPricesForProduct(index, 1);
    })).subscribe(x=>{
           // this.updateLoaderEvent.emit(false);
       });
     }
     var4UnitsChange(index) {
     forkJoin([
         this.GetVarientDropdowndataforModel(index)
       ]).pipe(tap(res => {
       var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
       let strunits = this.QuotationData.QuotationObj[index].Units_Variant4.toString();
       if (this.QuotationData.QuotationObj[index].Units_Variant4 == undefined || this.QuotationData.QuotationObj[index].Units_Variant4 == null) {
         this.QuotationData.QuotationObj[index].var4_Unit_Validation = true;
         this.IsQuotationDataValid = false;
       } else if (this.QuotationData.QuotationObj[index].Units_Variant4 < 0) {
         this.QuotationData.QuotationObj[index].var4_Unit_Validation = true;
         this.IsQuotationDataValid = false;
       } else if (this.QuotationData.QuotationObj[index].Units_Variant4 % 1 != 0) {
         this.toastr.info('Please enter only integer value');
         if (seriesname == 'HG' || seriesname == 'HN') {
           this.QuotationData.QuotationObj[index].Units_Variant4 = 100;
         }
         else {
           this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
         }
   
         this.IsQuotationDataValid = false;
       }
       else if (strunits.includes(".")) {
         this.toastr.info('Please enter valid units');
         if (seriesname == 'HG' || seriesname == 'HN') {
           this.QuotationData.QuotationObj[index].Units_Variant4 = 100;
         }
         else {
           this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
         }
   
         this.IsQuotationDataValid = false;
       }
       else {
   
         if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant4 % 100 != 0)) {
           this.toastr.info('Please enter the units in multiple of 100');
           this.QuotationData.QuotationObj[index].var4_Unit_Validation = true;
           this.IsQuotationDataValid = false;
         } else {
           this.QuotationData.QuotationObj[index].var4_Unit_Validation = false;
         }
       }
       if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
         this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
         var selectedVariant4 = this.QuotationData.QuotationObj[index].Variant4;
         var price: number = this.variantDropdownData.Variant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
         var units: number = this.QuotationData.QuotationObj[index].Units_Variant4;
         this.QuotationData.QuotationObj[index].Variant4Price = price * units;
       } else {
         var selectedVariant4 = this.QuotationData.QuotationObj[index].Variant4;
         var price: number = this.variantDropdownData.Variant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
         var units: number = this.QuotationData.QuotationObj[index].Units_Variant4;
         this.QuotationData.QuotationObj[index].Variant4Price = price * units;
         // this.QuotationData.QuotationObj[index].Variant4Price = this.productDrpdnDetails.Variant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
       }
       this.calculateTotalPricesForProduct(index, 1);
    })).subscribe(x=>{
         // this.updateLoaderEvent.emit(false);
     });
     }

}
