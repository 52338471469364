
export class OutofOfficeActivityModel {
    Pk_Id : number;
    OutofOffice:boolean=false; 
    StartDate : any;
    EndDate : any;
    //StartDateISO: string = "";
    //EndDateISO: string = "";
    AlternateUserId : string = "";
    AlternateUserName : string = "";
    Fk_UserId : string = "";
   // Role : any;
}