import { Component, OnInit, Input } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductSeries, Station, VariantLevel1 } from 'src/app/Model/ProductModel';
import { Title } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-add-variant-level1',
  templateUrl: './add-variant-level1.component.html',
  styleUrls: ['./add-variant-level1.component.scss']
})
export class AddVariantLevel1Component implements OnInit {
  VariantLevel1obj: VariantLevel1 = new VariantLevel1();
  charCode: any;
  @Input()
  showLoader: boolean = false;
  ProductSeries: ProductSeries = new ProductSeries();
  ProductModel: any[] = [];
  StationList: any[] = [];
  OrignalStationList: any[] = [];
  SearchText:string = "";

  SeriesName: string = '';
  ProductSeriesVariantLabel: string = '';
  Variant1ProductSeries: string;
  

  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title,
    private appConfigService : AppConfigService) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    // this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.VariantLevel1obj.ProductSeries = this.route.snapshot.params["id"];
    this.getAllProductSeries();
    this.GetProductModelById(this.VariantLevel1obj.ProductSeries);

  }

  /*******************************************************************************
   * Get data for Variant Level 1
   * ******************************************************************************/
  getAllProductSeries() {
    this.showLoader = true;

    let queryString = "";
    queryString = "Id=" + this.VariantLevel1obj.ProductSeries;

    this.CommonService.GetData("ProductSeries", "GetById", queryString).subscribe((data: any) => {
      this.VariantLevel1obj.SeriesName = data.SeriesName;
      this.VariantLevel1obj.ProductSeries = data.Id;
      this.ProductSeriesVariantLabel = data.Label_Variant1;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetProductModelById(SeriesId: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "SeriesId=" + SeriesId;
    this.CommonService.GetData("ProductModel", "GetProductModelById", queryString).subscribe((data: any) => {
      this.ProductModel = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetStation(Model: any) {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + Model;
    this.CommonService.GetData("ProductVariantLevel1", "GetAllStation", queryString).subscribe((data: any) => {
      this.StationList = data;
      this.OrignalStationList = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }


  /*******************************************************************************
   * End get data for add product family
   * ******************************************************************************/

  onModelSelected(event: any) {
    if (this.VariantLevel1obj.SeriesName == 'TC' || this.VariantLevel1obj.SeriesName == 'TR') {
      this.VariantLevel1obj.Level1arr = [];
      this.StationList = [];
      this.GetStation(event.target.value);
    }

  }

  onIsPriceOnRequest(event: any) {
    if (event.checked) {
      this.VariantLevel1obj.Price = 0;
    }
  }

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  // OnStationSearch(event:any)
  // {
  //   this.StationList = [];
  //   if(event != "" && event != null && event != undefined)
  //   {
  //    /// this.StationList = this.OrignalStationList.filter(element =>element.Level1 == event)
  //    this.OrignalStationList.forEach(element => {
  //      if((element.Level1+'').indexOf(event) > -1)
  //      {
  //        this.StationList.push(element)
  //      }
       
  //    });
  //   }
  //   else{
  //     this.StationList = this.OrignalStationList;
  //   }
  //   console.log(this.StationList);
  // }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this " + this.ProductSeriesVariantLabel + " ?",
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;
        for (var i = 0; i < this.VariantLevel1obj.Level1arr.length; i++) {
          var obj = { Id: null, Level1: this.VariantLevel1obj.Level1arr[i] }
          this.VariantLevel1obj.Level1.push(obj);
        }

        this.CommonService.PostData('ProductVariantLevel1', 'Add', this.VariantLevel1obj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success(this.ProductSeriesVariantLabel + ' added successfully!');
            this.router.navigate(['/VariantLevel1', this.VariantLevel1obj.ProductSeries, this.ProductSeriesVariantLabel]);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning(this.ProductSeriesVariantLabel + ' already exists!');
            this.VariantLevel1obj.Level1 = [];
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to ' + this.ProductSeriesVariantLabel + '!');
            this.router.navigate(['/VariantLevel1', this.VariantLevel1obj.ProductSeries, this.ProductSeriesVariantLabel]);
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add ' + this.ProductSeriesVariantLabel + '!');
          this.showLoader = false;
          this.router.navigate(['/VariantLevel1', this.VariantLevel1obj.ProductSeries, this.ProductSeriesVariantLabel]);
        });
      }
    });

  }

}

