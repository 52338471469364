<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Add LoginPage Image
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm"
                            (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                           
                            <div class="m-portlet__body">
                               <div> <span class="fileext" style="color: red">
                                    {{AppGlobals.supportedFileFormatForImage}}</span> <br/></div>
                                    <br>
                                <div class="form-group m-form__group row">
                                   
                                    <label class="col-lg-2 col-form-label">Upload Image:</label>
                                    <div class="col-lg-3">
                                        <div class="file">
                                            <i class="flaticon-file-2"></i>
                                            <input type="file" class="form-control-file" id="dynamicImage"
                                                name="dynamicImage" (change)="uploadDynamicImage($event)" />
                                            <br />
                                           
                                            <span class="fileext">
                                                {{LoginPageImageObj.ImageName}}
                                            </span> <br>
                                           
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Comment:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <textarea class="form-control m-input" id="comment" maxlength="1500"
                                            name="comment" rows="3" [(ngModel)]="LoginPageImageObj.Comment"
                                            (keyup)="limitTextOnKeyUpDown1($event)"
                                            (keypress)="limitTextOnKeyUpDown1($event)" #comment="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && comment.invalid || comment?.touched && comment.invalid}"
                                            required=""></textarea>
                                        <span> Limit {{maxLimit}} characters, {{remLimit}}
                                            characters remaining.</span>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && comment.invalid || comment?.touched && comment.invalid">
                                            <div *ngIf="myform.hasError('required', 'comment')">
                                                Comment is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a [routerLink]="['/LoginPageImage']"
                                                routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>