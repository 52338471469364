<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        {{FormStatus}} Product Model
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm"
                            (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                            <div class="m-portlet__body">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Product Series:</label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel1" name="productseries"
                                            [(ngModel)]="ProductModelobj.ProductSeries" #productseries="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productseries.invalid || productseries?.touched && productseries.invalid}"
                                            disabled required>
                                            <option value="null" disabled>Select Product Series</option>
                                            <option *ngFor="let item of ProductSeries" value="{{item.Id}}">
                                                {{item.SeriesName}} </option>
                                            <option disabled *ngIf="ProductSeries.length <= 0" value="No Data Found"> No
                                                Data Found</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && productseries.invalid || productseries?.touched && productseries.invalid">
                                            <div *ngIf="myform.hasError('required', 'productseries')">
                                                Product Series is required
                                            </div>
                                        </div>
                                    </div>

                                    <div class="offset-1"></div>
                                    <label class="col-lg-2  col-form-label">Product Model:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="ProductModelname"
                                            [(ngModel)]="ProductModelobj.ModelName" #ProductModelname="ngModel"
                                            maxlength="130"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && ProductModelname.invalid || ProductModelname?.touched && ProductModelname.invalid}"
                                            placeholder="Enter Product Model" (keypress)="AvoidSpace($event)"
                                            [disabled]="isEditable"
                                            (change)="ProductModelobj.ModelName=ProductModelobj.ModelName.trim()"
                                            required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && ProductModelname.invalid || ProductModelname?.touched && ProductModelname.invalid">
                                            <div *ngIf="myform.hasError('required', 'ProductModelname')">
                                                Product model is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Price (€):<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="price"
                                            [(ngModel)]="ProductModelobj.Price" #price="ngModel"
                                            style="text-align:right;"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && price.invalid || price?.touched && price.invalid}"
                                            placeholder="Enter Price" maxlength="7" pattern="[0-9]+"
                                            (keypress)="AvoidSpace($event);isNumberKey($event);" [disabled]="isEditable"
                                            (change)="ProductModelobj.Price=ProductModelobj.Price.trim()" required>

                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && price.invalid || price?.touched && price.invalid">
                                            <div *ngIf="myform.hasError('required', 'price')">
                                                Price is required</div>
                                            <div *ngIf="myform.hasError('pattern', 'price')">
                                                Price must be an integer</div>
                                        </div>
                                    </div>


                                    <!--  Article Number  -->
                                    <div class="offset-1"></div>
                                    <label class="col-lg-2  col-form-label">Article Number:<span
                                            style="color:#f4516c;">&nbsp;</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="Article Number"
                                            [(ngModel)]="ProductModelobj.ArticleNumber" #ArticleNumber="ngModel"
                                            maxlength="50" placeholder="Enter Article Number"
                                            (keypress)="AvoidSpace($event)" [disabled]="isEditable"
                                            (change)="ProductModelobj.ArticleNumber=ProductModelobj.ArticleNumber.trim()">
                                    </div>

                                </div>




                            </div>

                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit" *ngIf="isEditable"
                                                [hidden]="!isProductModelEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                                (click)="EditDetails()">Edit</button>&nbsp;&nbsp;
                                            <button id="m_login_signin_submit" *ngIf="!isEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a [routerLink]="['/ProductModel',ProductModelobj?.ProductSeries]"
                                                routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>