<footer class="m-grid__item m-footer foot">
    <div class="m-container m-container--responsive m-container--xxl m-container--full-height m-page__container">
        <div class="m-footer__wrapper">
            <div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
                <div class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
                    <span class="m-footer__copyright">
                        {{footerName}}
                    </span>
                </div>

                <div style="float: right;">
                    <button id="gototop" (click)="gotoTop()" title="Go To Top"><i
                            class="fa fa-arrow-up" style="font-size:20px;color:white;"></i></button>
                </div>
            </div>
        </div>
    </div>
</footer>