import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { EmployeeGuestModel, EmployeeRegion } from 'src/app/Model/EmployeeGuestModel';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { CommonService } from 'src/app/Service/common.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { OutofOfficeActivityModel } from 'src/app/Model/OutOfOfficeActivityModel';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-edit-view-employee',
  templateUrl: './edit-view-employee.component.html',
  styleUrls: ['./edit-view-employee.component.scss']
})
export class EditViewEmployeeComponent implements OnInit {
  EmployeeLeaveDetails: OutofOfficeActivityModel = new OutofOfficeActivityModel();
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];

  EmployeeDetails: EmployeeGuestModel = new EmployeeGuestModel();
  emailPattern: string = "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  // passPattern: any = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,30}$/;
  passPattern: any = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,30}';
  Country: any[] = [];
  Roles: any[] = [];
  Region: any[] = [];
  EmployeeData: any[] = [];
  AlternateEmployeeData: any[] = [];
  isEditable: boolean = true;
  FormStatus: string = "View";
  charCode: any;
  UserRole: any;
  isEmployeeEditable: boolean = false;
  isEmployeeLeaveDetailsVisible : boolean = true;
  mailIdFormat : any;

  @Input()
  showLoader: boolean = false;
  Passhide: boolean = true;
  ConfirmPasshide: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private Service: CommonService, 
    public dialog: MatDialog, private titleService: Title,private cd:ChangeDetectorRef, private accessservice: AccessPermissionService,
    private appConfigService : AppConfigService) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    this.mailIdFormat = appConfig.mailIdFormat;
    //this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
   //this.isEmployeeEditable = AppGlobals.EmployeeMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.EmployeeDetails.RegisterId = this.route.snapshot.params["id"];
    //this.getAllEmployeeData();
    this.getEmployeeDetailsById(this.EmployeeDetails.RegisterId);
    this.getAllCountry();
    this.getAllRoles();
    this.getAllRegion();
   
  }

  /*******************************************************************************
     * Get data for add employee
     * ******************************************************************************/
  getEmployeeLeaveDetailsById(Fk_User_Id: string) {
    this.showLoader = true;

    let queryString = "";
    queryString = "id=" + Fk_User_Id;
    this.Service.GetData('Employee', 'GetLeaveDateOfEmployeeById', queryString).subscribe((data: any) => {
      if (data == null){
        this.isEmployeeLeaveDetailsVisible = false;
        this.EmployeeDetails.AlternateEmployee = "";
      }else{
        this.EmployeeLeaveDetails = data;
        this.EmployeeDetails.AlternateEmployee = this.EmployeeLeaveDetails.AlternateUserId;
        this.EmployeeDetails.StartDate = this.EmployeeLeaveDetails.StartDate;
        this.EmployeeDetails.EndDate = this.EmployeeLeaveDetails.EndDate;
       
      }
      
     // this.getAllEmployeeData(Fk_User_Id);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });

  }


  getEmployeeDetailsById(RegisterId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "id=" + RegisterId;
    this.Service.GetData('Employee', 'GetEmployeeById', queryString).subscribe((data: any) => {
      this.EmployeeDetails = data;
      let region =[];
      region.push(this.EmployeeDetails.Region);

      this.EmployeeDetails.Region=[];
      region[0].forEach(element => {
        this.EmployeeDetails.Region.push(element.Id.toString());
      });       
      // this.cd.detectChanges();
      //this.EmployeeDetails = data;
      this.getEmployeeLeaveDetailsById(this.EmployeeDetails.Fk_User_Id);
      this.getAllEmployeeData(this.EmployeeDetails.Fk_User_Id);
      
      this.getAlternateEmployeeData(this.EmployeeDetails.Role, RegisterId,this.EmployeeDetails.Fk_User_Id);

      this.showLoader = false;
      
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });

  }

  getAllCountry() {
    this.showLoader = true;
    this.Service.GetData("Country", "GetAll", null).subscribe((data: any) => {
      this.Country = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getAllRoles() {
    this.showLoader = true;
    this.Service.GetData('Account', 'GetAllEmployeeRoles', null).subscribe((data: any) => {
      this.Roles = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  getAllRegion() {
    this.showLoader = true;
    this.Service.GetData("Region", "GetAll", null).subscribe((data: any) => {
      this.Region = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getAllEmployeeData(Fk_User_Id: string) {
    this.showLoader = true;
    this.Service.GetData("Employee", "GetAllEmployeeData", null).subscribe((data: any) => {
      this.EmployeeData = data;
      this.EmployeeData = this.EmployeeData.filter(x => x.Fk_User_Id !== Fk_User_Id);
      this.ChangeEmployee(this.EmployeeDetails.ReportingManager);
      this.showLoader = false;     
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }


  getAlternateEmployeeData(role, RegisterId, Fk_User_Id)
  {
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + RegisterId +"&role=" + role +"&userid=" + Fk_User_Id  ;
    this.Service.GetData("Employee", "GetAlternateEmployeeData", queryString).subscribe((data: any) => {
      this.AlternateEmployeeData = data;
      
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  
  /*******************************************************************************
       * End get data for add employee
  * ******************************************************************************/
  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    const appConfig = this.appConfigService.getConfig();
    this.titleService.setTitle(`Edit Employee - ${appConfig.titleName}`);
    //this.titleService.setTitle("Edit Employee - ECOTECH");
  }

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  OnChangeAlternateEmployee(value: any){
    var alternateemployee = this.AlternateEmployeeData?.filter(x => x.Fk_User_Id == value);
    this.EmployeeDetails.AlternateEmployee = alternateemployee[0].Fk_User_Id;
  }
  
   ChangeEmployee(value: any) {
    var employee = this.EmployeeData?.filter(x => x.Fk_User_Id == value);
    if (employee.length>0) {
    	this.EmployeeDetails.ReportingManagerEmail = employee[0].Email;
    }
    this.cd.detectChanges();
  }

  onSubmit(myform: any) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update this employee?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.EmployeeDetails.RoleType = 2;
        this.showLoader = true;
        this.Service.PostData('Account', 'UpdateRegister', this.EmployeeDetails, null).subscribe((data: any) => {
          let result = data;
          this.showLoader = false;
          if (result.ResponseCode == 1) {
            this.toastr.success('Employee details updated successfully!');
            this.router.navigate(['/Employee']);
          }
          if (result.ResponseCode == 0) {
            this.toastr.warning('Username already exists!');
            myform.controls['username'].setErrors({ AlreadyPresent: true });
          }
          if (result.ResponseCode == 2) {
            this.toastr.warning('Employee code already exists!');
            myform.controls['empcode'].setErrors({ AlreadyPresent: true });
          }
          if (result.ResponseCode == 3) {
            this.toastr.warning('Contact number already exists!');
            myform.controls['contactnumber'].setErrors({ AlreadyPresent: true });
          }
          if (result.ResponseCode == 4) {
            this.toastr.warning('Employee code and contact number already exists!');
            myform.controls['empcode'].setErrors({ AlreadyPresent: true });
            myform.controls['contactnumber'].setErrors({ AlreadyPresent: true });
          }
          if(result.ResponseCode == 5)
          {
            this.toastr.success('Failed to update employee password!');
            this.router.navigate(['/Employee']);
          }
        }, error => {
          this.toastr.error('Failed to update employee details!');
          this.showLoader = false;
          this.router.navigate(['/Employee']);
        });
      }
    });

  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isEmployeeEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isEmployee')[0]?.IsEditable;
  
    });
 
  }

}
