<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        View Stock Inventory
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <form class="m-form m-form--fit m-form--label-align-right" #myform="ngForm" novalidate>
                            <div class="m-portlet__body">
                                <div style="overflow-x:auto;padding: 20px;" class="table-responsive">
                                    <table class="table table-bordered table-edit">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Stock File Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let Attach of StockInventoryObj; let i = index;">
                                                <tr>
                                                    <td align="right">{{i+1}}</td>
                                                    <td>
                                                        {{Attach.StockFileName}}
                                                    </td>
                                                    <td>
                                                        <a href="#"
                                                            *ngIf="Attach.StockFileName != '' && Attach.StockFileName != null"
                                                            (click)="DownloadFile(Attach.StockFilePath)">Preview</a>
                                                    </td>
                                                </tr>
                                            </ng-container>


                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">

                                            <button type="button" routerLink="/StockInventory"
                                                routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>