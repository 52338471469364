import { Component, OnInit, ViewChild, Input, ɵclearResolutionOfComponentResourcesQueue, ElementRef, EventEmitter, Output } from '@angular/core';
import { EmployeeGuestModel } from 'src/app/Model/EmployeeGuestModel';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import * as FileSaver from 'file-saver';
import { BasicCustomerInformationModel } from 'src/app/Model/CustomerModel';
import { EnquiryModel, CustomerDrpdnDetails, CustomerDetails, ProductDrpdnDetails, EnquiryDetailsModel, ProductDetails, Comments, removefiles, TermsAndCondition, ActivityModel, ProductModelPOValues, CustomTermsAndCondition } from 'src/app/Model/EnquiryModel';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { NgbDate, NgbModal, NgbActiveModal, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppGlobals } from 'src/app/app.global';

import { UserRoles } from "src/app/UserRoles";
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { MatTableDataSource } from '@angular/material/table';
import { EnquiryModalComponent } from '../enquiry-modal/enquiry-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { QuotationDetailsModel, QuotationCustomerDetails } from 'src/app/Model/QuotationModel';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import {
  saveAs as importedSaveAs
} from "file-saver";
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { TechnicalDiscardComponent } from '../technical-discard/technical-discard.component';
import { CloseOppReasonComponent } from '../close-opp-reason/close-opp-reason.component';
import { EnquiryStatus, EnquiryStatusCode } from "src/app/EnquiryStatus";
import { EnquiryStatusList } from 'src/app/Model/EnquiryStatus';
import { EnquirySendBack } from 'src/app/Model/EnquirySendBack';
import { SendBackCommentComponent } from '../send-back-comment/send-back-comment.component';
import { AppConfigService } from 'src/app/Service/app-config.service';
declare var require: any

@Component({
  selector: 'app-oa-generation',
  templateUrl: './oa-generation.component.html',
  styleUrls: ['./oa-generation.component.scss']
})
export class OaGenerationComponent implements OnInit {

  @ViewChild('myform3') myform3: NgForm;
  @ViewChild('myform5') myform5: NgForm;
  @ViewChild('myInput') myInputVariable: ElementRef;
  @ViewChild('editpovalue', { static: false }) editpovalue: ElementRef;
  standardTC:any[] = [];
  customTCValues:any[] =[];

  userInfo: EmployeeGuestModel = new EmployeeGuestModel();
  CustomerList: BasicCustomerInformationModel[] = [];
  EnquiryObj: EnquiryDetailsModel = new EnquiryDetailsModel();
  newRFQDetails: any = {};
  productListdropdownData = [];
  customerDrpdnDetails: CustomerDrpdnDetails = new CustomerDrpdnDetails();
  productDrpdnDetails: ProductDrpdnDetails = new ProductDrpdnDetails();
  customerData: CustomerDetails;
  activityDetails: ActivityModel[] = Array<ActivityModel>();
  CustomerDetailsObj: QuotationCustomerDetails = new QuotationCustomerDetails();
  QuotationData: QuotationDetailsModel = new QuotationDetailsModel();
  allowedFileType: string[] = [
    'image/bmp',
    'image/jpeg',
    'image/png',
    'application/pdf',
    'text/plain',
    'text/csv',
    'application/xml',
    'text/xml',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/zip',
    'application/x-zip-compressed',
  ];

  showEditRFQForInitiator: boolean;
  UserRole: any;
  isTechnicalDiscardVisible: boolean =false;
  isEnquiryFormVisible: boolean = false;
  isRFQVisible: boolean = false;
  isQuoteGenerationVisible: boolean = false;
  isManagementApprovalVisible: boolean = false;
  isSOVisible: boolean = false;
  isOrderAcknowledgementVisible: boolean = false;
  EnquiryConfirmationVisible: boolean = false;
  isSupplychainVisible: boolean = false;
  isAccountsVisible: boolean = false;
  iseditable:boolean=false;
  isOAGenerationVisible: boolean = false;
  isBOMAttachment: boolean;
  isInvoiceAttachment: boolean;
  isPOAttachment: boolean;
  IsRFQDataValid: boolean = true;
  selectedIndexForStatus: any = 1;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  DiscountFromrange:any;
  Discounttorange:any;

  @Input()
  showLoader: boolean = false;
  charCode: number;
  enquiryStatus = EnquiryStatus;
  AppRoles = UserRoles;
  enquirySource: SingleFieldModel[] = [];
  TnCStandardFromDropDown: any[] = [];
  TnCCustomizedFromDropDown: any[] = [];
  TnCCustomizedDropDownForEditRfq1: any[] = [];
  TnCCustomizedDropDownForEditRfq2: any[] = [];
  newCustomTc: any;
  product: any = {};
  isEnquirySubmit: boolean = false;
  charCode1: any;
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  dataSource: MatTableDataSource<Comments>;
  enquiryStatusCode = EnquiryStatusCode;

  @ViewChild('stepper') stepper: MatStepper;
  AppGlobals = AppGlobals;
  fileDownloadPath = this.AppGlobals.baseFileDownloadUrl;
  QuotationDownloadPath = this.AppGlobals.QuotationFileDownloadUrl;
  isEnquiryLoaded: boolean;
  role: string;
  isStandardCondition: boolean;
  closeResult: string;
  isCEOApprovalVisible: boolean;
  demoObject: any;
  demoCTCObject: any;
  WinLossDropdownData: any[] = [];
  WinLossDropdownDataForStatus: any;
  isPoValueDisable: boolean;
  isCustomerCommentVisible: any;
  isOAGenerated: boolean;
  isAcceptClick: boolean;
  dataPassed: any;
  dropdownData: any;
  showQuotationLink: boolean;
  userid: string;
  isprocessing: boolean = true;
  rfqattpresent: boolean;
  QuotationDataForPO: any[] = [];
  chosenItem: string = "1";
  quoterevcomments: any;
  QGRFQEditable: boolean = false;
  IsActivityDataValid: boolean;
  today: Date;
  fc = new FormControl();
  minDate: NgbDate;
  showEditRFQ: boolean;
  isTantativeDateEditable: boolean;
  tempRFQ: ProductDetails[];
  IsPODataValid: boolean;
  isEditactivity: boolean = true;
  PreviousStatus: number = 0;
  tempfile: any;
  ngbDateParserFormatter: any;
  UpdatePO: boolean = false;
  isPOAttacmentDelete: boolean = false;
  RevisedQuotationFileNameList: string[] = [];
  emailPattern: string = "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  StartDate: NgbDate;
  EndDate: NgbDate;
  RoleWiseScreenDisplay: any;
  previousUrl: string = null;
  IndustrySegment: any[] = [];
  SubSegment: any[] = [];
  Application: any[] = [];

  FinalIndustrySegment: any[] = [];
  FinalSubSegment: any[] = [];
  FinalApplication: any[] = [];
  OADownloadPath = this.AppGlobals.OADownloadPath;
  ShowOALink: boolean = false;
  workflowActionList:any[] = [];
  enquiryStatusList: EnquiryStatusList[]=[];
  workflowId:number;
  roleOfAdmin : any;
  @Output() updateLoaderEvent = new EventEmitter<boolean>();
  UserRolesData:any[]=[];
  WinLossDataForStatus: any;

  constructor(private toastr: ToastrService, private commonService: CommonService, public dialog: MatDialog,
    private _location: Location,
    private config: NgbDatepickerConfig,
    private modalService: NgbModal, private activeModel: NgbActiveModal,
    private router: Router, private route: ActivatedRoute, private titleService: Title, private appConfigService: AppConfigService) {
    this.today = new Date();
    this.minDate = new NgbDate(this.today.getFullYear(), this.today.getMonth() + 1, this.today.getDate());
    this.StartDate = new NgbDate(2018, 1, 1);
    this.EndDate = new NgbDate(this.today.getFullYear() + 1, 12, 31);


  }
  ngAfterViewInit() {
    this.updateLoaderEvent.emit(true);
    setTimeout(() => {
      this.isprocessing = false;
      this.chosenItem = "1";
      this.updateLoaderEvent.emit(false);
    }, 2000);
  }
  ngOnInit() {
    this.commonService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });

    let tempRoleWiseScreenDisplay = localStorage.getItem("WorkFlowRoleWiseScreenAccess");
    this.RoleWiseScreenDisplay = tempRoleWiseScreenDisplay.split(",");
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    //this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.commonService.RefreshHeaderFun();
    this.EnquiryObj.EnquiryDetails.EnquiryId = this.route.snapshot.params["id"];
    this.EnquiryObj.EnquiryDetails.EstimatedProductValue = null;
    this.EnquiryObj.EnquiryDetails.InvoiceAttachment = undefined;
    this.getEnquirySourceDetails();
    this.getCustomerList();
    this.getEnquiryDetailsById();
    this.getEnquiryStatus();
    this.getAllWorkFlow();
    this.getUserRoles();
    this.isEnquiryLoaded = false;
    this.getSendbackWorkfow();
    this.UserRole = localStorage.getItem("UserRole");
    this.isTechnicalDiscardVisible = AppGlobals.QuoteGenearation_Enquiry_TechnicalDiscardArray.filter(x => x.role
      == this.UserRole)[0]?.isEditable;
    this.roleOfAdmin = UserRoles.Admin;
  }
  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }
 


  getEnquiryDetailsById() {
    this.updateLoaderEvent.emit(true);
    let queryString = "";
    queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
    this.commonService.GetData('Enquiry', 'GetById', queryString).subscribe((data: any) => {
      this.EnquiryObj.EnquiryDetails = data.EnquiryDetails;
      if (this.EnquiryObj.EnquiryDetails.QuotationRevisionNo != null && this.EnquiryObj.EnquiryDetails.QuotationRevisionNo > 0) {
        this.getRevisedQuotationFileNamelist(this.EnquiryObj.EnquiryDetails.QuotationNumber, this.EnquiryObj.EnquiryDetails.QuotationRevisionNo);
      }

      this.EnquiryObj.EnquiryDetails.EnquiryDate = new NgbDate(new Date(this.EnquiryObj.EnquiryDetails.EnquiryDate).getFullYear(), new Date(this.EnquiryObj.EnquiryDetails.EnquiryDate).getMonth() + 1, new Date(this.EnquiryObj.EnquiryDetails.EnquiryDate).getDate());
      if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != null) {
        this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate = new NgbDate(new Date(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate).getFullYear(), new Date(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate).getMonth() + 1, new Date(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate).getDate());
      }
      if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != null) {
        this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate = new NgbDate(new Date(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate).getFullYear(), new Date(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate).getMonth() + 1, new Date(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate).getDate());
      }
      this.EnquiryObj.EnqCustomerDetails = data.CustomerDetails;
      this.EnquiryObj.RFQObject = data.RFQObject;
      this.EnquiryObj.CustomerServiceComments_Quotation = data.CustomerServiceComments_Quotation;
      this.EnquiryObj.CustomerServiceComments_Order = data.CustomerServiceComments_Order;
      this.EnquiryObj.SalesDirectorComments_Quotation = data.SalesDirectorComments_Quotation;

      this.EnquiryObj.SalesDirectorComments_Order = data.SalesDirectorComments_Order;
      this.EnquiryObj.Comments = data.Comments == undefined ? [] : data.Comments;
      this.EnquiryObj.POValues = data.POValues;

      this.WinLossDropdownData = data.ReasonEnquiryWinLossObj;
      this.WinLossDropdownDataForStatus = this.WinLossDropdownData.filter(x => x.Value == this.EnquiryObj.EnquiryDetails.EnquiryConfirmStatus);
      
      if (data.EnquiryDetails.RFQTermsAndCondition == "Standard" || data.EnquiryDetails.RFQTermsAndCondition == "") {

        if (data.TermsAndConditions.length == 0) {
          this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition = "Standard";
          this.EnquiryObj.TermsAndConditions = this.TnCStandardFromDropDown;
        } else {
          this.EnquiryObj.TermsAndConditions = data.TermsAndConditions;

        }
        this.isStandardCondition = true;
      } else if (data.EnquiryDetails.RFQTermsAndCondition == "Customized") {

        if (data.CustomTermsAndConditions.length == 0) {
          this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition = "Customized";
          this.EnquiryObj.CustomTermsAndConditions = this.TnCCustomizedFromDropDown;
          this.TnCCustomizedDropDownForEditRfq1 = this.TnCCustomizedFromDropDown;
        } else {
          this.EnquiryObj.CustomTermsAndConditions = data.CustomTermsAndConditions;
          this.TnCCustomizedDropDownForEditRfq2 = data.CustomTermsAndConditions;
        }

        this.isStandardCondition = false;
      }

      this.activityDetails = data.ActivityDetails;
      this.activityDetails.forEach(element => {
        element.ActivityDate = new NgbDate(new Date(element.ActivityDate).getFullYear(), new Date(element.ActivityDate).getMonth() + 1, new Date(element.ActivityDate).getDate());

        //element.ActivityDateISO = new NgbDate(new Date(element.ActivityDate).getFullYear(), new Date(element.ActivityDate).getMonth() + 1, new Date(element.ActivityDate).getDate());
      });

      //TO POPULATE A SINGLE ROW ON PAGE INIT
      if (this.EnquiryObj.RFQObject.length == 0) {
        var obj = new ProductDetails();
        this.EnquiryObj.RFQObject.push(obj);
      }

      //TO POPULATE A SINGLE ROW ON PAGE INIT
      if (this.activityDetails.length == 0) {
        var obj1 = new ActivityModel();
        obj1.ActivityDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
        obj1.uniqueid = this.makeRandom();
        this.activityDetails.push(obj1);
      }

      this.role = localStorage.getItem('UserRole');
     // this.ScreenAccessSpecifier(localStorage.getItem('UserRole'), this.EnquiryObj.EnquiryDetails.Status);
      if(this.EnquiryObj.EnquiryDetails.segment!=undefined)
      {
       this.SubSegment = this.FinalSubSegment.filter(x=>x.SegmentId==this.EnquiryObj.EnquiryDetails.segment);
       this.Application= this.FinalApplication.filter(x=>x.SubSegmentId==this.EnquiryObj.EnquiryDetails.subSegment);
      }
     if(this.EnquiryObj.EnquiryDetails.segment==null|| this.EnquiryObj.EnquiryDetails.subSegment==null || this.EnquiryObj.EnquiryDetails.application==null)
      {
        this.EnquiryObj.EnquiryDetails.segment=undefined;
        this.EnquiryObj.EnquiryDetails.subSegment=undefined;
        this.EnquiryObj.EnquiryDetails.application=undefined;
      }

      // if (this.EnquiryObj.EnquiryDetails.OA_filename != "") {
      //   this.ShowOALink = true;
      // }

      // if (!(this.EnquiryObj.EnquiryDetails.OA_filename == null ||
      //   this.EnquiryObj.EnquiryDetails.OA_filename == undefined ||
      //   this.EnquiryObj.EnquiryDetails.OA_filename == "")) {
      //   this.ShowOALink = true;
      // } else {
      //   this.ShowOALink = false;
      // }

      //this.EnquiryObj.EnquiryDetails.IsFetchFromKYC=data.EnquiryDetails.IsFetchFromKYC;
      
    }, error => {
      this.updateLoaderEvent.emit(false);
    });

  }

  getRevisedQuotationFileNamelist(QuotationNumber: string, QuotationRevision: number) {
    let QTNNumber = QuotationNumber.replace("QTN/", "");
    QTNNumber = QTNNumber.split("/").join("_");
    let filename = "Quotation_" + QTNNumber;
    let pdffilename = filename.split('_R')[0];
    for (let i = 0; i < QuotationRevision; i++) {
      if (i == 0) {
        this.RevisedQuotationFileNameList.push(pdffilename + ".pdf")
      }
      else {
        this.RevisedQuotationFileNameList.push(pdffilename + "_R" + i + ".pdf")
      }
    }

  }
  //   replaceAll(str, term, replacement) {
  //     return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
  // }
  makeRandom() {
    let text = "";
    var possible: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~";
    const lengthOfCode = 40;
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }


  custtermscond: any = [];
 
 

  deleteAttachDocument(index, attachemntType) {
    switch (attachemntType) {
      case "MDS":
        this.EnquiryObj.RFQObject[index].MDSAttachment = [];
        this.EnquiryObj.RFQObject[index].isMDSAttachment = false;
        break;
      case "ITC":
        this.EnquiryObj.RFQObject[index].ITCAttachment = [];
        this.EnquiryObj.RFQObject[index].isITCAttachment = false;
        break;
      case "Customer":
        this.EnquiryObj.RFQObject[index].CustomerAttachment = [];
        this.EnquiryObj.RFQObject[index].isCustomerAttachment = false;
        break;
      case "Weiss":
        this.EnquiryObj.RFQObject[index].WeissAttachment = [];
        this.EnquiryObj.RFQObject[index].isWeissAttachment = false;
        break;
    }

  }


  getCustomerList() {
    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("Customer", "GetAll", null).subscribe((data: any) => {
      this.CustomerList = data;

       this.updateLoaderEvent.emit(false);
    });
  }

  getEnquirySourceDetails() {
    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("Enquiry", "GetEnquiryDropdownData", null).subscribe((data: any) => {
      this.enquirySource = data.EnquirySource;
      this.updateLoaderEvent.emit(false);
    });
  }

  getProductDetails() {
    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("Product", "GetRFQProductDetails", null).subscribe((data: any) => {
      this.CustomerList = data;
      //this.updateLoaderEvent.emit(false);
    });
  }


  AvoidSpace(event: any) {
    if ((event.which === 46 || event.which === 32) && !event.target.value.length) {
      event.preventDefault();
    }
  }

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  isintNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 46 || this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }
 

  backtoList() {
    // this._location.back();
    if (this.previousUrl == null || this.previousUrl == "" || this.previousUrl == undefined) {
      this.router.navigate(['/Enquiry']);
    }
    else if (this.previousUrl.includes("PendingEnquiry")) {
      this.router.navigate(['/PendingEnquiry']);
    }
    else {
      this.router.navigate(['/Enquiry']);
    }

  }


  getMultipleFileDetails(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];
      let isValidFile = true;
      if (((file.size) / 1000000) <= 5) {

        isValidFile = this.allowedFileType.includes(file.type);
        if (!isValidFile) {
          this.toastr.warning("Invalid file attached!");
          //event.target.value = null;
          isValidFile = false;
        } else if (file.name.length > 50) {
          this.toastr.warning("The file name with more than 50 characters are not allowed.");
          isValidFile = false;
        }
      }
      else {
        this.toastr.warning("File size must be less than or equal to 5MB!");
        //event.target.value = null;
        isValidFile = false;
      }
      if (isValidFile) {
        switch (event.target.name) {
          case "quotefiles":
            if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments == undefined || this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length == 0) {
              this.EnquiryObj.EnquiryDetails.QuoteGenAttachments = [];
            }
            this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.push(file);
            break;
          case "mafile":
            if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments == undefined || this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length == 0) {
              this.EnquiryObj.EnquiryDetails.QuoteGenAttachments = [];
            }
            this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.push(file);
            break;
          case "ceofile":
            if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments == undefined || this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length == 0) {
              this.EnquiryObj.EnquiryDetails.QuoteGenAttachments = [];
            }
            this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.push(file);
            break;
          case "maofile":
            if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments == undefined || this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length == 0) {
              this.EnquiryObj.EnquiryDetails.QuoteGenAttachments = [];
            }
            this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.push(file);
            break;
        }
      }
    }
  }

  removeQuoteGenAttachments(index) {
    this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.splice(index, 1);
    this.myInputVariable.nativeElement.value = "";

  }
  removeQuoteGenAttachmentsDB(index) {
    this.EnquiryObj.RemoveFiles.push(this.EnquiryObj.EnquiryDetails.QuoteAttachmentsArray[index].FilePath);
    this.EnquiryObj.EnquiryDetails.QuoteAttachmentsArray[index].FileName = null;
    this.EnquiryObj.EnquiryDetails.QuoteAttachmentsArray[index].FilePath = null;
    this.EnquiryObj.EnquiryDetails.QuoteAttachmentsArray.splice(index, 1);
  }
  removeBOMAttachments(flag) {
    if (flag == 1) {
      this.EnquiryObj.RemoveFiles.push(this.EnquiryObj.EnquiryDetails.BOM_filepath);
    }
    this.isBOMAttachment = false;
    this.EnquiryObj.EnquiryDetails.BOMAttachment = [];
    this.EnquiryObj.EnquiryDetails.BOM_filename = null;
    this.EnquiryObj.EnquiryDetails.BOM_filepath = null;
  }

  removeInvoiceAttachments() {
    this.isInvoiceAttachment = false;
    this.myInputVariable.nativeElement.value = "";
    this.EnquiryObj.EnquiryDetails.InvoiceAttachment = undefined;
  }



  GenerateOANumber() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Once OA Number Generated, can't be altered. Do you want to continue?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.updateLoaderEvent.emit(true);
        let queryString = "";
        queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
        this.commonService.GetData('Enquiry', 'GenerateOANo', queryString).subscribe((data: any) => {

          this.EnquiryObj.EnquiryDetails.OrdAcknowledgeNumber = data;
          this.isOAGenerated = true;
          this.updateLoaderEvent.emit(false);
          this.toastr.success('Order Acknowledgement Number generated successfully!');

        }, error => {
          this.EnquiryObj.EnquiryDetails.OrdAcknowledgeNumber = undefined;
          this.toastr.error('Failed to Order Acknowledgement Number!');
          this.isOAGenerated = false;
          this.updateLoaderEvent.emit(false);
        });
      }
    });
  }

  onStepChange(data) {

    if (this.userid == this.EnquiryObj.EnquiryDetails.Initiator_Id && data.previouslySelectedIndex == 0 && this.activityDetails[0].Activity != "") {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Please make sure that you have saved the activity details. Do you want to continue?"
        }
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
        } else {
          this.stepper.selectedIndex = data.previouslySelectedIndex;
        }
      });
    }
  }

  radioChange(event) {

  }

  SetStatus(rule, myform: any) {
    this.PreviousStatus = this.EnquiryObj.EnquiryDetails.Status;
    this.EnquiryObj.EnquiryDetails.Status = rule[0].workflowNextStatusId;
    this.AddEnquiry();

  }

  AddEnquiry() {
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='EnquiryAdded')[0]?.Pk_Id
      || this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQSaveAsDraft')[0]?.Pk_Id
      || this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQSubmittedbySalesEngg')[0]?.Pk_Id) {
    
      this.checkRFQGridValidation();
      if (!this.IsRFQDataValid) {
        return;
      }
      if (this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition == 'Standard')
      {
        let tnc = this.EnquiryObj.TermsAndConditions.filter(x => (x.TnCValue == undefined || x.TnCValue == null || x.TnCValue == ''));
        if (tnc.length > 0) {
          return;
        }
        this.EnquiryObj.CustomTermsAndConditions=null;
      }
      
      if (this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition == 'Customized')
      {
        let ctnc = this.EnquiryObj.CustomTermsAndConditions.filter(x => (x.CustomValues == undefined || x.CustomValues == null))
        if (ctnc.length > 0) {
          return;
        }   
        this.EnquiryObj.TermsAndConditions=null;
      }      
    
      
    }
    //Dont proceed if the BOM attachment is not present
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='SendBOMandProposal')[0]?.Pk_Id || this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='QuotationSenttoCustomerService')[0]?.Pk_Id ||
      this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQsentbyL1toL2')[0]?.Pk_Id) {
      if ((this.EnquiryObj.EnquiryDetails.BOMAttachment == null || this.EnquiryObj.EnquiryDetails.BOMAttachment == undefined || this.EnquiryObj.EnquiryDetails.BOMAttachment.length == 0) &&
        (this.EnquiryObj.EnquiryDetails.BOM_filename == null || this.EnquiryObj.EnquiryDetails.BOM_filename == undefined || this.EnquiryObj.EnquiryDetails.BOM_filename == "")) {
        this.toastr.info('BOM attachment is required.');
        return;
      }
    }

    // //Dont proceed if the Po attachment is not present
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='EnquiryOrderConfirmedbySalesEngg')[0]?.Pk_Id) {
      if ((this.EnquiryObj.EnquiryDetails.POAttchments == null || this.EnquiryObj.EnquiryDetails.POAttchments == undefined || this.EnquiryObj.EnquiryDetails.POAttchments.length == 0) &&
        (this.EnquiryObj.EnquiryDetails.PO_filename == null || this.EnquiryObj.EnquiryDetails.PO_filename == undefined || this.EnquiryObj.EnquiryDetails.PO_filename == "")) {
          this.toastr.info('PO attachment is required.');
        return;
      }
    }

    // //Dont proceed if the Invoice attachment is not present
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='AccountsConfirmInvoiceNo')[0]?.Pk_Id) {
      if ((this.EnquiryObj.EnquiryDetails.InvoiceAttachment == null || this.EnquiryObj.EnquiryDetails.InvoiceAttachment == undefined || this.EnquiryObj.EnquiryDetails.InvoiceAttachment.length == 0) &&
        (this.EnquiryObj.EnquiryDetails.Invoice_filename == null || this.EnquiryObj.EnquiryDetails.Invoice_filename == undefined || this.EnquiryObj.EnquiryDetails.Invoice_filename == "")) {
        this.EnquiryObj.EnquiryDetails.Status = this.enquiryStatusList.filter(x=>x.Status=='MaterialReadyForDispatch')[0]?.Pk_Id;
        this.toastr.info('Invoice attachment is required.');
        return;
      }
    }


    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='EnquiryOrderConfirmedbySalesEngg')[0]?.Pk_Id) {
      if ((this.EnquiryObj.EnquiryDetails.POAttchments == undefined || this.EnquiryObj.EnquiryDetails.POAttchments == null || this.EnquiryObj.EnquiryDetails.POAttchments.length==0)) {
        return;
      }
    }


    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrdersentforMngmtApproval_SalesDirector')[0]?.Pk_Id) {
      if (this.isPOAttacmentDelete) {
        if ((this.EnquiryObj.EnquiryDetails.POAttchments == undefined || this.EnquiryObj.EnquiryDetails.POAttchments == null || this.EnquiryObj.EnquiryDetails.POAttchments.length == 0)) {
          return;
        }
      }

    }

    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrderConfirmedbyCustomerServiceSendSO')[0]?.Pk_Id) {
      if (this.isPOAttacmentDelete) {
        if ((this.EnquiryObj.EnquiryDetails.POAttchments == undefined || this.EnquiryObj.EnquiryDetails.POAttchments == null || this.EnquiryObj.EnquiryDetails.POAttchments.length == 0)) {
          return;
        }
      }
    }
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='AccountsConfirmInvoiceNo')[0]?.Pk_Id) {
      if (this.EnquiryObj.EnquiryDetails.InvoiceAttachment == undefined) {
        this.EnquiryObj.EnquiryDetails.Status = this.enquiryStatusList.filter(x=>x.Status=='MaterialReadyForDispatch')[0]?.Pk_Id
        return;
      }
    }
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrdersentforMngmtApproval_SalesDirector')[0]?.Pk_Id ||
      this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrderConfirmedbyCustomerServiceSendSO')[0]?.Pk_Id) {
      let sum: number = 0;
      this.EnquiryObj.POValues.forEach(element => {
        element.ProductModelPOValue = (element.ProductModelPOValue == null || element.ProductModelPOValue == '' || element.ProductModelPOValue == undefined)
          ? '0' : element.ProductModelPOValue;
        sum = sum + parseInt(element.ProductModelPOValue);
        element.QUotationValueValidation = false;
      });
      if (sum.toString() != this.EnquiryObj.EnquiryDetails.POValue) {
        document.getElementById("povalue0").focus();
        this.toastr.info('Total PO value and product wise PO values are not matching.');
        this.EnquiryObj.EnquiryDetails.Status = this.PreviousStatus;
        this.UpdatePO = false;
        return;
      }
    }

    setTimeout(() => this.openPopup());
  }
  openPopup() {
    var confimrmsg = this.AppGlobals.successMessages.filter(x => x.id == this.EnquiryObj.EnquiryDetails.Status)[0];
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: confimrmsg.confirmmsg
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.updateLoaderEvent.emit(true);
        const frmData = new FormData();
        this.EnquiryObj.RFQObject.forEach(item => {
          item.IsRowDeleted = false;
        });
        if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.EnquiryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.TentativeDeliveryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.year, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.month, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.RequestedDeliveryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.year, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.month, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.EnquiryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day)));
        }
        if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.year, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.month, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.day)));
        }
        if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.year, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.month, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.day)));
        }

        if (this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition == "Customized") {
          this.EnquiryObj.TermsAndConditions = null;
  
          if(this.QGRFQEditable == true)
          {
            this.EnquiryObj.CustomTermsAndConditions;
            
          }else{
          this.EnquiryObj.CustomTermsAndConditions = this.custtermscond;
          }
        }

        let EnquiryObj = JSON.stringify(this.EnquiryObj);
        frmData.append("EnquiryModel", EnquiryObj);


        if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments != null) {
          for (let i = 0; i < this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length; i++) {
            frmData.append("QuoteGenAttachments[" + i + "]", this.EnquiryObj.EnquiryDetails.QuoteGenAttachments[i]);
          }
        }
        for (let i = 0; i < this.EnquiryObj.RFQObject.length; i++) {
          if (this.EnquiryObj.RFQObject[i].MDSAttachment != undefined) {
            frmData.append("MDSAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].MDSAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].ITCAttachment != undefined) {
            frmData.append("ITCAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].ITCAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].CustomerAttachment != undefined) {
            frmData.append("CustomerAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].CustomerAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].WeissAttachment != undefined) {
            frmData.append("WeissAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].WeissAttachment[0]);
          }
        }
        if (this.EnquiryObj.EnquiryDetails.BOMAttachment) {
          frmData.append("BOMAttachment", this.EnquiryObj.EnquiryDetails.BOMAttachment[0]);
        }
        if (this.EnquiryObj.EnquiryDetails.InvoiceAttachment) {
          frmData.append("InvoiceAttachment", this.EnquiryObj.EnquiryDetails.InvoiceAttachment[0]);
        }
        if (this.EnquiryObj.EnquiryDetails.POAttchments) {
          frmData.append("POAttchments", this.EnquiryObj.EnquiryDetails.POAttchments[0]);
        }
        
        this.commonService.PostData("Enquiry", "Add", frmData, null).subscribe((data: any) => {
          this.customerDrpdnDetails = data;
          //this.EnquiryObj.EnquiryDetails.EnquiryId = data;
	 
          if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OANoGenerated')[0]?.Pk_Id) {
            this.toastr.success("OA Number generated successfully");
          }
          else {
            this.toastr.success(confimrmsg.successmsg);
          }

          this.updateLoaderEvent.emit(false);
          if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQAcceptedbyL1')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='QuotationRevision')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQSubmittedbyL1')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQSubmittedbyL2')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQSubmittedbyL3')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQSubmittedbyCustomerService')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OANoGenerated')[0]?.Pk_Id) {
            // this.router.navigate(['/EditViewEnquiry/' + this.EnquiryObj.EnquiryDetails.EnquiryId]);
            window.location.reload();
          } else {
            this.router.navigate(['/Enquiry']);
            //this.backtoList();
	      }
          
        }, error => {
          this.toastr.error(confimrmsg.errormsg);
          this.updateLoaderEvent.emit(false);
        });
      } else {
        if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='QuotationRevision')[0]?.Pk_Id) {
          this.chosenItem = "2";
          this.quoterevcomments = this.EnquiryObj.EnquiryDetails.Comments;
          this.EnquiryObj.EnquiryDetails.Comments = "";
        }
        else {
          if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrdersentforMngmtApproval_SalesDirector')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrderConfirmedbyCustomerServiceSendSO')[0]?.Pk_Id) {
            this.UpdatePO = false;
          }
          if (this.PreviousStatus > 0) {
            this.EnquiryObj.EnquiryDetails.Status = this.PreviousStatus;
          }
        }
      }
    });

  }

  checkRFQGridValidation() {
    var inde = 0;
    this.IsRFQDataValid = true;
    this.EnquiryObj.RFQObject.forEach((element, index) => {
      if (element.ProductLine == undefined || element.ProductLine == null || element.ProductLine == 0) {
        document.getElementById("productline" + index).focus();
        element.ProductLineValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.ProductModel == undefined || element.ProductModel == null || element.ProductModel == 0) {
        document.getElementById("productmodel" + index).focus();
        element.ProductModelValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.Quantity == undefined || element.Quantity == null || element.Quantity == 0) {
        document.getElementById("quantity" + index).focus();
        element.QuantityValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.MDSOption == undefined || element.MDSOption == null || element.MDSOption == 0) {
        document.getElementById("mdsoption" + index).focus();
        element.MDSOptionValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.SalesPrice == undefined || element.SalesPrice == null || element.SalesPrice == 0) {
        document.getElementById("salesprice" + index).focus();
        element.SalesPriceValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.SalesDiscount == undefined || element.SalesDiscount == null) {
        document.getElementById("salesdiscount" + index).focus();
        element.SalesDiscountValidation = true;
        this.IsRFQDataValid = false;
      }

    });

  }

  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }




  downloadPDF() {
    const url = AppGlobals.baseAppUrl + 'uploads/pdf/' + this.EnquiryObj.EnquiryDetails.QuotationPdf_filename;
    window.open(url, "download");
  }

  DownloadPDFfromServer(Filename: any) {
    this.commonService.gereatePDF('Enquiry', 'DownloadOAPDF', Filename).subscribe((data: any) => {

      importedSaveAs(data, Filename);
    });
  }
  checkattachment() {
    this.rfqattpresent = false;
    this.EnquiryObj.RFQObject.forEach(element => {
      if (!(element.MDS_filename == null || element.MDS_filename == undefined || element.MDS_filename == '')) {
        this.rfqattpresent = true;
      }
      if (!(element.ITC_filename == null || element.ITC_filename == undefined || element.ITC_filename == '')) {
        this.rfqattpresent = true;
      }
      if (!(element.Weiss_filename == null || element.Weiss_filename == undefined || element.Weiss_filename == '')) {
        this.rfqattpresent = true;
      }
      if (!(element.Customer_filename == null || element.Customer_filename == undefined || element.Customer_filename == '')) {
        this.rfqattpresent = true;
      }
    });
  }
  DownloadAllAttachments() {
    this.checkattachment();
    if (!this.rfqattpresent) {
      this.toastr.info('No attachment present.');
      return;
    }
    this.updateLoaderEvent.emit(true);
    let queryString = "";
    queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
    this.commonService.GetData('Enquiry', 'Download', queryString).subscribe((data: any) => {
      var url = AppGlobals.ZipDownloadUrl + data + '.zip';
      window.open(url, "_blank");
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }



  public saveAsFile(pdfurl: any, fileName: string): void {
    //const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(this.fileDownloadPath + pdfurl, fileName);
  }

  
  getAllWorkFlow() {
    this.commonService.GetData("Workflow", "GetAll", null).subscribe((data: any) => {
     
      if(data!=null){
        var workflowData = data;
        this.workflowId=workflowData.filter(x=>x.document_type_code == "ORDER_ACK")[0].workflow_id;
        this.getWorkflowActions(this.workflowId);
      }
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  getWorkflowActions(workflowId:any)
  {    
    let queryString = "";
    queryString = "enquiryId=" + this.EnquiryObj.EnquiryDetails.EnquiryId +"&workflowId=" + workflowId ;

    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("EnquiryWorkflowTransitionRules","GetTransitionRulesByStatus",queryString).subscribe((data: any) => {
      this.workflowActionList=data;
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  getEnquiryStatus()
  {
    this.commonService.GetData("Enquiry","GetEnquiryStatus", null).subscribe((data: any) => {
      this.enquiryStatusList=data;
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  OnWorkflowAction(myform9:NgForm,workflowActionId:number)
    {    
    var rule= this.workflowActionList.filter(x=>x.workflowActionId==workflowActionId);
    
      myform9.control.markAllAsTouched();
      if(myform9.invalid)
      {
        this.toastr.error("Please fill required information");
        return;
      }  
      this.SetStatus(rule,myform9);  
  }

  getUserRoles(){
    this.commonService.GetData("Account", "GetAll", null).subscribe((data: any) => {
      this.UserRolesData = data;
    
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  
  sendbackRules:EnquirySendBack[]=[];
  getSendbackWorkfow()
  {
    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("EnquiryWorkflowTransitionRules","GetWorkflowSendbackRule", null).subscribe((data: any) => {
      this.sendbackRules=data;
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  rule:EnquirySendBack;
  SendBack()
  {      
    var quotationRule= this.sendbackRules.filter(x=>x.WorkflowId==this.workflowId);
    this.rule=quotationRule[0];

    const modalRef = this.modalService.open(SendBackCommentComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });

    var sendBackObj = {
      EnquiryId: this.EnquiryObj.EnquiryDetails.EnquiryId,
      workflowId: this.workflowId,
      finalrule: this.rule
    }
    modalRef.componentInstance.sendBackObj = sendBackObj;
    modalRef.result.then((result) => {
      this.closeResult = `Closed with:${result}`;
      if (this.closeResult !== 'Closed with:Close click') {
        this.ngOnInit();
      }
    });    
  }

}
