import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Level1Model, ProductSeries, VariantLevel2 } from 'src/app/Model/ProductModel';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';
@Component({
  selector: 'app-edit-view-variant-level2',
  templateUrl: './edit-view-variant-level2.component.html',
  styleUrls: ['./edit-view-variant-level2.component.scss']
})
export class EditViewVariantLevel2Component implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title,private accessservice: AccessPermissionService,
    private appConfigService : AppConfigService) { }

  VariantLevel2obj: VariantLevel2 = new VariantLevel2();
  isEditable: boolean = true;
  FormStatus: string = "View";
  charCode: any;
  @Input()
  showLoader: boolean = false;
  queryString: string = "";
  //To bind current VariantLevel2 data
  Level2Currdata: any[] = [];

  ProductSeries: ProductSeries = new ProductSeries();
  Level1arr: Level1Model[] = [];
  Level2arr: Level1Model[] = [];
  UserRole: any;
  isProductVariant2Editable: boolean = false;
  SpeedStepName: string = "";
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];



  @ViewChild('closebutton') closebutton;
  
  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    // this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
    //this.isProductVariant2Editable = AppGlobals.ProductMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    if (this.route.snapshot.params["Id"] == undefined) {
      this.queryString = "Level1=" + this.route.snapshot.params["Level"];
      this.queryString += "&ProductModel=" + this.route.snapshot.params["Model"];
      this.queryString += "&Price=" + this.route.snapshot.params["Price"];
      this.queryString += "&Status=" + this.route.snapshot.params["Status"];
      this.queryString += "&IsPriceOnRequest=" + this.route.snapshot.params["IsPriceOnRequest"];
      this.getVariantLevel2IdDetailsById('GetbyProductModelAndPrice', this.queryString);
    }
    else {
      this.queryString = "Id=" + this.route.snapshot.params["Id"];
      this.getVariantLevel2IdDetailsById('GetById', this.queryString);
    }

  }

  /*******************************************************************************
    * Get data for add variant level-1
    * ******************************************************************************/
  getVariantLevel2IdDetailsById(ActionName: string, queryString: string) {
    this.showLoader = true;

    this.CommonService.GetData('ProductVariantLevel2', ActionName, queryString).subscribe((data: any) => {
      this.VariantLevel2obj = data;
      this.getAllProductSeries();
      this.GetVariant1ByModelId(this.VariantLevel2obj.ProductModel);
      if (this.VariantLevel2obj.SeriesName == 'TC' || this.VariantLevel2obj.SeriesName == 'TR') {
        this.Level2arr = this.VariantLevel2obj.Level2;
        this.Level2Currdata = this.VariantLevel2obj.Level2.map(item => item.Level1.toString());
        this.GetAllSpeedStep(this.VariantLevel2obj.Level1Id, this.VariantLevel2obj.ProductModel);
      }


      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });

  }

  getAllProductSeries() {
    this.showLoader = true;

    let queryString = "";
    queryString = "Id=" + this.VariantLevel2obj.ProductSeries;

    this.CommonService.GetData("ProductSeries", "GetById", queryString).subscribe((data: any) => {
      this.VariantLevel2obj.SeriesName = data.SeriesName;
      this.VariantLevel2obj.ProductSeries = data.Id;
      this.VariantLevel2obj.IsLevel2DepOnLevel1 = data.IsLevel2DepOnLevel1;
      this.VariantLevel2obj.Label_Variant1 = data.Label_Variant1;
      this.VariantLevel2obj.Label_Variant2 = data.Label_Variant2;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetVariant1ByModelId(ModelId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel1", "GetVariant1ByModelId", queryString).subscribe((data: any) => {
      this.Level1arr = data;
      if (this.VariantLevel2obj.SeriesName == 'TC' || this.VariantLevel2obj.SeriesName == 'TR') {
        this.Level1arr = this.Level1arr.sort((a, b) => parseInt(a.Level1) - parseInt(b.Level1));
      }
      else {
        this.Level1arr = this.Level1arr.sort((a, b) => a.Level1.localeCompare(b.Level1));
      }

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetAllSpeedStep(StationId, ModelId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    queryString += "&StationId=" + StationId;
    this.CommonService.GetData("ProductVariantLevel2", "GetAllSpeedStep", queryString).subscribe((data: any) => {
      this.Level2arr = this.VariantLevel2obj.Level2.concat(data);
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });
  }


  /*******************************************************************************
   * End get data for add variant level-1
   * ******************************************************************************/

  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    const appConfig = this.appConfigService.getConfig();
    this.titleService.setTitle(`Edit Product Variant Level 2 - ${appConfig.titleName}`);
    // this.titleService.setTitle("Edit Product Variant Level 2 - ECOTECH");
  }
  onIsPriceOnRequest(event: any) {
    if (event.checked) {
      this.VariantLevel2obj.Level2Price = 0;
    }
  }

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  OpenPopupAddSpeedStep() {
    this.SpeedStepName = "";

  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  DeleteStation() {
    this.VariantLevel2obj.Level2 = [];
    for (var i = 0; i < this.Level2Currdata.length; i++) {
      var obj = this.Level2arr.find(x => x.Level1 == this.Level2Currdata[i])
      this.VariantLevel2obj.Level2.push(obj);
    }
    this.VariantLevel2obj.Level2 = this.VariantLevel2obj.Level2.filter(x => x.Id != null);
    if (this.VariantLevel2obj.Level2.length == 0) {
      this.toastr.warning("This " + this.VariantLevel2obj.Label_Variant2 + " does not exist for " + this.VariantLevel2obj.ModelName + " product model")
      this.VariantLevel2obj.Level2 = [];
    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to delete this " + this.VariantLevel2obj.Label_Variant2 + "?"
        }
      });

      dialogRef.afterClosed().subscribe(dialogResult => {

        if (dialogResult) {
          this.showLoader = true;

          this.CommonService.PostData('ProductVariantLevel2', 'DeleteSpeedStep', this.VariantLevel2obj, null).subscribe((data: any) => {
            let result = data;

            if (result.ResponseCode == 1) {
              this.toastr.success(this.VariantLevel2obj.Label_Variant2 + ' deleted successfully!');
              this.router.navigate(['/VariantLevel2', this.VariantLevel2obj.ProductSeries, this.VariantLevel2obj.Label_Variant1, this.VariantLevel2obj.Label_Variant2]);
              this.showLoader = false;
            }
            else if (result.ResponseCode == 0) {
              this.toastr.warning("Failed to delete this " + this.VariantLevel2obj.Label_Variant2 + " because it has some dependency!");
              this.showLoader = false;
            }
            else {
              this.toastr.error('Failed to delete ' + this.VariantLevel2obj.Label_Variant2 + '!');
              this.router.navigate(['/VariantLevel2', this.VariantLevel2obj.ProductSeries, this.VariantLevel2obj.Label_Variant1, this.VariantLevel2obj.Label_Variant2]);
              this.showLoader = false;
            }


          }, error => {
            this.toastr.error('Failed to delete ' + this.VariantLevel2obj.Label_Variant2 + '!');
            this.showLoader = false;
            this.router.navigate(['/VariantLevel2', this.VariantLevel2obj.ProductSeries, this.VariantLevel2obj.Label_Variant1, this.VariantLevel2obj.Label_Variant2]);
          });
        }
        else {
          this.VariantLevel2obj.Level2 = [];
        }
      });
    }
  }

  onSubmit(myform: any) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update this " + this.VariantLevel2obj.Label_Variant2 + "?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.showLoader = true;
        if (this.VariantLevel2obj.SeriesName == 'TC' || this.VariantLevel2obj.SeriesName == 'TR') {
          this.VariantLevel2obj.Level2 = [];
        }
        for (var i = 0; i < this.Level2Currdata.length; i++) {
          var obj = this.Level2arr.find(x => x.Level1 == this.Level2Currdata[i])
          this.VariantLevel2obj.Level2.push(obj);
        }
        this.CommonService.PostData('ProductVariantLevel2', 'Update', this.VariantLevel2obj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success(this.VariantLevel2obj.Label_Variant2 + ' updated successfully!');
            this.router.navigate(['/VariantLevel2', this.VariantLevel2obj.ProductSeries, this.VariantLevel2obj.Label_Variant1, this.VariantLevel2obj.Label_Variant2]);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning(this.VariantLevel2obj.Label_Variant2 + ' already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to update ' + this.VariantLevel2obj.Label_Variant2 + '!');
            this.router.navigate(['/VariantLevel2', this.VariantLevel2obj.ProductSeries, this.VariantLevel2obj.Label_Variant1, this.VariantLevel2obj.Label_Variant2]);
            this.showLoader = false;
          }


        }, error => {
          this.toastr.error('Failed to update ' + this.VariantLevel2obj.Label_Variant2 + '!');
          this.showLoader = false;
          this.router.navigate(['/VariantLevel2', this.VariantLevel2obj.ProductSeries, this.VariantLevel2obj.Label_Variant1, this.VariantLevel2obj.Label_Variant2]);
        });
      }
    });
  }

  AddSpeedStep(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this SpeedStep ?",
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;

        let queryString = "";
        queryString = "SpeedStepName=" + this.SpeedStepName;
        this.CommonService.PostData('ProductVariantLevel2', 'AddSpeedStep', null, queryString).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('SpeedStep added successfully!');
            this.closebutton.nativeElement.click();
            if ((this.VariantLevel2obj.ProductModel != null && this.VariantLevel2obj.ProductModel != undefined
              && this.VariantLevel2obj.ProductModel != 0) &&
              (this.VariantLevel2obj.Level1Id != null && this.VariantLevel2obj.Level1Id != undefined && this.VariantLevel2obj.Level1Id != 0)) {

              this.GetAllSpeedStep(this.VariantLevel2obj.Level1Id, this.VariantLevel2obj.ProductModel);
            }
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('SpeedStep already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to add SpeedStep!');
            this.closebutton.nativeElement.click();
            if ((this.VariantLevel2obj.ProductModel != null && this.VariantLevel2obj.ProductModel != undefined
              && this.VariantLevel2obj.ProductModel != 0) &&
              (this.VariantLevel2obj.Level1Id != null && this.VariantLevel2obj.Level1Id != undefined && this.VariantLevel2obj.Level1Id != 0)) {

              this.GetAllSpeedStep(this.VariantLevel2obj.Level1Id, this.VariantLevel2obj.ProductModel);
            }
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add SpeedStep!');
          this.closebutton.nativeElement.click();
          this.showLoader = false;
          if ((this.VariantLevel2obj.ProductModel != null && this.VariantLevel2obj.ProductModel != undefined
            && this.VariantLevel2obj.ProductModel != 0) &&
            (this.VariantLevel2obj.Level1Id != null && this.VariantLevel2obj.Level1Id != undefined && this.VariantLevel2obj.Level1Id != 0)) {

            this.GetAllSpeedStep(this.VariantLevel2obj.Level1Id, this.VariantLevel2obj.ProductModel);
          }
        });
      }
    });

  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isProductVariant2Editable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isProductLine')[0]?.IsEditable;
      
    });
 
  }
}

