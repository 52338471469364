import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from 'src/app/app.global';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { EnquiryDetailsModel } from 'src/app/Model/EnquiryModel';
import { LeadModel } from 'src/app/Model/LeadModel';
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { CommonService } from 'src/app/Service/common.service';
import { CloseLeadComponent } from '../../close-lead/close-lead.component';
import { AppConfigService } from 'src/app/Service/app-config.service';
@Component({
  selector: 'app-edit-view-lead',
  templateUrl: './edit-view-lead.component.html',
  styleUrls: ['./edit-view-lead.component.scss']
})

export class EditViewLeadComponent implements OnInit {
  LeadObj: LeadModel = new LeadModel();
 // EnquiryObj: EnquiryDetailsModel = new EnquiryDetailsModel();
  Id: any;
  Country: any[] = [];
  Region: any[] = [];
  IndustrySegment: any[] = [];
  FK_EnquirySourceId : any[] = [];
  Typeofcustomer: any[] = [];
  enquirySource: any[] = [];
  showLoader: boolean = false;
  minDate: NgbDate;
  maxDate: NgbDate;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  isEditable: boolean = true;
  FormStatus: string = "View";
  isLeadEditable: boolean = false;
  UserRole: any;
  AppGlobals = AppGlobals;
  closeResult: string;
  type="lead";
  leadStatus:any;
  constructor(
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    private Service: CommonService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private CommonService: CommonService,
    private titleService: Title,
    private appConfigService : AppConfigService) {
    this.minDate = new NgbDate(2018, 1, 1);
    this.maxDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  }
  ngOnInit(): void {
    this.UserRole = localStorage.getItem("UserRole");
    this.isLeadEditable = AppGlobals.LeadMasterEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.Id = this.route.snapshot.params["id"];
    this.getLeadDetailsById(this.Id);
    this.GetCustomerDropdownData();
    this.getEnquirySourceDetails();

  }
  GetCustomerDropdownData() {
    this.showLoader = true;
    this.Service.GetData("Customer", "GetCustomerDropdownDetails", null).subscribe((data: any) => {
      //this.Country = data.Country;
      this.Region = data.Region;
      this.IndustrySegment = data.IndustrySegment;
      this.Typeofcustomer = data.CustomerType;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getLeadDetailsById(Lead_Id: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "Lead_Id=" + Lead_Id;
    this.Service.GetData('Lead', 'GetById', queryString).subscribe((data: any) => {
      this.LeadObj = data;
      this.LeadObj.LeadDate = new NgbDate(new Date(this.LeadObj.LeadDate).getFullYear(), new Date(this.LeadObj.LeadDate).getMonth() + 1, new Date(this.LeadObj.LeadDate).getDate());
     this.remLimit = this.maxLimit - data.Description.length;
      if(this.LeadObj!=null)
      {
        if(this.LeadObj.IsConvertToProspect)
        {
          this.leadStatus="Converted to prospect"; 
        }
       
        if(this.LeadObj.Fk_LeadCloseReason!=null)
        {
          this.leadStatus="Lead is closed with reason : " + this.LeadObj.closeReason;
        }
        if(this.LeadObj.Fk_LeadCloseReason==null && !this.LeadObj.IsConvertToProspect)
        {
          this.leadStatus="Lead is active";
        }
      }
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });

  }
  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }
  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }
  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    const appConfig = this.appConfigService.getConfig();
    this.titleService.setTitle(`Edit Lead - ${appConfig.titleName}`);
    //this.titleService.setTitle("Edit Lead - ECOTECH");
  }
  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "You want to Update this Lead?"
      }

    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showLoader = true;
        if (this.LeadObj.LeadDate != undefined) {
          this.LeadObj.LeadDate = new Date(this.convertDate(new NgbDate(this.LeadObj.LeadDate.year, this.LeadObj.LeadDate.month, this.LeadObj.LeadDate.day))).toDateString();
        }
        
        const frmData = new FormData();
        let LeadDatails = JSON.stringify(this.LeadObj);
        frmData.append("LeadModel", LeadDatails);
        if (this.LeadObj.Id != undefined) {
          frmData.append("Lead", this.Id);
        }

        this.CommonService.PostData('Lead', 'Update', frmData, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('Lead Update successfully!');
            this.CommonService.RefreshHeaderFun();
            this.router.navigate(['/Lead']);
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to Update Lead!');
            this.router.navigate(['/Lead']);
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to Update Lead!');
          this.showLoader = false;
          this.router.navigate(['/Lead']);
        });
      }


    });

  }
  openLeadReasonPopup() {
    const modalRef = this.modalService.open(CloseLeadComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });

    var leadObj = {
      Lead_Id: this.LeadObj.Lead_Id,
      // EnquiryStatus: this.EnquiryObj.EnquiryDetails.Status,
      // EnqTermsConditionSelected: this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition,
      // IsQuotationPriceOnRequest: this.EnquiryObj.EnquiryDetails.IsQuotationPriceOnRequest,
      // TermsAndCondition: this.EnquiryObj.TermsAndConditions,
      // CustomTermsAndCondition: this.EnquiryObj.CustomTermsAndConditions,
      // PendingAtRoleId: this.EnquiryObj.EnquiryDetails.PendingAtRoleId
    }

    modalRef.componentInstance.leadDataObj = leadObj;
    modalRef.result.then((result) => {
      this.closeResult = `Closed with:${result}`;
      if (this.closeResult !== 'Closed with:Close click') {
        this.ngOnInit();
      }
    });
  }
  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }
  convert()
  {
    this.router.navigate( ['EditViewCustomer', {id: this.LeadObj.Lead_Id, type: "lead"}]);    
  }
  
  getEnquirySourceDetails() {
    this.showLoader = true;
    this.CommonService.GetData("Enquiry", "GetEnquiryDropdownData", null).subscribe((data: any) => {
      this.enquirySource = data.EnquirySource;
      this.showLoader = false;
    });
  }

  goBack() {
    this.location.back();
  }
}
