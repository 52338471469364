import { Component, OnInit, Input } from '@angular/core';
import { CustomerModel, PlantInformationWithGSTNoModel, TopManagementModel, AttachmentModel } from 'src/app/Model/CustomerModel';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { SingleFieldModel, SingleFieldComponent } from 'src/app/Model/SingleFieldModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-edit-view-customer',
  templateUrl: './edit-view-customer.component.html',
  styleUrls: ['./edit-view-customer.component.scss']
})
export class EditViewCustomerComponent implements OnInit {
  CustomerModel: CustomerModel = new CustomerModel();
  emailPattern: string = "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  urlPattern_old: string = "^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$";
  urlPattern: string = "(https?://)?([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*/?";
  charCode: any;
  Country: any[] = [];
  Region: any[] = [];
  IndustrySegment: any[] = [];
  SubSegment: any[] = [];
  CustomerClassification: any[] = [];
  CustomerCategory: any[] = [];
  Typeofcustomer: any[] = [];
  InvalidExtension: boolean;
  CustomerGroup: any[] = []; //for customer group  & code
  CustomerGroupCode: any[] = []; //for customer group  & code

  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];


  fileType: any[] = ['image/bmp', 'image/jpeg', 'image/png', 'application/pdf', 'text/plain', 'text/csv', 'application/xml', 'text/xml',
    'application/msword', 'image/jpg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/zip', 'application/x-zip-compressed', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
  KYCfileType: any[] = ['text/csv', 'application/xml', 'text/xml', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  tempCorporatePresentation: any;
  tempOrganogram: any;
  tempKYC1: any;
  tempKYC2: any;
  tempKYC3: any;
  PlantInformationWithGSTNoobj: PlantInformationWithGSTNoModel = new PlantInformationWithGSTNoModel();
  TopmanagementObj: TopManagementModel = new TopManagementModel();
  isEditable: boolean = true;
  FormStatus: string = "View";
  Attachmentobj: AttachmentModel = new AttachmentModel();
  KYC1Attachment: any;
  UserRole: any;
  isCustomerEditable: boolean = false;
  ProductModelDiscount:any[]=[];
  specialDiscountEnrtiesVisible:boolean;
  singleFieldObj: SingleFieldModel = new SingleFieldModel();
  singleFieldComponent: SingleFieldComponent = new SingleFieldComponent();
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  Id: any;
  mailIdFormat : any;
  businessOfWhichComapany : any;
  opportunitiesForWhichCompany : any;
  weissContactDetailsOfWhichCountry : any;
  weissWithAbbreviatedCountry : any;

  @Input()
  showLoader: boolean = false;
  
  //leadId: any;
  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private Service: CommonService, public dialog: MatDialog, private titleService: Title,private accessservice: AccessPermissionService,
    private appConfigService : AppConfigService) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    this.mailIdFormat = appConfig.mailIdFormat;
    this.businessOfWhichComapany = appConfig.businessOfWhichComapany;
    this.opportunitiesForWhichCompany = appConfig.opportunitiesForWhichCompany;
    this.weissContactDetailsOfWhichCountry = appConfig.weissContactDetailsOfWhichCountry;
    this.weissWithAbbreviatedCountry = appConfig.weissWithAbbreviatedCountry;
    //this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
  //  this.isCustomerEditable = AppGlobals.CustomerMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.CustomerModel.BasicCustomerInformationModel.Id = this.route.snapshot.params["id"];
    
    this.GetCustomerDropdownData();
    this.getCustomerDetailsById(this.CustomerModel.BasicCustomerInformationModel.Id);
   // this.getLeadDetailsById(this.CustomerModel.BasicCustomerInformationModel.Id);
   this.getDetailsByCustomerId(this.CustomerModel.BasicCustomerInformationModel.Id); 
   this.Id = this.route.snapshot.params["id"]; 
  }



  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    const appConfig = this.appConfigService.getConfig();
    this.titleService.setTitle(`Edit Customer - ${appConfig.titleName}`);
    //this.titleService.setTitle("Edit Customer - ECOTECH");
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


  /*******************************************************************************
      * Get data for add customer
  * ******************************************************************************/
  getCustomerDetailsById(CustomerId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "id=" + CustomerId;

    this.Service.GetData('Customer', 'GetById', queryString).subscribe((data: any) => {
      this.CustomerModel = data;
      this.remLimit = this.maxLimit - data.OpportunitiesforWeiss.Comments.length;
      this.GetSubSegment(this.CustomerModel.CustomerProfile.IndustrySegment);
      this.GetCustomerGroupCode(this.CustomerModel.BasicCustomerInformationModel.CustomergroupId);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  getLeadDetailsById(leadId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "Lead_Id=" + leadId;

    this.Service.GetData('Lead', 'GetLeadByIDForCustomer', queryString).subscribe((data: any) => {
      this.CustomerModel = data;
      this.GetSubSegment(this.CustomerModel.CustomerProfile.IndustrySegment);
      this.GetCustomerGroupCode(this.CustomerModel.BasicCustomerInformationModel.CustomergroupId);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  getDetailsByCustomerId(CustomerId: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + CustomerId;

    this.Service.GetData('CustomerSpecialDiscount', 'GetById', queryString).subscribe((data: any) => {
      this.ProductModelDiscount = data;
      
      if(this.ProductModelDiscount?.length==0||this.ProductModelDiscount?.length==undefined)
      {
          this.specialDiscountEnrtiesVisible=false;
      }
      else
      {
        this.specialDiscountEnrtiesVisible=true;
      }     
    
    }, error => {
      this.toastr.error("Failed to load data!");

    });
    this.showLoader = false;
  }

  GetCustomerDropdownData() {
    this.showLoader = true;
    this.Service.GetData("Customer", "GetCustomerDropdownDetails", null).subscribe((data: any) => {
      this.Country = data.Country;
      this.Region = data.Region;
      this.IndustrySegment = data.IndustrySegment;
      this.CustomerCategory = data.CustomerCategory;
      this.CustomerClassification = data.CustomerClassification;
      this.Typeofcustomer = data.CustomerType;
      this.CustomerGroup = data.CustomerGroup; //for customer group & code
      //console.log("CustomerGroup array---->",this.CustomerGroup)
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetSubSegment(IndustrySegment: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "SegmentId=" + IndustrySegment;
    this.Service.GetData("SubSegment", "GetByIndustrySegmentId", queryString).subscribe((data: any) => {
      this.SubSegment = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  /*******************************************************************************
      * End get data for add customer
  * ******************************************************************************/

  /*******************************************************************************
      * File Upload section
  * ******************************************************************************/

  CheckFileValidation(File: any, ValidFileType: any[], MaxSize: number) {
    var Result = {
      isExtensionValid: false,
      isSizeValid: false
    };
    let FileType = File.type;
    if ((File.size) / 1000000 <= MaxSize) {
      Result.isSizeValid = true;
      for (var j = 0; j < ValidFileType.length; j++) {
        if (FileType.toLowerCase() == ValidFileType[j]) {
          Result.isExtensionValid = true;
          break;
        }

      }

    }
    else {
      Result.isSizeValid = false;
    }
    return Result;
  }

  NoOfEmp(myform: any) {
    if (this.CustomerModel.CompanyTurnover.Noofemployees == 0 && this.CustomerModel.CompanyTurnover.Noofemployees.toString() != "") {
      myform.controls['noofemployees'].setErrors({ NoGreater: true });
    }
  }

  getfiledetails(e: any) {
    if (e.target.files[0].name.length > 50) {
      e.target.value = null;
      this.toastr.warning("The file name with more than 50 characters are not allowed.");
    }
    else {
      if (e.target.name == "KYC1attachment" || e.target.name == "KYC2attachment" || e.target.name == "KYC3attachment") {
        let Result = this.CheckFileValidation(e.target.files[0], this.KYCfileType, 5);
        if (Result.isExtensionValid == true && Result.isSizeValid == true) {
          if (e.target.name == "KYC1attachment") {
            this.KYC1Attachment = e.target.files[0];
            const KYCfrmData = new FormData();
            KYCfrmData.append("KYC1Attachment", this.KYC1Attachment);
            this.showLoader = true;
            this.Service.PostData('Customer', 'KYC1Attachment', KYCfrmData, null).subscribe((data: any) => {
              this.CustomerModel = data;
              this.showLoader = false;
            }, error => {
              this.toastr.error('Failed to load data!');
              this.showLoader = false;

            });
          }
          else if (e.target.name == "KYC2attachment") {
            this.Attachmentobj.KYC2Attachment = e.target.files[0];
          }
          else if (e.target.name == "KYC3attachment") {
            this.Attachmentobj.KYC3Attachment = e.target.files[0];
          }

        }
        else if (Result.isExtensionValid == false) {
          e.target.value = null;
          this.toastr.warning("Please attach the file having extension :\n\n .xls, .xlsx");
        }
        else if (Result.isSizeValid == false) {
          e.target.value = null;
          this.toastr.warning("File size must be less than or equal to 5MB!");
        }
      }

      else {
        var Result = this.CheckFileValidation(e.target.files[0], this.fileType, 5)
        if (Result.isExtensionValid == true && Result.isSizeValid == true) {
          if (e.target.name == "corppresentation") {
            this.Attachmentobj.CorporatePresentationAttachment = e.target.files[0];
          }
          else if (e.target.name == "organogram") {
            this.Attachmentobj.OrganogramAttachment = e.target.files[0];
          }

        }
        else if (Result.isExtensionValid == false) {
          e.target.value = null;
          this.toastr.warning("Please attach the file having extension :\n\n .bmp, .jpeg, .jpg, .png, .pdf, .xml, .txt, .doc, .docx, .zip, .ppt, .pptx");
        }
        else if (Result.isSizeValid == false) {
          e.target.value = null;
          this.toastr.warning("File size must be less than or equal to 5MB!");
        }
      }

    }

  }

  /*******************************************************************************
      * End File Upload section
  * ******************************************************************************/

  OnselectIndustrySegment(event: any) {
    this.CustomerModel.CustomerProfile.SubSegment = null;
    this.GetSubSegment(event.target.value);
  }

  //for customer grp name & code
  OnselectCustomerGroupName(event: any) {
    //this.CustomerModel.BasicCustomerInformationModel.CustomergroupId = null;
    this.GetCustomerGroupCode(event.target.value);
  
  }

  GetCustomerGroupCode(CustomerGroupId: number) {
    this.CustomerModel.BasicCustomerInformationModel.CustomergroupCode = null;
    this.CustomerGroupCode = this.CustomerGroup?.filter(x => x.Id == CustomerGroupId);
   // console.log("After CustomerGroupName change---->",this.CustomerGroupCode);
    
    }

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  PercentageShareValidation(myform: any) {
    if (this.CustomerModel.FinancialDetails.PercentageShare > 100) {
      myform.controls['percentageshare'].setErrors({ PercentageGreater: true });
    }
  }
  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  /*******************************************************************************
  * Add multiple filed (Dynamic grid)
  * ******************************************************************************/
  AddNewRow(Type: any) {

    if (Type == 'Submitted') {
      if (this.PlantInformationWithGSTNoobj.CompanyName != "" || this.PlantInformationWithGSTNoobj.Region != null || this.PlantInformationWithGSTNoobj.Address != "" || this.PlantInformationWithGSTNoobj.GSTNo != "" ||
        this.PlantInformationWithGSTNoobj.KeyAccountNo != "" || this.PlantInformationWithGSTNoobj.ContactNo != "" || this.PlantInformationWithGSTNoobj.Email != "") {
        this.CustomerModel.PlantInformationWithGSTNo.push(this.PlantInformationWithGSTNoobj);
        this.PlantInformationWithGSTNoobj = new PlantInformationWithGSTNoModel();
      }
      if (this.TopmanagementObj.Name != "" || this.TopmanagementObj.Designation != "" || this.TopmanagementObj.ContactNo != "" || this.TopmanagementObj.Email != "") {
        this.CustomerModel.TopManagement.push(this.TopmanagementObj);
        this.TopmanagementObj = new TopManagementModel();
      }

    }
    else {

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to add another row for entering remaining details?"
        }
      });

      dialogRef.afterClosed().subscribe(dialogResult => {

        if (dialogResult) {
          if (Type == 'Plant') {
            if (Object.keys(this.PlantInformationWithGSTNoobj).length > 0) {
              this.CustomerModel.PlantInformationWithGSTNo.push(this.PlantInformationWithGSTNoobj);
              this.PlantInformationWithGSTNoobj = new PlantInformationWithGSTNoModel();
            }
          }
          if (Type == 'TopManagement') {
            if (Object.keys(this.TopmanagementObj).length > 0) {
              this.CustomerModel.TopManagement.push(this.TopmanagementObj);
              this.TopmanagementObj = new TopManagementModel();
            }
          }
        }

      });

    }

  }

  deleteField(index: any, Type: any, Id: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to delete this record?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        if (Type == 'Plant') {

          if (Id != null && Id != undefined) {
            this.showLoader = true;
            let queryString = "";
            queryString = "id=" + Id;

            this.Service.PostData('Customer', 'DeletePlantDetailsbyId', null, queryString).subscribe((data: any) => {
              this.CustomerModel.PlantInformationWithGSTNo = data;
              this.showLoader = false;

            }, error => {
              this.toastr.error("Failed to delete record!");
              this.showLoader = false;
            });
          }
          else {
            this.CustomerModel.PlantInformationWithGSTNo.splice(index, 1);
          }

        }
        if (Type == 'TopManagement') {
          if (Id != null && Id != undefined) {
            this.showLoader = true;
            let queryString = "";
            queryString = "id=" + Id;

            this.Service.PostData('Customer', 'DeleteTopManagementDetailsbyId', null, queryString).subscribe((data: any) => {
              this.CustomerModel.TopManagement = data;
              this.showLoader = false;
            }, error => {
              this.toastr.error("Failed to delete record!");
              this.showLoader = false;
            });
          }
          else {
            this.CustomerModel.TopManagement.splice(index, 1);
          }
        }
      }

    });
  }

  /*******************************************************************************
   * End - add multiple filed (Dynamic grid)
   * ******************************************************************************/

  /*******************************************************************************
     *Download and delete file section
  *******************************************************************************/
  DownloadFile(Path: any, OrignalFilename: any) {
    let link = document.createElement('a');
    let path = AppGlobals.baseAppUrl + 'Uploads/' + Path
    link.setAttribute('href', path);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.remove();
    return false;
  }

  DeleteAttachments(Type: any, Filename) {
    if (Filename != '') {

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to delete this attachment?"
        }
      });

      dialogRef.afterClosed().subscribe(dialogResult => {

        if (dialogResult) {
          let queryString = "";
          queryString = "Filename=" + Filename;
          this.showLoader = true;
          this.Service.PostData('Customer', 'DeleteFile', null, queryString).subscribe((data: any) => {
            let result = data;
            this.showLoader = false;
            if (result.ResponseCode == 1) {
              switch (Type) {
                case "corppresentation":
                  this.CustomerModel.CustomerProfile.CorporatePresentationFileName = "";
                  this.CustomerModel.CustomerProfile.CorporatePresentationFilePath = "";
                  break;
                case "organogram":
                  this.CustomerModel.BasicCustomerInformationModel.OrganogramFileName = "";
                  this.CustomerModel.BasicCustomerInformationModel.OrganogramFilePath = "";
                  break;
                case "KYC2attachment":
                  this.CustomerModel.BasicCustomerInformationModel.KYC2FileName = "";
                  this.CustomerModel.BasicCustomerInformationModel.KYC2FilePath = "";
                  break;
                case "KYC3attachment":
                  this.CustomerModel.BasicCustomerInformationModel.KYC3FileName = "";
                  this.CustomerModel.BasicCustomerInformationModel.KYC3FilePath = "";
                  break;
              }
            }
            if (result.ResponseCode == 0) {
              this.toastr.warning("Failed to delete file!");
            }
          }, error => {
            this.toastr.error("Failed to delete file!");
            this.showLoader = false;
          });
        }
      });

    }
    else if (Filename == '') {
      switch (Type) {
        case "corppresentation":
          this.Attachmentobj.CorporatePresentationAttachment = null;
          break;
        case "organogram":
          this.Attachmentobj.OrganogramAttachment = null;
          break;
        case "KYC2attachment":
          this.Attachmentobj.KYC2Attachment = null;
          break;
        case "KYC3attachment":
          this.Attachmentobj.KYC3Attachment = null;
          break;
        case "KYC1attachment":
          this.KYC1Attachment = null;
          break;

      }
    }
  }

  /*******************************************************************************
 *End Download and delete file section
 * ******************************************************************************/

  onSubmit(myform: any) {

    // if(this.type=='lead')
    // {
    //   this.CustomerModel.BasicCustomerInformationModel.leadId=this.route.snapshot.params["id"];
      
    // }
    if (this.CustomerModel.FinancialDetails.PercentageShare > 100) {
      myform.controls['percentageshare'].setErrors({ PercentageGreater: true });
    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to update this customer?"
        }
      });
      if (this.CustomerModel.CompanyTurnover.Noofemployees == 0 && this.CustomerModel.CompanyTurnover.Noofemployees.toString() != "") {
        myform.controls['noofemployees'].setErrors({ NoGreater: true });
      }

      dialogRef.afterClosed().subscribe(dialogResult => {

        if (dialogResult) {

          this.AddNewRow('Submitted');
          const frmData = new FormData();
          frmData.append("KYC2Attachment", this.Attachmentobj.KYC2Attachment);
          frmData.append("KYC3Attachment", this.Attachmentobj.KYC3Attachment);
          frmData.append("CorporatePresentationAttachment", this.Attachmentobj.CorporatePresentationAttachment);
          frmData.append("OrganogramAttachment", this.Attachmentobj.OrganogramAttachment);
          frmData.append("CustomerModel", JSON.stringify(this.CustomerModel));
          this.showLoader = true;
          this.Service.PostData('Customer', 'Update', frmData, null).subscribe((data: any) => {
            let result = data;
            this.showLoader = false;
            if (result.ResponseCode == 1) {
              this.toastr.success('Customer details updated successfully!');
              this.router.navigate(['/Customer']);
            }
            else {
              this.toastr.error("Failed to update customer details!");
              this.showLoader = false;
            }
          }, error => {
            this.toastr.error('Failed to update customer details!');
            this.showLoader = false;
            this.router.navigate(['/Customer']);
          });
        }
      });
    }
  }
  

  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isCustomerEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isCustomer')[0]?.IsEditable;
      
    });
 
  }

}
