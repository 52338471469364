<loader [showLoader]="showLoader"></loader>

<div [ngClass]="{'custsize': inPopUpMode}">
  <div [ngClass]="{'modal-content custContent': inPopUpMode}">

    <div
      [ngClass]="{'m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-page__container m-body': !inPopUpMode}">

      <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
          <div class="row">
            <div class="col-sm-12">
              <div class="m-portlet">
                <div class="m-portlet__body">
                  <div class="multisteps-form">
                    <div class="row mt-3">
                      <div class="col-lg-12 mb-4 table-responsive">
                        <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right"
                          #addActivityForm="ngForm" (ngSubmit)="onSubmit(frmData,addActivityForm)" novalidate>
                          <div class="multisteps-form__panel shadow rounded bg-white js-active" data-animation="fadeIn">
                            <div class="multisteps-form__content">
                              <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                <div class="m-portlet__head">
                                  <div class="m-portlet__head-caption">
                                    <div class="m-portlet__head-title">
                                      <h3 class="m-portlet__head-text">
                                        Add Activity
                                      </h3>
                                    </div>
                                  </div>
                                </div>


                                <div class="m-portlet__body">
                                  <div id="dvTestData">
                                    <div class="form-group m-form__group row">
                                      <label class="col-lg-2 col-form-label">Activity Type:<span
                                          style="color:#f4516c;">&nbsp;*</span></label>
                                      <div class="col-lg-3">
                                        <select class="form-control" id="ActivityTypeId" name="ActivityTypeId"
                                          [(ngModel)]="frmData.ActivityTypeId"
                                          (change)="onActivityTypeChange(frmData.ActivityTypeId)" required>
                                          <option value="undefined" selected disabled> select </option>
                                          <!-- <option *ngFor="let item of activityTypeMaster" [value]="item.ActivityTypeId"   > -->
                                          <option *ngFor="let item of activityTypeMaster" [value]="item.ActivityTypeId">
                                            {{ item.ActivityTypeDesc }}
                                          </option>
                                        </select>
                                        <div
                                          *ngIf="addActivityForm.submitted && !addActivityForm.controls.ActivityTypeId.valid"
                                          class="text-danger">
                                          <div>Select activity</div>
                                        </div>
                                      </div>
                                      <div class="offset-1"></div>
                            <!-- ======= Future Use ===== -->
                                      <!-- <label class="col-lg-2 col-form-label"
                                        [ngStyle]="{'display': subtypeArray1.length !==0  ?  '' : 'none'}">Subtype
                                        :</label>
                                      <div class="col-lg-3"
                                        [ngStyle]="{'display': subtypeArray1.length !==0  ?  '' : 'none'}">
                                        <select class="form-control" id="ActivitySubType" name="ActivitySubType"
                                          [(ngModel)]="frmData.ActivitySubType">

                                          <option *ngFor="let c of subtypeArray1" [selected]="c === 'Webinar' ">{{c}}
                                          </option>
                                        </select>
                                        <div class="invalid-feedback">
                                          <div>Subtype is required</div>
                                        </div>
                                      </div> -->

                                    </div>

                                    <div class="form-group m-form__group row">
                                      <label class="col-lg-2 col-form-label">Owner:<span
                                          style="color:#f4516c;">&nbsp;*</span></label>
                                      <div class="col-lg-3">
                                        <select class="form-control" id="ActivityOwnerId" name="ActivityOwnerId"
                                          (change)="onOwnerChange(frmData,0)" [(ngModel)]="frmData.ActivityOwnerId"
                                          required>
                                          <option [value]="null" selected disabled>
                                            Select Owner
                                          </option>
                                          <option *ngFor="let p of allUserMaster" [value]="p.Id">
                                            {{ p.Name }}
                                          </option>
                                        </select>

                                      </div>
                                      <div class="offset-1"></div>
                                      <label class="col-lg-2 col-form-label">Subject:<span
                                          style="color:#f4516c;">&nbsp;*</span></label>
                                      <div class="col-lg-3">
                                        <input class="form-control m-input" id="Subject" name="Subject"
                                          [(ngModel)]="frmData.Subject" placeholder="Enter subject" #Subject
                                          (keydown)="onKeyDown($event)" required />
                                        <div
                                          *ngIf="addActivityForm.submitted && !addActivityForm.controls.Subject.valid"
                                          class="text-danger">
                                          <div>Subject is required</div>
                                        </div>

                                      </div>
                                      <div class="offset-1"></div>
                                    </div>

                                    <div class="form-group m-form__group row">
                                      <label class="col-lg-2 col-form-label">Purpose:</label>
                                      <div class="col-lg-3">
                                        <select class="form-control" id="ActivityPurposeId" name="ActivityPurposeId"
                                          [(ngModel)]="frmData.ActivityPurposeId">
                                          <option value="undefined" disabled selected>select</option>
                                          <option *ngFor="
                                                      let item of activityPurposeByTypeMaster"
                                            value="{{ item.ActivityPurposeId }}">
                                            {{ item.ActivityPurposeDesc }}
                                          </option>
                                        </select>
                                      </div>

                                      <div class="offset-1"></div>

                                      <label class="col-lg-2 col-form-label">Purpose Description:</label>
                                      <div class="col-lg-3">
                                        <textarea class="form-control m-input" id="PurposeDesc" name="PurposeDesc"
                                          [(ngModel)]="frmData.PurposeDesc" rows="3" #PurposeDesc
                                          (keydown)="onKeyDown($event)"
                                          placeholder="Enter purpose description"></textarea>
                                      </div>
                                    </div>

                                    <div class="form-group m-form__group row">
                                    </div>

                                    <div class="form-group m-form__group row">

                                      <label class="col-lg-2 col-form-label">Due Date:<span
                                          style="color:#f4516c;">&nbsp;*</span></label>

                                      <div class="col-lg-3">
                                        <div class="input-group date">
                                          <input type="datetime-local" class="form-control" id="PlannedDueDatetime"
                                            name="PlannedDueDatetime" [(ngModel)]="frmData.PlannedDueDatetime"
                                            required />
                                        </div>
                                        <div
                                          *ngIf="addActivityForm.submitted && !addActivityForm.controls.PlannedDueDatetime.valid"
                                          class="text-danger">
                                          <div>Due Date is required.</div>
                                        </div>


                                      </div>
                                      <div class="offset-1"></div>
                                    </div>

                                    <div class="form-group m-form__group row">
                                      <label class="col-lg-2 col-form-label">Description:<span
                                          style="color:#f4516c;">&nbsp;*</span></label>
                                      <div class="col-lg-9">
                                        <textarea class="form-control m-input" id="Comment" name="Comment"
                                          [(ngModel)]="frmData.Comment" rows="3" placeholder="Enter description"
                                          #Comment (keydown)="onKeyDown($event)" required></textarea>
                                        <div
                                          *ngIf="addActivityForm.submitted && !addActivityForm.controls.Comment.valid"
                                          class="text-danger">
                                          <div>Description is required.</div>
                                        </div>


                                      </div>
                                    </div>


                                    <div class="form-group m-form__group row">

                                      <label class="col-lg-2 col-form-label">Customers</label>

                                      <div class="col-lg-10" style="margin-left: -13px;">


                                        <div class="col-lg-8">
                                          <ng-select name="customerEntities" [items]="allCustomers" [searchable]="true"
                                            [multiple]="true" [closeOnSelect]="false" [placeholder]="'Select records'"
                                            bindLabel="EntityName" bindValue="EntityId"
                                            (change)="onChangeInCustomerSelection()" [(ngModel)]="selectedCustomers">

                                            <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                              let-index="index">
                                              <input name="itemc-{{index}}" id="itemc-{{index}}" type="checkbox"
                                                [ngModel]="item$.selected" style="margin-right: 8px;"/>
                                              <label for="itemc-{{index}}">{{ item.EntityName }}</label>
                                            </ng-template>

                                          </ng-select>
                                        </div>
                                        <div class="offset-1"></div>

                                      </div>

                                    </div>

                                    <div class="form-group m-form__group row">

                                      <label class="col-lg-2 col-form-label">Leads</label>
                                      <div class="col-lg-10" style="margin-left: -13px;">



                                        <div class="col-md-8">
                                          <ng-select name="leadEntities" [items]="allLeads" [searchable]="true"
                                            [multiple]="true" [closeOnSelect]="false" [placeholder]="'Select records'"
                                            bindLabel="EntityName" bindValue="EntityId" [(ngModel)]="selectedLeads">

                                            <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                              let-index="index">
                                              <input name="itemc-{{index}}" id="itemc-{{index}}" type="checkbox"
                                                [ngModel]="item$.selected" style="margin-right: 8px;"/>
                                              <label for="itemc-{{index}}">{{ item.EntityName }}</label>
                                            </ng-template>

                                          </ng-select>
                                        </div>
                                        <div class="offset-1"></div>

                                      </div>

                                    </div>

                                    <!-- ------------------------------------------------------------------------------------------------------- -->


                                    <div class="form-group m-form__group row">
                                      <label class="col-lg-2 col-form-label">Enquiry:</label>
                                      <div class="col-lg-10" style="margin-left: -13px;">


                                        <div class="col-md-8">
                                          <ng-select name="enquries" [items]=" allEnquiries" [searchable]="true"
                                            [multiple]="true" [closeOnSelect]="false" [placeholder]="'Select Enquiries'"
                                            bindLabel="EntityName" bindValue="EntityId" [(ngModel)]="selectedEnquries">
                                            <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                              let-index="index">
                                              <input name="itemc-{{index}}" id="itemc-{{index}}" type="checkbox"
                                                [ngModel]="item$.selected" style="margin-right: 8px;"/>
                                              <label for="itemc-{{index}}">{{ item.EntityName }}</label>
                                            </ng-template>
                                          </ng-select>
                                        </div>
                                        <div class="offset-1"></div>

                                      </div>
                                    </div>


















                                    <div class="form-group m-form__group row">
                                      <label class="col-lg-2 col-form-label">Participant:<span
                                          style="color:#f4516c;">&nbsp;*</span></label>
                                      <div class="col-lg-3">

                                        <ng-select name="participants" [items]="allUserMaster" [searchable]="true"
                                          [multiple]="true" [closeOnSelect]="false" [placeholder]="'Select Partcipants'"
                                          bindLabel="Name" bindValue="Id"
                                          [(ngModel)]="frmData.ListActivityParticipants">
                                          <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                            let-index="index">
                                            <input name="item-{{index}}" id="item-{{index}}" type="checkbox"
                                              [ngModel]="item$.selected" style="margin-right: 8px;"/>
                                            <label for="item-{{index}}">{{ item.Name }}</label>
                                          </ng-template>

                                        </ng-select>
                                      </div>
                                    </div>




                                    <div class="button-row text-left mt-4" style="margin: 15px">
                                      <button class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next"
                                        type="submit">
                                        Save</button>&nbsp;&nbsp;
                                      <button *ngIf="!inPopUpMode" class="btn btn-metal m-btn m-btn--air m-btn--custom"
                                        type="button" (click)="goBack()" routerLinkActive="active">
                                        Cancel
                                      </button>
                                      <button *ngIf="inPopUpMode" class="btn btn-metal m-btn m-btn--air m-btn--custom"
                                        type="button" (click)="closePopup()" routerLinkActive="active">
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>




<!-- <ng-select name="abc" [items]="dropdownData.ListActivityParticipants"
[searchable]="true"
[multiple]="true"
[closeOnSelect]="false"
[placeholder]="'Select options'"
bindLabel="ParticipantName"
bindValue="ActivityParticipant"
[(ngModel)]="selectedOptions">

<ng-template name="abc"  ng-option-tmp let-item="item" let-item$="item$" let-index="index">
  <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
  <label for="item-{{index}}">{{ item.ParticipantName }}</label>
</ng-template>

</ng-select>

<button (click)="submit()">Submit</button> -->
