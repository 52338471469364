import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { SingleFieldModel } from './SingleFieldModel';
import { string, array } from '@amcharts/amcharts4/core';

export class EnquiryDetailsModel {
    EnquiryDetails: EnquiryModel = new EnquiryModel();
    RFQObject: ProductDetails[] = Array<ProductDetails>();
    CustomerDetails: CustomerDetails = new CustomerDetails();
    EnqCustomerDetails: CustomerDrpdnDetails = new CustomerDrpdnDetails();
    Comments: Comments[] = Array<Comments>();
    RemoveFiles: string[] = Array<string>();
    TermsAndConditions: TermsAndCondition[] = Array<TermsAndCondition>();
    CustomerServiceComments_Quotation: CommentObj[] = Array<CommentObj>();
    CustomerServiceComments_Order: CommentObj[] = Array<CommentObj>();
    SalesDirectorComments_Quotation: CommentObj[] = Array<CommentObj>();
    SalesDirectorComments_Order: CommentObj[] = Array<CommentObj>();
    ActivityDetails: ActivityModel[] = Array<ActivityModel>();
    POValues: ProductModelPOValues[] = Array<ProductModelPOValues>();
    CustomerSpecialDiscountdtls: SalesPriceDiscountforCutomerSpecialDiscount[] = Array<SalesPriceDiscountforCutomerSpecialDiscount>();
    SalesPriceDiscountdtls: SalesPricediscountList[] = Array<SalesPricediscountList>();
    DiscountFrom: number = 0;
    DiscountTo: number = 0;
    CustomTermsAndConditions: CustomTermsAndCondition[] = Array<CustomTermsAndCondition>();
    CompetitorDetailObj: CompetitorDetails[] = Array<CompetitorDetails>();
    Fk_OppCloseReason: number;
    DynamicTCDetailObj: DynamicTermsAndCondition[] = Array<DynamicTermsAndCondition>();
    CustomerServiceDetails: CustomerServiceDetailModel = new CustomerServiceDetailModel();
}



export class SalesPriceDiscountforCutomerSpecialDiscount {
    ProductDiscount: number = 0;
    Fk_ProductModel: number = 0;

}

export class SalesPricediscountList {
    DiscountFrom: number = 0;
    DiscountTo: number = 0;
}

export class ProductModelPOValues {
    ProductId: Number;
    ProductModelName: string;
    QUotationValue: string;
    // ProductModelPOValue: string;
    ProductModelPOValue: any;
    QuotationQuantity: string;
    QuotationDiscount: any = 0;
    Variant1_Discount: any = 0;
    Variant2_Discount: any = 0;
    Variant3_Discount: any = 0;
    Variant4_Discount: any = 0;
    POQuantity: any;
    POUnitPrice: any;
    PODiscount: any = 0;
    QUotationValueValidation: boolean;
    QuantityValidation: boolean = false;
    QuantityRangeValidation: boolean = false;
    PoUnitPriceValidation: boolean = false;
    PoQuantityValidation: boolean = false;
    Variant1Label: any;
    Variant2Label: any;
    Variant3Label: any;
    Variant4Label: any;
    Options: OptionsModel[] = new Array<OptionsModel>();
    ScheduledShipmentDate: any;
    Special_pkg_Discount : any = 0;
}

export class EnquiryModel {
    UserId: number;
    EnquiryId: number;
    InitiatorName: string = "";
    RoleName: string = "";
    MngOrderApprovalComments: string = "";
    OrdAcknowledgeNumber: string = "";
    Comments: string = "";
    RFQNumber: string = "";
    RFQComments: string = "";
    KindAttention: string = "";
    KindAttEmailId: string = "";
    KindAttContactNo: string = "";
    YourReference: string = "";
    FinalAmount: any;
    Initiator_Id: any;
    EnquiryStatusDescription: any;

    //<Quote GEneration Variables
    QuoteGenComments: string = "";
    QuoteGenAttachments: any[] = [];
    BOMAttachment: any[] = [];  //Bill of materials
    BOM_filename: any;
    BOM_filepath: any;
    QuoteAttachmentsArray: any[] = [];
    //>
    //<MANAGEMENT APPROVAL ATTACHMETNS
    MngApprovalComments: string = "";
    MngApprovalAttachments: any[] = [];
    MngOrderApprovalAttachments: any[] = [];
    //>
    //<MANAGEMENT APPROVAL ATTACHMETNS
    CEOApprovalComments: string = "";
    CEOApprovalAttachments: any[] = [];
    //>

    InvoiceAttachment: any[] = [];
    Invoice_filename: any;
    Invoice_filepath: any;
    InvoiceNumber: any;

    SupplyChainType: string = "";
    TentativeDeliveryDate: any;
    RequestedDeliveryDate: any;

    POValue: string = "";
    POAttchments: any[] = [];
    EnquiryConfirmStatus: string = "";
    EnquiryConfirmReason: any = null;
    POOtherAttachments: any[] = [];
    PO_filename: any;
    PO_filepath: any;

    EnquiryDescription: string = "";
    Status: number;
    EnquiryStatusDisplay: string = "";
    EnquiryNumber: string = "";
    EnquiryDate: any;
    FkCustomersCustomer_Id:number=null;
    QuotationPdf_filename: any;

    EnquiryDateISO: string = "";
    TentativeDeliveryDateISO: string = "";
    RequestedDeliveryDateISO: string = "";
    RFQTermsAndCondition: string = "";
    PreviousRFQTermsAndCondition: string;
    EstimatedProductValue: number = null;
    EnquirySourceId: number;
    IsQuotationPriceOnRequest: boolean = false;
    PendingAtRoleId: string;
    QuotationRevisionNo: number = null;
    QuotationNumber: string = "";

    TechnicalDiscardId: number;
    TechnicalDiscardBy: number;
    segment: number;
    subSegment: number;
    application: number;
    EndCustomer: string = "";
    Fk_OppCloseReason: any;
    IsActive: boolean = true;
    EnquiryDeletedBy: number;
    ApplicationDescription: string = "";
    IsFetchFromKYC: boolean = false;
    OA_filename: any;
    IsCompetitorRequired: boolean = false;

    PackageAndForward: number;
    TransferCharge: number;
    OtherTax: number;

    POSubmittedOn: any;
    PurchaseOrderNo: string = "";
    BillToAddress: string = "";
    ShipToAddress: string = "";
    IsIncludedAllSOCharges: boolean = false;
    POValueWithAllSOCharges: string = "";

    ReasonCategory : any;
    EnquiryConfirmReasonName : string = "";
    ReasonCategoryName : string = "";
    OrderAckDate : any;
    SalesOrderSpecialPkgDiscount : any =0;
    
}

export class removefiles {
    ProductId: number;
    filetype: string;
}
export class CommentObj {
    CommentDate: any;
    Comments: any;
    CommentsBy: any;
    DisplayStatus: any;
    EnquiryId: any;
    Id: any;
    Role: any;
    RoleName: any;
    Status: any;
    UserId: any;
}

export class TermsAndCondition {
    Enquiry_TnCId: any;
    TnCId: any;
    TnCName: any;
    TnCValue: any;
}

export class DynamicTermsAndCondition {
    Enquiry_TndCId: any;
    TndCId: any;
    TndCName: string = "";
    TndCValue: string = "";
    IsRowDeleted: boolean;
    TndCNameValidation: boolean = false;
    TndCValueValidation: boolean = false;
}

export class CustomerDetails {
    CustomerId: number = 0;
    CustomerCode: string = "";
    IndustrySegmentId: number;
    CustomerTypeId: number;
    CustomerCategoryId: number;
    CustomerClassificationId: number;
    RegionId: number;
    CustomerName: string = "";
    Region: string = "";

}

export class ProductDetails {
    ProductId: number;
    ProductLine: number;
    ProductModel: number;
    Quantity: number;
    MDSOption: number;
    MDSAttachment: any[] = [];
    ITCAttachment: any[] = [];
    CustomerAttachment: any[] = [];
    WeissAttachment: any[] = [];
    isMDSAttachment: boolean;
    isITCAttachment: boolean;
    isCustomerAttachment: boolean;
    isWeissAttachment: boolean;
    SalesPrice: number;
    EstimatedProductValue: number;
    SalesDiscount: string;
    IsRowDeleted: boolean;
    ProductModelList: any[] = [];
    isProductLineChangeClick: boolean = false;
    MDS_filename: string;
    MDS_filepath: string;
    ITC_filename: string;
    ITC_filepath: string;
    Customer_filename: string;
    Customer_filepath: string;
    Weiss_filename: string;
    Weiss_filepath: string;

    deleted_MDS_filename: string;
    deleted_MDS_filepath: string;
    deleted_ITC_filename: string;
    deleted_ITC_filepath: string;
    deleted_Customer_filename: string;
    deleted_Customer_filepath: string;
    deleted_Weiss_filename: string;
    deleted_Weiss_filepath: string;

    ProductLineValidation: boolean = false;
    ProductModelValidation: boolean = false;
    QuantityValidation: boolean = false;
    MDSOptionValidation: boolean = false;
    SalesPriceValidation: boolean = false;
    SalesDiscountValidation: boolean = false;
    DiscountFromrange: number;
    Discounttorange: number;

}

export class CustomerDrpdnDetails {
    CustomerId: number;
    CustomerCode: string = "";
    IndustrySegment: string = "";
    CustomerType: string = "";
    CustomerCategory: string = "";
    CustomerClassification: string = "";
    Country: string = "";
    Region: string = "";
    EnquirySource: string = "";
    CustomerName: string = "";
    RegionId: number;
    Name: string = "";
    MailAddress: string = "";
    ContactNo: string = "";
}


export class Comments {
    DisplayStatus: string;
    CommentsBy: string;
    RoleName: string;
    Comments: string;
    CommentDate: Date;
    showAttachments: boolean = false;
}


export class ProductDrpdnDetails {
    ProductLine: SingleFieldModel[] = [];
    ProductModel: SingleFieldModel[] = [];
    MDSOptions: SingleFieldModel[] = [];
    SalesPrice: SingleFieldModel[] = [];
    EstimatedProductValue: SingleFieldModel[] = [];
}


export class EnquiryGridModel {
    EnquiryId: number;
    EnquiryNumber: string = "";
    EnquiryDate: any;
    RegionName: string = "";
    CustomerName: string = "";
    CustomerCode: string = "";
    EnquiryDescription: string = "";
    EnquiryStatus: string = "";
    EnquiryConfirmStatus: string = "";
    EmployeeGuestName: string = "";
    EnquirySource: string = "";
    CustomerClass: string = "";
    CustomerClassification: string = "";
    IndustrySegment: string = "";
    QuotationNumber: string = "";
}


export class EnquiryConfimrationDetails {
    POValue: any = "";
    EnquiryStatus: any;
    StandardReason: any = "";

}
export class ActivityModel {
    Pk_Id: number;
    EnquiryId: number;
    Activity: string = "";
    ActivityDate: any;
    ActivityDateISO: string = "";
    Attachment: any[] = [];
    isActivityAttachment: boolean;
    IsRowDeleted: boolean;
    fileName: any;
    filePath: any;
    Updates: any;
    ActionPlan: any;
    WOWUpdates: any;
    ActivityValidation: boolean = false;
    ActivityDateValidation: boolean = false;
    uniqueid: string = "";
    ISNewActivityAdded: boolean = false;
    AddedByUserName: string = "";
    AddedBy: string = "";
}

export class EnquiryPaginationGrid {
    pageNumber: number = 0;
    pageSize: number = 0;
    SearchText: string = "";
    direction: string = "";
    ColumnName: string = "";


    EnquiryDateFromFiter: string = null;
    EnquiryDateToFiter: string = null;
    EstimatedEnquiryValueFilter: string[] = [];
    SegmentFilter: string[] = [];
    MicroSegmentFilter: string[] = [];
    CusomterNameFilter: string[] = [];
    CusomterClassFilter: string[] = [];
    ProdcutFilter: string[] = [];
    QuotationValueToFilter: number = null;
    QuotationValueFromFilter: number = null;
    DiscountFromFilter: number = null;
    DiscounToFilter: number = null;
    QuotationStatusFilter: string[] = [];
    RegionFilter: string[] = [];
    InitiatorNameFilter: string[] = [];


}


export class EnquiryGridFilterList {
    EstimatedEnquiryValue: EnquiryGridDropDown[] = [];
    Segment: EnquiryGridDropDown[] = [];
    MicroSegment: EnquiryGridDropDown[] = [];
    OrignaleMicroSegment: EnquiryGridDropDown[] = [];
    CusomterName: EnquiryGridDropDown[] = [];
    CusomterClass: EnquiryGridDropDown[] = [];
    ProductModels: EnquiryGridDropDown[] = [];
    Region: EnquiryGridDropDown[] = [];
    QuotationStatus: string[] = ["Lost", "Open", "Won"];
    InitiatorName: EnquiryGridDropDown[] = [];
}
export class EnquiryGridDropDown {
    Fk_Name: string = "";
    Id: number = 0;
    Name: string = "";
}

export class EnquiryExportToExcelModel {

    // EnquiryNumber:string = "";
    // EnquiryDate: any;
    // CustomerName:string = ""; 
    // CustomerCode :string = "";
    // EstimatedEnquiryvalue :string = "";
    // Segment:string = "";
    // Micro_Segment :string = "";
    // Region :string = "";
    // CustomerClassification :string = "";
    // QuotationNumber :string = "";
    // Products :string = "";
    // Quotation_Amount :string = "";
    // Discount :string = "";
    // Quotation_Status :string = "";
    // Updates :string = "";
    // Action_Plan :string = "";
    // CRM_updates :string = "";
    EnquiryNumber: string = "";
    EnquiryDate: any;
    InitiatorName: string = "";
    CustomerName: string = "";
    CustomerCode: string = "";
    IndustrySegment: string = "";
    SubSegment: string = "";
    CustomerType: string = "";
    CustomerCategory: string = "";
    CustomerClassification: string = "";
    Country: string = "";
    Region: string = "";
    EnquirySource: string = "";
    EnquiryDescription: string = "";
    RFQNumber: string = "";
    RequestedDeliveryDate: any;
    KindAttentionPerName: string = "";
    YourRefPerName: string = "";
    RefDate: any;
    RFQRevisionNo: number = 0;
    EstimatedProductvalue: string = "";
    RFQTermsAndCondition: string = "";
    KindAttContactNo: string = "";
    KindAttEmailId: string = "";
    QuotationNumber: string = "";
    QuotationRevisionNo: number = 0;
    QuotationAmount: number = 0;
    QuotationDiscount: number = 0;
    QuotationFinalAmount: number = 0;
    QuotationSubmittedDate: any;
    POValue: number = 0;
    Quotation_Status: string = "";
    POSubmittedOn: any;
    OrdAcknowledgeNumber: string = "";
    SupplyChain_Type_Domestic_Export: string = "";
    TentativeDateOfDelivery: any;
    InvoiceNo: string = "";
    InvoiceSubmittedOn: any;
    EnquiryLostDate: any;
    WinLostReason: string = "";
    EnquiryStatus: string = "";

    ActivityType: string = "";
    Activity_Subject: string = "";
    ActivityStatus: string = "";
    ActivityPurpose: string = "";
    ActivityPurposeDesc: string = "";
    Activity_Outcome: string = "";
    Activity_OutcomeDesc: string = "";

    ProductLine: string = "";
    ProductFamily: string = "";
    ProductSeries: string = "";
    ProductModel: string = "";
    ProductModelPrice: number = 0;
    ProductVariant_1: string = "";
    ProductVariant_1Price: number = 0;
    ProductVariant_2: string = "";
    ProductVariant_2Price: number = 0;
    ProductVariant_3: string = "";
    ProductVariant_3Price: number = 0;
    ProductVariant_4: string = "";
    ProductVariant_4Price: number = 0;
    Quantity: number = 0;
    TotalAmount: number = 0;
    Discount: number = 0;
    FinalAmount: number = 0;
    POAmount: number = 0;
}

export class CustomTermsAndCondition {
    Enquiry_TnCId: any;
    StandardTC: number = null;
    StandardTCName: string = '';
    CustTCId: number = null;
    CustValue: string = '';
    CustomTCValidation: boolean = false;
    CustomValues: any[] = [];
}

export class CompetitorDetails {
    EnquiryId: number = 0;
    CompetitorName: any = "";
    ModelName: string = "";
    Price: number = null;
    IsRowDeleted: boolean;
    CompetitorNameValidation: boolean = false;
    ModelNameValidation: boolean = false;
    PriceValidation: boolean = false;
}

export class OptionsModel {
    ProductOptionId: any; //Quotation table primary key
    OptionId: any; // Options table primary key
    Name: any;
    Price: any;
    IsPriceOnRequest: boolean = false;
    optionValid: boolean = false;
    Option_Discount: any = 0;
    Option_FinalDiscount: any = 0;
}

export class CustomerServiceDetailModel {
    CustomerServiceName: string = "";
    CustomerServiceEmail: string = "";
    CustomerServiceContactNo: string = "";
}

// export class ManagementApprovalDetails {
//     ManagementApprovalId: number;
//     MngApprovalAttachments: any[] = [];
//     MngApprovalCommnents: string = "";
// }
// export class QuoteGenetationDetails {
//     QuoteGenerationId: number;
//     QuoteGenComments: string = "";
//     QuoteGenAttachments: any[] = [];
//     BOMAttachment: any[] = [];  //Bill of materials
// }
// export class EnquiryDetails {
//     EnquiryId: number;
//     EnquiryDescription: string = "";
//     Status: string = "";
//     EnquiryNumber: string = "";
//     EnquiryDate: Date;
// }
// export class SupplyChainDetails {
//     SupplyChainId: number;
//     SupplyChainType: number;
//     TentativeDeliveryDate: Date = new Date();
// }

