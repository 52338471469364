import { Component, OnInit, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-business-plan-chart',
  templateUrl: './business-plan-chart.component.html',
  styleUrls: ['./business-plan-chart.component.scss']
})
export class BusinessPlanChartComponent implements OnInit {

  @Input()
  showLoader: boolean = false;
  @Input()
  Year: number;
  @Input() region: number;
  @Input() currency: number;
  @Input() filterChanged: boolean;
  @Input() currencyCode: string;
  drpdnOption: string = "Amount";
  grapthOption: string = "BP";
  constructor(private toastr: ToastrService, private commonService: CommonService) { }

  ngOnInit() {
   
    this.businessPlanChart();

  }

  ngOnChanges(): void {
    if (this.filterChanged) this.businessPlanChart();
  }

  OnSelectChange() {
    this.businessPlanChart();
  }

  businessPlanChart() {

    this.showLoader = true;
    let queryString = "";
    queryString += "drpdnOption=" + this.drpdnOption;
    queryString += "&Year=" + this.Year;
    queryString += "&regionid=" + this.region;
    queryString += "&currency=" + this.currency;

    this.commonService.GetData("Dashboard", "BPSalesvsYTDChart", queryString).subscribe((data: any) => {
    //  am4core.options.onlyShowOnViewport = true;
      let chart = am4core.create("BusinessPlanChart", am4charts.XYChart);
      // Export
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
     // chart.scrollbarY.parent = chart.rightAxesContainer;
      // chart.cursor = new am4charts.XYCursor();
      chart.scrollbarX.exportable = false;
      chart.scrollbarY.exportable = false;
      chart.exporting.menu = new am4core.ExportMenu();
      let options = chart.exporting.getFormatOptions("pdf");
      options.addURL = false;
      chart.exporting.setFormatOptions("pdf", options);
      chart.exporting.filePrefix = "BPSales_YTD";
      /**************Custom Export**************** */
      chart.exporting.menu.items = [
        {
          "label": "...",
          "menu": [
            {
              "label": "Image",
              "menu": [
                { "type": "png", "label": "PNG" },
                { "type": "jpg", "label": "JPG" },
                // { "type": "svg", "label": "SVG" },
                { "type": "pdf", "label": "PDF" }
              ]
            }, {
              "label": "Data",
              "menu": [
                { "type": "xlsx", "label": "XLSX" },
              ]
            }, {
              "label": "Print", "type": "print"
            }
          ]
        }
      ];
      let CurrencyLabel = "";
      if (this.drpdnOption == "Amount") {
        // if (this.currency == 1) CurrencyLabel = "(USD)";
        // if (this.currency == 2) CurrencyLabel = "(EURO)";
        // if (this.currency == 3) CurrencyLabel = "(INR)";
        if (this.currencyCode == "USD") CurrencyLabel = "(USD)";
        if (this.currencyCode == "Euro") CurrencyLabel = "(EURO)";
        //if (this.currencyCode == "INR") CurrencyLabel = "(INR)";
        if (this.currencyCode == "VND") CurrencyLabel = "(VND)";
        if (this.currencyCode == "IDR") CurrencyLabel = "(IDR)";
        if (this.currencyCode == "PHP") CurrencyLabel = "(PHP)";
        if (this.currencyCode == "THB") CurrencyLabel = "(THB)";
        if (this.currencyCode == "MYR") CurrencyLabel = "(MYR)";
        if (this.currencyCode == "SGD") CurrencyLabel = "(SGD)";
      }
      else {
        CurrencyLabel = "(No's)";
      }
      chart.exporting.dataFields = {
        "category": "Month",
        "valueL1": "BusinessPlan" + CurrencyLabel,
        "valueL2": "YTD" + CurrencyLabel,
        "valueL3": "FC1" + CurrencyLabel,
        "valueL4": "FC2" + CurrencyLabel,
        "valueL5": "FC3" + CurrencyLabel,
      }
      /**************End Custom Export**************** */

      let title = chart.titles.create();
      title.text = "BP Sales vs YTD";
      title.fontSize = 15;
      title.fontWeight = "bold";
      title.marginBottom = 30;

      chart.data = data;

      /* Create axes */
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.minGridDistance = 30;

    

      /* Create value axis */
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis()); valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
        var val = text == "0.00" ? "0" : text;
        return val;
      });
        /**************Scrolling Start End Point**************** */
        chart.events.on("beforedatavalidated", function(ev) {
          categoryAxis.start = 0;
          categoryAxis.end = 0.5;
          valueAxis.start = 0;
          valueAxis.end = 1;
          });
          /**************End Scrolling Start End Point**************** */
      if (this.drpdnOption == 'Units') {
        valueAxis.title.text = "No.";
        chart.numberFormatter.numberFormat = "0";
        valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
          var val = parseFloat(text) % 1 != 0 ? "" : parseInt(text).toString();
          return val;
        });
      }
      else {
        // if (this.currency == 1) valueAxis.title.text = "USD";
        // if (this.currency == 2) valueAxis.title.text = "EURO";
        // if (this.currency == 3) valueAxis.title.text = "INR";
        if (this.currencyCode == "USD") valueAxis.title.text = "USD";
        if (this.currencyCode == "Euro") valueAxis.title.text = "EURO";
        //if (this.currencyCode == "INR") valueAxis.title.text = "INR";
        if (this.currencyCode == "VND") valueAxis.title.text = "VND";
        if (this.currencyCode == "IDR") valueAxis.title.text = "IDR";
        if (this.currencyCode == "PHP") valueAxis.title.text = "PHP";
        if (this.currencyCode == "THB") valueAxis.title.text = "THB";
        if (this.currencyCode == "MYR") valueAxis.title.text = "MYR";
        if (this.currencyCode == "SGD") valueAxis.title.text = "SGD";
       // chart.numberFormatter.numberFormat = "0.00";
      }
      valueAxis.title.fontWeight = "bold";
      valueAxis.title.fontSize = "16px";
      valueAxis.min = 0;
      valueAxis.extraMax = 0.5;
      valueAxis.events.on("ready", function (ev) {
        ev.target.min = ev.target.min;
        ev.target.max = ev.target.max;
        if (ev.target.max < 0.1) valueAxis.extraMax = 10;
        if (ev.target.max > 0.1 && ev.target.max < 1) valueAxis.extraMax = 5;
        if (ev.target.max > 1) valueAxis.extraMax = 0.2;
        if (ev.target.max == undefined) valueAxis.extraMax = 0.2;
      })


      if (this.grapthOption == 'BP') {
        title.text = "Business Plan vs YTD";
        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.name = "Business Plan";
        lineSeries.dataFields.valueY = "valueL1";
        lineSeries.dataFields.categoryX = "category";
        lineSeries.tooltip.getFillFromObject = false;
        lineSeries.tooltip.background.fill = am4core.color("#4a463c");
        //lineSeries.stroke = am4core.color("#4a463c");   //For line or column color

        let bullet = lineSeries.bullets.push(new am4charts.Bullet());
        bullet.fill = am4core.color("#4a463c"); // tooltips grab fill from parent by default
        bullet.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
        let circle = bullet.createChild(am4core.Circle);
        circle.radius = 4;
        circle.fill = am4core.color("#fff");
        circle.strokeWidth = 3;

        let labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueL1}";
        labelBullet.label.verticalCenter = "bottom";
      }


      




      /********************************************************************************************************** */
      /********************************************************************************************************** */

      if (this.grapthOption == 'FC1') {
        title.text = "FC1 vs YTD";
        let lineSeries3 = chart.series.push(new am4charts.LineSeries());
        lineSeries3.name = "FC1";
        lineSeries3.dataFields.valueY = "valueL3";
        lineSeries3.dataFields.categoryX = "category";
        lineSeries3.tooltip.getFillFromObject = false;
        lineSeries3.tooltip.background.fill = am4core.color("#4a463c");
        // lineSeries3.stroke = am4core.color("#80ff80"); //For line or column color

        let bullet3 = lineSeries3.bullets.push(new am4charts.Bullet());
        bullet3.fill = am4core.color("#4a463c"); // tooltips grab fill from parent by default
        bullet3.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
        let circle3 = bullet3.createChild(am4core.Circle);
        circle3.radius = 4;
        circle3.fill = am4core.color("#fff");
        circle3.strokeWidth = 3;

        let labelBullet3 = lineSeries3.bullets.push(new am4charts.LabelBullet());
        labelBullet3.label.text = "{valueL3}";
        labelBullet3.label.verticalCenter = "bottom";
      }
      if (this.grapthOption == 'FC2') {
        title.text = "FC2 vs YTD";
        let lineSeries4 = chart.series.push(new am4charts.LineSeries());
        lineSeries4.name = "FC2";
        lineSeries4.dataFields.valueY = "valueL4";
        lineSeries4.dataFields.categoryX = "category";
        lineSeries4.tooltip.getFillFromObject = false;
        lineSeries4.tooltip.background.fill = am4core.color("#4a463c");
        // lineSeries4.stroke = am4core.color("#4933FF"); //For line or column color

        let bullet4 = lineSeries4.bullets.push(new am4charts.Bullet());
        bullet4.fill = am4core.color("#4a463c"); // tooltips grab fill from parent by default
        bullet4.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
        let circle4 = bullet4.createChild(am4core.Circle);
        circle4.radius = 4;
        circle4.fill = am4core.color("#fff");
        circle4.strokeWidth = 3;

        let labelBullet4 = lineSeries4.bullets.push(new am4charts.LabelBullet());
        labelBullet4.label.text = "{valueL4}";
        labelBullet4.label.verticalCenter = "bottom";
      }
      if (this.grapthOption == 'FC3') {
        title.text = "FC3 vs YTD";
        let lineSeries5 = chart.series.push(new am4charts.LineSeries());
        lineSeries5.name = "FC3";
        lineSeries5.dataFields.valueY = "valueL5";
        lineSeries5.dataFields.categoryX = "category";
        lineSeries5.tooltip.getFillFromObject = false;
        lineSeries5.tooltip.background.fill = am4core.color("#4a463c");
        //lineSeries5.stroke = am4core.color("#D914B8"); //For line or column color

        let bullet5 = lineSeries5.bullets.push(new am4charts.Bullet());
        bullet5.fill = am4core.color("#4a463c"); // tooltips grab fill from parent by default
        bullet5.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
        let circle5 = bullet5.createChild(am4core.Circle);
        circle5.radius = 4;
        circle5.fill = am4core.color("#fff");
        circle5.strokeWidth = 3;

        let labelBullet5 = lineSeries5.bullets.push(new am4charts.LabelBullet());
        labelBullet5.label.text = "{valueL5}";
        labelBullet5.label.verticalCenter = "bottom";
      }




      /********************************************************************************************************** */
      /********************************************************************************************************** */
      let lineSeries2 = chart.series.push(new am4charts.LineSeries());
      lineSeries2.name = "YTD";
      lineSeries2.dataFields.valueY = "valueL2";
      lineSeries2.dataFields.categoryX = "category";
      lineSeries2.tooltip.getFillFromObject = false;
      lineSeries2.tooltip.background.fill = am4core.color("#4a463c");
      lineSeries2.stroke = am4core.color("#ff4d4d"); //For line or column color

      let bullet2 = lineSeries2.bullets.push(new am4charts.Bullet());
      bullet2.fill = am4core.color("#ff4d4d"); // tooltips grab fill from parent by default
      bullet2.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
      let circle2 = bullet2.createChild(am4core.Circle);
      circle2.radius = 4;
      circle2.fill = am4core.color("#fff");
      circle2.strokeWidth = 3;

      let labelBullet2 = lineSeries2.bullets.push(new am4charts.LabelBullet());
      labelBullet2.label.text = "{valueL2}";
      labelBullet2.label.verticalCenter = "bottom";


      chart.legend = new am4charts.Legend();

      this.showLoader = false;
    }, error => {
      this.showLoader = false;

    });
  }
}
