import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModalComponent } from '../charts-modal/charts-modal.component';
import { UserRoles } from 'src/app/UserRoles';
import { AppGlobals } from 'src/app/app.global';
import { ExportToExcelService } from 'src/app/Service/exportToexcel.service';
import { UnsubscriptionError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { EnquiryDetailsChartComponent } from './enquiry-details-chart/enquiry-details-chart.component';
import { AppConfigService } from 'src/app/Service/app-config.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @Input()
  Year: number = new Date().getFullYear();

  @Input()
  showLoader: boolean = false;
  YearList: any[] = [];
  currYear: number = new Date().getFullYear();
  EnquiryChartData: any;
  closeResult: string;
  role: string;
  drpdnOption: string = "Amount";
  setCurrency: number = 2;
  setCurrencyCode : string = "";
  setRegion: number = 0;

  AppRoles = UserRoles;
  opportunitySum: number = 0;
  rfqSum: number = 0;
  quotationSum: number = 0;
  orderintkaeSum: number = 0;
  salesSum: number = 0; appgl
  CurrencyList: any[] = [];
  RegionList: any;
  exprtexcellist: any[];
  excelExportHeaderList: any[];
  currencyname: string = 'EURO';
  regionLocked: boolean;
  filterChanged: boolean = false;
  IsOnInit: boolean;
  @ViewChild('fiterElement') fiterElement: ElementRef;
  constructor(private toastr: ToastrService, private modalService: NgbModal, private commonService: CommonService,

    private excelexportservice: ExportToExcelService, private route: ActivatedRoute, private titleService: Title,
    private appConfigService: AppConfigService) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    localStorage.setItem('ViewType', 'dashboardComponent');
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
   // this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.showLoader = true;
    this.IsOnInit = true;
    window.scroll(0, 0);
    this.role = localStorage.getItem("UserRole");
    if(this.role=="1"){
      this.getRegionList();
    }else{
      this.getUserProfileData();
    }

    // if (this.role == UserRoles.SalesEngineer || this.role == UserRoles.RegionalSalesManager || this.role == UserRoles.AreaSalesManager
    //   || this.role == UserRoles.Distributor || this.role == UserRoles.ChannelPartner || this.role == UserRoles.Agent) {
    //   this.regionLocked = true;
    //   this.commonService.GetUserProfileData('Account', 'GetUserProfileInfo').subscribe((data: any) => {
    //     localStorage.setItem("RegionID", data.ListRegion);
    //     this.setRegion = parseInt(localStorage.getItem("RegionID"));
    //     setTimeout(() => {
    //       this.fiterElement.nativeElement.focus();
    //     }, 0);
    //   }, error => {
    //     this.toastr.error("Failed to load data!");
    //   });
    // }
    // else {
    //   this.regionLocked = false;
    // }

    this.commonService.RefreshHeaderFun();
    //this.YearList = AppGlobals.YearsConstant;
    let k = 1;
    for (let i = 2018; i <= this.currYear; i++) {
        let batch =i;
        this.YearList.push(batch);
        k++;
    }
    this.getCurrencyList();
    this.getEnquiryChartData();
  }

  ngOnChanges(): void {
    this.getEnquiryChartData();
  }

  OnSelectChange() {
    if (this.drpdnOption == 'Amount') {
      // if (this.setCurrency == 1) this.currencyname = 'USD';
      // if (this.setCurrency == 2) this.currencyname = 'EURO';
      // if (this.setCurrency == 3) this.currencyname = 'INR';
      this.setCurrencyCode = this.CurrencyList.filter(x => x.Id == this.setCurrency)[0]?.CurrencyCode;
      if (this.setCurrencyCode == "USD" ) this.currencyname = 'USD';
      if (this.setCurrencyCode == "Euro") this.currencyname = 'EURO';
      // if (this.setCurrencyCode == "INR") this.currencyname = 'INR';
      if (this.setCurrencyCode == "VND") this.currencyname = 'VND';
      if (this.setCurrencyCode == "IDR") this.currencyname = 'IDR';
      if (this.setCurrencyCode == "PHP") this.currencyname = 'PHP';
      if (this.setCurrencyCode == "THB") this.currencyname = 'THB';
      if (this.setCurrencyCode == "MYR") this.currencyname = 'MYR';
      if (this.setCurrencyCode == "SGD") this.currencyname = 'SGD';
    
    }
    else {
      this.currencyname = "No's"
    }

    this.getEnquiryChartData();

  }

  getEnquiryChartData() {
    this.showLoader = true;
    let queryString = "";
    queryString += "drpdnOption=" + this.drpdnOption;
    queryString += "&Year=" + this.Year;
    queryString += "&regionid=" + this.setRegion;
    queryString += "&currency=" + this.setCurrency;

    this.commonService.GetData("Dashboard", "EnquiryChart", queryString).subscribe((data: any) => {
      this.EnquiryChartData = data;
      this.showLoader = false;
    }, error => {
      this.showLoader = false;

    });

  }

  // getYearList() {
  //   this.commonService.GetData("Dashboard", "GetYearList", null).subscribe((data: any) => {
  //     this.YearList = data;
  //     var check = this.YearList.filter(x=>x==new Date().getFullYear())[0];
  //     if (check.length==0) this.YearList.push(new Date().getFullYear());
  //   },
  //     error => {
  //       this.toastr.error("Failed to load year list!");
  //       this.showLoader = false;
  //     });
  // }
  getRegionList() {
    this.commonService.GetData("Region", "GetAll", null).subscribe((data: any) => {
      this.RegionList = data;
      this.IsOnInit = false;
    },
      error => {
        this.toastr.error("Failed to load region list!");
        this.showLoader = false;
      });
  }
  getCurrencyList() {
    this.commonService.GetData("Currency", "GetAll", null).subscribe((data: any) => {
      this.CurrencyList = data;
      this.setCurrency = this.CurrencyList.filter(x => x.Name == 'Euro (€)')[0]?.Id;
      this.setCurrencyCode = this.CurrencyList.filter(x => x.Name == 'Euro (€)')[0]?.CurrencyCode;
      this.IsOnInit = false;
    },
      error => {
        this.toastr.error("Failed to load currency list!");
        this.showLoader = false;
      });
  }

  yearChange(event: any) {
    if (this.IsOnInit) this.filterChanged = false;
    else this.filterChanged = true;
    this.getEnquiryChartData();
  }
  regionChange() {
    if (this.IsOnInit) this.filterChanged = false;
    else this.filterChanged = true;
    this.getEnquiryChartData();
  }
  currencyChange() {
    if (this.drpdnOption == 'Amount') {
      // if (this.setCurrency == 1) this.currencyname = 'USD';
      // if (this.setCurrency == 2) this.currencyname = 'EURO';
      // if (this.setCurrency == 3) this.currencyname = 'INR';
      this.setCurrencyCode = this.CurrencyList.filter(x => x.Id == this.setCurrency)[0]?.CurrencyCode;
      if (this.setCurrencyCode == "USD" ) this.currencyname = 'USD';
      if (this.setCurrencyCode == "Euro") this.currencyname = 'EURO';
      // if (this.setCurrencyCode == "INR") this.currencyname = 'INR';
      if (this.setCurrencyCode == "VND") this.currencyname = 'VND';
      if (this.setCurrencyCode == "IDR") this.currencyname = 'IDR';
      if (this.setCurrencyCode == "PHP") this.currencyname = 'PHP';
      if (this.setCurrencyCode == "THB") this.currencyname = 'THB';
      if (this.setCurrencyCode == "MYR") this.currencyname = 'MYR';
      if (this.setCurrencyCode == "SGD") this.currencyname = 'SGD';
    }
    else {
      this.currencyname = "No's"
    }
    this.filterChanged = true;
    this.getEnquiryChartData();
  }

  openFunnelChartPopup(regionObj) {
    if (this.drpdnOption == "Amount") {
      // if (this.setCurrency == 1) regionObj.Label = 'USD';
      // if (this.setCurrency == 2) regionObj.Label = 'EURO';
      // if (this.setCurrency == 3) regionObj.Label = 'INR';
      if (this.setCurrencyCode == "USD" ) regionObj.Label = 'USD';
      if (this.setCurrencyCode == "Euro") regionObj.Label = 'EURO';
      // if (this.setCurrencyCode == "INR") regionObj.Label = 'INR';
      if (this.setCurrencyCode == "VND") regionObj.Label = 'VND';
      if (this.setCurrencyCode == "IDR") regionObj.Label = 'IDR';
      if (this.setCurrencyCode == "PHP") regionObj.Label = 'PHP';
      if (this.setCurrencyCode == "THB") regionObj.Label = 'THB';
      if (this.setCurrencyCode == "MYR") regionObj.Label = 'MYR';
      if (this.setCurrencyCode == "SGD") regionObj.Label = 'SGD';
    }
    else {
      regionObj.Label = "No.";
    }
    const modalRef = this.modalService.open(ChartsModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });
    modalRef.componentInstance.region = regionObj;
    modalRef.result.then((result) => {
      this.closeResult = `Closed with:${result}`;
      if (this.closeResult !== 'Closed with:Close click') {
        // if (flag === 1) {
        // } else {
        //   //  this.saveQuoteLimit(this.QuotationNumber, result);
        // }
      }
    });
  }

  export() {
    this.showLoader = true;
    const params = {
      skipHeader: false,
      columnGroups: false,
      skipFooters: false,
      skipGroups: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: false,
      fileName: 'demo_File',
      // columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.exprtexcellist = [];
    this.excelExportHeaderList = [];
    const data = AppGlobals.dummyReportData;
    this.excelexportservice.generateExcelwithdata(data, AppGlobals.excelCoulumns, 'demo_file');
    this.showLoader = false;
  }

  exporttoexcel(node, index) {
    const row = {};
    let headerlist = AppGlobals.excelCoulumns;
    headerlist.every(function (value, index1, _arr) {
      const tmp = {};
      this.excelExportHeaderList.push(value.field);
      row[value.field] = node.data[value.field];
      let id = node.data["id"];
      tmp['value'] = node.data[value.field];
      tmp['color'] = this.getColorForExcelExport(value.field, node.data[value.field], id);
      row[value.field] = tmp;
      return true;
    }.bind(this));
    this.exprtexcellist.push(row);
  }

  getUserProfileData() {
    this.commonService.GetUserProfileData('Account', 'GetUserProfileInfo').subscribe((data: any) => {
      this.RegionList = data.ListRegion;
      //this.IsOnInit = false;
    }, error => {
      this.toastr.error("Failed to load data!");

    });
  }

}
