import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AppGlobals } from 'src/app/app.global';
import { RegisterModel } from '../Model/RegisterModel';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderComponent } from '../loader/loader.component';


@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  login(data: RegisterModel): any {
    var logindata = "username=" + data.Email + "&password=" + data.Password + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.http.post(AppGlobals.baseAppUrl + 'Token', logindata, { headers: reqHeader });
  }

  forgotPassword(Username) {
  const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
   return this.http.post(AppGlobals.baseAppUrl + 'api/Account/ForgotPassword', {"Username":Username} ,{ headers: reqHeader });
  }

  getImageName(ControllerName: string, ActionName: String) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
     return this.http.get(AppGlobals.baseAppUrl+"api/"+ControllerName+"/"+ActionName,{ headers: reqHeader });

  }
  

}
