<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Customer Frame Agreement Listing
                                    </h3>
                                </div>
                            </div>
                            <div class="m-portlet__head-tools" >
                        
                                <ul class="m-portlet__nav">
                                    <li class="m-portlet__nav-item">
                                        <a class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                             routerLink="/AddContractFrameAgreement/0"
                                            
                                            style="white-space: normal;word-wrap: break-word;">Add Customer Frame Agreement</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="table-responsive">
                                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 right">&nbsp;</div>
                                        <div class="col-sm-12 col-md-6 right">
                                            <mat-form-field style="width: 50%;" class="pull-right">

                                                <mat-label>Search</mat-label>
                                                <input matInput (keyup)="SearchParam($event)" name="searchtext"
                                                    [(ngModel)]="SearchText" #searchtext="ngModel"
                                                    (keypress)="AvoidSpace($event)" maxlength="50" placeholder="Search"
                                                    #input>
                                            </mat-form-field>

                                        </div>

                                    </div>
                                    <div class="mat-elevation-z8">
                                        
                                            <table mat-table [dataSource]="dataSource" matSort
                                                (matSortChange)="sortData($event)"
                                                class="table table-striped- table-bordered table-hover dataTable no-footer"
                                                role="grid">
                                                <ng-container matColumnDef="Companyname">
                                                    
                                                    <div>
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Customer Name </th>
                                                        <td mat-cell *matCellDef="let row of dataSource"> {{row.Companyname}}
                                                        </td>
                                                    </div>

                                                </ng-container>

                                                <ng-container matColumnDef="Value">
                                                    <div >
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        WorkFlow Status </th>
                                                     <td mat-cell
                                                        *matCellDef="let row" >
                                                        {{row.Value}} </td>
                                                    </div>
                                                 </ng-container>
                                            
                                            <ng-container matColumnDef="Action">
                                              
                                                <th mat-header-cell *matHeaderCellDef> Action </th>
                                                
                                                <div>
                                                <td mat-cell *matCellDef="let row">
                                                    <a mat-icon-button color="accent"
                                                        [routerLink]="['/EditViewContractFrameAgreement',row?.FK_CustomerId]"
                                                        routerLinkActive="active" title="View">
                                                        <i class="fa fa-eye fa-lg" aria-hidden="true"></i>
                                                    </a>&nbsp;&nbsp;&nbsp;&nbsp;

                                                    <mat-slide-toggle   [hidden]="!IsCustomerFrameAgreementEditable"
                                                        class="example-margin" [checked]="row.Status"
                                                        [disabled]="disabled"
                                                        (change)="UpdateStatusById(row.FK_CustomerId,row.Status)">
                                                    </mat-slide-toggle>&nbsp;
                                                    {{row.Status==true ? 'Active': 'Inactive'}}
                                                </td>
                                            </div>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                           
                                            </table>
                                        

                                        <div *ngIf="dataSource != undefined && (dataSource.filteredData == null || dataSource.filteredData?.length === 0)"
                                            class="text-center">No records found</div>

                                        <mat-paginator [(length)]="length" [(pageIndex)]="pageIndex"
                                            [(pageSize)]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                                            (page)="pageEvent($event)">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>