import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { SiteActivityLogModel, SiteActivityLogGridFilterList, SiteActivityPaginationGrid } from 'src/app/Model/SiteActivityLogModel';
import { AppConfigService } from 'src/app/Service/app-config.service';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-site-activity',
  templateUrl: './site-activity.component.html',
  styleUrls: ['./site-activity.component.scss']
})
export class SiteActivityComponent implements OnInit {

  minDate: NgbDate;
  maxDate: NgbDate;
  SiteActivityLogGridFilterList: SiteActivityLogGridFilterList = new SiteActivityLogGridFilterList();

  EnquiryngbDateFromFiter: NgbDate | null;
  EnquiryngbDateToFiter: NgbDate | null;
  hoveredDate: NgbDate | null = null;

 
  displayedColumns: string[] = ['Name', 'Http_Method', 'Route', 'CreatedOn'];
  dataSource: MatTableDataSource<SiteActivityLogModel>;
  UserRole: any;

  /*************************************************************************
     Server side Pagination purpose
 *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';
  ColumnName: string = 'CreatedOn';
  @Output('matSortChange') sortChange: EventEmitter<Sort>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  PaginationModel: SiteActivityPaginationGrid = new SiteActivityPaginationGrid();
  /*************************************************************************
     End here
  *************************************************************************/

  @Input()
  showLoader: boolean = false;
  data: SiteActivityLogModel[] = [];

  constructor(private toastr: ToastrService, private Service: CommonService, private route: ActivatedRoute, private titleService: Title,
    private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private cd: ChangeDetectorRef,
    private appConfigService : AppConfigService) { }

  ngOnInit() {
    this.Service.RefreshHeaderFun();
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    // this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.showLoader = true;
   
    this.UserRole = localStorage.getItem("UserRole");
    this.GetAllSiteActivityGridFilterList()
    this.GetAllSiteActivityLogDetails();
  }

 

  GetAllSiteActivityGridFilterList()
  {
    this.Service.GetData("SiteActivityLog", "SiteActivityGridFilterList", null).subscribe((data: any) => {
     
      this.SiteActivityLogGridFilterList.InitiatorName = data.InitiatorName;

    });
  }

  GetAllSiteActivityLogDetails() {
    this.showLoader = true;

    this.PaginationModel.pageNumber = this.pageNumber;
    this.PaginationModel.pageSize = this.pageSize; 
    this.PaginationModel.direction = this.direction;
    this.PaginationModel.ColumnName = this.ColumnName;


    if (this.EnquiryngbDateFromFiter != undefined && this.EnquiryngbDateFromFiter != null) {
      this.PaginationModel.EnquiryDateFromFiter = (this.ConvertNgbDateToDate(this.EnquiryngbDateFromFiter)).toDateString();
    }
    else {
      this.PaginationModel.EnquiryDateFromFiter = null;
    }

    if (this.EnquiryngbDateToFiter != undefined && this.EnquiryngbDateToFiter != null) {
      this.PaginationModel.EnquiryDateToFiter = (this.ConvertNgbDateToDate(this.EnquiryngbDateToFiter)).toDateString();
    }
    else {
      this.PaginationModel.EnquiryDateToFiter = null;
    }

      this.Service.PostData('SiteActivityLog', 'GetAllSiteActivityLogs', this.PaginationModel, null).subscribe((data: any) => {
      this.data = data.Data;
      this.length = data.Total;
      this.dataSource = new MatTableDataSource(this.data);
      
      this.showLoader = false;

    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }


  pageEvent(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAllSiteActivityLogDetails();
  }

  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAllSiteActivityLogDetails();
    }
  }

  /************************Date Range*************************************** */

  onDateSelection(date: NgbDate) {
    if (!this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter) {
      this.EnquiryngbDateFromFiter = date;
    } else if (this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter && date && date.after(this.EnquiryngbDateFromFiter)) {
      this.EnquiryngbDateToFiter = date;
    } else {
      this.EnquiryngbDateToFiter = null;
      this.EnquiryngbDateFromFiter = date;
    }
  }

  isRange(date: NgbDate) {
    return date.equals(this.EnquiryngbDateFromFiter) || (this.EnquiryngbDateToFiter && date.equals(this.EnquiryngbDateToFiter)) || this.isInside(date) || this.isHovered(date);
  }

  isHovered(date: NgbDate) {
    return this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter && this.hoveredDate && date.after(this.EnquiryngbDateFromFiter) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.EnquiryngbDateToFiter && date.after(this.EnquiryngbDateFromFiter) && date.before(this.EnquiryngbDateToFiter);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  /************************End Date Range*************************************** */

  
  OnFromDateFilterChange() {
    let EnquiryDateFromFiter = this.ConvertNgbDateToDate(this.EnquiryngbDateFromFiter)
    let EnquiryDateToFiter = this.ConvertNgbDateToDate(this.EnquiryngbDateToFiter)
    if (EnquiryDateToFiter < EnquiryDateFromFiter) {
      this.EnquiryngbDateToFiter = this.EnquiryngbDateFromFiter;
      this.cd.detectChanges();

    }   
  }

  OnToDateFilterChange() {

    //  this.GetAllEnquiry();
  }
 
  ConvertNgbDateToDate(ngbdate: any) {
    if (ngbdate != null) {
      return new Date(ngbdate.year, ngbdate.month - 1, ngbdate.day)
    }

  }

  ResetAllFilters() {
    this.PaginationModel.EnquiryDateFromFiter = null;
    this.PaginationModel.EnquiryDateToFiter = null;
    
    this.PaginationModel.InitiatorNameFilter = [];
    this.EnquiryngbDateFromFiter = null;
    this.EnquiryngbDateToFiter = null;

    this.GetAllSiteActivityLogDetails();

  }
}
