<div
  class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-content">
      <div class="row">

        <div class="col-sm-12">
          <div class="m-portlet">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    Bulk Edit
                  </h3>
                </div>
              </div>
            </div>



            <div class="mat-elevation-z8">
              <div class="m-portlet__head-tools">
                <ul class="m-portlet__nav" style="list-style-type: none;">
                  <li class="m-portlet__nav-item">
                    <br>
                    <a class="btn btn-primary m-btn m-btn--air m-btn--custom" (click)="addRow()">Add
                      Activities
                    </a>
                  </li>
                </ul>
              </div>

              <b style="padding-left: 13px;margin-right: 15px;">Bulk Edit</b>
              <mat-slide-toggle class="example-margin" [(ngModel)]="isbulkEdit" (change)="bulkEdit()">
              </mat-slide-toggle>&nbsp;

              <div class="row">
                <div class="col-sm-12">
                  <div class="m-portlet">

                    <div style="padding-left: 17px; padding-right: 15px;  margin-top: 17px">

                      <form #addActivityForm="ngForm" (ngSubmit)="onSubmit(filterActivityForm)" novalidate>
                        <div class="form-group m-form__group row">
                          <div class="col">
                            <label class="form-control-label" class="lable-size">Activity Type:</label>
                            <select class="form-control" id="ActivityTypeId" name="ActivityTypeId"
                              [(ngModel)]="filterActivityForm.ActivityTypeId">
                              <option [ngValue]="null" selected>select</option>
                              <option *ngFor="let item of activityTypeMaster" [value]="item.ActivityTypeId">
                                {{ item.ActivityTypeDesc }}
                              </option>
                            </select>
                          </div>


                          <div class="col">
                            <label for="activity-status-filter" class="lable-size">Status:</label>

                            <select class="form-control" id="ActivityStatusId" name="ActivityStatusId"
                              [(ngModel)]="filterActivityForm.ActivityStatusId">
                              <option [ngValue]="null" selected>select</option>
                              <option *ngFor="let item of activityStatusByTypeMaster " value="{{ item.Id }}">
                                {{ item.ActivityStatusDesc }}
                              </option>
                            </select>
                          </div>


                          <div class="col">
                            <label for="priority-filter" class="lable-size">Subject:</label>
                            <input class="form-control" name="Subject" [(ngModel)]="filterActivityForm.Subject"
                              placeholder="subject"   #Subject (keydown)="onKeyDown($event)" type="text" />
                          </div>


                          <div Class="col">
                            <label for="participant" class="lable-size">Participant:</label>
                            <select name="participant" class="form-control"
                              [(ngModel)]="filterActivityForm.ParticipantId">
                              <option value=undefined selected>select</option>
                              <option *ngFor="let p of allUserMaster" [value]="p.Id"> {{ p.Name }} </option>
                            </select>
                          </div>

                          <!-- <div Class="col">
                            <label for="StartDate" class="lable-size">Date:</label>
                            <input class="form-control" name="StartDate" [(ngModel)]="filterActivityForm.StartDate"
                              type="date" />
                          </div> -->


                          <div class="datepickclear">
                            <div class="input-group">
                              <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                                [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                                outsideDays="hidden" [startDate]="EnquiryngbDateFromFiter!" tabindex="-1" hidden [minDate]="minDate"
                                [maxDate]="maxDate" [footerTemplate]="footerTemplate">

                              <ng-template #t let-date let-focused="focused">
                                <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                                  [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                                  (mouseleave)="hoveredDate = null">
                                  {{ date.day }}
                                </span>
                              </ng-template>
                            </div>
                          </div>




                          <div class="col-md-2">
                            <label class="form-control-label" class="lable-size">Date:</label>
                            <div class="form-group  m-form__group ">
                              <div class="input-group">
                                <input readonly #dpFromDate class="form-control" placeholder="From" name="dpFromDate"
                                  [value]="formatter.format(EnquiryngbDateFromFiter)"
                                  (input)="EnquiryngbDateFromFiter = validateInput(EnquiryngbDateFromFiter, dpFromDate.value)"
                                  (click)="datepicker.toggle()">
                                <input readonly #dpToDate class="form-control" placeholder="To" name="dpToDate"
                                  [value]="formatter.format(EnquiryngbDateToFiter)"
                                  (input)="EnquiryngbDateToFiter = validateInput(EnquiryngbDateToFiter, dpToDate.value)"
                                  (click)="datepicker.toggle()">
                              </div>
                            </div>
                          </div>
                          <ng-template #footerTemplate>
                            <hr class="my-0">
                            <button class="btn btn-primary btn-sm m-2 float-right" (click)="datepicker.toggle()">Ok</button>
                          </ng-template>




                          <div class="col">
                            <label class="lable-size">Entity Type:</label>
                            <select class="form-control" name="EntityTypeId"
                              [(ngModel)]="filterActivityForm.EntityTypeId"
                              (ngModelChange)="onEntityTypeChange(filterActivityForm.EntityTypeId)" required>
                              <option [ngValue]="null" selected>select</option>
                              <option *ngFor=" let s of allEntityTypesMaster " [value]="s.Id">
                                {{ s.EntityType }}
                              </option>
                            </select>
                          </div>

                          <div class="col" style="margin-top: 25px;">
                            <select class="form-control" name="EntityId" [(ngModel)]="filterActivityForm.EntityId"
                              required>
                              <option [ngValue]="null" selected>select</option>
                              <option *ngFor=" let c of entityTypeFilterArray" [value]="c.EntityId">
                                {{ c.EntityName }}
                              </option>
                            </select>
                          </div>

                          <div class="col">
                            <br>
                            <mat-checkbox id="isOwner" [(ngModel)]="filterActivityForm.IsOwner" name="isOwner">
                              <label for="isOwner" class="lable-size">I am Owner</label>
                            </mat-checkbox>

                          </div>

                          <div class="col">
                            <br>
                            <button class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next">Filter
                            </button>
                          </div>

                          <div class="col">
                            <br>
                            <button type="button" (click)="removeFilter()"
                              class="btn btn-metal m-btn m-btn--air m-btn--custom">Clear </button>
                          </div>
                        </div>
                      </form>


                      <table *ngIf="filteredActivities?.length" class="table table-striped- dataTable no-footer"
                        role="grid">
                        <thead>
                          <tr>

                            <th>Activity Type</th>
                            <th>Customer</th>
                            <th>Participants</th>
                            <th>Subject</th>
                            <th>Due Date</th>
                            <th>Activity Status</th>
                            <th>Delayed By</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr list-activity-row *ngFor="let activityRow of filteredActivities; ; let i=index"
                            (refreshGridParent)="refreshGrid(filterActivityForm)" [activity]="activityRow" [index]="i"
                            (dataEvent)="removeByIndex($event)">
                          </tr>
                        </tbody>
                      </table>

                      <div *ngIf="filteredActivities?.length <= 0">
                        <h5 style="padding: 50px 20px;">No Records found</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="pageEvent($event)"
                [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
