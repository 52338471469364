import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmployeeGuestModel } from 'src/app/Model/EmployeeGuestModel';
import { CommonService } from 'src/app/Service/common.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';
@Component({
  selector: 'app-edit-view-guest',
  templateUrl: './edit-view-guest.component.html',
  styleUrls: ['./edit-view-guest.component.scss']
})

export class EditViewGuestComponent implements OnInit {
  GuestDetails: EmployeeGuestModel = new EmployeeGuestModel();
  isEditable: boolean = true;
  FormStatus: string = "View";
  emailPattern: string = "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  // passPattern: any = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,30}$/;
  passPattern: any = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,30}';
  isValidEmail: boolean = false;
  Country: any[] = [];
  Roles: any[] = [];
  Region: any[] = [];
  charCode: any;
  UserRole: any;
  isGuestEditable: boolean = false;
  mailIdFormat : any;

  @Input()
  showLoader: boolean = false;
  Passhide: boolean = true;
  ConfirmPasshide: boolean = true;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];


  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private Service: CommonService, public dialog: MatDialog, private titleService: Title,private accessservice: AccessPermissionService,
    private appConfigService : AppConfigService) {
      // this.GuestDetails.Region = []; // Initialize Region as an empty array
     }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    this.mailIdFormat = appConfig.mailIdFormat;
    //this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
    //this.isGuestEditable = AppGlobals.GuestMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.GuestDetails.RegisterId = this.route.snapshot.params["id"];
    this.getGuestDetailsById(this.GuestDetails.RegisterId);
    this.getAllCountry();
    this.getAllRoles();
    this.getAllRegion();
  }

  /*******************************************************************************
  * Get data for add employee
  * ******************************************************************************/
  getAllCountry() {
    this.showLoader = true;
    this.Service.GetData("Country", "GetAll", null).subscribe((data: any) => {
      this.Country = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getAllRoles() {
    this.showLoader = true;
    this.Service.GetData('Account', 'GetAllGuestRoles', null).subscribe((data: any) => {
      this.Roles = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }
  getAllRegion() {
    this.showLoader = true;
    this.Service.GetData("Region", "GetAll", null).subscribe((data: any) => {
      this.Region = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getGuestDetailsById(RegisterId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "id=" + RegisterId;

    this.Service.GetData('Guest', 'GetGuestById', queryString).subscribe((data: EmployeeGuestModel) => {
      this.GuestDetails = data;
      // console.log("---------------  +" , this.GuestDetails)
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  /*******************************************************************************
  * End Get data for add employee
  * ******************************************************************************/

  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    const appConfig = this.appConfigService.getConfig();
    this.titleService.setTitle(`Edit Guest - ${appConfig.titleName}`);
    //this.titleService.setTitle("Edit Guest - ECOTECH");
  }

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/


  onSubmit(myform: any) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update this guest?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.GuestDetails.RoleType = 3;
        this.showLoader = true;
        this.Service.PostData('Account', 'UpdateRegister', this.GuestDetails, null).subscribe((data: any) => {
          let result = data;
          this.showLoader = false;
          if (result.ResponseCode == 1) {
            this.toastr.success('Guest updated successfully!');
            this.router.navigate(['/Guest']);
          }
          if (result.ResponseCode == 0) {
            this.toastr.warning('Username already exists!');
            myform.controls['username'].setErrors({ AlreadyPresent: true });
          }
          if (result.ResponseCode == 2) {
            this.toastr.warning('Guest id already exists!');
            myform.controls['guest_id'].setErrors({ AlreadyPresent: true });
          }
          if (result.ResponseCode == 3) {
            this.toastr.warning('Contact number already exists!');
            myform.controls['contactnumber'].setErrors({ AlreadyPresent: true });
          }
          if (result.ResponseCode == 4) {
            this.toastr.warning('Guest id and contact number already exists!');
            myform.controls['guest_id'].setErrors({ AlreadyPresent: true });
            myform.controls['contactnumber'].setErrors({ AlreadyPresent: true });
          }
           if(result.ResponseCode == 5)
          {
            this.toastr.success('Failed to update guest password!');
            this.router.navigate(['/Guest']);
          }
        }, error => {
          this.toastr.error('Failed to update guest details!');
          this.showLoader = false;
          this.router.navigate(['/Guest']);
        });
      }
    });

  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isGuestEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isGuest')[0]?.IsEditable;
      console.log("IsConfigure-",this.isGuestEditable);
  
    });
 
  }

}
