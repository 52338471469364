<!-- <loader [showLoader]="showLoader"></loader> -->
<div id="whileContent"
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-portlet m-portlet--success m-portlet--head-solid-bg " style="margin-bottom: 0px;">
            <div class="m-portlet__body" style="padding: 1px;margin-top: 15px;">
                <div style="padding: 20px;">
                    <div class="form-group m-form__group row">
                        <div class="col-sm-4">
                            <label style="display: flex;" class="col-sm2">
                                <h6>Year:<span style="color:#f4516c;">&nbsp;*</span></h6>
                            </label>
                            <div>
                                <select style="width: 250px;" #fiterElement class="form-control " id="supplychaintype"
                                    name="supplychaintype" [(ngModel)]="Year" (change)="yearChange($event)" required>
                                    <option value=undefined selected disabled>
                                        Select</option>
                                    <option *ngFor="let item of YearList" value="{{item}}">
                                        {{item}}
                                    </option>
                                </select><br />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label style="display: flex;" class="col-sm2">
                                <h6>Region:<span style="color:#f4516c;">&nbsp;*</span></h6>
                            </label>
                            <div>
                                <select [disabled]="regionLocked" class="form-control" style="width: 250px;"
                                    (change)="regionChange()" id="supplychaintype" [(ngModel)]="setRegion"
                                    name="supplychaintype" required>
                                    <option value="0" selected>All Regions</option>
                                    <option *ngFor="let item of RegionList;let i = index;" value="{{item.Id}}">
                                        {{item.Name}}</option>
                                </select><br />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label style="display: flex;" class="col-sm2">
                                <h6>Currency:<span style="color:#f4516c;">&nbsp;*</span></h6>
                            </label>
                            <div>
                                <select class="form-control" style="width: 250px;" (change)="currencyChange()"
                                    [(ngModel)]="setCurrency" id="supplychaintype" name="supplychaintype" required>
                                    <option value=undefined selected disabled>Select</option>
                                    <option *ngFor="let item of CurrencyList;let i = index;" value="{{item.Id}}">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="m-content" id="demoHTML">
            <div class="row">
                <div class="col-sm-6">
                    <app-enquiry-details-chart [Year]="Year" [region]="setRegion" [currency]="setCurrency"
                        [filterChanged]="filterChanged" [currencyCode]="setCurrencyCode"></app-enquiry-details-chart>
                </div>
                <div class="col-sm-6">
                    <app-order-values-chart [Year]="Year" [region]="setRegion" [currency]="setCurrency"
                        [filterChanged]="filterChanged" [currencyCode]="setCurrencyCode"></app-order-values-chart>
                </div>

            </div>
            <div class="row">
                <div class="col-sm-12 table-responsive">
                    <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Enquiry Details ({{currencyname}})
                                    </h3>
                                </div>
                            </div>
                            <div class="m-portlet__head-tools">
                                <select class="form-control mr-1 pl-0" id="sel1" style="min-width:110px;"
                                    [(ngModel)]="drpdnOption" (change)="OnSelectChange()">
                                    <option selected disabled>Select</option>
                                    <option value="Units">No's</option>
                                    <option value="Amount">Amount</option>
                                </select>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <th style="width: 10%;">Region</th>
                                        <th class="text-center">Lead/Enquiry</th>
                                        <th class="text-center">RFQ</th>
                                        <th class="text-center">Quotations</th>
                                        <th class="text-center"> Order Booked</th>
                                        <th class="text-center"> Sales</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of EnquiryChartData;let i = index;">
                                            <td style="cursor: pointer; color: #5867dd;"><a
                                                    (click)="openFunnelChartPopup(item)"><u>{{item.Region}}</u></a></td>
                                            <td class="text-center">{{item.SubmittedEnquiry}}</td>
                                            <td class="text-center">{{item.RFQ}}</td>
                                            <td class="text-center">{{item.ProposalDelivered}}</td>
                                            <td class="text-center">{{item.OrderConfirmation}}</td>
                                            <td class="text-center">{{item.OrderDelivered}}</td>
                                        </tr>

                                        <!-- <tr>
                                        <td><b>Total</b></td>{{ drpdnOption == 'Units' ? '': '₹' }}
                                        <td class="text-center"><b>{{opportunitySum}}</b></td>
                                        <td class="text-center"><b>{{rfqSum}}</b></td>
                                        <td class="text-center"><b>{{quotationSum}}</b></td>
                                        <td class="text-center"><b>{{orderintkaeSum}}</b></td>
                                        <td class="text-center"><b>{{salesSum}}</b></td>
                                    </tr> -->

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- *ngIf="role==AppRoles.SalesDirector || role==AppRoles.CEO || role==AppRoles.BusinessDevelopment || role==AppRoles.ProjectManager"> -->
            <!-- *ngIf="role==AppRoles.SalesEngineer || role==AppRoles.RegionalSalesManager || 
            role==AppRoles.AreaSalesManager || role==AppRoles.SalesDirector || role==AppRoles.CEO || role==AppRoles.BusinessDevelopment || role==AppRoles.ProductManager" -->
            <div class="row">
                <div class="col-sm-6">
                    <app-business-plan-chart [Year]="Year" [region]="setRegion" [currency]="setCurrency"
                        [filterChanged]="filterChanged" [currencyCode]="setCurrencyCode"></app-business-plan-chart>
                </div>
                <div class="col-sm-6">
                    <app-bporder-intake [Year]="Year" [region]="setRegion" [currency]="setCurrency"
                        [filterChanged]="filterChanged" [currencyCode]="setCurrencyCode"></app-bporder-intake>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <app-opportunity-rfq-chart [Year]="Year" [region]="setRegion" [currency]="setCurrency"
                        [filterChanged]="filterChanged" [currencyCode]="setCurrencyCode"></app-opportunity-rfq-chart>
                </div>
                <div class="col-sm-6">
                    <app-opportunity-quotation-chart [Year]="Year" [region]="setRegion" [currency]="setCurrency"
                        [filterChanged]="filterChanged" [currencyCode]="setCurrencyCode"></app-opportunity-quotation-chart>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <app-quatation-order-intake-chart [Year]="Year" [region]="setRegion" [currency]="setCurrency"
                        [filterChanged]="filterChanged" [currencyCode]="setCurrencyCode"></app-quatation-order-intake-chart>
                </div>
                <div class="col-sm-6">
                    <app-orderbacklog-supplychain-chart [Year]="Year" [region]="setRegion" [currency]="setCurrency"
                        [filterChanged]="filterChanged" [currencyCode]="setCurrencyCode"></app-orderbacklog-supplychain-chart>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <app-order-win-lost-chart [Year]="Year" [region]="setRegion" [currency]="setCurrency"
                        [filterChanged]="filterChanged" [currencyCode]="setCurrencyCode"></app-order-win-lost-chart>
                </div>
                <!-- <div class="col-sm-6">
                    <app-avg-discount-month [Year]="Year"></app-avg-discount-month> 
                </div>-->
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <app-show-pending-activity [title]="'My Activities for Today'" [reportTypeId]="'REP_MY_TODAYS_ACTIVITIES'"></app-show-pending-activity>
                </div>
                <div class="col-sm-6">
                    <app-show-pending-activity [title]="'My Pending Activities'" [reportTypeId]="'rep_my_pending_activities'"></app-show-pending-activity>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- <app-charts-modal></app-charts-modal> -->