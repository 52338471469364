import { Component, OnInit, Input } from '@angular/core';
import { SingleFieldModel, SingleFieldComponent } from 'src/app/Model/SingleFieldModel';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-edit-view-configuration',
  templateUrl: './edit-view-configuration.component.html',
  styleUrls: ['./edit-view-configuration.component.scss']
})
export class EditViewConfigurationComponent implements OnInit {

  @Input()
  showLoader: boolean = false;
  singleFieldObj: SingleFieldModel = new SingleFieldModel();
  singleFieldComponent: SingleFieldComponent = new SingleFieldComponent();
  editFormValues: boolean = false;
  editFormStatus: string = "";
  UserRole: any;
  isConfigureEditable: boolean = false;
  IndustrySegment: any[] = [];
  SubSegment: any[] = [];
  Roles: any[] = [];
  charCode: any;
  TermsAndConditions: any[] = [];
  Customer:any[] =[];
  ProductModel:any[]=[];
  ProductModelDiscount:any[]=[];
  ProductModelDiscountArr:SingleFieldModel[]=[];
  CategoryRegion: any[] = [];
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];
  appConfig : any;

  constructor(private toastr: ToastrService, private commonService: CommonService, public dialog: MatDialog,
    private router: Router, private route: ActivatedRoute, private titleService: Title,private accessservice: AccessPermissionService,
    private appConfigService: AppConfigService) { }

  ngOnInit() {
    this.appConfig = this.appConfigService.getConfig();
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess()
    //this.isConfigureEditable = AppGlobals.ConfigureMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.singleFieldObj.Id = this.route.snapshot.params["id"];
    this.singleFieldComponent.RoutingParam = this.route.snapshot.params["heading"];
    this.editFormStatus = "View";
    this.showLoader = true;
    switch (this.singleFieldComponent.RoutingParam) {
      case "MDSOption":
        this.singleFieldComponent.Heading = "MDS Option";
        this.singleFieldComponent.RoutingParam = "MDSOption";
        this.singleFieldComponent.ButtonText = "Add MDS Option";
        this.singleFieldComponent.ConfirmationText = "MDS option";
        this.singleFieldComponent.ControllerName = "MDSOptions";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "MDS option";
        this.singleFieldComponent.firstFiledLabel = "MDS Option";
        this.singleFieldComponent.firstFieldValidationMsg = "MDS option";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.getDetailsById(this.singleFieldObj.Id);
        break;
      case "SalesPrice":
        this.singleFieldComponent.Heading = "Sales Price Discount";
        this.singleFieldComponent.RoutingParam = "SalesPrice";
        this.singleFieldComponent.ButtonText = "Add Sales Price Discount";
        this.singleFieldComponent.ConfirmationText = "sales price";
        this.singleFieldComponent.ControllerName = "SalesPrice";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Sales price";
        this.singleFieldComponent.firstFiledLabel = "Sales Price";
        this.singleFieldComponent.firstFieldValidationMsg = "Sales price";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isSalespriceDiscountFields=true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = '';
        //   this.singleFieldComponent.FirstFiledPatternValidationMessage = "Please enter alphanumeric values ​​only";
        this.getDetailsById(this.singleFieldObj.Id);
        this.getAllRoles();
        break;
      case "Country":
        this.singleFieldComponent.Heading = "Country";
        this.singleFieldComponent.RoutingParam = "Country";
        this.singleFieldComponent.ButtonText = "Add Country";
        this.singleFieldComponent.ConfirmationText = "country";
        this.singleFieldComponent.ControllerName = "Country";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Country";
        this.singleFieldComponent.SuccessText2 = "Country Code";
        this.singleFieldComponent.firstFiledLabel = "Country";
        this.singleFieldComponent.secondFiledLabel = "Country Code";
        this.singleFieldComponent.firstFieldValidationMsg = "Country";
        this.singleFieldComponent.SecondFieldValidationMsg = "Country Code";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.SecondFieldMaxLen = 10;
        this.getDetailsById(this.singleFieldObj.Id);
        break;
      case "CustomerCategory":
        this.singleFieldComponent.Heading = "Customer Category";
        this.singleFieldComponent.RoutingParam = "CustomerCategory";
        this.singleFieldComponent.ButtonText = "Add Customer Category";
        this.singleFieldComponent.ConfirmationText = "customer category";
        this.singleFieldComponent.ControllerName = "CustomerCategory";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Customer category";
        this.singleFieldComponent.firstFiledLabel = "Customer Category";
        this.singleFieldComponent.firstFieldValidationMsg = "Customer category";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 150;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.getDetailsById(this.singleFieldObj.Id);
        break;
      case "CustomerClassification":
        this.singleFieldComponent.Heading = "Customer Classification";
        this.singleFieldComponent.RoutingParam = "CustomerClassification";
        this.singleFieldComponent.ButtonText = "Add Customer Classification";
        this.singleFieldComponent.ConfirmationText = "customer classification";
        this.singleFieldComponent.ControllerName = "CustomerClassification";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Customer classification";
        this.singleFieldComponent.firstFiledLabel = "Customer Classification";
        this.singleFieldComponent.firstFieldValidationMsg = "Customer classification";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 150;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.getDetailsById(this.singleFieldObj.Id);
        break;
      case "EnquirySource":
        this.singleFieldComponent.Heading = "Enquiry Source";
        this.singleFieldComponent.RoutingParam = "EnquirySource";
        this.singleFieldComponent.ButtonText = "Add Enquiry Source";
        this.singleFieldComponent.ConfirmationText = "enquiry source";
        this.singleFieldComponent.ControllerName = "EnquirySource";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Enquiry source";
        this.singleFieldComponent.firstFiledLabel = "Enquiry Source";
        this.singleFieldComponent.firstFieldValidationMsg = "Enquiry source";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 150;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.getDetailsById(this.singleFieldObj.Id);
        break;
      case "IndustrySegment":
        this.singleFieldComponent.Heading = "Industry Segment";
        this.singleFieldComponent.RoutingParam = "IndustrySegment";
        this.singleFieldComponent.ButtonText = "Add Industry Segment";
        this.singleFieldComponent.ConfirmationText = "industry segment";
        this.singleFieldComponent.ControllerName = "IndustrySegment";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Industry segment";
        this.singleFieldComponent.firstFiledLabel = "Industry Segment";
        this.singleFieldComponent.firstFieldValidationMsg = "Industry segment";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.getDetailsById(this.singleFieldObj.Id);
        break;

      case "SubSegment":
        this.singleFieldComponent.Heading = "Sub Segment";
        this.singleFieldComponent.RoutingParam = "SubSegment";
        this.singleFieldComponent.ButtonText = "Add Sub Segment";
        this.singleFieldComponent.ConfirmationText = "sub segment";
        this.singleFieldComponent.ControllerName = "SubSegment";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Sub segment";
        this.singleFieldComponent.firstFiledLabel = "Industry Segment";
        this.singleFieldComponent.secondFiledLabel = "Sub Segment";
        this.singleFieldComponent.firstFieldValidationMsg = "Industry segment";
        this.singleFieldComponent.SecondFieldValidationMsg = "Sub segment";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.getDetailsById(this.singleFieldObj.Id);
        this.singleFieldComponent.isDropdownrequird = true;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.SecondFieldMaxLen = 100;
        this.GetData("IndustrySegment", "GetAll");
        break;

      case "Region":
        this.singleFieldComponent.Heading = "Region";
        this.singleFieldComponent.RoutingParam = "Region";
        this.singleFieldComponent.ButtonText = "Add Region";
        this.singleFieldComponent.ConfirmationText = "region";
        this.singleFieldComponent.ControllerName = "Region";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Region";
        this.singleFieldComponent.SuccessText2 = "Region Code";
        this.singleFieldComponent.firstFiledLabel = "Region";
        this.singleFieldComponent.secondFiledLabel = "Region Code";
        this.singleFieldComponent.firstFieldValidationMsg = "Region";
        this.singleFieldComponent.SecondFieldValidationMsg = "Region Code";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.SecondFieldMaxLen = 10;
        this.getDetailsById(this.singleFieldObj.Id);
        break;

      case "TypeofCustomer":
        this.singleFieldComponent.Heading = "Type of Customer";
        this.singleFieldComponent.RoutingParam = "TypeofCustomer";
        this.singleFieldComponent.ButtonText = "Add Type of Customer";
        this.singleFieldComponent.ConfirmationText = "type of customer";
        this.singleFieldComponent.ControllerName = "CustomerType";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Type of customer";
        this.singleFieldComponent.firstFiledLabel = "Type of Customer";
        this.singleFieldComponent.firstFieldValidationMsg = "Type of customer";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.getDetailsById(this.singleFieldObj.Id);
        break;
      case "EstimatedProductValue":
        this.singleFieldComponent.Heading = "Estimated Product Value";
        this.singleFieldComponent.RoutingParam = "EstimatedProductValue";
        this.singleFieldComponent.ButtonText = "Add Estimated Product Value";
        this.singleFieldComponent.ConfirmationText = "estimated product value";
        this.singleFieldComponent.ControllerName = "EstimatedProductValue";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Estimated product value";
        this.singleFieldComponent.firstFiledLabel = "Estimated Product Value";
        this.singleFieldComponent.firstFieldValidationMsg = "Estimated product value";
        this.singleFieldComponent.secondFiledLabel = "Role";
        this.singleFieldComponent.SecondFieldValidationMsg = "Role"
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.singleFieldComponent.FirstFieldMaxLen = 50;
        this.getDetailsById(this.singleFieldObj.Id);
        this.getAllRoles();
        break;
      case "Currency":
        this.singleFieldComponent.Heading = "Currency";
        this.singleFieldComponent.RoutingParam = "Currency";
        this.singleFieldComponent.ButtonText = "Add Currency";
        this.singleFieldComponent.ConfirmationText = "currency";
        this.singleFieldComponent.ControllerName = "Currency";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Currency";
        this.singleFieldComponent.firstFiledLabel = "Currency";
        this.singleFieldComponent.secondFiledLabel = "Exchange Rate (€)";
        this.singleFieldComponent.firstFieldValidationMsg = "Currency";
        this.singleFieldComponent.SecondFieldValidationMsg = "Exchange rate";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = /^[0-9]\d*(\.\d+)?$/;
        this.singleFieldComponent.FirstFieldMaxLen = 50;
        this.singleFieldComponent.SecondFieldMaxLen = 6;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.getDetailsById(this.singleFieldObj.Id);
        break;
      case "TermsConditions":
        this.singleFieldComponent.Heading = "Terms & Conditions";
        this.singleFieldComponent.RoutingParam = "TermsConditions";
        this.singleFieldComponent.ButtonText = "Add Terms & Conditions";
        this.singleFieldComponent.ConfirmationText = "terms & conditions";
        this.singleFieldComponent.ControllerName = "TermsAndConditions";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Terms & conditions";
        this.singleFieldComponent.firstFiledLabel = "Terms & Condition";
        this.singleFieldComponent.secondFiledLabel = "Standard Value";
        this.singleFieldComponent.firstFieldValidationMsg = "Terms & condition";
        this.singleFieldComponent.SecondFieldValidationMsg = "Standard Value";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 50;
        this.singleFieldComponent.SecondFieldMaxLen = 100;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.getDetailsById(this.singleFieldObj.Id);
        break;
   
      case "StandardReasonforWinningLosing":
        this.singleFieldComponent.Heading = "Standard Reason";
        this.singleFieldComponent.RoutingParam = "StandardReasonforWinningLosing";
        this.singleFieldComponent.ButtonText = "Add Standard Reason";
        this.singleFieldComponent.ConfirmationText = "standard reason for winning/losing";
        this.singleFieldComponent.ControllerName = "ReasonEnquiryWinLoss";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Standard reason for winning/losing";
        this.singleFieldComponent.firstFiledLabel = "Enquiry Status";
	      this.singleFieldComponent.secondFiledLabel = "Reason Catagory";
	      this.singleFieldComponent.thirdFieldLabel = "Reason for Winning/Losing";
        this.singleFieldComponent.firstFieldValidationMsg = "Enquiry status";
        this.singleFieldComponent.SecondFieldValidationMsg = "Reason Category";
 	      this.singleFieldComponent.ThirdFieldValidationMsg = "Reason for winning/losing";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isTripleField = true;
        this.singleFieldComponent.isDropdownrequird = true;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 10;
        this.singleFieldComponent.SecondFieldMaxLen = 100;
        this.singleFieldComponent.ThirdFieldMaxLen = 100;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.getDetailsById(this.singleFieldObj.Id);
        this.getAllCategoryRegion();
        break;
        
        case "EnquiryCloseReason":
        this.singleFieldComponent.Heading = "Enquiry Close Reason";
        this.singleFieldComponent.RoutingParam = "EnquiryCloseReason";
        this.singleFieldComponent.ButtonText = "Add Enquiry Close Reason";
        this.singleFieldComponent.ConfirmationText = "standard reason Close Enquiry";
        this.singleFieldComponent.ControllerName = "EnquiryCloseReason";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Standard reason for Close Enquiry";
        this.singleFieldComponent.firstFiledLabel = "Reason for Close Enquiry";
        this.singleFieldComponent.firstFieldValidationMsg = "Reason for Close Enquiry";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.getDetailsById(this.singleFieldObj.Id);
        break;

        case "LeadCloseReason":
          this.singleFieldComponent.Heading = "Lead Close Reason";
          this.singleFieldComponent.RoutingParam = "LeadCloseReason";
          this.singleFieldComponent.ButtonText = "Add Lead Close Reason";
          this.singleFieldComponent.ConfirmationText = "Lead close reason";
          this.singleFieldComponent.ControllerName = "OpportunityCloseReason";
          this.singleFieldComponent.ActionName = "Update";
          this.singleFieldComponent.SuccessText = "Lead close reason";
          this.singleFieldComponent.firstFiledLabel = "Reason for Close Lead";
          this.singleFieldComponent.firstFieldValidationMsg = "Reason for Close Lead";
          this.singleFieldComponent.isDoubleField = false;
          this.singleFieldComponent.isDropdownrequird = false;
          this.singleFieldComponent.Pattern = "";
          this.singleFieldComponent.FirstFiledPattern = "";
          this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
          this.singleFieldComponent.FirstFieldMaxLen = 100;
          this.getDetailsById(this.singleFieldObj.Id);
          break;

      case "EnquiryCompetitor":
        this.singleFieldComponent.Heading = "Enquiry Competitor";
        this.singleFieldComponent.RoutingParam = "EnquiryCompetitor";
        this.singleFieldComponent.ButtonText = "Add Enquiry Competitor";
        this.singleFieldComponent.ConfirmationText = "Enquiry Competitor";
        this.singleFieldComponent.ControllerName = "EnquiryCompetitor";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Enquiry Competitor";
        this.singleFieldComponent.firstFiledLabel = "Enquiry Competitor";
        this.singleFieldComponent.firstFieldValidationMsg = "Enquiry Competitor";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.getDetailsById(this.singleFieldObj.Id);
        break;

        case "CustomerGroup":
          this.singleFieldComponent.Heading = "Customer Group";
          this.singleFieldComponent.RoutingParam = "CustomerGroup";
          this.singleFieldComponent.ButtonText = "Add Customer Group";
          this.singleFieldComponent.ConfirmationText = "customer group";
          this.singleFieldComponent.ControllerName = "CustomerGroup";
          this.singleFieldComponent.ActionName = "Update";
          this.singleFieldComponent.SuccessText = "Customer group";
          this.singleFieldComponent.firstFiledLabel = "Customer Group";
          this.singleFieldComponent.secondFiledLabel = "Customer Group Code";
          this.singleFieldComponent.firstFieldValidationMsg = "Customer Group";
          this.singleFieldComponent.SecondFieldValidationMsg = "Customer Group Code";
          this.singleFieldComponent.isDoubleField = true;
          this.singleFieldComponent.isDropdownrequird = false;
          this.singleFieldComponent.Pattern = "";
          this.singleFieldComponent.FirstFieldMaxLen = 150;
          this.singleFieldComponent.SecondFieldMaxLen = 150;
          this.singleFieldComponent.FirstFiledPattern = "";
          this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
          this.getDetailsById(this.singleFieldObj.Id);
          break;

        case "TechnicalDiscardReason":
            this.singleFieldComponent.Heading = "Technical Discard Reason";
            this.singleFieldComponent.RoutingParam = "TechnicalDiscardReason";
            this.singleFieldComponent.ButtonText = "Add Technical Discard Reason";
            this.singleFieldComponent.ConfirmationText = "Technical discard reason";
            this.singleFieldComponent.ControllerName = "TechnicalDiscard";          
            this.singleFieldComponent.ActionName = "Update";
            this.singleFieldComponent.SuccessText = "Technical discard reason";
            this.singleFieldComponent.firstFiledLabel = "Technical Discard Reason";
            this.singleFieldComponent.firstFieldValidationMsg = "Technical Discard Reason";
            this.singleFieldComponent.isDoubleField = false;
            this.singleFieldComponent.isDropdownrequird = false;
            this.singleFieldComponent.Pattern = "";
            this.singleFieldComponent.FirstFieldMaxLen = 50;
            this.singleFieldComponent.FirstFiledPattern = "";
            this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
            this.getDetailsById(this.singleFieldObj.Id);
            break;

        case "TermsConditions-NonStandard":
          this.singleFieldComponent.Heading = "Terms & Conditions-Non Standard";
          this.singleFieldComponent.RoutingParam = "TermsConditions-NonStandard";
          this.singleFieldComponent.ButtonText = "Add Non Standard Terms & Conditions";
          this.singleFieldComponent.ConfirmationText = "terms & conditions non standard";
          this.singleFieldComponent.ControllerName = "TermsAndConditionsCustomized";
          this.singleFieldComponent.ActionName = "Update";
          this.singleFieldComponent.SuccessText = "Terms & conditions-Non Standard";
          this.singleFieldComponent.firstFiledLabel = "Terms & Condition-Non Standard";
          this.singleFieldComponent.secondFiledLabel = "Non Standard Value";
          this.singleFieldComponent.TableHeader1 = "Terms & Conditions-Non Standard";
          this.singleFieldComponent.TableHeader2 = "Non Standard Values";
          this.singleFieldComponent.firstFieldValidationMsg = "Non standard term & condition";
          this.singleFieldComponent.SecondFieldValidationMsg = "Non standard value";
          this.singleFieldComponent.isDoubleField = true;
          this.singleFieldComponent.isDropdownrequird = true;
          this.singleFieldComponent.Pattern = "";
          this.singleFieldComponent.SecondFieldMaxLen = 100;
          this.singleFieldComponent.FirstFiledPattern = "";
          this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
          this.getDetailsById(this.singleFieldObj.Id);
          this.GetData("TermsAndConditions", "GetAll");
          break;

        case "Application":
          this.singleFieldComponent.Heading = "Application";
          this.singleFieldComponent.RoutingParam = "Application";
          this.singleFieldComponent.ButtonText = "Add Application";
          this.singleFieldComponent.ConfirmationText = "Application";
          this.singleFieldComponent.ControllerName = "Application";
          this.singleFieldComponent.ActionName = "Update";
          this.singleFieldComponent.SuccessText = "Application";
          this.singleFieldComponent.firstFiledLabel = "Industry Segment";
          this.singleFieldComponent.secondFiledLabel = "Sub Segment";
          this.singleFieldComponent.thirdFieldLabel = "Application Name";
          this.singleFieldComponent.TableHeader1 = "Industry Segment";
          this.singleFieldComponent.TableHeader2 = "Sub Segment";
          this.singleFieldComponent.TableHeader3 = "Application";
          this.singleFieldComponent.firstFieldValidationMsg = "Industry Segment";
          this.singleFieldComponent.SecondFieldValidationMsg = "Sub Segment";
          this.singleFieldComponent.thirdFieldValidationMsg = "Application Name";
          this.singleFieldComponent.isDoubleField = true;
          this.singleFieldComponent.isTripleField = true;    
          this.singleFieldComponent.isDropdownrequird = true;
          this.singleFieldComponent.Pattern = "";
          this.singleFieldComponent.ThirdFieldMaxLen = 250;
          this.singleFieldComponent.FirstFiledPattern = "";
          this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
          this.singleFieldComponent.SecondFieldPattern = "";
          this.singleFieldComponent.SecondFieldPatternValidationMessage = "";
          this.singleFieldComponent.ThirdFieldPattern = "";
          this.singleFieldComponent.ThirdFieldPatternValidationMessage = "";
          this.getDetailsById(this.singleFieldObj.Id); 
          this.GetData("IndustrySegment", "GetAll"); 
          break; 

        case "CustomerSpecialDiscount":
          this.singleFieldComponent.Heading = "Customer Frame Agreement";
          this.singleFieldComponent.RoutingParam = "CustomerSpecialDiscount";
          this.singleFieldComponent.ButtonText = "Add Customer Frame Agreement";
          this.singleFieldComponent.ConfirmationText = "Customer Frame Agreement";
          this.singleFieldComponent.ControllerName = "CustomerSpecialDiscount";
          this.singleFieldComponent.ActionName = "Update";
          this.singleFieldComponent.SuccessText = "Customer Frame Agreement";
          this.singleFieldComponent.firstFiledLabel = "Product Model";
          this.singleFieldComponent.secondFiledLabel = "Product Discount";
          this.singleFieldComponent.thirdFiledLabel = "Product Discount ";
          this.singleFieldComponent.firstFieldValidationMsg = "Product Model";
          this.singleFieldComponent.SecondFieldValidationMsg = "Product Discount ";
          this.singleFieldComponent.ThirdFieldValidationMsg = "Product Discount ";
          this.singleFieldComponent.isDoubleField = false;
          this.singleFieldComponent.isvariablediscountfield=true;
          this.singleFieldComponent.ismultiplerecords=true;
          
          this.singleFieldComponent.FirstFiledPattern = "";
          this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
          this.getDetailsByCustomerId(this.singleFieldObj.Id);
         
          this.singleFieldComponent.Pattern = "";
          this.singleFieldComponent.SecondFieldMaxLen = 100;
          this.GetData("CustomerSpecialDiscount", "GetAll");
          break;
          
        case "RoleManagement":
        this.singleFieldComponent.Heading = "Role Management";
        this.singleFieldComponent.RoutingParam = "RoleManagement";
        this.singleFieldComponent.ButtonText = "Add Role";
        this.singleFieldComponent.ConfirmationText = "Role Management";
        this.singleFieldComponent.ControllerName = "Account";
        this.singleFieldComponent.ActionName = "Update";
        this.singleFieldComponent.SuccessText = "Role Management";
        this.singleFieldComponent.firstFiledLabel = "Role Management";
        this.singleFieldComponent.firstFieldValidationMsg = "Role Management";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 150;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.getDetailsById(this.singleFieldObj.Id);
        break;
    }
    this.titleService.setTitle(this.editFormStatus + " " + this.singleFieldComponent.Heading + " - " + this.appConfig.titleName);
    //this.titleService.setTitle(this.editFormStatus + " " + this.singleFieldComponent.Heading + " - ECOTECH");
    this.showLoader = false;

  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  editForm() {
    if (this.editFormValues == false) {
      this.editFormValues = true;
      this.editFormStatus = "Edit";
      this.titleService.setTitle(this.editFormStatus + " " + this.singleFieldComponent.Heading + " - " + this.appConfig.titleName);
      //this.titleService.setTitle(this.editFormStatus + " " + this.singleFieldComponent.Heading + " - ECOTECH");
    }
    else {
      this.editFormValues = false;
      this.editFormStatus = "View";
      this.titleService.setTitle(this.editFormStatus + " " + this.singleFieldComponent.Heading + " - " + this.appConfig.titleName);
      //this.titleService.setTitle(this.editFormStatus + " " + this.singleFieldComponent.Heading + " - ECOTECH");
    }
  }

  onIndustrySegmentSelect(e: any) 
  {
    this.singleFieldObj.SubSegmentId = null;
    this.GetSubSegment(e.target.value);
    console.log("id--",(e.target.value))
  }

  //************************************Validation for discount*********************************** */

  OnDiscountChange(index)
  {
    if (this.ProductModelDiscount[index].ProductDiscount < 0) {
      this.toastr.info('Sales Discount cannot be negative.');
      this.ProductModelDiscount[index].ProductDiscount = null;
      return;
    }
    else if (this.ProductModelDiscount[index].ProductDiscount > 100) {
      this.toastr.info('Discount cannot be greater than 100.');
      this.ProductModelDiscount[index].ProductDiscount= null;
      return;
    }
  }

  getDetailsById(Id: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + Id;

    this.commonService.GetData(this.singleFieldComponent.ControllerName, 'GetById', queryString).subscribe((data: any) => {
      this.singleFieldObj = data;
      //  console.log("asdf---->",data);
      if(this.singleFieldComponent.ControllerName == 'Application')
      {
        this.GetSubSegment(this.singleFieldObj.SegmentId);
      }
    }, error => {
      this.toastr.error("Failed to load data!");

    });
    this.showLoader = false;
  }

  GetSubSegment(SegmentId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "SegmentId=" + SegmentId;
    this.commonService.GetData("SubSegment", "GetByIndustrySegmentId", queryString).subscribe((data: any) => {
      this.SubSegment = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getDetailsByCustomerId(Id: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + Id;

    this.commonService.GetData(this.singleFieldComponent.ControllerName, 'GetById', queryString).subscribe((data: any) => {
      this.ProductModelDiscount = data;
    
    }, error => {
      this.toastr.error("Failed to load data!");

    });
    this.showLoader = false;
  }


  GetData(ControllerName: string, ActionName: string) {
    this.showLoader = true;
    this.commonService.GetData(ControllerName, ActionName, null).subscribe((data: any) => {
      if(ControllerName === 'IndustrySegment')
      {this.IndustrySegment = data;}
      else 
      {this.TermsAndConditions = data;}
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getAllRoles() {
    this.showLoader = true;
    this.commonService.GetData("Account", "GetAllEmployeeRoles", null).subscribe((data: any) => {
      this.Roles = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  Update(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "You want to update this " + this.singleFieldComponent.ConfirmationText + "?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult =>       {
      // if user pressed yes dialogResult will be true, 
      // if he pressed no - it will be false
      if (dialogResult)      {
        this.showLoader = true;
        if (this.singleFieldComponent.ismultiplerecords) 
        {
          this.commonService.PostData(this.singleFieldComponent.ControllerName, 'Update', this.ProductModelDiscount, null, this.singleFieldObj.Id).subscribe((data: any) =>           {

            let result = data;
            if (result.ResponseCode == 1) {
              this.toastr.success(this.singleFieldComponent.SuccessText + " updated successfully!");
              this.showLoader = false;
              this.router.navigate(['/ConfigureMasters', this.singleFieldComponent.RoutingParam]);
            }
            else if (result.ResponseCode == 0) {
              // myform.controls['fieldtitle'].setErrors({ AlreadyPresent: true });
              this.toastr.warning(this.singleFieldComponent.SuccessText + ' already exists!');
              this.showLoader = false;
            }
            else {
              this.toastr.error("Failed to update " + this.singleFieldComponent.ConfirmationText + "!");
              this.showLoader = false;
              this.router.navigate(['/ConfigureMasters', this.singleFieldComponent.RoutingParam]);
            }
          }, error => {
            this.toastr.error("Failed to update " + this.singleFieldComponent.ConfirmationText + "!");
            this.showLoader = false;
            this.router.navigate(['/ConfigureMasters', this.singleFieldComponent.RoutingParam]);
          });

        }
        else 
        {
         
          this.commonService.PostData(this.singleFieldComponent.ControllerName, 'Update', this.singleFieldObj, null).subscribe((data: any) =>          {

            if (data.ResponseCode == 1) {
              this.toastr.success(this.singleFieldComponent.SuccessText + " updated successfully!");
              this.showLoader = false;
              this.router.navigate(['/ConfigureMasters', this.singleFieldComponent.RoutingParam]);
            }
            else if (data.ResponseCode == 0) {
              // myform.controls['fieldtitle'].setErrors({ AlreadyPresent: true });
              this.toastr.warning(this.singleFieldComponent.SuccessText + ' already exists!');
              this.showLoader = false;
            }
            else if (data.ResponseCode == 2) {
              // myform.controls['fieldtitle'].setErrors({ AlreadyPresent: true });
              this.toastr.warning(this.singleFieldComponent.SuccessText2 + ' already exists!');
              this.showLoader = false;
            }
            else {
              this.toastr.error("Failed to update " + this.singleFieldComponent.ConfirmationText + "!");
              this.showLoader = false;
              this.router.navigate(['/ConfigureMasters', this.singleFieldComponent.RoutingParam]);
            }
          }, error => {
            this.toastr.error("Failed to update " + this.singleFieldComponent.ConfirmationText + "!");
            this.showLoader = false;
            this.router.navigate(['/ConfigureMasters', this.singleFieldComponent.RoutingParam]);
          });

         }
        }
      });
  }
  
  fieldChange(value : any)
  {
    console.log('checkbox value --->',value);
    if(value == true)
    { 
      this.singleFieldObj.IsCompetitorRequired = true;
    }
    else
    {
      this.singleFieldObj.IsCompetitorRequired = false;
    }
    
  }

  getAllCategoryRegion() 
  {
    this.showLoader = true;
    this.commonService.GetData("ReasonEnquiryWinLoss", "GetAllReasonCategory", null).subscribe((data: any) => {
      this.CategoryRegion = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
   });
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isConfigureEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isConfigureMasters')[0]?.IsEditable;
      console.log("IsConfigure-",this.isConfigureEditable);
  
    }); 

  }

  isintNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if
      (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;

    }
    else {
      return true;
    }
  }

}