import { Component, OnInit, HostListener } from '@angular/core';
import { CommonService } from '../Service/common.service';
import { AppConfig } from 'src/assets/constants';
import { AppConfigService } from '../Service/app-config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerName : any;
  currYear: number = new Date().getFullYear();

  constructor(private commonService:CommonService, private appConfigService: AppConfigService) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    this.footerName = appConfig.footerName;
    this.commonService.RefreshHeaderFun();
  }

  @HostListener('window:scroll')
  checkScroll() {
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let btn = document.getElementById('gototop');
    if (scrollPosition > 300) {
      btn.classList.add('show');
    } else {
      btn.classList.remove('show');
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
