import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EnquirySendBack } from 'src/app/Model/EnquirySendBack';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-send-back-comment',
  templateUrl: './send-back-comment.component.html',
  styleUrls: ['./send-back-comment.component.scss']
})
export class SendBackCommentComponent implements OnInit {
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  sendbackRules: EnquirySendBack[] = [];
  showLoader: boolean = false;
  Comment: any;
  @Input() sendBackObj;
  @Output() updateLoaderEvent = new EventEmitter<boolean>();
  sendbackComment: any;
  constructor(
    private activeModal: NgbActiveModal,
    private commonService: CommonService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  closePopup() {
    this.activeModal.close('Close Click');
  }
  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }

  rule: EnquirySendBack;
  SendBackSubmit(sendBackCommentForm: NgForm) {
    sendBackCommentForm.control.markAllAsTouched();
    if (sendBackCommentForm.invalid) {
      return;
    }
    this.rule = this.sendBackObj.finalrule;
    this.rule.EnquiryId = this.sendBackObj.EnquiryId;
    this.rule.Comment = this.sendbackComment;
    this.showLoader = true;
    let rules = JSON.stringify(this.rule);
    const frmData = new FormData();
    frmData.append("rule", rules);
    this.commonService.PostData("EnquiryWorkflowTransitionRules", "SetStatus", frmData, "", null).subscribe(data => {
      this.showLoader = false;
      this.toastr.success("Enquiry sent one step back");
      window.location.reload();
    }, error => {
      this.toastr.error("Failed to Enquiry sent one step back");
      this.showLoader = false;
    });

  }

}
