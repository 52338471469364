<loader [showLoader]="showLoader"></loader>
<div class="custsize">
    <!-- <div class="modal fade" id="ActivityModal" tabindex="-1" role="dialog"> -->

    <div class="modal-content custContent">

        <div class="modal-body">

            <div class="m-portlet m-portlet--head-solid-bg">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Close Enquiry Reason
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body">
                    <form class="m-form m-form--fit m-form--label-align-right" name="closeOpportunityForm"
                        (ngSubmit)="closeOpportunityForm.form.valid" #closeOpportunityForm="ngForm" novalidate>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-3  col-form-label">Select Reason:<span
                                    style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-7">
                                <select class="form-control " id="coreason" name="coreason" [(ngModel)]="EnquiryObj.EnquiryDetails.EnquiryConfirmReason"
                                    #coreason="ngModel"
                                    [ngClass]="{ 'is-invalid': (closeOpportunityForm.submitted && coreason.invalid) || (coreason?.touched && coreason.invalid) }"
                                    required>
                                    <option value="null" selected disabled>Select Enquiry Close Reason
                                    </option>
                                    <option *ngFor="let item of closeOpportunityList" value="{{item.Id}}">
                                        {{item.Value}}
                                    </option>
                                </select>
                                <div *ngIf="(closeOpportunityForm.submitted && coreason.invalid ) || (coreason?.touched && coreason.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="coreason.errors.required">
                                        Enquiry reason is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-3 col-form-label">Comments:<span style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" id="macomments" name="macomments" rows="3" maxlength="1500"
                                    [(ngModel)]="EnquiryObj.EnquiryDetails.Comments" (keyup)="limitTextOnKeyUpDown1($event)"
                                    (keypress)="limitTextOnKeyUpDown1($event)" #macomments="ngModel"
                                    [ngClass]="{ 'is-invalid': (closeOpportunityForm.submitted && macomments.invalid) || (macomments?.touched && macomments.invalid) }"
                                    required>
                                </textarea>
                                <span> Limit {{maxLimit}} characters,
                                    {{remLimit}} characters
                                    remaining.</span>
                                <div *ngIf="(closeOpportunityForm.submitted && macomments.invalid) || (macomments?.touched && macomments.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="macomments.errors.required">
                                        Comment is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal-footer p-2 pr-4" style="float: right;">
            <div class="button-row text-right mt-4" style="margin:15px;">

                <button type="submit" class="btn btn-primary btnspc"
                    (click)="CloseOpportunityReason(closeOpportunityForm)"
                    data-dismiss="modal">Submit</button>&nbsp;&nbsp;
                <button type="button" class="btn btn-metal" (click)="closePopup()" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>