import { Component, OnInit, Input } from '@angular/core';
import { SingleFieldModel, SingleFieldComponent } from 'src/app/Model/SingleFieldModel';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { element } from 'protractor';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-add-configuration',
  templateUrl: './add-configuration.component.html',
  styleUrls: ['./add-configuration.component.scss']
})
export class AddConfigurationComponent implements OnInit {

  singleFieldObj: SingleFieldModel = new SingleFieldModel();
  singleFieldComponent: SingleFieldComponent = new SingleFieldComponent();
  //CustomerSpecialDiscountObject:SingleFieldModel [] = Array<SingleFieldModel>();
  charCode: any;
  IndustrySegment: any[] = [];
  CategoryRegion: any[] = [];

  isdropdowninactive: boolean = false;


  SubSegment: any[] = [];
  TermsAndConditions: any[] = []
  @Input()
  showLoader: boolean = false;
  Roles: any[] = [];
  Customer: any[] = [];
  ProductModel: any[] = [];
  tempProductModel: any[] = [];
  NewArr: any[] = [];
  temp_ProductModel: any[] = [];
  ProductModelDiscountArr: SingleFieldModel[] = [];
  productModelrequired: boolean=false;
  productDiscountrequired: boolean;
  OtherComponent: boolean;
  constructor(private router: Router, private toastr: ToastrService, private CommonService: CommonService,
    public dialog: MatDialog, private route: ActivatedRoute, private titleService: Title, private appConfigService: AppConfigService) { }
  ngOnInit() {
    // alert(this.singleFieldObj.CustomerSpecialDiscountObject);
    this.AddNewRow(0);
    this.singleFieldComponent.RoutingParam = this.route.snapshot.params["heading"];
    if (this.singleFieldComponent.RoutingParam == "CustomerSpecialDiscount") {
      this.singleFieldObj.Fk_Customer = this.route.snapshot.params["id"]
      if (this.singleFieldObj.Fk_Customer != undefined) {
        this.isdropdowninactive = true;
      }
      else this.isdropdowninactive = false
    }

    this.showLoader = true;
    this.OtherComponent=true;
    switch (this.singleFieldComponent.RoutingParam) {
      case "MDSOption":
        this.singleFieldComponent.Heading = "MDS Option";
        this.singleFieldComponent.RoutingParam = "MDSOption";
        this.singleFieldComponent.ButtonText = "Add MDS Option";
        this.singleFieldComponent.ConfirmationText = "MDS option";
        this.singleFieldComponent.ControllerName = "MDSOptions";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "MDS option";
        this.singleFieldComponent.firstFiledLabel = "MDS Option";
        this.singleFieldComponent.firstFieldValidationMsg = "MDS option";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;
      case "SalesPrice":
        this.singleFieldComponent.Heading = "Sales Price Discount";
        this.singleFieldComponent.RoutingParam = "SalesPrice";
        this.singleFieldComponent.ButtonText = "Add Sales Price Discount";
        this.singleFieldComponent.ConfirmationText = "sales price";
        this.singleFieldComponent.ControllerName = "SalesPrice";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Sales price";
        this.singleFieldComponent.firstFiledLabel = "Sales Price";
        this.singleFieldComponent.firstFieldValidationMsg = "Sales price";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isExtraFields = true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = '';
        this.getAllRoles();
        // this.singleFieldComponent.FirstFiledPatternValidationMessage = "Please enter alphanumeric values ​​only";
        // this.singleFieldComponent.FirstFiledPatternValidationMessage = "Please enter alphanumeric values ​​only";
        // this.singleFieldComponent.FirstFiledPatternValidationMessage = "Please enter alphanumeric values ​​only";
        break;
      case "Country":
        this.singleFieldComponent.Heading = "Country";
        this.singleFieldComponent.RoutingParam = "Country";
        this.singleFieldComponent.ButtonText = "Add Country";
        this.singleFieldComponent.ConfirmationText = "country";
        this.singleFieldComponent.ControllerName = "Country";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Country";
        this.singleFieldComponent.SuccessText2 = "Country Code";
        this.singleFieldComponent.firstFiledLabel = "Country";
        this.singleFieldComponent.secondFiledLabel = "Country Code";
        this.singleFieldComponent.firstFieldValidationMsg = "Country";
        this.singleFieldComponent.SecondFieldValidationMsg = "Country Code";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.SecondFieldMaxLen = 10;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "Invalid";
        break;

      case "CustomerCategory":
        this.singleFieldComponent.Heading = "Customer Category";
        this.singleFieldComponent.RoutingParam = "CustomerCategory";
        this.singleFieldComponent.ButtonText = "Add Customer Category";
        this.singleFieldComponent.ConfirmationText = "customer category";
        this.singleFieldComponent.ControllerName = "CustomerCategory";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Customer category";
        this.singleFieldComponent.firstFiledLabel = "Customer Category";
        this.singleFieldComponent.firstFieldValidationMsg = "Customer category";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 150;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;
      case "CustomerClassification":
        this.singleFieldComponent.Heading = "Customer Classification";
        this.singleFieldComponent.RoutingParam = "CustomerClassification";
        this.singleFieldComponent.ButtonText = "Add Customer Classification";
        this.singleFieldComponent.ConfirmationText = "customer classification";
        this.singleFieldComponent.ControllerName = "CustomerClassification";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Customer classification";
        this.singleFieldComponent.firstFiledLabel = "Customer Classification";
        this.singleFieldComponent.firstFieldValidationMsg = "Customer classification";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 150;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;
      case "EnquirySource":
        this.singleFieldComponent.Heading = "Enquiry Source";
        this.singleFieldComponent.RoutingParam = "EnquirySource";
        this.singleFieldComponent.ButtonText = "Add Enquiry Source";
        this.singleFieldComponent.ConfirmationText = "enquiry source";
        this.singleFieldComponent.ControllerName = "EnquirySource";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Enquiry source";
        this.singleFieldComponent.firstFiledLabel = "Enquiry Source";
        this.singleFieldComponent.firstFieldValidationMsg = "Enquiry source";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 150;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;
      case "IndustrySegment":
        this.singleFieldComponent.Heading = "Industry Segment";
        this.singleFieldComponent.RoutingParam = "IndustrySegment";
        this.singleFieldComponent.ButtonText = "Add Industry Segment";
        this.singleFieldComponent.ConfirmationText = "industry segment";
        this.singleFieldComponent.ControllerName = "IndustrySegment";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Industry segment";
        this.singleFieldComponent.firstFiledLabel = "Industry Segment";
        this.singleFieldComponent.firstFieldValidationMsg = "Industry segment";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;
      case "SubSegment":
        this.singleFieldComponent.Heading = "Sub Segment";
        this.singleFieldComponent.RoutingParam = "SubSegment";
        this.singleFieldComponent.ButtonText = "Add Sub Segment";
        this.singleFieldComponent.ConfirmationText = "sub segment";
        this.singleFieldComponent.ControllerName = "SubSegment";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Sub segment";
        this.singleFieldComponent.firstFiledLabel = "Industry Segment";
        this.singleFieldComponent.secondFiledLabel = "Sub Segment";
        this.singleFieldComponent.firstFieldValidationMsg = "Industry segment";
        this.singleFieldComponent.SecondFieldValidationMsg = "Sub segment";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = true;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.SecondFieldMaxLen = 100;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.GetData("IndustrySegment", "GetAll");
        break;
      case "Region":
        this.singleFieldComponent.Heading = "Region";
        this.singleFieldComponent.RoutingParam = "Region";
        this.singleFieldComponent.ButtonText = "Add Region";
        this.singleFieldComponent.ConfirmationText = "region";
        this.singleFieldComponent.ControllerName = "Region";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Region";
        this.singleFieldComponent.SuccessText2 = "Region Code";
        this.singleFieldComponent.firstFiledLabel = "Region";
        this.singleFieldComponent.secondFiledLabel = "Region Code";
        this.singleFieldComponent.firstFieldValidationMsg = "Region";
        this.singleFieldComponent.SecondFieldValidationMsg = "Region Code";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.SecondFieldMaxLen = 10;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;
      case "TypeofCustomer":
        this.singleFieldComponent.Heading = "Type of Customer";
        this.singleFieldComponent.RoutingParam = "TypeofCustomer";
        this.singleFieldComponent.ButtonText = "Add Type of Customer";
        this.singleFieldComponent.ConfirmationText = "type of customer";
        this.singleFieldComponent.ControllerName = "CustomerType";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Type of customer";
        this.singleFieldComponent.firstFiledLabel = "Type of Customer";
        this.singleFieldComponent.firstFieldValidationMsg = "Type of customer";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;
      case "EstimatedProductValue":
        this.singleFieldComponent.Heading = "Estimated Product Value";
        this.singleFieldComponent.RoutingParam = "EstimatedProductValue";
        this.singleFieldComponent.ButtonText = "Add Estimated Product Value";
        this.singleFieldComponent.ConfirmationText = "estimated product value";
        this.singleFieldComponent.ControllerName = "EstimatedProductValue";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Estimated product value";
        this.singleFieldComponent.firstFiledLabel = "Estimated Product Value";
        this.singleFieldComponent.firstFieldValidationMsg = "Estimated product value";
        this.singleFieldComponent.secondFiledLabel = "Role";
        this.singleFieldComponent.SecondFieldValidationMsg = "Role"
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 50;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.getAllRoles();
        break;
      case "Currency":
        this.singleFieldComponent.Heading = "Currency";
        this.singleFieldComponent.RoutingParam = "Currency";
        this.singleFieldComponent.ButtonText = "Add Currency";
        this.singleFieldComponent.ConfirmationText = "currency";
        this.singleFieldComponent.ControllerName = "Currency";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Currency";
        this.singleFieldComponent.firstFiledLabel = "Currency";
        this.singleFieldComponent.secondFiledLabel = "Exchange Rate (€)";
        this.singleFieldComponent.firstFieldValidationMsg = "Currency";
        this.singleFieldComponent.SecondFieldValidationMsg = "Exchange rate";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = /^[0-9]\d*(\.\d+)?$/;
        this.singleFieldComponent.FirstFieldMaxLen = 50;
        this.singleFieldComponent.SecondFieldMaxLen = 6;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;
      case "TermsConditions":
        this.singleFieldComponent.Heading = "Terms & Conditions";
        this.singleFieldComponent.RoutingParam = "TermsConditions";
        this.singleFieldComponent.ButtonText = "Add Terms & Conditions";
        this.singleFieldComponent.ConfirmationText = "terms & conditions";
        this.singleFieldComponent.ControllerName = "TermsAndConditions";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Terms & conditions";
        this.singleFieldComponent.firstFiledLabel = "Terms & Condition";
        this.singleFieldComponent.secondFiledLabel = "Standard Value";
        this.singleFieldComponent.firstFieldValidationMsg = "Terms & condition";
        this.singleFieldComponent.SecondFieldValidationMsg = "Standard Value";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 50;
        this.singleFieldComponent.SecondFieldMaxLen = 100;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;
      case "StandardReasonforWinningLosing":
        this.singleFieldComponent.Heading = "Standard Reason";
        this.singleFieldComponent.RoutingParam = "StandardReasonforWinningLosing";
        this.singleFieldComponent.ButtonText = "Add Standard Reason";
        this.singleFieldComponent.ConfirmationText = "standard reason for winning/losing";
        this.singleFieldComponent.ControllerName = "ReasonEnquiryWinLoss";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Standard reason for winning/losing";
        this.singleFieldComponent.firstFiledLabel = "Enquiry Status";
          this.singleFieldComponent.secondFiledLabel = "Select Catagory";
          this.singleFieldComponent.thirdFieldLabel = "Reason for Winning/Losing";
        this.singleFieldComponent.firstFieldValidationMsg = "Enquiry status";
          this.singleFieldComponent.SecondFieldValidationMsg = "Required";
          this.singleFieldComponent.ThirdFieldValidationMsg = "Reason for winning/losing";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isTripleField = true;
        this.singleFieldComponent.isDropdownrequird = true;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 10;
        this.singleFieldComponent.SecondFieldMaxLen = 100;
        this.singleFieldComponent.ThirdFieldMaxLen = 100;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
          this.getAllCategoryRegion();
        break;
      case "EnquiryCloseReason":
        this.singleFieldComponent.Heading = "Enquiry Close Reason";
        this.singleFieldComponent.RoutingParam = "EnquiryCloseReason";
        this.singleFieldComponent.ButtonText = "Add Enquiry Close Reason";
        this.singleFieldComponent.ConfirmationText = "Enquiry Close Reason";
        this.singleFieldComponent.ControllerName = "EnquiryCloseReason";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Enquiry Close Reason";
        this.singleFieldComponent.firstFiledLabel = "Enquiry Close Reason";
        this.singleFieldComponent.firstFieldValidationMsg = "Enquiry Close Reason";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.SecondFieldMaxLen = 100;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;

        case "LeadCloseReason":
          this.singleFieldComponent.Heading = "Lead Close Reason";
          this.singleFieldComponent.RoutingParam = "LeadCloseReason";
          this.singleFieldComponent.ButtonText = "Add Lead Close Reason";
          this.singleFieldComponent.ConfirmationText = "Lead close reason";
          this.singleFieldComponent.ControllerName = "OpportunityCloseReason";
          this.singleFieldComponent.ActionName = "Add";
          this.singleFieldComponent.SuccessText = "Lead close reason";
          this.singleFieldComponent.firstFiledLabel = "Lead Close Reason";
          this.singleFieldComponent.firstFieldValidationMsg = "Lead Close Reason";
          this.singleFieldComponent.isDoubleField = false;
          this.singleFieldComponent.isDropdownrequird = false;
          this.singleFieldComponent.FirstFieldMaxLen = 100;
          this.singleFieldComponent.SecondFieldMaxLen = 100;
          this.singleFieldComponent.Pattern = "";
          this.singleFieldComponent.FirstFiledPattern = "";
          this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
          break;

      case "EnquiryCompetitor":
        this.singleFieldComponent.Heading = "Enquiry Competitor";
        this.singleFieldComponent.RoutingParam = "EnquiryCompetitor";
        this.singleFieldComponent.ButtonText = "Add Enquiry Competitor";
        this.singleFieldComponent.ConfirmationText = "Enquiry Competitor";
        this.singleFieldComponent.ControllerName = "EnquiryCompetitor";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Enquiry Competitor";
        this.singleFieldComponent.firstFiledLabel = "Enquiry Competitor";
        this.singleFieldComponent.firstFieldValidationMsg = "Enquiry Competitor";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 100;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;

      case "CustomerGroup":
        this.singleFieldComponent.Heading = "Customer Group";
        this.singleFieldComponent.RoutingParam = "CustomerGroup";
        this.singleFieldComponent.ButtonText = "Add Customer Group";
        this.singleFieldComponent.ConfirmationText = "customer group";
        this.singleFieldComponent.ControllerName = "CustomerGroup";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Customer group";
        this.singleFieldComponent.firstFiledLabel = "Customer Group";
        this.singleFieldComponent.secondFiledLabel = "Customer Group Code";
        this.singleFieldComponent.firstFieldValidationMsg = "Customer Group";
        this.singleFieldComponent.SecondFieldValidationMsg = "Customer Group Code";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 150;
        this.singleFieldComponent.SecondFieldMaxLen = 150;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;

      case "TechnicalDiscardReason":
        this.singleFieldComponent.Heading = "Technical Discard Reason";
        this.singleFieldComponent.RoutingParam = "TechnicalDiscardReason";
        this.singleFieldComponent.ButtonText = "Add Technical Discard Reason";
        this.singleFieldComponent.ConfirmationText = "Technical discard reason";
        this.singleFieldComponent.ControllerName = "TechnicalDiscard";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Technical discard reason";
        this.singleFieldComponent.firstFiledLabel = "Technical Discard Reason";
        this.singleFieldComponent.firstFieldValidationMsg = "Technical Discard Reason";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFieldMaxLen = 50;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;


      case "TermsConditions-NonStandard":
        this.singleFieldComponent.Heading = "Terms & Conditions-Non Standard";
        this.singleFieldComponent.RoutingParam = "TermsConditions-NonStandard";
        this.singleFieldComponent.ButtonText = "Add Non Standard Terms & Conditions";
        this.singleFieldComponent.ConfirmationText = "non standard terms & conditions";
        this.singleFieldComponent.ControllerName = "TermsAndConditionsCustomized";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Terms & conditions-Non Standard";
        this.singleFieldComponent.firstFiledLabel = "Terms & Condition-Non Standard";
        this.singleFieldComponent.secondFiledLabel = "Non Standard Value";
        this.singleFieldComponent.TableHeader1 = "Terms & Conditions-Non Standard";
        this.singleFieldComponent.TableHeader2 = "Non Standard Values";
        this.singleFieldComponent.firstFieldValidationMsg = "Non standard term & condition";
        this.singleFieldComponent.SecondFieldValidationMsg = "Non standard value";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isDropdownrequird = true;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.SecondFieldMaxLen = 100;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.GetData("TermsAndConditions", "GetAll");
        break;

      case "Application":
        this.singleFieldComponent.Heading = "Application";
        this.singleFieldComponent.RoutingParam = "Application";
        this.singleFieldComponent.ButtonText = "Add Application";
        this.singleFieldComponent.ConfirmationText = "Application";
        this.singleFieldComponent.ControllerName = "Application";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Application";
        this.singleFieldComponent.firstFiledLabel = "Industry Segment";
        this.singleFieldComponent.secondFiledLabel = "Sub Segment";
        this.singleFieldComponent.thirdFieldLabel = "Application Name";
        this.singleFieldComponent.TableHeader1 = "Industry Segment";
        this.singleFieldComponent.TableHeader2 = "Sub Segment";
        this.singleFieldComponent.TableHeader3 = "Application";
        this.singleFieldComponent.firstFieldValidationMsg = "Industry Segment";
        this.singleFieldComponent.SecondFieldValidationMsg = "Sub Segment";
        this.singleFieldComponent.thirdFieldValidationMsg = "Application Name";
        this.singleFieldComponent.isDoubleField = true;
        this.singleFieldComponent.isTripleField = true;
        this.singleFieldComponent.isDropdownrequird = true;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.ThirdFieldMaxLen = 250;
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        this.singleFieldComponent.SecondFieldPattern = "";
        this.singleFieldComponent.SecondFieldPatternValidationMessage = "";
        this.singleFieldComponent.ThirdFieldPattern = "";
        this.singleFieldComponent.ThirdFieldPatternValidationMessage = "";
        this.GetData("IndustrySegment", "GetAll");
       
        break;


      case "CustomerSpecialDiscount":
       
        this.router.navigate(['/AddContractFrameAgreementComponent/0']);
        // this.singleFieldComponent.Heading = "Customer Frame Agreement";
        // this.singleFieldComponent.RoutingParam = "CustomerSpecialDiscount";
        // this.singleFieldComponent.ButtonText = "Add Customer Frame Agreement";
        // this.singleFieldComponent.ConfirmationText = "Customer Frame Agreement";
        // this.singleFieldComponent.ControllerName = "CustomerSpecialDiscount";
        // this.singleFieldComponent.ActionName = "Add";
        // this.singleFieldComponent.SuccessText = "Customer Frame Agreement";

        // this.singleFieldComponent.firstFiledLabel = "Frame Agreement";
        // this.singleFieldComponent.secondFiledLabel = "Product Model";
        // this.singleFieldComponent.thirdFiledLabel = "Customer Name";
        // this.singleFieldComponent.firstFieldValidationMsg = "Customer Name";
        // this.singleFieldComponent.SecondFieldValidationMsg = "Product Model";
        // this.singleFieldComponent.ThirdFieldValidationMsg = "Product Model";
        // this.singleFieldComponent.isDoubleField = false;
        // // this.singleFieldComponent.isDropdownrequird = false;
        // this.singleFieldComponent.isvariablediscountfield = true;
        // this.singleFieldComponent.isthirdfielddropdown = true;
        // this.singleFieldComponent.issecondfielddropdown = true;
        // this.singleFieldComponent.ismultiplerecords = true;
        // this.singleFieldComponent.Pattern = "";
        // this.singleFieldComponent.FirstFieldMaxLen = 100;
        // this.singleFieldComponent.SecondFieldMaxLen = 100;
        // this.singleFieldComponent.FirstFiledPattern = "";
        // this.singleFieldComponent.FirstFiledPatternValidationMessage = "";

        // this.getAllCustomer("Customer", "GetAll");

        // this.getAllProductModel("ProductModel", "GetAllModel");
       
        break;

      case "RoleManagement":
        this.singleFieldComponent.Heading = "Role Management";
        this.singleFieldComponent.RoutingParam = "RoleManagement";
        this.singleFieldComponent.ButtonText = "Add Role";
        this.singleFieldComponent.ConfirmationText = "Role Management";
        this.singleFieldComponent.ControllerName = "Account";
        this.singleFieldComponent.ActionName = "Add";
        this.singleFieldComponent.SuccessText = "Role Management";
        this.singleFieldComponent.firstFiledLabel = "Role Management";
        this.singleFieldComponent.firstFieldValidationMsg = "Role Management";
        this.singleFieldComponent.isDoubleField = false;
        this.singleFieldComponent.isDropdownrequird = false;
        this.singleFieldComponent.FirstFieldMaxLen = 150;
        this.singleFieldComponent.Pattern = "";
        this.singleFieldComponent.FirstFiledPattern = "";
        this.singleFieldComponent.FirstFiledPatternValidationMessage = "";
        break;


    }
    const appConfig = this.appConfigService.getConfig();
    this.titleService.setTitle(this.singleFieldComponent.ButtonText + " - " + appConfig.titleName);
    //this.titleService.setTitle(this.singleFieldComponent.ButtonText + " - ECOTECH");
    this.showLoader = false;
  }


  /*******************************************************************************
     * Get data
     * ******************************************************************************/
  GetData(ControllerName: string, ActionName: string) {
    this.showLoader = true;

    this.CommonService.GetData(ControllerName, ActionName, null).subscribe((data: any) => {
      if (ControllerName === 'IndustrySegment') { this.IndustrySegment = data; }
      else if (ControllerName === 'SubSegment') { this.SubSegment = data; }
      else { this.TermsAndConditions = data; }

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }


  getAllCustomer(ControllerName: string, ActionName: string) {
    this.showLoader = true;
    this.CommonService.GetData(ControllerName, ActionName, null).subscribe((data: any) => {
      this.Customer = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getAllProductModel(ControllerName: string, ActionName: string) {
    this.showLoader = true;
    this.CommonService.GetData(ControllerName, ActionName, null).subscribe((data: any) => {
      this.ProductModel = data;
      this.tempProductModel = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getAllRoles() {
    this.showLoader = true;
    this.CommonService.GetData("Account", "GetAllEmployeeRoles", null).subscribe((data: any) => {
      this.Roles = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }



  //  **************************** For Validation of Discount ******************************

  OnDiscountChange(index) {

    if(this.singleFieldObj.customerSpecialDiscountInfo[index].ProductDiscount==undefined || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel==null)
    {
      this.singleFieldObj.customerSpecialDiscountInfo[index].ProductDiscount=0;
    }

    if (this.singleFieldObj.customerSpecialDiscountInfo[index].ProductDiscount < 0) {
      this.toastr.info(' Discount cannot be negative.');
      this.singleFieldObj.customerSpecialDiscountInfo[index].ProductDiscount = 0;
      return;
    }
    else if (this.singleFieldObj.customerSpecialDiscountInfo[index].ProductDiscount > 100) {
      this.toastr.info('Discount cannot be greater than 100.');
      this.singleFieldObj.customerSpecialDiscountInfo[index].ProductDiscount = 0;
      return;
    }
  }

  //**********************Discount change for to and from******************************** */

  /*OnDiscountChangeToFrom(index)
  {
    if (this.singleFieldObj[index].DiscountFrom < 0 || this.singleFieldObj[index].DiscountTo<0) {
      alert(' Discount cannot be negative.');
      this.singleFieldObj[index].DiscountFrom = null;
      return;
    }
    else if (this.singleFieldObj[index].DiscountFrom > 100|| this.singleFieldObj[index].DiscountTo > 100) {
      alert('Discount cannot be greater than 100.');
      this.singleFieldObj[index].DiscountTo = null;
      return; 
    }
    else if(this.singleFieldObj[index].DiscountFrom>this.singleFieldObj[index].DiscountTo)
    {
      alert('Discount To must be less than Discount From');
      this.singleFieldObj[index].DiscountTo=null;
    }
  
  
  }
  */
  onIndustrySegmentSelect(e: any) {
    this.GetSubSegment(e.target.value);
  }

  //Get sub segment by industry segment id
  GetSubSegment(SegmentId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "SegmentId=" + SegmentId;
    this.CommonService.GetData("SubSegment", "GetByIndustrySegmentId", queryString).subscribe((data: any) => {
      this.SubSegment = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  /*******************************************************************************
     * End Get data
     * ******************************************************************************/

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

   isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if
      (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;

    }
    else {
      return true;
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onSubmit(myform: any) {

    this.productModelrequired=false;
    if (this.singleFieldComponent.ismultiplerecords) {
      if (this.singleFieldObj.customerSpecialDiscountInfo.length == 0) {
        this.singleFieldObj.customerSpecialDiscountInfo[0].ProductModelRequired = true;
        this.showLoader = false;
        return;
      }
      else {
        this.singleFieldObj.customerSpecialDiscountInfo.forEach(element => {
          if (element.Fk_ProductModel == null || element.Fk_ProductModel == undefined || element.Fk_ProductModel == 0 ) {
            element.ProductModelRequired = true;
          }
          else {
            element.ProductModelRequired = false;
          }          
        });
      }
    }
    this.singleFieldObj.customerSpecialDiscountInfo.forEach(element => {
      if (element.ProductModelRequired) {
        this.productModelrequired=true;
        this.showLoader = false;
        return;
      }
    });

    if (this.productModelrequired == false) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "You want to add this " + this.singleFieldComponent.ConfirmationText + "?"
        }
      });

      dialogRef.afterClosed().subscribe(dialogResult => {

        if (dialogResult) {
          this.showLoader = true;

          if (this.singleFieldComponent.ismultiplerecords) {         
            this.CommonService.PostData(this.singleFieldComponent.ControllerName, 'Add', this.singleFieldObj.customerSpecialDiscountInfo, null, this.singleFieldObj.Fk_Customer).subscribe((data: any) => {
              let result = data;

              this.showLoader = false;
              if (result.ResponseCode == 1) {
                this.toastr.success(this.singleFieldComponent.SuccessText + ' added successfully!');
                this.router.navigate(['/ConfigureMasters', this.singleFieldComponent.RoutingParam]);
              }
              else if (data.ResponseCode == 0) {
                // myform.controls['fieldtitle'].setErrors({ AlreadyPresent: true });
                this.toastr.warning(this.singleFieldComponent.SuccessText + ' already exists!');
                this.showLoader = false;
              }
              else {
                this.toastr.error('Failed to add ' + this.singleFieldComponent.ConfirmationText + '!');
                this.router.navigate(['/ConfigureMasters', this.singleFieldComponent.RoutingParam]);
              }
            }, error => {
              this.toastr.error('Failed to add ' + this.singleFieldComponent.ConfirmationText + '!');
              this.showLoader = false;
              this.router.navigate(['/ConfigureMasters', this.singleFieldComponent.RoutingParam]);
            });
          }
          else
            this.CommonService.PostData(this.singleFieldComponent.ControllerName, 'Add', this.singleFieldObj, null).subscribe((data: any) => {
              let result = data;

              this.showLoader = false;
              if (result.ResponseCode == 1) {
                this.toastr.success(this.singleFieldComponent.SuccessText + ' added successfully!');
                this.router.navigate(['/ConfigureMasters', this.singleFieldComponent.RoutingParam]);
              }
              else if (data.ResponseCode == 0) {
                // myform.controls['fieldtitle'].setErrors({ AlreadyPresent: true });
                this.toastr.warning(this.singleFieldComponent.SuccessText + ' already exists!');
                this.showLoader = false;
              }
              else if (data.ResponseCode == 2) {
                // myform.controls['fieldtitle'].setErrors({ AlreadyPresent: true });
                this.toastr.warning(this.singleFieldComponent.SuccessText2 + ' already exists!');
                this.showLoader = false;
              }

              else {
                this.toastr.error('Failed to add ' + this.singleFieldComponent.ConfirmationText + '!');
                this.router.navigate(['/ConfigureMasters', this.singleFieldComponent.RoutingParam]);
              }
            }, error => {
              this.toastr.error('Failed to add ' + this.singleFieldComponent.ConfirmationText + '!');
              this.showLoader = false;
              this.router.navigate(['/ConfigureMasters', this.singleFieldComponent.RoutingParam]);
            });
        }
      });
    }

  }

  deleterow(index: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to delete this row?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (index != null) {
          this.singleFieldObj.customerSpecialDiscountInfo.splice(index, 1);
        } else {
          this.singleFieldObj.customerSpecialDiscountInfo.pop();
        }
      }
    });
  }

  testing: any[] = [];

  showItems: Array<{ id: number, value: any[] }>;
  OnSelectProductModel(selectedValue, index) {
    var selectedItem = this.tempProductModel.filter(x => x.Id != selectedValue);
    this.testing.push(selectedItem);
    this.showItems[index].value = this.tempProductModel.filter(x => x.Id == selectedValue);

  }

  AddNewRow(index) {
    if (this.singleFieldObj.Fk_ProductModel != undefined
      && this.singleFieldObj.ProductDiscount != undefined) {

      this.singleFieldObj.IsRowDeleted = true;
      var obj = new SingleFieldModel();
      obj.Fk_ProductModel = this.singleFieldObj.Fk_ProductModel;
      obj.ProductDiscount = this.singleFieldObj.ProductDiscount

      this.singleFieldObj.ProductDiscount = 0;
      this.singleFieldObj.Fk_ProductModel = 0;
      this.singleFieldObj.customerSpecialDiscountInfo.push(obj);
    }
    else{
      if(this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=0 || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=undefined || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=null)
      {
        this.singleFieldObj.customerSpecialDiscountInfo[index].ProductModelRequired=false;
      }
      
    }
  }

  OnProductModelChange(index)
  {
    if(this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=0 || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=undefined || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=null)
    {
      this.singleFieldObj.customerSpecialDiscountInfo[index].ProductModelRequired=false;
    }
    else{
      this.singleFieldObj.customerSpecialDiscountInfo[index].ProductModelRequired=true;
    }

  }

  fieldChange(value)
  {
    console.log('checkbox value --->',value);
    if(value == true)
    { 
      this.singleFieldObj.IsCompetitorRequired = true;
    }
    else
    {
      this.singleFieldObj.IsCompetitorRequired = false;
    }
    
  }

  getAllCategoryRegion() {
    this.showLoader = true;
    this.CommonService.GetData("ReasonEnquiryWinLoss", "GetAllReasonCategory", null).subscribe((data: any) => {
      this.CategoryRegion = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  isintNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 46 || this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }


  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }
}
