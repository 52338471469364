export class SiteActivityLogModel {
    Pk_LogId: number;
    User_Id: string = '';
    User_Name: string = '';
    Name: string = '';
    Http_Method: string = "";
    Browser_Info: string = '';
    EmpCode: string = '';
    Ip_Address: string = '';
    Route: string = '';
    Payload: string = '';
    CreatedOn: any;
}

export class SiteActivityLogGridFilterList {
    InitiatorName: SiteActivityGridDropDown[] = [];
}
export class SiteActivityGridDropDown {
    User_Name: string = "";
    Id: string = "";
    Name: string = "";
}

export class SiteActivityPaginationGrid {
    pageNumber: number = 0;
    pageSize: number = 0;
    //SearchText: string = "";
    direction: string = "";
    ColumnName: string = "";

    EnquiryDateFromFiter: string = null;
    EnquiryDateToFiter: string = null;
    InitiatorNameFilter: string[] = [];

}