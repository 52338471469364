import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmployeeGuestModel } from 'src/app/Model/EmployeeGuestModel';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Title } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit {
  EmployeeDetails: EmployeeGuestModel = new EmployeeGuestModel();
  emailPattern: string = "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  //passPattern: any = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,30}$/;
  passPattern: any = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,30}';
  isValidEmail: boolean = false;
  Country: any[] = [];
  Roles: any[] = [];
  Region: any[] = [];
  EmployeeData: any[] = [];
  UserId: number;
  charCode: any;
  mailIdFormat : any;

  @Input()
  showLoader: boolean = false;
  ConfirmPasshide: boolean = true;
  Passhide: boolean = true;

  constructor(private router: Router, private toastr: ToastrService, private Service: CommonService, public dialog: MatDialog
    , private route: ActivatedRoute, private titleService: Title, private appConfigService : AppConfigService) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    this.mailIdFormat = appConfig.mailIdFormat;
    //this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.getAllEmployeeData();
    this.getAllCountry();
    this.getAllRoles();
    this.getAllRegion();

  }

  /*******************************************************************************
     * Get data for add employee
     * ******************************************************************************/
  getAllCountry() {
    this.showLoader = true;
    this.Service.GetData("Country", "GetAll", null).subscribe((data: any) => {
      this.Country = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  getAllRoles() {
    this.showLoader = true;
    this.Service.GetData("Account", "GetAllEmployeeRoles", null).subscribe((data: any) => {
      this.Roles = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  getAllRegion() {
    this.showLoader = true;
    this.Service.GetData("Region", "GetAll", null).subscribe((data: any) => {
      this.Region = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getAllEmployeeData() {
    this.showLoader = true;
    this.Service.GetData("Employee", "GetAllEmployeeData", null).subscribe((data: any) => {
      this.EmployeeData = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  /*******************************************************************************
   * End Get data for add employee
   * ******************************************************************************/


  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/


  ChangeEmployee(value: any) {
    var employee = this.EmployeeData?.filter(x => x.Fk_User_Id == value);
    this.EmployeeDetails.ReportingManagerEmail = employee[0].Email;
  }

  onSubmit(myform: any) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this employee?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.EmployeeDetails.RoleType = 2;
        this.showLoader = true;
        this.Service.PostData('Account', 'Register', this.EmployeeDetails, null).subscribe((data: any) => {
          let result = data;
          this.showLoader = false;
          if (result.ResponseCode == 1) {
            this.toastr.success('Employee details added successfully!');
            this.router.navigate(['/Employee']);
          }
          if (result.ResponseCode == 0) {
            this.toastr.warning('Username already exists!');
            myform.controls['username'].setErrors({ AlreadyPresent: true });
          }
          if (result.ResponseCode == 2) {
            this.toastr.warning('Employee code already exists!');
            myform.controls['empcode'].setErrors({ AlreadyPresent: true });
          }
          if (result.ResponseCode == 3) {
            this.toastr.warning('Contact number already exists!');
            myform.controls['contactnumber'].setErrors({ AlreadyPresent: true });
          }
          if (result.ResponseCode == 4) {
            this.toastr.warning('Employee code and contact number already exists!');
            myform.controls['empcode'].setErrors({ AlreadyPresent: true });
            myform.controls['contactnumber'].setErrors({ AlreadyPresent: true });
          }

        }, error => {
          this.toastr.error('Failed to add employee details!');
          this.showLoader = false;
          this.router.navigate(['/Employee']);
        });
      }
    });

  }

}
