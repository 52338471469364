<div class="row">
    <div class="col-sm-12">
        <div class="m-portlet">

            <div class="m-portlet__body">

                <div class="col-lg-12 mb-4 table-responsive" *ngIf="!isprocessing">
                    <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="myform10"
                        #myform10="ngForm" appFocusInvalidInput novalidate>
                        <div class="multisteps-form__panel shadow rounded bg-white js-active" data-animation="fadeIn">
                            <div class="multisteps-form__content">
                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <div
                                                    *ngIf="!(AppGlobals.ManagementApproval_Order_ApproveByCEO ||
                                                                            AppGlobals.ManagementApproval_Order_RejectByCEO) && AppGlobals.isCEOApprovalEditable">
                                                    <h3 class="m-portlet__head-text">
                                                        CEO Approval - Quotation
                                                    </h3>
                                                </div>
                                                <div
                                                    *ngIf="(AppGlobals.ManagementApproval_Order_ApproveByCEO ||
                                                                            AppGlobals.ManagementApproval_Order_RejectByCEO) && AppGlobals.isCEOApprovalEditable">
                                                    <h3 class="m-portlet__head-text">
                                                        CEO Approval - Order
                                                    </h3>
                                                </div>
                                                <div *ngIf="!AppGlobals.isCEOApprovalEditable">
                                                    <h3 class="m-portlet__head-text">
                                                        CEO Approval
                                                    </h3>
                                                </div>

                                            </div>
                                          
                                        </div>
                                        <div class="m-portlet__head-tools">
                                            <button *ngIf="this.UserRole == roleOfAdmin"
                                                class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                                (click)="SendBack(myform10)">Send Back</button>
                                        </div>

                                    </div>
                                    <div class="markedFields">
                                        <span style="margin-left:10px;">* Marked fields are
                                            mandatory</span>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Customer
                                                Name:</label>
                                            <div class="col-lg-3" style="padding-top:9px;">
                                                {{EnquiryObj.EnqCustomerDetails.CustomerName}}
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Segment:</label>
                                            <div class="col-lg-4" style="padding-top:9px;">
                                                {{EnquiryObj.EnqCustomerDetails.IndustrySegment}}
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Type of
                                                Customer:</label>
                                            <div class="col-lg-3" style="padding-top:9px;">
                                                {{EnquiryObj.EnqCustomerDetails.CustomerType}}
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Region:</label>
                                            <div class="col-lg-3" style="padding-top:9px;">
                                                {{EnquiryObj.EnqCustomerDetails.Region}}
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Terms and
                                                Conditions:</label>
                                            <div class="col-lg-3" style="padding-top:9px;">
                                                {{EnquiryObj.EnquiryDetails.RFQTermsAndCondition}}
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Quotation
                                                Amount:</label>
                                            <div class="col-lg-4" style="padding-top:9px;">
                                                €
                                                {{EnquiryObj.EnquiryDetails.FinalAmount |
                                                indianCurrency}}/-
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Contact
                                                Person:</label>
                                            <div class="col-lg-3" style="padding-top:9px;">
                                                {{EnquiryObj.EnquiryDetails.KindAttention}}
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">
                                                BOM (Bill Of Materials):</label>
                                            <div class="col-lg-3 pt-2 filenamemobile">
                                                <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.BOM_filepath}}"
                                                    target="_blank">{{EnquiryObj.EnquiryDetails.BOM_filename}}</a>
                                            </div>
                                        </div>
                                        <div *ngIf="!(EnquiryObj.EnquiryDetails.PO_filename == null ||
                                                                    EnquiryObj.EnquiryDetails.PO_filename == undefined||
                                                                    EnquiryObj.EnquiryDetails.PO_filename == '')"
                                            class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">PO
                                                Value:</label>
                                            <div class="col-lg-3" style="padding-top:9px;">
                                                € {{EnquiryObj.EnquiryDetails.POValue |
                                                indianCurrency}}/-
                                            </div>

                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">PO
                                                Attachment:</label>
                                            <div class="col-lg-3 pt-2 filenamemobile">
                                                <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.PO_filepath}}"
                                                    target="_blank">{{EnquiryObj.EnquiryDetails.PO_filename}}</a>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">View
                                                Quotation:</label>
                                            <div class="col-lg-3 pt-2 filenamemobile">
                                                <a target="_blank"
                                                    href="{{QuotationDownloadPath}}{{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}">
                                                    {{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}
                                                </a>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label *ngIf="AppGlobals.isCEOApprovalEditable"
                                                class="col-lg-2 col-form-label">Attachment(s):</label>
                                            <div *ngIf="AppGlobals.isCEOApprovalEditable" class="col-lg-4 pt-1">
                                                <div class="file btn btn-sm btn-warning">
                                                    Upload
                                                    <input type="file" class="form-control-file" id="ceofile"
                                                        name="ceofile" #myInput
                                                        (change)="getMultipleFileDetails($event)" multiple />
                                                </div>
                                                <br /><span class="fileext">
                                                    {{AppGlobals.supportedFileFormatsForOtherMultipleAttachments}}</span>
                                                <span
                                                    *ngIf="EnquiryObj.EnquiryDetails.QuoteGenAttachments != undefined && EnquiryObj.EnquiryDetails.QuoteGenAttachments.length > 0">
                                                    <ul>
                                                        <ng-container
                                                            *ngFor="let item of EnquiryObj.EnquiryDetails.QuoteGenAttachments;let i=index;">
                                                            <li class="filenamemobile">
                                                                {{item.name}}&nbsp;&nbsp;<i class="flaticon-delete"
                                                                    style="color:#f4516c"
                                                                    (click)="removeQuoteGenAttachments(i)"
                                                                    title="Remove"></i></li>
                                                        </ng-container>
                                                    </ul>

                                                </span>
                                            </div>

                                        </div>

                                        <div *ngIf="AppGlobals.isCEOApprovalEditable"
                                            class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Comments:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-9">
                                                <textarea class="form-control m-input" id="ceocomments"
                                                    name="ceocomments" rows="3" maxlength="1500"
                                                    [(ngModel)]="EnquiryObj.EnquiryDetails.Comments"
                                                    (keyup)="limitTextOnKeyUpDown1($event)"
                                                    (keypress)="limitTextOnKeyUpDown1($event)" #ceocomments="ngModel"
                                                    [ngClass]="{ 'is-invalid': (myform10.submitted && ceocomments.invalid) || (ceocomments?.touched && ceocomments.invalid) }"
                                                    required>
                                                                            </textarea>
                                                <span> Limit {{maxLimit}} characters,
                                                    {{remLimit}} characters
                                                    remaining.</span>
                                                <div *ngIf="(myform10.submitted && ceocomments.invalid) || (ceocomments?.touched && ceocomments.invalid)"
                                                    class="invalid-feedback">
                                                    <div *ngIf="ceocomments.errors.required">
                                                        Comment is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="button-row text-right mt-4" style="margin:15px;">
                                    <div>
                                        <div class="m-portlet__head-caption">
                                            <span *ngFor="let item of workflowActionList;let i = index;">
                                                <button class="btn btn-primary ml-auto js-btn-next btnspc"
                                                    type="{{item.workflowActionType}}"
                                                    (click)="OnWorkflowAction(myform10,item.workflowActionId)">
                                                    {{item.workflowActionName}}
                                                </button> &nbsp; &nbsp;
<!-- 
                                                <button *ngIf="this.UserRole == roleOfAdmin"
                                                    class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                                    (click)="SendBack(myform10)">Send Back</button> &nbsp; &nbsp; -->

                                            </span>

                                            <button class="btn btn-metal m-btn m-btn--air m-btn--custom" type="button"
                                                (click)="backtoList()" routerLinkActive="active">Cancel</button>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>


            <!-- </div>
                </div> -->
            <!-- </div> -->
        </div>
    </div>
</div>