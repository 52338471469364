import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Level1Model, ProductSeries, VariantLevel3 } from 'src/app/Model/ProductModel';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { MatPaginator } from '@angular/material/paginator';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-variant-level3-details',
  templateUrl: './variant-level3-details.component.html',
  styleUrls: ['./variant-level3-details.component.scss']
})
export class VariantLevel3DetailsComponent implements OnInit {
  displayedColumns: string[] = ['SeriesName', 'ModelName','Level1Name', 'Level2', 'Level2Price', 'otherType', 'Level3', 'Level3Price', 'Action'];
  dataSource: MatTableDataSource<VariantLevel3>;
  disabled: boolean = false;
  SeriesName: string = "";
  isVariant3DependsOnVariant2: boolean = false;
  isVariant2DependsOnVariant1: boolean = false;
  UserRole: any;
  isProductVariant3Editable: boolean = false;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];


  /*************************************************************************
        Server side Pagination purpose
    *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';
  ColumnName: string = 'ModifiedOn';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /*************************************************************************
     End here
  *************************************************************************/
  ProductSeriesVariant1Label: string = '';
  ProductSeriesVariant2Label: string = '';
  ProductSeriesVariant3Label: string = '';
  ProductSeriesId: number;
  showLoader: boolean;
  data: any[] = [];
  ProductSeriesArr: ProductSeries = new ProductSeries();
  VariantDrpdown: string = "";
  constructor(private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private titleService: Title,
    private accessservice: AccessPermissionService, private appConfigService : AppConfigService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    // this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
    //this.isProductVariant3Editable = AppGlobals.ProductMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.route.params.subscribe(params => {
      this.ProductSeriesVariant2Label = params['Variant2'];
      this.ProductSeriesVariant3Label = params['Variant3'];
      this.ProductSeriesId = params['Seriesid'];
      this.pageSize = 5;
      this.pageNumber = 1;
      this.pageIndex = this.pageNumber - 1;
      this.length = 0;
      this.direction = 'desc';
      this.ColumnName = 'ModifiedOn';
      this.SearchText = "";
      this.VariantDrpdown = "Variant3";
      this.GetAllVariantLevel3Details();
      this.getAllProductSeries();
    });


  }


  /*************************************************************************
   * Below function used for Server side Pagination,Sorting and Searching
   * (1)GetAllVariantLevel3Details() - To get data for grid
   * (2)SearchParam() -  searching
   * (3)sortData() - Sorting
   * (4)pageEvent() - Pagination
   * 
   * ***********************************************************************/

  GetAllVariantLevel3Details() {
    this.showLoader = true;
    let queryString = "";
    queryString = "pageNumber=" + this.pageNumber;
    queryString += "&pageSize=" + this.pageSize;
    queryString += "&searchText=" + this.SearchText;
    queryString += "&direction=" + this.direction;
    queryString += "&columnName=" + this.ColumnName;
    queryString += "&ProductSeriesId=" + this.ProductSeriesId; //Seires Id

    this.CommonService.GetData('ProductVariantLevel3', 'GetAllPagination', queryString).subscribe((data: any) => {
      this.data = data.Data;
      this.length = data.Total;
      if (this.ColumnName == 'Level2Price') {
        var IsPriceOnRequestList: VariantLevel3[] = [];
        var IsNotPriceOnRequestList: VariantLevel3[] = [];

        IsPriceOnRequestList = this.data.filter(x => x.Level2IsPriceOnRequest == true);
        IsNotPriceOnRequestList = this.data.filter(x => x.Level2IsPriceOnRequest != true);
        IsPriceOnRequestList = IsPriceOnRequestList.concat(IsNotPriceOnRequestList);
        this.dataSource = new MatTableDataSource(IsPriceOnRequestList);
      }
      else if (this.ColumnName == 'Level3Price') {
        var IsPriceOnRequestList: VariantLevel3[] = [];
        var IsNotPriceOnRequestList: VariantLevel3[] = [];

        IsPriceOnRequestList = this.data.filter(x => x.IsPriceOnRequest == true);
        IsNotPriceOnRequestList = this.data.filter(x => x.IsPriceOnRequest != true);
        IsPriceOnRequestList = IsPriceOnRequestList.concat(IsNotPriceOnRequestList);
        this.dataSource = new MatTableDataSource(IsPriceOnRequestList);
      }
      else {
        this.dataSource = new MatTableDataSource(this.data);
      }
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  getAllProductSeries() {
    this.showLoader = true;

    let queryString = "";
    queryString = "Id=" + this.ProductSeriesId;

    this.CommonService.GetData("ProductSeries", "GetById", queryString).subscribe((data: any) => {
      this.ProductSeriesArr = data;
      this.SeriesName = data.SeriesName;
      this.isVariant3DependsOnVariant2 = data.IsLevel3DepOnLevel2;
      this.isVariant2DependsOnVariant1 = data.IsLevel2DepOnLevel1;
      this.ProductSeriesVariant2Label = data.Label_Variant2;
      this.ProductSeriesVariant3Label = data.Label_Variant3;
      this.ProductSeriesVariant1Label = data.Label_Variant1;
      
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.GetAllVariantLevel3Details();
  }

  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAllVariantLevel3Details();
    }
  }

  pageEvent(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAllVariantLevel3Details();
  }
  /************************************************************************
   * Grid Code End 
   * **********************************************************************/

  /*******************************************************************************
  * Form validation method
  * ******************************************************************************/

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  RedirectToVariant(event: any) {
    if (this.VariantDrpdown == 'ProductModel') {
      this.router.navigate(['/ProductModel', this.ProductSeriesArr.Id]);
    }
    else if (this.VariantDrpdown == 'Options') {
      this.router.navigate(['/Options', this.ProductSeriesArr.Id]);
    }
    else if (this.VariantDrpdown == 'Variant1') {
      this.router.navigate(['/VariantLevel1', this.ProductSeriesArr.Id, this.ProductSeriesArr.Label_Variant1]);
    }
    else if (this.VariantDrpdown == 'Variant2') {
      this.router.navigate(['/VariantLevel2', this.ProductSeriesArr.Id, this.ProductSeriesArr.Label_Variant1, this.ProductSeriesArr.Label_Variant2]);
    }
    else if (this.VariantDrpdown == 'Variant3') {
      this.router.navigate(['/VariantLevel3', this.ProductSeriesArr.Id, this.ProductSeriesArr.Label_Variant2, this.ProductSeriesArr.Label_Variant3]);
    }
    else if (this.VariantDrpdown == 'Variant4') {
      this.router.navigate(['/VariantLevel4', this.ProductSeriesArr.Id, this.ProductSeriesArr.Label_Variant3, this.ProductSeriesArr.Label_Variant4]);
    }
  }

  UpdateVariantLevel3StatusById(Level3Data: VariantLevel3, CurrentStatus: boolean) {

    if (CurrentStatus == true) {
      //Create ConfirmDialogRef 
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to deactivate this " + this.ProductSeriesVariant3Label + ' ?',
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;
          this.CommonService.PostData('ProductVariantLevel3', 'UpdateStatus', Level3Data, null).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success(this.ProductSeriesVariant3Label + " deactivated successfully!");
            }
            else if (data.ResponseCode == 0) {
              this.toastr.warning("Failed to deactivate this " + this.ProductSeriesVariant3Label + " because it has some dependency!");
            }
            else {
              this.toastr.info("Failed to deactivate " + this.ProductSeriesVariant3Label + "!");
            }
            this.GetAllVariantLevel3Details();
          }, error => {
            this.toastr.error("Failed to deactivate " + this.ProductSeriesVariant3Label + "!");
            this.GetAllVariantLevel3Details();
            this.showLoader = false;
          });

        }
        else {
          this.GetAllVariantLevel3Details();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;

        }
      });

    }
    else {
      //Create ConfirmDialogRef
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to activate this " + this.ProductSeriesVariant3Label + "?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          this.CommonService.PostData('ProductVariantLevel3', 'UpdateStatus', Level3Data, null).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success(this.ProductSeriesVariant3Label + " activated successfully!");
            }
            else {
              this.toastr.info("Failed to activate " + this.ProductSeriesVariant3Label + "!");
            }
            this.GetAllVariantLevel3Details();
          }, error => {
            this.toastr.error("Failed to activate " + this.ProductSeriesVariant3Label + "!");
            this.GetAllVariantLevel3Details();
            this.showLoader = false;
          });

        }
        else {
          this.GetAllVariantLevel3Details();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });
    }
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isProductVariant3Editable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isProductLine')[0]?.IsEditable;
      
    });
 
  }
}


