import { string } from '@amcharts/amcharts4/core';

export class ActivityModel {
  ActivityId: number;
  EnquiryId: number;
  Activity: string = '';
  Comments: string = '';
  ActivityDate: Date = new Date();
  Attachment: any[] = [];
  IsRowDeleted: boolean;
}

export class Activities {
  ActivityId: number;
  EnquiryId: number;
  Activity: string = '';
  Comments: string = '';
  ActivityDate: Date = new Date();
  Attachment: any[] = [];
  IsRowDeleted: boolean;
}

export class Activity {
  ActivityId: number;
  Subject: string;
  ActivityOwner: string;
  ActivityOwnerRoleId: number;
  Priority?: string;
  Address?: string;
  ActivityTypeId: number;
  ActivitySubType?: string;
  ActivityPurposeId: number;
  PurposeDesc?: string;
  ActivityOutcomeId: number;
  OutcomeDesc?: string;
  ActivityStatusId: number;
  Comment?: string;
  PlannedStartDatetime?: Date;
  PlannedDueDatetime?: Date;
  ActualStartDatetime?: Date;
  ActualCompletionDatetime?: Date;
  ActivityStatusDesc: string;
  InEditMode: boolean = false;
  ActivityStatus: string;
  ActivityType: string;
  DueDays:number;
  Customer:string;
  Participants:string;
  canEditCustomerEngagementReportOfOthers:boolean;
  canViewCustomerEngagementReportOfOthers:boolean;
  ParticipantsID:string;
}

export class ActivityType {
  ActivityTypeId: number;
  ActivityTypeCode: string;
  private _ActivityType: string;


  public get ActivityType() {
    return this._ActivityType;
  }


  ActivityTypeDesc : string

  // public get ActivityTypeDesc() {
  //   return this.ActivityType;
  // }
  IsActive?: boolean;
}

export class ActivityStatus {
  ActivityStatusId: number;
  ActivityStatusCode: string;
  ActivityStatusDesc: string;
  IsActive?: boolean;
}

export class ActivityStatusByType {
  StatusCode: string;
  ActivityStatusId: number;
  ActivityStatusDesc: string;
  ActivityStatusCode: string;
  ActivityTypeId: number;
  IsActive?: boolean;
}

export class ActivityPurpose {
  ActivityPurposeId: number;
  ActivityPurposeCode: string;
  ActivityPurposeDesc: string;
  IsActive?: boolean;
}

export class ActivityPurposeByType {
  ActivityTypeId: number;
  ActivityPurposeId: number;
  ActivityPurposeCode: string;
  ActivityPurposeDesc: string;
  IsActive?: boolean;
  CreatedOn?: Date;
  CreatedBy?: string;
}

export class ActivityOutcome {
  ActivityOutcomeId: number;
  ActivityOutcomeCode: string;
  ActivityOutcomeDesc: string;
  IsActive?: boolean;
  CreatedOn?: Date;
  CreatedBy?: string;
  ModifiedOn?: Date;
  ModifiedBy?: string;
}


export class ActivityOutcomeByType {
  Id: number;
  ActivityTypeId: number;
  ActivityOutcomeId: number;
  ActivityOutcomeCode: string;
  ActivityOutcomeDesc: string;
  IsActive?: boolean;
}

export class ActivityParticipant {
  Id: number;
  Activityd: number;
  ActivityParticipant: string;
  ActivityParticipantRoleId: number;
  IsActivityOwner: boolean;
  CreatedOn?: Date;
  CreatedBy?: string;
  ModifiedOn?: Date;
  ModifiedBy?: string;
}

export class ActivityParticipants {
  participants: ActivityParticipant[];
}

export class ActivityLinkageEntityType {
  Id: number;
  EntityType: string;
  IsActive?: boolean;
  CreatedOn?: Date;
}

export class ActivityLinkage {
  ActivityLinkageId?: number;
  ActiviyId?: number;
  EntityTypeId: number;
  EntityId: number;
  CreatedOn?: Date;
}

export class EntityRecord {
  EntityId: number;
  EntityTitle: string;
  EntityTypeId: number;
}

export class EntityRecords {
  EntityRecord: EntityRecord[];
}

export class ActivityLinkages {
  ActivityLinkages: ActivityLinkage[];
}

export class User {
  Id: string;
  FirstName: string;
}
