import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { UpdateStatus } from 'src/app/Model/CommonModel';
import { SingleFieldComponent, SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-customer-frame-agreement-details',
  templateUrl: './customer-frame-agreement-details.component.html',
  styleUrls: ['./customer-frame-agreement-details.component.scss']
})
export class CustomerFrameAgreementDetailsComponent implements OnInit {
  dataSource: MatTableDataSource<SingleFieldModel>;
  displayedColumns: string[] = ['Companyname', 'Value', 'Action'];
  Customer: any[] = [];
  disabled: boolean = false;
  singleFieldComponent: SingleFieldComponent = new SingleFieldComponent();
  UserRole: any;
  isConfigureEditable: boolean = false;
  RouteName : string ='';
  someCondition : boolean = false;
  viewedit:boolean=true;
  isAddframeAgreementAccess:boolean=false;

  IsCustomerFrameAgreementEditable:boolean;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];

  /*************************************************************************
        Server side Pagination purpose
    *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';
  ColumnName: string = 'ModifiedOn';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /*************************************************************************
     End here
  *************************************************************************/

  @Input()
  showLoader: boolean = false;
  data: any[] = [];
  UpdateStatus: UpdateStatus = new UpdateStatus();
  

  constructor(private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog,
    private route: ActivatedRoute, private router: Router, private titleService: Title,private accessservice: AccessPermissionService) { }

  ngOnInit(): void {
    this.UserRole = localStorage.getItem("UserRole");
    this.GetAll();
    this.getAccess();
  }
  
  GetAll() {
    this.showLoader = true;
    let queryString = "";
    this.length = 0;
    queryString = "pageNumber=" + this.pageNumber;
    queryString += "&pageSize=" + this.pageSize;
    queryString += "&searchText=" + this.SearchText;
    queryString += "&direction=" + this.direction;
    queryString += "&columnName=" + this.ColumnName;

    this.CommonService.GetData('CustomerSpecialDiscount', 'GetAllPagination', queryString).subscribe((data: any) => {
      this.data = data.Data;
     
      this.length = data.Total;
      this.dataSource = new MatTableDataSource(this.data);
      
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.GetAll();
  }

  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAll();
    }
  }

  pageEvent(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAll();
  }

  
  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  UpdateStatusById(Id: number, CurrentStatus: boolean) {

    if (CurrentStatus == true) {
      //Create ConfirmDialogRef 
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to deactivate this Customer Frame Agreement?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Id;

          this.CommonService.PostData('CustomerSpecialDiscount', 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success( "Customer Frame Agreement deactivated successfully!");
            }
            else if (data.ResponseCode == 0) {
              this.toastr.warning("Failed to deactivate this Customer Frame Agreement because it has some dependency!");
            }
            else {
              this.toastr.info("Failed to deactivate Customer Frame Agreement!");
            }
            this.GetAll();
          }, error => {
            this.toastr.error("Failed to deactivate Customer Frame Agreement!");
            this.GetAll();
            this.showLoader = false;
          });
        }
        else {
          this.GetAll();
        }
      });

    }
    else {
      //Create ConfirmDialogRef
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to activate this Customer Frame Agreement?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Id;

          this.CommonService.PostData('CustomerSpecialDiscount', 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Customer Frame Agreement activated successfully!");
            }
            else {
              this.toastr.info("Failed to activate Customer Frame Agreement!");
            }
            this.GetAll();
          }, error => {
            this.toastr.error("Failed to activate Customer Frame Agreement!");
            this.GetAll();
            this.showLoader = false;
          });

        }
        else {
          this.GetAll();
        }
      });
    }
  }
  
  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.IsCustomerFrameAgreementEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='IsCustomerFrameAgreement')[0]?.IsEditable;
     
    });
 
  }

}
