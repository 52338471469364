import { number, string } from '@amcharts/amcharts4/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from 'src/app/app.global';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { BusinessPlanModel } from 'src/app/Model/BusinessPlanModel';
import { SalesOrderBusinesPlan } from 'src/app/Model/SalesOrderBusinessPlan';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-edit-business-plan',
  templateUrl: './edit-business-plan.component.html',
  styleUrls: ['./edit-business-plan.component.scss']
})
export class EditBusinessPlanComponent implements OnInit {
  BusinessPlan: SalesOrderBusinesPlan = new SalesOrderBusinesPlan();
  showLoader: boolean;
  allExistingData: any;
  FinalModel: any[];
  selectedUserData: any;
  allEmployees: any;
  name: any;
  zeroValues: boolean;
  isEditable: boolean = true;
  FormStatus: string = "View";
  charCode: any;
  BusinessPlanobje: BusinessPlanModel = new BusinessPlanModel();
  isBPMasterEditable: boolean = false;
  UserRole: any;
  IsBPDataValid: boolean = true;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];

  constructor(private route: ActivatedRoute,
    private Service: CommonService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    private titleService: Title,
    private accessservice: AccessPermissionService,
    private appConfigService: AppConfigService
) { }

  // targetYears = ["2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030",
  //   "2031", "2032", "2033", "2034", "2035", "2036", "2037", "2038", "2039", "2040",
  //   "2041", "2042", "2043", "2044", "2045", "2046", "2047", "2048", "2049",
  // ];
  targetYears:any[]=[];
  currYear: number = new Date().getFullYear();
  targets = ['BP Sales', 'BP Order Booked'];

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    //this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    let k = 1;
    for (let i = 2018; i <= this.currYear; i++) {
        let batch =i;
        this.targetYears.push(batch);
        k++;
    }
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();

    //this.isBPMasterEditable = AppGlobals.BusinessPlanEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.getAllUsers();

    this.BusinessPlanobje.TargetYear = this.route.snapshot.params[1];
    this.BusinessPlanobje.TargetFor = this.route.snapshot.params[2];
    this.BusinessPlanobje.EmployeeId = this.route.snapshot.params[3];
    this.GetBusinessPlan();
  }

  getAllUsers() {
    this.showLoader = true;
    this.Service.GetData("Employee", "GetAll", null).subscribe((data: any) => {
      this.allEmployees = data;
      if (this.BusinessPlan.UserName != undefined) {
        this.selectedUserData = this.allEmployees.filter(x => x.Fk_User_Id == this.BusinessPlan.Fk_UserId_Target);
        if (this.selectedUserData.length > 0) {
          this.name = this.selectedUserData[0].RoleName;
        }
      }
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetBusinessPlan() {
    this.showLoader = true;

    let queryString = "";
    queryString = "TargetYear=" + this.BusinessPlanobje.TargetYear;
    queryString += "&TargetFor=" + this.BusinessPlanobje.TargetFor;
    queryString += "&EmployeeId=" + this.BusinessPlanobje.EmployeeId;


    this.Service.GetData("BusinessPlanSalesTarget", "GetExistingPlan", queryString).subscribe((data: any) => {
      this.BusinessPlanobje.EmployeeId = data?.EmployeeId;
      this.BusinessPlanobje.TargetFor = data?.TargetFor;
      this.BusinessPlanobje.TargetYear = data?.TargetYear;
      this.BusinessPlanobje.RoleName = data?.RoleName;

      if (data.PlanList != null) {

        var i = 0;
        data.PlanList.forEach(element => {
          this.BusinessPlanobje.PlanList[i].Id = element.Id;
        //  this.BusinessPlanobje.PlanList[i].Units = element.Units;
          this.BusinessPlanobje.PlanList[i].Amount = element.Amount;
          this.BusinessPlanobje.PlanList[i].FC1Amount = element.FC1Amount==null?0:element.FC1Amount;
          this.BusinessPlanobje.PlanList[i].FC2Amount = element.FC2Amount==null?0:element.FC2Amount;
          this.BusinessPlanobje.PlanList[i].FC3Amount = element.FC3Amount==null?0:element.FC3Amount;
          i = i + 1;
        }, error => {
          this.toastr.error("Failed to load existing plan!");
          this.showLoader = false;
        });

        this.showLoader = false;
      }
      else {
        var i = 0;
        this.BusinessPlanobje.PlanList.forEach(element => {
          this.BusinessPlanobje.PlanList[i].Id = 0;
         // this.BusinessPlanobje.PlanList[i].Units = 0;
          this.BusinessPlanobje.PlanList[i].Amount = 0;
          this.BusinessPlanobje.PlanList[i].FC1Amount = 0;
          this.BusinessPlanobje.PlanList[i].FC2Amount = 0;
          this.BusinessPlanobje.PlanList[i].FC3Amount = 0;
          i = i + 1;
        });
      }
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }



  setName(event: any) {
    this.name = event.target.value;
  }

  /*******************************************************************************
     * Form validation method
     * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }
  onUnitsChange(i) {

    var povalue: number = parseFloat(this.BusinessPlanobje.PlanList[i].Units.toString());
    if (povalue == undefined || povalue == null) {
      this.BusinessPlanobje.PlanList[i].UnitsValidation = true;
      this.IsBPDataValid = false;
    } else if (povalue < 0) {
      this.BusinessPlanobje.PlanList[i].UnitsValidation = true;
      this.IsBPDataValid = false;
    } else if (povalue % 1 != 0) {
      this.BusinessPlanobje.PlanList[i].UnitsValidation = true;
      this.IsBPDataValid = false;
    } else if (this.BusinessPlanobje.PlanList[i].Units.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].UnitsValidation = true;
      this.IsBPDataValid = false;
    }
    else if (isNaN(this.BusinessPlanobje.PlanList[i].Units)) {
      this.BusinessPlanobje.PlanList[i].UnitsValidation = true;
      this.IsBPDataValid = false;
    }
    else {
      this.BusinessPlanobje.PlanList[i].UnitsValidation = false;
    }

  }
  onAmountChange(i) {

    var povalue: number = parseFloat(this.BusinessPlanobje.PlanList[i].Amount.toString());
    if (povalue == undefined || povalue == null) {
      this.BusinessPlanobje.PlanList[i].AmountValidation = true;
      this.IsBPDataValid = false;
    } else if (povalue < 0) {
      this.BusinessPlanobje.PlanList[i].AmountValidation = true;
      this.IsBPDataValid = false;
    } else if (povalue % 1 != 0) {
      this.BusinessPlanobje.PlanList[i].AmountValidation = true;
      this.IsBPDataValid = false;
    } else if (this.BusinessPlanobje.PlanList[i].Amount.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].AmountValidation = true;
      this.IsBPDataValid = false;
    }
    else if (isNaN(this.BusinessPlanobje.PlanList[i].Amount)) {
      this.BusinessPlanobje.PlanList[i].AmountValidation = true;
      this.IsBPDataValid = false;
    } else {
      this.BusinessPlanobje.PlanList[i].AmountValidation = false;
    }
  }

  onFC1Change(i)
  {
    var povalue: number = parseFloat(this.BusinessPlanobje.PlanList[i].FC1Amount.toString());
    if (povalue == undefined || povalue == null) {
      this.BusinessPlanobje.PlanList[i].FC1Validation = true;
      this.IsBPDataValid = false;
    } else if (povalue < 0) {
      this.BusinessPlanobje.PlanList[i].FC1Validation = true;
      this.IsBPDataValid = false;
    } else if (povalue % 1 != 0 || povalue.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].FC1Validation = true;
      this.IsBPDataValid = false;
    } else if (this.BusinessPlanobje.PlanList[i].FC1Amount.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].FC1Validation = true;
      this.IsBPDataValid = false;
    }  else if (isNaN(this.BusinessPlanobje.PlanList[i].FC1Amount)) {
      this.BusinessPlanobje.PlanList[i].FC1Validation = true;
      this.IsBPDataValid = false;
    }else {
      this.BusinessPlanobje.PlanList[i].FC1Validation = false;
    }
  }
  onFC2Change(i)
  {
    var povalue: number = parseFloat(this.BusinessPlanobje.PlanList[i].FC2Amount.toString());
    if (povalue == undefined || povalue == null) {
      this.BusinessPlanobje.PlanList[i].FC2Validation = true;
      this.IsBPDataValid = false;
    } else if (povalue < 0) {
      this.BusinessPlanobje.PlanList[i].FC2Validation = true;
      this.IsBPDataValid = false;
    } else if (povalue % 1 != 0 || povalue.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].FC2Validation = true;
      this.IsBPDataValid = false;
    } else if (this.BusinessPlanobje.PlanList[i].FC2Amount.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].FC2Validation = true;
      this.IsBPDataValid = false;
    }  else if (isNaN(this.BusinessPlanobje.PlanList[i].FC2Amount)) {
      this.BusinessPlanobje.PlanList[i].FC2Validation = true;
      this.IsBPDataValid = false;
    }else {
      this.BusinessPlanobje.PlanList[i].FC2Validation = false;
    }
  }
  onFC3Change(i)
  {
    var povalue: number = parseFloat(this.BusinessPlanobje.PlanList[i].FC3Amount.toString());
    if (povalue == undefined || povalue == null) {
      this.BusinessPlanobje.PlanList[i].FC3Validation = true;
      this.IsBPDataValid = false;
    } else if (povalue < 0) {
      this.BusinessPlanobje.PlanList[i].FC3Validation = true;
      this.IsBPDataValid = false;
    } else if (povalue % 1 != 0 || povalue.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].FC3Validation = true;
      this.IsBPDataValid = false;
    } else if (this.BusinessPlanobje.PlanList[i].FC3Amount.toString().indexOf('.') > -1) {
      this.BusinessPlanobje.PlanList[i].FC3Validation = true;
      this.IsBPDataValid = false;
    }  else if (isNaN(this.BusinessPlanobje.PlanList[i].FC3Amount)) {
      this.BusinessPlanobje.PlanList[i].FC3Validation = true;
      this.IsBPDataValid = false;
    }else {
      this.BusinessPlanobje.PlanList[i].FC3Validation = false;
    }
  }
  
  checkValidBusinessPlan() {
    this.BusinessPlanobje.PlanList.forEach(element => {
      // var povalue: number = parseFloat(element.Units.toString());
      // if (povalue == undefined || povalue == null) {
      //   element.UnitsValidation = true;
      //   this.IsBPDataValid = false;
      // } else if (povalue < 0) {
      //   element.UnitsValidation = true;
      //   this.IsBPDataValid = false;
      // } else if (povalue % 1 != 0) {
      //   element.UnitsValidation = true;
      //   this.IsBPDataValid = false;
      // } else if (element.Units.toString().indexOf('.') > -1) {
      //   element.UnitsValidation = true;
      //   this.IsBPDataValid = false;
      // } else {
      //   element.UnitsValidation = false;
      // }

      var povalue: number = parseFloat(element.Amount.toString());
      if (povalue == undefined || povalue == null) {
        element.AmountValidation = true;
        this.IsBPDataValid = false;
      } else if (povalue < 0) {
        element.AmountValidation = true;
        this.IsBPDataValid = false;
      } else if (povalue % 1 != 0) {
        element.AmountValidation = true;
        this.IsBPDataValid = false;
      } else if (element.Amount.toString().indexOf('.') > -1) {
        element.AmountValidation = true;
        this.IsBPDataValid = false;
      } else {
        element.AmountValidation = false;
      }


      var povalue: number = parseFloat(element.FC1Amount.toString());
      if (povalue == undefined || povalue == null) {
        element.FC1Validation = true;
        this.IsBPDataValid = false;
      } else if (povalue < 0) {
        element.FC1Validation = true;
        this.IsBPDataValid = false;
      } else if (povalue % 1 != 0) {
        element.FC1Validation = true;
        this.IsBPDataValid = false;
      } else if (element.FC1Amount.toString().indexOf('.') > -1) {
        element.FC1Validation = true;
        this.IsBPDataValid = false;
      } else {
        element.FC1Validation = false;
      }

      var povalue: number = parseFloat(element.FC2Amount.toString());
      if (povalue == undefined || povalue == null) {
        element.FC2Validation = true;
        this.IsBPDataValid = false;
      } else if (povalue < 0) {
        element.FC2Validation = true;
        this.IsBPDataValid = false;
      } else if (povalue % 1 != 0) {
        element.FC2Validation = true;
        this.IsBPDataValid = false;
      } else if (element.FC2Amount.toString().indexOf('.') > -1) {
        element.FC2Validation = true;
        this.IsBPDataValid = false;
      } else {
        element.FC2Validation = false;
      }

      var povalue: number = parseFloat(element.FC3Amount.toString());
      if (povalue == undefined || povalue == null) {
        element.FC3Validation = true;
        this.IsBPDataValid = false;
      } else if (povalue < 0) {
        element.FC3Validation = true;
        this.IsBPDataValid = false;
      } else if (povalue % 1 != 0) {
        element.FC3Validation = true;
        this.IsBPDataValid = false;
      } else if (element.FC3Amount.toString().indexOf('.') > -1) {
        element.FC3Validation = true;
        this.IsBPDataValid = false;
      } else {
        element.FC3Validation = false;
      }
    });
  }
  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/
  onSubmit(myform: any) {
  //  var unit = this.BusinessPlanobje.PlanList.filter(x => x.Units != 0);
    var amt = this.BusinessPlanobje.PlanList.filter(x => x.Amount != 0);
    // if (unit.length == 0) {
    //   this.zeroValues = true;
    //   alert("Units cannot be 0 for all the months.")
    //   return;
    // }

    if (amt.length == 0) {
      this.zeroValues = true;
      alert("Amount cannot be 0 for all the months.")
      return;
    }
    this.IsBPDataValid = true;
    this.checkValidBusinessPlan();
    if (!this.IsBPDataValid) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update this business plan for sales and orders?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showLoader = true;
        this.Service.PostData('BusinessPlanSalesTarget', 'Update', this.BusinessPlanobje, null).subscribe((data: any) => {
          let result = data;
          this.showLoader = false;
          this.toastr.success('Business plan updated successfully!');
          this.router.navigate(['/BusinessPlanDetails']);
        }, error => {
          this.toastr.error('Failed to update business plan');
          this.showLoader = false;
          this.router.navigate(['/BusinessPlanDetails']);
        });
      }
    });


  }

  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    const appConfig = this.appConfigService.getConfig();
    this.titleService.setTitle(`Edit Business Plan - ${appConfig.titleName}`);
    //this.titleService.setTitle("Edit Business Plan - ECOTECH");  
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isBPMasterEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isBusinessPlanforSalesandOrder')[0]?.IsEditable;
      
    });
 
  }
}
