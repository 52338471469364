import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from 'src/app/app.global';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { LoginPageImage } from 'src/app/Model/LoginPageImage';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-add-login-page-dynamic-image',
  templateUrl: './add-login-page-dynamic-image.component.html',
  styleUrls: ['./add-login-page-dynamic-image.component.scss']
})
export class AddLoginPageDynamicImageComponent implements OnInit {

  LoginPageImageObj: LoginPageImage = new LoginPageImage();
  allowedFileType: string[] = [
    'image/bmp',
    'image/jpeg',
    'image/png',
  ];
  AppGlobals = AppGlobals;
  file: any;
  showLoader: boolean = false;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private CommonService: CommonService

  ) { }

  ngOnInit(): void {
  }

  onSubmit(myform: any) {

    if (this.LoginPageImageObj.ImageName == "") {
      this.toastr.info("Please Select an Image");
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "You want to add this Login_Page_Image?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showLoader = true;

        const frmData = new FormData();
        // let LoginPagedetails = JSON.stringify(this.LoginPageImageObj);
        // frmData.append("LoginModel", LoginPagedetails);
        if (this.LoginPageImageObj.ImageName != undefined)
          frmData.append("LoginPageImage", this.file);
        let LoginPagedetails = JSON.stringify(this.LoginPageImageObj);
        frmData.append("LoginModel", LoginPagedetails);
        this.CommonService.PostData('LoginPageImage', 'Add', frmData, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('Login_Page_Image added successfully!');
            this.CommonService.RefreshHeaderFun();
            this.router.navigate(['/LoginPageImage']);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('Login_Page_Image is already exist!');
            this.router.navigate(['/LoginPageImage']);
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to add Login_Page_Image!');
            this.router.navigate(['/LoginPageImage']);
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add Login_Page_Image!');
          this.showLoader = false;
          this.router.navigate(['/LoginPageImage']);
        });
      }


    });
  }
  // uploadDynamicImage(event, index) {
  //   let file = event.target.files[0];
  //   let isValidFile = true;
  //    let fileExists = true;
  //   if (((file.size) / 1000000) <= 5) {
  //     isValidFile = this.allowedFileType.includes(file.type);
  //     if (!isValidFile) {
  //       this.toastr.warning("Invalid file");
  //     }
  //     if(!fileExists) {
  //          this.toastr.warning("File is Already exists!")
  //     }
  //     else {
  //       this.LoginPageImageObj.ImageName = file.name;
  //       this.file=file; 
  //       //const fileExists = true;       
  //     }
  //   }
  //   else
  //   {
  //     this.toastr.info("Too large file. Please select file upto 5MB");
  //   }
  // }

  uploadDynamicImage(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];
      let isValidFile = true;
      if (((file.size) / 1000000) <= 5) {

        isValidFile = this.allowedFileType.includes(file.type);
        if (!isValidFile) {
          this.toastr.warning("Please attach the file having extension :\n\n .bmp, .jpeg, .jpg, .png,");
          //event.target.value = null;
          isValidFile = false;
        }
        //  else if (file.name.length > 50) {
        //   this.toastr.warning("The file name with more than 50 characters are not allowed.");
        //   isValidFile = false;
        // }
      }
      else {
        this.toastr.warning("File size must be less than or equal to 5MB!");
        //event.target.value = null;
        isValidFile = false;
      }
      if (isValidFile) {
        // this.StockFile.push(file);
        this.LoginPageImageObj.ImageName = file.name;
        this.file = file;
      }
    }

  }
  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }
  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }
}
