<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Enquiry Listing - Pending
                                    </h3>
                                </div>
                            </div>
                            <div class="m-portlet__head-tools">
                                <ul class="m-portlet__nav">
                                    <li class="m-portlet__nav-item">
                                        <a class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                            routerLink="/AddEnquiry">Add Enquiry</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <mat-accordion>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <label style="font-weight: bolder;">Filters</label>
                                        <span class="material-icons"
                                            style="position: absolute;right: 15px;">expand_more</span>
                                    </mat-panel-title>
                                    <mat-panel-description>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div>
                                    <div class="form-group m-form__group row">

                                        <div class="col-sm-3 form-group ml-1.9 datepicks">

                                            <div class="form-group hidden">

                                                <div class="input-group">
                                                    <input name="datepicker" class="form-control" ngbDatepicker
                                                        #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                        (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                                        [dayTemplate]="t" outsideDays="hidden"
                                                        [startDate]="EnquiryngbDateFromFiter!" tabindex="-1" hidden
                                                        [minDate]="minDate" [maxDate]="maxDate"
                                                        [footerTemplate]="footerTemplate">
                                                    <ng-template #t let-date let-focused="focused">
                                                        <span class="custom-day" [class.focused]="focused"
                                                            [class.range]="isRange(date)"
                                                            [class.faded]="isHovered(date) || isInside(date)"
                                                            (mouseenter)="hoveredDate = date"
                                                            (mouseleave)="hoveredDate = null">
                                                            {{ date.day }}
                                                        </span>
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label style="display: flex;" class="col-sm2">
                                                    <h6>Enquiry Date:</h6>
                                                </label>
                                                <div class="input-group">
                                                    <input readonly #dpFromDate class="form-control"
                                                        placeholder="DD-MMM-YYYY" name="dpFromDate"
                                                        [value]="formatter.format(EnquiryngbDateFromFiter)"
                                                        (input)="EnquiryngbDateFromFiter = validateInput(EnquiryngbDateFromFiter, dpFromDate.value)"
                                                        (click)="datepicker.toggle()">
                                                    <input readonly #dpToDate class="form-control"
                                                        placeholder="DD-MMM-YYYY" name="dpToDate"
                                                        [value]="formatter.format(EnquiryngbDateToFiter)"
                                                        (input)="EnquiryngbDateToFiter = validateInput(EnquiryngbDateToFiter, dpToDate.value)"
                                                        (click)="datepicker.toggle()">
                                                    <div class="input-group-append" (click)="datepicker.toggle()"
                                                        *ngIf="EnquiryngbDateFromFiter == null || EnquiryngbDateToFiter == null">
                                                        <span class="input-group-text">
                                                            <i class="la la-calendar-check-o"></i>
                                                        </span>

                                                    </div>
                                                    <div class="input-group-append"
                                                        (click)="EnquiryngbDateFromFiter=null;EnquiryngbDateToFiter=null;OnFromDateFilterChange();OnToDateFilterChange()"
                                                        *ngIf="EnquiryngbDateFromFiter != null && EnquiryngbDateToFiter != null">
                                                        <span class="input-group-text">
                                                            <i class="la la-close"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <ng-template #footerTemplate>
                                                <hr class="my-0">

                                                <button class="btn btn-primary btn-sm m-2 float-right"
                                                    (click)="datepicker.toggle()">Ok</button>
                                            </ng-template>

                                        </div>

                                        <!-- <div class="col-sm-3 form-group ml-1.9">
                                            <div class="form-group ml-2">
                                                <label style="display: flex;" class="col-sm2">
                                                    <h6>To Enquiry Date::</h6>
                                                </label>
                                              <div class="input-group">
                                                <input #dpToDate
                                                       class="form-control" placeholder="DD-MMM-YYYY"
                                                       name="dpToDate"
                                                       [value]="formatter.format(EnquiryngbDateToFiter)"
                                                       (input)="EnquiryngbDateToFiter = validateInput(EnquiryngbDateToFiter, dpToDate.value)"
                                                     >
                                                <div class="input-group-append"  (click)="datepicker.toggle()"
                                                *ngIf="EnquiryngbDateToFiter == null">
                                                    <span class="input-group-text">
                                                        <i class="la la-calendar-check-o"></i>
                                                    </span>

                                                </div>
                                                <div class="input-group-append"
                                                    (click)="EnquiryngbDateToFiter=null;OnToDateFilterChange()"
                                                    *ngIf="EnquiryngbDateToFiter != null">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div>
                                              </div>
                                            </div>
                                        </div> -->



                                        <!-- <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>From Enquiry Date:</h6>
                                            </label>

                                            <div class="input-group">
                                                <input class="form-control" placeholder="DD-MMM-YYYY" id="fromdate"
                                                    name="fromdate" ngbDatepicker #from="ngbDatepicker"
                                                    [(ngModel)]="EnquiryngbDateFromFiter" #fromdate="ngModel"
                                                    (click)="from.toggle()" (ngModelChange)="OnFromDateFilterChange()"
                                                    [minDate]="minDate" [maxDate]="maxDate">
                                                <div class="input-group-append" (click)="from.toggle()"
                                                    *ngIf="EnquiryngbDateFromFiter == null">
                                                    <span class="input-group-text">
                                                        <i class="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                                <div class="input-group-append"
                                                    (click)="EnquiryngbDateFromFiter=null;OnFromDateFilterChange()"
                                                    *ngIf="EnquiryngbDateFromFiter != null">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div><br />
                                            </div>
                                        </div>

                                        <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>To Enquiry Date::</h6>
                                            </label>
                                            <div class="input-group">
                                                <input class="form-control" placeholder="DD-MMM-YYYY" id="todate"
                                                    name="todate" ngbDatepicker #to="ngbDatepicker"
                                                    [(ngModel)]="EnquiryngbDateToFiter" #todate="ngModel"
                                                    (click)="to.toggle()"
                                                    [minDate]="EnquiryngbDateFromFiter!= null?EnquiryngbDateFromFiter:minDate"
                                                    [maxDate]="maxDate" (ngModelChange)="OnToDateFilterChange()">
                                                <div class="input-group-append" (click)="to.toggle()"
                                                    *ngIf="EnquiryngbDateToFiter == null">
                                                    <span class="input-group-text">
                                                        <i class="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                                <div class="input-group-append"
                                                    (click)="EnquiryngbDateToFiter=null;OnToDateFilterChange()"
                                                    *ngIf="EnquiryngbDateToFiter != null">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div><br />
                                            </div>
                                        </div> -->

                                        <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>Customer Name:</h6>
                                            </label>
                                            <div class="input-group">
                                                <ng-select [items]="EnquiryGridFilterList.CusomterName"
                                                    [ngClass]="(PaginationModel.CusomterNameFilter.length > 0 && PaginationModel.CusomterNameFilter != []) ? 'InputFiledWithbtn' : 'GridFilter'"
                                                    [multiple]="true" appendTo="body" bindLabel="Name"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="false" [clearable]="false" bindValue="Name"
                                                    placeholder="Select Customer"
                                                    [(ngModel)]="PaginationModel.CusomterNameFilter">

                                                    <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                        let-index="index">
                                                        <input id="item-{{index}}" type="checkbox"
                                                            [ngModel]="item$.selected" />
                                                        <span title="{{item.Name}}">{{item.Name}}</span>
                                                    </ng-template>

                                                </ng-select>
                                                <div class="input-group-append"
                                                    *ngIf=" (PaginationModel.CusomterNameFilter.length > 0 && PaginationModel.CusomterNameFilter != [])"
                                                    (click)="PaginationModel.CusomterNameFilter=[];">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>Estimated Enquiry Value:</h6>
                                            </label>
                                            <div class="input-group">
                                                <ng-select [items]="EnquiryGridFilterList.EstimatedEnquiryValue"
                                                    [ngClass]="(PaginationModel.EstimatedEnquiryValueFilter.length > 0 && PaginationModel.EstimatedEnquiryValueFilter != []) ? 'InputFiledWithbtn' : 'GridFilter'"
                                                    [multiple]="true" appendTo="body" [clearable]="false"
                                                    bindLabel="Name" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="false"
                                                    bindValue="Name" placeholder="Select Estimated Enquiry Value"
                                                    [(ngModel)]="PaginationModel.EstimatedEnquiryValueFilter">

                                                    <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                        let-index="index">
                                                        <input id="item-{{index}}" type="checkbox"
                                                            [ngModel]="item$.selected" />
                                                        <span title="{{item.Name}}">{{item.Name}}</span>
                                                    </ng-template>

                                                </ng-select>

                                                <div class="input-group-append"
                                                    *ngIf="(PaginationModel.EstimatedEnquiryValueFilter.length > 0 && PaginationModel.EstimatedEnquiryValueFilter != [])"
                                                    (click)="PaginationModel.EstimatedEnquiryValueFilter=[];">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>Initiator Name:</h6>
                                            </label>
                                            <div class="input-group">
                                                <ng-select [items]="EnquiryGridFilterList.InitiatorName"
                                                    [ngClass]="(PaginationModel.InitiatorNameFilter.length > 0 && PaginationModel.InitiatorNameFilter != []) ? 'InputFiledWithbtn' : 'GridFilter'"
                                                    [multiple]="true" appendTo="body" bindLabel="Name"
                                                    [clearable]="false" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="false"
                                                    bindValue="Name" placeholder="Select Initiator"
                                                    [(ngModel)]="PaginationModel.InitiatorNameFilter">

                                                    <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                        let-index="index">
                                                        <input id="item-{{index}}" type="checkbox"
                                                            [ngModel]="item$.selected" />
                                                        <span title="{{item.Name}}">{{item.Name}}</span>
                                                    </ng-template>

                                                </ng-select>
                                                <div class="input-group-append"
                                                    *ngIf="(PaginationModel.InitiatorNameFilter.length > 0 && PaginationModel.InitiatorNameFilter != [])"
                                                    (click)="PaginationModel.InitiatorNameFilter=[];">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>


                                    </div>

                                    <div class="form-group m-form__group row">
                                        <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>Segment:</h6>
                                            </label>
                                            <div class="input-group">
                                                <ng-select [items]="EnquiryGridFilterList.Segment"
                                                    [ngClass]="(PaginationModel.SegmentFilter.length > 0 && PaginationModel.SegmentFilter != []) ? 'InputFiledWithbtn' : 'GridFilter'"
                                                    [multiple]="true" appendTo="body" bindLabel="Name"
                                                    [clearable]="false" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="false"
                                                    bindValue="Name" placeholder="Select Segment"
                                                    [(ngModel)]="PaginationModel.SegmentFilter"
                                                    (change)="OnSegmentChangeGetMiCroSegment()">

                                                    <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                        let-index="index">
                                                        <input id="item-{{index}}" type="checkbox"
                                                            [ngModel]="item$.selected" />
                                                        <span title="{{item.Name}}">{{item.Name}}</span>
                                                    </ng-template>

                                                </ng-select>
                                                <div class="input-group-append"
                                                    *ngIf="(PaginationModel.SegmentFilter.length > 0 && PaginationModel.SegmentFilter != [])"
                                                    (click)="PaginationModel.SegmentFilter=[];OnSegmentChangeGetMiCroSegment()">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>Micro-Segment:</h6>
                                            </label>
                                            <div class="input-group">
                                                <ng-select [items]="EnquiryGridFilterList.MicroSegment"
                                                    [ngClass]="(PaginationModel.MicroSegmentFilter.length > 0 && PaginationModel.MicroSegmentFilter != []) ? 'InputFiledWithbtn' : 'GridFilter'"
                                                    [multiple]="true" appendTo="body" [clearable]="false"
                                                    bindLabel="Name" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="false"
                                                    bindValue="Name" placeholder="Select Micro-Segment"
                                                    [(ngModel)]="PaginationModel.MicroSegmentFilter">

                                                    <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                        let-index="index">
                                                        <input id="item-{{index}}" type="checkbox"
                                                            [ngModel]="item$.selected" />
                                                        <span title="{{item.Name}}">{{item.Name}}</span>
                                                    </ng-template>

                                                </ng-select>
                                                <div class="input-group-append"
                                                    *ngIf="(PaginationModel.MicroSegmentFilter.length > 0 && PaginationModel.MicroSegmentFilter != [])"
                                                    (click)="PaginationModel.MicroSegmentFilter=[];">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>Region:</h6>
                                            </label>
                                            <div class="input-group">
                                                <ng-select [items]="EnquiryGridFilterList.Region"
                                                    [ngClass]="(PaginationModel.RegionFilter.length > 0 && PaginationModel.RegionFilter != []) ? 'InputFiledWithbtn' : 'GridFilter'"
                                                    [multiple]="true" appendTo="body" bindLabel="Name"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="false" [clearable]="false" bindValue="Name"
                                                    placeholder="Select Region"
                                                    [(ngModel)]="PaginationModel.RegionFilter">

                                                    <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                        let-index="index">
                                                        <input id="item-{{index}}" type="checkbox"
                                                            [ngModel]="item$.selected" />
                                                        <span title="{{item.Name}}">{{item.Name}}</span>
                                                    </ng-template>

                                                </ng-select>
                                                <div class="input-group-append"
                                                    *ngIf="(PaginationModel.RegionFilter.length > 0 && PaginationModel.RegionFilter != [])"
                                                    (click)="PaginationModel.RegionFilter=[];">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>Customer Classification:</h6>
                                            </label>
                                            <div class="input-group">
                                                <ng-select [items]="EnquiryGridFilterList.CusomterClass"
                                                    [ngClass]="(PaginationModel.CusomterClassFilter.length > 0 && PaginationModel.CusomterClassFilter != []) ? 'InputFiledWithbtn' : 'GridFilter'"
                                                    [multiple]="true" appendTo="body" [clearable]="false"
                                                    bindLabel="Name" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="false"
                                                    bindValue="Name" placeholder="Select Customer Classification"
                                                    [(ngModel)]="PaginationModel.CusomterClassFilter">

                                                    <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                        let-index="index">
                                                        <input id="item-{{index}}" type="checkbox"
                                                            [ngModel]="item$.selected" />
                                                        <span title="{{item.Name}}">{{item.Name}}</span>
                                                    </ng-template>

                                                </ng-select>
                                                <div class="input-group-append"
                                                    *ngIf="(PaginationModel.CusomterClassFilter.length > 0 && PaginationModel.CusomterClassFilter != [])"
                                                    (click)="PaginationModel.CusomterClassFilter=[];">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>


                                    </div>

                                    <div class="form-group m-form__group row">
                                        <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>Product:</h6>
                                            </label>
                                            <div class="input-group">
                                                <ng-select [items]="EnquiryGridFilterList.ProductModels"
                                                    [ngClass]="(PaginationModel.ProdcutFilter.length > 0 && PaginationModel.ProdcutFilter != []) ? 'InputFiledWithbtn' : 'GridFilter'"
                                                    [multiple]="true" appendTo="body" bindLabel="Name"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="false" [clearable]="false" bindValue="Name"
                                                    placeholder="Select Product"
                                                    [(ngModel)]="PaginationModel.ProdcutFilter">

                                                    <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                        let-index="index">
                                                        <input id="item-{{index}}" type="checkbox"
                                                            [ngModel]="item$.selected" />
                                                        <span title="{{item.Name}}">{{item.Name}}</span>
                                                    </ng-template>

                                                </ng-select>
                                                <div class="input-group-append"
                                                    *ngIf="(PaginationModel.ProdcutFilter.length > 0 && PaginationModel.ProdcutFilter != [])"
                                                    (click)="PaginationModel.ProdcutFilter=[];">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>Quotation Value (€):</h6>
                                            </label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                    (keyup)="OnQuotationValueFilterChange('From')" value="0"
                                                    name="quotationamtfrom" maxlength="7" pattern="[0-9]+"
                                                    #quotationamtfrom="ngModel" placeholder="From Quotation Value"
                                                    maxlength="9" [(ngModel)]="PaginationModel.QuotationValueFromFilter"
                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);">

                                                <input type="text" class="form-control"
                                                    min="PaginationModel.QuotationValueFromFilter"
                                                    (keyup)="OnQuotationValueFilterChange('To')" value="0"
                                                    name="quotationamtto" maxlength="9" pattern="[0-9]+"
                                                    #quotationamtto="ngModel" placeholder="To Quotation Value"
                                                    maxlength="9" [(ngModel)]="PaginationModel.QuotationValueToFilter"
                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);">
                                                <div class="input-group-append"
                                                    (click)="PaginationModel.QuotationValueFromFilter=null;OnQuotationValueFilterChange('From');PaginationModel.QuotationValueToFilter=null;OnQuotationValueFilterChange('To')"
                                                    *ngIf="PaginationModel.QuotationValueFromFilter != null || PaginationModel.QuotationValueToFilter != null">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>To Quotation Value (€):</h6>
                                            </label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                    min="PaginationModel.QuotationValueFromFilter"
                                                    (keyup)="OnQuotationValueFilterChange('To')" value="0"
                                                    name="quotationamtto" maxlength="9" pattern="[0-9]+"
                                                    #quotationamtto="ngModel" placeholder="To Quotation Value"
                                                    maxlength="9" [(ngModel)]="PaginationModel.QuotationValueToFilter"
                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);">
                                                <div class="input-group-append"
                                                    (click)="PaginationModel.QuotationValueToFilter=null;OnQuotationValueFilterChange('To')"
                                                    *ngIf="PaginationModel.QuotationValueToFilter != null">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div> -->

                                        <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>Quotation Status:</h6>
                                            </label>
                                            <div class="input-group">
                                                <ng-select [items]="EnquiryGridFilterList.QuotationStatus"
                                                    [ngClass]="(PaginationModel.QuotationStatusFilter.length > 0 && PaginationModel.QuotationStatusFilter != []) ? 'InputFiledWithbtn' : 'GridFilter'"
                                                    [multiple]="true" appendTo="body" bindLabel="item"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="false" [clearable]="false" bindValue="item"
                                                    placeholder="Select Status"
                                                    [(ngModel)]="PaginationModel.QuotationStatusFilter">

                                                    <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                        let-index="index">
                                                        <input id="item-{{index}}" type="checkbox"
                                                            [ngModel]="item$.selected" title="{{item}}" />
                                                        <span title="{{item}}">{{item}}</span>
                                                    </ng-template>

                                                </ng-select>
                                                <div class="input-group-append"
                                                    *ngIf="(PaginationModel.QuotationStatusFilter.length > 0 && PaginationModel.QuotationStatusFilter != [])"
                                                    (click)="PaginationModel.QuotationStatusFilter=[];">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>Discount (%):</h6>
                                            </label>

                                            <div class="input-group">
                                                <input type="text" class="form-control m-input" min="0" max="99.99"
                                                    step="0.01" (keyup)="onDiscountChange('From')" value="0"
                                                    name="DiscountFrom" minlength="0" maxlength="5"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                    [(ngModel)]="PaginationModel.DiscountFromFilter"
                                                    placeholder="From Discount">

                                                <input type="text" class="form-control m-input" min="0" max="99.99"
                                                    step="0.01" (keyup)="onDiscountChange('To')" value="0"
                                                    name="DiscountTo" minlength="0" maxlength="5"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                    [(ngModel)]="PaginationModel.DiscounToFilter"
                                                    placeholder="To Discount">

                                                <div class="input-group-append"
                                                    (click)="PaginationModel.DiscountFromFilter=null;onDiscountChange('From');PaginationModel.DiscounToFilter=null;onDiscountChange('To')"
                                                    *ngIf="PaginationModel.DiscountFromFilter != null || PaginationModel.DiscounToFilter != null">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div><br />
                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-group m-form__group row">



                                        <!-- <div class="col-sm-3 form-group ml-1.9">
                                            <label style="display: flex;" class="col-sm2">
                                                <h6>To Discount (%):</h6>
                                            </label>
                                            <div class="input-group">
                                                <input type="text" class="form-control m-input" min="0" max="99.99"
                                                    step="0.01" (keyup)="onDiscountChange('To')" value="0"
                                                    name="DiscountTo" minlength="0" maxlength="5"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                    [(ngModel)]="PaginationModel.DiscounToFilter"
                                                    placeholder="To Discount">

                                                <div class="input-group-append"
                                                    (click)="PaginationModel.DiscounToFilter=null;onDiscountChange('To')"
                                                    *ngIf="PaginationModel.DiscounToFilter != null">
                                                    <span class="input-group-text">
                                                        <i class="la la-close"></i>
                                                    </span>
                                                </div><br />
                                            </div>
                                        </div> -->



                                        <div class="col-sm-3 form-group ml-1.9">
                                            <div class="row" style="margin-top: 28px;margin-left: 2px;">

                                                <button id="filter" (click)="GetAllEnquiry()"
                                                    class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Filter</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                                                <button id="resetfilter" (click)="ResetAllFilters()"
                                                    class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                                    *ngIf="(EnquiryngbDateFromFiter != null ||
                                                    EnquiryngbDateToFiter != null ||
                                                    (PaginationModel.QuotationValueToFilter != null) ||
                                                    (PaginationModel.QuotationValueFromFilter != null) ||
                                                    (PaginationModel.DiscountFromFilter != null) ||
                                                    (PaginationModel.DiscounToFilter != null) ||
                                                    (PaginationModel.EstimatedEnquiryValueFilter.length > 0 && PaginationModel.EstimatedEnquiryValueFilter != []) ||
                                                    (PaginationModel.SegmentFilter.length > 0 && PaginationModel.SegmentFilter != []) ||
                                                    (PaginationModel.MicroSegmentFilter.length > 0 && PaginationModel.MicroSegmentFilter != []) ||
                                                    (PaginationModel.CusomterNameFilter.length > 0 && PaginationModel.CusomterNameFilter != []) ||
                                                    (PaginationModel.CusomterClassFilter.length > 0 && PaginationModel.CusomterClassFilter != []) ||
                                                    (PaginationModel.ProdcutFilter.length > 0 && PaginationModel.ProdcutFilter != []) ||
                                                    (PaginationModel.QuotationStatusFilter.length > 0 && PaginationModel.QuotationStatusFilter != []) ||
                                                    (PaginationModel.InitiatorNameFilter.length > 0 && PaginationModel.InitiatorNameFilter != []) ||
                                                    (PaginationModel.RegionFilter.length > 0 && PaginationModel.RegionFilter != []))">Reset</button>
                                            </div>




                                        </div>

                                    </div>


                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="m-portlet__body">

                            <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div class="row">

                                    <div class="col-sm-12 col-md-6"></div>
                                    <div class="col-sm-12 col-md-6 right">
                                        <mat-form-field style="width: 50%;" class="pull-right">

                                            <mat-label>Search</mat-label>
                                            <input matInput (keyup)="SearchParam($event)" placeholder="Search" #input>
                                        </mat-form-field>

                                    </div>

                                </div>

                                <div class="mat-elevation-z8">
                                    <div class="table-responsive maxht400">
                                        <table mat-table [dataSource]="dataSource" matSort
                                            (matSortChange)="sortData($event)"
                                            class="table table-striped- table-bordered table-hover dataTable no-footer"
                                            role="grid" multiTemplateDataRows>

                                            <ng-container matColumnDef="EnquiryId">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Enquiry No. </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('EnquiryNo',i)"
                                                    [style.display]="getRowSpan('EnquiryNo', i) ? '' : 'none'"
                                                    style="word-break: break-all;">

                                                    <a [routerLink]="['/EditViewEnquiry', row.EnquiryId]"
                                                        routerLinkActive="active">{{row.EnquiryNumber}}</a>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="EnquiryDate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Enquiry Date</th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('EnquiryDate',i)"
                                                    [style.display]="getRowSpan('EnquiryDate', i) ? '' : 'none'">
                                                    {{row.EnquiryDate | date: "dd-MMM-yyyy"}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="CustomerName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer
                                                    Name/Code
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('CustomerName',i)"
                                                    [style.display]="getRowSpan('CustomerName', i) ? '' : 'none'">
                                                    {{row.CustomerName}} <br>{{row.CustomerCode}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="EstimatedProductvalue">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estimated Enquiry
                                                    Value
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('EstimatedProductvalue',i)"
                                                    [style.display]="getRowSpan('EstimatedProductvalue', i) ? '' : 'none'">
                                                    {{row.EstimatedProductvalue}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="IndustrySegment">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Segment
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('IndustrySegment',i)"
                                                    [style.display]="getRowSpan('IndustrySegment', i) ? '' : 'none'">
                                                    {{row.IndustrySegment}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="SubSegment">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Micro - Segment
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('SubSegment',i)"
                                                    [style.display]="getRowSpan('SubSegment', i) ? '' : 'none'">
                                                    {{row.SubSegment}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="RegionName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Region</th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('RegionName',i)"
                                                    [style.display]="getRowSpan('RegionName', i) ? '' : 'none'">
                                                    {{row.RegionName}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="CustomerClassification">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Class.
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('CustomerClassification',i)"
                                                    [style.display]="getRowSpan('CustomerClassification', i) ? '' : 'none'">
                                                    {{row.CustomerClassification}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="QuotationNumber">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Quotation No.
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('QuotationNumber',i)"
                                                    [style.display]="getRowSpan('QuotationNumber', i) ? '' : 'none'"
                                                    style="word-break: break-all;">
                                                    <a target="_blank"
                                                        href="{{QuotationDownloadPath}}{{row.QuotationPdf_filename}}">
                                                        {{row.QuotationNumber}}
                                                    </a>
                                                </td>
                                            </ng-container>

                                            <!-- <ng-container matColumnDef="CustomerId">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Code
                                                </th>
                                                <td mat-cell *matCellDef="let row"> {{row.CustomerCode}} </td>
                                            </ng-container> -->

                                            <ng-container matColumnDef="Products">
                                                <th mat-header-cell *matHeaderCellDef> Products
                                                </th>
                                                <td style="word-break: break-all;" title="{{row.Products}}" mat-cell
                                                    *matCellDef="let row">
                                                    {{row.Products}}

                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="EnquiryValue">
                                                <th mat-header-cell *matHeaderCellDef> Quotation Amount (€)
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.EnquiryValue==null?'':row.EnquiryValue| indianCurrency}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Discount">
                                                <th mat-header-cell *matHeaderCellDef> Discount (%)
                                                </th>
                                                <td mat-cell *matCellDef="let row">

                                                    {{row.Discount}}

                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="EnquiryWinLostOpenStatus">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Quotation Status
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('EnquiryWinLostOpenStatus',i)"
                                                    [style.display]="getRowSpan('EnquiryWinLostOpenStatus', i) ? '' : 'none'">
                                                    {{row.EnquiryWinLostOpenStatus}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="ActivityType">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Type
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('ActivityType',i)"
                                                    [style.display]="getRowSpan('ActivityType', i) ? '' : 'none'">
                                                    {{row.ActivityType}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Activity_Subject">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Subject
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('Activity_Subject',i)"
                                                    [style.display]="getRowSpan('Activity_Subject', i) ? '' : 'none'">
                                                    {{row.Activity_Subject}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="ActivityStatus">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Status
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('ActivityStatus',i)"
                                                    [style.display]="getRowSpan('ActivityStatus', i) ? '' : 'none'">
                                                    {{row.ActivityStatus}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="ActivityPurpose">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Purpose
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('ActivityPurpose',i)"
                                                    [style.display]="getRowSpan('ActivityPurpose', i) ? '' : 'none'">
                                                    {{row.ActivityPurpose}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="ActivityPurposeDesc">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Purpose Desc
                                                </th>
                                                <td mat-cell title="{{row.ActivityPurposeDesc}}"
                                                    *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('ActivityPurposeDesc',i)"
                                                    [style.display]="getRowSpan('ActivityPurposeDesc', i) ? '' : 'none'">
                                                    <textarea name="filetext" id="filetext" cols="30" rows="4"
                                                        class="filetext-textarea" readonly
                                                        *ngIf="row.ActivityPurposeDesc != null && row.ActivityPurposeDesc != ''">
                                                    {{row.ActivityPurposeDesc}}</textarea>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Activity_Outcome">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Outcome
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('Activity_Outcome',i)"
                                                    [style.display]="getRowSpan('Activity_Outcome', i) ? '' : 'none'">
                                                    {{row.Activity_Outcome}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Activity_OutcomeDesc">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Outcome Desc
                                                </th>
                                                <td mat-cell title="{{row.Activity_OutcomeDesc}}"
                                                    *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('Activity_OutcomeDesc',i)"
                                                    [style.display]="getRowSpan('Activity_OutcomeDesc', i) ? '' : 'none'">
                                                    <textarea name="filetext" id="filetext" cols="30" rows="4"
                                                        class="filetext-textarea" readonly
                                                        *ngIf="row.ActivityOutcomeDesc != null && row.ActivityOutcomeDesc != ''">
                                                    {{row.Activity_OutcomeDesc}}</textarea>
                                                </td>
                                            </ng-container>

                                            <!-- <ng-container matColumnDef="Activity_Updates">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Updates
                                                </th>
                                                <td mat-cell title="{{row.Activity_Updates}}"
                                                    *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('Activity_Updates',i)"
                                                    [style.display]="getRowSpan('Activity_Updates', i) ? '' : 'none'">
                                                    <textarea name="filetext" id="filetext" cols="30" rows="4"
                                                        class="filetext-textarea" readonly
                                                        *ngIf="row.Activity_Updates != null && row.Activity_Updates != ''">
                                                    {{row.Activity_Updates}}</textarea>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Activity_ActionPlan">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Action Plan
                                                </th>
                                                <td mat-cell title="{{row.Activity_ActionPlan}}"
                                                    *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('Activity_ActionPlan',i)"
                                                    [style.display]="getRowSpan('Activity_ActionPlan', i) ? '' : 'none'">
                                                    <textarea name="filetext" id="filetext" cols="30" rows="4"
                                                        class="filetext-textarea" readonly
                                                        *ngIf="row.Activity_ActionPlan != null && row.Activity_ActionPlan != ''">
                                                    {{row.Activity_ActionPlan}}</textarea>
                                                </td>
                                            </ng-container> -->

                                            <!-- <ng-container matColumnDef="EnquiryDescription">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Enquiry
                                                    Description</th>
                                                    <td mat-cell class="wrapword" title="{{row.EnquiryDescription}}"
                                                    *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('EnquiryDescription',i)"
                                                    [style.display]="getRowSpan('EnquiryDescription', i) ? '' : 'none'">
                                                    {{row.EnquiryDescription}} </td>
                                            </ng-container> -->

                                            <ng-container matColumnDef="EnquiryStatus">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> CRM updates </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('EnquiryStatus',i)"
                                                    [style.display]="getRowSpan('EnquiryStatus', i) ? '' : 'none'">
                                                    {{row.EnquiryStatus}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="InitiatorName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Initiator Name
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('InitiatorName',i)"
                                                    [style.display]="getRowSpan('InitiatorName', i) ? '' : 'none'">
                                                    {{row.InitiatorName}} </td>
                                            </ng-container>

                                            <!-- <ng-container matColumnDef="EnquirySource">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Source
                                                </th>
                                                <td mat-cell *matCellDef="let row"> {{row.EnquirySource}} </td>
                                            </ng-container> -->


                                            <ng-container matColumnDef="Action">
                                                <th mat-header-cell *matHeaderCellDef> Action </th>
                                                <td mat-cell *matCellDef="let row;let i = dataIndex"
                                                    [attr.rowspan]="getRowSpan('Action',i)"
                                                    [style.display]="getRowSpan('Action', i) ? '' : 'none'">
                                                    <a (click)="openQuotationPopup(row.EnquiryId,row.EnquiryNumber,row.Initiator_Id,row.Status,row.PendingAtRoleId)"
                                                        title="Activity List"
                                                        class="btn btn-primary m-btn m-btn--icon m-btn--icon-only mr-2"><i
                                                            class="fa fa-tasks"></i></a>
                                                    <a style="margin-top: 8px;" title="add"
                                                        (click)="addEnquiryPopup(row.EnquiryId, row.CustomerId)"
                                                        class="btn btn-primary m-btn m-btn--icon m-btn--icon-only">
                                                        <i class="flaticon-add"></i></a>
                                                    <a style="margin-top: 8px;"
                                                        *ngIf="role==AppRoles.SalesDirector || role==AppRoles.CEO || role==AppRoles.CustomerService"
                                                        title="Pass on Enquiry" (click)="openPassOn(row)"
                                                        class="btn btn-primary m-btn m-btn--icon m-btn--icon-only"><i
                                                            class="fa fa-user-friends"></i></a>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                        </table>
                                        <div *ngIf="dataSource != undefined && (dataSource.filteredData == null || dataSource.filteredData?.length === 0)"
                                            class="text-center">No records found</div>
                                    </div>

                                    <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                                        [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent($event)">
                                    </mat-paginator>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <app-activity-details [EnquiryId]="EnquiryId"></app-activity-details> -->