import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ViewChild, TemplateRef, } from '@angular/core';
import { endOfMonth, isSameDay, isSameMonth, addHours, startOfMonth, } from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, } from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { ActivityService } from 'src/app/Service/activity.service';
import { Activity, ActivityLinkageEntityType, ActivityStatusByType, ActivityType, User } from 'src/app/Model/ActivityModel';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { startOfWeek, endOfWeek, format } from 'date-fns';
import { ViewActivityComponent } from '../view-activity/view-activity.component';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';

export interface EventAction {
  id?: string | number;
  label: string;
  cssClass?: string;
  a11yLabel?: string;
  onClick({ event, sourceEvent, }: {
    event: CalendarEvent;
    sourceEvent: MouseEvent | KeyboardEvent;
  }): any;
}

export interface CalendarEvent1<MetaType = any> {
  ActivityId?: number;
  id?: string | number;
  start: Date;
  end?: Date;
  title: string;
  color?: EventColor;
  actions?: EventAction[];
  allDay?: boolean;
  cssClass?: string;
  resizable?: {
    beforeStart?: boolean;
    afterEnd?: boolean;
  };
  draggable?: boolean;
  meta?: MetaType;
}


const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-calender-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calender-view.component.html',
  styleUrls: ['./calender-view.component.scss']
})
export class CalenderViewComponent implements OnInit {

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any> | undefined;

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: any;
  modalData: any;

  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];
   canViewCustomerEngagementReportOfOthers:boolean=true;
  Role: any;
  partid: string = null;
  canEditCustomerEngagementReportOfOthers: boolean;


  constructor(private router: Router, private activityService: ActivityService, private modalService: NgbModal, public dialog: MatDialog, private toastr: ToastrService, private cd: ChangeDetectorRef,private accessservice: AccessPermissionService) { }

  activities: Activity[];
  filteredActivities: Activity[];
  activityStatusFilter: number;
  priorityFilter: string;
  activityTypeFilter: number;
  activityTypeMaster: ActivityType[] = [];
  filterActivityForm: any = {};
  activityStatusByTypeMaster: ActivityStatusByType[] = [];
  allEntityTypesMaster: ActivityLinkageEntityType[] = [];
  events: CalendarEvent[] = [];
  entityTypeFilterArray: any = [];
  showLoader: boolean = false;
  refresh = new Subject<void>();
  activeDayIsOpen: boolean = false;
  allUserMaster: User[] = [];


  ngOnInit() {
    this.showLoader = true;
   var defaultView = localStorage.getItem('ViewType-A');
   this.backNavigationBackView(defaultView);

    this.viewDate = new Date();
    localStorage.setItem('ViewType', 'Calender');
    this.getActivityTypeMasterDetails();
    this.getallEntityTypesMasterDetails();
    this.filterActivityForm.ActivityTypeId = null;
    this.filterActivityForm.ActivityStatusId = null;
    this.filterActivityForm.Subject = null;
    this.filterActivityForm.StartDate = format(startOfMonth(this.viewDate), "yyyy-MM-dd");
    this.filterActivityForm.EndDate = format(endOfMonth(this.viewDate), "yyyy-MM-dd");
    this.filterActivityForm.EntityTypeId = null;
    this.filterActivityForm.EntityId = null;
    this.filterActivityForm.PageNumber = 1;
    this.filterActivityForm.PageSize = 2000;
    this.filterActivityForm.IsOwner = true;
    this.filterActivityForm.ParticipantId = localStorage.getItem('UserID');
    this.Role = localStorage.getItem("UserRole");
    this.partid = this.filterActivityForm.ParticipantId;
    this.getAllStatus();
    this.getallParticipant();

    this.getAccess().subscribe(x=>{
      this.refreshGrid(this.filterActivityForm);
   });
  }

  backNavigationBackView(defaultView:any){
    if(defaultView == 'week'){
      this.view = CalendarView.Week;
     }else if(defaultView == 'day'){
      this.view = CalendarView.Day;
     }else{
      this.view = CalendarView.Month;
     }
  }

  getallParticipant() {
    this.showLoader = true;
    this.activityService.getallUserMaster().subscribe((data: User[]) => {
      this.showLoader = false;
      this.allUserMaster = data;
      this.cd.detectChanges();

    })
  }


  refreshGrid(filterActivityForm) {
    this.getData(filterActivityForm);
  }


  getallEntityTypesMasterDetails() {
    this.showLoader = true;
    this.activityService
      .getallEntityTypesMaster()
      .subscribe((data: ActivityLinkageEntityType[]) => {
        this.showLoader = false;
        this.allEntityTypesMaster = data;
      });
  }

  getAllActivitiesDetails() {
    this.getData({});
  }


  getActivityTypeMasterDetails() {
    this.showLoader = true;
    this.activityService
      .getActivityTypeMaster()
      .subscribe((data: ActivityType[]) => {
        this.showLoader = false;
        this.activityTypeMaster = data;
        this.cd.detectChanges();
      });
  }

  getAllStatus() {
    this.showLoader = true;
    this.activityService.getActivityStatusMaster(null).subscribe((data: ActivityStatusByType[]) => {
      this.activityStatusByTypeMaster = data;
      this.showLoader = false;
      this.cd.detectChanges();
    });
  }


  onEntityTypeChange(EntityTypeId: any) {
    this.activityService.getrelatedEntityRecords(EntityTypeId,'').subscribe((data: any[]) => {
      this.entityTypeFilterArray = data;
    });
  }



  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];


  dayClicked({ date, events }: { date: Date; events: CalendarEvent1[] }): void {

    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent1): void {
    if (event.ActivityId == null || event.ActivityId == undefined) {
      // this.router.navigate(['/View-Activity', event]);
      this.openViewPopup(event)
      localStorage.setItem('ViewType-A',this.view)
      return;
    }
    localStorage.setItem('ViewType-A', this.view)
    this.openViewPopup(event)
  }



  setView(view: CalendarView) {

    this.view = view;
    this.activityByViewTypeOfCalender();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    this.activityByViewTypeOfCalender();
  }

  onSubmit(filterActivityForm: any) {

    this.filterActivityForm.StartDate = format(startOfMonth(this.viewDate), "yyyy-MM-dd");
    this.filterActivityForm.EndDate = format(endOfMonth(this.viewDate), "yyyy-MM-dd");
    this.filterActivityForm.ActivityStatusId = filterActivityForm.ActivityStatusId;
    this.filterActivityForm.PageNumber = 1;
    this.filterActivityForm.PageSize = 2000;

    if(filterActivityForm.ActivityStatusId == "null"){
      filterActivityForm.ActivityStatusId = null
    }
    if(filterActivityForm.ActivityTypeId == "null"){
      filterActivityForm.ActivityTypeId = null
    }
    if(filterActivityForm.EntityTypeId == "null"){
      filterActivityForm.EntityTypeId = null
    }
    if(filterActivityForm.ParticipantId == "null"){
      filterActivityForm.ParticipantId = null
    }



    this.getData(filterActivityForm);
  }


  clearFilter() {
    let filters = {
      ActivityTypeId: null,
      ActivityStatusId: null,
      Subject: null,
      StartDate: format(startOfMonth(this.viewDate), "yyyy-MM-dd"),
      EntityTypeId: null,
      EntityId: null,
      PageNumber: 1,
      PageSize: 2000,
      IsOwner: false,
      EndDate: format(endOfMonth(this.viewDate), "yyyy-MM-dd"),
    };

    // this.entityTypeFilterArray = [];
    // this.filterActivityForm = {};
    this.getData(filters);
    this.entityTypeFilterArray = [];
    this.filterActivityForm = { ActivityTypeId: null, ActivityStatusId: null, Subject: null, StartDate: null, EntityTypeId: null, EntityId: null, PageNumber: 1, PageSize: 2000, IsOwner: false }
  }


  getData(filterActivityForm) {
    this.activityService
      .getActivityByFilter(filterActivityForm)
      .subscribe((data: any) => {
        this.activities = data.Data;
        this.filteredActivities = data.Data;
        this.checkAccess();
        this.updateEvents(data.Data);
        this.cd.detectChanges();
      });

  }


  updateEvents(filteredActivities) {

    this.events = filteredActivities.map((arg: Activity) => {
      return {
        ActivityId: arg.ActivityId,
        title: arg.Subject,
        start: new Date(arg.PlannedDueDatetime),
        end: addHours(new Date(arg.PlannedDueDatetime), 1),
        color: arg.ActivityType == 'Meeting' ? colors.red : arg.ActivityType == 'Task' ? colors.blue : colors.yellow,
        draggable: false,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      };

    });

  }



  activityByViewTypeOfCalender() {

    let filterDate = this.viewDate;
    let filterStartDate = null;
    let filterEndDate = null;

    switch (this.view) {

      case 'month':
        filterStartDate = startOfMonth(filterDate);
        filterEndDate = endOfMonth(filterDate);
        break;
      case 'week':
        filterStartDate = startOfWeek(filterDate);
        filterEndDate = endOfWeek(filterDate);
        break;
      case 'day':
        filterStartDate = filterDate;
        filterEndDate = filterDate;
        break;
    }

    this.filterActivityForm.StartDate = format(filterStartDate, "yyyy-MM-dd");
    this.filterActivityForm.EndDate = format(filterEndDate, "yyyy-MM-dd");
    this.filterActivityForm.PageNumber = 1;
    this.filterActivityForm.PageSize = 2000;
    this.getData(this.filterActivityForm);

  }


  onKeyDown(event: KeyboardEvent) {
    // Check if the pressed key is a space and if the input field is empty
    if (event.key === ' ' && event.target['value'].trim() === '') {
      event.preventDefault(); // prevent the space from being entered
    }
  }


  openViewPopup(event: any) {
    const modalRef = this.modalService.open(ViewActivityComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });

    var Activity = {
      activityId: event.ActivityId,
      inPopUpMode: true,
      canEditCustomerEngagementReportOfOthers:this.filteredActivities.filter(x=>x.ActivityId==event.ActivityId)[0]?.canEditCustomerEngagementReportOfOthers,
      canViewCustomerEngagementReportOfOthers:this.filteredActivities.filter(x=>x.ActivityId==event.ActivityId)[0]?.canViewCustomerEngagementReportOfOthers
    };

    modalRef.componentInstance.Activity = Activity;
  }

  getAccess(): Observable<any> {
    let tracker = this.accessservice.GetAll();

    tracker.subscribe((data: any) => {
     // console.log(data);
      this.accesspermissionobj = data;
      this.canViewCustomerEngagementReportOfOthers = this.accesspermissionobj.filter(x => x.Fk_RoleId == this.Role && x.ElementKey == 'canViewCustomerEngagementReportOfOthers')[0]?.IsVisible;
      this.canEditCustomerEngagementReportOfOthers = this.accesspermissionobj.filter(x => x.Fk_RoleId == this.Role && x.ElementKey == 'canViewCustomerEngagementReportOfOthers')[0]?.IsEditable;
      if(!this.canViewCustomerEngagementReportOfOthers){

        this.partid = this.filterActivityForm.ParticipantId;
      }
    });

    return tracker;
  }


  
  checkAccess()
  {
      this.filteredActivities.forEach(element => {
        const participantsIdArray = element.ParticipantsID.split(',');
        console.log(localStorage.getItem('UserID'));
        
        if((participantsIdArray.filter(x=>x==localStorage.getItem('UserID')).length>0 ||element.ActivityOwner==localStorage.getItem('UserID')))
        {
          element.canEditCustomerEngagementReportOfOthers=true;
          element.canViewCustomerEngagementReportOfOthers=true;
        }
        else
        {
          element.canEditCustomerEngagementReportOfOthers=this.canEditCustomerEngagementReportOfOthers;
          element.canViewCustomerEngagementReportOfOthers=this.canViewCustomerEngagementReportOfOthers;
        }
      });  
  }

}
