import { Component, OnInit, ViewChild, Input, NgZone, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SingleFieldComponent, SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { UpdateStatus } from 'src/app/Model/CommonModel';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { AppGlobals } from 'src/app/app.global';
import { UserRoles } from 'src/app/UserRoles';

import * as Excel from 'exceljs/dist/exceljs'
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];

  displayedColumns: string[] = ['Id', 'CompanyName', 'RegionName', 'GroupName', 'ContactPerson', 'KYCAttachment', 'Action'];
  dataSource: MatTableDataSource<SingleFieldModel>;
  singleFieldComponent: SingleFieldComponent = new SingleFieldComponent();
  singleFieldObj: SingleFieldModel = new SingleFieldModel();
  disabled: boolean = false;
  Role: any;
  UserRoles: any;
  isCustomerEditable: boolean = false;
  isDownloadCustomerData: boolean = false;
  isKYCAttchmentVisible: boolean = false;
  /*************************************************************************
        Server side Pagination purpose
    *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';
  ColumnName: string = 'ModifiedOn';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  /*************************************************************************
     End here
  *************************************************************************/


  @Input()
  showLoader: boolean = false;
  data: any[] = [];
  UpdateStatus: UpdateStatus = new UpdateStatus();
  myfile: any;
  customerData: any[] = [];
  type="customer";
  
  constructor(private toastr: ToastrService, private Service: CommonService, public dialog: MatDialog,
    private http: HttpClient, private NgZone: NgZone, private router: Router, private route: ActivatedRoute,
     private titleService: Title,private accessservice: AccessPermissionService,
     private appConfigService : AppConfigService) {
    this.UserRoles = UserRoles;
  }

  ngOnInit() {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    // this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    
    this.singleFieldComponent.RoutingParam = this.route.snapshot.params["CustomerSpecialDiscount"];
    this.singleFieldObj.Id = this.route.snapshot.params["id"];
    this.Role = localStorage.getItem("UserRole");
    this.getAccess();
   // this.isCustomerEditable = AppGlobals.CustomerMastersEditableAccessArray.filter(x => x.role == this.Role)[0]?.isEditable;
    // this.isDownloadCustomerData = AppGlobals.CustomerMastersDataDownloadAccessArray.filter(x => x.role == this.Role)[0]?.isEditable;
    // this.isKYCAttchmentVisible= AppGlobals.CustomerMastersKYCAttachmentAccessArray.filter(x => x.role == this.Role)[0]?.isEditable;
    this.GetAllCustomerDetails();
  }

  ngOnChanges(){
    this.GetAllCustomerDetails();
  }

  /*************************************************************************
   * Below function used for Server side Pagination,Sorting and Searching
   * (1)GetAllCustomerDetails() - To get data for grid
   * (2)SearchParam() -  searching
   * (3)sortData() - Sorting
   * (4)pageEvent() - Pagination
   * 
   * ***********************************************************************/

  GetAllCustomerDetails() {
    this.showLoader = true;
    let queryString = "";
    queryString = "pageNumber=" + this.pageNumber;
    queryString += "&pageSize=" + this.pageSize;
    queryString += "&searchText=" + this.SearchText;
    queryString += "&direction=" + this.direction;
    queryString += "&columnName=" + this.ColumnName;

    this.Service.GetData('Customer', 'GetAllPagination', queryString).subscribe((data: any) => {
      this.data = data.Data;
      this.length = data.Total;

      this.dataSource = new MatTableDataSource(this.data);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });


  }

  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.GetAllCustomerDetails();
  }

  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAllCustomerDetails();
    }
  }

  pageEvent(event: any) {
    
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
     
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAllCustomerDetails();
  }
  /************************************************************************
   * Grid Code End 
   * **********************************************************************/

  /*******************************************************************************
 * Form validation method
 * ******************************************************************************/

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  /*******************************************************************************
   *Download file section
   * ******************************************************************************/
  DownloadFile(Path: any) {
    let link = document.createElement('a');
    let path = AppGlobals.baseAppUrl + 'Uploads/' + Path
    link.setAttribute('href', path);
    document.body.appendChild(link);
    link.click();
    link.remove();
    return false;

  }
  /*******************************************************************************
 *End Download file section
 * ******************************************************************************/

  UpdateCustomerStatusById(Id: number, CurrentStatus: boolean) {

    if (CurrentStatus == true) {
      //Create ConfirmDialogRef 
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to deactivate this customer?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Id;

          this.Service.PostData('Customer', 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Customer deactivated successfully!");
            }
            else {
              this.toastr.info("Failed to deactivate customer!");
            }
            this.GetAllCustomerDetails();
          }, error => {
            this.toastr.error("Failed to deactivate customer!");
            this.GetAllCustomerDetails();
            this.showLoader = false;
          });
        }
        else {
          this.GetAllCustomerDetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });

    }
    else {
      //Create ConfirmDialogRef
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to activate this customer?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Id;

          this.Service.PostData('Customer', 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Customer activated successfully!");
            }
            else {
              this.toastr.info("Failed to activate customer!");
            }
            this.GetAllCustomerDetails();
          }, error => {
            this.toastr.error("Failed to activate customer!");
            this.GetAllCustomerDetails();
            this.showLoader = false;
          });

        }
        else {
          this.GetAllCustomerDetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });
    }
  }


  ExportData() {
    this.Service.GetData("Customer", "ExporttoExcel", null).subscribe((data: any) => {
      this.customerData = data;
      if (this.customerData.length > 0) {
        this.exportAsXLSX(this.customerData, 'CustomerData.xlsx');
      }
      else {
        this.toastr.error("Failed to load data!");
      }
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  exportAsExcelFile(json: any[], excelFileName: string): void {
    // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // const workbook: XLSX.WorkBook = { Sheets: { 'CustomerData': worksheet }, SheetNames: ['CustomerData'] };

    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // this.saveAsExcelFile(excelBuffer, excelFileName);
    let workbook = new Excel.Workbook();
    workbook = this.getworkSheet(json, workbook);
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.saveAsExcelFile(data, excelFileName);
    });
  }

  getworkSheet(sheet2: any[], workbook: any) {

    let worksheet = workbook.addWorksheet('CustomerData');
    let header = ['CustomerCode',	'CompanyName',	'AddressHeadquarters',	'AddressPlant',	'City',	'State',
    'PINCode',	'Country',	'MailAddress',	'WebSite',	'HeadquarterInfo',	'KeyManagementPerson_Name',
    'KeyManagementPerson_ContactNo',	'KeyManagementPerson_MailAddress',	'WINAccountManager',	'Region',
    'CompanyInformation',	'BrandsProducts',	'IndustrySegment',	'SubSegment',	'CustomerClassification',
    'TypeofcustomerName',	'CustomerCategory',	'CustomersCustomer',	'Indexer',	'HeavyDutyIndexer',	
    'LinearTransferSystem',	'PickandPlaceSystem',	'LinearSystem',	'Directdrives',	'DeltaRobot',	
    'Valueaddedsolution',	'AccessoriesSpareParts',	'Comments',	'CurrentVisibleCompetitorsNamewithUSP'];
  
    let headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '74B4FE' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10,
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    })
    for (let key of Object.keys(sheet2)) {
      let tmparray = [];
      let tmpobj = sheet2[key];

      for (let key of Object.keys(tmpobj)) {
        let tmpobj1 = tmpobj[key];
        tmparray.push(tmpobj1)
      }
    worksheet.addRow(tmparray);
    }
    return workbook;

  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName);
  }

  public exportAsXLSX(arrayofdata, filename: string): void {
    //const data = this.getFormatedData(arrayofdata);
    this.exportAsExcelFile(arrayofdata, filename);
  }

  public getFormatedData(arrayofdata) {
    const formateddata = [];
    for (let i = 0; i < arrayofdata.length; i++) {
      const obj = arrayofdata[i];
      const newobj = {
        CustomerCode: obj.CustomerCode,
        Country: obj.Country,
        CompanyName: obj.CompanyName,
        AddressOfHeadquater: obj.AddressHeadquaters,
        AddressPlant: obj.AddressPlant,

      }
      formateddata.push(newobj);
    };
    return formateddata
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isCustomerEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.Role && x.ElementKey=='isCustomer')[0]?.IsEditable;
      this.isDownloadCustomerData = this.accesspermissionobj.filter(x => x.Fk_RoleId == this.Role && x.ElementKey=='IsCustomerMastersDataDownloadAccess')[0]?.IsVisible;
      this.isKYCAttchmentVisible=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.Role && x.ElementKey=='IsCustomerMastersKYCAttachmentAccess')[0]?.IsVisible;
    });
 
  }
 
}
