import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Level1Model, Station, VariantLevel1 } from 'src/app/Model/ProductModel';
import { MatSelectChange } from '@angular/material/select';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-edit-view-variant-level1',
  templateUrl: './edit-view-variant-level1.component.html',
  styleUrls: ['./edit-view-variant-level1.component.scss']
})
export class EditViewVariantLevel1Component implements OnInit {
  isEditable: boolean = true;
  FormStatus: string = "View";
  VariantLevel1obj: VariantLevel1 = new VariantLevel1();
  charCode: any;
  ProductFamily: any[] = [];
  @Input()
  showLoader: boolean = false;
  ProductSeries: any[] = [];
  ProductModel: any[] = [];
  StationList: Level1Model[] = [];
  SelectedStation: any[] = [];
  ProductSeriesVariantLabel: string = '';
  queryString: string = "";
  UserRole: any;
  isProductVariant1Editable: boolean = false;

  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];


  @Output() selectionChange: EventEmitter<MatSelectChange>
  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title,private accessservice: AccessPermissionService,
    private appConfigService : AppConfigService) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    // this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
    //this.isProductVariant1Editable = AppGlobals.ProductMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.ProductSeriesVariantLabel = this.route.snapshot.params["label"];
    if (this.route.snapshot.params["id"] == undefined) {
      this.queryString = "ProductModel=" + this.route.snapshot.params["ProductModel"];
      this.queryString += "&Price=" + this.route.snapshot.params["Price"];
      this.queryString += "&Status=" + this.route.snapshot.params["Status"];
      this.queryString += "&IsPriceOnRequest=" + this.route.snapshot.params["IsPriceOnRequest"];
      this.getVariantLevel1IdDetailsById('GetbyProductModelAndPrice', this.queryString);
    }
    else {
      this.queryString = "Id=" + this.route.snapshot.params["id"];
      this.getVariantLevel1IdDetailsById('GetById', this.queryString);
    }

  }
  /*******************************************************************************
     * Get data for add variant level-1
  * ******************************************************************************/
  getVariantLevel1IdDetailsById(ActionName: string, queryString: string) {
    this.showLoader = true;

    this.CommonService.GetData('ProductVariantLevel1', ActionName, queryString).subscribe((data: any) => {
      this.VariantLevel1obj = data;
      if (this.VariantLevel1obj.SeriesName == 'TC' || this.VariantLevel1obj.SeriesName == 'TR') {
        this.StationList = this.VariantLevel1obj.Level1;
        this.SelectedStation = this.VariantLevel1obj.Level1.map(item => item.Level1.toString()); //Level1
        this.GetStation(this.VariantLevel1obj.ProductModel);

      }
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });

  }


  GetStation(Model: any) {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + Model;
    this.CommonService.GetData("ProductVariantLevel1", "GetAllStation", queryString).subscribe((data: any) => {
      this.StationList = this.VariantLevel1obj.Level1.concat(data);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  /*******************************************************************************
   * End get data for add variant level-1
   * ******************************************************************************/

  OnStationselected(event: any) {
    this.VariantLevel1obj.Level1 = [];
  }

  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    const appConfig = this.appConfigService.getConfig();
    this.titleService.setTitle(`Edit Product Variant Level 1 - ${appConfig.titleName}`);
    // this.titleService.setTitle("Edit Product Variant Level 1 - ECOTECH");
  }

  onIsPriceOnRequest(event: any) {
    if (event.checked) {
      this.VariantLevel1obj.Price = 0;
    }
  }

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  DeleteStation() {
    this.VariantLevel1obj.Level1 = [];
    for (var i = 0; i < this.SelectedStation.length; i++) {
      let obj = this.StationList.find(x => x.Level1 == this.SelectedStation[i]);
      this.VariantLevel1obj.Level1.push(obj);
    }
    this.VariantLevel1obj.Level1 = this.VariantLevel1obj.Level1.filter(x => x.Id != null);
    if (this.VariantLevel1obj.Level1.length == 0) {
      this.toastr.warning("This " + this.ProductSeriesVariantLabel + " does not exist for " + this.VariantLevel1obj.ModelName + " product model")
      this.VariantLevel1obj.Level1 = [];
    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to delete this " + this.ProductSeriesVariantLabel + "?"
        }
      });

      dialogRef.afterClosed().subscribe(dialogResult => {

        if (dialogResult) {
          this.showLoader = true;

          this.CommonService.PostData('ProductVariantLevel1', 'DeleteStation', this.VariantLevel1obj, null).subscribe((data: any) => {
            let result = data;

            if (result.ResponseCode == 1) {
              this.toastr.success(this.ProductSeriesVariantLabel + ' deleted successfully!');
              this.router.navigate(['/VariantLevel1', this.VariantLevel1obj.ProductSeries, this.ProductSeriesVariantLabel]);
              this.showLoader = false;
            }
            else if (result.ResponseCode == 0) {
              this.toastr.warning("Failed to delete this " + this.ProductSeriesVariantLabel + " because it has some dependency!");
              this.showLoader = false;
            }
            else {
              this.toastr.error('Failed to delete ' + this.ProductSeriesVariantLabel + '!');
              this.router.navigate(['/VariantLevel1', this.VariantLevel1obj.ProductSeries, this.ProductSeriesVariantLabel]);
              this.showLoader = false;
            }


          }, error => {
            this.toastr.error('Failed to delete ' + this.ProductSeriesVariantLabel + '!');
            this.showLoader = false;
            this.router.navigate(['/VariantLevel1', this.VariantLevel1obj.ProductSeries, this.ProductSeriesVariantLabel]);
          });
        }
        else {
          this.VariantLevel1obj.Level1 = [];
        }
      });
    }
  }


  onSubmit(myform: any) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update this " + this.ProductSeriesVariantLabel + "?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.showLoader = true;

        for (var i = 0; i < this.SelectedStation.length; i++) {
          let obj = this.StationList.find(x => x.Level1 == this.SelectedStation[i]);
          this.VariantLevel1obj.Level1.push(obj);
        }
        this.CommonService.PostData('ProductVariantLevel1', 'Update', this.VariantLevel1obj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success(this.ProductSeriesVariantLabel + ' updated successfully!');
            this.router.navigate(['/VariantLevel1', this.VariantLevel1obj.ProductSeries, this.ProductSeriesVariantLabel]);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning(this.ProductSeriesVariantLabel + ' already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to update ' + this.ProductSeriesVariantLabel + '!');
            this.router.navigate(['/VariantLevel1', this.VariantLevel1obj.ProductSeries, this.ProductSeriesVariantLabel]);
            this.showLoader = false;
          }


        }, error => {
          this.toastr.error('Failed to update ' + this.ProductSeriesVariantLabel + '!');
          this.showLoader = false;
          this.router.navigate(['/VariantLevel1', this.VariantLevel1obj.ProductSeries, this.ProductSeriesVariantLabel]);
        });
      }
    });
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isProductVariant1Editable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isProductLine')[0]?.IsEditable;
      
    });
 
  }
}
