<!-- <h1 mat-dialog-title>Price List Upload Log...</h1> -->
<div mat-dialog-content>  
  <!-- <mat-form-field> -->
    <div class="col-sm-12">      
      <div class="m-portlet">
    <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title">
          <h3 class="m-portlet__head-text">
            Price List Upload Log...
          </h3>
        </div>
      </div>
    </div>
    <div class="m-portlet__body">
    <div class="table-responsive">
      <table class="table table-edit table-hover">
        <thead>
          <tr>
            <th scope="col">Row No</th>
            <!-- <th scope="col">ModelID</th> -->
            <th scope="col">Series Name</th>
            <th scope="col">Model Name</th>
            <th scope="col">Description</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let a of dataSourcePrice">
            <td>{{a.RowNo}}</td>
            <!-- <td>{{a.ModelID}}</td> -->
            <td>{{a.SeriesName}}</td>
            <td>{{a.ModelName}}</td>
            <td>{{a.Description}}</td>
            <td class="text-center">
              <span *ngIf="a.Status;else falseCondition"><i class="fas fa-check-square pl-2" style="color: #44bd44;"></i></span>
              <ng-template #falseCondition><i class="fas fa-times-circle" style="color:red;"></i></ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
      </div>
    </div>
  <!-- </mat-form-field> -->
</div>
<div mat-dialog-actions style="justify-content: flex-end;">
  <button mat-button (click)="onOkClick()" class="btn btn-primary m-btn m-btn--air m-btn--custom custbtns">Ok</button>
</div>


