<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text"> Activity Data
                                    </h3>
                                </div>
                            </div>

                        </div>

                        <div class="m-portlet__body">

                        <div>
                            <div class="form-group m-form__group row">

                                <div class="col-sm-3 form-group ml-1.9">
                                    
                                    <label style="display: flex;" class="col-sm2">
                                        <h6>User Name:</h6></label>
                                    <div class="input-group">
                                        <ng-select [items]="SiteActivityLogGridFilterList.InitiatorName"
                                            [ngClass]="(PaginationModel.InitiatorNameFilter.length > 0 && PaginationModel.InitiatorNameFilter != []) ? 'InputFiledWithbtn' : 'GridFilter'"
                                            [multiple]="true" appendTo="body" bindLabel="Name"
                                            [clearable]="false" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="false"
                                            bindValue="Name" placeholder="Select User"
                                            [(ngModel)]="PaginationModel.InitiatorNameFilter" style="height: 40px; line-height:30px;
                                            ">

                                            <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                let-index="index">
                                                <input id="item-{{index}}" type="checkbox"
                                                    [ngModel]="item$.selected" />
                                                <span title="{{item.Name}}" class="checkbox-option">{{item.Name}}</span>
                                            </ng-template>

                                        </ng-select>
                                        <div class="input-group-append"
                                            *ngIf="(PaginationModel.InitiatorNameFilter.length > 0 && PaginationModel.InitiatorNameFilter != [])"
                                            (click)="PaginationModel.InitiatorNameFilter=[];">
                                            <span class="input-group-text">
                                                <i class="la la-close"></i>
                                            </span>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-sm-3 form-group ml-1.9 datepicks">

                                    <div class="form-group hidden mb-0">

                                        <div class="input-group">
                                            <input name="datepicker" class="form-control" ngbDatepicker
                                                #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                                [dayTemplate]="t" outsideDays="hidden"
                                                [startDate]="EnquiryngbDateFromFiter!" tabindex="-1" hidden
                                                [minDate]="minDate" [maxDate]="maxDate"
                                                [footerTemplate]="footerTemplate">
                                            <ng-template #t let-date let-focused="focused">
                                                <span class="custom-day" [class.focused]="focused"
                                                    [class.range]="isRange(date)"
                                                    [class.faded]="isHovered(date) || isInside(date)"
                                                    (mouseenter)="hoveredDate = date"
                                                    (mouseleave)="hoveredDate = null">
                                                    {{ date.day }}
                                                </span>
                                            </ng-template>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label style="display: flex;" class="col-sm2">
                                            <h6>Activity Date:</h6>
                                        </label>
                                        <div class="input-group">
                                            <input readonly #dpFromDate class="form-control"
                                                placeholder="DD-MMM-YYYY" name="dpFromDate"
                                                [value]="formatter.format(EnquiryngbDateFromFiter)"
                                                (input)="EnquiryngbDateFromFiter = validateInput(EnquiryngbDateFromFiter, dpFromDate.value)"
                                                (click)="datepicker.toggle()">
                                            <input readonly #dpToDate class="form-control"
                                                placeholder="DD-MMM-YYYY" name="dpToDate"
                                                [value]="formatter.format(EnquiryngbDateToFiter)"
                                                (input)="EnquiryngbDateToFiter = validateInput(EnquiryngbDateToFiter, dpToDate.value)"
                                                (click)="datepicker.toggle()">
                                            <div class="input-group-append" (click)="datepicker.toggle()"
                                                *ngIf="EnquiryngbDateFromFiter == null || EnquiryngbDateToFiter == null">
                                                <span class="input-group-text">
                                                    <i class="la la-calendar-check-o"></i>
                                                </span>

                                            </div>
                                            <div class="input-group-append"
                                                (click)="EnquiryngbDateFromFiter=null;EnquiryngbDateToFiter=null;OnFromDateFilterChange();OnToDateFilterChange()"
                                                *ngIf="EnquiryngbDateFromFiter != null && EnquiryngbDateToFiter != null">
                                                <span class="input-group-text">
                                                    <i class="la la-close"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <ng-template #footerTemplate>
                                        <hr class="my-0">

                                        <button class="btn btn-primary btn-sm m-2 float-right"
                                            (click)="datepicker.toggle()">Ok</button>
                                    </ng-template>

                                </div>

                               

                                <div class="col-sm-3 form-group ml-1.9" style="margin-top: 25px;">
                                   
                                    <button id="filter" (click)="GetAllSiteActivityLogDetails()"
                                    class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Filter</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    <button id="resetfilter" (click)="ResetAllFilters()"
                                    class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                    *ngIf="(EnquiryngbDateFromFiter != null ||
                                    EnquiryngbDateToFiter != null ||
                                    (PaginationModel.InitiatorNameFilter.length > 0 && PaginationModel.InitiatorNameFilter != []))">Reset</button>
                                </div>
 
                            </div>
                        </div>

                            <div class="mat-elevation-z5">
                                <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                                    class="table table-striped- table-bordered table-hover  no-footer"
                                    role="grid">

                                    <ng-container matColumnDef="Name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
                                        <td mat-cell *matCellDef="let row"> {{row.Name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Http_Method">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                                        <td mat-cell *matCellDef="let row"> {{row.Http_Method}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Route">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Route </th>
                                        <td mat-cell *matCellDef="let row"> {{row.Route}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="CreatedOn">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                                        <td mat-cell *matCellDef="let row"> {{row.CreatedOn | date:'medium':'+0530'}} </td>
                                        
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                </table>
                                <div *ngIf="dataSource != undefined && (dataSource.filteredData == null || dataSource.filteredData?.length === 0)"
                                    class="text-center">No records found</div>
                                <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                                    [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent($event)">
                                </mat-paginator>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>