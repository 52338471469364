<div
  class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-page__container m-body"
>
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-content">
      <div class="row">
        <div class="col-sm-12">
          <div class="m-portlet">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">My Activities</h3>
                </div>
              </div>
              <div class="m-portlet__head-tools">

                <ul class="m-portlet__nav">

                </ul>

                <ul class="m-portlet__nav">
                  <li class="m-portlet__nav-item">
                    <a class="btn btn-primary m-btn m-btn--air m-btn--custom" routerLink="/Calender-View">Calender View
                    </a>
                  </li>
                </ul>

                <ul class="m-portlet__nav">
                  <li class="m-portlet__nav-item">
                    <a class="btn btn-primary m-btn m-btn--air m-btn--custom" routerLink="/Add-Activity">
                    Add activity
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mat-elevation-z8">
              <div class="row">
                <div class="col-sm-12">
                  <div class="m-portlet">

                    <app-list-activity ></app-list-activity>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
