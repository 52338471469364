export class SingleFieldModel {
    

    customerSpecialDiscountInfo:CustomerSpecialDiscountDetails[]= Array<CustomerSpecialDiscountDetails>();
    tranisitionrulesobj:tranisitionruledetails[]=Array<tranisitionruledetails>();
    

    Id: number;
    Name: string = "";
    Value: string = "";
    
    RoleType: number;
    Status: boolean;
   
    RoleArr: any[] = [];

    //for Sales Price 
    DiscountFrom:number=0;
    DiscountTo:number=0;
    App_Level1:number=0;
    App_Level2:number=0;
    App_Level3:number=0;
    
    ///Subsegment
    SegmentId:number = null;
    SegmentName:string = '';
    SubSegmentName:string = '';
    SubSegmentId:number = null;

    ///TermsConditionsCustomized
    StandardTCId:number = null;
    StandardTCName:string = '';
    CustomTCValue:string = '';
    CustomTCId:number = null;

    ///Application
    Application: string = "";

    ///CustomerSpecialDiscount
    ModelName:string = "";
    CompanyName:string="";
    Fk_Customer:number=0;
    Fk_ProductModel:number=0;
    ProductDiscount:number=0;
    UnapprovedProductDiscount:number=0;
    
    IsRowDeleted: boolean;
    //singleFieldComponent: SingleFieldModel[]=[];
    ProductModelRequired:boolean=false;
    productDiscountrequired:boolean=false;

    IsCompetitorRequired : boolean;
     action_id:number;
    Comment : string= "";
    ReasonCategoryId : number;
 
}

export class CustomerSpecialDiscountDetails
{
    Id: number;
    Name: string = "";
    Value: string = "";
    Status: boolean;
   
    ModelName:string = "";
    CompanyName:string="";
    Fk_Customer:number=0;
    Fk_ProductModel:number=0;
    ProductDiscount:number=0;
    UnapprovedProductDiscount:number=0;
    IsRowDeleted: boolean;    
    ProductModelRequired:boolean=false;
    productDiscountrequired:boolean=false;
    action_id:number;
    Comment : string="";

}

export class SingleFieldComponent {
    Heading: string = "";
    RoutingParam: string = "";
    ConfirmationText: string = "";
    SuccessText: string = "";
    SuccessText2: string = "";
    ButtonText: string = "";
    ActionName: string = "";
    ControllerName: string = "";    
    isDoubleField: boolean = false;
    isExtraFields:boolean=false;
    isthirdfielddropdown:boolean = false;
    isvariablediscountfield:boolean = false;
    isSalespriceDiscountFields:boolean=false;
    issecondfielddropdown:boolean = false;
    firstFiledLabel: string = "";
    secondFiledLabel: string = "";
    thirdFiledLabel:string ="";
    firstFieldValidationMsg: string = "";
    SecondFieldValidationMsg: string = "";
    ThirdFieldValidationMsg: string = "";
    isDropdownrequird: boolean = false;
    Pattern:any;
    FirstFieldMaxLen:number = 0;
    SecondFieldMaxLen:number = 0;
    ThirdFieldMaxLen:number = 0;
    TableHeader1: string = "";
    TableHeader2: string = "";
    TableHeader3: string = "";
    FirstFiledPattern :string = "";
    FirstFiledPatternValidationMessage :string = "";

     /////////////////////////////
     isTripleField: boolean = false;
     thirdFieldLabel: string = "";
     thirdFieldValidationMsg: string = "";
     //ThirdFieldMaxLen:number = 0;
     
     SecondFieldPattern :string = "";
     SecondFieldPatternValidationMessage :string = "";
     ThirdFieldPattern :string = "";
     ThirdFieldPatternValidationMessage :string = "";
    Companyname:string="";
    isCompanynamerequired:boolean=false;
    ismultiplerecords:boolean=false;

    
}

export class tranisitionruledetails
{
    transition_rule_id:number;
    workflow_id:number;
    current_state_id:number;
    action_id:number;
    next_state_id:number;
    action_by_role_id:any;
    condition_code:string;
    pending_at_role_id:any;
    actionname:string;
    Comment : string="";
}