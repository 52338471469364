import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from 'src/app/app.global';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { LoginPageImage } from 'src/app/Model/LoginPageImage';
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-edit-view-login-page-dynamic-image',
  templateUrl: './edit-view-login-page-dynamic-image.component.html',
  styleUrls: ['./edit-view-login-page-dynamic-image.component.scss']
})
export class EditViewLoginPageDynamicImageComponent implements OnInit {
  LoginPageImageObj: LoginPageImage = new LoginPageImage();
  allowedFileType: string[] = [
    'image/bmp',
    'image/jpeg',
    'image/png',
  ];
  // Id: any;
  file: any;
  Comment: string = "";
  ImagePath: string = "";
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  isEditable: boolean = true;
  isCommentEditable: boolean = false;
  FormStatus: string = "View";

  UserRole:any;
  isLoginPageImageEditable:boolean;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];


  @Input()
  showLoader: boolean = false;
  data: any[] = [];
  @Output() sendImage: EventEmitter<ImageData>;
  Id:any;

  
  constructor(private Service:CommonService,
    private toastr:ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    public dialog: MatDialog,private accessservice: AccessPermissionService,
    private appConfigService : AppConfigService) {
   
  }
 
  ngOnInit(): void {
    this.Id= this.route.snapshot.params["id"];
    this.GetAllImageDetails();
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
    //  console.log(this.GetAllImageDetails)
    
  }
  
  GetAllImageDetails() {
    this.showLoader = true;
    let queryString = "";
    queryString="Id="+this.Id;
    this.Service.GetData('LoginPageImage', 'GetById', queryString).subscribe((data: any) => {
      this.LoginPageImageObj = data;
      this.ImagePath = AppGlobals.baseAppUrl + "uploads/LoginPageImage/" + encodeURIComponent(data.ImageName);
      this.remLimit = this.maxLimit - data.Comment.length;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });


  }
  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }

  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "You want to Update this Comment?"
      }

    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showLoader = true;
        // if (this.LeadObj.LeadDate != undefined) {
        //   this.LeadObj.LeadDate = new Date(this.convertDate(new NgbDate(this.LeadObj.LeadDate.year, this.LeadObj.LeadDate.month, this.LeadObj.LeadDate.day)));
        // }
        
         const frmData = new FormData();
        let ImageDatails = JSON.stringify(this.LoginPageImageObj);
        frmData.append("LoginModel", ImageDatails);
        if (this.LoginPageImageObj.Id != undefined) {
          frmData.append("LoginPageImage", this.Id);
        }

        this.Service.PostData('LoginPageImage', 'Update', frmData, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('Comment Update successfully!');
            this.Service.RefreshHeaderFun();
            this.router.navigate(['/LoginPageImage']);
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to Update Comment!');
            this.router.navigate(['/LoginPageImage']);
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to Update Comment!');
          this.showLoader = false;
          this.router.navigate(['/LoginPageImage']);
        });
      }


    });

  }
  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    const appConfig = this.appConfigService.getConfig();
    this.titleService.setTitle(`Edit Comment - ${appConfig.titleName}`);
    //this.titleService.setTitle("Edit Comment - ECOTECH");
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isLoginPageImageEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isLoginPageImage')[0]?.IsEditable;
     
    });
 
  }

}
