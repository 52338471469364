<loader [showLoader]="showLoader"></loader>

<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <div class="m-portlet__body" style="padding: 1px;margin-top: 15px;">

            <div class="m-content">

                <div class="m-portlet">
                    <div class="m-portlet__body">
                        <div style="padding: 20px;">
                            <div class="form-group m-form__group row">

                                <div class="col-sm-4">
                                    <label style="display: flex;" class="col-sm2" for="datefrom">
                                        <h6>Start date</h6>
                                    </label>
                                    <div>
                                        <input type="date" class="form-control" name="datefrom" id="datefrom"
                                            style="max-width: 250px;" [(ngModel)]="datefrom">
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <label style="display: flex;" class="col-sm2" for="dateto">
                                        <h6>Due date</h6>
                                    </label>
                                    <div>
                                        <input type="date" class="form-control" name="dateto" id="dateto"
                                            style="max-width: 250px;" [(ngModel)]="dateto">
                                    </div>
                                </div>

                                <div class="col-sm-4" style="display: flex; place-items: center;">
                                    <button
                                        type="submit"
                                        class="btn btn-primary m-btn m-btn--air m-btn--custom mr-auto js-btn-next"
                                        (click)="applyFilters()">
                                        Apply
                                    </button>
                                    <button
                                        type="submit"
                                        class=" btn btn-metal m-btn m-btn--air m-btn--custom mr-auto js-btn-next"
                                        (click)="removeFilters()">
                                        Clear
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <!-- row -->
                <div class="row">

                    <div class="col-sm-6">
                        <app-report-by-activity-type-status [date]="date"></app-report-by-activity-type-status>
                    </div>

                    <div class="col-sm-6">
                        <app-report-by-activity-type-month [date]="date"></app-report-by-activity-type-month>
                    </div>

                    <div class="col-sm-6">
                        <app-activity-status-by-month [date]="date"></app-activity-status-by-month>
                    </div>

                    <!-- <div class="col-sm-6">
                        <app-report-by-activity-status-type [date]="date"></app-report-by-activity-status-type>
                    </div> -->

                </div>
            </div>
        </div>
    </div>
</div>













































<!-- <div class="m-content" id="demoHTML">
    <div class="row">
        <div class="col-sm-6">
            <app-report-by-activity-type-status></app-report-by-activity-type-status>
        </div>
        <div class="col-sm-6">
            <app-report-by-activity-type-month></app-report-by-activity-type-month>
        </div>
    </div>
</div> -->