
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <div class="m-content">
               <!-- PowerBIGraph -->
               <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                               PowerBI Reports
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body">
                   
                    
                       
                        <iframe width="1280" height="720" 
                        src="https://app.powerbi.com/reportEmbed?reportId=5bcd74aa-b08c-41b7-8571-77020d9ffdc7&autoAuth=true&ctid=07a8129b-b481-4d1a-ad1f-fdcee6567ad6&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWVhc3QtYXNpYS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" 
                        frameborder="0" allowFullScreen="true"></iframe>
                    
                </div>
            </div>


        </div>
    </div>
</div>

<!-- <app-charts-modal></app-charts-modal> -->