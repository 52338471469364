import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ProductLine } from 'src/app/Model/ProductModel';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ReportFilterViewModel } from 'src/app/Model/ReportFilterModel';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppGlobals } from 'src/app/app.global';


@Component({
  selector: 'app-enquiry-grid-report',
  templateUrl: './enquiry-grid-report.component.html',
  styleUrls: ['./enquiry-grid-report.component.scss']
})
export class EnquiryGridReportComponent implements OnInit {
  displayedColumns: string[] = ['EnquiryAutoIncrId', 'EnquiryDate','QuotationNumber','QuotationAmount','POValue'
,'OrderWonDate','EnquiryStatus','OrderStatus'];
  dataSource: MatTableDataSource<ProductLine>;
  disabled: boolean = false;
  UserRole: any;
  @Input() CustomerDetails;
  @Input() reportFilterObj: ReportFilterViewModel = new ReportFilterViewModel();
  AppGlobals = AppGlobals;
  QuotationDownloadPath = this.AppGlobals.QuotationFileDownloadUrl;
  /*************************************************************************
        Server side Pagination purpose
    *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'asc';
  ColumnName: string = 'EnquiryAutoIncrId';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /*************************************************************************
     End here
  *************************************************************************/

  showLoader: boolean;
  data: any[] = [];
  constructor(private toastr: ToastrService, private CommonService: CommonService, 
  public dialog: MatDialog, private titleService: Title, private route: ActivatedRoute
  ,private activeModal: NgbActiveModal) {
  }


  ngOnInit(): void {
   this.GetAllEnquiryDetails();
  }

    /*************************************************************************
   * Below function used for Server side Pagination,Sorting and Searching
   * (1)GetAllEnquiryDetails() - To get data for grid
   * (2)SearchParam() -  searching
   * (3)sortData() - Sorting
   * (4)pageEvent() - Pagination
   * 
   * ***********************************************************************/

     GetAllEnquiryDetails() {
      if(this.reportFilterObj.currencyLable=="No's")
      {
        this.reportFilterObj.curency = 3
      }
      this.showLoader = true;
      let queryString = "";
      queryString = "pageNumber=" + this.pageNumber;
      queryString += "&pageSize=" + this.pageSize;
      queryString += "&searchText=" + this.SearchText;
      queryString += "&direction=" + this.direction;
      queryString += "&columnName=" + this.ColumnName;
      queryString += "&EnqType=" + this.reportFilterObj.EnqType;
      queryString += "&ReportValueType=" + this.reportFilterObj.ReportValueType;
      queryString += "&Year=" + this.reportFilterObj.Year;
      queryString += "&Region=" + this.reportFilterObj.Region;
      queryString += "&ProductLine=" + this.reportFilterObj.ProductLine;
      queryString += "&ProductSeries=" + this.reportFilterObj.ProductSeries;
      queryString += "&Productmodal=" + this.reportFilterObj.Productmodal;
      queryString += "&CustomerClass=" + this.reportFilterObj.CustomerClass;
      queryString += "&CustomerType=" + this.reportFilterObj.CustomerType;
      queryString += "&Segment=" + this.reportFilterObj.Segment;
      queryString += "&reporttype=" + this.reportFilterObj.reporttype;
      queryString += "&currency=" + this.reportFilterObj.curency;
      queryString += "&CustomerId="+this.CustomerDetails.CustomerId;
  
      this.CommonService.GetData('Report', 'GetAllEnquiryDetails', queryString).subscribe((data: any) => {
        this.data = data.Data;
        this.length = data.Total;
        this.dataSource = new MatTableDataSource(this.data);
        this.showLoader = false;
      }, error => {
        this.toastr.error("Failed to load data!");
        this.showLoader = false;
      });
  
    }
  
    SearchParam(event: any) {
      this.SearchText = event.target.value;
      this.pageNumber = 1;
      this.paginator.pageIndex = 0;
      this.GetAllEnquiryDetails();
    }
  
    sortData(event: any) {
      if (this.dataSource.filteredData != null) {
        this.direction = event.direction;
        this.ColumnName = event.active;
        this.GetAllEnquiryDetails();
      }
    }
  
    pageEvent(event: any) {
      this.pageNumber = event.pageIndex + 1;
      this.pageSize = event.pageSize;
      this.GetAllEnquiryDetails();
    }
    /************************************************************************
     * Grid Code End 
     * **********************************************************************/
  
    /*******************************************************************************
    * Form validation method
    * ******************************************************************************/
  
    AvoidSpace(event: any) {
      if (event.which === 32 && !event.target.value.length) {
        event.preventDefault();
      }
    }
  
    /*******************************************************************************
     * End Form validation method 
     * ******************************************************************************/
     closePopup() {
      this.activeModal.close('Close Click');
    }
}
