<loader [showLoader]="showLoader"></loader>
<div
    [ngClass]="{'m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-page__container m-body': !customerId}">

    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div [ngClass]="{'m-content': !customerId}">

            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head" [ngStyle]="{'display': customerId ? 'none' : ''}">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Planned vs Actual
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body p-0 flowvisible">
                            <mat-accordion>
                                <mat-expansion-panel hideToggle>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <label style="font-weight: bolder;">Filters</label>
                                            <span class="material-icons"
                                                style="position: absolute;right: 15px;">expand_more</span>
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <form #addActivityForm="ngForm" (ngSubmit)="onSubmit(filterActivityForm)"
                                        novalidate>

                                        <div class="form-group m-form__group row">
                                            <div class="col">
                                                <label class="form-control-label" class="lable-size">Activity
                                                    Type:</label>
                                                <select class="form-control" id="ActivityTypeId" name="ActivityTypeId"
                                                    [(ngModel)]="filterActivityForm.ActivityTypeId"
                                                    (change)="onActivityTypeChange(filterActivityForm.ActivityTypeId)"
                                                    required>
                                                    <option [ngValue]="null" selected>
                                                        Select
                                                    </option>
                                                    <option *ngFor="let item of activityTypeMaster"
                                                        [value]="item.ActivityTypeId">
                                                        {{ item.ActivityTypeDesc }}
                                                    </option>
                                                </select>
                                            </div>


                                            <div class="col">
                                                <label for="activity-status-filter" class="lable-size">Activity
                                                    Status:</label>
                                                <select class="form-control" id="ActivityStatusId"
                                                    name="ActivityStatusId"
                                                    [(ngModel)]="filterActivityForm.ActivityStatusId" required>
                                                    <option [ngValue]="null" selected>
                                                        Select
                                                    </option>
                                                    <option *ngFor="let item of activityStatusByTypeMaster "
                                                        value="{{ item.ActivityStatusId }}">
                                                        {{ item.ActivityStatusDesc }}
                                                    </option>
                                                </select>

                                            </div>


                                            <div class="col">
                                                <label for="priority-filter" class="lable-size">Subject:</label>
                                                <input class="form-control" name="Subject"
                                                    [(ngModel)]="filterActivityForm.Subject" placeholder="subject"
                                                    type="text" />
                                            </div>

                                            <div class="col-3">

                                                <div>
                                                    <div class="input-group datepickers notransform">
                                                        <input name="datepicker" class="form-control" ngbDatepicker
                                                            #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                            (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                                            [dayTemplate]="t" outsideDays="hidden"
                                                            [startDate]="FromDateNgb!" tabindex="-1" hidden
                                                            [maxDate]="maxDate" [footerTemplate]="footerTemplate">
                                                        <ng-template #t let-date let-focused="focused">
                                                            <span class="custom-day" [class.focused]="focused"
                                                                [class.range]="isRange(date)"
                                                                [class.faded]="isHovered(date) || isInside(date)"
                                                                (mouseenter)="hoveredDate = date"
                                                                (mouseleave)="hoveredDate = null">
                                                                {{ date.day }}
                                                            </span>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <label class="col">Select
                                                        Due Date:<span style="color:#f4516c;">&nbsp;*</span></label>
                                                    <div class="col">
                                                        <div class="input-group datepickers">
                                                            <input readonly #dpFromDate class="form-control"
                                                                placeholder="From" name="dpFromDate"
                                                                [value]="formatter.format(FromDateNgb)"
                                                                (input)="FromDateNgb = validateInput(FromDateNgb, dpFromDate.value)"
                                                                (click)="datepicker.toggle()">
                                                            <input readonly #dpToDate class="form-control"
                                                                placeholder="To" name="dpToDate"
                                                                [value]="formatter.format(ToDateNgb)"
                                                                (input)="ToDateNgb = validateInput(ToDateNgb, dpToDate.value)"
                                                                (click)="datepicker.toggle()">
                                                            <div class="input-group-append"
                                                                (click)="datepicker.toggle()"
                                                                *ngIf="FromDateNgb == null || ToDateNgb == null">
                                                                <span class="input-group-text">
                                                                    <i class="la la-calendar-check-o"></i>
                                                                </span>

                                                            </div>
                                                            <div class="input-group-append"
                                                                (click)="FromDateNgb=null;ToDateNgb=null;OnFromDateFilterChange()"
                                                                *ngIf="FromDateNgb != null && ToDateNgb != null">
                                                                <span class="input-group-text">
                                                                    <i class="la la-close"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <ng-template #footerTemplate>
                                                    <hr class="my-0">

                                                    <button class="btn btn-primary btn-sm m-2 float-right"
                                                        (click)="datepicker.toggle()">Ok</button>
                                                </ng-template>

                                            </div>
                                            <!-- <div Class="col">
                                        <label for="StartDate" class="lable-size">Due Date:</label>
                                        <input class="form-control" name="StartDate"
                                            [(ngModel)]="filterActivityForm.StartDate" type="date" />
                                    </div> -->

                                            <div class="col" [ngStyle]="{'display': customerId ? 'none' : ''}">
                                                <label class="lable-size">Related To:</label>
                                                <select class="form-control" name="EntityTypeId"
                                                    [(ngModel)]="filterActivityForm.EntityTypeId"
                                                    (change)="onEntityTypeChange(filterActivityForm.EntityTypeId)"
                                                    required>
                                                    <option [ngValue]="null" selected>
                                                        Select
                                                    </option>
                                                    <option *ngFor=" let s of allEntityTypesMaster " [value]="s.Id">
                                                        {{ s.EntityType }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="col" style="margin-top: 25px;" [ngStyle]="{'display': customerId ? 'none' : ''}">
                                                <select class="form-control" name="EntityId"
                                                    [(ngModel)]="filterActivityForm.EntityId" required>
                                                    <option [ngValue]="null" selected>Select</option>
                                                    <option *ngFor="let c of entityTypeFilterArray"
                                                        [value]="c.EntityId">
                                                        {{ c.EntityName }}
                                                    </option>
                                                </select>
                                            </div>


                                        </div>
                                        <div class="form-group m-form__group row">
                                            <div *ngIf="canViewCustomerEngagementReportOfOthers">
                                                <label class="col-lg-2 col-form-label">Participant:</label>
                                                <div class="col">
                                                    <select class="form-control" name="ActivityOwnerId"
                                                        [(ngModel)]="filterActivityForm.ActivityOwnerId" required>
                                                        <option [ngValue]="null" selected>
                                                            Select
                                                        </option>

                                                        <option *ngFor="let p of allUserMaster" [value]="p.Id">
                                                            {{ p.Name }}
                                                        </option>

                                                    </select>

                                                </div>
                                            </div>

                                            <div class="col-md-1 d-flex align-items-end">
                                                <br>
                                                <mat-checkbox id="isOwner" [(ngModel)]="filterActivityForm.IsOwner"
                                                    name="isOwner">
                                                    <label for="isOwner" class="lable-size">I am Owner</label>
                                                </mat-checkbox>
                                            </div>

                                            <div class="col-2 d-flex align-items-end">
                                                <button
                                                    class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next mr-2">Filter
                                                </button>
                                                <button type="button"
                                                    class="btn btn-metal m-btn m-btn--air m-btn--custom"
                                                    (click)="removeFilter()">Clear
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </mat-expansion-panel>
                            </mat-accordion>



                            <div class="multisteps-form p-2">
                                <div class="row ">

                                    <div class="col-lg-12 mb-4 table-responsive">
                                        <div class="tableconts">
                                            <div class="row bg-dark">
                                                <div class="col-md-2">
                                                    <h3 class="text-white pl-2">Day</h3>
                                                </div>
                                                <div class="col-md-5">
                                                    <h3 class="text-white">Planned</h3>
                                                </div>
                                                <div class="col-md-5">
                                                    <h3 class="text-white">Actual</h3>
                                                </div>
                                            </div>
                                            <div class="row" *ngFor="let activityObj of activities | keyvalue">
                                                <div class="col-md-2">
                                                    <div>
                                                        <h2>{{ getDay(activityObj.key) }}</h2>
                                                    </div>
                                                    <div>
                                                        <h2>{{ getDateDetails(activityObj.key) }}</h2>
                                                    </div>

                                                </div>

                                                <div class="col-md-5">
                                                    <div class="row" *ngIf="activities[activityObj.key]['Planned']">
                                                        <table
                                                            class="table order-list table-striped- table-bordered table-hover table-edit">
                                                            <tr>
                                                                <td> Customers</td>
                                                                <td> Type</td>
                                                                <td> Activity</td>
                                                                <td> Leads</td>
                                                            </tr>
                                                            <tr
                                                                *ngFor="let activity of activities[activityObj.key]['Planned']">
                                                                <td>
                                                                    <ul *ngIf="activity.Customers">
                                                                        <ng-container
                                                                            *ngIf="activity.Customers.indexOf('^') === -1; else CustomersLoop">
                                                                            <li>
                                                                                <a *ngIf="activity.Customers"
                                                                                    [routerLink]="['/CustomerEngagementDetails']"
                                                                                    [queryParams]="{'CustId': activity.Customers.split(':')[0]}">{{
                                                                                    " " +
                                                                                    activity.Customers.split(':')[1]
                                                                                    }}</a>
                                                                            </li>
                                                                        </ng-container>
                                                                        <ng-template #CustomersLoop>
                                                                            <li
                                                                                *ngFor="let Customers of activity.Customers.split('^')">

                                                                                <a *ngIf="Customers  && activities[activityObj.key]['Planned']"
                                                                                    [routerLink]="['/CustomerEngagementDetails']"
                                                                                    [queryParams]="{'CustId': Customers.split(':')[0]}">{{
                                                                                    " " + Customers.split(':')[1]
                                                                                    }}</a>
                                                                            </li>
                                                                        </ng-template>
                                                                    </ul>
                                                                    <ul *ngIf="activity.EnquiryCustomers">
                                                                        <ng-container
                                                                            *ngIf="activity.EnquiryCustomers.indexOf('^') === -1; else CustomersLoop">
                                                                            <li>
                                                                                <a *ngIf="activity.EnquiryCustomers"
                                                                                    [routerLink]="['/CustomerEngagementDetails']"
                                                                                    [queryParams]="{'CustId': activity.EnquiryCustomers.split(':')[0]}">{{
                                                                                    " " +
                                                                                    activity.EnquiryCustomers.split(':')[1]
                                                                                    }}</a>
                                                                            </li>
                                                                        </ng-container>
                                                                        <ng-template #CustomersLoop>
                                                                            <li
                                                                                *ngFor="let Customers of activity.EnquiryCustomers.split('^')">

                                                                                <a *ngIf="Customers  && activities[activityObj.key]['Planned']"
                                                                                    [routerLink]="['/CustomerEngagementDetails']"
                                                                                    [queryParams]="{'CustId': Customers.split(':')[0]}">{{
                                                                                    " " + Customers.split(':')[1]
                                                                                    }}</a>
                                                                            </li>
                                                                        </ng-template>
                                                                    </ul>
                                                                </td>
                                                                <td *ngIf="activity.ActivityTypeDesc == 'Meeting'">
                                                                    <i class="fa fa-users" aria-hidden="true"
                                                                        title="Meeting"></i>
                                                                </td>
                                                                <td *ngIf="activity.ActivityTypeDesc == 'Visit'">
                                                                    <i class="fas fa-map-marker-alt" aria-hidden="true"
                                                                        title="Visit"></i>
                                                                </td>
                                                                <td *ngIf="activity.ActivityTypeDesc == 'Call'">
                                                                    <i class="fa fa-phone"
                                                                        style="transform: rotate(90deg);"
                                                                        aria-hidden="true" title="Call"></i>
                                                                </td>
                                                                <td *ngIf="activity.ActivityTypeDesc == 'Task'">
                                                                    <i class="fa fa-tasks" aria-hidden="true"
                                                                        title="Activity"></i>
                                                                </td>
                                                                <td
                                                                    *ngIf="activity.ActivityTypeDesc != 'Meeting' && activity.ActivityTypeDesc != 'Visit' && activity.ActivityTypeDesc != 'Call' && activity.ActivityTypeDesc != 'Task'">
                                                                    <i class="fa fa-tasks" aria-hidden="true"
                                                                        title="Activity"></i>
                                                                </td>
                                                                <td>
                                                                    <a class="hrefclass" (click)="openViewPopup(activity.ActivityId)">{{activity.Subject}}</a>
                                                                    </td>
                                                                <td>
                                                                    <ul *ngIf="activity.Leads">
                                                                        <ng-container
                                                                            *ngIf="activity.Leads.indexOf('^') === -1; else leadsLoop">
                                                                            <li>
                                                                                <a *ngIf="activity.Leads"
                                                                                    [routerLink]="['/EditViewLead', activity.Leads.split(':')[0]]">{{
                                                                                    " " + activity.Leads.split(':')[1]
                                                                                    }}</a>
                                                                            </li>
                                                                        </ng-container>
                                                                        <ng-template #leadsLoop>
                                                                            <li
                                                                                *ngFor="let lead of activity.Leads.split('^')">

                                                                                <a *ngIf="lead && activities[activityObj.key]['Planned']"
                                                                                    [routerLink]="['/EditViewLead', lead.split(':')[0]]">{{
                                                                                    " " + lead.split(':')[1]
                                                                                    }}</a>
                                                                            </li>
                                                                        </ng-template>
                                                                    </ul>
                                                                </td>

                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div class="col-md-5">
                                                    <div class="row" *ngIf="activities[activityObj.key]['Actual']">
                                                        <table
                                                            class="table order-list table-striped- table-bordered table-hover table-edit">
                                                            <tr>
                                                                <td> Customers</td>
                                                                <td> Type</td>
                                                                <td> Activity</td>
                                                                <td> Leads</td>
                                                            </tr>
                                                            <tr
                                                                *ngFor="let activity of activities[activityObj.key]['Actual']">
                                                                <td>
                                                                    <ul *ngIf="activity.Customers">
                                                                        <ng-container
                                                                            *ngIf="activity.Customers.indexOf('^') === -1; else CustomersLoop">
                                                                            <li>
                                                                                <a *ngIf="activity.Customers"
                                                                                [routerLink]="['/CustomerEngagementDetails']"
                                                                                [queryParams]="{'CustId': activity.Customers.split(':')[0]}">{{
                                                                                    " " +
                                                                                    activity.Customers.split(':')[1]
                                                                                    }}</a>
                                                                            </li>
                                                                        </ng-container>
                                                                        <ng-template #CustomersLoop>
                                                                            <li
                                                                                *ngFor="let Customers of activity.Customers.split('^')">

                                                                                <a *ngIf="Customers  && activities[activityObj.key]['Actual']"
                                                                                    [routerLink]="['/EditViewCustomer', Customers.split(':')[0]]">{{
                                                                                    " " + Customers.split(':')[1]
                                                                                    }}</a>
                                                                            </li>
                                                                        </ng-template>
                                                                    </ul>
                                                                    <ul *ngIf="activity.EnquiryCustomers">
                                                                        <ng-container
                                                                            *ngIf="activity.EnquiryCustomers.indexOf('^') === -1; else CustomersLoop">
                                                                            <li>
                                                                                <a *ngIf="activity.EnquiryCustomers"
                                                                                [routerLink]="['/CustomerEngagementDetails']"
                                                                                [queryParams]="{'CustId': activity.EnquiryCustomers.split(':')[0]}">{{
                                                                                    " " +
                                                                                    activity.EnquiryCustomers.split(':')[1]
                                                                                    }}</a>
                                                                            </li>
                                                                        </ng-container>
                                                                        <ng-template #CustomersLoop>
                                                                            <li
                                                                                *ngFor="let Customers of activity.EnquiryCustomers.split('^')">

                                                                                <a *ngIf="Customers  && activities[activityObj.key]['Actual']"
                                                                                [routerLink]="['/CustomerEngagementDetails']"
                                                                                    [queryParams]="{'CustId': Customers.split(':')[0]}">{{
                                                                                    " " + Customers.split(':')[1]
                                                                                    }}</a>
                                                                            </li>
                                                                        </ng-template>
                                                                    </ul>
                                                                </td>
                                                                <td *ngIf="activity.ActivityTypeDesc == 'Meeting'">
                                                                    <i class="fa fa-users" aria-hidden="true"
                                                                        title="Meeting"></i>
                                                                </td>
                                                                <td *ngIf="activity.ActivityTypeDesc == 'Visit'">
                                                                    <i class="fas fa-map-marker-alt" aria-hidden="true"
                                                                        title="Visit"></i>
                                                                </td>
                                                                <td *ngIf="activity.ActivityTypeDesc == 'Call'">
                                                                    <i class="fa fa-phone"
                                                                        style="transform: rotate(90deg);"
                                                                        aria-hidden="true" title="Call"></i>
                                                                </td>
                                                                <td *ngIf="activity.ActivityTypeDesc == 'Task'">
                                                                    <i class="fa fa-tasks" aria-hidden="true"
                                                                        title="Activity"></i>
                                                                </td>
                                                                <td
                                                                    *ngIf="activity.ActivityTypeDesc != 'Meeting' && activity.ActivityTypeDesc != 'Visit' && activity.ActivityTypeDesc != 'Call' && activity.ActivityTypeDesc != 'Task'">
                                                                    <i class="fa fa-tasks" aria-hidden="true" title="Activity"></i>
                                                                </td>
                                                                <td> <a class="hrefclass" (click)="openViewPopup(activity.ActivityId)">{{activity.Subject}}</a></td>
                                                                <td>
                                                                    <ul *ngIf="activity.Leads">
                                                                        <ng-container
                                                                            *ngIf="activity.Leads.indexOf('^') === -1; else leadsLoop">
                                                                            <li>
                                                                                <a *ngIf="activity.Leads"
                                                                                    [routerLink]="['/EditViewLead', activity.Leads.split(':')[0]]">{{
                                                                                    " " + activity.Leads.split(':')[1]
                                                                                    }}</a>
                                                                            </li>
                                                                        </ng-container>
                                                                        <ng-template #leadsLoop>
                                                                            <li
                                                                                *ngFor="let lead of activity.Leads.split('^')">

                                                                                <a *ngIf="lead && activities[activityObj.key]['Actual']"
                                                                                    [routerLink]="['/EditViewLead', lead.split(':')[0]]">{{
                                                                                    " " + lead.split(':')[1]
                                                                                    }}</a>
                                                                            </li>
                                                                        </ng-template>
                                                                    </ul>
                                                                </td>

                                                            </tr>
                                                        </table>
                                                        <div *ngIf="activities?.length <= 0">
                                                            <h5 style="padding: 50px 20px;">No Records found</h5>
                                                          </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>