<loader [showLoader]="showLoader"></loader>

<div [ngClass]="{'custsize': inPopUpMode}">
<div [ngClass]="{'modal-content custContent': inPopUpMode}">


<div [ngClass]="{'m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-page__container m-body': !inPopUpMode}">

  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-content">
      <div class="row">
        <div class="col-sm-12">
          <div class="m-portlet" [ngStyle]="{'margin-bottom': inPopUpMode ? 0 : ''}">
            <div class="m-portlet__body">
              <div class="multisteps-form">
                <div class="row mt-3">
                  <div class="col-lg-12 mb-4 table-responsive">
                    <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right"
                      #addActivityForm="ngForm" novalidate>
                      <div class="multisteps-form__panel shadow rounded bg-white js-active" data-animation="fadeIn">
                        <div class="multisteps-form__content">
                          <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                            <div class="m-portlet__head">
                              <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                  <h3 class="m-portlet__head-text">
                                    View Activity
                                  </h3>
                                </div>
                              </div>
                            </div>

                            <div class="m-portlet__body">
                              <div id="dvTestData">
                                <div class="form-group m-form__group row">
                                  <label class="col-lg-2 col-form-label">Activity
                                    Type:</label>
                                  <div class="col-lg-3 col-form-label">
                                    {{ frmData?.ActivityType }}
                                  </div>

                     <!-- ========Future Use======= -->
                                  <!-- <div class="offset-1"></div>
                                  <label class="col-lg-2 col-form-label"
                                    [ngStyle]="frmData?.ActivityType =='Meeting' ? {} :{'display':'none'}">
                                    Subtype
                                  </label>
                                  <div class="col-lg-3 col-form-label">{{ frmData?.ActivitySubType }}</div> -->

                                </div>


                                <div class="form-group m-form__group row">
                                  <label class="col-lg-2 col-form-label">Owner:</label>
                                  <div class="col-lg-3 col-form-label">
                                    {{ frmData?.OwnerName }}
                                  </div>
                                  <div class="offset-1"></div>
                                  <label class="col-lg-2 col-form-label">Subject:</label>
                                  <div class="col-lg-3 col-form-label">
                                    {{ frmData?.Subject }}
                                  </div>
                                  <div class="offset-1"></div>
                                </div>

                                <div class="form-group m-form__group row">
                                  <label class="col-lg-2 col-form-label">Status:</label>
                                  <div class="col-lg-3 col-form-label">
                                    {{ frmData?.ActivityStatus }}
                                    <div class="invalid-feedback">
                                      <div>Status is required</div>
                                    </div>
                                  </div>
                                  <div class="offset-1"></div>
                                  <label class="col-lg-2 col-form-label">Due
                                    Date:</label>
                                  <div class="col-lg-3 col-form-label">
                                    <div class="input-group date">
                                      {{ frmData?.PlannedDueDatetime | date:'dd-MM-yyyy'}}
                                    </div>
                                  </div>
                                </div>


                                <div class="form-group m-form__group row">
                                  <label class="col-lg-2 col-form-label">Actual
                                    Start Date:</label>
                                  <div class="col-lg-3 col-form-label">
                                    <div class="input-group date">
                                      {{ frmData?.ActualStartDatetime | date:'dd-MM-yyyy'}}
                                    </div>
                                  </div>
                                  <div class="offset-1"></div>
                                  <label class="col-lg-2 col-form-label">Actual
                                    Complete Date:</label>
                                  <div class="col-lg-3 col-form-label">
                                    <div class="input-group date">
                                      {{ frmData?.ActualCompletionDatetime | date:'dd-MM-yyyy' }}
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group m-form__group row">
                                  <label class="col-lg-2 col-form-label">Purpose:</label>
                                  <div class="col-lg-3 col-form-label">
                                    {{ frmData?.ActivityPurpose }}
                                    <div class="invalid-feedback">
                                      <div>purpose is required</div>
                                    </div>
                                  </div>
                                  <div class="offset-1"></div>
                                  <label class="col-lg-2 col-form-label">Purpose
                                    Description:</label>
                                  <div class="col-lg-3 col-form-label">
                                    {{ frmData?.PurposeDesc }}
                                  </div>
                                </div>


                                <div class="form-group m-form__group row"
                                  [ngStyle]="frmData?.ActivityStatus == 'Completed' ? { }: {'display':'none' }">
                                  <label class="col-lg-2 col-form-label">Outcome:</label>
                                  <div class="col-lg-3 col-form-label">
                                    {{ frmData?.ActivityOutcome }}
                                    <div class="invalid-feedback">
                                      <div>Priority is required</div>
                                    </div>
                                  </div>
                                  <div class="offset-1"></div>
                                  <label class="col-lg-2 col-form-label">Outcome
                                    Description:</label>
                                  <div class="col-lg-3 col-form-label">
                                    {{ frmData?.OutcomeDesc }}
                                  </div>
                                </div>





                                <div class="form-group m-form__group row">
                                  <label class="col-lg-2 col-form-label">
                                    Description:</label>
                                  <div class="col-lg-9 col-form-label">
                                    {{ frmData?.Comment }}
                                  </div>
                                </div>

                                <div class="form-group m-form__group row">


                                  <label class="col-lg-2 col-form-label">Participant:</label>
                                  <div class="col-lg-3 col-form-label">
                                    <div *ngFor="let participant of frmData?.ListActivityParticipants;let i = index">
                                      {{ participant.ParticipantName }}
                                    </div>
                                  </div>

                                  <div class="offset-1"></div>

                                  <label class="col-lg-2 col-form-label">Related To:</label>

                                  <div class=" col-lg-4 col-form-label">
                                    <ul style="padding-left: 0px"
                                      *ngFor="let activityLinked of frmData?.ListActivityLinkage;let i = index">
                                      <li> <b>{{ activityLinked.EntityTypeName }}</b> <b>:</b>
                                        &nbsp;
                                        {{ activityLinked.EntityName }}
                                      </li>
                                    </ul>
                                  </div>



                                </div>
                                <div class="button-row text-left mt-4" style="margin: 15px"></div>
                                <div [ngStyle]="{'display': showEditAndCancelBtn ? '' : 'none'}"
                                  class="button-row text-left mt-4" style="margin: 15px">
                                  <button type="button" *ngIf="frmData?.ActivityStatus !=='Completed' && canEditCustomerEngagementReportOfOthers "
                                    class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next"
                                    (click)="goToEdit(frmData?.ActivityId)">
                                    Edit
                                  </button>
                                  &nbsp;&nbsp;
                                  <button *ngIf="!inPopUpMode" class="btn btn-metal m-btn m-btn--air m-btn--custom" (click)="goBack()">
                                    Cancel
                                  </button>
                                  <button *ngIf="inPopUpMode" class="btn btn-metal m-btn m-btn--air m-btn--custom" (click)="closePopup()">
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
</div>
