<loader [showLoader]="showLoader"></loader>
<div class="custsize">
    <!-- <div class="modal fade" id="ActivityModal" tabindex="-1" role="dialog"> -->

    <div class="modal-content custContent">

        <div class="modal-body">
            <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="activityForm"
                (ngSubmit)="activityForm.form.valid" #activityForm="ngForm" novalidate>
                <div class="multisteps-form__panel shadow p-4 rounded bg-white js-active" data-animation="fadeIn">
                    <!-- <h3 class="multisteps-form__title">Enquiry </h3> -->
                    <div class="multisteps-form__content">
                        <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                            <div class="m-portlet__head">
                                <div class="m-portlet__head-caption">
                                    <div class="m-portlet__head-title">
                                        <h3 class="m-portlet__head-text">
                                            Change Background Image
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="m-portlet__body">
                                <form class="m-form m-form--fit m-form--label-align-right">
                                    <div class="form-group m-form__group row">

                                        <label class="col-lg-5  col-form-label">Upload Image:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-7 pt-1">
                                        <div class="file btn btn-sm btn-warning">
                                            Upload
                                            <input type="file" class="form-control-file" name="bgImage"
                                                (change)="getFileDetails($event)" id="bgImage"
                                                [(ngModel)]="tempfile" #bgImage="ngModel" required multiple #myInput
                                                [ngClass]="{'is-invalid':bgImage.invalid || bgImage?.touched && bgImage.invalid}">

                                        </div>
                                        <div *ngIf="((bgImage.invalid) || (bgImage?.touched && bgImage.invalid))"
                                            class="invalid-feedback" style="display: block;">
                                            Image is required
                                        </div>
                                        <!-- <div *ngIf="myform.hasError('fileempty', 'bgImage')">
                                                Stock file is required
                                            </div> -->
                                        <br>

                                        <span class="fileext">
                                            [File Format: .jpeg, .jpg, .png] [Max 5 MB]</span>
                                        <span *ngIf="BackgroundImage != undefined && BackgroundImage.length > 0">
                                            <ul>
                                                
                                                    <li> {{BackgroundImage != undefined ?
                                                        BackgroundImage[0].name : ""}}&nbsp;&nbsp;<i class="flaticon-delete"
                                                            style="color:#f4516c" (click)="removeAttachments()"
                                                            title="Remove"></i>
                                                    </li>
                                            </ul>

                                        </span>
                                    </div>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="button-row text-right mt-4" style="margin:15px;">
                        <button [disabled]="!(BackgroundImage != undefined && BackgroundImage.length > 0)" type="button" class="btn btn-primary btnspc" (click)="OnSubmit()"
                            data-dismiss="modal">Submit</button>&nbsp;&nbsp;
                        <button type="button" class="btn btn-metal" (click)="closePopup()"
                            data-dismiss="modal">Close</button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
<!-- </div> -->