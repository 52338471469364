

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Activity, ActivityLinkageEntityType, ActivityOutcome, ActivityParticipants, ActivityPurpose, ActivityStatusByType, ActivityType, EntityRecord, User } from '../Model/ActivityModel';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  private readonly apiUrl = 'https://my-api.com/activities';


  // private mockActivities: Activity[] = [
  //   {
  //     ActivityId: 1,
  //     Subject: 'Meeting with client',
  //     ActivityOwner: 'John Doe',
  //     ActivityOwnerRoleId: 1,
  //     Priority: 'High',
  //     Address: '123 Main St.',
  //     ActivityTypeId: 1,
  //     ActivitySubType: 'Sales Meeting',
  //     ActivityPurposeId: 1,
  //     PurposeDesc: 'Discuss new product line',
  //     ActivityOutcomeId: 1,
  //     OutcomeDesc: 'Agreed to move forward with purchase',
  //     ActivityStatusId: 1,
  //     Comment: 'Follow up next week',
  //     PlannedStartDatetime: new Date(),
  //     PlannedDueDatetime: new Date(),
  //     ActualStartDatetime: new Date(),
  //     ActualCompletionDatetime: new Date(),
  //     CreatedOn: new Date(),
  //     CreatedBy: 'Jane Smith',
  //     ModifiedOn: new Date(),
  //     ModifiedBy: 'Jane Smith',
  //     InEditMode: false,

  //   },
  //   {
  //     ActivityId: 2,
  //     Subject: 'Phone call with prospect',
  //     ActivityOwner: 'John Doe',
  //     ActivityOwnerRoleId: 1,
  //     Priority: 'Low',
  //     ActivityTypeId: 2,
  //     ActivityPurposeId: 2,
  //     ActivityOutcomeId: 2,
  //     ActivityStatusId: 2,
  //     PlannedStartDatetime: new Date(),
  //     PlannedDueDatetime: new Date(),
  //     CreatedOn: new Date(),
  //     CreatedBy: 'Jane Smith',
  //     ModifiedOn: new Date(),
  //     ModifiedBy: 'Jane Smith',
  //     InEditMode: false
  //   },
  //   // {
  //   //   ActivityId: 2,
  //   //   Subject: 'Phone call with prospect',
  //   //   ActivityOwner: 'John Doe',
  //   //   ActivityOwnerRoleId: 1,
  //   //   Priority: 'Medium',
  //   //   ActivityTypeId: 2,
  //   //   ActivityPurposeId: 2,
  //   //   ActivityOutcomeId: 2,
  //   //   ActivityStatusId: 2,
  //   //   PlannedStartDatetime: new Date(),
  //   //   PlannedDueDatetime: new Date(),
  //   //   CreatedOn: new Date(),
  //   //   CreatedBy: 'Jane Smith',
  //   //   ModifiedOn: new Date(),
  //   //   ModifiedBy: 'Jane Smith',
  //   //   InEditMode:false
  //   // }, {
  //   //   ActivityId: 2,
  //   //   Subject: 'Phone call with prospect',
  //   //   ActivityOwner: 'John Doe',
  //   //   ActivityOwnerRoleId: 1,
  //   //   Priority: 'Low',
  //   //   ActivityTypeId: 2,
  //   //   ActivityPurposeId: 2,
  //   //   ActivityOutcomeId: 2,
  //   //   ActivityStatusId: 2,
  //   //   PlannedStartDatetime: new Date(),
  //   //   PlannedDueDatetime: new Date(),
  //   //   CreatedOn: new Date(),
  //   //   CreatedBy: 'Jane Smith',
  //   //   ModifiedOn: new Date(),
  //   //   ModifiedBy: 'Jane Smith',
  //   //   InEditMode:false
  //   // }, {
  //   //   ActivityId: 3,
  //   //   Subject: 'Phone call with prospect',
  //   //   ActivityOwner: 'John Doe',
  //   //   ActivityOwnerRoleId: 1,
  //   //   Priority: 'Low',
  //   //   ActivityTypeId: 2,
  //   //   ActivityPurposeId: 2,
  //   //   ActivityOutcomeId: 2,
  //   //   ActivityStatusId: 2,
  //   //   PlannedStartDatetime: new Date(),
  //   //   PlannedDueDatetime: new Date(),
  //   //   CreatedOn: new Date(),
  //   //   CreatedBy: 'Jane Smith',
  //   //   ModifiedOn: new Date(),
  //   //   ModifiedBy: 'Jane Smith',
  //   //   InEditMode:false
  //   // }, {
  //   //   ActivityId: 3,
  //   //   Subject: 'Phone call with prospect',
  //   //   ActivityOwner: 'John Doe',
  //   //   ActivityOwnerRoleId: 1,
  //   //   Priority: 'High',
  //   //   ActivityTypeId: 2,
  //   //   ActivityPurposeId: 2,
  //   //   ActivityOutcomeId: 2,
  //   //   ActivityStatusId: 2,
  //   //   PlannedStartDatetime: new Date(),
  //   //   PlannedDueDatetime: new Date(),
  //   //   CreatedOn: new Date(),
  //   //   CreatedBy: 'Jane Smith',
  //   //   ModifiedOn: new Date(),
  //   //   ModifiedBy: 'Jane Smith',
  //   //   InEditMode:false
  //   // }
  // ];

  constructor(private http: HttpClient, private commonService: CommonService) { }


  getAllActivities(): Observable<any> {
    return this.commonService.GetData("Activity", "Getall", null);
  }


  // getActivity(activityId: number): Observable<Activity> {
  //   return of({ ...this.mockActivities[0] });
  // }


  addOrUpdateActivity(activity: Activity): Observable<any> {
    if (activity.ActivityId) {
      return this.commonService.PostDataForCustomerEnagement("Activity", "Update", activity, null);
    } else {

      if (isNaN(activity.ActivityId) || activity.ActivityId < 1) {
        return this.commonService.PostDataForCustomerEnagement("Activity", "Add", activity, null);
      }
      return of(activity);

    }
    //console.log(activity)

    // const index = this.mockActivities.findIndex((a) => a.ActivityId === activity.ActivityId);
    // if (index !== -1) {
    //   // return this.commonService.PostData("Activity", "Update", activity,null,null);
    //   // return this.updateActivity(activity);
    // } else {
    //   return this.addActivity(activity);
    // }

    // return this.commonService.PostData("Activity", "Update", activity,null);

  }


  editActivity(activity: Activity): Observable<Activity> {
    return of(activity);
  }


  deleteActivity(activity: Activity): Observable<Activity> {
    return of(activity);
  }




  addActivity(activity) {
    if (isNaN(activity.ActivityId) || activity.ActivityId < 1) {
      return this.commonService.PostDataForCustomerEnagement("Activity", "Add", activity, null);
    }
    return of(activity);
  }


  // updateActivity(activity: Activity) {

  //   const index = this.mockActivities.findIndex((a) => a.ActivityId === activity.ActivityId);

  //   if (activity.ActivityId > 0) {
  //     this.mockActivities[index] = activity;
  //   }
  //   return of(activity);
  // }



  // ----Dropdowns ActivityType, Status, Outcome,  Purpose-----


  private mockActivityTypeMaster: ActivityType[] = [
  ]

  private mockActivityStatusByTypeMaster: ActivityStatusByType[] = [
    {
      StatusCode: 'Pending',
      ActivityStatusId: 1,
      ActivityStatusDesc: 'Pending',
      ActivityStatusCode: 'Pending',
      ActivityTypeId: 1,
      IsActive: true,
    }, {
      StatusCode: 'Inprocess',
      ActivityStatusId: 2,
      ActivityStatusDesc: 'Inprocess',
      ActivityStatusCode: 'Inprocess',
      ActivityTypeId: 2,
      IsActive: false,
    }, {
      StatusCode: 'Done',
      ActivityStatusId: 3,
      ActivityStatusDesc: 'Done',
      ActivityStatusCode: 'Done',
      ActivityTypeId: 3,
      IsActive: false,
    },


  ]

  private mockActivityOutcomeMaster: ActivityOutcome[] = [

    {
      ActivityOutcomeId: 1,
      ActivityOutcomeCode: "AAA",
      ActivityOutcomeDesc: "Final",
      IsActive: false,
      CreatedOn: new Date(),
      CreatedBy: "assdf",
      ModifiedOn: new Date(),
      ModifiedBy: 'fefe'
    }, {
      ActivityOutcomeId: 2,
      ActivityOutcomeCode: "BBB",
      ActivityOutcomeDesc: "Final",
      IsActive: false,
      CreatedOn: new Date(),
      CreatedBy: "assdf",
      ModifiedOn: new Date(),
      ModifiedBy: 'fefe'
    }, {
      ActivityOutcomeId: 3,
      ActivityOutcomeCode: "CCC",
      ActivityOutcomeDesc: "Final",
      IsActive: false,
      CreatedOn: new Date(),
      CreatedBy: "assdf",
      ModifiedOn: new Date(),
      ModifiedBy: 'fefe'
    }
  ]


  private mockActivityPurposeMaster: ActivityPurpose[] = [
    {
      ActivityPurposeId: 1,
      ActivityPurposeCode: 'wee',
      ActivityPurposeDesc: 'add',
      IsActive: true,
    },
    {
      ActivityPurposeId: 2,
      ActivityPurposeCode: 'wee',
      ActivityPurposeDesc: 'vrrtrt',
      IsActive: true,
    },
    {
      ActivityPurposeId: 3,
      ActivityPurposeCode: 'wee',
      ActivityPurposeDesc: 'vgtfd',
      IsActive: true,
    }
  ]



  getActivityById(activityId): Observable<any> {
    let queryString = "id=" + activityId;

    return this.commonService.GetData("Activity", "Getbyid", queryString);
  }



  getActivityTypeMaster(): Observable<any> {
    return this.commonService.GetData("ActivityType", "GetAll", null);
  }

  getActivityStatusMaster(activityTypeId) {
    let queryString;
    if (activityTypeId == null) {
      queryString = null;
    }
    else {
      queryString = "activityTypeId=" + activityTypeId;
    }
    return this.commonService.GetData("ActivityStatus", queryString == null ? "GetAll" : "GetAllByType", queryString);
    // return of([...this.mockActivityStatusByTypeMaster]);
  }


  getActivityOutcomeMaster(activityTypeId) {
    let queryString = "activityTypeId=" + activityTypeId;
    return this.commonService.GetData("ActivityOutcome", "GetAllByType", queryString);
    //return of ([...this.mockActivityOutcomeMaster])
  }


  getActivityPurposeMaster(activityTypeId) {
    let queryString = "activityTypeId=" + activityTypeId;
    return this.commonService.GetData("ActivityPurpose", "GetAllByType", queryString);
    // return of ([...this.mockActivityPurposeMaster]);
  }

  getallEntityTypesMaster() {
    // return of ([...this.allEntityTypesMaster])
    return this.commonService.GetData("ActivityLinkEntityType", "GetAll", null);

  }

  getrelatedEntityRecords(entityTypeId,entityIds) {

    if(!entityIds || !Array.isArray(entityIds)){
      entityIds=[];//initialize with blank in case its null or not an array
    }

    let queryString = "EntityTypeId=" + entityTypeId + "&EntityIds="+encodeURIComponent(entityIds.join(","));

   // console.log(queryString);

    return this.commonService.GetData("Activity", "EntityData", queryString);
  }


  getallUserMaster() {
    return this.commonService.GetData("Account", "GetAllUsers", null);
  }
  getActivityByFilter(reqBody: any) {
    return this.commonService.PostDataForCustomerEnagement("Activity", "GetDataPagination", reqBody, null);
  }

}


