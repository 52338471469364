import { Component, OnInit, Input } from '@angular/core';
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductSeries } from 'src/app/Model/ProductModel';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-edit-view-product-series',
  templateUrl: './edit-view-product-series.component.html',
  styleUrls: ['./edit-view-product-series.component.scss']
})
export class EditViewProductSeriesComponent implements OnInit {
  AppGlobals = AppGlobals;
  catlogDownloadPath = this.AppGlobals.catlogDownloadPath;
  isEditable: boolean = true;
  FormStatus: string = "View";
  ProductSeriesobj: ProductSeries = new ProductSeries();
  charCode: any;
  ProductFamily: any[] = [];
  ProductLine: any[] = [];
  UserRole: any;
  isProductSeriesEditable: boolean = false;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];


  @Input()
  showLoader: boolean = false;
  file: any;
  allowedFileType: string[] = [   
    'application/pdf'    
  ];
  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title,private accessservice: AccessPermissionService,
    private appConfigService : AppConfigService) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    // this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
    //this.isProductSeriesEditable = AppGlobals.ProductMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.ProductSeriesobj.Id = this.route.snapshot.params["id"];
    this.getProductSeriesIdDetailsById(this.ProductSeriesobj.Id);
    this.getAllProductLine();
  }
  /*******************************************************************************
     * Get data for add product series
     * ******************************************************************************/
  getProductSeriesIdDetailsById(ProductSeriesId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "id=" + ProductSeriesId;

    this.CommonService.GetData('ProductSeries', 'GetById', queryString).subscribe((data: any) => {
      this.ProductSeriesobj = data;
      console.log(data);
      
      this.getAllProductFamily(this.ProductSeriesobj.ProductLine);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });

  }

  getAllProductLine() {
    this.showLoader = true;
    this.CommonService.GetData("ProductLine", "GetAll", null).subscribe((data: any) => {
      this.ProductLine = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getAllProductFamily(ProductLine: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "ProductLine=" + ProductLine;
    this.CommonService.GetData("ProductFamily", "GetbyProductLine", queryString).subscribe((data: any) => {
      this.ProductFamily = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  /*******************************************************************************
   * End get data for add product series
   * ******************************************************************************/

  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    const appConfig = this.appConfigService.getConfig();
    this.titleService.setTitle(`Edit Product Series - ${appConfig.titleName}`);
    // this.titleService.setTitle("Edit Product Series - ECOTECH");
  }

  onProductLineSelect(e: any) {
    this.ProductSeriesobj.ProductFamily = null;
    this.getAllProductFamily(e.target.value);
  }

  OnVariantChange() {
    if (this.ProductSeriesobj.Label_Variant1 == '' || this.ProductSeriesobj.Label_Variant2 == '') {
      this.ProductSeriesobj.IsLevel2DepOnLevel1 = false;
    }
    if (this.ProductSeriesobj.Label_Variant2 == '' || this.ProductSeriesobj.Label_Variant3 == '') {
      this.ProductSeriesobj.IsLevel3DepOnLevel2 = false;
    }
    if (this.ProductSeriesobj.Label_Variant3 == '' || this.ProductSeriesobj.Label_Variant4 == '') {
      this.ProductSeriesobj.IsLevel4DepOnLevel3 = false;
    }
  }

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onSubmit(myform: any) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update this product series?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.showLoader = true;

        const frmData = new FormData();
        let ProductSeriesDetails = JSON.stringify(this.ProductSeriesobj);
        frmData.append("ProductSeries", ProductSeriesDetails);
        if (this.ProductSeriesobj.CatlogName != undefined) {
          frmData.append("CatalogFile", this.file);
        }

        this.CommonService.PostData('ProductSeries', 'Update', frmData, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('Product series updated successfully!');
            this.CommonService.RefreshHeaderFun();
            this.router.navigate(['/ProductSeries']);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('Product series already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to update product series!');
            this.router.navigate(['/ProductSeries']);
            this.showLoader = false;
          }


        }, error => {
          this.toastr.error('Failed to update product series!');
          this.showLoader = false;
          this.router.navigate(['/ProductSeries']);
        });
      }
    });
  }
  editFileDetails(event, index) {
    let file = event.target.files[0];
    let isValidFile = true;
    if (((file.size) / 1000000) <= 20) {
      isValidFile = this.allowedFileType.includes(file.type);
      if (!isValidFile) {
        this.toastr.warning("Please select valid file. Valid filetype is .pdf file");
      }
      else
      {
        this.ProductSeriesobj.CatlogName = file.name;
        this.file=file;        
      }    
    }
    else{
      this.toastr.info("Please select file having size upto 20MB");
    }
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isProductSeriesEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isProductLine')[0]?.IsEditable;
      
    });
 
  }
}
