
 
    <div class="col-lg-12 mb-4 table-responsive" *ngIf="!isprocessing">
        <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="myform2"
            #myform2="ngForm" appFocusInvalidInput novalidate>
            <div class="multisteps-form__panel shadow rounded bg-white js-active" data-animation="fadeIn">
                <div class="multisteps-form__content">
                    <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        RFQ (Request For Quote)
                                    </h3>
                                </div>
                            </div>
                            <div class="m-portlet__head-tools">
                                <button *ngIf="this.UserRole == roleOfAdmin"
                                    class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                    (click)="SendBack()">Send Back</button> &nbsp;&nbsp;
                            </div>
                        </div>

                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <div class="m-portlet__body">

                            <div class="table-responsive">
                                <table
                                    class="table order-lists table-edit table-responsive table-bordered table-cust bord "
                                    id="rfqtable">
                                    <thead class="quoteheader">
                                        <tr>
                                            <th rowspan="2" class="text-center">
                                                Product Line <span style="color:#f4516c;">&nbsp;*</span>
                                            </th>
                                            <th rowspan="2" class="text-center">
                                                Product Model <span style="color:#f4516c;">&nbsp;*</span>
                                            </th>
                                            <th rowspan="2" class="text-center">
                                                Quantity <span style="color:#f4516c;">&nbsp;*</span>
                                            </th>
                                            <th rowspan="2" class="text-center">
                                                MDS Option <span style="color:#f4516c;">&nbsp;*</span>
                                            </th>
                                            <th colspan="4" class="text-center">
                                                Attachment
                                            </th>
                                            <th rowspan="2" class="text-center">
                                                Sales Price <span style="color:#f4516c;">&nbsp;*</span>
                                            </th>
                                            <th rowspan="2" class="text-center">
                                                Sales Discount <span style="color:#f4516c;">&nbsp;*</span>
                                            </th>

                                            <th rowspan="2" class="text-center" *ngIf="isRFQEditable">
                                                Action
                                            </th>

                                        </tr>
                                        <tr>
                                            <th class="text-center">
                                                MDS
                                            </th>
                                            <th class="text-center">
                                                ITC
                                            </th>
                                            <th class="text-center">
                                                Customer
                                            </th>
                                            <th class="text-center">
                                                <!-- Ecotech -->
                                                {{documentOfWhichComapany}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let field of EnquiryObj.RFQObject; let i = index;">
                                            <tr>
                                                <td
                                                    style="vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                                    <select class="form-control p-0" id="productline{{i}}"
                                                        name="productline{{i}}" [(ngModel)]="field.ProductLine"
                                                        style="border: none;" [disabled]="!isRFQEditable"
                                                        (change)="getProductModel(field.ProductLine,i)">
                                                        <option value="undefined" selected>
                                                            Select
                                                        </option>
                                                        <option *ngFor="let item of product.Product"
                                                            value="{{item.ProductLine.Id}}">
                                                            {{item.ProductLine.Name}}
                                                        </option>
                                                    </select>
                                                    <div *ngIf="(field.ProductLineValidation)">
                                                        <span class="validationmsg">
                                                            Product Line is
                                                            required</span>
                                                    </div>
                                                </td>
                                                <td
                                                    style="vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                                    <select *ngIf="field.ProductModelList.length!=0"
                                                        class="form-control p-0" id="productmodel{{i}}"
                                                        name="productmodel{{i}}" [disabled]="!isRFQEditable"
                                                        (change)="OnProductModelChange(i)"
                                                        [(ngModel)]="field.ProductModel" style="border: none;">
                                                        <option value="undefined" selected disabled>
                                                            Select
                                                        </option>
                                                        <option *ngFor="let item of field.ProductModelList"
                                                            value="{{item.Id}}">
                                                            {{item.Name}}
                                                        </option>

                                                    </select>
                                                    <select *ngIf="field.ProductModelList.length==0"
                                                        class="form-control p-0" id="productmodel{{i}}"
                                                        name="productmodel{{i}}" [disabled]="!isRFQEditable"
                                                        (change)="OnProductModelChange(i)"
                                                        [(ngModel)]="field.ProductModel" style="border: none;">
                                                        <option value="undefined" selected disabled>
                                                            Select
                                                        </option>

                                                        <option
                                                            *ngFor="let item of productDrpdnDetails.ProductModel"
                                                            value="{{item.Id}}">
                                                            {{item.Name}}
                                                        </option>
                                                    </select>
                                                    <div *ngIf="(field.ProductModelValidation)">
                                                        <span class="validationmsg">
                                                            Product Model is
                                                            required</span>
                                                    </div>
                                                </td>
                                                <td
                                                    style="width:75px; vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                                    <input type="number" class="form-control m-input"
                                                        id="quantity{{i}}" name="quantity{{i}}"
                                                        (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                        [disabled]="!isRFQEditable" (change)="OnQuantityChange(i)"
                                                        [(ngModel)]="field.Quantity"
                                                        style="border:none;text-align:right;">
                                                    <div *ngIf="(field.QuantityValidation)">
                                                        <span class="validationmsg">
                                                            Quantity is
                                                            required</span>
                                                    </div>
                                                </td>
                                                <td
                                                    style="vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                                    <select class="form-control p-0" id="mdsoption{{i}}"
                                                        name="mdsoption{{i}}" [disabled]="!isRFQEditable"
                                                        (change)="OnMDSOptionChange(i)"
                                                        [(ngModel)]="field.MDSOption" style="border: none;">
                                                        <option value="undefined" selected disabled>
                                                            Select
                                                        </option>
                                                        <option *ngFor="let item of productDrpdnDetails.MDSOptions"
                                                            value="{{item.Id}}">
                                                            {{item.Name}}
                                                        </option>
                                                    </select>
                                                    <div *ngIf="(field.MDSOptionValidation)">
                                                        <span class="validationmsg">
                                                            MDS Option is
                                                            required</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="file"
                                                        *ngIf="isRFQEditable && (field.MDS_filename==null && !field.isMDSAttachment)">
                                                        <i class="flaticon-file-2"></i>
                                                        <input type="file" class="form-control-file"
                                                            id="mdsfile{{i}}" *ngIf="isRFQEditable" name="mdsfile"
                                                            (change)="editFileDetails($event,i)" />
                                                    </div>

                                                    <div *ngIf="field.isMDSAttachment">
                                                        {{(field.MDSAttachment
                                                        != undefined &&
                                                        field.MDSAttachment[0]
                                                        != undefined) ?
                                                        field.MDSAttachment[0].name:
                                                        ""}}
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            *ngIf="isRFQEditable"
                                                            (click)="deleteAttachDocument(i,'MDS')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                    <div *ngIf="field.MDS_filename!=null && !field.isMDSAttachment">
                                                        <a href="{{fileDownloadPath}}{{field.MDS_filepath}}"
                                                            target="_blank">{{field.MDS_filename}}</a>
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            *ngIf="isRFQEditable"
                                                            (click)="deleteAttachDocumentFromDB(i,'MDS')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="file"
                                                        *ngIf="isRFQEditable && (field.ITC_filename==null && !field.isITCAttachment)">
                                                        <i class="flaticon-file-2"></i>
                                                        <input type="file" class="form-control-file"
                                                            id="itcfile{{i}}" name="itcfile" *ngIf="isRFQEditable"
                                                            (change)="editFileDetails($event,i)" />
                                                    </div>

                                                    <div *ngIf="field.isITCAttachment">
                                                        {{(field.ITCAttachment
                                                        != undefined &&
                                                        field.ITCAttachment[0]
                                                        != undefined) ?
                                                        field.ITCAttachment[0].name:""}}
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            *ngIf="isRFQEditable"
                                                            (click)="deleteAttachDocument(i,'ITC')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                    <div *ngIf="field.ITC_filename!=null && !field.isITCAttachment">
                                                        <a href="{{fileDownloadPath}}{{field.ITC_filepath}}"
                                                            target="_blank">{{field.ITC_filename}}</a>
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            *ngIf="isRFQEditable"
                                                            (click)="deleteAttachDocumentFromDB(i,'ITC')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="file"
                                                        *ngIf="isRFQEditable && (field.Customer_filename==null && !field.isCustomerAttachment)">
                                                        <i class="flaticon-file-2"></i>
                                                        <input type="file" class="form-control-file"
                                                            id="customerfile{{i}}" name="customerfile"
                                                            *ngIf="isRFQEditable"
                                                            (change)="editFileDetails($event,i)" />
                                                    </div>

                                                    <div *ngIf="field.isCustomerAttachment">
                                                        {{(field.CustomerAttachment
                                                        != undefined &&
                                                        field.CustomerAttachment[0]
                                                        != undefined) ?
                                                        field.CustomerAttachment[0].name:""}}
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            *ngIf="isRFQEditable"
                                                            (click)="deleteAttachDocument(i,'Customer')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                    <div
                                                        *ngIf="field.Customer_filename!=null && !field.isCustomerAttachment">
                                                        <a href="{{fileDownloadPath}}{{field.Customer_filepath}}"
                                                            target="_blank">{{field.Customer_filename}}</a>
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            *ngIf="isRFQEditable"
                                                            (click)="deleteAttachDocumentFromDB(i,'Customer')"
                                                            title="Remove"><br></i>
                                                    </div>


                                                </td>
                                                <td>
                                                    <div class="file"
                                                        *ngIf="isRFQEditable && (field.Weiss_filename==null && !field.isWeissAttachment)">
                                                        <i class="flaticon-file-2"></i>
                                                        <input type="file" class="form-control-file"
                                                            id="weissfile{{i}}" name="weissfile"
                                                            *ngIf="isRFQEditable"
                                                            (change)="editFileDetails($event,i)" />
                                                    </div>

                                                    <div *ngIf="field.isWeissAttachment">
                                                        {{(field.WeissAttachment
                                                        != undefined &&
                                                        field.WeissAttachment[0]
                                                        != undefined) ?
                                                        field.WeissAttachment[0].name:
                                                        ""}}
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            *ngIf="isRFQEditable"
                                                            (click)="deleteAttachDocument(i,'Weiss')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                    <div
                                                        *ngIf="field.Weiss_filename!=null && !field.isWeissAttachment">
                                                        <a href="{{fileDownloadPath}}{{field.Weiss_filepath}}"
                                                            target="_blank">{{field.Weiss_filename}}</a>
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            *ngIf="isRFQEditable"
                                                            (click)="deleteAttachDocumentFromDB(i,'Weiss')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                </td>
                                                <td
                                                    style="vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                                    <select class="form-control p-0" id="salesprice{{i}}"
                                                        (change)="OnSalesPriceChange(i)" name="salesprice{{i}}"
                                                        [disabled]="!isRFQEditable" [(ngModel)]="field.SalesPrice"
                                                        style="border: none;">
                                                        <option value="undefined" selected disabled>
                                                            Select
                                                        </option>
                                                        <option *ngFor="let item of productDrpdnDetails.SalesPrice"
                                                            value="{{item.Id}}">
                                                            {{item.Name}}
                                                        </option>
                                                    </select>
                                                    <div *ngIf="(field.SalesPriceValidation)">
                                                        <span class="validationmsg">
                                                            Sales Price is
                                                            required</span>
                                                    </div>
                                                </td>

                                                <td
                                                    style="width:75px; vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                                    <input type="text" class="form-control m-input"
                                                        id="salesdiscount{{i}}" name="salesdiscount{{i}}"
                                                        onfocus="this.value=''" [disabled]="!isRFQEditable"
                                                        (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                        (change)="OnSalesDiscountChange(i)"
                                                        [(ngModel)]="field.SalesDiscount" max="99.99" step="0.01"
                                                        style="border:none;text-align:right;"
                                                        [disabled]="!iseditable">

                                                    <div *ngIf="(field.SalesDiscountValidation)">
                                                        <span class="validationmsg">
                                                            Sales Discount is
                                                            required</span>
                                                    </div>

                                                </td>

                                                <td *ngIf="isRFQEditable" style="padding:5px!Important;">
                                                    <div style="display: inline-flex;">

                                                        <a title="Add" (click)="AddRFQDetails(i)"
                                                            *ngIf="i==EnquiryObj.RFQObject.length-1"
                                                            style="color:#000000;width: 25px;height: 25px;"
                                                            class="btn btn-primary m-btn m-btn--icon m-btn--icon-only mr-2"><i
                                                                class="fa fa-plus"></i></a>

                                                        <a title="Delete"
                                                            *ngIf="(EnquiryObj.RFQObject.length>1) && ((EnquiryObj.RFQObject.length - 1 ) == i)"
                                                            (click)="deleteRFQDetails(i)"
                                                            style="color:#ffffff;width: 25px;height: 25px;"
                                                            class="btn btn-metal m-btn m-btn--icon m-btn--icon-only mr-2"><i
                                                                class="fa fa-minus"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>

                            </div>
                            <div *ngIf="isRFQEditable" class="form-group m-form__group row">
                                <span class="fileext">[File Format: txt, pdf,
                                    doc,
                                    docx, xls, xlsx, jpg, jpeg, png, bmp, zip,
                                    csv, xml] [Max 5 MB]</span><br />
                            </div>
                            <!--termsandcondition-->
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Estimated
                                    RFQ Value:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <select class="form-control p-0" id="estproductvalue" name="estproductvalue"
                                        #estproductvalue="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform2.submitted && estproductvalue.invalid) || (estproductvalue?.touched && estproductvalue.invalid) }"
                                        required [disabled]="!isRFQEditable"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.EstimatedProductValue">
                                        <option value="null" disabled>Select
                                        </option>
                                        <option *ngFor="let item of productDrpdnDetails.EstimatedProductValue"
                                            value="{{item.Id}}">
                                            {{item.Name}}
                                        </option>
                                    </select>
                                    <div *ngIf="(myform2.submitted && estproductvalue.invalid) || (estproductvalue?.touched && estproductvalue.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="estproductvalue.errors.required">
                                            Estimated RFQ Value is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Requested
                                    date of Delivery:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <div class="input-group date">
                                        <input readonly class="form-control" placeholder="DD-MMM-YYYY"
                                            id="deliverydate" name="deliverydate" ngbDatepicker #d="ngbDatepicker"
                                            [minDate]="EnquiryObj.EnquiryDetails.EnquiryDate" (click)="d.toggle()"
                                            [startDate]="EnquiryObj.EnquiryDetails.EnquiryDate" [maxDate]="EndDate"
                                            [disabled]="!isRFQEditable"
                                            [(ngModel)]="EnquiryObj.EnquiryDetails.RequestedDeliveryDate"
                                            #deliverydate="ngModel"
                                            [ngClass]="{ 'is-invalid': (myform2.submitted && deliverydate.invalid) || (deliverydate?.touched && deliverydate.invalid) }"
                                            required>
                                        <div class="input-group-append">
                                            <span (click)="d.toggle()" class="input-group-text">
                                                <i class="la la-calendar-check-o"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div *ngIf="(myform2.submitted && deliverydate.invalid) || (deliverydate?.touched && deliverydate.invalid)"
                                        class="invalid-feedback" style="display: block;">
                                        Date is
                                        required
                                    </div>
                                </div>

                                <div class="offset-1"></div>
                                <label class="col-lg-2 col-form-label" *ngIf="isRFQEditable">Other
                                    Attachment(s):</label>
                                <div class="col-lg-4 pt-1" *ngIf="isRFQEditable">
                                    <div class="file btn btn-sm btn-warning">
                                        Upload
                                        <input type="file" [disabled]="!isRFQEditable" class="form-control-file"
                                            id="quotefiles" name="quotefiles" #myInput
                                            (change)="getMultipleFileDetails($event)" multiple />
                                    </div>
                                    <br /><span class="fileext">
                                        {{AppGlobals.supportedFileFormatsForOtherMultipleAttachments}}</span>

                                    <span
                                        *ngIf="EnquiryObj.EnquiryDetails.QuoteGenAttachments != undefined && EnquiryObj.EnquiryDetails.QuoteGenAttachments.length > 0">
                                        <ul>
                                            <ng-container
                                                *ngFor="let item of EnquiryObj.EnquiryDetails.QuoteGenAttachments;let i=index;">
                                                <li class="filenamemobile">
                                                    {{item.name}}&nbsp;&nbsp;<i class="flaticon-delete"
                                                        style="color:#f4516c" (click)="removeQuoteGenAttachments(i)"
                                                        title="Remove"></i>
                                                </li>
                                            </ng-container>
                                        </ul>

                                    </span>
                                </div>
                            </div>

                            <div class="form-group m-form__group row" *ngIf="isRFQEditable">
                                <label class="col-lg-2 col-form-label">Comments:<span style="color:#f4516c;">&nbsp;
                                        *</span></label>
                                <div class="col-lg-10">
                                    <textarea class="form-control m-input" id="rfqcomments" name="rfqcomments"
                                        rows="3" maxlength="1500" (keyup)="limitTextOnKeyUpDown1($event)"
                                        (keypress)="limitTextOnKeyUpDown1($event)"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.Comments" #rfqcomments="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform2.submitted && rfqcomments.invalid) || (rfqcomments?.touched && rfqcomments.invalid) }"
                                        required>
                                                </textarea>
                                    <span> Limit {{maxLimit}} characters,
                                        {{remLimit}} characters
                                        remaining.</span>
                                    <div *ngIf="(myform2.submitted && rfqcomments.invalid) || (rfqcomments?.touched && rfqcomments.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="rfqcomments.errors.required">
                                            Comment is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <h5
                                    style="border-bottom: 1px solid #ccc; margin-top: 15px;padding-bottom: 10px; width: 100%;">
                                    Application</h5>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Industry
                                    Segment:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <select class="form-control p-0" id="industrysegment" name="industrysegment"
                                        #industrysegment="ngModel"
                                        (change)="onIndustrySegmentSelect(industrysegment.value)"
                                        [ngClass]="{ 'is-invalid':myform2.submitted  && industrysegment.invalid || industrysegment?.touched && industrysegment.invalid}"
                                        required [disabled]="!isRFQEditable"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.segment">
                                        <option value="null" disabled>
                                            Select
                                            Industry Segment</option>
                                        <option *ngFor="let item of IndustrySegment" value="{{item.Id}}">
                                            {{item.Name}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback"
                                        *ngIf="myform2.submitted && industrysegment.invalid || industrysegment?.touched && industrysegment.invalid">
                                        <div *ngIf="myform2.hasError('required', 'industrysegment')">
                                            Industry segment is required</div>
                                    </div>
                                </div>

                                <div class="offset-1"></div>

                                <label class="col-lg-2 col-form-label">Sub
                                    Segment:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <select class="form-control p-0" id="subsegment" name="subsegment"
                                        #subsegment="ngModel"
                                        [ngClass]="{ 'is-invalid':myform2.submitted  && subsegment.invalid || subsegment?.touched && subsegment.invalid}"
                                        required [disabled]="!isRFQEditable"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.subSegment"
                                        (change)="onSubSegmentSelect(subsegment.value)">
                                        <option value="null" disabled>
                                            Select
                                            Sub Segment</option>
                                        <option *ngFor="let item of SubSegment" value="{{item.Id}}">
                                            {{item.Name}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback"
                                        *ngIf="myform2.submitted && subsegment.invalid || subsegment?.touched && subsegment.invalid">
                                        <div *ngIf="myform2.hasError('required', 'subsegment')">
                                            Sub segment is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Application
                                    Name:</label>
                                <div class="col-lg-3">
                                    <select class="form-control p-0" id="appplication" name="appplication"
                                        #appplication="ngModel" [disabled]="!isRFQEditable"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.application">
                                        <option value="null" disabled>
                                            Select
                                            Application Name</option>
                                        <option *ngFor="let item of Application" value="{{item.Id}}">
                                            {{item.Application}}
                                        </option>
                                    </select>

                                    <!-- Commenting this because we don't keep application required on RFQ. It will be required on confirmation page -->
                                    <!-- <div class="invalid-feedback"
                                                *ngIf="myform2.submitted && appplication.invalid || appplication?.touched && appplication.invalid">
                                                <div *ngIf="appplication.errors.required">
                                                    Application name is required
                                                </div>
                                            </div> -->

                                </div>
                                <div class="offset-1"></div>

                                <label class="col-lg-2 col-form-label">End
                                    Customer:</label>
                                <div class="col-lg-3">
                                    <input class="form-control m-input" type="text" name="endcustomer"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.EndCustomer" #endcustomer="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform2.submitted && endcustomer.invalid) || (endcustomer?.touched && endcustomer.invalid) }"
                                        placeholder="Enter End Customer" maxlength="50"
                                        (keyup)="limitTextOnKeyUpDown2($event)"
                                        (keypress)="limitTextOnKeyUpDown2($event)" [disabled]="!isRFQEditable">
                                    <span> Limit {{maxCharLimit}} characters,
                                        {{remCharLimit}} characters
                                        remaining.</span>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Application
                                    Description:</label>

                                <div class="col-lg-9">
                                    <textarea class="form-control m-input" id="applicationDescription"
                                        name="applicationDescription" rows="3" maxlength="1500"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.ApplicationDescription"
                                        (keyup)="limitTextOnKeyUpDown3($event)"
                                        (keypress)="limitTextOnKeyUpDown3($event)" #applicationDescription="ngModel"
                                        [disabled]="!isRFQEditable">
                                        </textarea>
                                    <span> Limit {{maxappdescLimit}} characters,
                                        {{remappdescLimit}} characters
                                        remaining.</span>
                                    <!---  <div *ngIf="(myform2.submitted && applicationDescription.invalid) || (applicationDescription?.touched && applicationDescription.invalid)"
                                                class="invalid-feedback">
                                                <div *ngIf="applicationDescription.errors.required">
                                                    Application Description is
                                                    required
                                                </div>
                                            </div> -->
                                </div>
                            </div>



                            <div class="form-group m-form__group row">
                                <h5
                                    style="border-bottom: 1px solid #ccc; margin-top: 15px;padding-bottom: 10px; width: 100%;">
                                    Kind Attention</h5>
                            </div>

                            <div class="form-group m-form__group row"
                                style="border-bottom: 1px solid #ccc; margin-top: 15px;margin-left: 14px;padding-bottom: 10px; width: 97%;">
                                <mat-radio-group class="mobileView" [disabled]="!isRFQEditable"
                                    [(ngModel)]="this.EnquiryObj.EnquiryDetails.IsFetchFromKYC" name="radio"
                                    (change)="radioChange(this.EnquiryObj.EnquiryDetails.IsFetchFromKYC)"
                                    aria-label="Select an option">
                                    <mat-radio-button
                                        [checked]="this.EnquiryObj.EnquiryDetails.IsFetchFromKYC == false"
                                        [value]=false>Enter
                                        Manually
                                    </mat-radio-button>
                                    &nbsp;&nbsp;
                                    <mat-radio-button [value]=true
                                        [checked]="this.EnquiryObj.EnquiryDetails.IsFetchFromKYC == true">
                                        From KYC
                                    </mat-radio-button>
                                </mat-radio-group><br /><br />
                            </div>


                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Kind
                                    Attention:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <input class="form-control m-input" type="text" name="kindatt"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.KindAttention" #kindatt="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform2.submitted && kindatt.invalid) || (kindatt?.touched && kindatt.invalid) }"
                                        placeholder="Enter Kind Attention" (keypress)="AvoidSpace($event)"
                                        maxlength="100"
                                        (change)="EnquiryObj.EnquiryDetails.KindAttention=EnquiryObj.EnquiryDetails.KindAttention.trim()"
                                        [disabled]="!isRFQEditable" required>

                                    <div *ngIf="(myform2.submitted && kindatt.invalid) || (kindatt?.touched && kindatt.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="kindatt.errors.required">
                                            Kind attention is
                                            required
                                        </div>
                                    </div>
                                </div>

                                <div class="offset-1"></div>

                                <label class="col-lg-2 col-form-label">Contact
                                    Number:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <input class="form-control m-input" type="text" name="kindattcno"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.KindAttContactNo"
                                        #kindattcno="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform2.submitted && kindattcno.invalid) || (kindattcno?.touched && kindattcno.invalid) }"
                                        placeholder="Enter Contact Number"
                                        (keypress)="AvoidSpace($event);isNumberKey($event);" minlength="10"
                                        maxlength="15" pattern="[0-9]+"
                                        (change)="EnquiryObj.EnquiryDetails.KindAttContactNo=EnquiryObj.EnquiryDetails.KindAttContactNo.trim()"
                                        [disabled]="!isRFQEditable" required>

                                    <div *ngIf="(myform2.submitted && kindattcno.invalid) || (kindattcno?.touched && kindattcno.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="kindattcno.errors.required">
                                            Contact number is
                                            required
                                        </div>
                                        <div *ngIf="myform2.hasError('pattern', 'kindattcno')">
                                            Please enter valid contact number
                                        </div>
                                        <div *ngIf="myform2.hasError('minlength', 'kindattcno')">
                                            Contact number should be 10-15
                                            digits</div>
                                    </div>
                                </div>

                            </div>


                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Email:<span
                                        style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <input class="form-control m-input" type="text" name="kindattemail"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.KindAttEmailId"
                                        #kindattemail="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform2.submitted && kindattemail.invalid) || (kindattemail?.touched && kindattemail.invalid) }"
                                        placeholder="Enter Email" (keypress)="AvoidSpace($event)" maxlength="50"
                                        [pattern]="emailPattern"
                                        (change)="EnquiryObj.EnquiryDetails.KindAttEmailId=EnquiryObj.EnquiryDetails.KindAttEmailId.trim()"
                                        [disabled]="!isRFQEditable" required>

                                    <div *ngIf="(myform2.submitted && kindattemail.invalid) || (kindattemail?.touched && kindattemail.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="kindattemail.errors.required">
                                            Email is
                                            required
                                        </div>
                                        <div *ngIf="myform2.hasError('pattern', 'kindattemail')">
                                            <!-- Should be in Email Id format (e.g. user@ecotech.com) -->
                                            Should be in Email Id format ({{mailIdFormat}})
                                        </div>
                                    </div>
                                </div>

                                <div class="offset-1"></div>

                            </div>

                            <div class="form-group m-form__group row">
                                <h5
                                    style="border-bottom: 1px solid #ccc; margin-top: 15px;padding-bottom: 10px; width: 100%;">
                                    Terms and Condition Details</h5>
                            </div>
                            <!--T&C-->
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Terms and
                                    Conditions:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <select class="form-control p-0" id="tandc" name="tandc" #tandc="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform2.submitted && tandc.invalid) || (tandc?.touched && tandc.invalid) }"
                                        required [disabled]="!isRFQEditable" (change)="setTermsAndConditions()"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.RFQTermsAndCondition">
                                        <option value="" selected disabled>
                                            Select
                                        </option>
                                        <option value="Standard">Standard
                                        </option>
                                        <option value="Non-Standard">Non-Standard
                                        </option>
                                    </select>
                                    <div *ngIf="(myform2.submitted && tandc.invalid) || (tandc?.touched && tandc.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="tandc.errors.required">
                                            Terms & conditions is
                                            required
                                        </div>
                                    </div>
                                </div>
                                <div class="offset-1"></div>

                            </div>
                            <div class="row" style="margin-left: 15px;" *ngIf="isStandardCondition">
                                <div class="form-group m-form__group row col-sm-6"
                                    *ngFor="let item of EnquiryObj.TermsAndConditions; let i = index">
                                    <div style="display: contents;">
                                        <label class="col-form-label">{{item.TnCName}}:<span
                                                style="color:#f4516c;">&nbsp;*</span></label>
                                        <input
                                            [disabled]="!isRFQEditable || EnquiryObj.EnquiryDetails.RFQTermsAndCondition=='Standard'"
                                            [(ngModel)]="item.TnCValue" maxlength="150" type="text" name="tnc{{i}}"
                                            class="form-control m-input"><br />
                                    </div><br />
                                </div>
                            </div>
                            <!----------For Custom TC---------------->
                            <div class="row" style="margin-left: 5px;" *ngIf="!isStandardCondition">
                                <div class="form-group m-form__group col-sm-6"
                                    *ngFor="let item of EnquiryObj.CustomTermsAndConditions; let i = index">

                                    <label class="col-form-label">{{item.StandardTCName}}:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <select class="form-control" id="ctnc{{i}}" name="ctnc{{i}}" #ctnc="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform2.submitted && ctnc.invalid) || (ctnc?.touched && ctnc.invalid) }"
                                        [disabled]="!isRFQEditable" (change)="OnCustomValueChange(i,item.CustTCId)"
                                        [(ngModel)]="item.CustTCId" required>
                                        <option value=null selected disabled>
                                            Select
                                        </option>
                                        <option *ngFor="let custValues of item.CustomValues"
                                            value="{{custValues.Id}}">
                                            {{custValues.Name}}
                                        </option>
                                    </select>

                                    <div *ngIf="!isStandardCondition && ((myform2.submitted && ctnc.invalid) || (ctnc?.touched && ctnc.invalid))"
                                        class="invalid-feedback">
                                        <div *ngIf="ctnc.errors.required">
                                            Non standard value is required
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="button-row text-right mt-4" style="margin:15px;">

                        <div class="m-portlet__head-caption">
                            <span *ngFor="let item of workflowActionList;let i = index;">
                                <button class="btn btn-primary ml-auto js-btn-next btnspc"
                                    type="{{item.workflowActionType}}"
                                    (click)="OnWorkflowAction(myform2,item.workflowActionId)">
                                    {{item.workflowActionName}}
                                </button> &nbsp; &nbsp;
                            </span>
                            <button class="btn btn-metal m-btn m-btn--air m-btn--custom" type="button"
                                (click)="backtoList()" routerLinkActive="active">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
