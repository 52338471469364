<!-- <loader [showLoader]="showLoader"></loader> -->
<div class="m-portlet m-portlet--success m-portlet--head-solid-bg ">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">
                    Lead/Enquiry vs RFQ
                </h3>
            </div>
        </div>
    </div>
    <div class="m-portlet__body">
        <div id="OpportunityRFQChart" style="width: 100%; height: 400px; background-color: #FFFFFF;"></div>
    </div>
</div>