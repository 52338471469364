import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class EngagementReportService {
  constructor(private commonService: CommonService) { }

  seriesColors: string[] = [
    "#FF0000",
    "#097969",
    "#800080",
    "#0080FE",
    "#8B4513",
    "#808080",
    "#6F00FF",
    "#FFFF00",
    "#32CD32",
    "#C1E1C1",
    "#00308F"
  ]

  getReportData(data: any) {
    return this.commonService.PostData("Activity", "GetChartData", data, null)
  }


  transformChartData(xCategory, yCategory, seriesCategory, chartData) {
    let transformedChartData = {};

    let allSeriesNames = this.getAllSeriesName(seriesCategory, chartData);

    // console.log('allSeriesNames');
    // console.log(allSeriesNames);

    for (let item of chartData) {

      let category = item[xCategory];
      let seriesCat = item[seriesCategory];

      if (!transformedChartData[category]) {
        transformedChartData[category] = {};
        transformedChartData[category][xCategory] = item[xCategory];

        for (let seriesName of allSeriesNames) {
          transformedChartData[category][seriesName] = 0;
        }
      }

      if (!transformedChartData[category][seriesCat]) {
        transformedChartData[category][seriesCat] = 0;
      }

      transformedChartData[category][seriesCat] += item[yCategory];
    }

    // console.log('transformed chart data in key values');
    // console.log(transformedChartData);

    let arrayformat = this.convertToArray(transformedChartData);

    return arrayformat;

  }

  getAllSeriesName(seriesCategory, chartData) {
    let seriesNames = new Array();

    for (let item of chartData) {
      // console.log(item);
      let seriesCat = item[seriesCategory];
      if (seriesNames.indexOf(seriesCat) < 0) {
        seriesNames.push(seriesCat);
      }
    }

    return seriesNames;
  }

  convertToArray(data: any) {
    let result = Array.from(
      Object.keys(data),
      (k) => data[k]
    );
    // console.log(data);

    return result;
  }


  convertArrayToObject(data: []): any {
    const result = {};

    data.forEach(activity => {
      const key = `${activity['ActivityYear']}-${activity['ActivityMonth']}-${activity['ActivityDay']}`;

      if (!result[key]) {
        result[key] = {};
      }

      if (!result[key][activity['PlanType']]) {
        result[key][activity['PlanType']] = [];
      }
      result[key][activity['PlanType']].push(activity);
    });

    return result;
  }

}