<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        {{FormStatus}} {{VariantLevel2obj.Label_Variant2}}
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm" appFocusInvalidInput
                            (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                            <div class="m-portlet__body">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Product Series:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="productseries"
                                            [(ngModel)]="VariantLevel2obj.SeriesName" #productseries="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productseries.invalid || productseries?.touched && productseries.invalid}"
                                            placeholder="Enter Product Series" (keypress)="AvoidSpace($event)" required
                                            disabled>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && productseries.invalid || productseries?.touched && productseries.invalid">
                                            <div *ngIf="myform.hasError('required', 'productseries')">
                                                Product series is required
                                            </div>
                                        </div>

                                    </div>

                                    <div class="offset-1"></div>
                                    <label class="col-lg-2  col-form-label">Product Model:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="productmodel"
                                            [(ngModel)]="VariantLevel2obj.ModelName" #productmodel="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productmodel.invalid || productmodel?.touched && productmodel.invalid}"
                                            placeholder="Enter Product Model" (keypress)="AvoidSpace($event)" required
                                            (change)="VariantLevel2obj.ModelName=VariantLevel2obj.ModelName.trim()"
                                            disabled>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && productmodel.invalid || productmodel?.touched && productmodel.invalid">
                                            <div *ngIf="myform.hasError('required', 'productmodel')">
                                                Product model is required
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group m-form__group row"
                                    *ngIf="VariantLevel2obj.SeriesName == 'TC' || VariantLevel2obj.SeriesName == 'TR'">
                                    <label *ngIf="VariantLevel2obj.IsLevel2DepOnLevel1"
                                        class="col-lg-2 col-form-label">{{VariantLevel2obj.Label_Variant1}}:
                                        <span style="color:#f4516c;"
                                            *ngIf="VariantLevel2obj.IsLevel2DepOnLevel1">&nbsp;*</span>
                                    </label>
                                    <div class="col-lg-3" *ngIf="VariantLevel2obj.IsLevel2DepOnLevel1">
                                        <select class="form-control" id="sel1" name="Variant1"
                                            [(ngModel)]="VariantLevel2obj.Level1Id" #Variant1="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && Variant1.invalid || Variant1?.touched && Variant1.invalid}"
                                            [required]="VariantLevel2obj.IsLevel2DepOnLevel1" disabled>
                                            <option value="null" disabled selected>Select
                                                {{VariantLevel2obj.Label_Variant1}}</option>
                                            <option *ngFor="let item of Level1arr" value="{{item.Id}}">
                                                {{item.Level1}}
                                            </option>
                                        </select>

                                        <div class="invalid-feedback"
                                            *ngIf="(myform.submitted && Variant1.invalid || Variant1?.touched && Variant1.invalid)">
                                            <div *ngIf="myform.hasError('required', 'Variant1')">
                                                {{VariantLevel2obj.Label_Variant1}} is required
                                            </div>
                                        </div>

                                    </div>
                                    <div class="offset-1" *ngIf="VariantLevel2obj.IsLevel2DepOnLevel1"></div>
                                    <label class="col-lg-2 col-form-label">{{VariantLevel2obj.Label_Variant2}}:
                                        <span style="color:#f4516c;">&nbsp;*</span>
                                    </label>
                                    <div class="col-lg-3">

                                        <mat-select class="form-control"
                                            placeholder="Select {{VariantLevel2obj.Label_Variant2}}"
                                            [(ngModel)]="Level2Currdata" [ngStyle]="{'background-color': isEditable ? '#f4f5f8' : '#fff' }"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && Variant2.invalid || Variant2?.touched && Variant2.invalid}"
                                            #Variant2="ngModel" multiple name="Variant2" required
                                            [disabled]="isEditable">
                                            <mat-option *ngFor="let item of Level2arr" value="{{item.Level1}}">
                                                {{item.Level1}}</mat-option>
                                            <mat-option disabled *ngIf="Level2arr.length <= 0" value="No Data Found"> No
                                                Data Found </mat-option>
                                        </mat-select>
                                        <div class="invalid-feedback"
                                            *ngIf="(myform.submitted && Variant2.invalid || Variant2?.touched && Variant2.invalid)">
                                            <div *ngIf="myform.hasError('required', 'Variant2')">
                                                {{VariantLevel2obj.Label_Variant2}} is required
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-1" *ngIf="!isEditable">
                                        <a title="Add" (click)="OpenPopupAddSpeedStep()" title="Add SpeedStep"
                                        style="color:#000000;width: 25px;height: 25px;margin-top: 6px;"  data-toggle="modal" data-target="#myModal"
                                        class="btn btn-primary m-btn m-btn--icon m-btn--icon-only mr-2"><i
                                            class="fa fa-plus"></i></a>
                                    </div>

                                </div>
                                <div class="form-group m-form__group row"
                                    *ngIf="VariantLevel2obj.SeriesName != 'TC' && VariantLevel2obj.SeriesName != 'TR'">
                                    <label *ngIf="VariantLevel2obj.IsLevel2DepOnLevel1"
                                        class="col-lg-2 col-form-label">{{VariantLevel2obj.Label_Variant1}}:
                                        <span style="color:#f4516c;"
                                            *ngIf="VariantLevel2obj.IsLevel2DepOnLevel1">&nbsp;*</span></label>
                                    <div class="col-lg-3" *ngIf="VariantLevel2obj.IsLevel2DepOnLevel1">

                                        <select class="form-control" id="sel1" name="var1"
                                            [(ngModel)]="VariantLevel2obj.Level1Id" #var1="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && var1.invalid || var1?.touched && var1.invalid}"
                                            [required]="VariantLevel2obj.IsLevel2DepOnLevel1" [disabled]="isEditable">
                                            <option value="null" disabled selected>Select
                                                {{VariantLevel2obj.Label_Variant1}}</option>
                                            <option *ngFor="let item of Level1arr" value="{{item.Id}}">
                                                {{item.Level1}}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="(myform.submitted && var1.invalid || var1?.touched && var1.invalid)">
                                            <div *ngIf="myform.hasError('required', 'var1')">
                                                {{VariantLevel2obj.Label_Variant1}} is required
                                            </div>
                                        </div>

                                    </div>
                                    <div class="offset-1" *ngIf="VariantLevel2obj.IsLevel2DepOnLevel1"></div>
                                    <label class="col-lg-2 col-form-label">{{VariantLevel2obj.Label_Variant2}}:
                                        <span style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="var2"
                                            [(ngModel)]="VariantLevel2obj.Level2[0] && VariantLevel2obj.Level2[0].Level1"
                                            #var2="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && var2.invalid || var2?.touched && var2.invalid}"
                                            placeholder="Enter {{VariantLevel2obj.Label_Variant2}}" maxlength="130"
                                            (keypress)="AvoidSpace($event)" required [disabled]="isEditable"
                                            (change)="VariantLevel2obj.Level2[0].Level1=VariantLevel2obj.Level2[0].Level1.trim()">
                                        <div class="invalid-feedback"
                                            *ngIf="(myform.submitted && var2.invalid || var2?.touched && var2.invalid)">
                                            <div *ngIf="myform.hasError('required', 'var2')">
                                                {{VariantLevel2obj.Label_Variant2}} is required
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Price (€):</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="price" min="0"
                                            [(ngModel)]="VariantLevel2obj.Level2Price" #price="ngModel" pattern="[0-9]+"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && price.invalid || price?.touched && price.invalid}"
                                            style="text-align:right;" placeholder="Enter Price" maxlength="7"
                                            (keypress)="AvoidSpace($event);isNumberKey($event);"
                                            [disabled]="VariantLevel2obj.IsPriceOnRequest ||isEditable">
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && price.invalid || price?.touched && price.invalid">

                                            <div *ngIf="myform.hasError('pattern', 'price')">
                                                Price must be an integer</div>
                                        </div>

                                    </div>
                                    <div class="offset-1"></div>
                                    <div class="col-lg-3">
                                        <mat-checkbox name="dep2" #dep2="ngModel"
                                            [(ngModel)]="VariantLevel2obj.IsPriceOnRequest"
                                            (change)="onIsPriceOnRequest($event)" [disabled]="isEditable">
                                            <label>Is Price On Request</label>
                                        </mat-checkbox>
                                    </div>
                                    <div class="col-lg-3"
                                        *ngIf="(VariantLevel2obj.SeriesName != 'TC' && VariantLevel2obj.SeriesName != 'TR' )">
                                        <mat-checkbox name="dep1" #dep1="ngModel" [(ngModel)]="VariantLevel2obj.IsUnits"
                                            [disabled]="isEditable">
                                            <label>Is Units</label>
                                        </mat-checkbox>
                                    </div>

                                </div>
                                <div class="form-group m-form__group row">

                                </div>
                            </div>

                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit" *ngIf="isEditable"
                                                [hidden]="!isProductVariant2Editable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                                (click)="EditDetails()">Edit</button>&nbsp;&nbsp;
                                            <button id="m_login_signin_submit" *ngIf="!isEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <!-- <button type="button"
                                                *ngIf="!isEditable && (VariantLevel2obj.SeriesName == 'TC' || VariantLevel2obj.SeriesName == 'TR')"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                                [disabled]="Level2Currdata.length == 0"
                                                (click)="DeleteStation()">Delete</button>&nbsp;&nbsp; -->
                                            <a [routerLink]="['/VariantLevel2',VariantLevel2obj?.ProductSeries,VariantLevel2obj?.Label_Variant1,VariantLevel2obj?.Label_Variant2]"
                                                routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

<!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
    <div class="custsize">
    <div class="modal-dialog">
  
      <!-- Modal content-->
      <div class="modal-content custContent">
        <div class="modal-body">
            <div class="multisteps-form__panel shadow p-4 rounded bg-white js-active" data-animation="fadeIn">
        <div class="row">
            <div class="col-sm-12">
                <div class="m-portlet">
                    <div class="modal-header">
                        <h5 class="modal-title" style="font-weight: 450">Add SpeedStep</h5>
                        <!-- <button type="button" class="btn btn-primary"  data-dismiss="modal">
                            <span>&times;</span>
                        </button> -->
                    </div>
                    <div class="markedFields">
                        <span style="margin-left:10px;">* Marked fields are
                            mandatory</span>
                    </div>
                    <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="myform1" #myform1="ngForm"
                        (ngSubmit)="myform1.form.valid && AddSpeedStep(myform1)" novalidate>
                     
                        <div class="m-portlet__body">

                            <div class="form-group m-form__group row">
                                <label class="col-lg-5 col-form-label">SpeedStep :<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-6">
                                    <input class="form-control m-input" type="text" name="speedstep" id="speedstep" [(ngModel)]="SpeedStepName"
                                        #speedstep="ngModel"
                                        [ngClass]="{ 'is-invalid':myform1.submitted  && speedstep.invalid || speedstep?.touched && speedstep.invalid}"
                                        placeholder="Enter SpeedStep" (keypress)="AvoidSpace($event)" maxlength="4"
                                        (change)="SpeedStepName=SpeedStepName.trim()" required pattern="^[a-zA-Z0-9]*$">
                            
                                    <div class="invalid-feedback"
                                        *ngIf="myform1.submitted && speedstep.invalid || speedstep?.touched && speedstep.invalid">
                                        <div *ngIf="myform1.hasError('required', 'speedstep')">
                                            SpeedStep is required</div>
                                        <div *ngIf="myform1.hasError('pattern', 'speedstep')">
                                            Please enter alphanumeric values ​​only</div>
                                        <div *ngIf="myform1.hasError('maxlength', 'speedstep')">
                                            SpeedStep must not exceed 4 characters
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                            <div class="m-form__actions m-form__actions--solid" style="padding: 16px;">
                                <div class="row">
                                    <div class="col-lg-2"></div>
                                    <div class="col-lg-10 button-row text-right mt-3">
                                        <button id="m_login_signin_submit"
                                            class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                        <button type="button" class="btn btn-metal" #closebutton data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </form>
                </div>
            </div>

        </div>
    </div>
        </div>
    </div>
  
    </div>
</div>
  </div>