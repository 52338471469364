import { Component, OnInit, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-order-win-lost-chart',
  templateUrl: './order-win-lost-chart.component.html',
  styleUrls: ['./order-win-lost-chart.component.scss']
})
export class OrderWinLostChartComponent implements OnInit {

  @Input()
  showLoader: boolean = false;
  drpdnOption: string = "Amount";
  type: string = "Won";

  @Input() Year: number;
  @Input() region: number;
  @Input() currency: number;
  @Input() filterChanged: boolean;
  @Input() currencyCode: string;
  constructor(private toastr: ToastrService, private commonService: CommonService) { }

  ngOnInit(): void {
    //am4core.options.onlyShowOnViewport = true;
    this.OrderWinLostChart();

  }
  ngOnChanges(): void {
    if (this.filterChanged) this.OrderWinLostChart();
  }

  OnSelectChange() {
    this.OrderWinLostChart();
  }

  OrderWinLostChart() {
    this.showLoader = true;
    let queryString = "";
    queryString += "drpdnOption=" + this.drpdnOption;
    queryString += "&Year=" + this.Year;
    queryString += "&type=" + this.type;
    queryString += "&regionid=" + this.region;
    queryString += "&currency=" + this.currency;

    this.commonService.GetData("Dashboard", "OrderWinLossChart", queryString).subscribe((data: any) => {
      // Create chart instance
      let chart = am4core.create("OrderWinLostChart", am4charts.XYChart);
      let title = chart.titles.create();
      title.text = "Order Won/Lost Analysis";
      title.fontSize = 15;
      title.fontWeight = "bold";
      title.marginBottom = 30;

      // Export
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      // chart.cursor = new am4charts.XYCursor();
      chart.scrollbarX.exportable = false;
      chart.scrollbarY.exportable = false;
      chart.exporting.menu = new am4core.ExportMenu();
      let options = chart.exporting.getFormatOptions("pdf");
      options.addURL = false;
      chart.exporting.setFormatOptions("pdf", options);
      chart.exporting.filePrefix = "OrderWon_LostAnalysis";
      /**************Custom Export**************** */
      chart.exporting.menu.items = [
        {
          "label": "...",
          "menu": [
            {
              "label": "Image",
              "menu": [
                { "type": "png", "label": "PNG" },
                { "type": "jpg", "label": "JPG" },
                // { "type": "svg", "label": "SVG" },
                { "type": "pdf", "label": "PDF" }
              ]
            }, {
              "label": "Data",
              "menu": [
                { "type": "xlsx", "label": "XLSX" },
              ]
            }, {
              "label": "Print", "type": "print"
            }
          ]
        }
      ];
      let CurrencyLabel = "";
      if (this.drpdnOption == "Amount") {
        // if (this.currency == 1) CurrencyLabel = "(USD)";
        // if (this.currency == 2) CurrencyLabel = "(EURO)";
        // if (this.currency == 3) CurrencyLabel = "(INR)";
        if (this.currencyCode == "USD") CurrencyLabel = "(USD)";
        if (this.currencyCode == "Euro") CurrencyLabel = "(EURO)";
        //if (this.currencyCode == "INR") CurrencyLabel = "(INR)";
        if (this.currencyCode == "VND") CurrencyLabel = "(VND)";
        if (this.currencyCode == "IDR") CurrencyLabel = "(IDR)";
        if (this.currencyCode == "PHP") CurrencyLabel = "(PHP)";
        if (this.currencyCode == "THB") CurrencyLabel = "(THB)";
        if (this.currencyCode == "MYR") CurrencyLabel = "(MYR)";
        if (this.currencyCode == "SGD") CurrencyLabel = "(SGD)";
      }
      else {
        CurrencyLabel = "(No's)";
      }
      chart.exporting.dataFields = {
        "category": this.type + "_Reason",
        "value": this.type + "_" + this.drpdnOption + CurrencyLabel,

      }
      /**************End Custom Export**************** */

      // Data for both series
      chart.data = data;

      /* Create axes */
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());


      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.minGridDistance = 30;
      //categoryAxis.renderer.labels.template.rotation = -45;

      /* Create value axis */
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
        var val = text == "0.00" ? "0" : text;
        return val;
      });
      /**************Scrolling Start End Point**************** */
      chart.events.on("beforedatavalidated", function(ev) {
        categoryAxis.start = 0;
        categoryAxis.end = 0.5;
        valueAxis.start = 0;
        valueAxis.end = 1;
        });
        /**************End Scrolling Start End Point**************** */
      if (this.drpdnOption == 'Units') {
        valueAxis.title.text = "No.";
        chart.numberFormatter.numberFormat = "0";
        valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
          var val = parseFloat(text) % 1 != 0 ? "" : parseInt(text).toString();
          return val;
        });
      }
      else {
        // if (this.currency == 1) valueAxis.title.text = "USD";
        // if (this.currency == 2) valueAxis.title.text = "EURO";
        // if (this.currency == 3) valueAxis.title.text = "INR";
        if (this.currencyCode == "USD") valueAxis.title.text = "USD";
        if (this.currencyCode == "Euro") valueAxis.title.text = "EURO";
        //if (this.currencyCode == "INR") valueAxis.title.text = "INR";
        if (this.currencyCode == "VND") valueAxis.title.text = "VND";
        if (this.currencyCode == "IDR") valueAxis.title.text = "IDR";
        if (this.currencyCode == "PHP") valueAxis.title.text = "PHP";
        if (this.currencyCode == "THB") valueAxis.title.text = "THB";
        if (this.currencyCode == "MYR") valueAxis.title.text = "MYR";
        if (this.currencyCode == "SGD") valueAxis.title.text = "SGD";
      //  chart.numberFormatter.numberFormat = "0.00";
      }
      valueAxis.title.fontWeight = "bold";
      valueAxis.title.fontSize = "16px";
      valueAxis.min = 0;
      valueAxis.extraMax = 0.5;


      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "value";
      columnSeries.dataFields.categoryX = "category";
      if (this.drpdnOption == 'Units') {
        if (this.type == 'Won') columnSeries.name = "Won No's";
        if (this.type == 'Lost') columnSeries.name = "Lost No's";
      }
      else {
        if (this.type == 'Won') columnSeries.name = "Won Amount";
        if (this.type == 'Lost') columnSeries.name = "Lost Amount";
      }
      columnSeries.columns.template.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.tooltip.getFillFromObject = false;
      columnSeries.tooltip.background.fill = am4core.color("#4a463c");
      let colLabel = columnSeries.bullets.push(new am4charts.LabelBullet);
      categoryAxis.renderer.labels.template.maxWidth = 100;
     // categoryAxis.renderer.labels.template.truncate = true;
     categoryAxis.renderer.labels.template.wrap = true;
      categoryAxis.renderer.labels.template.tooltipText = '{category}';
      colLabel.label.text = "{valueY}";
    //  colLabel.label.rotation = 0;
      colLabel.label.fontSize = 9;
      colLabel.label.padding(0, 0, 20, 0);
      colLabel.label.truncate = false;
      colLabel.label.hideOversized = false;



      //  /* Create series */
      //  let columnSeries2 = chart.series.push(new am4charts.ColumnSeries());
      //  columnSeries2.name = "Lost No.";
      //  columnSeries2.dataFields.valueY = "valueL2";
      //  columnSeries2.dataFields.categoryX = "category";

      //  columnSeries2.columns.template.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]";
      //  columnSeries2.columns.template.propertyFields.fillOpacity = "fillOpacity";
      //  columnSeries2.columns.template.propertyFields.stroke = "stroke";
      //  columnSeries2.columns.template.propertyFields.strokeWidth = "strokeWidth";
      //  columnSeries2.columns.template.propertyFields.strokeDasharray = "columnDash";
      //  columnSeries2.tooltip.label.textAlign = "middle";
      //  columnSeries2.tooltip.getFillFromObject = false;
      //  columnSeries2.tooltip.background.fill = am4core.color("#4a463c");     

      chart.legend = new am4charts.Legend();

      this.showLoader = false;
    }, error => {
      this.showLoader = false;

    });

  }


}
