<!-- begin::Header -->
<loader [showLoader]="showLoader"></loader>
<header id="m_header" class="m-grid__item m-header " m-minimize="minimize" m-minimize-offset="200"
    m-minimize-mobile-offset="200">
    <div class="m-header__top">
        <div class="m-container m-container--responsive m-container--xxl m-container--full-height m-page__container">
            <div class="m-stack m-stack--ver m-stack--desktop">

                <!-- begin::Brand -->
                <div class="m-stack__item m-brand">
                    <div class="m-stack m-stack--ver m-stack--general m-stack--inline">
                        <!-- <div (click)="onLogoClick()" class="m-stack__item m-stack__item--middle m-brand__logo"> -->
                            <!-- <img alt="" src="assets/images/logo-sm.png" /> -->
                            <!-- <img alt="" src="assets/images/eco_logo.png" /> -->
                        <!-- </div> -->

                        <div class="m-stack__item m-stack__item--middle m-brand__logo">
                            <!-- <a routerLink="/Dashboard" ><img alt="" src="assets/images/ecotech_logo.png"/></a> -->
                            <a routerLink="/Dashboard"><img alt="" [src]="logoImageUrl" /></a>
                        </div>

                        <div class="m-stack__item m-stack__item--middle m-brand__tools">

                            <!-- begin::Responsive Header Menu Toggler-->
                            <a id="m_aside_header_menu_mobile_toggle" (click)="myFunction()"
                                class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
                                <span class="toggle"></span>
                            </a>

                            <!-- end::Responsive Header Menu Toggler-->

                            <!-- begin::Topbar Toggler-->
                            <a id="m_aside_header_topbar_mobile_toggle"
                                class="m-brand__icon m--visible-tablet-and-mobile-inline-block" (click)="myFunctions()">
                                <i class="flaticon-more"></i>
                            </a>

                            <!--end::Topbar Toggler-->
                        </div>

                    </div>
                </div>

                <!-- end::Brand -->
                <div class="m-stack__item m-stack__item--middle m-stack__item--fluid m-header-head">
                    <div class="m-stack__item m-stack__item--middle m-stack__item--fluid cust">

                        <div id="m_header_menu"
                            class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-dark m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-light m-aside-header-menu-mobile--submenu-skin-light ">
                            <button
                                class="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-light "
                                id="m_aside_header_menu_mobile_close_btn" (click)="myFunction()"><i
                                    class="la la-close"></i></button>
                            <ul class="m-menu__nav  m-menu__nav--submenu-arrow ">
                                <li class="m-menu__item  " routerLinkActive="m-menu__item--active"><a
                                        routerLink="/Dashboard" routerLinkActive="m-menu__item--active"
                                        class="m-menu__link "><span class="m-menu__link-text">Dashboard</span></a></li>

                                <li class="m-menu__item m-menu__item--submenu m-menu__item--open-dropdown dropdowns"
                                    m-menu-submenu-toggle="click" m-menu-link-redirect="1"
                                    routerLinkActive="m-menu__item--active">
                                    <a class="m-menu__link" *ngIf="getValue('isAllEnquiry')">
                                        <span class="m-menu__link-text enqDrDn">Enquiry</span>
                                        <i class="m-menu__hor-arrow la la-angle-down enqDrDnArrow"></i>
                                        <i class="m-menu__ver-arrow la la-angle-right enqDrDnArrow"></i>

                                    </a>
                                    <div class="m-menu__submenu  customerHeaderWidth collapse dropdown-menu"
                                        id="enquiryDiv">
                                        <span class="m-menu__arrow m-menu__arrow--adjust" style="left: 298px;"></span>
                                        <div class="m-menu__subnav " style="width: fit-content;">
                                            <ul class="m-menu__content">

                                                <li class="m-menu__item">
                                                    <ul class="m-menu__inner">

                                                        <li *ngIf="getValue('isAllEnquiry')" class="m-menu__item "
                                                            aria-haspopup="true"><a routerLink="/Enquiry"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text enqDrDn toggle">All
                                                                            Enquiries</span>
                                                                    </span></span></a></li>
                                                        <li *ngIf="getValue('isAllEnquiry')" class="m-menu__item " aria-haspopup="true"><a
                                                                routerLink="/PendingEnquiry"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text enqDrDn toggle">Pending
                                                                            Enquiries</span> </span></span></a>
                                                        </li>
                                                        <li *ngIf="getValue('isLead')" class="m-menu__item "
                                                        aria-haspopup="true">
                                                        <a routerLink="/Lead"
                                                            routerLinkActive="m-menu__item--active"
                                                            class="m-menu__link "><span class="m-menu__link-title">
                                                                <span class="m-menu__link-wrap"> <span
                                                                        class="m-menu__link-text mngMaster toggle">Lead
                                                                        </span>
                                                                </span></span></a>
                                                    </li>
                                                    </ul>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </li>

                                <li class="m-menu__item m-menu__item--submenu m-menu__item--open-dropdown dropdowns"
                                    m-menu-submenu-toggle="click" m-menu-link-redirect="1" *ngIf="getValue('isProductLine')"
                                    routerLinkActive="m-menu__item--active">
                                    <a class="m-menu__link">
                                        <span class="m-menu__link-text ProductLineMain">Product Line</span>
                                        <i class="m-menu__hor-arrow la la-angle-down ProductLineArrow"></i>
                                        <i class="m-menu__ver-arrow la la-angle-right ProductLineMain"></i>

                                    </a>
                                    <div class="m-menu__submenu customerHeaderWidth collapse dropdown-menu"
                                        id="ProductLineMainDiv">
                                        <span class="m-menu__arrow m-menu__arrow--adjust" style="left: 298px;"></span>
                                        <div class="m-menu__subnav " style="width: fit-content;">
                                            <ul class="m-menu__content">
                                                <li class="m-menu__item">
                                                    <ul class="m-menu__inner">
                                                        <!-- <li *ngIf="getValue('isProductLine')"
							                                class="m-menu__item "
                                                            aria-haspopup="true">

                                                            <a href="javascript:;" routerLink="/ProductMatrix"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link m-menu__toggle" title="">
                                                                <span class="m-menu__link-text">TC/TR Matrix</span>

                                                            </a>
                                                        </li> -->
                                                        <li *ngIf="getValue('isProductLine')"
							                                class="m-menu__item "
                                                            aria-haspopup="true">

                                                            <a href="javascript:;" routerLink="/ProductLine"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link m-menu__toggle" title="">
                                                                <span class="m-menu__link-text PL">Product Line</span>
                                                                <i class="m-menu__hor-arrow la la-angle-right"></i>
                                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                                            </a>
                                                            <div id="ProductLineDiv"
                                                                class="m-menu__submenu m-menu__submenu--classic m-menu__submenu--right subs ProductLine">
                                                                <ul class="m-menu__subnav">

                                                                    <li class="m-menu__item " m-menu-link-redirect="1"
                                                                        aria-haspopup="true">
                                                                        <a routerLink="/ProductFamily"
                                                                            routerLinkActive="m-menu__item--active"
                                                                            class="m-menu__link "><span
                                                                                class="m-menu__link-text">Product
                                                                                Family</span></a>
                                                                    </li>
                                                                    <li class="m-menu__item subnav"
                                                                        m-menu-link-redirect="1" aria-haspopup="true">
                                                                        <a routerLink="/ProductSeries"
                                                                            routerLinkActive="m-menu__item--active"
                                                                            class="m-menu__link m-menu__toggle">
                                                                            <span class="m-menu__link-text PS">Product
                                                                                Series</span>
                                                                            <i
                                                                                class="m-menu__hor-arrow la la-angle-right"></i>
                                                                            <i
                                                                                class="m-menu__ver-arrow la la-angle-right"></i>
                                                                        </a>
                                                                        <div id="seriesDiv"
                                                                            class="m-menu__submenu m-menu__submenu--classic m-menu__submenu--right sub-subs ProductLine">
                                                                            <ul class="m-menu__subnav">
                                                                                <div
                                                                                    class="row row pr-5 pl-5 pt-3 pb-3">
                                                                                    <ng-container
                                                                                        class="m-menu__content"
                                                                                        *ngFor="let item of SeriesSubmenu">
                                                                                        <div class="col-sm-25">
                                                                                            <li class="m-menu__item subnavs "
                                                                                                m-menu-submenu-toggle="click"
                                                                                                m-menu-link-redirect="1"
                                                                                                aria-haspopup="true"
                                                                                                routerLinkActive="m-menu__item--active"
                                                                                                [routerLinkActiveOptions]="{exact: true}"
                                                                                                style="width: 150px;">

                                                                                                <a [routerLink]="['/ProductModel',item?.Id]"
                                                                                                    routerLinkActive="m-menu__item--active"
                                                                                                    class="m-menu__link m-menu__toggle">
                                                                                                    <span
                                                                                                        class="m-menu__link-text mngMaster SN WrapSeriesName"
                                                                                                        title="{{item.SeriesName}}">{{item.SeriesName}}</span>
                                                                                                  
                                                                                                </a>
                                                                                               
                                                                                            </li>

                                                                                        </div>

                                                                                    </ng-container>
                                                                                </div>
                                                                            </ul>
                                                                        </div>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        </li>

                                                    </ul>

                                                </li>


                                            </ul>
                                        </div>
                                    </div>
                                </li>

                                <li class="m-menu__item m-menu__item--submenu m-menu__item--open-dropdown dropdowns"
                                    m-menu-submenu-toggle="click" m-menu-link-redirect="1"
                                    routerLinkActive="m-menu__item--active">
                                    <a class="m-menu__link">
                                        <span class="m-menu__link-text mngMaster">Manage Masters</span>
                                        <i class="m-menu__hor-arrow la la-angle-down mngMasterArrow"></i>
                                        <i class="m-menu__ver-arrow la la-angle-right mngMasterArrow"></i>

                                    </a>
                                    <!-- m-menu__submenu--fixed-xl m-menu__submenu--center -->
                                    <div class="m-menu__submenu customerHeaderWidth collapse dropdown-menu"
                                        id="manageMasterDiv">
                                        <span class="m-menu__arrow m-menu__arrow--adjust" style="left: 298px;"></span>
                                        <div class="m-menu__subnav " style="width: fit-content;">
                                            <ul class="m-menu__content">
                                                <li class="m-menu__item">
                                                    <ul class="m-menu__inner">

                                                        <li *ngIf="getValue('isBusinessPlanforSalesandOrder')" class="m-menu__item "
                                                            aria-haspopup="true"><a routerLink="/BusinessPlanDetails"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text mngMaster toggle">Business
                                                                            Plan for Sales and Order</span>
                                                                    </span></span></a>
                                                        </li>
                                                        <li *ngIf="getValue('isConfigureMasters')" class="m-menu__item "
                                                            aria-haspopup="true"><a
                                                                [routerLink]="['/ConfigureMasters', '']"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text mngMaster toggle">Configure
                                                                            Masters</span>
                                                                    </span></span></a></li>
                                                        <li *ngIf="getValue('isCustomer')" class="m-menu__item "
                                                            aria-haspopup="true"><a routerLink="/Customer"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text mngMaster toggle">Customer</span>
                                                                    </span></span></a></li>
                                                        <li *ngIf="getValue('isEmployee')" class="m-menu__item "
                                                            aria-haspopup="true"><a routerLink="/Employee"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text mngMaster toggle">Employee</span>
                                                                    </span></span></a></li>
                                                        <li *ngIf="getValue('isGuest')" class="m-menu__item " aria-haspopup="true">
                                                            <a routerLink="/Guest"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text mngMaster toggle">Guest</span>
                                                                    </span></span></a>
                                                        </li>
                                                        <li *ngIf="getValue('isWorkflowScreenAccess')" class="m-menu__item "
                                                            aria-haspopup="true">
                                                            <a routerLink="/WorkFlowScreenAccess"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text mngMaster toggle">Access and Permissions</span>
                                                                    </span></span></a>
                                                        </li>
                                                        <li *ngIf="getValue('isLoginPageImage')" class="m-menu__item "
							                                aria-haspopup="true">
                                                            <a routerLink="/LoginPageImage" routerLinkActive="m-menu__item--active" class="m-menu__link "><span
                                                                    class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span class="m-menu__link-text mngMaster toggle">Login
                                                                            Page Image</span>
                                                                    </span></span></a>
                                                        </li>



                                                         <li *ngIf="getValue('isAlertsManagement')" class="m-menu__item " aria-haspopup="true">
                                                            <a routerLink="/Reminders-config"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text mngMaster toggle">Reminder</span>
                                                                    </span></span></a>
                                                        </li>
                                                        <li *ngIf="getValue('IsCustomerFrameAgreement')" class="m-menu__item " aria-haspopup="true">
                                                            <a routerLink="/CustomerFrameAgreementDetails"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text mngMaster toggle">Customer Frame Agreement</span>
                                                                    </span></span></a>
                                                        </li>
                                                    </ul>

                                                </li>


                                            </ul>
                                        </div>
                                    </div>
                                </li>

                                <li class="m-menu__item m-menu__item--submenu m-menu__item--open-dropdown dropdowns"
                                    m-menu-submenu-toggle="click" m-menu-link-redirect="1"
                                    routerLinkActive="m-menu__item--active">
                                    <a class="m-menu__link">
                                        <span class="m-menu__link-text reportDrDn">Reports</span>
                                        <i class="m-menu__hor-arrow la la-angle-down reportDrDnArrow"></i>
                                        <i class="m-menu__ver-arrow la la-angle-right reportDrDnArrow"></i>

                                    </a>
                                    <div class="m-menu__submenu  customerHeaderWidth collapse dropdown-menu"
                                        id="reportDiv">
                                        <span class="m-menu__arrow m-menu__arrow--adjust" style="left: 298px;"></span>
                                        <div class="m-menu__subnav " style="width: fit-content;">
                                            <ul class="m-menu__content">

                                                <li class="m-menu__item">
                                                    <ul class="m-menu__inner">

                                                        <li *ngIf="getValue('CanAccessReports')" class="m-menu__item "
                                                            aria-haspopup="true"><a routerLink="/RegionWise-Reports"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text reportDrDn toggle">Region
                                                                            wise</span>
                                                                    </span></span></a></li>
                                                        <li *ngIf="getValue('CanAccessReports')" class="m-menu__item " aria-haspopup="true"><a
                                                                routerLink="/ProductWise-Reports"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text reportDrDn toggle">Product
                                                                            wise</span> </span></span></a>
                                                        </li>
                                                        <li *ngIf="getValue('CanAccessReports')" class="m-menu__item " aria-haspopup="true"><a
                                                                routerLink="/CustomerWise-Reports"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text reportDrDn toggle">Customer
                                                                            wise</span> </span></span></a>
                                                        </li>
                                                        <li *ngIf="getValue('CanAccessReports')" class="m-menu__item " aria-haspopup="true"><a
                                                                routerLink="/SegmentWise-Reports"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text reportDrDn toggle">Segment
                                                                            wise</span> </span></span></a>
                                                        </li>
                                                        <li *ngIf="getValue('isSiteActivity')" class="m-menu__item " aria-haspopup="true"><a
                                                                routerLink="/Site-Activity"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text reportDrDn toggle">Site
                                                                            Activity</span> </span></span></a>
                                                        </li>



                                                        <li class="m-menu__item" *ngIf="getValue('isStockInventory')"
                                                        routerLinkActive="m-menu__item--active"><a routerLink="/StockInventory"
                                                            routerLinkActive="m-menu__item--active" class="m-menu__link "><span
                                                                class="m-menu__link-text">Stock Inventory</span></a></li>
                    
                                                        <!-- <li class="m-menu__item " aria-haspopup="true"><a routerLink="/PowerBI-Reports" routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span class="m-menu__link-text reportDrDn toggle">PowerBI-Reports</span>
                                                                    </span></span></a>
                                                        </li> -->


                                                    </ul>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </li>

                               


                                <li class="m-menu__item m-menu__item--submenu m-menu__item--open-dropdown dropdowns"
                                    m-menu-submenu-toggle="click" m-menu-link-redirect="1" *ngIf="getValue('isCustomerEngagment')"
                                    routerLinkActive="m-menu__item--active">
                                    <a class="m-menu__link">
                                        <span class="m-menu__link-text activityqDrDn">Customer Engagement</span>
                                        <i class="m-menu__hor-arrow la la-angle-down activityDrDnArrow"></i>
                                        <i class="m-menu__ver-arrow la la-angle-right enqDrDnArrow"></i>
                                    </a>
                                    <div class="m-menu__submenu  customerHeaderWidth collapse dropdown-menu"
                                        id="activityDiv">
                                        <span class="m-menu__arrow m-menu__arrow--adjust" style="left: 298px;"></span>
                                        <div class="m-menu__subnav " style="width: fit-content;">
                                            <ul class="m-menu__content">

                                                <li class="m-menu__item">
                                                    <ul class="m-menu__inner">

                                                        <li *ngIf="getValue('isCustomerEngagment')" class="m-menu__item"
                                                            aria-haspopup="true"><a routerLink="/My-Activities"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text activityqDrDn toggle">My
                                                                            Activities</span>
                                                                    </span></span></a>
                                                        </li>




                                                        <!-- <li class="m-menu__item " aria-haspopup="true"><a
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text activityqDrDn toggle">Report</span>
                                                                    </span></span></a>
                                                        </li> -->
                                                        <li *ngIf="getValue('isCustomerEngagment')" class="m-menu__item " aria-haspopup="true"><a routerLink="/customer-reports" routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span class="m-menu__link-text activityqDrDn toggle">My
                                                                            Reports</span>

                                                                    </span></span></a>

                                                        </li>

                                                        <li class="m-menu__item" *ngIf="getValue('isCustomerEngagment')"
                                                            aria-haspopup="true"><a routerLink="/planned-actual"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text activityqDrDn toggle">My
                                                                            Plan</span>
                                                                    </span></span></a>
                                                        </li>

                                                        <li class="m-menu__item" *ngIf="getValue('isCustomerEngagment')"
                                                            aria-haspopup="true"><a routerLink="/CustomerEngagement"
                                                                routerLinkActive="m-menu__item--active"
                                                                class="m-menu__link "><span class="m-menu__link-title">
                                                                    <span class="m-menu__link-wrap"> <span
                                                                            class="m-menu__link-text activityqDrDn toggle">Customer Engagement</span>
                                                                    </span></span></a>
                                                        </li>
                                                    </ul>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </li>

                                <li class="m-menu__item" *ngIf="getValue('isPriceConfigurator')"
                                routerLinkActive="m-menu__item--active">
                                <a routerLink="/PriceConfigurator"
                                    routerLinkActive="m-menu__item--active" class="m-menu__link "><span
                                        class="m-menu__link-text">
                                        <i class="la la-calculator" style="font-size:26px;"></i></span></a>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- begin::Topbar -->
                <div  class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                    <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general">
                        <div class="m-stack__item m-topbar__nav-wrapper">
                            <ul class="m-topbar__nav m-nav m-nav--inline">

                                <li style="padding-right: 0px;" class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                                    m-dropdown-toggle="click">
                                    <a href="#" class="m-nav__link m-dropdown__toggle">
                                        <span class="m-topbar__welcome">Hello, &nbsp;</span>
                                        <span style="padding-right: 0px;" class="m-topbar__username">{{profileObj.Name}}</span>
                                    </a>
                                    <div class="m-dropdown__wrapper marginOnTablet">
                                        <!-- <span
                                            class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span> -->
                                        <div class="m-dropdown__inner">
                                            <div class="m-dropdown__header m--align-center"
                                                style="background: linear-gradient(to right, #09181c 0%,rgba(89,101,109,1) 50%,rgba(9,24,28,1) 100%)">
                                                <div class="m-card-user m-card-user--skin-dark">
                                                    <div class="m-card-user__pic">
                                                        <!--
                                                        <img src="assets/images/user4.jpg"
                                                            class="m--img-rounded m--marginless" alt="" /> -->
                                                    </div>
                                                    <div class="m-card-user__details">
                                                        <span class="m-card-user__name m--font-weight-500 textoverflow"
                                                            title="{{profileObj.Name}}">{{profileObj.Name}}</span>
                                                        <span
                                                            class="m-card-user__email m--font-weight-300 m-link textoverflow"
                                                            title="{{profileObj.Email}}">{{profileObj.Email}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="m-dropdown__body">
                                                <div class="m-dropdown__content">
                                                    <ul class="m-nav m-nav--skin-light">
                                                        <li class="m-nav__section m--hide">
                                                            <span class="m-nav__section-text">Section</span>
                                                        </li>
                                                        <li class="m-nav__item">
                                                            <a routerLink="/Profile" routerLinkActive="active"
                                                                class="m-nav__link">
                                                                <i class="m-nav__link-icon flaticon-profile-1"></i>
                                                                <span class="m-nav__link-title">
                                                                    <span class="m-nav__link-wrap">
                                                                        <span class="m-nav__link-text">My Profile </span>

                                                                    </span>
                                                                </span>
                                                            </a>
                                                        </li>

                                                        <li class="m-nav__separator m-nav__separator--fit">
                                                        </li>
                                                        <li class="m-nav__item">
                                                            <a (click)="logout()"
                                                                class="btn m-btn--pill btn-primary m-btn m-btn--custom m-btn--label-brand m-btn--bolder">Log
                                                                Out</a>

                                                            <!-- <a (click)="openDialog()"
                                                                class="btn m-btn--pill btn-primary m-btn m-btn--custom m-btn--label-brand m-btn--bolder">Change Background-Image</a> -->
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <!-- end::Topbar -->
            </div>
        </div>
    </div>

</header>
