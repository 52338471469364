import { Component, OnInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-enquiry-details-modal-chart',
  templateUrl: './enquiry-details-modal-chart.component.html',
  styleUrls: ['./enquiry-details-modal-chart.component.scss']
})
export class EnquiryDetailsModalChartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    //this.enquiryDetailsModal();
  }

  
}
