import { Component, OnInit, ViewChild, Input, ɵclearResolutionOfComponentResourcesQueue, ElementRef, Inject, Renderer2, ChangeDetectorRef } from '@angular/core';
import { EmployeeGuestModel } from 'src/app/Model/EmployeeGuestModel';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import * as FileSaver from 'file-saver';
import { BasicCustomerInformationModel } from 'src/app/Model/CustomerModel';
import { EnquiryModel, CustomerDrpdnDetails, CustomerDetails, ProductDrpdnDetails, EnquiryDetailsModel, ProductDetails, Comments, removefiles, TermsAndCondition, ActivityModel, ProductModelPOValues, CustomTermsAndCondition } from 'src/app/Model/EnquiryModel';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { NgbDate, NgbModal, NgbActiveModal, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppGlobals } from 'src/app/app.global';
import { EnquiryStatus, EnquiryStatusCode } from "src/app/EnquiryStatus";
import { UserRoles } from "src/app/UserRoles";
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { MatTableDataSource } from '@angular/material/table';
import { EnquiryModalComponent } from '../enquiry-modal/enquiry-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { QuotationDetailsModel, QuotationCustomerDetails } from 'src/app/Model/QuotationModel';
import { Title } from '@angular/platform-browser';
import { DOCUMENT, Location } from '@angular/common';
import {
  saveAs as importedSaveAs
} from "file-saver";
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { TechnicalDiscardComponent } from '../technical-discard/technical-discard.component';
import { CloseOppReasonComponent } from '../close-opp-reason/close-opp-reason.component';
import { AlertsDetailsComponent } from '../../masters/Alerts/alerts-details/alerts-details.component';
import { AppConfigService } from 'src/app/Service/app-config.service';
declare var require: any
@Component({
  selector: 'app-edit-view-enquiry',
  templateUrl: './edit-view-enquiry.component.html',
  styleUrls: ['./edit-view-enquiry.component.scss']
})
export class EditViewEnquiryComponent implements OnInit {

  @ViewChild('myform3') myform3: NgForm;
  @ViewChild('myform5') myform5: NgForm;
  @ViewChild('myInput') myInputVariable: ElementRef;
  @ViewChild('editpovalue', { static: false }) editpovalue: ElementRef;
  standardTC:any[] = [];
  customTCValues:any[] =[];

  Id: any;
  userInfo: EmployeeGuestModel = new EmployeeGuestModel();
  CustomerList: BasicCustomerInformationModel[] = [];
  EnquiryObj: EnquiryDetailsModel = new EnquiryDetailsModel();
  newRFQDetails: any = {};
  productListdropdownData = [];
  customerDrpdnDetails: CustomerDrpdnDetails = new CustomerDrpdnDetails();
  productDrpdnDetails: ProductDrpdnDetails = new ProductDrpdnDetails();
  customerData: CustomerDetails;
  activityDetails: ActivityModel[] = Array<ActivityModel>();
  CustomerDetailsObj: QuotationCustomerDetails = new QuotationCustomerDetails();
  QuotationData: QuotationDetailsModel = new QuotationDetailsModel();
  allowedFileType: string[] = [
    'image/bmp',
    'image/jpeg',
    'image/png',
    'application/pdf',
    'text/plain',
    'text/csv',
    'application/xml',
    'text/xml',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/zip',
    'application/x-zip-compressed',
  ];

  showEditRFQForInitiator: boolean;
  UserRole: any;
  isTechnicalDiscardVisible: boolean = false;
  isEnquiryFormVisible: boolean = false;
  isRFQVisible: boolean = false;
  isQuoteGenerationVisible: boolean = false;
  isManagementApprovalVisible: boolean = false;
  isSOVisible: boolean = false;
  isOrderAcknowledgementVisible: boolean = false;
  EnquiryConfirmationVisible: boolean = false;
  isSupplychainVisible: boolean = false;
  isAccountsVisible: boolean = false;
  iseditable: boolean = false;
  isOAGenerationVisible: boolean = false;
  isBOMAttachment: boolean;
  isInvoiceAttachment: boolean;
  isPOAttachment: boolean;
  IsRFQDataValid: boolean = true;
  selectedIndexForStatus: any;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  DiscountFromrange: any;
  Discounttorange: any;
  maxCharLimit: any = 50;
  remCharLimit: any = 50;
  maxappdescLimit: any = 1500;
  remappdescLimit: any = 1500;
  hasAccessForCustomersCustomer:boolean;

  @Input()
  showLoader: boolean = false;
  charCode: number;
  enquiryStatus = EnquiryStatus;
  enquiryStatusCode = EnquiryStatusCode;
  AppRoles = UserRoles;
  enquirySource: SingleFieldModel[] = [];
  TnCStandardFromDropDown: any[] = [];
  TnCCustomizedFromDropDown: any[] = [];
  TnCCustomizedDropDownForEditRfq1: any[] = [];
  TnCCustomizedDropDownForEditRfq2: any[] = [];
  newCustomTc: any;
  product: any = {};
  isEnquirySubmit: boolean = false;
  charCode1: any;
  pageNumber: number = 1;
  @Input() pageSize: number = 100;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  dataSource: MatTableDataSource<Comments>;

 // @ViewChild('stepper') stepper: MatStepper;
  AppGlobals = AppGlobals;
  fileDownloadPath = this.AppGlobals.baseFileDownloadUrl;
  QuotationDownloadPath = this.AppGlobals.QuotationFileDownloadUrl;
  isEnquiryLoaded: boolean;
  role: string;
  isStandardCondition: boolean;
  closeResult: string;
  isCEOApprovalVisible: boolean;
  demoObject: any;
  demoCTCObject: any;
  WinLossDropdownData: any[] = [];
  WinLossDropdownDataForStatus: any;
  isPoValueDisable: boolean;
  isCustomerCommentVisible: any;
  isOAGenerated: boolean;
  isAcceptClick: boolean;
  dataPassed: any;
  dropdownData: any;
  showQuotationLink: boolean;
  userid: string;
  isprocessing: boolean = true;
  rfqattpresent: boolean;
  QuotationDataForPO: any[] = [];
  chosenItem: string = "1";
  quoterevcomments: any;
  QGRFQEditable: boolean = false;
  IsActivityDataValid: boolean;
  today: Date;
  fc = new FormControl();
  minDate: NgbDate;
  showEditRFQ: boolean;
  isTantativeDateEditable: boolean;
  tempRFQ: ProductDetails[];
  IsPODataValid: boolean;
  isEditactivity: boolean = true;
  PreviousStatus: number = 0;
  tempfile: any;
  ngbDateParserFormatter: any;
  UpdatePO: boolean = false;
  isPOAttacmentDelete: boolean = false;
  RevisedQuotationFileNameList: string[] = [];
  emailPattern: string = "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  StartDate: NgbDate;
  EndDate: NgbDate;
  RoleWiseScreenDisplay: any;
  previousUrl: string = null;
  IndustrySegment: any[] = [];
  SubSegment: any[] = [];
  Application: any[] = [];

  FinalIndustrySegment: any[] = [];
  FinalSubSegment: any[] = [];
  FinalApplication: any[] = [];
  AlertReminderDays: number;
  showReminder: boolean = false;
  ReminderDays:number[]=[5,10,15,20];
  enquiryStatusList: any;
  isQuotation: boolean;
  workflowId=1;
  transitionRule:any;
  workflowActionList:any[] = [];
  closeOpportunityList: any;
  showRFQBtn: boolean;
  WinLossDataForStatus: any;
  @ViewChild('buttonclk', { static: false, read: ElementRef }) buttonclk: ElementRef<HTMLButtonElement>;
  falseSetup: boolean;
  QuotationPdfGenerationHostPath:any;

  constructor(private toastr: ToastrService, private commonService: CommonService, public dialog: MatDialog,
    private _location: Location,
    private config: NgbDatepickerConfig,
    private modalService: NgbModal, private activeModel: NgbActiveModal,
    private router: Router, private route: ActivatedRoute, private titleService: Title,
    private renderer: Renderer2, private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,private appConfigService: AppConfigService) 
    {
      this.getEnquiryStatus();
      this.getReasonCategory();
      this.today = new Date();
      this.minDate = new NgbDate(this.today.getFullYear(), this.today.getMonth() + 1, this.today.getDate());
      this.StartDate = new NgbDate(2018, 1, 1);
      this.EndDate = new NgbDate(this.today.getFullYear() + 1, 12, 31);
    }

    ngOnInit() {
      this.commonService.previousUrl$.subscribe((previousUrl: string) => {
        this.previousUrl = previousUrl;
      });
      this.getUserProfileInfo();
      let tempRoleWiseScreenDisplay = localStorage.getItem("WorkFlowRoleWiseScreenAccess");  
      this.RoleWiseScreenDisplay = tempRoleWiseScreenDisplay.split(",");
      const appConfig = this.appConfigService.getConfig();
      this.QuotationPdfGenerationHostPath=appConfig.QuotationPdfGenerationHostPath;
      const dynamicTitle = this.route.snapshot.data['title'];
      this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
      //this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
      this.commonService.RefreshHeaderFun();
      this.EnquiryObj.EnquiryDetails.EnquiryId = this.route.snapshot.params["id"];
      
      this.EnquiryObj.EnquiryDetails.EstimatedProductValue = null;
      this.EnquiryObj.EnquiryDetails.InvoiceAttachment = undefined;
      this.getAllCloseOpportunityReason();
      this.getWorkflowActions();
      this.getRFQProduct();
      this.getEnquirySourceDetails();
      this.getCustomerList();
      this.getEnquiryDetailsById();
      this.isEnquiryLoaded = false;
  
      this.UserRole = localStorage.getItem("UserRole");
      this.isTechnicalDiscardVisible = AppGlobals.QuoteGenearation_Enquiry_TechnicalDiscardArray.filter(x => x.role
        == this.UserRole)[0]?.isEditable;
  
      this.Id = this.route.snapshot.params["id"];
     
    }
    
  ngAfterViewInit() {   
    this.showLoader = true;
    this.falseSetup=true;
    setTimeout(() => {
      this.isprocessing = false;    
      this.showLoader = true;
      this.triggerFalseClick();
    }, 2000);
  }
 

    hasScreenAccess()
    {
      if (this.RoleWiseScreenDisplay.some(x => x == 'Enquiry Form')) {
        this.isEnquiryFormVisible = true;   
      }      
  
      if (this.RoleWiseScreenDisplay.some(x => x == 'RFQ')) {
        this.isRFQVisible = true;
      }
  
      if (this.RoleWiseScreenDisplay.some(x => x == 'Quote Generation')) {
        this.isQuoteGenerationVisible = true;
      }
  
  
      if (this.RoleWiseScreenDisplay.some(x => x == 'Management Approval')) {
            this.isManagementApprovalVisible = true;            
          }
  
      if (this.RoleWiseScreenDisplay.some(x => x == 'CEO Approval')) {
            this.isCEOApprovalVisible = true;          
          }
  
      if (this.RoleWiseScreenDisplay.some(x => x == 'Enquiry Confirmation')) {
            this.EnquiryConfirmationVisible = true;
          }
  
      if (this.RoleWiseScreenDisplay.some(x => x == 'Sales Order')) {
            this.isSOVisible = true;          
          }
  
      if (this.RoleWiseScreenDisplay.some(x => x == 'Supply Chain')) {
            this.isSupplychainVisible = true;        
          }
  
      if (this.RoleWiseScreenDisplay.some(x => x == 'Accounts')) {
            this.isAccountsVisible = true;          
          }
  
      if (this.RoleWiseScreenDisplay.some(x => x == 'OAGenerate')) {
            this.isOAGenerationVisible = true;            
          }
    }
  
  //enquiryStatusCode:string;
  isVisibleStep(workflowCode){   
   
     let rfqVisible=[
      this.enquiryStatusCode.EnquiryClosePendingAtSalesDirector,
      this.enquiryStatusCode.EnquiryClosePendingAtCS,
     ]


    let quotationAllowedStatus= [this.enquiryStatusCode.RFQAcceptedbyL1,
                               this.enquiryStatusCode.RFQsentbyL1toL2,
                               this.enquiryStatusCode.RFQsentbyL2toL3,
                               this.enquiryStatusCode.RFQSubmittedbyL1,
                               this.enquiryStatusCode.RFQSubmittedbyL2,
                               this.enquiryStatusCode.RFQSubmittedbyL3,
                               this.enquiryStatusCode.RFQSubmittedbySalesEngg,   
                               this.enquiryStatusCode.SendBOMandProposal,
                               this.enquiryStatusCode.QuotationPriceOnRequestbyL1,
                               this.enquiryStatusCode.QuotationGeneratedbyL1,
                                this.enquiryStatusCode.QuotationsentbyL2toL1,
                               this.enquiryStatusCode.EnquiryTechnicalDiscardbyL1,
                               this.enquiryStatusCode.QuotationPriceOnRequestbyL2,
                               this.enquiryStatusCode.QuotationGeneratedbyL2,
                               this.enquiryStatusCode.QuotationsentbyL3toL2,
                               this.enquiryStatusCode.RFQsentbyL2toL3,
                               this.enquiryStatusCode.RFQSubmittedbyL3,
                               this.enquiryStatusCode.QuotationSaveAsDraftbyL3,
                               this.enquiryStatusCode.QuotationPriceOnRequestbyL3,
                               this.enquiryStatusCode.QuotationGeneratedbyL3,
                               this.enquiryStatusCode.RFQSubmittedbyCustomerService,
                               this.enquiryStatusCode.QuotationSaveAsDraftbyCustomerService,
                               this.enquiryStatusCode.QuotationPriceOnRequestbyCustomerService,
                               this.enquiryStatusCode.QuotationGeneratedbyCustomerService,
                               this.enquiryStatusCode.QuotationSentForPriceDiscountApprovalToLevel1,
                               this.enquiryStatusCode.PriceDiscountApprovedByL1,
                               this.enquiryStatusCode.PriceDiscountApprovedByL2,
                               this.enquiryStatusCode.PriceDiscountApprovedByL3,
                               this.enquiryStatusCode.QuotationSentForPriceDiscountApprovalToLevel3,
                               this.enquiryStatusCode.QuotationSentForPriceDiscountApprovalToLevel2,
                               this.enquiryStatusCode.PriceDiscountRejectedByL1,
                               this.enquiryStatusCode.PriceDiscountRejectedByL2,
                               this.enquiryStatusCode.PriceDiscountRejectedByL3,
                               this.enquiryStatusCode.QuotationSendtoCustomerServicebyL1,
                               this.enquiryStatusCode.QuotationSendtoCustomerServicebyL2,
                               this.enquiryStatusCode.QuotationSendtoCustomerServicebyL3,
                               this.enquiryStatusCode.QuotationSenttoCustomerService,
                               this.enquiryStatusCode.QuotationApprovedbyMngmt_CEO,
                               this.enquiryStatusCode.EnquiryOrderConfirmedbySalesEngg,
                               this.enquiryStatusCode.OrderApprovedbyMngmt_SalesDirector,
                               this.enquiryStatusCode.OrderApprovedbyMngmt_CEO,
                               this.enquiryStatusCode.QuotationSentforMngtApproval_SalesDirector,
                               this.enquiryStatusCode.QuotationOnHoldbyMngmt_SalesDirector,
                               this.enquiryStatusCode.OrdersentforMngmtApproval_SalesDirector,
                               this.enquiryStatusCode.QuotationApprovedbyMngmt_SalesDirector,
                               this.enquiryStatusCode.OrderRejectedbyMngmt_SalesDirector,
                               this.enquiryStatusCode.OrderRejectedbyMngmt_CEO,
                               this.enquiryStatusCode.OrdersentforMngmtApproval_CEO,
                               this.enquiryStatusCode.QuotationSentforMngmtApproval_CEO,
                               this.enquiryStatusCode.QuotationOnHoldbyMngmt_CEO,
                               this.enquiryStatusCode.OrderConfirmedbyCustomerServiceSendSO,
                               this.enquiryStatusCode.MaterialReadyForDistpatch,
                               this.enquiryStatusCode.SupplyChainConfirmedDeliveryDate,
                               this.enquiryStatusCode.QuotationSaveAsDraftbyL1,
                               this.enquiryStatusCode.QuotationSaveAsDraftbyL2,
                               this.enquiryStatusCode.QuotationGeneratedbyL1,
                               this.enquiryStatusCode.QuotationGeneratedbyL2,
                               this.enquiryStatusCode.QuotationGeneratedbyL3,
                               this.enquiryStatusCode.EnquiryLost,
                               this.enquiryStatusCode.AccountsConfirmInvoiceNo,
                               this.enquiryStatusCode.PriceOnRequestSubmitted,
                               this.enquiryStatusCode.OANoGenerated,
                              ];

    let confirmationAllowedStatus= [this.enquiryStatusCode.SendBOMandProposal,
      this.enquiryStatusCode.OrderRejectedbyMngmt_SalesDirector, 
      this.enquiryStatusCode.OrderRejectedbyMngmt_CEO,
      this.enquiryStatusCode.EnquiryOrderConfirmedbySalesEngg,
      this.enquiryStatusCode.OrdersentforMngmtApproval_SalesDirector,
      this.enquiryStatusCode.OrdersentforMngmtApproval_CEO,
      this.enquiryStatusCode.OrderConfirmedbyCustomerServiceSendSO,
      this.enquiryStatusCode.SupplyChainConfirmedDeliveryDate,
      this.enquiryStatusCode.OrderApprovedbyMngmt_CEO,      
      this.enquiryStatusCode.OrderApprovedbyMngmt_SalesDirector,
      this.enquiryStatusCode.EnquiryLost,
      this.enquiryStatusCode.MaterialReadyForDistpatch,
      this.enquiryStatusCode.AccountsConfirmInvoiceNo,
      this.enquiryStatusCode.OANoGenerated,
    ];
                            
    let salesOrderAllowedStatus= [
      this.enquiryStatusCode.EnquiryOrderConfirmedbySalesEngg,
      this.enquiryStatusCode.OrderApprovedbyMngmt_CEO,
      this.enquiryStatusCode.OrderApprovedbyMngmt_SalesDirector,
      this.enquiryStatusCode.OrdersentforMngmtApproval_SalesDirector,
      this.enquiryStatusCode.OrdersentforMngmtApproval_CEO,
      this.enquiryStatusCode.OrderConfirmedbyCustomerServiceSendSO,
      this.enquiryStatusCode.SupplyChainConfirmedDeliveryDate,
      this.enquiryStatusCode.MaterialReadyForDistpatch,
      this.enquiryStatusCode.AccountsConfirmInvoiceNo,
      this.enquiryStatusCode.OANoGenerated,
    ];
   
    let managementApprovalAllowedStatus= 
    [
      this.enquiryStatusCode.OrdersentforMngmtApproval_SalesDirector,    
      this.enquiryStatusCode.QuotationSentforMngtApproval_SalesDirector,
      this.enquiryStatusCode.QuotationOnHoldbyMngmt_CEO,
      this.enquiryStatusCode.QuotationSentforMngmtApproval_CEO,
      this.enquiryStatusCode.QuotationOnHoldbyMngmt_SalesDirector,
      this.enquiryStatusCode.QuotationSentForPriceDiscountApprovalToLevel1,
      this.enquiryStatusCode.QuotationSentForPriceDiscountApprovalToLevel2,
      this.enquiryStatusCode.QuotationSentForPriceDiscountApprovalToLevel3,      
    ];

    let ceoApprovalAllowedStatus= 
    [
      this.enquiryStatusCode.OrdersentforMngmtApproval_CEO,
      this.enquiryStatusCode.QuotationOnHoldbyMngmt_CEO,
      this.enquiryStatusCode.QuotationSentforMngmtApproval_CEO,
      
    ];
    let oaAllowedStatus= [
      this.enquiryStatusCode.OANoGenerated,
      this.enquiryStatusCode.OrderConfirmedbyCustomerServiceSendSO,
      this.enquiryStatusCode.SupplyChainConfirmedDeliveryDate,
      this.enquiryStatusCode.MaterialReadyForDistpatch, 
      this.enquiryStatusCode.AccountsConfirmInvoiceNo
    ];

    let supplytChainAllowedStatus= [
      this.enquiryStatusCode.OANoGenerated,
      this.enquiryStatusCode.SupplyChainConfirmedDeliveryDate,
      this.enquiryStatusCode.MaterialReadyForDistpatch,
      this.enquiryStatusCode.AccountsConfirmInvoiceNo
    ];

    let confirmInvoiceAllowedStatus= [ 
      this.enquiryStatusCode.MaterialReadyForDistpatch,
      this.enquiryStatusCode.AccountsConfirmInvoiceNo,
    ];
    
    if(workflowCode == 'RFQ'){      
      let enquiryStatusCode = this.enquiryStatusList.filter(x=>x.Pk_Id == this.EnquiryObj.EnquiryDetails.Status);    
      

      if(this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'EnquiryLost')[0]?.Pk_Id)
      {
        let reasonCategoryDetails=this.WinLossDataForStatus.filter(y=>y.ReasonCategoryId==this.EnquiryObj.EnquiryDetails.ReasonCategory);
        if(reasonCategoryDetails.length>0)
        {
          if(reasonCategoryDetails[0]?.AvailableAtWorkflowScreen==1)
          {
            return false;
          }         
        }
      }

      if(enquiryStatusCode.length>0)
      {
        let canShowtab=rfqVisible.filter(x=>x==enquiryStatusCode[0].Status);
          if(canShowtab.length>0)
          {
            return false;      
          }          
      }          
      return true;
    }    
    if(workflowCode == 'QUOTATION'){      
      let enquiryStatusCode = this.enquiryStatusList.filter(x=>x.Pk_Id == this.EnquiryObj.EnquiryDetails.Status);    

      if(this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'EnquiryLost')[0]?.Pk_Id)
      {
        let reasonCategoryDetails=this.WinLossDataForStatus.filter(y=>y.ReasonCategoryId==this.EnquiryObj.EnquiryDetails.ReasonCategory);
        if(reasonCategoryDetails.length>0)
        {
          if(reasonCategoryDetails[0]?.AvailableAtWorkflowScreen==1)
          {
            return false;
          }         
        }
      }

      if(enquiryStatusCode.length>0)
      {
        let canShowtab=quotationAllowedStatus.filter(x=>x==enquiryStatusCode[0].Status);
          if(canShowtab.length>0)
          {
            return true;      
          }
              
      }          
      return false;
    }    
    else  if(workflowCode == 'ENQUIRY_CONFIRMATION'){      
      let enquiryStatusCode = this.enquiryStatusList.filter(x=>x.Pk_Id == this.EnquiryObj.EnquiryDetails.Status);       

      if(this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'EnquiryLost')[0]?.Pk_Id)
      {
        let reasonCategoryDetails=this.WinLossDataForStatus.filter(y=>y.ReasonCategoryId==this.EnquiryObj.EnquiryDetails.ReasonCategory);
        if(reasonCategoryDetails.length>0)
        {
          if(reasonCategoryDetails[0]?.AvailableAtWorkflowScreen==1 || reasonCategoryDetails[0]?.AvailableAtWorkflowScreen==3)
          {
            return false;
          }         
        }
      }

      if(enquiryStatusCode.length>0)
      {
        let canShowtab=confirmationAllowedStatus.filter(x=>x==enquiryStatusCode[0].Status);
          if(canShowtab.length>0)
          {
            return true;    
          }
              
      }          
      return false; 
    }    
    else  if(workflowCode == 'SALES_ORDER'){      
      let enquiryStatusCode = this.enquiryStatusList.filter(x=>x.Pk_Id == this.EnquiryObj.EnquiryDetails.Status);    
      if(enquiryStatusCode.length>0)
      {
        let canShowtab=salesOrderAllowedStatus.filter(x=>x==enquiryStatusCode[0].Status);
          if(canShowtab.length>0)
          {
            return true;    
          }
              
      }          
      return false; 
    }    
    else  if(workflowCode == 'MANAGEMNT_APPROVAL'){      
      let enquiryStatusCode = this.enquiryStatusList.filter(x=>x.Pk_Id == this.EnquiryObj.EnquiryDetails.Status);    
      if(enquiryStatusCode.length>0)
      {
        let canShowtab=managementApprovalAllowedStatus.filter(x=>x==enquiryStatusCode[0].Status);
          if(canShowtab.length>0)
          {
            return true;    
          }              
      }          
      return false; 
    }    
    else  if(workflowCode == 'CEO_APPROVAL'){      
      let enquiryStatusCode = this.enquiryStatusList.filter(x=>x.Pk_Id == this.EnquiryObj.EnquiryDetails.Status);    
      if(enquiryStatusCode.length>0)
      {
        let canShowtab=ceoApprovalAllowedStatus.filter(x=>x==enquiryStatusCode[0].Status);
          if(canShowtab.length>0)
          {
            return true;    
          }              
      }          
      return false; 
    }    
    else  if(workflowCode == 'ORDER_ACK'){      
      let enquiryStatusCode = this.enquiryStatusList.filter(x=>x.Pk_Id == this.EnquiryObj.EnquiryDetails.Status);    
      if(enquiryStatusCode.length>0)
      {
        let canShowtab = oaAllowedStatus.filter(x=>x==enquiryStatusCode[0].Status);
          if(canShowtab.length>0)
          {
            return true;    
          }              
      }          
      return false; 
    }    
    else  if(workflowCode == 'SUPPLY_CHAIN'){      
      let enquiryStatusCode = this.enquiryStatusList.filter(x=>x.Pk_Id == this.EnquiryObj.EnquiryDetails.Status);    
      if(enquiryStatusCode.length>0)
      {
        let canShowtab=supplytChainAllowedStatus.filter(x=>x==enquiryStatusCode[0].Status);
          if(canShowtab.length>0)
          {
            return true;    
          }              
      }          
      return false; 
    }    
    else  if(workflowCode == 'CONFIRM_INVOICE'){      
      let enquiryStatusCode = this.enquiryStatusList.filter(x=>x.Pk_Id == this.EnquiryObj.EnquiryDetails.Status);    
      if(enquiryStatusCode.length>0)
      {
        var canShowtab=confirmInvoiceAllowedStatus.filter(x=>x==enquiryStatusCode[0].Status);
          if(canShowtab.length>0)
          {
            return true;    
          }              
      }          
      return false; 
    }    
  }


  getEnquiryStatus()
  {
    this.commonService.GetData("Enquiry","GetEnquiryStatus", null).subscribe((data: any) => {
      this.enquiryStatusList=data;      
     
    }, error => {
     this.showLoader=false;
    });
  }

 
  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }

  limitTextOnKeyUpDown2(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxCharLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxCharLimit);
    } else {
      this.remCharLimit = this.maxCharLimit - goalText.length;
    }
  }

  limitTextOnKeyUpDown3(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxappdescLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxappdescLimit);
    } else {
      this.remappdescLimit = this.maxappdescLimit - goalText.length;
    }
  }


  toggleAttchments(i) {

    this.EnquiryObj.Comments[i].showAttachments = !this.EnquiryObj.Comments[i].showAttachments;
  }

  checkActivityValidations() {
    this.IsActivityDataValid = true;
    this.activityDetails.forEach(element => {
      if (element.Activity == undefined || element.Activity == null || element.Activity == "") {
        element.ActivityValidation = true;
        this.IsActivityDataValid = false;
      }
      if (element.ActivityDate == undefined || element.ActivityDate == null || element.ActivityDate == "") {
        element.ActivityDateValidation = true;
        this.IsActivityDataValid = false;
      }
    });
  }
  editActivity() {
    this.isEditactivity = true;
  }
  cancelEditActivity() {
    this.isEditactivity = false;
  }
  SaveActivityDetails() {
    this.checkActivityValidations();
    if (!this.IsActivityDataValid) {
      return;
    }
    this.showLoader = true;
    this.activityDetails.forEach(element => {
      if (element.ActivityDate != undefined) {
        element.ActivityDateISO = new Date(this.convertDate(new NgbDate(element.ActivityDate.year, element.ActivityDate.month, element.ActivityDate.day))).toDateString();
      }
      if (element.ActivityDate != undefined) {
        element.ActivityDate = new Date(this.convertDate(new NgbDate(element.ActivityDate.year, element.ActivityDate.month, element.ActivityDate.day)));
      }
      element.EnquiryId = this.EnquiryObj.EnquiryDetails.EnquiryId;
    });

    let activityDetails = JSON.stringify(this.activityDetails);
    const frmData = new FormData();
    frmData.append("ActivityObj", activityDetails);
    var i = 0;
    this.activityDetails.forEach(element => {
      if (element.Attachment != undefined) {
        frmData.append("Attachment[" + i + "]", this.activityDetails[i].Attachment[0]);
      }
      i++;
    });

    this.commonService.PostData("Enquiry", "AddActivity", frmData, null).subscribe((data: any) => {
      this.activityDetails.forEach(element => {
        element.ActivityDate = new NgbDate(new Date(element.ActivityDate).getFullYear(), new Date(element.ActivityDate).getMonth() + 1, new Date(element.ActivityDate).getDate());
      });
      //this.isEditactivity = false;
      this.toastr.success("Activity details added successfully!");
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to add activity details!");
      this.showLoader = false;
    });

  }

 

  getEnquiryDetailsById() {
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
    this.commonService.GetData('Enquiry', 'GetById', queryString).subscribe((data: any) => {
      
      this.EnquiryObj.EnquiryDetails = data?.EnquiryDetails;

      this.remLimit = this.maxLimit - data?.EnquiryDetails.EnquiryDescription?.length;
      if (this.EnquiryObj.EnquiryDetails.QuotationRevisionNo != null && this.EnquiryObj.EnquiryDetails.QuotationRevisionNo > 0) {
        this.getRevisedQuotationFileNamelist(this.EnquiryObj.EnquiryDetails.QuotationNumber, this.EnquiryObj.EnquiryDetails.QuotationRevisionNo);
      }

      this.EnquiryObj.EnquiryDetails.EnquiryDate = new NgbDate(new Date(this.EnquiryObj.EnquiryDetails.EnquiryDate).getFullYear(), new Date(this.EnquiryObj.EnquiryDetails.EnquiryDate).getMonth() + 1, new Date(this.EnquiryObj.EnquiryDetails.EnquiryDate).getDate());
      if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != null) {
        this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate = new NgbDate(new Date(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate).getFullYear(), new Date(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate).getMonth() + 1, new Date(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate).getDate());
      }
      if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != null) {
        this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate = new NgbDate(new Date(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate).getFullYear(), new Date(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate).getMonth() + 1, new Date(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate).getDate());
      }
      this.EnquiryObj.EnqCustomerDetails = data?.CustomerDetails;
      this.EnquiryObj.RFQObject = data?.RFQObject;
      this.EnquiryObj.CustomerServiceComments_Quotation = data?.CustomerServiceComments_Quotation;
      this.EnquiryObj.CustomerServiceComments_Order = data?.CustomerServiceComments_Order;
      this.EnquiryObj.SalesDirectorComments_Quotation = data?.SalesDirectorComments_Quotation;

      this.EnquiryObj.SalesDirectorComments_Order = data?.SalesDirectorComments_Order;
      this.EnquiryObj.Comments = data?.Comments == undefined ? [] : data?.Comments;
      this.EnquiryObj.POValues = data?.POValues;

      this.WinLossDropdownData = data?.ReasonEnquiryWinLossObj;
      this.WinLossDropdownDataForStatus = this.WinLossDropdownData.filter(x => x.Value == this.EnquiryObj.EnquiryDetails.EnquiryConfirmStatus);

      if (data.EnquiryDetails.RFQTermsAndCondition == "Standard" || data.EnquiryDetails.RFQTermsAndCondition == "") {

        if (data.TermsAndConditions.length == 0) {
          this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition = "Standard";
          this.EnquiryObj.TermsAndConditions = this.TnCStandardFromDropDown;
        } else {
          this.EnquiryObj.TermsAndConditions = data.TermsAndConditions;

        }
        this.isStandardCondition = true;
      } else if (data.EnquiryDetails.RFQTermsAndCondition == "Customized") {

        if (data.CustomTermsAndConditions.length == 0 && data.EnquiryDetails.PreviousRFQTermsAndCondition == "Standard") {
          this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition = "Customized";
          this.EnquiryObj.CustomTermsAndConditions = this.TnCCustomizedFromDropDown;
          this.TnCCustomizedDropDownForEditRfq1 = this.TnCCustomizedFromDropDown;
        } else {
          this.EnquiryObj.CustomTermsAndConditions = data.CustomTermsAndConditions;
          this.TnCCustomizedDropDownForEditRfq2 = data.CustomTermsAndConditions;
        }

        this.isStandardCondition = false;
      }

      this.activityDetails = data.ActivityDetails;
      this.activityDetails.forEach(element => {
        element.ActivityDate = new NgbDate(new Date(element.ActivityDate).getFullYear(), new Date(element.ActivityDate).getMonth() + 1, new Date(element.ActivityDate).getDate());

        //element.ActivityDateISO = new NgbDate(new Date(element.ActivityDate).getFullYear(), new Date(element.ActivityDate).getMonth() + 1, new Date(element.ActivityDate).getDate());
      });

      //TO POPULATE A SINGLE ROW ON PAGE INIT
      if (this.EnquiryObj.RFQObject.length == 0) {
        var obj = new ProductDetails();
        this.EnquiryObj.RFQObject.push(obj);
      }

      //TO POPULATE A SINGLE ROW ON PAGE INIT
      if (this.activityDetails.length == 0) {
        var obj1 = new ActivityModel();
        obj1.ActivityDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
        obj1.uniqueid = this.makeRandom();
        this.activityDetails.push(obj1);
      }

      this.role = localStorage.getItem('UserRole');
      //this.ScreenAccessSpecifier(localStorage.getItem('UserRole'), this.EnquiryObj.EnquiryDetails.Status);
      this.hasScreenAccess();
     
      if (this.EnquiryObj.EnquiryDetails.segment != undefined) {
        this.SubSegment = this.FinalSubSegment.filter(x => x.SegmentId == this.EnquiryObj.EnquiryDetails.segment);
        this.Application = this.FinalApplication.filter(x => x.SubSegmentId == this.EnquiryObj.EnquiryDetails.subSegment);
      }
      if (this.EnquiryObj.EnquiryDetails.segment == null || this.EnquiryObj.EnquiryDetails.subSegment == null || this.EnquiryObj.EnquiryDetails.application == null) {
        this.EnquiryObj.EnquiryDetails.segment = undefined;
        this.EnquiryObj.EnquiryDetails.subSegment = undefined;
        this.EnquiryObj.EnquiryDetails.application = undefined;
      }
      this.EnquiryObj.EnquiryDetails.IsFetchFromKYC = data.EnquiryDetails.IsFetchFromKYC;
    
      this.getReminderData();
      this.showGoToRFQPage();
    
    }, error => {
      this.showLoader = false;
    });


  }

  getRevisedQuotationFileNamelist(QuotationNumber: string, QuotationRevision: number) {
    let QTNNumber = QuotationNumber.replace("QTN/", "");
    QTNNumber = QTNNumber.split("/").join("_");
    let filename = "Quotation_" + QTNNumber;
    let pdffilename = filename.split('_R')[0];
    for (let i = 0; i < QuotationRevision; i++) {
      if (i == 0) {
        this.RevisedQuotationFileNameList.push(pdffilename + ".pdf")
      }
      else {
        this.RevisedQuotationFileNameList.push(pdffilename + "_R" + i + ".pdf")
      }
    }

  }
  //   replaceAll(str, term, replacement) {
  //     return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
  // }
  makeRandom() {
    let text = "";
    var possible: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~";
    const lengthOfCode = 40;
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  getRFQProduct() {
    this.showLoader = true;
    this.commonService.GetData("Enquiry", "GetEnquiryRFQDropdownData", null).subscribe((data: any) => {
      this.product = data;
      this.productDrpdnDetails.MDSOptions = data.MDS;
      this.productDrpdnDetails.SalesPrice = data.Salesprice;
      this.productDrpdnDetails.EstimatedProductValue = data.EstimatedProductValue;
      this.IndustrySegment = data.segment;
      this.SubSegment = [];
      this.Application = [];
      this.FinalIndustrySegment = data.segment;
      this.FinalSubSegment = data.subSegment;
      this.FinalApplication = data.application;
      data.TermsAndConditions.forEach(element => {
        var obj = new TermsAndCondition();
        obj.TnCId = element.Id;
        obj.TnCName = element.Name;
        obj.TnCValue = element.Value;
        this.TnCStandardFromDropDown.push(obj);
      });

      this.demoObject = data.TermsAndConditions;

      this.demoCTCObject = data.CustomTermsAndCondition;

      for (let i = 0; i < data.CustomTermsAndCondition.length; i++) {
        var obj1 = new CustomTermsAndCondition();
        obj1.StandardTC = data.CustomTermsAndCondition[i].StandardTC.Id;
        obj1.StandardTCName = data.CustomTermsAndCondition[i].StandardTC.Name;
        // this.standardTC.push(data.CustomTermsAndCondition[i].StandardTC);

        for (let j = 0; j < data.CustomTermsAndCondition[i].CustomValues.length; j++) {
          obj1.CustomValues.push(data.CustomTermsAndCondition[i].CustomValues[j]);
        }
        this.TnCCustomizedFromDropDown.push(obj1);
      }

      this.demoCTCObject = data.CustomTermsAndCondition;

      //this.showLoader = false;
    });
  }

  custtermscond: any = [];
  OnCustomValueChange(index, id) {
    let CustTcObj: CustomTermsAndCondition = new CustomTermsAndCondition();
    CustTcObj.StandardTC = this.EnquiryObj.CustomTermsAndConditions[index].StandardTC;
    CustTcObj.StandardTCName = this.EnquiryObj.CustomTermsAndConditions[index].StandardTCName;
    CustTcObj.CustValue = this.EnquiryObj.CustomTermsAndConditions[index].CustomValues.find(x => x.Id == id).Name;
    CustTcObj.CustTCId = id;

    this.custtermscond.push(CustTcObj)
   // console.log(this.custtermscond);
  }



  getProductModel(Id, ind) {
    this.EnquiryObj.RFQObject[ind].ProductLineValidation = false;
    this.product.Product.forEach((element, index) => {
      if (element.ProductLine.Id == Id) {
        this.productDrpdnDetails.ProductModel = element.ProductModels;
        this.newRFQDetails.ProductModelList = element.ProductModels;
        this.EnquiryObj.RFQObject[ind].isProductLineChangeClick = true;
        this.EnquiryObj.RFQObject[ind].ProductModelList = this.productDrpdnDetails.ProductModel;
      }
    });
  }


  addActivityDetails(index) {
    if (!(this.activityDetails[index].Activity == undefined || this.activityDetails[index].Activity == null || this.activityDetails[index].Activity == "")
      &&
      !(this.activityDetails[index].ActivityDate == undefined || this.activityDetails[index].ActivityDate == null || this.activityDetails[index].ActivityDate == "")) {
      var obj = new ActivityModel();
      obj.ActivityDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
      obj.uniqueid = this.makeRandom();
      obj.ISNewActivityAdded = true;
      this.activityDetails.push(obj);
    }
  }

  getCustomerList() {
    this.showLoader = true;
    this.commonService.GetData("Customer", "GetAll", null).subscribe((data: any) => {
      this.CustomerList = data;

      this.showLoader = false;
    });
  }

  getEnquirySourceDetails() {
    this.showLoader = true;
    this.commonService.GetData("Enquiry", "GetEnquiryDropdownData", null).subscribe((data: any) => {
      this.enquirySource = data.EnquirySource;
      this.showLoader = false;
    });
  }

  getProductDetails() {
    this.showLoader = true;
    this.commonService.GetData("Product", "GetRFQProductDetails", null).subscribe((data: any) => {
      this.CustomerList = data;
      //this.showLoader = false;
    });
  }


  AvoidSpace(event: any) {
    if ((event.which === 46 || event.which === 32) && !event.target.value.length) {
      event.preventDefault();
    }
  }

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  isintNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 46 || this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AddRFQDetails(index) {
    if (this.EnquiryObj.RFQObject[index].ProductLine != undefined && this.EnquiryObj.RFQObject[index].ProductModel != undefined &&
      this.EnquiryObj.RFQObject[index].Quantity != undefined) {

      this.EnquiryObj.RFQObject[index].IsRowDeleted = true;

      var obj = new ProductDetails();
      this.EnquiryObj.RFQObject.push(obj);

    }
  }

  openCSComments() {
    this.isCustomerCommentVisible = !this.isCustomerCommentVisible;
  }

  openQuotationPopup(flag) {
    const frmData = new FormData();
    if (this.EnquiryObj.EnquiryDetails.BOMAttachment) {
      this.showLoader = true;
      if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
        this.EnquiryObj.EnquiryDetails.EnquiryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day))).toDateString();
      }
      if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != undefined) {
        this.EnquiryObj.EnquiryDetails.RequestedDeliveryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.year, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.month, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.day))).toDateString();
      }
      if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
        this.EnquiryObj.EnquiryDetails.EnquiryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day)));
      }
      if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != undefined) {
        this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.year, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.month, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.day)));
      }
      frmData.append("BOMAttachment", this.EnquiryObj.EnquiryDetails.BOMAttachment[0]);

      let EnquiryObj = JSON.stringify(this.EnquiryObj);
      frmData.append("EnquiryModel", EnquiryObj);

      this.commonService.PostData("Enquiry", "AddBOM", frmData, null).subscribe((data: any) => {
        this.showLoader = false;
      }, error => {
        this.showLoader = false;
      });
    }

    const modalRef = this.modalService.open(EnquiryModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });
    var enquiObj = {
      EnquiryId: this.EnquiryObj.EnquiryDetails.EnquiryId,
      EnquiryStatus: this.EnquiryObj.EnquiryDetails.Status,
      EnqTermsConditionSelected: this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition,
      IsQuotationPriceOnRequest: this.EnquiryObj.EnquiryDetails.IsQuotationPriceOnRequest,
      TermsAndCondition: this.EnquiryObj.TermsAndConditions,
      CustomTermsAndCondition: this.EnquiryObj.CustomTermsAndConditions,
      PendingAtRoleId: this.EnquiryObj.EnquiryDetails.PendingAtRoleId
    }
    modalRef.componentInstance.enquiObj = enquiObj;
    modalRef.result.then((result) => {
      this.closeResult = `Closed with:${result}`;
      if (this.closeResult !== 'Closed with:Close click') {
        if (flag === 1) {
        } else {
          //  this.saveQuoteLimit(this.QuotationNumber, result);
        }
      }
    }, error => {
      this.showLoader = false;
    });
  }

  backtoList() {
    // this._location.back();
    if (this.previousUrl == null || this.previousUrl == "" || this.previousUrl == undefined) {
      this.router.navigate(['/Enquiry']);
    }
    else if (this.previousUrl.includes("PendingEnquiry")) {
      this.router.navigate(['/PendingEnquiry']);
    }
    else {
      this.router.navigate(['/Enquiry']);
    }

  }  


  // onStepChange(data) {

  //   if (this.userid == this.EnquiryObj.EnquiryDetails.Initiator_Id && data.previouslySelectedIndex == 0 && this.activityDetails[0].Activity != "") {
  //     const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //       maxWidth: "400px",
  //       data: {
  //         title: "Are you sure?",
  //         message: "Please make sure that you have saved the activity details. Do you want to continue?"
  //       }
  //     });
  //     dialogRef.afterClosed().subscribe(dialogResult => {
  //       if (dialogResult) {
  //       } else {
  //         this.stepper.selectedIndex = data.previouslySelectedIndex;
  //       }
  //     });
  //   }
  // }


  SetStatus(status) {    
      this.PreviousStatus = this.EnquiryObj.EnquiryDetails.Status;      
      this.AddEnquiry();      
  }

  AddEnquiry() {
    setTimeout(() => this.openPopup());
  }
  openPopup() {
    var confimrmsg = this.AppGlobals.successMessages.filter(x => x.id == this.EnquiryObj.EnquiryDetails.Status)[0];
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "You want to continue?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showLoader = true;
        const frmData = new FormData();
        this.EnquiryObj.RFQObject.forEach(item => {
          item.IsRowDeleted = false;
        });
        // if (this.newRFQDetails != {}) {
        //   this.isEnquirySubmit = true;
        //   this.AddRFQDetails();
        // }
        if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.EnquiryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.TentativeDeliveryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.year, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.month, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.EnquiryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day)));
        }
        if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.year, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.month, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.day)));
        }
        let EnquiryObj = JSON.stringify(this.EnquiryObj);
        frmData.append("EnquiryModel", EnquiryObj);

        for (let i = 0; i < this.EnquiryObj.EnquiryDetails.QuoteGenAttachments?.length; i++) {
          frmData.append("QuoteGenAttachments[" + i + "]", this.EnquiryObj.EnquiryDetails.QuoteGenAttachments[i]);
        }
        for (let i = 0; i < this.EnquiryObj.EnquiryDetails.MngOrderApprovalAttachments?.length; i++) {
          frmData.append("MngOrderApprovalAttachments[" + i + "]", this.EnquiryObj.EnquiryDetails.MngOrderApprovalAttachments[i]);
        }
        for (let i = 0; i < this.EnquiryObj.EnquiryDetails.MngApprovalAttachments?.length; i++) {
          frmData.append("MngApprovalAttachments[" + i + "]", this.EnquiryObj.EnquiryDetails.MngApprovalAttachments[i]);
        }
        for (let i = 0; i < this.EnquiryObj.EnquiryDetails.CEOApprovalAttachments?.length; i++) {
          frmData.append("CEOApprovalAttachments[" + i + "]", this.EnquiryObj.EnquiryDetails.CEOApprovalAttachments[i]);
        }
        for (let i = 0; i < this.EnquiryObj.RFQObject?.length; i++) {
          if (this.EnquiryObj.RFQObject[i].MDSAttachment != undefined) {
            frmData.append("MDSAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].MDSAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].ITCAttachment != undefined) {
            frmData.append("ITCAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].ITCAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].CustomerAttachment != undefined) {
            frmData.append("CustomerAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].CustomerAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].WeissAttachment != undefined) {
            frmData.append("WeissAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].WeissAttachment[0]);
          }
        }
       
        this.commonService.PostData("Enquiry", "Add", frmData, null).subscribe((data: any) => {
          this.customerDrpdnDetails = data;
          this.EnquiryObj.EnquiryDetails.EnquiryId = data;
        
            this.toastr.success("Enquiry Updated successfully");
        

          this.showLoader = false;
         
            this.router.navigate(['/Enquiry']);
          
        }, error => {
          this.toastr.error('Failed to Update enquiry');
          this.showLoader = false;
        });
      } else {
        this.showLoader=false;
      }
    });

  }
  

  onActivityChange(i) {
    if (this.activityDetails[i].Activity == undefined || this.activityDetails[i].Activity == null || this.activityDetails[i].Activity == "") {
      this.activityDetails[i].ActivityValidation = true;
      this.IsActivityDataValid = false;
    } else {
      this.activityDetails[i].ActivityValidation = false;
    }
  }
  onActivityDateChange(i) {
    if (this.activityDetails[i].ActivityDate == undefined || this.activityDetails[i].ActivityDate == null || this.activityDetails[i].ActivityDate == "") {
      this.activityDetails[i].ActivityDateValidation = true;
      this.IsActivityDataValid = false;
    } else {
      this.activityDetails[i].ActivityDateValidation = false;
    }
  }

  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }

  

  downloadPDF() {
    const url = this.QuotationPdfGenerationHostPath + 'uploads/pdf/' + this.EnquiryObj.EnquiryDetails.QuotationPdf_filename;
    window.open(url, "download");
  }

  DownloadPDFfromServer(Filename: any) {
    this.commonService.gereatePDF('Enquiry', 'DownloadPDF', Filename).subscribe((data: any) => {

      importedSaveAs(data, Filename);
    });
  }
  checkattachment() {
    this.rfqattpresent = false;
    this.EnquiryObj.RFQObject.forEach(element => {
      if (!(element.MDS_filename == null || element.MDS_filename == undefined || element.MDS_filename == '')) {
        this.rfqattpresent = true;
      }
      if (!(element.ITC_filename == null || element.ITC_filename == undefined || element.ITC_filename == '')) {
        this.rfqattpresent = true;
      }
      if (!(element.Weiss_filename == null || element.Weiss_filename == undefined || element.Weiss_filename == '')) {
        this.rfqattpresent = true;
      }
      if (!(element.Customer_filename == null || element.Customer_filename == undefined || element.Customer_filename == '')) {
        this.rfqattpresent = true;
      }
    });
  }
  DownloadAllAttachments() {
    this.checkattachment();
    if (!this.rfqattpresent) {
      alert('No attachment present.');
      return;
    }
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
    this.commonService.GetData('Enquiry', 'Download', queryString).subscribe((data: any) => {
      var url = AppGlobals.ZipDownloadUrl + data + '.zip';
      window.open(url, "_blank");
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });
  }



  public saveAsFile(pdfurl: any, fileName: string): void {
    //const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(this.fileDownloadPath + pdfurl, fileName);
  }


  getReminderData() {
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
    this.commonService.GetData('Enquiry', 'GetByReminderData', queryString).subscribe((data: any) => {
      let regionData = data;
      regionData.forEach(el => {
        if(el.ReminderType == 3 && el.isSent && this.UserRole==1){
          this.showReminder = true;
        }
        if (el.ReminderType == 3 && el.isSent && this.EnquiryObj.EnquiryDetails.PendingAtRoleId==this.UserRole) {
          this.showReminder = true;
        }
        if (el.ReminderType == 4 && el.isPostpone) {
          this.showReminder = false;
        }  
        if (el.ReminderType == 4 && el.isSent) {
          this.showReminder = false;
        }     
      });
      this.showLoader = false;
    });
  }

  AddReminderDays( myform: any){
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
    queryString += "&ReminderDay=" + this.AlertReminderDays;
    this.commonService.GetData('Enquiry', 'UpdateReminderData', queryString).subscribe((data: any) => {
      if(data==1){
        this.toastr.success("Escalation Date Updated successfully")
        window.location.reload();
      }
    })
    this.showLoader=false;
  }
  
  updateLoader(value: boolean) {
    if(this.falseSetup==true)
    {
      this.showLoader=true;
    }
    else
    {
      this.showLoader = value;
    }
    
  }

  getWorkflowActions()
  {    
    let queryString = "";
    queryString = "enquiryId=" + this.EnquiryObj.EnquiryDetails.EnquiryId +"&workflowId=" + this.workflowId ;

    this.showLoader=true;
    this.commonService.GetData("EnquiryWorkflowTransitionRules","GetTransitionRulesByStatus",queryString).subscribe((data: any) => {
        if(data!=null)
        {
          this.workflowActionList=data;
        //  this.workflowActionList=this.workflowActionList.filter(x=>x.workflowActionCode=='CloseEnquiry' || x.workflowActionCode=='Approve' ||x.workflowActionCode=='Reject' || x.workflowActionCode=="Close");
        }      
      this.showLoader=false;
    }, error => {
      this.showLoader=false;
    });
  }


  OnWorkflowAction(myform1:NgForm,workflowActionId:number)
  {    
    var rule= this.workflowActionList.filter(x=>x.workflowActionId==workflowActionId);
    var closeEnquiryRule= this.workflowActionList.filter(x=>x.workflowActionCode=="CloseEnquiry");     
    //var nextStatus=this.enquiryStatusList.filter(x=>x.Status=='EnquiryClosePendingAtSalesDirector')[0].Pk_Id;
    if(closeEnquiryRule.length>0 && rule[0].workflowActionId == closeEnquiryRule[0].workflowActionId){
      this.openCloseOppReasonPopup(closeEnquiryRule[0].workflowNextStatusId);
      return;
    }
    else
    {
      myform1.control.markAllAsTouched();
      if(myform1.invalid)
      {
        this.toastr.error("Please fill required information");
        return;
      }  
    }
  
    this.CloseEnquiry(rule);
  }
  
  openCloseOppReasonPopup(enquiryStatusId:any) {
    const modalRef = this.modalService.open(CloseOppReasonComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });

    var enquiObj = {
      EnquiryId: this.EnquiryObj.EnquiryDetails.EnquiryId,
      EnquiryStatusId: enquiryStatusId,
      EnqTermsConditionSelected: this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition,
      IsQuotationPriceOnRequest: this.EnquiryObj.EnquiryDetails.IsQuotationPriceOnRequest,
      TermsAndCondition: this.EnquiryObj.TermsAndConditions,
      CustomTermsAndCondition: this.EnquiryObj.CustomTermsAndConditions,
      PendingAtRoleId: this.EnquiryObj.EnquiryDetails.PendingAtRoleId
    }

    modalRef.componentInstance.enquiryDataObj = enquiObj;
    modalRef.result.then((result) => {
      this.closeResult = `Closed with:${result}`;
      if (this.closeResult !== 'Closed with:Close click') {
        this.ngOnInit();
      }
    });
  }
  
  CloseEnquiry(rule){

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "You want to " + rule[0].workflowActionCode + "?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) { 
        var closereasondetails = this.closeOpportunityList.filter(x => x.Id == this.EnquiryObj.EnquiryDetails.EnquiryConfirmReason);
        if(rule[0].workflowNextStatusId==this.enquiryStatusList.filter(x=>x.Status=='EnquiryClosePendingAtSalesDirector')[0].Pk_Id)
        {
          this.EnquiryObj.EnquiryDetails.EnquiryConfirmStatus = closereasondetails[0].Name;        
        }   
        if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.EnquiryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.EnquiryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day)));
        }
        if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.year, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.month, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.day)));
        }
        this.EnquiryObj.EnquiryDetails.Status=rule[0].workflowNextStatusId;
        // this.EnquiryObj.EnquiryDetails.ReasonCategory = closereasondetails[0].ReasonCategoryId;
        // this.EnquiryObj.EnquiryDetails.Status = rule[0].workflowNextStatusId;
        let enquiryDetails = JSON.stringify(this.EnquiryObj);
       
        const frmData = new FormData();
        frmData.append("enquiryDetails", enquiryDetails);
      
        this.commonService.PostData("Enquiry", "CloseEnquiry", frmData, null).subscribe((data: any) => {       
            this.showLoader = false;
            if(rule[0].workflowActionCode=="Approve")
                  this.toastr.success("Enquiry closure " + rule[0].workflowActionCode +"d successfully!"); 
            else if(rule[0].workflowActionCode=="Reject")
                  this.toastr.success("Enquiry closure " + rule[0].workflowActionCode +"ed successfully!");      
            else if(rule[0].workflowActionCode=="Close")
                  this.toastr.success("Enquiry " + rule[0].workflowActionCode +"d successfully!"); 
            this.router.navigate(['/Enquiry']);      
          }, error => {
            this.toastr.error('Failed to ' + rule[0].workflowActionCode);
            this.showLoader = false;
          });
        }
    });     
    }
    
  getAllCloseOpportunityReason() {
    this.commonService.GetData("OpportunityCloseReason", "GetAll", null).subscribe((data: any) => {
      this.closeOpportunityList = data;      
    },
      error => {
        this.toastr.error("Failed to load Data!");
         this.showLoader = false;
      });
  }

  showGoToRFQPage()
  {
    this.showRFQBtn=true;
    let statusList=this.enquiryStatusList.filter(x=> x.Status=='EnquiryClosePendingAtSalesDirector' || x.Status=='EnquiryLost');
    
    if(statusList.length>0)
    {        
        let enquiryStatusCode = statusList.filter(x=>x.Pk_Id == this.EnquiryObj.EnquiryDetails.Status);    
        if(enquiryStatusCode.length>0)
        {
          this.showRFQBtn=false;
        }       
    }
  }
  getReasonCategory()
  {   
     this.showLoader=true;
      this.commonService.GetData("ReasonEnquiryWinLoss", "GetAllReasonCategory", null).subscribe((data: any) => {
        this.WinLossDataForStatus = data;     
    
        this.showLoader=false;
      },
        error => {
          this.toastr.error("Failed to load Data!");
           this.showLoader = false;
        });

  }
  checkStep(stepper: MatStepper){    
    this.selectedIndexForStatus=stepper.steps.length-1;
  }
 
  triggerFalseClick() {
    this.showLoader=true;    
    setTimeout(() => {     
      if(this.buttonclk!=undefined)
      {
        const buttonElement = this.buttonclk.nativeElement;
        this.renderer.selectRootElement(buttonElement).click();
      }   
      this.showLoader=false;   
      this.falseSetup=false;
      this.updateLoader(false);
    }, 6000);   
  }  

  getUserProfileInfo() {
    this.showLoader = true;
    this.commonService.GetUserProfileData('Account', 'GetUserProfileInfo').subscribe((data: any) => {
      this.hasAccessToViewCustomersCustomer(data.RoleType); 
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }
  hasAccessToViewCustomersCustomer(RoleType:any)
  {
    if(RoleType==3){
      this.hasAccessForCustomersCustomer=false
    }
    else{
      this.hasAccessForCustomersCustomer=true
    }  
  }
}
