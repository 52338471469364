export class ProductLine {
    Id: number;
    Name: string = "";
    Status: boolean;
}

export class ProductFamily {
    Id: number;
    ProductLine: number = null;
    ProductLineName: string = "";
    ProductFamilyName: string = "";
    Status: boolean;
}

export class ProductSeries {
    Id:number;
    ProductLine: number = null;
    ProductLineName: string = "";
    ProductFamily: number = null;
    ProductFamilyName: string = "";
    SeriesName: string = "";
    Label_Variant1: string = '';
    Label_Variant2: string = '';
    Label_Variant3: string = '';
    Label_Variant4: string = '';
    IsLevel2DepOnLevel1: boolean = false;
    IsLevel3DepOnLevel2: boolean = false;
    IsLevel4DepOnLevel3: boolean = false;
    Status: boolean;
    CatlogName: string = '';
}

export class ProductModel {
    Id: number;
    ProductSeries: number = null;
    SeriesName: string = "";
    ModelName: string = "";
    Price: string = "";
    ArticleNumber: string = "";
    Status: boolean;
}

export class VariantLevel1 {
    Id: number;
    ProductSeries: number = null;
    SeriesName: string = "";
    ProductModel: number = null;
    ModelName: string = "";
    ArticleNumber: string = "";
    Level1: Level1Model[] = [];
    Level1arr: any[] = [];
    Price: number = 0;
    Status: boolean;
    otherType: string = '';
    IsPriceOnRequest: boolean = false;
    PrevIsPriceOnRequest: boolean = false;
    PrevPrice: number = 0;
    IsUnits: boolean = false;

}

//Model used for Level1 , Level2 get data with specific id 
//i.e Station-id with Station name
export class Level1Model {
    
    Id: number;
    Level1: string;
}

export class VariantLevel2 {
   
    Id: number;
    ProductSeries: number = null;
    SeriesName: string = "";
    ProductModel: number = null;
    ModelName: string = "";
    Level1Id: number = null;
    Level1: string = "";
    Level1Price: number = 0;
    Level2: Level1Model[] = [];
    Level2Price: number = 0;
    Status: boolean;
    IsLevel2DepOnLevel1:boolean = false;
    Label_Variant1: string = '';
    Label_Variant2: string = '';
    otherType: string = '';
    Level1IsPriceOnRequest: boolean =false;
    IsPriceOnRequest: boolean = false;
    IsUnits: boolean = false;
    PrevIsPriceOnRequest: boolean = false;
    PrevPrice: number = 0;
}

export class VariantLevel3 {
    Id: number;
    ProductSeries: number = null;
    SeriesName: string = "";
    ProductModel: number = null;
    ModelName: string = "";
    Level1Id:number = null;
    Level1Name:string = "";
    Level2Id:number = null;
    Level2:string = "";
    Level2Price:number = 0;
    Level3:Level1Model[] = [];
    Level3Price: number = 0;
    Level2IsPriceOnRequest: boolean =false;
    IsLevel3DepOnLevel2:boolean = false;
    IsLevel2DepOnLevel1:boolean = false;
    Label_Variant1: string = "";
    Label_Variant2: string = "";
    Label_Variant3: string = "";
    Status: boolean;
    IsPriceOnRequest: boolean = false;
    IsUnits: boolean = false;
    PrevIsPriceOnRequest: boolean = false;
    PrevPrice: number = 0;
       
}

export class VariantLevel4 {
    Id: number;
    ProductSeries: number = null;
    SeriesName: string = "";
    ProductModel: number = null;
    ModelName: string = "";
    Level1Id:number = null;
    Level2Id:number = null;
    Level3Id:number = null;
    Level3:string = "";
    Level3Price:number = 0;
    Level4:Level1Model[] = [];
    Level4Price: number = 0;
    Level3IsPriceOnRequest: boolean =false;
    IsLevel2DepOnLevel1:boolean = false;
    IsLevel3DepOnLevel2:boolean = false;
    IsLevel4DepOnLevel3:boolean = false;
    Label_Variant1: string = "";
    Label_Variant2: string = "";
    Label_Variant3: string = "";
    Label_Variant4: string = "";
    Status: boolean;
    IsPriceOnRequest: boolean = false;
    IsUnits: boolean = false;
    PrevIsPriceOnRequest: boolean = false;
    PrevPrice: number = 0;
       
}

export class Options {
    Id: number;
    ProductSeries: number = null;
    SeriesName: string = "";
    // ProductModel: number = null;
    ProductModelArr: number[] = [];
    ProductModelNameArr: string[] = [];
    ModelName: string = "";
    ArticleNumber: string = "";
    Option: string = "";
    Price: number;
    IsPriceOnRequest: boolean = false;
}



export class Station {
    Id:number;
    Station:string = '';
}

