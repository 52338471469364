import { map } from '@amcharts/amcharts4/.internal/core/utils/Iterator';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Menuelement } from '../Model/HeaderMenuModel';
import { AccessPermissionModel } from '../Model/WorkFlowScreenAccessModel';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AccessPermissionService {
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];
  menuaccessinfo:Menuelement[]=Array<Menuelement>();
  constructor(private commonService: CommonService) { }

  GetAll(): Observable<any>
  {
     return this.commonService.GetData('AccessPermission','GetPermissionsForCurrentUser', null);
   }

  GetWorkFlowAccessScreen()
  {
    
  } 

}
