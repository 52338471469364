import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportFilterViewModel } from 'src/app/Model/ReportFilterModel';
import { ActivityService } from 'src/app/Service/activity.service';
import { ListActivityComponent } from '../activity/list-activity/list-activity.component';

@Component({
  selector: 'app-my-activity',
  templateUrl: './my-activity.component.html',
  styleUrls: ['./my-activity.component.scss']
})
export class MyActivityComponent implements OnInit {

  constructor(public dialog: MatDialog, private service: ActivityService) { }

  isListView: boolean = true;

  events = [];

  ngOnInit(): void {
    localStorage.removeItem('ViewType-A');
    localStorage.setItem('ViewType', 'ListView');
  }



}
