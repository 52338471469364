import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatPaginator } from '@angular/material/paginator';
import { BusinessPlanModel } from 'src/app/Model/BusinessPlanModel';
import { AppGlobals } from 'src/app/app.global';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';


@Component({
  selector: 'app-business-plan-details',
  templateUrl: './business-plan-details.component.html',
  styleUrls: ['./business-plan-details.component.scss']
})
export class BusinessPlanDetailsComponent implements OnInit {
  showLoader: boolean = false;
  businessPlanDetails: BusinessPlanModel[] = [];
  displayedColumns: string[] = ['UserName', 'Region', 'RoleName', 'TargetYear', 'TargetFor', 'Action'];
  dataSource: MatTableDataSource<BusinessPlanModel>;
  disabled: boolean = false;
  UserRole: any;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];

  /*************************************************************************
        Server side Pagination purpose
    *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';

  ColumnName: string = 'ModifiedOn';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isBPMasterEditable: boolean = false;
  constructor(
    public dialog: MatDialog,
    private Service: CommonService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private titleService: Title,
    private accessservice: AccessPermissionService,
    private appConfigService: AppConfigService
 ) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    //this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();

   // this.isBPMasterEditable = AppGlobals.BusinessPlanEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.getAllBusinessPlandetails();
  }

  getAllBusinessPlandetails() {
    let queryString = "";
    queryString = "pageNumber=" + this.pageNumber;
    queryString += "&pageSize=" + this.pageSize;
    queryString += "&searchText=" + this.SearchText;
    queryString += "&direction=" + this.direction;
    queryString += "&columnName=" + this.ColumnName;
    this.showLoader = true;
    this.Service.GetData('BusinessPlanSalesTarget', 'GetAllPagination', queryString).subscribe((data: any) => {
      this.businessPlanDetails = data.Data;
      this.length = data.Total;
      this.dataSource = new MatTableDataSource(this.businessPlanDetails);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.getAllBusinessPlandetails();
    }
  }
  pageEvent(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getAllBusinessPlandetails();
  }
  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.getAllBusinessPlandetails();
  }
  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  UpdateBPStatusById(data: BusinessPlanModel, CurrentStatus: boolean) {

    if (CurrentStatus == true) {
      //Create ConfirmDialogRef 
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to deactivate this business plan for sales and orders?",
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;
          this.Service.PostData('BusinessPlanSalesTarget', 'UpdateStatus', data, null).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Business plan for sales and orders deactivated successfully!");
            }
            else if (data.ResponseCode == 0) {
              this.toastr.warning("Failed to deactivate this business plan for sales and orders because it has some dependency!");
            }
            else {
              this.toastr.info("Failed to deactivate business plan for sales and orders!");
            }
            this.getAllBusinessPlandetails();
          }, error => {
            this.toastr.error("Failed to deactivate business plan for sales and orders!");
            this.getAllBusinessPlandetails();
            this.showLoader = false;
          });

        }
        else {
          this.getAllBusinessPlandetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;

        }
      });

    }
    else {
      //Create ConfirmDialogRef
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to activate this business plan for sales and orders?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          this.Service.PostData('BusinessPlanSalesTarget', 'UpdateStatus', data, null).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Business plan for sales and orders activated successfully!");
            }
            else {
              this.toastr.info("Failed to activate business plan for sales and orders!");
            }
            this.getAllBusinessPlandetails();
          }, error => {
            this.toastr.error("Failed to activate business plan for sales and orders!");
            this.getAllBusinessPlandetails();
            this.showLoader = false;
          });

        }
        else {
          this.getAllBusinessPlandetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });
    }
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isBPMasterEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isBusinessPlanforSalesandOrder')[0]?.IsEditable;
      
    });
 
  }
}
