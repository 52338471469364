import { Component, OnInit, Input } from '@angular/core';
import { EmployeeGuestModel } from 'src/app/Model/EmployeeGuestModel';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { CommonService } from 'src/app/Service/common.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { StockInventoryModel } from 'src/app/Model/StockInventoryModel';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-view-stock-inventory',
  templateUrl: './view-stock-inventory.component.html',
  styleUrls: ['./view-stock-inventory.component.scss']
})
export class ViewStockInventoryComponent implements OnInit {
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];
  StockInventoryObj: StockInventoryModel[] = [];
  UserRole: any;
  isEmployeeEditable: boolean = false;
  @Input()
  showLoader: boolean = false;
  fileUrl = AppGlobals.baseFileDownloadUrl + "StockInventory/";
  constructor(private router: Router, private route: ActivatedRoute, 
    private toastr: ToastrService, private Service: CommonService, 
    public dialog: MatDialog, private titleService: Title,
    private accessservice: AccessPermissionService,
    private appConfigService : AppConfigService) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    // this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
   // this.isEmployeeEditable = AppGlobals.EmployeeMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    var Id = this.route.snapshot.params["id"];
    this.getInventoryDetailsById(Id);
  }

  getInventoryDetailsById(Id: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "id=" + Id;

    this.Service.GetData('StockInventory', 'GetById', queryString).subscribe((data: any) => {
      this.StockInventoryObj = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });
  }

  DownloadFile(Path: any) {
    let link = document.createElement('a');
    let path = this.fileUrl + Path
    link.setAttribute('href', path);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.remove();
    return false;
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isEmployeeEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isEmployee')[0]?.IsEditable;
      
    });
 
  }

}
