import { Component, OnInit, Input } from '@angular/core';
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductLine } from 'src/app/Model/ProductModel';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AppConfigService } from 'src/app/Service/app-config.service';

@Component({
  selector: 'app-edit-view-product-line',
  templateUrl: './edit-view-product-line.component.html',
  styleUrls: ['./edit-view-product-line.component.scss']
})
export class EditViewProductLineComponent implements OnInit {
  isEditable: boolean = true;
  FormStatus: string = "View";
  ProductLineobj: ProductLine = new ProductLine();
  charCode: any;
  UserRole: any;
  isProductLineEditable: boolean = false;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];

  @Input()
  showLoader: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title,private accessservice: AccessPermissionService,
    private appConfigService : AppConfigService) { }

  ngOnInit(): void {
    const appConfig = this.appConfigService.getConfig();
    const dynamicTitle = this.route.snapshot.data['title'];
    this.titleService.setTitle(`${dynamicTitle} - ${appConfig.titleName}`);
    // this.titleService.setTitle(this.route.snapshot.data['title'] + " - ECOTECH");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
    //this.isProductLineEditable = AppGlobals.ProductMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.ProductLineobj.Id = this.route.snapshot.params["id"];
    this.getProductLineIdDetailsById(this.ProductLineobj.Id);
  }

  getProductLineIdDetailsById(ProductLineId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "id=" + ProductLineId;

    this.CommonService.GetData('ProductLine', 'GetById', queryString).subscribe((data: any) => {
      this.ProductLineobj = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });

  }

  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    const appConfig = this.appConfigService.getConfig();
    this.titleService.setTitle(`Edit Product Line - ${appConfig.titleName}`);
    // this.titleService.setTitle("Edit Product Line - ECOTECH");
  }


  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onSubmit(myform: any) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update this product line?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.showLoader = true;
        this.CommonService.PostData('ProductLine', 'Update', this.ProductLineobj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('Product line updated successfully!');
            this.router.navigate(['/ProductLine']);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('Product line already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to update product line!');
            this.router.navigate(['/ProductLine']);
            this.showLoader = false;
          }


        }, error => {
          this.toastr.error('Failed to update product line!');
          this.showLoader = false;
          this.router.navigate(['/ProductLine']);
        });
      }
    });
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isProductLineEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isProductLine')[0]?.IsEditable;
      
    });
 
  }
}
