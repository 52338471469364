import { Component, OnInit, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-quatation-order-intake-chart',
  templateUrl: './quatation-order-intake-chart.component.html',
  styleUrls: ['./quatation-order-intake-chart.component.scss']
})
export class QuatationOrderIntakeChartComponent implements OnInit {

  @Input()
  showLoader: boolean = false;
  drpdnOption: string = "Amount";

  @Input() Year: number;
  @Input() region: number;
  @Input() currency: number;
  @Input() filterChanged: boolean;
  @Input() currencyCode: string;
  constructor(private toastr: ToastrService, private commonService: CommonService) { }

  ngOnInit() {
   // am4core.options.onlyShowOnViewport = true;
    this.QuotationOrdIntake();
  }
  ngOnChanges(): void {
    if (this.filterChanged) this.QuotationOrdIntake();
  }

  OnSelectChange() {
    this.QuotationOrdIntake();
  }

  QuotationOrdIntake() {
    this.showLoader = true;
    let queryString = "";
    queryString += "drpdnOption=" + this.drpdnOption;
    queryString += "&Year=" + this.Year;
    queryString += "&regionid=" + this.region;
    queryString += "&currency=" + this.currency;

    this.commonService.GetData("Dashboard", "QuotationOrderIntakeChart", queryString).subscribe((data: any) => {
      // Create chart instance
      let chart = am4core.create("QuotationOrdIntakeChart", am4charts.XYChart);

      // Export
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      // chart.cursor = new am4charts.XYCursor();
      chart.scrollbarX.exportable = false;
      chart.scrollbarY.exportable = false;
      chart.exporting.menu = new am4core.ExportMenu();
      let options = chart.exporting.getFormatOptions("pdf");
      options.addURL = false;
      chart.exporting.setFormatOptions("pdf", options);
      chart.exporting.filePrefix = "QuotationOrderIntake";
      /**************Custom Export**************** */
      chart.exporting.menu.items = [
        {
          "label": "...",
          "menu": [
            {
              "label": "Image",
              "menu": [
                { "type": "png", "label": "PNG" },
                { "type": "jpg", "label": "JPG" },
                // { "type": "svg", "label": "SVG" },
                { "type": "pdf", "label": "PDF" }
              ]
            }, {
              "label": "Data",
              "menu": [
                { "type": "xlsx", "label": "XLSX" },
              ]
            }, {
              "label": "Print", "type": "print"
            }
          ]
        }
      ];
      let CurrencyLabel = "";
      if (this.drpdnOption == "Amount") {
        // if (this.currency == 1) CurrencyLabel = "(USD)";
        // if (this.currency == 2) CurrencyLabel = "(EURO)";
        // if (this.currency == 3) CurrencyLabel = "(INR)";
        if (this.currencyCode == "USD") CurrencyLabel = "(USD)";
        if (this.currencyCode == "Euro") CurrencyLabel = "(EURO)";
        //if (this.currencyCode == "INR") CurrencyLabel = "(INR)";
        if (this.currencyCode == "VND") CurrencyLabel = "(VND)";
        if (this.currencyCode == "IDR") CurrencyLabel = "(IDR)";
        if (this.currencyCode == "PHP") CurrencyLabel = "(PHP)";
        if (this.currencyCode == "THB") CurrencyLabel = "(THB)";
        if (this.currencyCode == "MYR") CurrencyLabel = "(MYR)";
        if (this.currencyCode == "SGD") CurrencyLabel = "(SGD)";
      }
      else {
        CurrencyLabel = "(No's)";
      }
      chart.exporting.dataFields = {
        "category": "Month",
        "valueBar1": "Quotation_Individual" + CurrencyLabel,
        "valueLine1": "Quotation_Cumulative" + CurrencyLabel,
        "valueBar2": "OrderBooked_Individual" + CurrencyLabel,
        "valueLine2": "OrderBooked_Cumulative" + CurrencyLabel,

      }
      /**************End Custom Export**************** */

      let title = chart.titles.create();
      title.text = "Quotation vs Order Booked";
      title.fontSize = 15;
      title.fontWeight = "bold";
      title.marginBottom = 30;

      chart.data = data;

      /* Create axes */
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.minGridDistance = 30;


      /* Create value axis */
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
        // var val  = parseFloat(text)%1!=0  ? "" : parseInt(text).toString();
        var val = text == "0.00" ? "0" : text;
        return val;
      });
      /*******Change by Ashwini  *********/

      /**************Scrolling Start End Point**************** */
      chart.events.on("beforedatavalidated", function(ev) {
        categoryAxis.start = 0;
        categoryAxis.end = 0.5;
        valueAxis.start = 0;
        valueAxis.end = 1;
        });
        /**************End Scrolling Start End Point**************** */
      if (this.drpdnOption == 'Units') {
        valueAxis.title.text = "No.";
        chart.numberFormatter.numberFormat = "0";
        valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
          var val = parseFloat(text) % 1 != 0 ? "" : parseInt(text).toString();
          return val;
        });
      }
      else {
        // if (this.currency == 1) valueAxis.title.text = "USD";
        // if (this.currency == 2) valueAxis.title.text = "EURO";
        // if (this.currency == 3) valueAxis.title.text = "INR";
        if (this.currencyCode == "USD") valueAxis.title.text = "USD";
        if (this.currencyCode == "Euro") valueAxis.title.text = "EURO";
        //if (this.currencyCode == "INR") valueAxis.title.text = "INR";
        if (this.currencyCode == "VND") valueAxis.title.text = "VND";
        if (this.currencyCode == "IDR") valueAxis.title.text = "IDR";
        if (this.currencyCode == "PHP") valueAxis.title.text = "PHP";
        if (this.currencyCode == "THB") valueAxis.title.text = "THB";
        if (this.currencyCode == "MYR") valueAxis.title.text = "MYR";
        if (this.currencyCode == "SGD") valueAxis.title.text = "SGD";
       // chart.numberFormatter.numberFormat = "0.000";
      }
      valueAxis.title.fontWeight = "bold";
      valueAxis.title.fontSize = "16px";
      valueAxis.min = 0;
      valueAxis.events.on("ready", function (ev) {
        ev.target.min = ev.target.min;
        ev.target.max = ev.target.max;
        if (ev.target.max < 0.1) valueAxis.extraMax = 10;
        if (ev.target.max > 0.1 && ev.target.max < 1) valueAxis.extraMax = 5;
        if (ev.target.max > 1) valueAxis.extraMax = 0.2;
      })

      valueAxis.calculateTotals = true;
      /********End  *********/

      valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
        var val = text == "0.00" ? "0" : text;
        return val;
      });
      valueAxis.title.fontWeight = "bold";
      valueAxis.title.fontSize = "16px";
      valueAxis.min = 0;
      valueAxis.extraMax = 0.5;




      //#region "ColumnSeries1"
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());
      columnSeries.name = "Quotation";
      columnSeries.dataFields.valueY = "valueBar1";
      columnSeries.dataFields.categoryX = "category";

      columnSeries.columns.template.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.tooltip.getFillFromObject = false;
      columnSeries.fill = am4core.color("#ff4d4d");
      columnSeries.tooltip.background.fill = am4core.color("#4a463c");
      let colLabel = columnSeries.bullets.push(new am4charts.LabelBullet);
      colLabel.label.text = "{valueY}";
      colLabel.label.rotation = 0;
      colLabel.label.fontSize = 9;
      colLabel.label.padding(0, 0, 20, 0);
      colLabel.label.truncate = false;
      colLabel.label.hideOversized = false;
      //#endregion

      //#region "ColumnSeries2"
      /* Create series */
      let column2Series = chart.series.push(new am4charts.ColumnSeries());
      column2Series.name = "Order Booked";
      column2Series.dataFields.valueY = "valueBar2";
      column2Series.dataFields.categoryX = "category";

      column2Series.columns.template.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]";
      column2Series.columns.template.propertyFields.fillOpacity = "fillOpacity";
      column2Series.columns.template.propertyFields.stroke = "stroke";
      column2Series.columns.template.propertyFields.strokeWidth = "strokeWidth";
      column2Series.columns.template.propertyFields.strokeDasharray = "columnDash";
      column2Series.tooltip.label.textAlign = "middle";
      column2Series.tooltip.getFillFromObject = false;
      column2Series.fill = am4core.color("#6794dc");

      column2Series.tooltip.background.fill = am4core.color("#4a463c");
      let colLabel2 = column2Series.bullets.push(new am4charts.LabelBullet);
      colLabel2.label.text = "{valueY}";
      colLabel2.label.rotation = 0;
      colLabel2.label.fontSize = 9;
      colLabel2.label.padding(0, 0, 20, 0);
      colLabel2.label.truncate = false;
      colLabel2.label.hideOversized = false;
      //#endregion

      //#region "LineSeries1"
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.name = "Quotation";
      lineSeries.dataFields.valueY = "valueLine1";
      lineSeries.dataFields.categoryX = "category";
      lineSeries.tooltip.getFillFromObject = false;
      lineSeries.tooltip.background.fill = am4core.color("#4a463c");
      lineSeries.stroke = am4core.color("#ff4d4d");   //For line or column color

      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#ff4d4d"); // tooltips grab fill from parent by default
      bullet.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 4;
      circle.fill = am4core.color("#fff");
      circle.strokeWidth = 3;

      let labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueL1}";
      labelBullet.label.verticalCenter = "bottom";
      //#endregion

      //#region "LineSeries2"
      let lineSeries2 = chart.series.push(new am4charts.LineSeries());
      lineSeries2.name = "Order Booked";
      lineSeries2.dataFields.valueY = "valueLine2";
      lineSeries2.dataFields.categoryX = "category";
      lineSeries2.tooltip.getFillFromObject = false;
      lineSeries2.tooltip.background.fill = am4core.color("#4a463c");
      //lineSeries2.stroke = am4core.color("#4a463c"); //For line or column color

      let bullet2 = lineSeries2.bullets.push(new am4charts.Bullet());
      bullet2.fill = am4core.color("#4a463c"); // tooltips grab fill from parent by default
      bullet2.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
      let circle2 = bullet2.createChild(am4core.Circle);
      circle2.radius = 4;
      circle2.fill = am4core.color("#fff");
      circle2.strokeWidth = 3;

      let labelBullet2 = lineSeries2.bullets.push(new am4charts.LabelBullet());
      labelBullet2.label.text = "{valueL2}";
      labelBullet2.label.verticalCenter = "bottom";
      //#endregion

      chart.numberFormatter.numberFormat = chart.numberFormatter.numberFormat;
      chart.legend = new am4charts.Legend();

      this.showLoader = false;
    }, error => {
      this.showLoader = false;

    });

  }

}
