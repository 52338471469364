import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../Service/common.service';

@Component({
  selector: 'app-dynamic-bgimage',
  templateUrl: './dynamic-bgimage.component.html',
  styleUrls: ['./dynamic-bgimage.component.scss']
})
export class DynamicBGImageComponent implements OnInit {
  allowedFileType: string[] = ['image/jpeg', 'image/png', 'image/jpg'];
  tempfile: any;
  BackgroundImage:any[]=[];
  @Input() showLoader: boolean = false;
  constructor(private toastr: ToastrService, public cdRef: ChangeDetectorRef,private activeModal: NgbActiveModal, private commonService: CommonService) { }

  ngOnInit(): void {
  }

  getFileDetails(event) {
    
      let file = event.target.files[0];
      let isValidFile = true;
      if (((file.size) / 1000000) <= 5) {

        isValidFile = this.allowedFileType.includes(file.type);
        if (!isValidFile) {
          this.toastr.warning("Please attach the file having extension :\n\n .jpeg, .jpg, .png");
          //event.target.value = null;
          isValidFile = false;
        } else if (file.name.length > 50) {
          this.toastr.warning("The file name with more than 50 characters are not allowed.");
          isValidFile = false;
        }
      }
      else {
        this.toastr.warning("File size must be less than or equal to 5MB!");
        //event.target.value = null;
        isValidFile = false;
      }
      if (isValidFile) {
        this.BackgroundImage = [];
        this.BackgroundImage.push(file);
      }
  }

  removeAttachments()
  {
    this.BackgroundImage = [];
  }

  OnSubmit() {
    const frmData = new FormData();
    if (this.BackgroundImage != null) {
     
        frmData.append("BGImages", this.BackgroundImage[0]);
    
    this.commonService.PostData('BgImage', 'Add', frmData, null).subscribe((data: any) => {

      if (data.ResponseCode == 1) {
        this.toastr.success("Background image added successfully!");
        this.closePopup();
        this.showLoader = false;
      }
      else {
        this.toastr.error("Failed to background image!");
        this.closePopup();
        this.showLoader = false;
      }


    }, error => {
      this.toastr.error("Failed to add stock inventory details!");
      this.closePopup();
      this.showLoader = false;
    });
  }
  else{
    alert("Please upload background image")
  }
   
  }
  closePopup() {
    this.activeModal.close('Close Click');
  }
  }

